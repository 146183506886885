import filesize from "filesize";
import React from "react";

import { IoMdTrash } from "react-icons/io";

import { LayoutForm } from "../../../components/Form/LayoutForm";
import { ContainerPreviews } from "../../../components/ModalModificationHeaderDatasheet/styled";
import Dropzone from "../../../components/Upload/Dropzone";
import { ContainerDropZone } from "../../create/styles";
import { ButtunSubmit, ContainerImageExample } from "../styles";

import generateHash from "../../../utils/generateHash";
import selectIcon from "../../../utils/selectIcon";

import Fachada from "../../../assets/Faixada.jpg";
import Interior from "../../../assets/Interior.jpg";

interface Props {
  setFiles: React.Dispatch<React.SetStateAction<FileProps[]>>;
  files: FileProps[];

  onErrorSelect?: boolean;
  handleSubmit?: () => void;
}

export interface FileProps {
  typeFile?: string;
  file: File;
}

export function DropzoneClientFile({
  files,
  setFiles,
  onErrorSelect,
  handleSubmit,
}: Props) {
  async function handleDeleteFile(index: number) {
    setFiles((old) => old.filter((_, i) => i !== index));
  }

  return (
    <LayoutForm>
      <div className="field">
        <div className="containerLabelButton">
          <h3>Enviar fotos</h3>
          <button
            type="button"
            onClick={() => {
              setFiles([]);
            }}
          >
            <IoMdTrash size={15} />
            Apagar todos
          </button>
        </div>

        <p
          style={{
            fontSize: "1.1rem",
            marginBottom: "0.675rem",
            marginTop: "0.875rem",
            color: "#333",
          }}
        >
          Requisitos
        </p>
        <ul
          style={{
            marginBottom: "0.875rem",
            listStyle: "initial",
            marginLeft: "1.5rem",
          }}
        >
          <li>Foto externa da fachada, aparecendo os vizinhos laterais.</li>
          <li>Fotos internas amplas, que apareça toda a loja.</li>
          <li>Não é permitido prints de fotos.</li>
        </ul>

        <p
          style={{
            fontSize: "1.1rem",
            marginBottom: "0.675rem",
            color: "#333",
          }}
        >
          Exemplos:
        </p>
        <ContainerImageExample>
          <div>
            <span>FACHADA</span>
            <img src={Fachada} alt="Fachada" />
          </div>
          <div>
            <span>INTERIOR</span>
            <img src={Interior} alt="Interior" />
          </div>
        </ContainerImageExample>

        <span
          style={{
            fontWeight: "lighter",
            fontSize: "0.875rem",
            color: "#333",
            marginTop: "0.775rem",
          }}
        >
          * Necessário no mínimo 3 imagens, e ao menos uma em cada categoria (
          FACHADA E INTERIOR )
        </span>

        <ContainerDropZone style={{ marginTop: "1rem" }}>
          <Dropzone
            accept={["image/*"]}
            onFileUploaded={(e) =>
              setFiles((old) => [...old, { file: e, typeFile: "" }])
            }
          />
          <ContainerPreviews
            style={{
              overflowY: "auto",
              maxHeight: "30vh",
            }}
          >
            {files &&
              files.map(({ file, typeFile }, index) => (
                <li key={`${generateHash()}-${file.name}`}>
                  <img
                    src={
                      selectIcon(file.type)
                        ? selectIcon(file.type)
                        : URL.createObjectURL(file)
                    }
                    alt="_"
                  />
                  <div className="fileInfo">
                    <div>
                      <strong>{file.name}</strong>
                      <span>{filesize(file.size)}</span>
                    </div>

                    <div className="action-file">
                      <div className="field-file">
                        <label htmlFor="typeFile">Tipo:</label>
                        <select
                          name={`typeFile-${index}`}
                          onChange={(e) => {
                            const filesNormalized = files.map((item) =>
                              item.file === file
                                ? {
                                    file: item.file,
                                    typeFile: String(e.target.value),
                                  }
                                : item
                            );

                            setFiles(filesNormalized);
                          }}
                          value={typeFile}
                          className={
                            onErrorSelect && !typeFile
                              ? "fileInfo-field-required"
                              : ""
                          }
                        >
                          <option value="">Selecionar</option>
                          <option value="FACHADA">FACHADA</option>
                          <option value="INTERIOR">INTERIOR</option>
                        </select>
                      </div>

                      <button
                        type="button"
                        onClick={() => {
                          handleDeleteFile(index);
                        }}
                      >
                        <IoMdTrash size={30} />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
          </ContainerPreviews>
        </ContainerDropZone>
      </div>

      {handleSubmit && (
        <ButtunSubmit type="button" onClick={handleSubmit}>
          Enviar arquivos
        </ButtunSubmit>
      )}
    </LayoutForm>
  );
}
