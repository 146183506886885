import React from "react";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { FieldProps } from "../../../components/panel/table/TableContent";
import { Billet } from "../../../hook/queries/useBillet";

type FilterProps = {
  columns: FieldProps<Billet>[];
  formikFilter: any;

  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isVisible: boolean;

  filters: BilletFilterProps;
  setFilters: (data: BilletFilterProps) => void;

  is_admin?: boolean;
  is_client?: boolean;
  is_seller?: boolean;
  is_manager?: boolean;
};

export interface BilletFilterProps {
  documentNumber?: string;
  ourNumber?: string;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  duePeriod?: number;
  duePresetDate?: number;
  dueSpecificDate?: Date;
  dueStartDate?: Date;
  dueEndDate?: Date;
}

export function BilletFilter({
  filters,
  setFilters,
  columns,
  formikFilter,
  isVisible,
  setIsVisible,
}: FilterProps) {
  function selectDescription(st: any, title: string) {
    let data = String(st);
    const arrTitle = ["duePeriod", "duePresetDate"];
    if (arrTitle.includes(title)) {
      switch (title) {
        case "duePeriod":
          if (Number(st) === 1) {
            data = "Predefinida";
          }
          if (Number(st) === 2) {
            data = "Específica";
          }
          if (Number(st) === 3) {
            data = "Intevalo";
          }

          break;
        case "duePresetDate":
          if (Number(st) === 0) {
            data = "Hoje";
          }
          if (Number(st) === 2) {
            data = "Ontem";
          }
          if (Number(st) === 7) {
            data = "Últimos 7 dias";
          }
          if (Number(st) === 15) {
            data = "Últimos 15 dias";
          }
          if (Number(st) === 30) {
            data = "Últimos 30 dias";
          }

          break;
      }
    }

    return data;
  }

  return (
    <PanelFilter
      filters={filters}
      setFilters={setFilters}
      columns={columns}
      onSubmit={formikFilter.handleSubmit}
      onResetForm={() => formikFilter.handleReset(formikFilter)}
      setViewFilter={setIsVisible}
      viewFilter={isVisible}
      normalizedFilters={selectDescription}
    >
      <GroupInput>
        <Input
          label="Cód. Cliente"
          {...formikFilter.getFieldProps("codClient")}
        />
        <Input label="CNPJ" {...formikFilter.getFieldProps("cnpj")} />
        <Input
          label="Razão social"
          {...formikFilter.getFieldProps("company_name")}
        />
      </GroupInput>
      <GroupInput>
        <Input
          label="Número do documento"
          {...formikFilter.getFieldProps("documentNumber")}
        />
        <Input
          label="Nosso número"
          {...formikFilter.getFieldProps("ourNumber")}
        />
      </GroupInput>

      <Select
        label="PERÍODO DE VENCIMENTO"
        data={[
          { name: "Predefinida", value: 1 },
          { name: "Específica", value: 2 },
          { name: "Intervalo", value: 3 },
        ]}
        {...formikFilter.getFieldProps("duePeriod")}
      />

      {Number(formikFilter.values.duePeriod) === 1 && (
        <Select
          label="DATA DO PEDIDO"
          data={[
            { name: "Hoje", value: 0 },
            { name: "Ontem", value: 1 },
            { name: "Últimos 7 dias", value: 7 },
            { name: "Últimos 15 dias", value: 15 },
            { name: "Últimos 30 dias", value: 30 },
          ]}
          {...formikFilter.getFieldProps("duePresetDate")}
          error={
            formikFilter.touched.duePresetDate &&
            formikFilter.errors.duePresetDate
              ? formikFilter.errors.duePresetDate
              : undefined
          }
        />
      )}
      {Number(formikFilter.values.duePeriod) === 2 && (
        <Input
          type="date"
          label="DATA DO PEDIDO"
          {...formikFilter.getFieldProps("dueSpecificDate")}
          error={
            formikFilter.touched.dueSpecificDate &&
            formikFilter.errors.dueSpecificDate
              ? formikFilter.errors.dueSpecificDate
              : undefined
          }
        />
      )}
      {Number(formikFilter.values.duePeriod) === 3 && (
        <GroupInput>
          <Input
            type="date"
            label="DATA INICIAL"
            {...formikFilter.getFieldProps("dueStartDate")}
            error={
              formikFilter.touched.dueStartDate &&
              formikFilter.errors.dueStartDate
                ? formikFilter.errors.dueStartDate
                : undefined
            }
          />
          <Input
            type="date"
            label="DATA FINAL"
            {...formikFilter.getFieldProps("dueEndDate")}
            error={
              formikFilter.touched.dueEndDate && formikFilter.errors.dueEndDate
                ? formikFilter.errors.dueEndDate
                : undefined
            }
          />
        </GroupInput>
      )}
    </PanelFilter>
  );
}
