import filesize from "filesize";
import React, { useEffect, useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { FilesProps } from "../";
import Dropzone from "../../../components/Upload/DropzoneArr";
import { FileProps } from "../../../pages/detail/Datasheet";
import api from "../../../service/api";
import selectIcon from "../../../utils/selectIcon";
import { ContainerPreviews } from "./styles";

interface FileComponentProps {
  fileQueryOver?: FileProps[];

  files: FilesProps[];
  setFiles: React.Dispatch<React.SetStateAction<FilesProps[]>>;
}

interface CategotyFile {
  id: number;
  name: string;
}

export const Files: React.FC<FileComponentProps> = ({
  files,
  setFiles,
  fileQueryOver,
}) => {
  const [categoriesFile, setCategoriesFile] = useState<CategotyFile[]>([]);

  useEffect(() => {
    (async () => {
      const gategoriesFileDB = await api.get<CategotyFile[]>(
        `headerDatasheet/datasheet/categoryFile`
      );
      setCategoriesFile(gategoriesFileDB.data);
    })();
  }, []);

  async function handleRemoveFile(indece: number) {
    const arrDelete = files.filter((f, index) => index !== indece);

    setFiles(arrDelete);
  }

  function onChangeSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    const updateFiles = files.filter((e) => e.file !== null);

    const [, index] = e.target.name.split("-");
    const findOneFile = updateFiles.find((_, indexF) => +indexF === +index);
    if (!findOneFile) return;
    findOneFile.category = e.target.value;

    setFiles(updateFiles);
  }

  return (
    <>
      <h3>Arquivos</h3>

      <Dropzone
        onFileUploaded={(filesSelect) => {
          var normilizedFiles: FilesProps[] = filesSelect.map((f) => ({
            file: f,
            category: "",
          }));

          setFiles([...files, ...normilizedFiles]);
        }}
      />
      <ContainerPreviews
        style={{
          overflowY: "auto",
          maxHeight: "35vh",
        }}
      >
        {files &&
          files.map(
            ({ file, category }, index) =>
              file && (
                <li key={index} className={!category ? "required-on" : ""}>
                  <img
                    src={
                      selectIcon(file.type)
                        ? selectIcon(file.type)
                        : URL.createObjectURL(file)
                    }
                    alt={file.name}
                  />

                  <div className="fileInfo">
                    <div>
                      <strong style={{ wordWrap: "break-word" }}>
                        {file.name}
                      </strong>
                      <span>{filesize(file.size)}</span>
                    </div>

                    <div className="action-file">
                      <div className="field-file">
                        <label htmlFor="category">Tipo:</label>
                        <select
                          name={`category-${index}`}
                          onChange={onChangeSelect}
                          value={category}
                          className={!category ? "fileInfo-field-required" : ""}
                        >
                          <option value="">Selecionar</option>
                          {categoriesFile.map((item) => (
                            <option key={item.id} value={String(item.id)}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <button
                        type="button"
                        onClick={() => {
                          handleRemoveFile(index);
                        }}
                      >
                        <IoMdTrash size={30} />
                      </button>
                    </div>
                  </div>
                </li>
              )
          )}

        {fileQueryOver &&
          fileQueryOver.map(
            ({ category_file, name, size, mimetype, url }, index) => (
              <li
                key={index}
                className={!category_file.id ? "required-on" : ""}
              >
                <img
                  src={selectIcon(mimetype) ? selectIcon(mimetype) : url}
                  alt={name}
                />

                <div className="fileInfo">
                  <div>
                    <strong>{name}</strong>
                    <span>{filesize(size)}</span>
                  </div>

                  <div className="action-file">
                    <div className="field-file">
                      <label htmlFor="category">Tipo:</label>
                      <select
                        name={`category-${index}`}
                        onChange={onChangeSelect}
                        value={category_file.id}
                        disabled={true}
                        className={
                          !category_file.id ? "fileInfo-field-required" : ""
                        }
                      >
                        <option value="">Selecionar</option>
                        {categoriesFile.map((item) => (
                          <option key={item.id} value={String(item.id)}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </li>
            )
          )}
      </ContainerPreviews>
    </>
  );
};
