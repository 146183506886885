import styled from "styled-components";

export const Container = styled.div`
  grid-area: APP;
  padding: 10px 30px 6rem 30px;

  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;

  h3 {
    font-size: 18;
    color: #333;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0 0px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  header {
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;

    .containerActions {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      margin-left: 10px;

      .buttonActionDatasheet {
        background: transparent;
        padding: 5px 8px;

        border-radius: 6px;
        font-weight: bold;
        font-size: 14px;

        transition: background 0.3s;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            margin-right: 4px;
          }
        }

        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }
      }

      .buttonAction {
        background: transparent;
        padding: 5px 8px;

        border-radius: 6px;
        font-weight: bold;
        font-size: 14px;

        transition: background 0.3s;

        span {
          margin-left: 0.3rem;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }

    .arrowBack {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 10px;

      height: 30px;
      width: 30px;

      border-radius: 50%;

      transition: all 0.4s;

      &:hover {
        background-color: #ccc;
      }
    }

    .reportDownload {
      margin-left: 20px;

      span {
        color: #259457;
        font-size: 12px;
      }
    }

    position: relative;

    .infoUpdate {
      position: absolute;
      top: 0px;
      right: 0px;

      span {
        font-size: 0.8rem;
        color: #555;
        font-weight: 300;
      }
    }

    @media (max-width: 1015px) {
      margin-left: 0px;
      justify-content: space-between;

      .reportDownload {
        margin-left: 0;
      }

      .infoUpdate {
        position: absolute;
        top: 30px;
        right: 0px;
      }
    }

    @media (max-width: 530px) {
      margin-left: 0px;
      justify-content: space-between;

      .reportDownload {
        margin-left: 0;

        .text-execel {
          display: none;
        }
      }

      .infoUpdate {
        position: absolute;
        top: 30px;
        right: 0px;
      }
    }
  }
`;

export const ScreenLoading = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
