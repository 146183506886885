import styled from 'styled-components';
import {Form as  FormDevolution, ButtunSubmit as ButtonDevolution, Error as ErrorDevolution } from '../create/styles';

export const Container = styled.div`
  grid-area: APP;
  padding: 10px 30px;

  height: 100%;  
  max-height: 100vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  header {
    width: 100%;
    background: #ccc;
    padding: 8px 15px;
    color: #333;
    border-radius: 4px 4px 0 0 ;

    h2 {
      font-weight: 500; 
    }
  }
`;

export const ContainerForm = styled.div`
  width: 100%;
  background: #fff;
  padding: 10px 20px;
`;

export const Form = styled(FormDevolution)`
  overflow-y: visible;
  max-height: 100%;
  width: 100%;

  .field{
    width: 100%;

    input {
      height: 35px;
    }
  }
`;

export const ButtunSubmit = styled(ButtonDevolution)`
  width: 100%;
  margin-bottom: 0;
`;

export const Error = styled(ErrorDevolution)`
`;