import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiBadgeCheck } from "react-icons/hi";
import { IoReload } from "react-icons/io5";
import {
  RiAlertFill,
  RiCloseCircleFill,
  RiFileExcel2Fill,
} from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { panelTypesDefault } from "../../../@types/panelTypes";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { TableStatus } from "../../../components/panel/TableStatus";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import { ClientFilters } from "../../../hook/queries/useClients";
import {
  EnchentesRs,
  getClientsEnchenteRSOperator,
  useClientsEnchenteRSOperator,
} from "../../../hook/queries/useClientsEnchteRS";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import * as sellerLocalStorage from "../../../service/localStorage/seller";
import { generateXlsx } from "../../../utils/generateXlsx";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

interface PanelColorsProps extends panelTypesDefault {}

const statusTable = [
  {
    label: "Pendente",
    value: "pending",
    icon: {
      icon: RiAlertFill,
      props: {
        size: 26,
        color: "#e9c912",
      },
    },
  },
  {
    label: "Não reabrirá",
    value: "notReopen",
    icon: {
      icon: RiCloseCircleFill,
      props: {
        size: 26,
        color: "#d21e26",
      },
    },
  },
  {
    label: "Reabrirá",
    value: "reopen",
    icon: {
      icon: HiBadgeCheck,
      props: {
        size: 26,
        color: "#21b543",
      },
    },
  },
];

const statusNormalized = (row: EnchentesRs) =>
  row.client_pj.client_enchete_rs?.length === 0
    ? "pending"
    : row.client_pj.client_enchete_rs?.filter((f) => f.reopening === true)
        .length === row.client_pj.client_enchete_rs?.length
    ? "reopen"
    : "notReopen";

const columns: FieldProps<EnchentesRs>[] = [
  {
    name: "icon",
    field: "",
    render(row) {
      const normalizedStatus = statusNormalized(row);

      const findStatus = statusTable.find((f) => f.value === normalizedStatus);

      if (findStatus)
        return (
          <span>{<findStatus.icon.icon {...findStatus.icon.props} />}</span>
        );
    },
    viewList: true,
  },
  {
    name: "brand",
    field: "MARCA",
    viewList: true,
    render: (row) => <span>{row?.brand.name}</span>,
  },
  {
    name: "seller",
    field: "VENDEDOR",
    viewList: true,
    render: (row) => (
      <span>{`${row?.seller.cod} - ${row?.seller.abbreviation}`}</span>
    ),
  },
  {
    name: "cod",
    field: "CÓD. CLIENTE",
    viewList: true,
    render: (row) => <span>{row?.client_pj?.cod}</span>,
  },
  {
    name: "company_name",
    field: "RAZÃO SOCIAL",
    viewList: true,
    render: (row) => <span>{row?.client_pj?.company_name}</span>,
  },
  {
    name: "cnpj",
    field: "CNPJ",
    viewList: true,
    render: (row) => <span>{row?.client_pj?.cnpj}</span>,
  },
  {
    name: "city",
    field: "CIDADE",
    viewList: true,
    render: (row) => <span>{row?.client_pj?.adresses?.city}</span>,
  },
  {
    name: "neighborhood",
    field: "BAIRRO",
    viewList: true,
    render: (row) => <span>{row?.client_pj?.adresses?.neighborhood}</span>,
  },

  {
    name: "enchentesStatus",
    field: "Situação",
  },
  {
    name: "brandId",
    field: "Marca",
  },
  {
    name: "sellerCod",
    field: "Vendedor",
  },
];

const PanelEnchestesRs: React.FC<PanelColorsProps> = ({ seller }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const query = useQueryParams();

  const sellerStorage =
    sellerLocalStorage.getUser() as sellerLocalStorage.SellerProps;

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });
  const [filters, setFilters] = useState<ClientFilters>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });

  const [viewFilter, setViewFilter] = useState(false);
  const { data, isLoading, isFetching, refetch } = useClientsEnchenteRSOperator(
    page,
    {
      ...filters,
      uf: "RS",
      hasOrder: true,
      orderType: "venda-futura",
      billingOrderPeriod: 3,
      billingOrderStartDate: "2024-05-01",
      billingOrderEndDate: "2024-07-30",
      brandId: seller ? Number(sellerStorage.brands?.[0]) : filters.brandId,
    }
  );

  const formikFilter = useFormik({
    initialValues: {
      cod: "",
      company_name: "",
      cnpj: "",
      city: "",
      neighborhood: "",
      enchentesStatus: "",
    },
    onSubmit: (data) => {
      handleFilter(data);
    },
  });

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  function handleFilter(data: ClientFilters) {
    setFilters(data);
    setPage(1);
    setViewFilter(false);
  }

  async function handleExportList() {
    addToast("Gerando...", {
      appearance: "info",
      autoDismiss: true,
    });

    const getAllClients = await getClientsEnchenteRSOperator(
      1,
      {
        ...filters,
        uf: "RS",
        hasOrder: true,
        orderType: "venda-futura",
        billingOrderPeriod: 3,
        billingOrderStartDate: "2024-05-01",
        billingOrderEndDate: "2024-07-30",
      },
      99999
    );

    let data = [];
    for (const enchente of getAllClients.clients) {
      const maio =
        enchente?.client_pj.client_enchete_rs?.[0]?.invoces_period.find(
          (f) => f.period === "05-2024"
        );
      const junho =
        enchente?.client_pj.client_enchete_rs?.[0]?.invoces_period.find(
          (f) => f.period === "06-2024"
        );
      const julho =
        enchente?.client_pj.client_enchete_rs?.[0]?.invoces_period.find(
          (f) => f.period === "07-2024"
        );

      let row: any = {
        MARCA: enchente?.brand?.name ?? "-",
        REPRESENTANTE: enchente?.seller
          ? `${enchente?.seller?.cod}-${enchente?.seller?.abbreviation}`
          : "-",
        CÓD: enchente.client_pj.cod,
        "RAZÃO SOCIAL": enchente.client_pj.company_name,
        CNPJ: enchente.client_pj.cnpj,
        CIDADE: enchente.client_pj.adresses?.city,
        BAIRRO: enchente.client_pj.adresses?.neighborhood,
        "TELEFONE 1": enchente.client_pj.phone,
        "TELEFONE 2": enchente.client_pj.phone2,
        CELULAR: enchente.client_pj.cellPhone,
        SITUAÇÃO: statusTable.find(
          (f) => f.value === statusNormalized(enchente)
        )?.label,

        "PREVISÃO DE ABERTURA":
          enchente.client_pj.client_enchete_rs?.[0]?.reopening === true
            ? moment(
                enchente.client_pj.client_enchete_rs?.[0]?.openingForecast
              ).format("DD/MM/yyyy")
            : "-",

        "FUTURAR EM MAIO 2024":
          enchente.client_pj.client_enchete_rs?.[0]?.reopening === false && maio
            ? maio?.invoice === true
              ? "SIM"
              : "NÃO"
            : "-",
        "FUTURAR EM JUNHO 2024":
          enchente.client_pj.client_enchete_rs?.[0]?.reopening === false &&
          junho
            ? junho?.invoice === true
              ? "SIM"
              : "NÃO"
            : "-",
        "FUTURAR EM JULHO 2024":
          enchente.client_pj.client_enchete_rs?.[0]?.reopening === false &&
          julho
            ? julho?.invoice === true
              ? "SIM"
              : "NÃO"
            : "-",
      };

      data.push(row);
    }

    await generateXlsx<any>({
      filename: `Exportar gestão clientes RS`,
      data: data ?? [],
    });
  }

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de Clientes Rio Grande do Sul" />

      <TableStatus
        title="SITUAÇÕES"
        data={statusTable}
        onPress={(status) => {
          setFilters((old) => ({ ...old, enchentesStatus: status }));
        }}
      />

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={(value, label) => {
          switch (label) {
            case "enchentesStatus":
              return statusTable.find((f) => f.value === value)?.label;
            case "brandId":
              return data?.filters.brand.find(
                (f) => String(f.id) === String(value)
              )?.name;
            case "sellerCod":
              return data?.filters.seller.find(
                (f) => String(f.id) === String(value)
              )?.name;

            default:
              return value;
          }
        }}
      >
        <GroupInput>
          <Input label="Cód" {...formikFilter.getFieldProps("cod")} />
          <Input
            label="Razão social"
            {...formikFilter.getFieldProps("company_name")}
          />
          <Input label="CNPJ" {...formikFilter.getFieldProps("cnpj")} />
        </GroupInput>
        <GroupInput>
          <Select
            label="Cidade"
            {...formikFilter.getFieldProps("city")}
            data={
              data?.filters.city.map((city) => ({
                name: city.name,
                value: city.id,
              })) ?? []
            }
          />
          <Select
            label="Bairro"
            {...formikFilter.getFieldProps("neighborhood")}
            data={
              data?.filters.neighborhood.map((neighborhood) => ({
                name: neighborhood.name,
                value: neighborhood.id,
              })) ?? []
            }
          />
        </GroupInput>
        <GroupInput>
          <Select
            label="Vendedor"
            {...formikFilter.getFieldProps("sellerCod")}
            data={
              data?.filters.seller.map((seller) => ({
                name: seller.name,
                value: seller.id,
              })) ?? []
            }
          />

          {!seller && (
            <Select
              label="Marca"
              {...formikFilter.getFieldProps("brandId")}
              data={
                data?.filters.brand.map((brand) => ({
                  name: brand.name,
                  value: brand.id,
                })) ?? []
              }
            />
          )}
        </GroupInput>
      </PanelFilter>

      <TableHeader title="CLIENTES" isFetching={isFetching && !isLoading}>
        <button style={{ background: "#1d6f42" }} onClick={handleExportList}>
          <RiFileExcel2Fill size={19} />
          <span>Exportar</span>
        </button>
        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>
      </TableHeader>

      <TableContent<EnchentesRs>
        data={data?.clients}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) =>
          history.push(
            seller
              ? `/representante/manager/enchestes-rs/${e.client_pj.id}/${e.seller.cod}/${e.brand.name}`
              : `/admin/enchestes-rs/${e.client_pj.id}/${e.seller.cod}/${e.brand.name}`
          )
        }
      />
      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={15}
      />
    </PanelLayout>
  );
};

export default PanelEnchestesRs;
