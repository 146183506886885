import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Color = {
  id: number;
  cod: number;
  description: string;
  hex: string;
  created_at: Date;
};

export type ColorFilters = {
  cod?: string;
  description?: string;
  hex?: string;
};

type GetColorsResponse = {
  colors: Color[];
  totalCount: number;
};

interface ColorApiResponse extends ResponsePanelApiDefault {
  contents: Color[];
}

export async function getColors(
  page: number,
  filter?: ColorFilters,
  registersPerPag?: number
): Promise<GetColorsResponse> {
  const { data } = await api.get<ColorApiResponse>("/populate/colors", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag, ...filter },
  });

  const colors = data.contents;
  const totalCount = data.total;

  return {
    totalCount,
    colors,
  };
}

export function useColors(
  page: number,
  filter?: ColorFilters,
  registersPerPag?: number
): UseQueryResult<GetColorsResponse> {
  return useQuery(
    ["colors", page, filter],
    () => getColors(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneColor(id?: number): Promise<Color | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<Color>(`/populate/colors/show/${id}`);

  return data;
}

export function useOneColor(id?: number): UseQueryResult<Color> | undefined {
  return useQuery(["color", id], () => getOneColor(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
