import styled from "styled-components";

export const ModalContainer = styled.div`
  height: 100%;
  width: 100%;

  display: grid;

  grid-template-columns: auto;
  grid-template-rows: 50px auto;

  grid-template-areas:
    "HEAD"
    "BODY";

  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  .body {
    grid-area: BODY;
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      height: 6px;
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #333;
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: #eee;
    }
  }

  .field {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-bottom: 24px;
    /* padding: 0 10px; */
    position: relative;

    .alertIcon {
      color: #d21e26;

      position: absolute;
      right: 40px;
      top: 8px;
      font-size: 20px;
    }

    label {
      font-size: 14px;
      margin-bottom: 4px;
      color: #555;

      span {
        color: #dba607;
      }

      .required {
        color: #f00;
        font-size: 11px;
      }
    }
    input {
      padding: 0 10px;
      background: #fff;
      height: 35px;
      font-size: 16px;
      color: #000;
      border: 2px solid #aaa;
      border-radius: 5px;
    }

    .selectClient {
      height: 40px;
      width: 100%;

      button {
        width: 100%;
        height: 100%;
        background: #d21e26;

        font-weight: bold;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
      }
    }

    input:focus {
      border: 2px solid #333;
    }

    input::placeholder {
      color: #a0a0b2;
    }

    textarea {
      padding: 10px 10px;
      background: #fff;
      height: 80px;
      font-size: 16px;
      color: #000;
      border: 2px solid #aaa;
      border-radius: 5px;
      resize: vertical;

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-thumb {
        background: #333;
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background: #888;
      }

      &:focus {
        border: 2px solid #333;
      }
    }

    select {
      height: 35px;

      background: #fff;
      border-radius: 5px;
      border: 2px solid #aaa;
      padding: 4px 8px;

      font-size: 16px;
      color: #aaa;

      cursor: pointer;
      /* -webkit-appearance: none; */
      -moz-appearance: none;
      /* appearance: none;  */

      &:focus {
        border: 2px solid #333;
        color: #333;
      }
    }
  }

  @keyframes fadeOpacity {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeScale {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }
`;

export const HeaderModal = styled.div`
  grid-area: HEAD;
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  height: 100%;

  font-size: 18px;
  /* border-bottom: 2px solid rgba(0, 0, 0, 0.5); */

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .closeModal {
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.2);
      transform: scale(1.1);
    }
  }
`;
