import { AxiosError } from "axios";
import fileSize from "filesize";
import React, { useEffect, useState } from "react";
import { BsFileEarmarkCheck } from "react-icons/bs";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { MdOutlineFileDownload } from "react-icons/md";
import { RiUserFollowFill, RiUserUnfollowLine } from "react-icons/ri";

import { IPermissionUser } from "../../../@types";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import FloatButton from "../../../components/FloatButton";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { Textarea } from "../../../components/Form/Textarea";
import { ImageClient } from "../../../components/ImageClient";
import { InfoCards } from "../../../components/InfoCards";
import Loading from "../../../components/Loading";
import LoadingDot from "../../../components/LoadingDot";
import Modal from "../../../components/ModalLib";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Concept } from "../../../hook/queries/useClients";
import { useOneRequestUpdateClient } from "../../../hook/queries/useRequestUpdateClient";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { compareValues } from "../../../utils/compareValues";
import { downloadFilaBase64 } from "../../../utils/downloadFilaBase64";
import { BoxInfo, PopUpConfirmation } from "../styles";

type Props = {
  permission: IPermissionUser;
};

export interface IFile {
  id: string;
  name: string;
  size: number;
  storeTypeImage: string;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype?: string;
  fileBase64?: string;
  clientImageUpdateRequestDate?: Date;
}

function RequestUpdateClient({ permission }: Props) {
  const match = useRouteMatch<{ id?: string }>();
  const history = useHistory();
  const { addToast } = useToasts();
  const { isLoading, data } = useOneRequestUpdateClient(
    Number(match.params.id)
  );

  const [isModalOpen, setIsModalOpen] = useState<"refuse" | "approve">();
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [observation, setObservation] = useState("");
  const [newConcept, setNewConcept] = useState<Concept>();
  const [isErrorModal, setIsErrorModal] = useState("");
  const [concepts, setConcepts] = useState<Concept[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(true);
  const [alertRegisterClientCode, setAlertRegisterClientCode] = useState<
    number | undefined
  >();

  var headerCards = [
    {
      title: "Situação",
      value: data?.status?.name ?? "-",
      colorLine: data?.status?.color,
    },
    {
      title: "Tipo",
      value: data?.typeFormatted ?? "-",
    },
    {
      title: "Envido por",
      value: data?.typeUserFormatted ?? "-",
    },
    {
      title: "Criação",
      value: data?.created_atFormatted ?? "-",
    },
  ];

  if (data?.status?.is_finisher) {
    headerCards.push({
      title: "Finalização",
      value: data?.finished_atFormatted ?? "-",
    });
  }

  async function handleRefuse() {
    if (!observation) {
      setIsErrorModal("Observação é obrigatório");
      return addToast("Observação é obrigatório", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
    setIsLoadingModal(true);

    try {
      await api.patch(`/request-update-client/refuse/${data?.id}`, {
        observation: observation,
      });

      queryClient.invalidateQueries("RequestUpdateClients");
      queryClient.invalidateQueries(["RequestUpdateClient", data?.id]);

      handleCloseModal();
      addToast("Cliente recusado com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
      history.goBack();
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setIsLoadingModal(false);
    }
  }

  async function handleApprove() {
    if (!newConcept && data?.type !== "update") {
      setIsErrorModal("Informar o conceito é obrigatório");
      return addToast("Informar o conceito é obrigatório", {
        appearance: "warning",
        autoDismiss: true,
      });
    }

    setIsLoadingModal(true);

    try {
      const response = await api.patch<{ client?: { code: number } }>(
        `/request-update-client/approve/${data?.id}`,
        {
          conceptCod: newConcept?.cod,
        }
      );

      queryClient.invalidateQueries("RequestUpdateClients");
      queryClient.invalidateQueries(["RequestUpdateClient", data?.id]);

      handleCloseModal();
      addToast("Atualização cadastral realizada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });

      if (response.data?.client?.code) {
        setAlertRegisterClientCode(response.data.client.code);
      } else {
        history.goBack();
      }
    } catch (error) {
      const err = error as AxiosError;

      if (err?.response?.status === 400) {
        return addToast(err?.response?.data?.message, {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setIsLoadingModal(false);
    }
  }

  function handleDownloadAllFiles() {
    if (!data) return;

    let i = 0;

    for (const file of files) {
      i++;

      const splitMimetype = file.mimetype?.split("/");
      const mimetype =
        splitMimetype && splitMimetype?.length > 1 ? splitMimetype[1] : "jpg";

      const clientCod =
        data?.type === "register"
          ? data.client_pj_duplicate?.cnpj
          : data.client_pj?.cod;
      const filename = `${clientCod} (${i}).${mimetype}`;

      downloadFilaBase64({
        base64: file.fileBase64 ?? "",
        filename: filename,
        mimetype: mimetype,
      });
    }
  }

  function handleSelectConcept(codigo: number) {
    const find = concepts.find((c) => Number(c.cod) === Number(codigo));
    if (find) setNewConcept(find);
  }

  function handleCloseModal() {
    setNewConcept(undefined);
    setObservation("");
    setIsErrorModal("");
    setIsModalOpen(undefined);
  }

  useEffect(() => {
    (async () => {
      const conceptRequest = await api.get<{ contents: Concept[] }>("concept");
      setConcepts(conceptRequest.data.contents);

      const response = await api.get<IFile[]>(
        `/request-update-client/show-file/${match.params.id}`
      );
      setFiles(response.data);

      setIsLoadingFile(false);
    })();
  }, [match.params.id]);

  return (
    <>
      <CreateAndDetailLayout isLoading={isLoading || isLoadingFile}>
        <PanelAndDetailAndCreateHeader title={"Atualização cadastral"} goBack />

        <InfoCards cards={headerCards} />

        {data?.type === "image" && (
          <BoxInfo>
            <div style={{ marginBottom: "1rem" }}>
              <h4 style={{ marginBottom: 0 }}>
                {data?.client_pj?.company_name}
              </h4>
              <span className="tag">{data?.client_pj?.cnpjFormatted}</span>
            </div>
            <p>
              <b>Código:</b> <span>{data?.client_pj?.cod}</span>
            </p>
            <p>
              <b>Conceito:</b>{" "}
              <span>{data?.client_pj?.concept?.description ?? "-"}</span>
            </p>
            <p>
              <b>Telefone:</b> <span>{data?.client_pj?.phone}</span>
            </p>
            <p>
              <b>Email:</b> <span>{data?.client_pj?.email}</span>
            </p>

            <p>
              <b>Endereço: </b>
              <span>
                {`${data?.client_pj?.adresses?.street}, ${data?.client_pj?.adresses?.numberStreet} - ${data?.client_pj?.adresses?.neighborhood}, ${data?.client_pj?.adresses?.city} - ${data?.client_pj?.adresses?.uf}, ${data?.client_pj?.adresses?.zip_codeFormat}`}
              </span>
            </p>
          </BoxInfo>
        )}

        {data?.resposable && (
          <BoxInfo>
            <div style={{ marginBottom: "1rem" }}>
              <h3 style={{ marginBottom: 0 }}>
                {data.resposable.name} {data.resposable.lastname}
              </h3>
              <span className="tag">Responsável</span>
            </div>

            <p>
              <b>Email:</b> <span>{data?.resposable.email}</span>
            </p>

            {data?.concept && (
              <p>
                <b>Conceito atualizado:</b>{" "}
                <span>
                  {data?.concept.cod + " " + data?.concept.description}
                </span>
              </p>
            )}

            {data.observation && (
              <p>
                <b>Observação:</b>
                <Textarea
                  name="obsView"
                  defaultValue={data.observation}
                  readOnly
                />
              </p>
            )}
          </BoxInfo>
        )}

        {data?.seller?.cod && (
          <BoxInfo>
            <div style={{ marginBottom: "1rem" }}>
              <h4 style={{ marginBottom: "0" }}>
                {data.seller.cod} {data.seller.abbreviation}
              </h4>
              <span className="tag">Representante (Enviou solicitação)</span>
            </div>

            <p>
              <b>Nome:</b> <span>{data.seller.fullName}</span>
            </p>
            <p>
              <b>Email:</b> <span>{data.seller.email}</span>
            </p>

            {data.seller?.allOfficeSeller?.[0] && (
              <>
                <h5 style={{ marginTop: "1rem" }}>Escritório</h5>
                <ul>
                  {data.seller?.allOfficeSeller.map((item) => (
                    <li>
                      {item.cod} - {item.abbreviation}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </BoxInfo>
        )}

        {data?.user?.id && (
          <BoxInfo>
            <div style={{ marginBottom: "1rem" }}>
              <h4 style={{ marginBottom: "0" }}>
                {data.user.name} {data.user.lastname}
              </h4>
              <span className="tag">Operador (Enviou solicitação)</span>
            </div>

            <p>
              <b>Permissionamento:</b> <span>{data.user.permission.name}</span>
            </p>
            <p>
              <b>Email:</b> <span>{data.user.email}</span>
            </p>
          </BoxInfo>
        )}

        {data?.type === "register" && (
          <BoxInfo>
            <h3>Novo cliente</h3>

            {data?.client_pj_duplicate?.cod && (
              <Input
                readOnly
                label="Código"
                name="cod"
                value={data?.client_pj_duplicate?.cod}
                isCopyClipboard
              />
            )}

            <GroupInput>
              <Input
                readOnly
                label="CNPJ"
                name="cnpj"
                value={data?.client_pj_duplicate?.cnpjFormatted}
                isCopyClipboard
              />
            </GroupInput>

            <GroupInput>
              <Input
                readOnly
                label="Razão social"
                name="company_name"
                value={data?.client_pj_duplicate?.company_name}
                isCopyClipboard
              />
              <Input
                readOnly
                label="Nome fantasia"
                name="trade_name"
                value={data?.client_pj_duplicate?.trade_name}
                isCopyClipboard
              />
            </GroupInput>

            <GroupInput>
              <Input
                readOnly
                label="Telefone 1"
                name="phone"
                value={data?.client_pj_duplicate?.phone}
                isCopyClipboard
              />
              <Input
                readOnly
                label="Telefone 2"
                name="phone2"
                value={data?.client_pj_duplicate?.phone2}
                isCopyClipboard
              />
            </GroupInput>

            <Input
              readOnly
              label="Celular"
              name="cellPhone"
              value={data?.client_pj_duplicate?.cellPhone}
              isCopyClipboard
            />

            <GroupInput>
              <Input
                readOnly
                label="Email"
                name="emailAccountant"
                value={data?.client_pj_duplicate?.emailAccountant}
                isCopyClipboard
              />
              <Input
                readOnly
                label="Email NFE"
                name="emailInvoice"
                value={data?.client_pj_duplicate?.emailInvoice}
                isCopyClipboard
              />
            </GroupInput>

            <GroupInput>
              <Input
                readOnly
                label="Suframa"
                name="suframa"
                value={data?.client_pj_duplicate?.suframa}
                isCopyClipboard
              />
              <Input
                readOnly
                label="Inscrição estadual"
                name="stateRegistration"
                value={data?.client_pj_duplicate?.stateRegistration}
                isCopyClipboard
              />
            </GroupInput>

            <Textarea
              readOnly
              label="Observação"
              name="observation"
              value={data?.client_pj_duplicate?.observation}
            />

            <h4>Endereço</h4>
            <Input
              readOnly
              label="CEP"
              name="zip_code"
              value={data?.client_pj_duplicate?.address?.zip_codeFormat}
              isCopyClipboard
            />

            <GroupInput>
              <Input
                readOnly
                label="UF"
                name="adress.uf"
                value={data.client_pj_duplicate?.address?.uf}
                isCopyClipboard
              />
              <Input
                readOnly
                label="Cidade"
                name="adress.city"
                value={data.client_pj_duplicate?.address?.city}
                isCopyClipboard
              />
            </GroupInput>

            <GroupInput>
              <Input
                readOnly
                label="Bairro"
                name="adress.neighborhood"
                value={data.client_pj_duplicate?.address?.neighborhood}
                isCopyClipboard
              />
              <Input
                readOnly
                label="Logradouro"
                name="adress.street"
                value={data.client_pj_duplicate?.address?.street}
                isCopyClipboard
              />
            </GroupInput>

            <GroupInput>
              <Input
                readOnly
                label="Número"
                name="adress.numberStreet"
                value={data.client_pj_duplicate?.address?.numberStreet}
                isCopyClipboard
              />

              <Input
                readOnly
                label="Complemento"
                name="adress.complement"
                value={data.client_pj_duplicate?.address?.complement}
                isCopyClipboard
              />
            </GroupInput>

            {data.client_pj_duplicate?.contacts?.[0]?.id && (
              <>
                <h4>Contatos</h4>
                <ul>
                  {data.client_pj_duplicate?.contacts?.map((contact) => (
                    <li key={contact?.id}>{contact?.name}</li>
                  ))}
                </ul>
              </>
            )}
          </BoxInfo>
        )}

        {data?.type === "update" && (
          <div style={{ display: "flex", columnGap: "1rem" }}>
            <BoxInfo>
              <h3>Cliente atual</h3>

              <Input
                readOnly
                label="Código"
                name="cod"
                value={data?.client_pj?.cod}
              />

              <GroupInput>
                <Input
                  readOnly
                  label="CNPJ"
                  name="cnpj"
                  value={data?.client_pj?.cnpjFormatted}
                />
              </GroupInput>

              <GroupInput>
                <Input
                  readOnly
                  label="Razão social"
                  name="company_name"
                  value={data?.client_pj?.company_name}
                />
                <Input
                  readOnly
                  label="Nome fantasia"
                  name="trade_name"
                  value={data?.client_pj?.trade_name}
                />
              </GroupInput>

              <Input
                readOnly
                label="Encarregado"
                name="overseer_name"
                value={data?.client_pj?.overseer_name}
              />

              <GroupInput>
                <Input
                  readOnly
                  label="Telefone 1"
                  name="phone"
                  value={data?.client_pj?.phone}
                />
                <Input
                  readOnly
                  label="Telefone 2"
                  name="phone2"
                  value={data?.client_pj?.phone2}
                />
              </GroupInput>

              <Input
                readOnly
                label="Celular"
                name="cellPhone"
                value={data?.client_pj?.cellPhone}
              />

              <GroupInput>
                <Input
                  readOnly
                  label="Email"
                  name="emailAccountant"
                  value={data?.client_pj?.email}
                />
                <Input
                  readOnly
                  label="Email NFE"
                  name="emailInvoice"
                  value={data?.client_pj?.emailInvoice}
                />
              </GroupInput>

              <GroupInput>
                <Input
                  readOnly
                  label="Suframa"
                  name="suframa"
                  value={data?.client_pj?.suframa}
                />
                <Input
                  readOnly
                  label="Inscrição estadual"
                  name="stateRegistration"
                  value={data?.client_pj?.stateRegistration}
                />
              </GroupInput>

              <h4>Endereço</h4>
              <Input
                readOnly
                label="CEP"
                name="zip_code"
                value={data?.client_pj?.adresses?.zip_codeFormat}
              />

              <GroupInput>
                <Input
                  readOnly
                  label="UF"
                  name="adress.uf"
                  value={data.client_pj?.adresses?.uf}
                />
                <Input
                  readOnly
                  label="Cidade"
                  name="adress.city"
                  value={data.client_pj?.adresses?.city}
                />
              </GroupInput>

              <GroupInput>
                <Input
                  readOnly
                  label="Bairro"
                  name="adress.neighborhood"
                  value={data.client_pj?.adresses?.neighborhood}
                />
                <Input
                  readOnly
                  label="Logradouro"
                  name="adress.street"
                  value={data.client_pj?.adresses?.street}
                />
              </GroupInput>

              <GroupInput>
                <Input
                  readOnly
                  label="Número"
                  name="adress.numberStreet"
                  value={data.client_pj?.adresses?.numberStreet}
                />

                <Input
                  readOnly
                  label="Complemento"
                  name="adress.complement"
                  value={data.client_pj?.adresses?.complement}
                />
              </GroupInput>
            </BoxInfo>
            <BoxInfo>
              <h3>Alterações</h3>

              <Input
                readOnly
                isCopyClipboard
                label="Código"
                name="cod"
                value={data?.client_pj?.cod}
              />

              <GroupInput>
                <Input
                  readOnly
                  label="CNPJ"
                  name="cnpj"
                  value={data?.client_pj_duplicate?.cnpjFormatted}
                  isCopyClipboard
                  isHighlighter={
                    data?.client_pj?.cnpjFormatted !==
                    data?.client_pj_duplicate?.cnpjFormatted
                  }
                />
              </GroupInput>

              <GroupInput>
                <Input
                  readOnly
                  label="Razão social"
                  name="company_name"
                  value={data?.client_pj_duplicate?.company_name}
                  isCopyClipboard
                  isHighlighter={
                    data?.client_pj?.company_name !==
                    data?.client_pj_duplicate?.company_name
                  }
                />
                <Input
                  readOnly
                  label="Nome fantasia"
                  name="trade_name"
                  value={data?.client_pj_duplicate?.trade_name}
                  isCopyClipboard
                  isHighlighter={
                    data?.client_pj?.trade_name !==
                    data?.client_pj_duplicate?.trade_name
                  }
                />
              </GroupInput>

              <Input
                readOnly
                label="Encarregado"
                name="overseer_name"
                value={data?.client_pj_duplicate?.overseer_name}
                isCopyClipboard
                isHighlighter={
                  data?.client_pj?.overseer_name !==
                  data?.client_pj_duplicate?.overseer_name
                }
              />

              <GroupInput>
                <Input
                  readOnly
                  label="Telefone 1"
                  name="phone"
                  value={data?.client_pj_duplicate?.phone}
                  isCopyClipboard
                  isHighlighter={
                    data?.client_pj?.phone !== data?.client_pj_duplicate?.phone
                  }
                />
                <Input
                  readOnly
                  label="Telefone 2"
                  name="phone2"
                  value={data?.client_pj_duplicate?.phone2}
                  isCopyClipboard
                  isHighlighter={
                    !compareValues(
                      data?.client_pj?.phone2,
                      data?.client_pj_duplicate?.phone2
                    )
                  }
                />
              </GroupInput>

              <Input
                readOnly
                label="Celular"
                name="cellPhone"
                value={data?.client_pj_duplicate?.cellPhone}
                isCopyClipboard
                isHighlighter={
                  !compareValues(
                    data?.client_pj?.cellPhone,
                    data?.client_pj_duplicate?.cellPhone
                  )
                }
              />

              <GroupInput>
                <Input
                  readOnly
                  label="Email"
                  name="emailAccountant"
                  value={data?.client_pj_duplicate?.emailAccountant}
                  isCopyClipboard
                  isHighlighter={
                    !compareValues(
                      data?.client_pj?.email,
                      data?.client_pj_duplicate?.emailAccountant
                    )
                  }
                />
                <Input
                  readOnly
                  label="Email NFE"
                  name="emailInvoice"
                  value={data?.client_pj_duplicate?.emailInvoice}
                  isCopyClipboard
                  isHighlighter={
                    !compareValues(
                      data?.client_pj?.emailInvoice,
                      data?.client_pj_duplicate?.emailInvoice
                    )
                  }
                />
              </GroupInput>

              <GroupInput>
                <Input
                  readOnly
                  label="Suframa"
                  name="suframa"
                  value={data?.client_pj_duplicate?.suframa}
                  isCopyClipboard
                  isHighlighter={
                    !compareValues(
                      data?.client_pj?.suframa,
                      data?.client_pj_duplicate?.suframa
                    )
                  }
                />
                <Input
                  readOnly
                  label="Inscrição estadual"
                  name="stateRegistration"
                  value={data?.client_pj_duplicate?.stateRegistration}
                  isCopyClipboard
                  isHighlighter={
                    !compareValues(
                      data?.client_pj?.stateRegistration,
                      data?.client_pj_duplicate?.stateRegistration
                    )
                  }
                />
              </GroupInput>

              <h4>Endereço</h4>
              <Input
                readOnly
                label="CEP"
                name="zip_code"
                value={data?.client_pj_duplicate?.address?.zip_codeFormat}
                isCopyClipboard
                isHighlighter={
                  data?.client_pj?.adresses?.zip_codeFormat !==
                  data?.client_pj_duplicate?.address?.zip_codeFormat
                }
              />

              <GroupInput>
                <Input
                  readOnly
                  label="UF"
                  name="adress.uf"
                  value={data.client_pj_duplicate?.address?.uf}
                  isCopyClipboard
                  isHighlighter={
                    data?.client_pj?.adresses?.uf !==
                    data?.client_pj_duplicate?.address?.uf
                  }
                />
                <Input
                  readOnly
                  label="Cidade"
                  name="adress.city"
                  value={data.client_pj_duplicate?.address?.city}
                  isCopyClipboard
                  isHighlighter={
                    data?.client_pj?.adresses?.city !==
                    data?.client_pj_duplicate?.address?.city
                  }
                />
              </GroupInput>

              <GroupInput>
                <Input
                  readOnly
                  label="Bairro"
                  name="adress.neighborhood"
                  value={data.client_pj_duplicate?.address?.neighborhood}
                  isCopyClipboard
                  isHighlighter={
                    data?.client_pj?.adresses?.neighborhood !==
                    data?.client_pj_duplicate?.address?.neighborhood
                  }
                />
                <Input
                  readOnly
                  label="Logradouro"
                  name="adress.street"
                  value={data.client_pj_duplicate?.address?.street}
                  isCopyClipboard
                  isHighlighter={
                    data?.client_pj?.adresses?.street !==
                    data?.client_pj_duplicate?.address?.street
                  }
                />
              </GroupInput>

              <GroupInput>
                <Input
                  readOnly
                  label="Número"
                  name="adress.numberStreet"
                  value={data.client_pj_duplicate?.address?.numberStreet}
                  isCopyClipboard
                  isHighlighter={
                    data?.client_pj?.adresses?.numberStreet !==
                    data?.client_pj_duplicate?.address?.numberStreet
                  }
                />

                <Input
                  readOnly
                  label="Complemento"
                  name="adress.complement"
                  value={data.client_pj_duplicate?.address?.complement}
                  isCopyClipboard
                  isHighlighter={
                    !compareValues(
                      data?.client_pj?.adresses?.complement,
                      data?.client_pj_duplicate?.address?.complement
                    )
                  }
                />
              </GroupInput>

              {data.client_pj_duplicate?.contacts?.[0]?.id && (
                <>
                  <h4>Contatos</h4>
                  <ul>
                    {data.client_pj_duplicate?.contacts?.map((contact) => (
                      <li key={contact?.id}>{contact?.name}</li>
                    ))}
                  </ul>
                </>
              )}
            </BoxInfo>
          </div>
        )}

        {files.length > 0 && (
          <BoxInfo>
            <div style={{ marginBottom: "1rem" }}>
              <h3>Imagens</h3>

              <span
                className="tag"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleDownloadAllFiles}
              >
                Baixar todos <MdOutlineFileDownload fontSize="1rem" />
              </span>
            </div>

            {isLoadingFile ? (
              <LoadingDot />
            ) : (
              <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                {files.map((item) => (
                  <ImageClient
                    key={item.id}
                    data={{
                      name: item.name,
                      uri: `data:image/png;base64,${item.fileBase64}`,
                      size: fileSize(item?.size) ?? "",
                      type: item?.storeTypeImage,
                    }}
                  />
                ))}
              </div>
            )}
          </BoxInfo>
        )}
      </CreateAndDetailLayout>

      {!isLoading &&
        !data?.status?.is_finisher &&
        permission?.requestUpdateClient > 2 && (
          <FloatButton Icon={BsFileEarmarkCheck} title="" notFloatButton>
            <li
              onClick={() => {
                setIsModalOpen("approve");
              }}
            >
              <span>Atualizar </span>
              <div
                style={{
                  background: "#41C57C",
                }}
              >
                <FaCheck color="#fff" size={18} />
              </div>
            </li>

            <li
              onClick={() => {
                setIsModalOpen("refuse");
              }}
            >
              <span>Recusa</span>
              <div
                style={{
                  background: "#F05978",
                }}
              >
                <IoClose color="#fff" size={28} />
              </div>
            </li>
          </FloatButton>
        )}

      <Modal
        modalVisible={!!isModalOpen}
        setModalVisible={handleCloseModal}
        maxHeight={"95%"}
        height={
          String(data?.type) === "update" && isModalOpen !== "refuse"
            ? 400
            : 500
        }
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              {isModalOpen === "refuse" ? (
                <RiUserUnfollowLine size={95} opacity={0.2} />
              ) : (
                <RiUserFollowFill size={95} opacity={0.2} />
              )}
            </nav>
            <span>
              {isModalOpen === "refuse"
                ? "Você tem certeza que deseja recusar as imagens do cliente"
                : String(data?.type) === "update"
                ? "Finalizar atualização do cliente"
                : "Informe o novo conceito do cliente"}
            </span>
          </div>

          <div style={{ padding: "0 3rem", width: "100%" }}>
            {isModalOpen === "approve" && data?.type !== "update" && (
              <Select
                label="Conceito"
                name="concept"
                data={concepts.map((c) => ({
                  value: c.cod,
                  name: `${c.cod} ${c.description}`,
                }))}
                error={isErrorModal}
                value={newConcept?.cod}
                onChange={(c) => handleSelectConcept(Number(c.target.value))}
                onClick={() => setIsErrorModal("")}
              />
            )}

            {isModalOpen === "refuse" && (
              <Textarea
                label="OBSERVAÇÃO"
                name="obs"
                defaultValue={observation}
                onChange={(e) => setObservation(e.target.value)}
                error={isErrorModal}
                onClick={() => setIsErrorModal("")}
              />
            )}
          </div>

          <div className="buttonsPopUp">
            <button type="button" onClick={handleCloseModal}>
              Cancelar
            </button>
            <button
              style={{
                background: isModalOpen === "refuse" ? "#F05978" : "#41C57C",
              }}
              type="button"
              onClick={() => {
                isModalOpen === "refuse" ? handleRefuse() : handleApprove();
              }}
            >
              {!isLoadingModal ? (
                isModalOpen === "refuse" ? (
                  "Recusar"
                ) : (
                  "Atualizar"
                )
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Loading
                    size={22}
                    borderSize={4}
                    colorLoading="rgba(255,255,255)"
                    borderColor="rgba(255,255,255, 0.3)"
                  />
                </div>
              )}
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        modalVisible={!!alertRegisterClientCode}
        setModalVisible={() => {
          setAlertRegisterClientCode(undefined);
          history.goBack();
        }}
        maxHeight={"95%"}
        height={300}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <RiUserFollowFill size={95} opacity={0.2} />
            </nav>
            <span>
              Código do cliente criado
              <span style={{ fontSize: "1.5rem" }}>
                {alertRegisterClientCode}
              </span>
            </span>
          </div>
        </PopUpConfirmation>
      </Modal>
    </>
  );
}

export default RequestUpdateClient;
