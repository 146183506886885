export const indemnities = [
  { title: "Defeito de Fábrica" },
  { title: "Defeito por uso" },
];

export const devolutions = [{ title: "Negociação comercial" }];

export const TypesAccount = [
  { title: "Conjunta" },
  { title: "Individual" },
  { title: "Poupança" },
  { title: "Poupança Conjunta" },
  { title: "Salário" },
  { title: "Conta jurídica" },
];

export const situationClient = (situation: number) => {
  if (situation === 1) return "Ativo";

  if (situation === 2) return "Bloqueado";

  if (situation === 3) return "inativo";
};
