import React, { useState } from "react";
import { LoadingPanel } from "../../pages/panel/styles";
import LoadingDot from "../loadings/LoadingDot";
import { Container } from "./styles";

interface TableEditableProps<T> {
  isLoading?: boolean;

  idEditableRow?: string;

  isSelectRows?: boolean;
  setSelectedRowsId?: (ids: string[]) => void;

  data: T[];
  colums: ColumConfigProps<T>[];
}

interface ColumConfigProps<T> {
  title: string;
  field: string;
  required?: boolean;

  render?: (row: T, key: any) => React.ReactNode;
  editableRow?: (row: T, key: any) => React.ReactNode;
}

export function TableEditableV2<T>({
  data,
  colums,
  idEditableRow,
  isLoading = false,
  isSelectRows = false,
}: TableEditableProps<T>) {
  const [selectedRowsId, setSelectedRowsId] = useState<string[]>([]);
  const [selectedAllRows, setSelectedAllRows] = useState(false);

  if (isLoading) {
    return (
      <LoadingPanel>
        <LoadingDot />
      </LoadingPanel>
    );
  }
  if (data.length < 1) {
    return (
      <Container>
        <div className="row-none">Não há registros a serem exibidos</div>
      </Container>
    );
  }

  return (
    <Container>
      <table>
        <thead>
          <tr>
            {isSelectRows && (
              <th>
                <input
                  type="checkbox"
                  name="selectRows"
                  checked={selectedAllRows}
                  onChange={() => {
                    setSelectedAllRows((oldData) => !oldData);
                  }}
                />
              </th>
            )}
            {colums.map((colum, index) => (
              <th key={index}>{colum.title}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {isSelectRows && (
                <td>
                  <input
                    type="checkbox"
                    name="selectRows"
                    checked={
                      //@ts-ignore
                      selectedRowsId.find((f) => f === row?.id) ? true : false
                    }
                    onChange={() => {
                      //@ts-ignore
                      if (row?.id) {
                        const findOne = selectedRowsId?.find(
                          //@ts-ignore
                          (f) => f === row?.id
                        );

                        if (findOne) {
                          setSelectedRowsId((oldData) =>
                            //@ts-ignore
                            oldData.filter((f) => f !== row.id)
                          );
                        } else {
                          //@ts-ignore
                          setSelectedRowsId((oldData) => [...oldData, row.id]);
                        }
                      }
                    }}
                  />
                </td>
              )}

              {colums.map((colum, index) => {
                if (
                  colum.editableRow &&
                  idEditableRow &&
                  //@ts-ignore
                  String(idEditableRow) === String(row?.id)
                ) {
                  return colum.editableRow(row, index);
                }
                if (colum.render) {
                  return colum.render(row, index);
                }

                return (
                  <td key={index}>
                    {
                      //@ts-ignore
                      row[colum.field]
                    }
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}
