import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { TableEditable } from "../../../../components/TableEditable";
import FormModificationContact from "./FormModificationContact";

export interface Contact {
  id: string;
  name: string;
}

interface Props {
  setContacts: React.Dispatch<React.SetStateAction<Contact[]>>;
  contacts: Contact[];
}

export const Contacts: React.FC<Props> = ({ contacts, setContacts }) => {
  const [isModalModify, setIsModalModify] = useState<
    "update" | "create" | undefined
  >();

  const [updateContact, setUpdateContact] = useState<Contact>();

  function onModalChange(type: "update" | "create", isVisible: boolean) {
    if (isVisible) {
      setIsModalModify(type);
    } else {
      setIsModalModify(undefined);
    }
  }

  function handleCreateContact(contact: Partial<Contact>) {
    const newContact: Contact = {
      id: uuid(),
      name: contact.name ?? "",
    };

    setContacts((rows) => [...rows, newContact]);

    setIsModalModify(undefined);
  }

  function handleUpdateContact(contact: Contact) {
    setContacts((rows) =>
      rows.map((row) => {
        if (row.id === contact.id) {
          return contact;
        }

        return row;
      })
    );

    setIsModalModify(undefined);
  }

  function handleRemoceContact(contact: Contact) {
    setContacts((rows) => rows.filter((row) => row.name !== contact.name));
  }

  return (
    <>
      <TableEditable
        header="Contatos"
        deleteData={(data) => {
          handleRemoceContact(data);
        }}
        setEditData={setUpdateContact}
        data={contacts}
        colums={[
          {
            title: "Nome",
            field: "name",
            inputType: "input",
          },
        ]}
        setModalVisibleEdit={(isVisible) =>
          onModalChange("update", !!isVisible)
        }
        setModalVisibleCreate={(isVisible) =>
          onModalChange("create", !!isVisible)
        }
        modalVisibleEdit={isModalModify === "update"}
        modalVisibleCreate={isModalModify === "create"}
        maxHeightModal={230}
        FormCreateComponent={() => (
          <FormModificationContact
            type="create"
            handleCloseModal={() => {
              onModalChange("create", false);
            }}
            onChangeData={handleCreateContact}
          />
        )}
        FormEditComponent={() => (
          <FormModificationContact
            type="edit"
            handleCloseModal={() => {
              onModalChange("update", false);
            }}
            onChangeData={handleUpdateContact}
            currentDate={updateContact}
          />
        )}
      />
    </>
  );
};
