import React from "react";
import { ButtonPage } from "./styles";

interface PaginationItemProps {
  isCurrent?: boolean;
  number: number;
  onPageChange: (page: number) => void;
}

export function PaginationItem({
  isCurrent = false,
  number,
  onPageChange,
}: PaginationItemProps) {
  if (isCurrent) {
    return <ButtonPage disabled>{number}</ButtonPage>;
  }

  return <ButtonPage onClick={() => onPageChange(number)}>{number}</ButtonPage>;
}
