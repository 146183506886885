import React from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import * as Yup from "yup";

import api from "../../../service/api";
import { Container, ContainerForm, Form, ButtunSubmit, Error } from "../styles";

const CreateDescriptionForecast: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  async function handleSubmit(data: any) {
    try {
      await api.post("/forecast/description", data);

      addToast("Descrição Forecast criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/descricoes/forecast");
    } catch (err) {
      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "cod status already exists") {
        return addToast("Este código já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <h2>Criar coleção Forecast</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            description: "",
          }}
          validationSchema={Yup.object({
            description: Yup.string().required("Descrição é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="description">Descrição</label>
                <input
                  id="description"
                  {...formik.getFieldProps("description")}
                />
                {formik.touched.description && formik.errors.description ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.description} </span>
                  </Error>
                ) : null}
              </div>

              <ButtunSubmit type="submit"> Criar </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default CreateDescriptionForecast;
