import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 2rem 1rem;
  padding-bottom: 8rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1100px;

  h2 {
    color: #333;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-top: 2rem;
  }
`;

export const Card = styled.div`
  display: flex;
  column-gap: 1rem;
  margin-top: 1.5rem;
  padding: 1rem;

  @media (max-width: 900px) {
    flex-direction: column;
    row-gap: 2rem;
  }

  background: #fff;
  border-radius: 6px;
  width: 100%;

  div {
    flex: 1;

    h3 {
      font-size: 1.3rem;
      font-weight: bold;
    }
    h4 {
      font-size: 0.875rem;
      color: #333;
      font-weight: bold;
      margin-bottom: 4px;
      margin-top: 4px;
    }

    p {
      font-size: 0.875rem;
      color: #333;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
    span {
      font-size: 0.875rem;
      color: #444;

      margin-bottom: 1.5rem;
    }

    .requirements {
      display: flex;
      margin-top: 1rem;
      column-gap: 4px;

      div {
        h4 {
          width: 100%;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    ul {
      padding-left: 2rem;
      list-style-type: circle !important;

      li {
        font-size: 0.875rem;
        color: #444;
      }
    }

    a {
      margin-top: 1rem;
      padding: 0.5rem;
      background: rgb(2, 106, 166);
      border-radius: 4px;
      color: #fff;
      font-weight: bold;

      display: flex;
      align-items: center;
      justify-content: center;

      width: 8rem;

      svg {
        margin-right: 4px;
      }
    }
  }
`;

export const InnerHtml = styled.div`
  margin-top: 1rem;

  h1,
  h2,
  h3,
  h4 {
    font-size: 0.875rem;
    color: #333;
    font-weight: bold;
    margin: 0 !important;
  }

  p {
    font-size: 0.875rem;
    color: #333;
    margin: 0 !important;
  }

  a {
    width: auto !important;
    background: transparent !important;
    display: inline-block !important;
    font-size: 0.875rem;
    color: #016aa5 !important;
    font-weight: 500 !important;

    margin: 0 !important;
    padding: 0 !important;
    text-decoration: underline !important;
  }
`;
