import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

export const Container = styled.div`
  padding: 0.645rem;

  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.3); */
  border-radius: 8px;
  background-color: #fff;
  height: 11rem;

  display: flex;
  align-items: center;

  & + & {
    margin-top: 0.5rem;
    border-top: 2px solid #ddd;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    height: 23rem;
  }
`;

export const Image = styled(LazyLoadImage)`
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100% !important;
  }
`;

export const ContainerImage = styled.div`
  height: 100%;
  max-width: 14rem;
  width: 30%;
  margin-right: 2.5rem;

  @media (max-width: 650px) {
    height: 30%;
    width: 100%;
    margin-right: 0;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
`;

export const ContentInfo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    line-height: 1.1rem;
  }

  & > strong {
    font-size: 1.5rem;
  }

  & > div {
    margin-top: 0.5rem;
    color: #808389;
    font-size: 0.875rem;
    font-weight: lighter;
  }

  & > div > div {
    display: flex;

    p + p {
      margin-left: 0.7rem;
    }
  }
`;
