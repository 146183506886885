import React from "react";
import { FiUnlock } from "react-icons/fi";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import api from "../../service/api";

import { useFormik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import * as Yup from "yup";

import { Container, ContainerLocker, Content, Error, Form } from "./styles";

interface IProps {
  client: boolean;
  admin: boolean;
  seller: boolean;
  provider: boolean;

  match: {
    params: {
      token: string;
      cnpj: string;
      cod: string;
      id: string;
    };
  };
}

const ResetPassword: React.FC<IProps> = ({
  match,
  seller,
  client,
  provider,
}) => {
  const history = useHistory();
  const { addToast } = useToasts();

  async function hadleSubmit(pass: string) {
    try {
      if (client) {
        await api.post("/client/resetPassword", {
          cnpj: match.params.cnpj,
          token: match.params.token,
          password: pass,
        });

        addToast("Alterção realizada com sucesso.", {
          appearance: "success",
          autoDismiss: true,
        });

        history.push("/");
      }

      if (seller) {
        await api.post("/seller/resetPassword", {
          cod: match.params.cod,
          token: match.params.token,
          password: pass,
        });

        addToast("Alterção realizada com sucesso.", {
          appearance: "success",
          autoDismiss: true,
        });

        history.push("/representante");
      }

      if (provider) {
        await api.post("/provider/auth/resetPassword", {
          id: match.params.id,
          token: match.params.token,
          password: pass,
        });

        addToast("Alterção realizada com sucesso.", {
          appearance: "success",
          autoDismiss: true,
        });

        history.push("/fornecedor");
      }
    } catch (err) {
      const error = err as any;

      if (!error.response || error.response.status >= 404)
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );

      if (error.response.data.error === "Token invalid")
        return addToast(
          "Desculpe, Link para alterar senha acesso invalido tente gerar novamente",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );

      if (error.response.data.error === "Token expired")
        return addToast(
          "Desculpe, ocorreu um erro no Link para redefinir senha expirou tente gerar novamente",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
    }
  }

  const formikPass = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      password: Yup.string()
        .required("Nova senha é obrigatoria")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "A senha precisa conter letra e números, no mínimo 8 caracteres e um carácter especial."
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "As senhas precisam ser iguais ")
        .required("Confirma nova senha é obrigatoria"),
    }),

    onSubmit: (values) => hadleSubmit(values.password),
  });

  return (
    <Container style={{ background: provider ? "#fff" : undefined }}>
      <Content>
        <header>
          <ContainerLocker>
            <FiUnlock color="#333" size={60} />
          </ContainerLocker>

          <h2>Definir nova senha</h2>
          <p></p>
        </header>

        <Form onSubmit={formikPass.handleSubmit}>
          <div className="field">
            <label htmlFor="password">Nova senha</label>
            <input
              id="password"
              type="password"
              {...formikPass.getFieldProps("password")}
            />
            {formikPass.touched.password && formikPass.errors.password ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formikPass.errors.password} </span>
              </Error>
            ) : null}
          </div>

          <div className="field">
            <label htmlFor="confirmPassword">Confirme sua nova senha</label>
            <input
              id="confirmPassword"
              type="password"
              {...formikPass.getFieldProps("confirmPassword")}
            />
            {formikPass.touched.confirmPassword &&
            formikPass.errors.confirmPassword ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formikPass.errors.confirmPassword} </span>
              </Error>
            ) : null}
          </div>

          <div className="field">
            <button
              style={{ background: provider ? "#444" : undefined }}
              type="submit"
            >
              Alterar senha
            </button>
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default ResetPassword;
