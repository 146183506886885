import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Banner = {
  id: string;
  title: string;
  link?: string;
  description?: string;
  admin: boolean;
  seller: boolean;
  client: boolean;
  provider: boolean;
  file: {
    name: string;
    url: string;
    size: number;
    mimetype?: string;
  };

  created_at: Date;
  created_atFormatted: String;
};

type BannersFilters = {
  listByUser?: boolean;
};

type GetBannersResponse = {
  banners: Banner[];
  totalCount: number;
  filters?: BannersFilters;
};

interface BannerApiResponse extends ResponsePanelApiDefault {
  contents: Banner[];
}

export async function getBanners(
  page: number,
  search?: string,
  registersPerPag?: number,
  filters?: BannersFilters
): Promise<GetBannersResponse> {
  const { data } = await api.get<BannerApiResponse>("/banner", {
    params: {
      pageRequest: page - 1,
      limitRequest: registersPerPag,
      search,
      ...filters,
    },
  });

  const banners = data.contents.map((contact) => ({
    ...contact,
    created_atFormatted: new Date(contact.created_at).toLocaleDateString(
      "pt-BR",
      {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
    ),
  }));
  const totalCount = data.total;

  return {
    totalCount,
    banners,
  };
}

export function useBanners(
  page: number,
  search?: string,
  registersPerPag?: number,
  filters?: BannersFilters
): UseQueryResult<GetBannersResponse> {
  return useQuery(
    ["banners", page, search],
    () => getBanners(page, search, registersPerPag, filters),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneBanner(id?: string): Promise<Banner | undefined> {
  if (!id) return undefined;

  const { data } = await api.get<Banner>(`/banner/${id}`);

  return data;
}

export function useOneBanner(id?: string): UseQueryResult<Banner> | undefined {
  return useQuery(["banner", id], () => getOneBanner(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
