import React from "react";
import { useLocation } from "react-router-dom";

type SetQueryParamsParams = {
  data: {
    field: string;
    value?: string | string[];
  };
  type: "append" | "set" | "delete";
  history: any;
};

export function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function setQueryParams({ data, type, history }: SetQueryParamsParams) {
  const params = new URLSearchParams(history?.location?.search);

  const { field, value } = data;

  if (value) {
    if (typeof value === "string") {
      params[type](field, value);
    } else {
      value.forEach((item) => {
        params[type](field, item);
      });
    }
  }

  if (type === "delete") params.delete(field);

  history.push({ search: params.toString() });
}
