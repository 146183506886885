import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

//@ts-ignore
import { mask } from "remask";

import axios from "axios";
import api from "../../../service/api";

import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

import Loading from "../../../components/loadings/Loading";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { IoMdSearch } from "react-icons/io";
import Loadind from "../../../components/loadings/Loading";
import {
  ButtunSubmit,
  Container,
  ContainerForm,
  Error,
  Form,
  ScreenLoading,
} from "../styles";

interface IHomeProps {
  match: {
    params: {
      id: number;
    };
  };

  admin?: boolean;
  seller?: boolean;

  is_manager?: boolean;
  is_supervisor?: boolean;
}

interface DefaultGateways {
  id: number;
  title?: string;
  parcel: number;
}

interface GatewayDefaultConfig {
  parcel: number;
  payment_gateway: {
    id: number;
    title: string;
  };
}

interface IReceitaAddress {
  cep: string;
  logradouro: string;
  complemento?: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia?: string;
  ddd: string;
  siafi: string;
}

interface IResponseDebt {
  parcel: number;
  value: number;
  isActive: boolean;
}

const CreateDecolucao: React.FC<IHomeProps> = ({ match }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [loadingPage, setLoadingPage] = useState(true);

  const [loading, setLoading] = useState(false);
  const [defaultGateway, setDefaultGateway] = useState<DefaultGateways>();

  // const [clints, setClients] = useState<IClient[]>([]);

  const [data, setData] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
    focus: "",
  });

  const [loadingSendAddress, setLoadingSendAddress] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const debtDB = await api.get<IResponseDebt>(
          `/debt/client/${match.params.id}`
        );

        if (!debtDB.data) {
          history.push("/404");
        }

        if (!debtDB.data.isActive) {
          history.push("/404");
        }

        const gatewayDefault = await api.get<GatewayDefaultConfig>(
          `/payment/gateway/config/default`
        );

        setDefaultGateway({
          id: gatewayDefault.data.payment_gateway.id,
          parcel: debtDB.data.parcel,
          title: gatewayDefault.data.payment_gateway.title,
        });

        formik.setFieldValue("payment.amount", debtDB.data.value);

        setLoadingPage(false);
      } catch (error) {
        console.log(error);
        setLoadingPage(false);

        history.push("/404");
      }
    })();
    // eslint-disable-next-line
  }, [match.params.id]);

  function parcelValue(value: number) {
    const quantityParcel =
      defaultGateway && defaultGateway.parcel ? defaultGateway.parcel : 0;
    let parcel = [];

    for (let index = 1; index <= quantityParcel; index++) {
      parcel.push({
        value: value / index,
        index: index,
      });
    }

    return parcel;
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = e.target.value;

    if (e.target.name === "name") {
      value = value.toUpperCase();
    }

    if (e.target.name === "number") {
      value = value
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{4})(\d)/, "$1 $2")
        .replace(/(\d{4})(\d)/, "$1 $2")
        .replace(/(\d{4})(\d)/, "$1 $2");
    }

    if (e.target.name === "expiry") {
      value = value.replace(/\D/g, "").replace(/^(\d{2})/, "$1/");
    }

    if (e.target.name === "cvc") {
      value = value.replace(/\D/g, "");
    }

    setData({
      ...data,
      [e.target.name]: value,
    });

    formik.setFieldValue(`creditCard.${e.target.name}`, value);
  }

  function handleInputFocus(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, focus: e.target.name });
  }

  async function hadleSubmit(props: any) {
    setLoading(true);

    try {
      await api.post(`/payment/${match.params.id}`, {
        ...props,
      });

      setLoading(false);

      addToast("Pagamento realizado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });

      history.push(`/negociacao/info/${match.params.id}`);
    } catch (err) {
      const error = err as any;
      setLoading(false);

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 402) {
        history.push(`/negociacao/info/${match.params.id}`);

        return addToast("Desculpe, ocorreu um erro em sua transição", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 403) {
        return addToast(
          "Ocorreu um erro na validação de seu cartão de credito, cartão invalidado ou dados incorretos.",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleSendAddress() {
    setLoadingSendAddress(true);
    try {
      const cep = String(formik.values.address.zip_code).replace(/\D/g, "");

      const response = await axios.get<IReceitaAddress>(
        `https://viacep.com.br/ws/${cep}/json/`
      );

      if (response && response.data) {
        const { logradouro, bairro, localidade, uf, complemento } =
          response.data;

        formik.setFieldValue("address.street", logradouro);
        formik.setFieldValue("address.complement", complemento);
        formik.setFieldValue("address.neighborhood", bairro);
        formik.setFieldValue("address.city", localidade);
        formik.setFieldValue("address.uf", uf);

        setLoadingSendAddress(false);
      } else {
        setLoadingSendAddress(false);

        addToast(
          "Ocorreu um erro em sua consulta. Tente novamente mais tarde ou preecha manualmente.",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }
    } catch (error) {
      setLoadingSendAddress(false);

      addToast(
        "Ocorreu um erro em sua consulta. Tente novamente mais tarde ou preencha manualmente",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
    }
  }

  function hadleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data: any;

    if (
      name === "custumer.document_number" &&
      String(formik.values.custumer.document_number).length <= 11
    ) {
      data = value.replace(/\D/g, "");
    }

    if (
      name === "custumer.phone_number" &&
      String(formik.values.custumer.phone_number).length <= 11
    ) {
      data = Number(value.replace(/\D/g, ""));
    }

    if (name === "address.zip_code") {
      data = value.replace(/\D/g, "");
    }

    formik.setFieldValue(name, String(data));
  }

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      custumer: {
        //Dados do solicitante
        first_name: undefined, //Primeiro nome
        last_name: undefined, //Sobrenome
        document_number: undefined, //CNPJ ou document_number

        phone_number: undefined, //Telefone
        email: undefined, //E-mail
      },

      address: {
        zip_code: undefined,
        uf: undefined,
        city: undefined,
        neighborhood: undefined,
        street: undefined,
        numberStreet: undefined,
        complement: undefined,
      },

      creditCard: {
        cvc: undefined,
        expiry: undefined,
        name: undefined,
        number: undefined,
      },

      payment: {
        amount: undefined,
        parcel: undefined,
      },

      client: {
        cod: undefined,
      },
    },

    validationSchema: Yup.object({
      custumer: Yup.object().shape({
        first_name: Yup.string().required("Nome é obrigatório"),
        last_name: Yup.string().required("Sobrenome é obrigatório"),
        document_number: Yup.string()
          .min(11, "CPF é inválida")
          .max(11, "CPF é inválida")
          .required("CPF é obrigatório"),
        phone_number: Yup.string().required("Telefone é obrigatório"),
        email: Yup.string()
          .email("informe um e-mail válido")
          .required("E-mail é obrigatório"),
      }),
      address: Yup.object().shape({
        zip_code: Yup.string().required("CEP é obrigatório"),
        uf: Yup.string()
          .min(2, "UF é inválida")
          .max(2, "UF é inválida")
          .required("UF é obrigatório"),
        city: Yup.string().required("Cidade é obrigatório"),
        neighborhood: Yup.string().required("Bairro é obrigatório"),
        street: Yup.string().required("Logradouro é obrigatório"),
        numberStreet: Yup.number()
          .typeError("Numero é inválido")
          .required("Número é obrigatório"),
      }),
      payment: Yup.object().shape({
        amount: Yup.number()
          .typeError("Valor é inválido, digite apenas os números.")
          .required("Valor é obrigatório"),
        parcel: Yup.number()
          .typeError("Parcela é inválido, digite apenas os números.")
          .required("Parcela é obrigatório")
          .test("is_Fisisher", "Parcela é invalida ", async function (value) {
            let error = true;
            //@ts-ignore
            if (value > Number(formik.values.payment.amount)) {
              error = false;
            }

            return error;
          }),
      }),
      creditCard: Yup.object().shape({
        number: Yup.number()
          .typeError("Número do cartão é inválido, digite apenas os números.")
          .test(
            "length",
            "Número do cartão é inválido",
            //@ts-ignore
            (value: number) => {
              let error = false;

              if (String(value).replace(/\D/g, "").length >= 13) {
                error = true;
              }

              return error;
            }
          )
          .required("Número do cartão é obrigatório"),
        name: Yup.string().required("Nome do cartão é obrigatório"),
        expiry: Yup.string().required("Data de expiração é obrigatório"),
        cvc: Yup.number()
          .typeError("Código segurança é inválido, digite apenas os números.")
          .required("Código segurança é obrigatório"),
      }),
    }),

    onSubmit: (values) => {
      hadleSubmit(values);
    },
  });

  return (
    <Container>
      {!loadingPage ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft color={"#333"} size={18} />
            </button>
            <h2>Realizar Pagamento</h2>
          </header>
          <ContainerForm>
            <Form
              onSubmit={
                loading
                  ? (e) => {
                      e.preventDefault();
                    }
                  : formik.handleSubmit
              }
            >
              <h3>Informações sobre titular</h3>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="custumer.first_name">
                    Nome <span className="required">*</span>
                  </label>
                  <input
                    id="custumer.first_name"
                    maxLength={40}
                    {...formik.getFieldProps("custumer.first_name")}
                  />
                  {formik.touched.custumer?.first_name &&
                  formik.errors.custumer?.first_name ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.custumer?.first_name} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="last_name">
                    Sobrenome <span className="required">*</span>
                  </label>
                  <input
                    id="last_name"
                    type="text"
                    maxLength={40}
                    {...formik.getFieldProps("custumer.last_name")}
                  />
                  {formik.touched.custumer?.last_name &&
                  formik.errors.custumer?.last_name ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.custumer?.last_name} </span>
                    </Error>
                  ) : null}
                </div>
              </div>
              <div className="field">
                <label htmlFor="email">
                  CPF <span className="required">*</span>
                </label>
                <input
                  name="custumer.document_number"
                  maxLength={14}
                  onChange={hadleInputChange}
                  value={
                    formik.values.custumer.document_number
                      ? mask(formik.values.custumer.document_number, [
                          "999.999.999-99",
                        ])
                      : ""
                  }
                />
                {formik.touched.custumer?.document_number &&
                formik.errors.custumer?.document_number ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.custumer?.document_number} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="email">
                    E-Mail <span className="required">*</span>
                  </label>
                  <input
                    id="custumer.first_name"
                    maxLength={60}
                    {...formik.getFieldProps("custumer.email")}
                  />
                  {formik.touched.custumer?.email &&
                  formik.errors.custumer?.email ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.custumer?.email} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="phone_number">
                    Telefone <span className="required">*</span>
                  </label>
                  <input
                    name="custumer.phone_number"
                    onChange={hadleInputChange}
                    maxLength={15}
                    value={
                      formik.values.custumer.phone_number
                        ? mask(formik.values.custumer.phone_number, [
                            "(99) 99999-9999",
                          ])
                        : ""
                    }
                  />
                  {formik.touched.custumer?.phone_number &&
                  formik.errors.custumer?.phone_number ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.custumer?.phone_number} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <h3>
                Endereço do titular <span className="required">*</span>
              </h3>
              <div className="field">
                <label htmlFor="zip_code">
                  CEP <span className="required">*</span>
                </label>

                <div className="containerCnpj">
                  <input
                    name="address.zip_code"
                    onChange={hadleInputChange}
                    maxLength={9}
                    value={
                      formik.values.address.zip_code
                        ? mask(formik.values.address.zip_code, ["99999-999"])
                        : ""
                    }
                  />

                  <button
                    type="button"
                    onClick={() => {
                      handleSendAddress();
                    }}
                  >
                    {loadingSendAddress ? (
                      <Loadind
                        size={22}
                        borderSize={3.5}
                        colorLoading="rgba(255,255,255)"
                        borderColor="rgba(255,255,255, 0.3)"
                      />
                    ) : (
                      <>
                        <IoMdSearch />
                        Buscar
                      </>
                    )}
                  </button>
                </div>
                {formik.touched.address?.zip_code &&
                formik.errors.address?.zip_code ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.address?.zip_code} </span>
                  </Error>
                ) : null}
              </div>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="city">
                    Cidade <span className="required">*</span>
                  </label>
                  <input id="city" {...formik.getFieldProps("address.city")} />
                  {formik.touched.address?.city &&
                  formik.errors.address?.city ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.address?.city} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="uf">
                    UF <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength={2}
                    className="uf"
                    {...formik.getFieldProps("address.uf")}
                  />
                  {formik.touched.address?.uf && formik.errors.address?.uf ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.address?.uf} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="neighborhood">
                    Bairro <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className="neighborhood"
                    maxLength={40}
                    {...formik.getFieldProps("address.neighborhood")}
                  />
                  {formik.touched.address?.neighborhood &&
                  formik.errors.address?.neighborhood ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span>{formik.errors.address?.neighborhood}</span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="street">
                    Logradouro <span className="required">*</span>
                  </label>
                  <input
                    id="street"
                    maxLength={60}
                    {...formik.getFieldProps("address.street")}
                  />
                  {formik.touched.address?.street &&
                  formik.errors.address?.street ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.address?.street} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="  ">
                    Número <span className="required">*</span>
                  </label>
                  <input
                    id="numberStreet"
                    type="number"
                    {...formik.getFieldProps("address.numberStreet")}
                    maxLength={10}
                  />
                  {formik.touched.address?.numberStreet &&
                  formik.errors.address?.numberStreet ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span>{formik.errors.address?.numberStreet}</span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="complement">Complemento</label>
                  <input
                    id="complement"
                    maxLength={100}
                    {...formik.getFieldProps("address.complement")}
                  />
                </div>
                {formik.touched.address?.complement &&
                formik.errors.address?.complement ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span>{formik.errors.address?.complement}</span>
                  </Error>
                ) : null}
              </div>

              <h3>Informações do pagamento</h3>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="paymentValue">Valor</label>
                  <input
                    style={{ borderStyle: "dashed" }}
                    readOnly={true}
                    id="paymentValue"
                    value={Number(
                      Number(formik.values.payment.amount) / 100
                    ).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  />
                  {formik.touched.payment?.amount &&
                  formik.errors.payment?.amount ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span>{formik.errors.payment?.amount}</span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="numberStreet">
                    Parcelas <span className="required">*</span>
                  </label>
                  <select {...formik.getFieldProps("payment.parcel")}>
                    <option value={undefined}></option>

                    {Boolean(formik.values.payment.amount) &&
                      parcelValue(Number(formik.values.payment.amount)).map(
                        (element, index) => (
                          <option key={index} value={element.index}>
                            {element.index}X
                            {Number(element.value / 100).toLocaleString(
                              "pt-br",
                              {
                                style: "currency",
                                currency: "BRL",
                              }
                            )}
                          </option>
                        )
                      )}
                  </select>

                  {formik.touched.payment?.parcel &&
                  formik.errors.payment?.parcel ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span>{formik.errors.payment?.parcel}</span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Cards
                  locale={{
                    valid: "válido até",
                  }}
                  placeholders={{
                    name: "Nome",
                  }}
                  cvc={data.cvc}
                  expiry={data.expiry}
                  focused={
                    data.focus as
                      | "number"
                      | "cvc"
                      | "expiry"
                      | "name"
                      | undefined
                  }
                  name={data.name}
                  number={data.number}
                />

                <div style={{ flex: 1, paddingLeft: "1rem" }}>
                  <div className="field">
                    <label htmlFor="neighborhood">
                      Número do cartão <span className="required">*</span>
                    </label>

                    <input
                      type="tel"
                      name="number"
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      maxLength={19}
                      value={formik.values.creditCard.number}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.creditCard?.number &&
                    formik.errors.creditCard?.number ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span>{formik.errors.creditCard?.number}</span>
                      </Error>
                    ) : null}
                  </div>
                  <div className="field">
                    <label htmlFor="name">
                      Nome impresso no cartão{" "}
                      <span className="required">*</span>
                    </label>

                    <input
                      type="text"
                      name="name"
                      maxLength={40}
                      value={formik.values.creditCard.name}
                      onFocus={handleInputFocus}
                      onChange={handleInputChange}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.creditCard?.name &&
                    formik.errors.creditCard?.name ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span>{formik.errors.creditCard?.name}</span>
                      </Error>
                    ) : null}
                  </div>

                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="neighborhood">
                        Data vencimento <span className="required">*</span>
                      </label>

                      <input
                        type="expiry"
                        name="expiry"
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        maxLength={5}
                        value={formik.values.creditCard.expiry}
                        onBlur={formik.handleBlur}
                      />

                      {formik.touched.creditCard?.expiry &&
                      formik.errors.creditCard?.expiry ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span>{formik.errors.creditCard?.expiry}</span>
                        </Error>
                      ) : null}
                    </div>
                    <div className="field">
                      <label htmlFor="neighborhood">
                        Código de segurança <span className="required">*</span>
                      </label>

                      <input
                        type="cvc"
                        name="cvc"
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        maxLength={4}
                        value={formik.values.creditCard.cvc}
                        onBlur={formik.handleBlur}
                      />

                      {formik.touched.creditCard?.cvc &&
                      formik.errors.creditCard?.cvc ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span>{formik.errors.creditCard?.cvc}</span>
                        </Error>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="containerButton">
                <ButtunSubmit type="submit">
                  {loading ? (
                    <Loading
                      size={22}
                      borderSize={4}
                      colorLoading="rgba(255,255,255)"
                      borderColor="rgba(255,255,255, 0.3)"
                    />
                  ) : (
                    "Realizar pagamento"
                  )}
                </ButtunSubmit>
              </div>
            </Form>
          </ContainerForm>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default CreateDecolucao;
