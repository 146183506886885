import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

import { useFormik } from "formik";

import { FaFileExcel } from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { panelTypesDefault } from "../../../@types/panelTypes";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import {
  Client,
  ClientFilters,
  getClients,
  useClients,
} from "../../../hook/queries/useClients";
import { generateXlsx } from "../../../utils/generateXlsx";

interface PanelColorsProps extends panelTypesDefault {}

const columns: FieldProps<Client>[] = [
  {
    name: "search",
    field: "CLIENTE",
    viewList: false,
  },
  {
    name: "cod",
    field: "CÓD",
    viewList: true,
  },
  {
    name: "company_name",
    field: "RAZÃO SOCIAL",
    viewList: true,
  },
  {
    name: "cnpj",
    field: "CNPJ",
    viewList: true,
  },
  {
    name: "email",
    field: "EMAIL",
    viewList: true,
  },
  {
    name: "updateImageAt",
    field: "ATUALIZAÇÃO FOTOS",
    viewList: true,
  },
  {
    name: "updateImagePeriod",
    field: "DATA ATUALIZAÇÃO FOTOS",
    viewList: false,
  },
  {
    name: "updateImageSpecificDate",
    field: "DATA ESPECIFICA ATUALIZAÇÃO FOTOS",
    viewList: false,
  },
  {
    name: "updateImageStartDate",
    field: "DATA INICIO ATUALIZAÇÃO FOTOS",
    viewList: false,
  },
  {
    name: "updateImageEndDate",
    field: "DATA FIM ATUALIZAÇÃO FOTOS",
    viewList: false,
  },
  {
    name: "lastOrderDatePeriod",
    field: "DATA ULTIMA COMPRA",
    viewList: false,
  },
  {
    name: "lastOrderDateSpecificDate",
    field: "DATA ESPECIFICA ULTIMA COMPRA",
    viewList: false,
  },
  {
    name: "lastOrderDateStartDate",
    field: "DATA INICIO ULTIMA COMPRA",
    viewList: false,
  },
  {
    name: "lastOrderDateEndDate",
    field: "DATA FIM ULTIMA COMPRA",
    viewList: false,
  },
  {
    name: "isClientContact",
    field: "CONTATO REALIZADO COM CLIENTE EXITO",
    viewList: false,
  },
  {
    name: "status",
    field: "SITUAÇÃO DO CLIENTE",
    viewList: false,
  },
  {
    name: "concept",
    field: "CONCEITO",
    viewList: false,
  },
  {
    name: "city",
    field: "CIDADE",
    viewList: false,
  },
  {
    name: "neighborhood",
    field: "BAIRRO",
    viewList: false,
  },
  {
    name: "situation",
    field: "SITUAÇÃO DO CLIENTE",
    viewList: true,
    render: ({ situation }) => (
      <span
        style={{
          color: "#fff",
          fontWeight: "bold",
          backgroundColor: String(situation) === "1" ? "#21b543" : "#c52929",
          borderRadius: 6,
          fontSize: 12,
          padding: "4px 6px",
        }}
      >
        {String(situation) === "1" ? "ATIVO" : "INATIVO"}
      </span>
    ),
  },
];

const PanelClients: React.FC<PanelColorsProps> = ({ admin }) => {
  const history = useHistory();
  const query = useQueryParams();

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });
  const [filters, setFilters] = useState<ClientFilters>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });

  const [viewFilter, setViewFilter] = useState(false);
  const { data, isLoading, isFetching, refetch } = useClients(
    page,
    filters,
    15
  );

  const formikFilter = useFormik({
    initialValues: {
      search: "",
      isClientContact: undefined,
      updateImagePeriod: undefined,
      updateImageSpecificDate: undefined,
      updateImageStartDate: undefined,
      updateImageEndDate: undefined,
      lastOrderDatePeriod: undefined,
      lastOrderDateSpecificDate: undefined,
      lastOrderDateStartDate: undefined,
      lastOrderDateEndDate: undefined,
    },
    validationSchema: Yup.object({
      updateImageSpecificDate: Yup.string().when("updateImagePeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      updateImageStartDate: Yup.string().when("updateImagePeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      updateImageEndDate: Yup.string().when("updateImagePeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),

      lastOrderDateSpecificDate: Yup.string().when("lastOrderDatePeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      lastOrderDateStartDate: Yup.string().when("lastOrderDatePeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      lastOrderDateEndDate: Yup.string().when("lastOrderDatePeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),
    }),
    onSubmit: (data) => {
      handleFilter(data);
    },
  });

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  function handleFilter(data: ClientFilters) {
    setFilters(data);
    setPage(1);
    setViewFilter(false);
  }

  function handleNew() {
    if (admin) {
      history.push("/admin/criar/cliente");
    } else {
      history.push("/representante/criar/cliente");
    }
  }

  function selectDescription(st: any, title: string) {
    let filterNormalized = String(st);
    const arrTitle = ["updateImagePeriod", "lastOrderDatePeriod", "concept"];
    if (arrTitle.includes(title)) {
      switch (title) {
        case "concept":
          const findConcept = data?.filters?.concept.find(
            (f) => String(f.id) === String(st)
          );
          filterNormalized = findConcept?.name ?? st;
          break;

        case "updateImagePeriod":
          if (Number(st) === 1) {
            filterNormalized = "Predefinida";
          }
          if (Number(st) === 2) {
            filterNormalized = "Específica";
          }
          if (Number(st) === 3) {
            filterNormalized = "Intevalo";
          }

          break;
        case "lastOrderDatePeriod":
          if (Number(st) === 1) {
            filterNormalized = "Predefinida";
          }
          if (Number(st) === 2) {
            filterNormalized = "Específica";
          }
          if (Number(st) === 3) {
            filterNormalized = "Intevalo";
          }

          break;
      }
    }

    return filterNormalized;
  }

  async function handleExportClients() {
    const clients = await getClients(1, filters, 99999);

    generateXlsx({
      data: clients.clients.map((client) => ({
        Código: client.cod,
        "Razão social": client.company_name,
        CNPJ: client.cnpj,
        Email: client.email,
        Situação: String(client.situation) === "1" ? "Ativo" : "Inativo",
        // String(client.situation) === "2"
        // ? "Bloqueado"
        // : String(client.situation) === "3"
        // ? "Inativo"
        // : "Inativo baixado",
        Telefone: client.phone,
        "Telefone 2": client.phone2,
        Celular: client.cellPhone,
      })),
      filename: "Clientes",
    });
  }

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de Clientes" />

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={selectDescription}
      >
        <GroupInput>
          <Input label="CLIENTE" {...formikFilter.getFieldProps("search")} />
        </GroupInput>

        <GroupInput>
          <Select
            label="CONCEITO"
            data={
              data?.filters.concept.map((item) => ({
                value: item.id,
                name: item.name,
              })) ?? []
            }
            {...formikFilter.getFieldProps("concept")}
          />

          <Select
            label="CIDADE"
            data={
              data?.filters.city.map((item) => ({
                value: item.id,
                name: item.name,
              })) ?? []
            }
            {...formikFilter.getFieldProps("city")}
          />
        </GroupInput>

        <GroupInput>
          <Select
            label="CONTATO REALIZADO COM CLIENTE EXITO"
            data={[
              { name: "SIM", value: "sim" },
              { name: "NÃO", value: "não" },
              { name: "NUNCA", value: "nunca" },
            ]}
            {...formikFilter.getFieldProps("isClientContact")}
          />

          <Select
            label="SITUAÇÃO DO CLIENTE"
            data={[
              { name: "ATIVO", value: "ativo" }, //1
              { name: "INATIVO", value: "inativo" }, //3
              // { name: "BLOQUEADO", value: "bloqueado" }, //2
              // { name: "INATIVO BAIXADO", value: "inativoBaixado" }, //4
            ]}
            {...formikFilter.getFieldProps("status")}
          />
        </GroupInput>

        <Select
          label="DATA ATUALIZAÇÃO FOTOS"
          data={[
            { name: "Específica", value: 2 },
            { name: "Intervalo", value: 3 },
          ]}
          {...formikFilter.getFieldProps("updateImagePeriod")}
        />

        <GroupInput>
          {Number(formikFilter.values.updateImagePeriod) === 2 && (
            <Input
              type="date"
              label="DATA ATUALIZAÇÃO FOTOS"
              {...formikFilter.getFieldProps("updateImageSpecificDate")}
              error={
                formikFilter.touched.updateImageSpecificDate &&
                formikFilter.errors.updateImageSpecificDate
                  ? formikFilter.errors.updateImageSpecificDate
                  : undefined
              }
            />
          )}
          {Number(formikFilter.values.updateImagePeriod) === 3 && (
            <GroupInput>
              <Input
                type="date"
                label="DATA INICIAL"
                {...formikFilter.getFieldProps("updateImageStartDate")}
                error={
                  formikFilter.touched.updateImageStartDate &&
                  formikFilter.errors.updateImageStartDate
                    ? formikFilter.errors.updateImageStartDate
                    : undefined
                }
              />
              <Input
                type="date"
                label="DATA FINAL"
                {...formikFilter.getFieldProps("updateImageEndDate")}
                error={
                  formikFilter.touched.updateImageEndDate &&
                  formikFilter.errors.updateImageEndDate
                    ? formikFilter.errors.updateImageEndDate
                    : undefined
                }
              />
            </GroupInput>
          )}
        </GroupInput>

        <Select
          label="DATA DA ÚLTIMA COMPRA"
          data={[
            { name: "Específica", value: 2 },
            { name: "Intervalo", value: 3 },
          ]}
          {...formikFilter.getFieldProps("lastOrderDatePeriod")}
        />

        <GroupInput>
          {Number(formikFilter.values.lastOrderDatePeriod) === 2 && (
            <Input
              type="date"
              label="DATA DA ÚLTIMA COMPRA"
              {...formikFilter.getFieldProps("lastOrderDateSpecificDate")}
              error={
                formikFilter.touched.lastOrderDateSpecificDate &&
                formikFilter.errors.lastOrderDateSpecificDate
                  ? formikFilter.errors.lastOrderDateSpecificDate
                  : undefined
              }
            />
          )}
          {Number(formikFilter.values.lastOrderDatePeriod) === 3 && (
            <GroupInput>
              <Input
                type="date"
                label="DATA INICIAL"
                {...formikFilter.getFieldProps("lastOrderDateStartDate")}
                error={
                  formikFilter.touched.lastOrderDateStartDate &&
                  formikFilter.errors.lastOrderDateStartDate
                    ? formikFilter.errors.lastOrderDateStartDate
                    : undefined
                }
              />
              <Input
                type="date"
                label="DATA FINAL"
                {...formikFilter.getFieldProps("lastOrderDateEndDate")}
                error={
                  formikFilter.touched.lastOrderDateEndDate &&
                  formikFilter.errors.lastOrderDateEndDate
                    ? formikFilter.errors.lastOrderDateEndDate
                    : undefined
                }
              />
            </GroupInput>
          )}
        </GroupInput>
      </PanelFilter>

      <TableHeader title="CLIENTES" isFetching={isFetching && !isLoading}>
        <button style={{ background: "#2d792f" }} onClick={handleExportClients}>
          <FaFileExcel size={19} />
          <span>EXPORTAR</span>
        </button>
        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>
        <button onClick={handleNew} type="button">
          <MdAdd size={22} /> <span> NOVO </span>
        </button>
      </TableHeader>

      <TableContent<Client>
        data={data?.clients.map((client) => ({
          ...client,
          updateImageAt: client?.lastImageUpdateDate
            ? dayjs(client.lastImageUpdateDate).format("DD/MM/YYYY")
            : "-",
        }))}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) =>
          history.push(`/${admin ? "admin" : "representante"}/cliente/${e.id}`)
        }
      />
      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={15}
      />
    </PanelLayout>
  );
};

export default PanelClients;
