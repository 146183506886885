import React, { FormEvent, useState } from "react";
import { FiLock, FiAlertCircle } from "react-icons/fi";
import { AiOutlineCheck } from "react-icons/ai";
import Loadind from "../../components/loadings/Loading";

import { useHistory } from "react-router-dom";

import { Container, Content, ContainerLocker, Form } from "./styles";
import api from "../../service/api";

interface IResponseApi {
  email: string;
  client: boolean;
  seller: boolean;
  provider: boolean;
}

interface HeaderProps {
  admin: boolean;
  client: boolean;
  seller: boolean;
  provider: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Check: React.FC<IResponseApi> = ({ email, seller, client, provider }) => {
  const history = useHistory();

  function handleToLogin() {
    if (client) {
      history.push("/");
    }

    if (seller) {
      history.push("/representante");
    }

    if (provider) {
      history.push("/fornecedor");
    }
  }

  return (
    <Content>
      <header>
        <ContainerLocker>
          <AiOutlineCheck color="#333" size={60} />
        </ContainerLocker>

        <h2>E-mail enviado com sucesso para</h2>
        <p>{email}</p>
      </header>

      <footer>
        <button onClick={handleToLogin}>Voltar ao Login</button>
      </footer>
    </Content>
  );
};

const ForgotPassword: React.FC<HeaderProps> = ({
  client,
  seller,
  provider,
}) => {
  const history = useHistory();
  const [erorrText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cod, setCod] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  async function hadleSubmitClient(event: FormEvent) {
    event.preventDefault();
    setLoading(true);

    if (!cnpj) {
      setLoading(false);
      return setErrorText("CNPJ não preenchido");
    }

    const cnpjValid = cnpj.replace(
      // eslint-disable-next-line
      /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
      ""
    );

    if (isNaN(Number(cnpjValid))) {
      setLoading(false);
      return setErrorText("CNPJ inválido");
    }

    if (cnpjValid.length !== 14) {
      setLoading(false);
      return setErrorText("CNPJ inválido");
    }

    try {
      const response = await api.post<IResponseApi>("/client/forgotPassword", {
        cnpj: cnpjValid,
      });
      setSuccessText(response.data.email);

      setLoading(false);
    } catch (error) {
      setErrorText("Cliente bloqueado ou inexistente");
      setLoading(false);
    }
  }

  async function hadleSubmitSeller(event: FormEvent) {
    event.preventDefault();
    setLoading(true);

    if (!cod) {
      setLoading(false);
      return setErrorText("CÓDIGO não preenchido");
    }

    if (cod.length > 4) {
      setLoading(false);
      return setErrorText("CÓDIGO inválido");
    }

    try {
      const response = await api.post<IResponseApi>("/seller/forgotPassword", {
        cod,
      });
      setSuccessText(response.data.email);

      setLoading(false);
    } catch (error) {
      setErrorText("Este CÓDIGO não existe em nossa base de dados");
      setLoading(false);
    }
  }

  async function hadleSubmitProvider(event: FormEvent) {
    event.preventDefault();
    setLoading(true);

    if (!email) {
      setLoading(false);
      return setErrorText("E-mail não preenchido");
    }

    const re =
      // eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
      setLoading(false);
      return setErrorText("E-mail inválido");
    }

    try {
      const response = await api.post<IResponseApi>(
        "/provider/auth/forgotPassword",
        {
          email,
        }
      );
      setSuccessText(response.data.email);

      setLoading(false);
    } catch (error) {
      setErrorText("Este E-mail não existe em nossa base de dados");
      setLoading(false);
    }
  }

  return (
    <Container
      style={{
        background: provider ? "#fff" : undefined,
      }}
    >
      {successText ? (
        <Check
          email={successText}
          client={client}
          seller={seller}
          provider={provider}
        />
      ) : (
        <>
          {client && (
            <Content>
              <header>
                <ContainerLocker>
                  <FiLock color="#333" size={60} />
                </ContainerLocker>

                <h2>Problemas para entrar?</h2>
                <p>
                  Insira o seu CNPJ e enviaremos um email para redefinir sua
                  senha.
                </p>
              </header>

              <Form onSubmit={hadleSubmitClient}>
                <div className="field">
                  <input
                    id="cnpj"
                    placeholder="Informe seu CNPJ"
                    type="text"
                    onChange={(e) => setCnpj(e.target.value)}
                  />
                  {erorrText && (
                    <div>
                      <FiAlertCircle />
                      <span> {erorrText} </span>
                    </div>
                  )}
                </div>
                <div className="field">
                  <button>
                    {loading ? (
                      <Loadind />
                    ) : (
                      "Enviar E-mail para redefinir senha"
                    )}
                  </button>
                </div>
              </Form>

              <footer>
                <button
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Voltar ao Login
                </button>
              </footer>
            </Content>
          )}

          {seller && (
            <Content>
              <header>
                <ContainerLocker>
                  <FiLock color="#333" size={60} />
                </ContainerLocker>

                <h2>Problemas para entrar?</h2>
                <p>
                  Insira o seu código de representante e enviaremos um email
                  para redefinir sua senha.
                </p>
              </header>

              <Form onSubmit={hadleSubmitSeller}>
                <div className="field">
                  <input
                    id="cod"
                    placeholder="Informe seu código de representante"
                    type="text"
                    onChange={(e) => setCod(e.target.value.trim())}
                  />
                  {erorrText && (
                    <div>
                      <FiAlertCircle />
                      <span> {erorrText} </span>
                    </div>
                  )}
                </div>
                <div className="field">
                  <button>
                    {loading ? (
                      <Loadind />
                    ) : (
                      "Enviar E-mail para redefinir senha"
                    )}
                  </button>
                </div>
              </Form>

              <footer>
                <button
                  onClick={() => {
                    history.push("/representante");
                  }}
                >
                  Voltar ao Login
                </button>
              </footer>
            </Content>
          )}

          {provider && (
            <Content>
              <header>
                <ContainerLocker>
                  <FiLock color="#333" size={60} />
                </ContainerLocker>

                <h2>Problemas para entrar?</h2>
                <p>
                  Insira o seu email de acesso e enviaremos um email para
                  redefinir sua senha.
                </p>
              </header>

              <Form onSubmit={hadleSubmitProvider}>
                <div className="field">
                  <input
                    id="email"
                    placeholder="Informe seu email"
                    type="text"
                    onChange={(e) => setEmail(e.target.value.trim())}
                  />
                  {erorrText && (
                    <div>
                      <FiAlertCircle />
                      <span> {erorrText} </span>
                    </div>
                  )}
                </div>
                <div className="field">
                  <button
                    style={{
                      background: "#444",
                    }}
                  >
                    {loading ? (
                      <Loadind />
                    ) : (
                      "Enviar E-mail para redefinir senha"
                    )}
                  </button>
                </div>
              </Form>

              <footer>
                <button
                  onClick={() => {
                    history.push("/fornecedor");
                  }}
                >
                  Voltar ao Login
                </button>
              </footer>
            </Content>
          )}
        </>
      )}
    </Container>
  );
};

export default ForgotPassword;
