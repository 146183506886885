import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { IoReload } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { FieldProps, panelTypesDefault } from "../../../@types/panelTypes";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import { TableContent } from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import {
  Color,
  ColorFilters,
  useColors,
} from "../../../hook/queries/useColors";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

interface PanelColorsProps extends panelTypesDefault {}

const columns: FieldProps[] = [
  {
    name: "cod",
    field: "Cód",
    viewList: true,
  },
  {
    name: "description",
    field: "Descrição",
    viewList: true,
  },
  {
    name: "hex",
    field: "HEX",
    viewList: true,
  },
];

const PanelColors: React.FC<PanelColorsProps> = () => {
  const history = useHistory();
  const query = useQueryParams();

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });
  const [filters, setFilters] = useState<ColorFilters>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });
  const [viewFilter, setViewFilter] = useState(false);
  const { data, isLoading, isFetching, refetch } = useColors(page, filters);

  const formikFilter = useFormik({
    initialValues: {
      cod: "",
      description: "",
      hex: "",
    },
    onSubmit: (data) => {
      handleFilter(data);
    },
  });

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  function handleFilter(data: ColorFilters) {
    setFilters(data);
    setPage(1);
    setViewFilter(false);
  }

  function handleNewColor() {
    history.push("/admin/criar/cores");
  }

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de Cores" />

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
      >
        <GroupInput>
          <Input label="Cód" {...formikFilter.getFieldProps("cod")} />
          <Input
            label="Descrição"
            {...formikFilter.getFieldProps("description")}
          />
        </GroupInput>
      </PanelFilter>

      <TableHeader title="CORES" isFetching={isFetching && !isLoading}>
        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>
        <button onClick={handleNewColor} type="button">
          <MdAdd size={22} /> <span> NOVA COR </span>
        </button>
      </TableHeader>

      <TableContent<Color>
        data={data?.colors}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) => history.push(`/admin/cores/${e.id}`)}
      />
      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
      />
    </PanelLayout>
  );
};

export default PanelColors;
