import styled from "styled-components";

export const Container = styled.div`
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 8px;

  button {
    background: #d21e26;

    width: 2rem;
    height: 2rem;

    color: #fff;
    border-radius: 50%;

    margin-bottom: 10px;
  }

  img {
    height: 8rem;
    width: 18rem;

    object-fit: cover;

    border-radius: 8px;
  }
`;
