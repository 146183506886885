import icon_xlsx from "../assets/icon_excel.png";
import icon_pdf from "../assets/icon_pdf.png";
import icon_power_point from "../assets/icon_power_point.png";
import icon_word from "../assets/icon_word.png";
import icon_xml from "../assets/icon_xml.png";
import video from "../assets/video.png";

export default function selectIcon(
  type?: string | undefined
): string | undefined {
  var data: string | undefined = undefined;

  if (type === "text/xml" || type === "application/xml") {
    data = icon_xml;
  }

  if (type === "application/pdf") {
    data = icon_pdf;
  }

  if (
    type === "application/vnd.ms-excel" ||
    type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ) {
    data = icon_xlsx;
  }

  if (type === "application/msword") {
    data = icon_word;
  }

  if (
    type === "application/vnd.ms-powerpoint" ||
    type ===
      "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ) {
    data = icon_power_point;
  }

  if (type === "video/mp4") {
    data = video;
  }

  return data;
}
