import React, { useState, useEffect } from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import api from "../../../service/api";
import {
  Container,
  ContainerForm,
  Form,
  ButtunSubmit,
  Error,
  ContainerCheck,
} from "../styles";
import { FiAlertCircle } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import { IPermissionUser } from "../../../@types";

interface HomeProps {
  location: {
    state: {
      brand: IStatus;
    };
  };
  permission: IPermissionUser;
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_active: boolean;
  is_observation: boolean;
  is_valid: boolean;
  user_id: number;
  responsable_id: number;
}

interface IPermission {
  id?: string;
  name?: string;
}

const User: React.FC<HomeProps> = ({ location, permission }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const {
    id,
    cod,
    name,
    color,
    finisher,
    user_id,
    responsable_id,
    is_active,
    is_valid,
    is_observation,
  } = location.state.brand;

  const [listPermission, setListPermission] = useState<IPermission[]>([]);

  useEffect(() => {
    (async () => {
      const permissionDB = await api.get("/populate/permission");
      setListPermission(permissionDB.data);
    })();
  }, []);

  async function handleSubmit(updates: any) {
    try {
      await api.put(`/scheduling/status/${updates.id}`, updates);
      addToast("Status de Agendamento alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/status/agendamentos");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.data.error === "cannot be changed") {
        return addToast("Status não pode ser alterado", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este e-mail já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>
        <h2>Status de agendamento</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            id: id,
            cod: cod,
            name: name,
            color: color,
            finisher: finisher,
            responsable: responsable_id,
            user: user_id,
            is_active: is_active,
            is_observation: is_observation,
            is_valid: is_valid,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Nome é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form
              onSubmit={
                permission && permission.config && permission.config > 2
                  ? formik.handleSubmit
                  : () => {}
              }
            >
              <div className="field">
                <label htmlFor="name">Situação</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.is_active ? "check" : ""}
                    onClick={() => formik.setFieldValue("is_active", true)}
                  >
                    Ativo
                  </button>

                  <button
                    type="button"
                    className={!formik.values.is_active ? "check" : ""}
                    onClick={() => formik.setFieldValue("is_active", false)}
                  >
                    Inativo
                  </button>
                </ContainerCheck>
              </div>

              <div className="field">
                <label htmlFor="COD">COD</label>
                <input
                  type="text"
                  className="id"
                  {...formik.getFieldProps("cod")}
                  readOnly={true}
                />
              </div>
              <div className="field">
                <label htmlFor="name">Descrição</label>
                <input id="name" {...formik.getFieldProps("name")} />
                {formik.touched.name && formik.errors.name ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.name} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="color">Cor de fundo</label>
                <input id="color" {...formik.getFieldProps("color")} />
                {formik.touched.color && formik.errors.color ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.color} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="responsable">Responsável</label>

                  <select {...formik.getFieldProps("responsable")}>
                    <option value="">Selecionar responsável</option>
                    {listPermission.map((item, i) => (
                      <option key={i} value={Number(item.id)}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="field">
                  <label htmlFor="user">Utilizador</label>

                  <select {...formik.getFieldProps("user")}>
                    <option value="">Selecionar utilizador</option>
                    {listPermission.map((item, i) => (
                      <option key={i} value={Number(item.id)}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="field">
                <label htmlFor="name">Observação</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.is_observation ? "check" : ""}
                    onClick={() => formik.setFieldValue("is_observation", true)}
                  >
                    SIM
                  </button>

                  <button
                    type="button"
                    className={!formik.values.is_observation ? "check" : ""}
                    onClick={() =>
                      formik.setFieldValue("is_observation", false)
                    }
                  >
                    NÃO
                  </button>
                </ContainerCheck>
              </div>

              <div className="field">
                <label htmlFor="name">Validador</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.is_valid ? "check" : ""}
                    onClick={() => formik.setFieldValue("is_valid", true)}
                  >
                    SIM
                  </button>

                  <button
                    type="button"
                    className={!formik.values.is_valid ? "check" : ""}
                    onClick={() => formik.setFieldValue("is_valid", false)}
                  >
                    NÃO
                  </button>
                </ContainerCheck>
              </div>

              <div className="field">
                <label htmlFor="name">Finalização</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.finisher ? "check" : ""}
                    onClick={() => formik.setFieldValue("finisher", true)}
                  >
                    SIM
                  </button>

                  <button
                    type="button"
                    className={!formik.values.finisher ? "check" : ""}
                    onClick={() => formik.setFieldValue("finisher", false)}
                  >
                    NÃO
                  </button>
                </ContainerCheck>
              </div>

              {permission && permission.config && permission.config > 2 && (
                <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
              )}
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default User;
