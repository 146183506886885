import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  grid-area: APP;

  padding: 10px 30px 10px 30px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }
`;

export const ContainerImages = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  a {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);

    width: 250px;
    height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 5px 15px 5px;

    transition: all 0.4s;

    &:hover {
      box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.6);
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  @media (max-width: 1099px) {
    & {
      justify-content: center;
    }
  }
`;
