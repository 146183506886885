import React, { useEffect, useState } from "react";

import Loading from "../../../components/loadings/Loading";
//@ts-ignore
import JSONPretty from "react-json-prettify";

import {
  BodyPaymentDetailCard,
  Container,
  HeaderPaymentDetailCard,
  InfoDevolution,
  Infogroup,
  InfoStatus,
  PaymentDetailCard,
  ScreenLoading,
} from "../styles";

import { FaArrowLeft } from "react-icons/fa";

import moment from "moment";
import { CgDetailsMore } from "react-icons/cg";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import FloatButton from "../../../components/FloatButton";
import api from "../../../service/api";
import { dataUndefined } from "../../../utils/dataProcessing";

interface HomeProps {
  location: {
    state: {
      content: IPayment;
    };
  };

  match: {
    params: {
      id: number;
    };
  };

  admin: boolean;
}

interface IPaymentDetail {
  status: string;
  status_color: string;
  authorization_code: string;
  authorized_at: Date;
  transaction_id: string;
  terminal_nsu: string;
  purchaser: string;
  reason_message: string;
  error_message: string;
}

interface IDebt {
  id: number;
  codeOrder: string;
  value: number;
  isActive: boolean;
  parcel: number;
  created_at: Date;
  dueDate: Date;

  payment: IPayment[];

  debt_type?: {
    cod: number;
    name: string;
  };

  clients_pj?: {
    cod: string;
    company_name: string;
    trade_name: string;
    cnpj: string;
  };
}

interface IPayment {
  id: number;
  created_at: Date;
  status: string;
  status_color: string;
  amount: number;
  card_brand: string;
  card_number: string;
  card_name: string;
  paid: boolean;
  payment_detail: IPaymentDetail[];
  custumer: {
    name: string | null;
    email: string;
    phone_number: string;
    document_number: string;
    adresses: {
      street: string;
      numberStreet: string;
      zip_code: string;
      uf: string;
      city: string;
    };
  };
}

const Payment: React.FC<HomeProps> = ({ location, admin, match }) => {
  const history = useHistory();

  const [contentPage, setContentPage] = useState<IDebt | null>(null);
  // const [update, setUpdate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [detailError, setDetailError] = useState<boolean>(false);
  const [activeViewPayment, setActiveViewPayment] = useState(0);

  useEffect(() => {
    (async () => {
      if (Boolean(match.params.id)) {
        try {
          const showPayment = await api.get<IDebt>(
            `/debt/show/${match.params.id}`
          );

          setLoading(false);

          if (!Boolean(showPayment.data)) {
            return history.push("/404");
          }

          setContentPage(showPayment.data);
        } catch (error) {
          return history.push("/404");
        }
      } else {
        history.push("/404");
      }
    })();
  }, [location, history, /*update, */ match]);

  // async function hadleCancel(id: Number) {
  //   setLoading(true);
  //   try {
  //     await api.post(`/payment/canceled/${id}`);
  //     setUpdate(true);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // }

  function handleActivePayment(i: number) {
    if (activeViewPayment === i) {
      setActiveViewPayment(0);
    } else {
      setActiveViewPayment(i);
    }
  }

  return (
    <Container>
      {contentPage !== null && !loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Detalhes da negociação</h2>
          </header>

          <InfoStatus>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>CÓDIGO DA NEGOCIAÇÃO</h5>
                </div>
                <p>{contentPage.id}</p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>TIPO</h5>
                </div>
                <p>
                  {dataUndefined(contentPage.debt_type?.name) === "-" &&
                  contentPage.codeOrder
                    ? "Pedido"
                    : dataUndefined(contentPage.debt_type?.name)}

                  {contentPage.codeOrder && ` #${contentPage.codeOrder}`}
                </p>
              </div>
            </div>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>VALOR</h5>
                </div>
                <p>
                  {Number(contentPage.value / 100).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>Vencimento em</h5>
                </div>
                <p>
                  {contentPage.dueDate
                    ? moment(contentPage.dueDate)
                        .add("day", 1)
                        .format("DD/MM/YYYY")
                    : "-"}
                </p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>Ativo</h5>
                </div>
                <p>
                  <span
                    style={{
                      backgroundColor: contentPage.isActive
                        ? "rgba(136, 227, 138, 0.5)"
                        : "rgba(255, 61, 61, 0.8)",
                    }}
                  >
                    {contentPage.isActive ? "Sim" : "Não"}
                  </span>
                </p>
              </div>
            </div>
          </InfoStatus>

          <Infogroup>
            <InfoDevolution>
              <div className="headerDevoltion">
                <h2>Informações</h2>
              </div>

              <div className="bodyDevoltion">
                <div className="conatinerInfo">
                  <h3>Cliente</h3>
                  <div className="line"></div>

                  <div>
                    <h4>CÓDIGO CLIENTE</h4>
                    <span>{contentPage.clients_pj?.cod}</span>

                    <h4>CNPJ</h4>
                    <span>{contentPage.clients_pj?.cnpj}</span>

                    <h4>RAZÃO SOCIAL</h4>
                    <span>{contentPage.clients_pj?.company_name}</span>

                    <h4>NOME FANTASIA</h4>
                    <span>{contentPage.clients_pj?.trade_name}</span>
                  </div>
                </div>
              </div>
            </InfoDevolution>

            <InfoDevolution style={{ gridArea: "HT" }}>
              <div className="headerDevoltion">
                <h2>Histórico de transações</h2>
              </div>
              {contentPage.payment.map((element) => (
                <PaymentDetailCard key={element.id}>
                  <HeaderPaymentDetailCard
                    onClick={() => handleActivePayment(element.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <div>
                      <h4
                        style={{
                          background: element.status_color,
                          color: "#000",
                          borderRadius: 6,
                          padding: 3,
                          textAlign: "center",
                        }}
                      >
                        {element.status}
                      </h4>
                      <span>
                        {moment(element.created_at).format(
                          "DD/MM/YYYY - hh:mm:ss"
                        )}
                      </span>
                    </div>

                    <div
                      className={
                        activeViewPayment === element.id
                          ? "arrow"
                          : "arrow-active"
                      }
                      style={{
                        width: 26,
                        padding: 4,
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "center",
                        marginBottom: 10,
                      }}
                    >
                      <MdKeyboardArrowDown size={22} />
                    </div>
                  </HeaderPaymentDetailCard>

                  {activeViewPayment === element.id && (
                    <BodyPaymentDetailCard>
                      <div>
                        <h4>Transaction Id</h4>
                        <span>
                          {dataUndefined(
                            element.payment_detail[0].transaction_id
                          )}
                        </span>
                        <h4>Adquirente</h4>
                        <span>
                          {dataUndefined(element.payment_detail[0].purchaser)}
                        </span>

                        <h4>NSU</h4>
                        <span>
                          {dataUndefined(
                            element.payment_detail[0].terminal_nsu
                          )}
                        </span>

                        <h4>Cód. de autorização</h4>
                        <span>
                          {dataUndefined(
                            element.payment_detail[0].authorization_code
                          )}
                        </span>
                      </div>

                      <div>
                        <h4>CARTÃO DE CRÉDITO</h4>
                        <span>{element.card_brand}</span>

                        <h4>Nome</h4>
                        <span>{element.card_name}</span>

                        <h4>Número</h4>
                        <span>**** **** **** {element.card_number}</span>
                      </div>

                      {element.payment_detail[0].error_message && admin && (
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              setDetailError(!detailError);
                            }}
                          >
                            <h4>Detalhes da requisição</h4>
                            <CgDetailsMore size={20} />
                          </button>

                          {detailError && (
                            <span>
                              {element.payment_detail[0].error_message ? (
                                <JSONPretty
                                  style={{ borderRadius: 8 }}
                                  json={JSON.parse(
                                    element.payment_detail[0].error_message
                                  )}
                                />
                              ) : (
                                "-"
                              )}
                            </span>
                          )}
                        </div>
                      )}
                    </BodyPaymentDetailCard>
                  )}
                </PaymentDetailCard>
              ))}
              {contentPage.payment.length <= 0 && (
                <PaymentDetailCard>
                  Não há registros a serem exibidos
                </PaymentDetailCard>
              )}
            </InfoDevolution>
          </Infogroup>

          {!!contentPage.isActive && !admin && (
            <FloatButton
              Icon={RiMoneyDollarCircleFill}
              title="Pagar"
              color="#009c27"
              onPress={() => {
                history.push(`/realizar/pagamento/${contentPage.id}`);
              }}
            />
          )}
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Payment;
