import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import ModalVisibleImage from "../../../components/ModalVisibleImage";
import { useOneQualities } from "../../../hook/queries/useQualities";

import "react-lazy-load-image-component/src/effects/blur.css";

import {
  ButtunSubmit,
  Container,
  ContainerForm,
  Form,
  InfoDevolution,
  InfoStatus,
  ScreenLoading,
} from "../styles";

import Loading from "../../../components/loadings/Loading";

import { useFormik } from "formik";
import { FaArrowLeft } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { IoMdOpen } from "react-icons/io";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useToasts } from "react-toast-notifications";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import Modal from "../../../components/ModalLib";
import { TableClean } from "../../../components/TableClean";
import api from "../../../service/api";
import { dataUndefined } from "../../../utils/dataProcessing";
import { generateReportQuality } from "./generateReportQuality";

interface HomeProps {
  match: {
    params: {
      idHeaderDatasheet: number;
      idDatasheet: number;
      idQuality: number;
    };
  };
}

const QualityComponent: React.FC<HomeProps> = ({ match }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const quality = useOneQualities(Number(match.params.idQuality));

  const [modalVisible, setModalVisible] = useState(false);
  const [modalFileOpen, setModalFileOpen] = useState<number>();

  const [isModalSendMail, setIsModalSendMail] = useState(false);
  const [isLoadingSendMail, setIsLoadingSendMail] = useState(false);
  const formikSendMail = useFormik({
    validateOnChange: false,

    initialValues: {
      email: "",
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Obrigatório"),
      email: Yup.string().email("E-mail invalido").required("Obrigatório"),
    }),

    onSubmit: async (data) => {
      setIsLoadingSendMail(true);

      try {
        await handleSendReport({ email: data.email, name: data.name });
        formikSendMail.resetForm();

        addToast(
          `
          Relatório de Auditoria enviado com sucesso!`,
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
        setIsLoadingSendMail(false);
        setIsModalSendMail(false);
      } catch (error) {
        addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    },
  });

  function printOrder() {
    if (quality?.data) {
      const html = generateReportQuality({
        reference: quality.data?.datasheet.alternative_code ?? "",
        observation: quality.data?.observation ?? "",
        date: new Date(quality.data?.created_at).toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        }),
        inspector: `${quality.data?.inspector.name} ${quality.data?.inspector.lastname}`,
        oc: quality.data?.oc ?? "",
        oc_total_quantity: String(quality.data?.oc_total_quantity),
        provider: quality.data?.provider.fullname,
        status: String(quality.data?.status.cod),
        type: String(quality.data?.type.cod),
        sampling: String(quality.data?.sampling),
        variations: quality?.data?.quality_variation.map((variation) => ({
          id: variation.id,
          qtd: variation.qtd,
          primary_color: (
            "0000" +
            dataUndefined(variation.datasheet_variation.primary_color_cod)
          ).slice(-4),
        })),
        variationsTotal: quality?.data?.quality_variation.reduce(
          (acc, value) => value.qtd + acc,
          0
        ),
        defects: quality.data.quality_defect.map((defect) => ({
          cod: defect.cod,
          description: defect.description,
          qtd: defect.qtd,
        })),
        defectsTotal: quality.data.quality_defect.reduce(
          (acc, value) => acc + value.qtd,
          0
        ),
        samplingTotal: quality.data.quality_product.length,
        nqa_accpet: quality.data.nqa_accpet,
        nqa_reject: quality.data.nqa_reject,
      });

      return html;
    }
    return;
  }

  async function handlePrintOrder() {
    try {
      if (quality?.data) {
        const html = printOrder();

        if (html) {
          var popup = window.open("", "popup", "width=650, height=700");
          if (popup) popup.document.write(html);
        }
      }
    } catch (error) {}
  }

  async function handleSendReport(data: { name: string; email: string }) {
    const html = printOrder();

    const email = {
      body: html,
      to: {
        email: data.email,
        name: data.name,
      },
    };

    await api.post(
      `/headerDatasheet/datasheet/qualities/mail/${match.params.idQuality}`,
      {
        email,
      }
    );
  }

  return (
    <Container>
      {!quality?.isLoading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Relatório de Auditoria</h2>

            <div className="containerActions">
              <button
                type="button"
                className="buttonActionDatasheet"
                onClick={handlePrintOrder}
              >
                {
                  <div>
                    <span>
                      <IoMdOpen color={"#f1b916"} />
                    </span>
                    Abrir relatório
                  </div>
                }
              </button>
              <button
                type="button"
                className="buttonActionDatasheet"
                onClick={() => setIsModalSendMail(true)}
              >
                {
                  <div>
                    <span>
                      <FiMail color={"#3e6196"} />
                    </span>
                    Enviar relatório
                  </div>
                }
              </button>
            </div>
          </header>

          <InfoStatus>
            <div className="containerStatus">
              <div
                className="bannerLine"
                style={{
                  backgroundColor: quality?.data?.status?.color,
                }}
              />
              <div className="box">
                <div>
                  <h5>SITUAÇÃO</h5>
                </div>
                <span
                  style={{
                    backgroundColor: quality?.data?.status?.color,
                  }}
                >
                  {quality?.data?.status.name}
                </span>
              </div>
            </div>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>TIPO</h5>
                </div>
                <p>
                  {quality?.data?.type.name ? quality?.data?.type.name : `-`}
                </p>
              </div>
            </div>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>REFERENCIA</h5>
                </div>
                <p>
                  {quality?.data?.datasheet.alternative_code
                    ? quality?.data?.datasheet.alternative_code
                    : `-`}
                </p>
              </div>
            </div>
            {/* <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>FORNECEDOR</h5>
                </div>
                <p>
                  {quality?.data?.provider
                    ? `${quality?.data?.provider.cod} - ${quality?.data?.provider.fullname}`
                    : `-`}
                </p>
              </div>
            </div> */}
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>DATA CRIAÇÃO</h5>
                </div>
                <p>
                  {quality?.data?.created_at
                    ? `${new Date(quality?.data.created_at).toLocaleDateString(
                        "pt-BR",
                        {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }
                      )}`
                    : `-`}
                </p>
              </div>
            </div>
          </InfoStatus>

          <InfoDevolution>
            <div className="headerDevoltion">
              <h2>Informações</h2>
            </div>
            <div className="bodyDevoltion">
              <div className="conatinerInfo">
                <h3>Fornecedor</h3>
                <div className="line"></div>

                <div>
                  <h4>Código</h4>
                  <span>
                    {quality?.data?.provider.cod
                      ? quality?.data?.provider.cod
                      : "-"}
                  </span>

                  <h4>Nome Completo</h4>
                  <span>
                    {quality?.data?.provider.fullname
                      ? quality?.data?.provider.fullname
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="conatinerInfo">
                <h3>Auditor(a)</h3>
                <div className="line"></div>

                <div>
                  <h4>Nome Completo</h4>
                  <span>
                    {quality?.data?.inspector.name &&
                    quality?.data?.inspector.name
                      ? `${quality?.data?.inspector.name} ${quality?.data?.inspector.lastname}`
                      : quality?.data?.inspector.name
                      ? quality?.data?.inspector.name
                      : "-"}
                  </span>

                  <h4>E-Mail</h4>
                  <span>
                    {quality?.data?.inspector.email
                      ? quality?.data?.inspector.email
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="conatinerInfo">
                <h3>Coleção</h3>
                <div className="line"></div>

                <div>
                  <h4>Marca</h4>
                  <span>
                    {quality?.data?.datasheet.header_datasheet.brand.name
                      ? quality?.data?.datasheet.header_datasheet.brand.name
                      : "-"}
                  </span>
                  <h4>Coleção </h4>
                  <span>
                    {quality?.data?.datasheet.header_datasheet
                      ? `${quality?.data?.datasheet.header_datasheet.collection.description} - ${quality?.data?.datasheet.header_datasheet.year}`
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="conatinerInfo">
                <h3>Dados</h3>
                <div className="line"></div>

                <div>
                  <h4>Tipo</h4>
                  <span>
                    {quality?.data?.type.name ? quality?.data?.type.name : "-"}
                  </span>
                  <h4>Situação</h4>
                  <span>
                    {quality?.data?.status.name
                      ? quality?.data?.status.name
                      : "-"}
                  </span>
                  <h4>O.C</h4>
                  <span> {quality?.data?.oc ? quality?.data?.oc : "-"}</span>
                  <h4>Quantidade do item na O.C</h4>
                  <span>
                    {quality?.data?.oc_total_quantity
                      ? quality?.data?.oc_total_quantity
                      : "-"}
                  </span>

                  <h4>Amostragem</h4>
                  <span>
                    {quality?.data?.sampling ? quality?.data?.sampling : "-"}
                  </span>
                </div>
              </div>
            </div>
          </InfoDevolution>

          <InfoDevolution>
            <div className="headerDevoltion">
              <h2>Inspeção de produtos</h2>
            </div>
            <div className="bodyDevoltion">
              <div className="conatinerInfo">
                <h3>Variantes de Cor</h3>

                <TableClean
                  columns={[
                    {
                      field: "primary_color",
                      title: "Cód. Variantes",
                    },

                    {
                      field: "qtd",
                      title: "Quantidade",
                    },
                  ]}
                  data={
                    quality?.data?.quality_variation.map((variation) => ({
                      id: variation.id,
                      qtd: variation.qtd,
                      primary_color: (
                        "0000" +
                        dataUndefined(
                          variation.datasheet_variation.primary_color_cod
                        )
                      ).slice(-4),
                    })) ?? []
                  }
                />
              </div>
              <div className="conatinerInfo">
                <h3>Irregularidades Verificadas</h3>

                <TableClean
                  columns={[
                    {
                      field: "cod",
                      title: "Cód.",
                    },

                    {
                      field: "description",
                      title: "Descrição",
                    },
                    {
                      field: "qtd",
                      title: "Quantidade",
                    },
                  ]}
                  data={
                    quality?.data?.quality_defect.map((defect) => ({
                      cod: defect.cod,
                      description: defect.description,
                      qtd: defect.qtd,
                    })) ?? []
                  }
                />
              </div>

              {quality?.data?.files && quality?.data?.files?.length > 0 && (
                <div className="conatinerInfo" style={{ minWidth: "100%" }}>
                  <h3>Arquivos</h3>
                  <div className="line" />
                  <div className="containerImg">
                    {quality?.data?.files?.map((img, index) => (
                      <div key={img.id}>
                        <LazyLoadImage
                          effect="blur"
                          onClick={() => {
                            setModalFileOpen(index);
                            setModalVisible(true);
                          }}
                          src={img.url}
                          alt={img.name}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </InfoDevolution>

          <Modal
            modalVisible={isModalSendMail}
            setModalVisible={setIsModalSendMail}
            title="Enviar Relatório de Auditoria"
            maxHeight={280}
            scrollOff
          >
            <Form
              onSubmit={
                isLoadingSendMail
                  ? (e) => {
                      e.preventDefault();
                    }
                  : formikSendMail.handleSubmit
              }
            >
              <ContainerForm>
                <div className="containerForm">
                  <GroupInput>
                    <Input
                      label="Nome destinatário"
                      {...formikSendMail.getFieldProps("name")}
                      error={
                        formikSendMail.touched.name &&
                        formikSendMail.errors.name
                          ? formikSendMail.errors.name
                          : undefined
                      }
                    />
                    <Input
                      label="E-mail destinatário"
                      {...formikSendMail.getFieldProps("email")}
                      error={
                        formikSendMail.touched.email &&
                        formikSendMail.errors.email
                          ? formikSendMail.errors.email
                          : undefined
                      }
                    />
                  </GroupInput>
                </div>

                <div className="containerFormButton">
                  <ButtunSubmit style={{ margin: 0 }} type="submit">
                    {isLoadingSendMail ? (
                      <div>
                        <Loading borderSize={2} size={20} />
                      </div>
                    ) : (
                      "Enviar"
                    )}
                  </ButtunSubmit>
                </div>
              </ContainerForm>
            </Form>
          </Modal>

          {modalVisible &&
            modalFileOpen !== null &&
            modalFileOpen !== undefined && (
              <ModalVisibleImage
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                images={
                  quality?.data?.files?.map((img) => ({
                    id: String(img.id),
                    name: img.name,
                    size: img.size,
                    url: img.url,
                  })) ?? []
                }
                indexImg={modalFileOpen}
              />
            )}
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default QualityComponent;
