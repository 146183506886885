import React from "react";
import Chart from "react-apexcharts";
import { BoxAnalytic } from "../../pages/analytic/styles";

interface BoxAnalyticCharAreaProps {
  title: string;
  typeDate?: "currency" | "number";

  dates: Date[];
  values: {
    name: string;
    data: number[];
  }[];

  obs?: JSX.Element;
  colors?: string[];
}

export const BoxAnalyticCharArea = ({
  title,
  dates,
  colors,
  values,
  obs,
  typeDate = "currency",
}: BoxAnalyticCharAreaProps) => {
  return (
    <BoxAnalytic>
      <h3>{title}</h3>

      <div style={{ width: "100%" }}>
        <Chart
          type="area"
          height={200}
          series={values}
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            grid: {
              show: false,
            },

            colors: colors,

            fill: {
              opacity: 0.3,
              type: "gradient",
              gradient: {
                opacityFrom: 0.7,
                opacityTo: 0.3,
              },
            },

            yaxis: {
              labels: {
                formatter: (value) =>
                  typeDate === "currency"
                    ? value.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    : String(value),
              },
            },

            xaxis: {
              // type: "datetime",
              labels: {
                formatter: (value) =>
                  new Date(value).toLocaleDateString("pt-BR", {
                    month: "short",
                    year: "2-digit",
                  }),
              },
              categories: dates,
            },
          }}
        />
        {obs && <span className="span-alert">{obs}</span>}
      </div>
    </BoxAnalytic>
  );
};
