import React from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import api from "../../../service/api";
import {
  Container,
  ContainerForm,
  Form,
  ButtunSubmit,
  Error,
  ContainerCheck,
} from "../styles";
import { FiAlertCircle } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import { IPermissionUser } from "../../../@types";

interface HomeProps {
  location: {
    state: {
      content: IStatus;
    };
  };

  permission: IPermissionUser;
}

interface IStatus {
  id: number;
  description: string;
  is_active: boolean;
}

const User: React.FC<HomeProps> = ({ location, permission }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const { id, description, is_active } = location.state.content;

  async function handleSubmit(updates: IStatus) {
    try {
      await api.put(`/forecast/description/${updates.id}`, updates);
      addToast("Descrição do forecast alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/descricoes/forecast");
    } catch (err) {
      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.data.error === "cannot be changed") {
        return addToast("Descrição não pode ser alterado", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este e-mail já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>
        <h2>Coleção de Forecast</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            id: id,
            description: description,
            active: is_active,
          }}
          validationSchema={Yup.object({
            description: Yup.string().required("Descrição é obrigatório"),
          })}
          //@ts-ignore
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form
              onSubmit={
                permission && permission.config && permission.config > 2
                  ? formik.handleSubmit
                  : () => {}
              }
            >
              <div className="field">
                <label htmlFor="id">ID</label>
                <input
                  type="text"
                  className="id"
                  style={{ borderStyle: "dashed" }}
                  {...formik.getFieldProps("id")}
                  readOnly={true}
                />
              </div>
              <div className="field">
                <label htmlFor="name">Descrição</label>
                <input
                  id="description"
                  {...formik.getFieldProps("description")}
                />
                {formik.touched.description && formik.errors.description ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.description} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="name">Situação</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.active ? "check" : ""}
                    onClick={() => formik.setFieldValue("active", true)}
                  >
                    Ativo
                  </button>

                  <button
                    type="button"
                    className={!formik.values.active ? "check" : ""}
                    onClick={() => formik.setFieldValue("active", false)}
                  >
                    Inativo
                  </button>
                </ContainerCheck>
              </div>

              {permission && permission.config && permission.config > 2 && (
                <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
              )}
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default User;
