import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContainerForm = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 60px;
  grid-template-areas:
    "FORM"
    "BTN";

  .containerForm {
    grid-area: FORM;
    overflow-y: scroll;
    padding: 18px;
  }

  .status-option {
    font-size: 13px;
    font-weight: 400;
    border-radius: 4px;
    padding: 2px 5px;
  }

  .containerFormButton {
    grid-area: BTN;
    padding: 0 8px;
  }

  .container-subtitle {
    display: flex;
    align-items: center;

    button {
      height: 46px;
      width: 46px;
      border-radius: 50%;

      margin-left: 0.5rem;

      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      svg {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
      }
    }
  }
`;
