import React from "react";
import {
  Container,
  ContainerInfoItem,
  Content,
  ContentItem,
  ExpectedDate,
  InfoItem,
  Item,
  Title,
} from "./styled";

interface TrackingProps {
  data: {
    cod: string;
    description: string;
    expectedDate?: Date;
    deliveryDate?: Date;
    date: Date;
    isDelivered?: Boolean;
    orderDate: Date;
  };
}

export const Tracking = ({ data }: TrackingProps) => {
  const { cod, description, expectedDate, date, orderDate, isDelivered } = data;

  return (
    <Container>
      <Title>RASTREIO</Title>

      <Content>
        <ExpectedDate>
          {expectedDate &&
            `Previsão de Entrega ${new Date(expectedDate).toLocaleDateString(
              "pt-br",
              {
                day: "2-digit",
                month: "long",
              }
            )}`}
        </ExpectedDate>

        <ContentItem>
          <Item
            style={{ width: isDelivered ? "100%" : `${Number(cod) * 16}%` }}
          />
        </ContentItem>
        <ContainerInfoItem
          style={{ width: isDelivered ? "100%" : `${Number(cod) * 16}%` }}
        >
          <InfoItem>
            <b>Pedido Recebido</b>
            <span>
              {new Date(orderDate).toLocaleDateString("pt-br", {
                day: "2-digit",
                month: "short",
              })}
            </span>
          </InfoItem>
          <InfoItem>
            <b style={{ color: "#333 " }}>{description}</b>
            <span>
              {new Date(date).toLocaleDateString("pt-br", {
                day: "2-digit",
                month: "short",
              })}
            </span>
          </InfoItem>
        </ContainerInfoItem>
      </Content>
    </Container>
  );
};
