import * as Yup from "yup";
import { OrderFilters } from "../../hook/queries/useOrders";

interface FormikFilterProps {
  handleFilter: (a: OrderFilters) => void;
}

export const formikFilterOrder = ({ handleFilter }: FormikFilterProps) => {
  return {
    initialValues: {
      cod: "",
      status: "",
      pointed: "",
      position_detail: "",
      type: "",
      codClient: "",
      cnpj: "",
      company_name: "",
      pointedDesc: "",
      codSeller: "",
      brand: "",
      orderPeriod: "",
      orderPresetDate: "",
      orderSpecificDate: "",
      orderStartDate: "",
      orderEndDate: "",
      billingOrderPeriod: "",
      billingOrderPresetDate: "",
      billingOrderSpecificDate: "",
      billingOrderStartDate: "",
      billingOrderEndDate: "",
    },
    validationSchema: Yup.object({
      orderPresetDate: Yup.string().when("orderPeriod", {
        is: "1",
        then: Yup.string().required("Data predefinida é obrigatório"),
      }),

      orderSpecificDate: Yup.string().when("orderPeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      orderStartDate: Yup.string().when("orderPeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      orderEndDate: Yup.string().when("orderPeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),

      //Finalização

      billingOrderPresetDate: Yup.string().when("billingOrderPeriod", {
        is: "1",
        then: Yup.string().required("Data predefinida é obrigatório"),
      }),

      billingOrderSpecificDate: Yup.string().when("billingOrderPeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      billingOrderStartDate: Yup.string().when("billingOrderPeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      billingOrderEndDate: Yup.string().when("billingOrderPeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),
    }),
    onSubmit: (data: any) => {
      handleFilter(data);
    },
  };
};
