import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { setFormikValues } from "../../../utils/setFormikValues";

import copy from "copy-to-clipboard";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useOneSeller } from "../../../hook/queries/useSeller";
import api from "../../../service/api";
import { ButtunSubmit, ContainerCheck, PopUpConfirmation } from "../styles";

import { IoIosSend } from "react-icons/io";
import { APP_LINK } from "../../../App";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { InputCheckboxV2 } from "../../../components/Form/InputCheckboxV2";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { HistoryAccess } from "../../../components/HistoryAccess";
import Loading from "../../../components/Loading";
import Modal from "../../../components/ModalLib";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Tab } from "../../../components/Tab";

interface HomeProps {
  admin?: boolean;

  location: {
    state: {
      content: ISeller;
    };
  };

  match: {
    params: {
      id: number;
    };
  };
}

interface ISeller {
  id: number;
  cod: number;
  abbreviation: string;
  fullName: string;
  email: string;
  phone: string | null;
  is_active: boolean;
  is_manager: boolean;
  is_supervisor: boolean;
  cod_manager: number | null;
  cod_supervisor: number | null;

  brands?: {
    cod: number;
    name: string;
  }[];
}

const Seller: React.FC<HomeProps> = ({ match, admin }) => {
  const getSeller = useOneSeller(match.params.id);
  const { addToast } = useToasts();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [loadingSendTutorial, setLoadingSendTutorial] = useState(false);
  const [linkWelcome, setLinkWelcome] = useState("");
  const [modalSendTutorial, setModalSendTutorial] = useState(false);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      cod: undefined,
      abbreviation: undefined,
      fullName: undefined,
      email: undefined,
      phone: undefined,
      is_active: undefined,
      is_manager: undefined,
      is_supervisor: undefined,
      password: undefined,
    },

    validationSchema: Yup.object({
      cod: Yup.number().required("Código é obrigatório"),
      abbreviation: Yup.string().required("Abreviação é obrigatório"),
      phone: Yup.string().required("Telefone é obrigatório"),
      fullName: Yup.string().required("Nome completo é obrigatório"),

      email: Yup.string()
        .email("informe um e-mail válido")
        .required("email é obrigatório"),

      password: Yup.string()
        .min(8, "A senha precisa conter no mínimo 8 caracteres.")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "A senha precisa conter letrar e numeros, no mínimo 8 caracteres e um caracter especial."
        ),
    }),
    //@ts-ignore
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    if (getSeller?.isSuccess && getSeller?.data) {
      setFormikValues(getSeller?.data, formik, setLoading);
    } else {
      setLoading(true);
    }
    // eslint-disable-next-line
  }, [getSeller?.data, getSeller?.isSuccess]);

  async function handleSubmit(updates: ISeller) {
    try {
      await api.put(`/seller/${match.params.id}`, updates);
      addToast("Representante alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/representantes");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este e-mail já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleSendTutorial() {
    setLoadingSendTutorial(true);
    try {
      const response = await api.post(
        `/seller/welcome/${getSeller?.data?.cod}`
      );
      setModalSendTutorial(false);
      setLinkWelcome(response.data.id);
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingSendTutorial(false);
    }
  }

  async function handleShare() {
    try {
      //@ts-ignore
      await navigator?.share({
        title: "Boas vindas",
        url: linkWelcome,
      });
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <CreateAndDetailLayout isLoading={getSeller?.isLoading || loading}>
      <PanelAndDetailAndCreateHeader title={"Representante"} goBack>
        <div className="containerActions">
          <button
            type="button"
            className="buttonActionDatasheet"
            onClick={() => {
              setModalSendTutorial(true);
            }}
          >
            {
              <div>
                <span>
                  <IoIosSend color="rgb(2, 106, 166)" />
                </span>
                Enviar boas vindas
              </div>
            }
          </button>
        </div>
      </PanelAndDetailAndCreateHeader>

      <Tab
        tabs={[
          {
            description: "Cadastro",
            component: (
              <>
                <LayoutForm onSubmit={formik.handleSubmit}>
                  <Input
                    label="Código"
                    {...formik.getFieldProps("cod")}
                    readOnly={true}
                    styleInput={{
                      borderStyle: "dashed",
                    }}
                  />

                  <div className="field">
                    <label htmlFor="phone">Situação</label>
                    <ContainerCheck>
                      <button
                        type="button"
                        className={
                          formik.values.is_active === true ? "check" : ""
                        }
                        onClick={() => formik.setFieldValue("is_active", true)}
                      >
                        Ativa
                      </button>

                      <button
                        type="button"
                        onClick={() => formik.setFieldValue("is_active", false)}
                        className={
                          formik.values.is_active === false ? "check" : ""
                        }
                      >
                        Inativa
                      </button>
                    </ContainerCheck>
                  </div>

                  <GroupInput>
                    <Input
                      label="Nome completo"
                      {...formik.getFieldProps("fullName")}
                      error={
                        formik.touched.fullName && formik.errors.fullName
                          ? formik.errors.fullName
                          : undefined
                      }
                    />
                    <Input
                      label="Abreviação"
                      {...formik.getFieldProps("abbreviation")}
                      error={
                        formik.touched.abbreviation &&
                        formik.errors.abbreviation
                          ? formik.errors.abbreviation
                          : undefined
                      }
                    />
                  </GroupInput>

                  <GroupInput>
                    <Input
                      label="Email"
                      {...formik.getFieldProps("email")}
                      error={
                        formik.touched.email && formik.errors.email
                          ? formik.errors.email
                          : undefined
                      }
                    />
                    <Input
                      label="Telefone"
                      {...formik.getFieldProps("phone")}
                      error={
                        formik.touched.phone && formik.errors.phone
                          ? formik.errors.phone
                          : undefined
                      }
                    />
                  </GroupInput>

                  <GroupInput>
                    <Input
                      label="Marcas"
                      name="brands"
                      value={getSeller?.data?.brands
                        .map((a) => a.name)
                        .join(",")}
                      readOnly
                    />
                    <Input
                      label="Tipo vendedor"
                      name="brands"
                      value={getSeller?.data?.typeSeller}
                      readOnly
                    />
                  </GroupInput>

                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="phone">Gerente</label>
                      <ContainerCheck>
                        <button
                          type="button"
                          className={
                            formik.values.is_manager === true ? "check" : ""
                          }
                          onClick={() =>
                            formik.setFieldValue("is_manager", true)
                          }
                        >
                          Sim
                        </button>

                        <button
                          type="button"
                          onClick={() =>
                            formik.setFieldValue("is_manager", false)
                          }
                          className={
                            formik.values.is_manager === false ? "check" : ""
                          }
                        >
                          Não
                        </button>
                      </ContainerCheck>
                    </div>

                    <div className="field">
                      <label htmlFor="phone">Supervisor</label>
                      <ContainerCheck>
                        <button
                          type="button"
                          className={
                            formik.values.is_supervisor === true ? "check" : ""
                          }
                          onClick={() =>
                            formik.setFieldValue("is_supervisor", true)
                          }
                        >
                          Sim
                        </button>

                        <button
                          type="button"
                          onClick={() =>
                            formik.setFieldValue("is_supervisor", false)
                          }
                          className={
                            formik.values.is_supervisor === false ? "check" : ""
                          }
                        >
                          Não
                        </button>
                      </ContainerCheck>
                    </div>
                  </div>

                  <GroupInput>
                    <Input
                      label="Cód. Gerente"
                      name="brands"
                      value={getSeller?.data?.cod_manager ?? "-"}
                      readOnly
                    />
                    <Input
                      label="Cód. Supervisor"
                      name="brands"
                      value={getSeller?.data?.cod_supervisor ?? "-"}
                      readOnly
                    />
                  </GroupInput>

                  <h3>Grupos de produtos</h3>
                  <InputCheckboxV2
                    height="100px"
                    name="groupToSeller"
                    checks={
                      getSeller?.data?.groupsToSeller.map((item) =>
                        item.group.cod.toString()
                      ) ?? []
                    }
                    data={
                      getSeller?.data?.groupsToSeller.map((item) => ({
                        value: String(item.group.cod),
                        description: `${item.group.descrpition} - ${item.manager.abbreviation}(${item.manager.cod})`,
                      })) ?? []
                    }
                    onChange={() => {}}
                  />

                  <h3>Endereço</h3>
                  <Input
                    readOnly
                    label="CEP"
                    value={getSeller?.data?.addrres?.zip_code}
                    name="zip_code"
                  />

                  <GroupInput>
                    <Input
                      readOnly
                      label="UF"
                      value={getSeller?.data?.addrres?.uf}
                      name="uf"
                    />
                    <Input
                      readOnly
                      label="Cidade"
                      value={getSeller?.data?.addrres?.city}
                      name="city"
                    />
                  </GroupInput>

                  <GroupInput>
                    <Input
                      readOnly
                      label="Bairro"
                      value={getSeller?.data?.addrres?.neighborhood}
                      name="neighborhood"
                    />
                    <Input
                      readOnly
                      label="Logradouro"
                      value={getSeller?.data?.addrres?.street}
                      name="street"
                    />
                  </GroupInput>

                  {admin && (
                    <>
                      <h3>Alterar a senha</h3>

                      <Input
                        {...formik.getFieldProps("password")}
                        name="password"
                        label="Nova senha"
                        error={
                          formik.touched.password && formik.errors.password
                            ? formik.errors.password
                            : undefined
                        }
                      />

                      <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
                    </>
                  )}
                </LayoutForm>

                <HistoryAccess
                  data={
                    getSeller?.data
                      ? getSeller.data.access.map((acces) => ({
                          id: String(getSeller.data.id),
                          cod: String(getSeller.data.cod),
                          name: getSeller?.data?.fullName ?? "",
                          date: acces.date,
                          dateToString: acces.dateToString,
                        }))
                      : []
                  }
                />
              </>
            ),
          },
          {
            description: "Nextdata",
            component: (
              <LayoutForm onSubmit={(e) => e.preventDefault()}>
                <Input
                  label="Situação"
                  readOnly
                  value={getSeller?.data?.nextdata_active ? "ATIVO" : "INATIVO"}
                  name="nextdata_active"
                />

                <GroupInput>
                  <Input
                    name="nextdata_login"
                    value={getSeller?.data?.nextdata_login}
                    label="Login"
                    type="email"
                    isCopyClipboard
                    readOnly
                  />
                  <Input
                    name="nextdata_password"
                    value={getSeller?.data?.nextdata_password}
                    label="Senha"
                    isCopyClipboard
                    readOnly
                  />
                </GroupInput>
              </LayoutForm>
            ),
          },
          {
            description: "Equipe de vendas",
            component: (
              <LayoutForm onSubmit={(e) => e.preventDefault()}>
                <ul>
                  {getSeller?.data?.team.map((seller) => (
                    <li key={seller.id}>
                      <b>{seller.cod}</b> {seller.fullName} ({seller.typeSeller}
                      )
                    </li>
                  ))}
                </ul>
              </LayoutForm>
            ),
          },
        ].filter((f) =>
          Number(getSeller?.data?.team.length ?? 0) > 1
            ? true
            : f.description !== "Equipe de vendas"
        )}
      />

      <Modal
        title="Selecione o cliente"
        modalVisible={modalSendTutorial}
        setModalVisible={setModalSendTutorial}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <IoIosSend size={95} opacity={0.2} />
            </nav>
            <span>
              Você tem certeza que deseja, enviar as boas vindas ao "
              {getSeller?.data?.abbreviation}" ?
            </span>
          </div>
          <div className="buttonsPopUp">
            <button type="button" onClick={() => setModalSendTutorial(false)}>
              Cancelar
            </button>
            <button
              style={{ background: "rgb(2, 106, 166)" }}
              type="button"
              onClick={() =>
                loadingSendTutorial ? () => {} : handleSendTutorial()
              }
            >
              {!loadingSendTutorial ? (
                "Enviar"
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Loading
                    size={22}
                    borderSize={4}
                    colorLoading="rgba(255,255,255)"
                    borderColor="rgba(255,255,255, 0.3)"
                  />
                </div>
              )}
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        title="Boas vindas"
        modalVisible={!!linkWelcome}
        setModalVisible={() => {
          setLinkWelcome("");
        }}
        // headerOff={true}
        maxHeight={250}
        maxWidth={600}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <div
              style={{
                background: "#EEEEF2",
                borderRadius: 4,
                padding: "1rem",
              }}
            >
              <a
                style={{ color: "#000" }}
                target="_blank"
                rel="noopener noreferrer"
                href={`${APP_LINK}/bem-vindo/${linkWelcome}`}
              >{`${APP_LINK}/bem-vindo/${linkWelcome}`}</a>
            </div>
          </div>
          <div className="buttonsPopUp">
            {
              //@ts-ignore
              navigator?.share !== undefined ? (
                <button
                  style={{ background: "#fff" }}
                  type="button"
                  onClick={handleShare}
                >
                  Compartilhar
                </button>
              ) : (
                <button
                  style={{ background: "#fff" }}
                  type="button"
                  onClick={() => {
                    window.open(`${APP_LINK}/bem-vindo/${linkWelcome}`);
                  }}
                >
                  Abrir
                </button>
              )
            }

            <button
              style={{ background: "rgb(2, 106, 166)" }}
              type="button"
              onClick={() => {
                copy(`${APP_LINK}/bem-vindo/${linkWelcome}`);
                addToast("Link copiado", {
                  appearance: "info",
                  autoDismiss: true,
                });
              }}
            >
              Copiar
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>
    </CreateAndDetailLayout>
  );
};

export default Seller;
