import React, { ReactNode } from "react";
import Chart from "react-apexcharts";
import { BoxAnalytic } from "../../pages/analytic/styles";

interface BoxAnalyticCharBarProps {
  title: string;
  children?: ReactNode;

  obs?: JSX.Element;
  height?: number | string;

  dates: Date[];
  values: {
    name: string;
    data: number[];
  }[];
}

export const BoxAnalyticColumnCharBar = ({
  title,
  values,
  dates,
  obs,
  height = 200,
  children,
}: BoxAnalyticCharBarProps) => {
  return (
    <BoxAnalytic>
      <div>
        <h3>{title}</h3>

        {children}
      </div>

      <div style={{ width: "100%" }}>
        <Chart
          type="bar"
          height={height}
          series={values}
          options={{
            xaxis: {
              labels: {
                formatter: (value) =>
                  new Date(value).toLocaleDateString("pt-BR", {
                    month: "short",
                    year: "2-digit",
                  }),
              },
              categories: dates,
            },
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "12px",
                colors: ["#304758"],
              },
            },

            plotOptions: {
              bar: {
                borderRadius: 4,
                dataLabels: {
                  position: "top",
                },
              },
            },
          }}
        />
        {obs && <span className="span-alert">{obs}</span>}
      </div>
    </BoxAnalytic>
  );
};
