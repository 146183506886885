import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;

  background-color: #fff;

  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 80px;

  grid-template-areas:
    "LOGO BUTTONS"
    "PRIVACYPOLICY PRIVACYPOLICY";

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 80px;

    grid-template-areas: "LOGO" "BUTTONS" "PRIVACYPOLICY";
  }

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }
`;

export const ContainerLogo = styled.div`
  grid-area: LOGO;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 95%;
  margin-bottom: 30px;

  svg {
    width: 100%;
    max-width: 450px;
    margin-left: 30px;
    padding: 0 8px;
  }

  span {
    margin-top: 20px;
    color: #444;
    font-weight: 600;
    font-size: 20px;
    text-align: center;

    padding: 0 8px;
  }

  @media (max-width: 900px) {
    svg {
      width: 80%;
      max-width: 300px;
      margin-top: 35px;
    }
  }
`;

export const ContainerButtons = styled.div`
  grid-area: BUTTONS;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: center;

  flex-direction: column;

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 6px;
    margin: 5px 0 5px 0;
    padding: 10px 10px;
    width: 70%;
    height: 65px;

    text-decoration: none;
    font-weight: 700;
    font-size: 22px;

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

    svg {
      margin-right: 10px;
    }

    transition: all 0.4s;

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      transform: translateX(20px);
    }
  }

  @media (max-width: 900px) {
    justify-content: flex-start;
    align-items: center;

    a {
      align-items: center;
      width: 80%;
    }
  }

  a:nth-child(1) {
    background-color: #f0f0f0;
    color: #d0222d;
  }
  a:nth-child(2) {
    background-color: #d0222d;
    color: #fff;
  }
  a:nth-child(3) {
    background-color: #fff;
    color: #444;
  }
  a:nth-child(4) {
    background-color: #444;
    color: #fff;
  }
`;

export const ContainerPrivacyPolicy = styled.footer`
  grid-area: PRIVACYPOLICY;
  width: 90%;
  padding: 0.6rem 1rem;
  border-top: 2px solid #e2e2e2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 1rem;

  span {
    font-weight: 300;
    font-size: 0.875rem;
  }
  a {
    color: #333;
    span {
      font-weight: 600;
    }
  }

  @media (max-width: 900px) {
    span {
      font-size: 1rem;
    }
  }
`;
