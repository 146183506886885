import React, { useRef, useState } from "react";

import moment from "moment";
import * as userLocalStorage from "../../../../../service/localStorage/user";
import * as providerLocalStorage from "../../../../../service/localStorage/provider";
import { useToasts } from "react-toast-notifications";
import { Container } from "./styles";
import { InfoDevolution } from "../../../styles";
import { IoMdSend } from "react-icons/io";

import * as sellerLocalStorage from "../../../../../service/localStorage/seller";

interface DatasheetActionsChatProps {
  isActive: boolean;

  admin?: boolean;
  provider?: boolean;
  seller?: boolean;

  chat: IMessage[];
  sendMessage: (message: string, sendUser: string) => Promise<void>;
}

export interface IMessage {
  id?: number;
  name?: string;
  typeUser: string;
  textMessage?: string;
  idDevolution?: number;
  created_at?: Date;
}

export const DatasheetActionsChat: React.FC<DatasheetActionsChatProps> = ({
  isActive,
  admin,
  chat,
  provider,
  seller,

  sendMessage,
}) => {
  const sellerStorage =
    sellerLocalStorage.getUser() as sellerLocalStorage.SellerProps;
  const { addToast } = useToasts();
  const messageRef = useRef(null);
  const userStorage = userLocalStorage.getUser() as userLocalStorage.User;
  const providerStorage =
    providerLocalStorage.getUser() as providerLocalStorage.Provider;

  const [textChat, setTextChat] = useState("");

  function selectAuthor(typeUser: string): "to" | "i" {
    const author = typeUser as
      | "admin"
      | "seller"
      | "seller_manager"
      | "provider";

    if (admin && author === "admin") {
      return "i";
    }
    if (provider && author === "provider") {
      return "i";
    }

    if (seller && author === "seller" && !sellerStorage.is_manager) {
      return "i";
    }

    if (seller && author === "seller_manager" && sellerStorage.is_manager) {
      return "i";
    }

    return "to";
  }

  async function hadleSubitChat(e: React.FormEvent) {
    e.preventDefault();

    if (!textChat) return;

    try {
      const sendUser = admin
        ? `${userStorage.name} ${userStorage.lastname}`
        : providerStorage.fullname;

      await sendMessage(textChat, sendUser);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }

    setTextChat("");

    const inputText = messageRef as React.RefObject<HTMLInputElement>;

    if (inputText.current) {
      inputText.current.value = "";
    }
  }

  return (
    <Container style={{ display: !isActive ? "none" : "" }}>
      <InfoDevolution>
        <form onSubmit={hadleSubitChat}>
          <div style={{ maxHeight: "60vh" }} className="bodyChat">
            {chat.map((item, i) => {
              let messageDate = new Date(item.created_at as Date);

              // "admin" | "seller" | "seller_manager"

              return (
                <div key={i} className={selectAuthor(item.typeUser)}>
                  <div className="containerchat">
                    <div className="containerMessage">
                      <header>
                        <span>{item.name}</span>
                      </header>
                      <p>{item.textMessage}</p>
                    </div>
                    <footer>
                      {moment(messageDate).format(" hh:mm A - DD/MM/YYYY")}
                      {` - `}
                      {item.typeUser === "client"
                        ? "Cliente"
                        : item.typeUser === "admin"
                        ? "Operador"
                        : item.typeUser === "provider"
                        ? "Fornecedor"
                        : item.typeUser === "seller_manager"
                        ? "Gerente"
                        : "Representante"}
                    </footer>
                  </div>
                </div>
              );
            })}
          </div>

          {
            <footer>
              <div>
                <textarea
                  placeholder="Digite uma nova mensagem"
                  defaultValue={textChat}
                  ref={messageRef}
                  onBlur={(e) => {
                    setTextChat(e.target.value);
                  }}
                  typeof="text"
                />
                <button type="submit">
                  <IoMdSend size={24} color="#fff" />
                </button>
              </div>
            </footer>
          }
        </form>
      </InfoDevolution>
    </Container>
  );
};
