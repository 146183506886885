import React from "react";
import { BoxAnalyticCount } from "../../components/Analytic/BoxAnalyticCount";
import { useBilletsCount } from "../../hook/queries/useBillet";
import { useDevolutionsCount } from "../../hook/queries/useDevolution";
import { useOrders } from "../../hook/queries/useOrders";
import * as SellerStorage from "../../service/localStorage/seller";
import { ContainerBoxAnalytic } from "../analytic/styles";

interface HomeAnalyticProps {
  userType: string;
}

export const HomeAnalyticSeller = ({ userType }: HomeAnalyticProps) => {
  const SellerData = SellerStorage.getUser() as SellerStorage.SellerProps;

  const totalBillet = useBilletsCount();
  const totalDevolution = useDevolutionsCount({
    brandManager: userType === "sellerManager" ? SellerData?.cod : undefined,
    pendencyType:
      userType === "sellerManager" ? 4 : userType === "seller" ? 3 : undefined,
  });
  const totalOrdersExpiredTitles = useOrders(
    1,
    {
      highlighter: "TITULO VENCIDO",
      status: "Aguardando Faturamento, Recusado",
      type: "pronta entrega",
      refuse: 39,
    },
    1
  );
  const totalOrdersCreditLimit = useOrders(
    1,
    {
      status: "Recusado",
      type: "pronta entrega",
      refuse: 44,
    },
    1
  );

  return (
    <>
      {["seller", "sellerManager"].includes(userType) && (
        <>
          <ContainerBoxAnalytic style={{ marginTop: 24, marginBottom: 0 }}>
            <BoxAnalyticCount
              description="Devoluções Pendentes"
              qtd={totalDevolution.data?.totalCount ?? 0}
              isLoading={totalDevolution.isLoading}
              externalLink={
                userType === "seller"
                  ? `/representante/devolucoes?page=1&finisher=0&pendencyType=3`
                  : `/representante/devolucoes?page=1&finisher=0&pendencyType=4&brandManager=${SellerData?.cod}`
              }
            />

            <BoxAnalyticCount
              description="Boletos em Aberto"
              qtd={totalBillet.data?.totalCount ?? 0}
              isLoading={totalBillet.isLoading}
              externalLink={`/representante/boletos`}
              obs="Boletos em aberto referente á 15 dias antecedente ao dia de hoje."
            />
          </ContainerBoxAnalytic>

          <ContainerBoxAnalytic style={{ marginTop: 10, marginBottom: 0 }}>
            <BoxAnalyticCount
              description="Pedidos pronta entrega pendentes (TITULO VENCIDO)"
              qtd={totalOrdersExpiredTitles.data?.totalCount ?? 0}
              isLoading={totalOrdersExpiredTitles.isLoading}
              externalLink={`/representante/pedidos/situacao/pedidos?page=1&status=Aguardando+Faturamento,Recusado&refuse=44&type=pronta+entrega&highlighter=TITULO+VENCIDO`}
            />
            <BoxAnalyticCount
              description="Pedidos pronta entrega pendentes (LIMITE CREDITO)"
              qtd={totalOrdersCreditLimit.data?.totalCount ?? 0}
              isLoading={totalOrdersCreditLimit.isLoading}
              externalLink={`/representante/pedidos/situacao/pedidos?page=1&type=pronta+entrega&status=Recusado&refuse=44`}
            />
          </ContainerBoxAnalytic>
        </>
      )}
    </>
  );
};
