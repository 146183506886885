import { useQuery, UseQueryResult } from "react-query";
import api from "../../service/api";

export type Concept = {
  cod: number;
  description: string;
};

export async function getConcepts(): Promise<Concept[]> {
  const { data } = await api.get<{ contents: Concept[] }>("/concept");

  return data.contents;
}

export function useConcepts(): UseQueryResult<Concept[]> {
  return useQuery(["concepts"], () => getConcepts(), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
