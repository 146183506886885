import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import api from "../../../service/api";

import "react-credit-cards/es/styles-compiled.css";
import Modal from "../../../components/Modal";
import * as userLocalStorage from "../../../service/localStorage/user";

import Loading from "../../../components/loadings/Loading";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle, FiSearch } from "react-icons/fi";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Select } from "../../../components/Form/Select";
import useOutsideAlerter from "../../../hook/outsideAlerter";
import { GatewayDefaultConfig } from "../../config/Gateway";
import {
  ButtunSubmit,
  Container,
  ContainerForm,
  ContainerList,
  Error,
  Filter,
  FilterCheck,
  FilterSelects,
  FooterContainer,
  Form,
  MainFilter,
  ModalBody,
  TableContainer,
} from "../styles";

interface IHeaderProps {
  admin: boolean;
}

interface IClient {
  id?: string;
  cod?: number;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
}

interface IUser {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
}

interface IClietsApi {
  clients: IClient[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IFilter {
  cod?: string;
  company_name?: string;
  cnpj?: string;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

const CreateDecolucao: React.FC<IHeaderProps> = ({ admin }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const outSideRefFilter = useRef(null);

  const [loading, setLoading] = useState(false);

  const userStorage = userLocalStorage.getUser() as IUser;

  const [clints, setClients] = useState<IClient[]>([]);
  const [gateway, setGateway] = useState<GatewayDefaultConfig>(
    {} as GatewayDefaultConfig
  );
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 10 } as IRequestPagination);

  const [modalVisible, setModalVisible] = useState(false);
  const [filters, setFilters] = useState<IFilter>({});
  const [filterView, setFilterView] = useState(false);

  const formikForm = useFormik({
    validateOnChange: false,
    initialValues: {
      debt: {
        value: undefined,
        codeOrder: undefined,
        type: undefined,
        parcel: undefined,
        dueDate: undefined,
      },

      client: {
        cod: undefined,
      },

      user: {
        id: userStorage.id,
      },
    },

    validationSchema: Yup.object({
      client: Yup.object().shape({
        cod: Yup.string().test(
          "is_Admin",
          "Cliente é obrigatório.",
          function (value) {
            let error = true;

            if (Boolean(admin) && value === undefined) error = false;

            return error;
          }
        ),
      }),
      debt: Yup.object().shape({
        codeOrder: Yup.string().test(
          "is_order",
          "Código pedido é obrigatório.",
          function (value) {
            let error = true;

            if (formikForm.values.debt.type === "1") {
              if (isNaN(Number(value))) error = false;
            }

            return error;
          }
        ),

        value: Yup.number().required("Valor é obrigatório"),
        parcel: Yup.string().required("Máximo de Parcelas é obrigatório"),
        type: Yup.string().required("Tipo é obrigatório"),
        dueDate: Yup.date().required("Data Vencimento é obrigatório"),
      }),
    }),

    onSubmit: (values) => {
      hadleSubmit(values);
    },
  });

  useEffect(() => {
    (async () => {
      if (admin) {
        try {
          const gatewayDefault = await api.get<GatewayDefaultConfig>(
            `/payment/gateway/config/default`
          );

          setGateway(gatewayDefault.data);

          const clients = await api.get<IClietsApi>("/clientPJ", {
            params: { ...filters, ...requestPagination },
          });
          const { limit, page, pages, total } = clients.data;
          setClients(clients.data.clients);
          setPagination({ limit, page, pages, total });
        } catch (error) {
          // return addToast('Desculpe, ocorreu um erro interno, Tente novamente mais tarde',{
          //   appearance: 'error',
          //   autoDismiss: true,})
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, requestPagination]);

  useEffect(() => {
    if (formikForm?.values.debt.type && formikForm?.values.debt.value) {
      const setParcel =
        String(formikForm.values.debt.type) === "1"
          ? String(gateway.parcel)
          : String(formikForm.values.debt.type) === "2"
          ? String(gateway.parcel_renegotiation)
          : "0";

      formikForm.setFieldValue("debt.parcel", setParcel.toString());
    }

    // eslint-disable-next-line
  }, [formikForm?.values.debt.type, formikForm?.values.debt.value]);

  function handleClearFilter() {
    setFilters({});
    formikFilter.setFieldValue("cod", "");
    formikFilter.setFieldValue("cnpj", "");
    formikFilter.setFieldValue("company_name", "");
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setRequestPagination({
      ...requestPagination,
      pageRequest: 0,
    });
    setFilterView(false);
  }

  async function hadleSubmit(props: any) {
    setLoading(true);

    try {
      await api.post("/debt", {
        ...props,
      });

      setLoading(false);

      addToast("Negociação criada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push(`/admin/negociacoes/abertas`);
    } catch (error) {
      setLoading(false);

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
    }
  }

  function handleSelectClient(
    cod: number | undefined,
    company_name: string | undefined
  ) {
    formikForm.setFieldValue("client.cod", cod);
    formikForm.setFieldValue("client.company_name", company_name);
    setModalVisible(false);
  }

  function hadleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data: any;

    if (name === "debt.value") {
      data = Number(value.replace(/\D/g, "")) / 100;
    }

    formikForm.setFieldValue(name, data);
  }

  useOutsideAlerter(outSideRefFilter, () => {
    if (filterView) setFilterView(false);
  });

  const formikFilter = useFormik({
    initialValues: {
      cod: filters.cod,
      company_name: filters.company_name,
      cnpj: filters.cnpj,
    },

    validationSchema: Yup.object({}),

    onSubmit: (data) =>
      hadleFilter({
        cnpj: data.cnpj?.trim()?.toUpperCase(),
        cod: data.cod?.trim(),
        company_name: data.company_name?.trim()?.toUpperCase(),
      }),
  });

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>
        <h2>Nova negociação em aberto</h2>
      </header>
      <ContainerForm>
        <Form
          onSubmit={
            loading
              ? (e) => {
                  e.preventDefault();
                }
              : formikForm.handleSubmit
          }
        >
          <h3>Cliente</h3>
          <div className="field-group">
            <div className="field">
              <label htmlFor="cod">Código Cliente</label>
              <input
                style={{ borderStyle: "dashed" }}
                className="cod"
                type="number"
                {...formikForm.getFieldProps("client.cod")}
                readOnly={true}
              />
            </div>

            <div className="field">
              <label htmlFor="company_name">Razão Social</label>
              <input
                style={{ borderStyle: "dashed" }}
                className="company_name"
                type="text"
                {...formikForm.getFieldProps("client.company_name")}
                readOnly={true}
              />
            </div>

            {modalVisible && (
              <Modal
                title="Selecione o cliente"
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                maxHeight={570}
              >
                <ModalBody>
                  <Filter>
                    <button
                      onClick={() => {
                        setFilterView(!filterView);
                      }}
                      type="button"
                    >
                      <FiSearch />
                    </button>
                    <input
                      onClick={() => {
                        setFilterView(!filterView);
                      }}
                      type="text"
                      name="filter"
                      id="filter"
                      readOnly={true}
                    />
                    <FilterSelects>
                      <ul>
                        {filters.cod && (
                          <li>
                            <span>COD: {filters.cod} </span>
                          </li>
                        )}
                        {filters.company_name && (
                          <li>
                            <span>Razão social: {filters.company_name}</span>
                          </li>
                        )}
                        {filters.cnpj && (
                          <li>
                            <span>CNPJ: {filters.cnpj}</span>
                          </li>
                        )}
                      </ul>
                    </FilterSelects>

                    {filterView && (
                      <FilterCheck ref={outSideRefFilter}>
                        {/* <HeaderFilter>
                                <button type='button' > Fechar </button>
                              </HeaderFilter> */}
                        <MainFilter>
                          <Form onSubmit={formikFilter.handleSubmit}>
                            <div className="field-group">
                              <div className="field">
                                <label htmlFor="cod">COD</label>
                                <input
                                  id="cod"
                                  {...formikFilter.getFieldProps("cod")}
                                />
                              </div>
                              <div className="field">
                                <label htmlFor="company_name">
                                  Razão social
                                </label>
                                <input
                                  id="company_name"
                                  {...formikFilter.getFieldProps(
                                    "company_name"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="field">
                              <label htmlFor="cnpj">CNPJ</label>
                              <input
                                id="cnpj"
                                {...formikFilter.getFieldProps("cnpj")}
                              />
                            </div>

                            <footer>
                              <button
                                className="clearFilter"
                                type="button"
                                onClick={() => {
                                  handleClearFilter();
                                }}
                              >
                                LIMPAR FILTRO
                              </button>
                              <button type="submit">BUSCAR</button>
                            </footer>
                          </Form>
                        </MainFilter>
                      </FilterCheck>
                    )}
                  </Filter>
                  <ContainerList>
                    <TableContainer>
                      <table>
                        <thead>
                          <tr>
                            <th>COD</th>
                            <th>RAZAO SOCIAL</th>
                            <th>CNPJ</th>
                          </tr>
                        </thead>

                        <tbody>
                          {clints.map((client: IClient) => (
                            <tr
                              key={client.id}
                              onClick={() =>
                                handleSelectClient(
                                  client.cod,
                                  client.company_name
                                )
                              }
                            >
                              <td> {client.cod} </td>
                              <td> {client.company_name} </td>
                              <td> {client.cnpj} </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableContainer>
                    <FooterContainer>
                      <span>
                        Mostrando {clints.length} de {pagination.total}{" "}
                        resultados
                      </span>

                      <div>
                        <button
                          className={pagination.page <= 0 ? "buttonNull" : ""}
                          type="button"
                          onClick={
                            pagination.page <= 0 ? () => {} : hadlePreviousPage
                          }
                        >
                          ANTERIOR
                        </button>
                        <p style={{ marginRight: 8 }}>{pagination.page + 1}</p>
                        <button
                          className={
                            pagination.page >= pagination.pages
                              ? "buttonNull"
                              : ""
                          }
                          type="button"
                          onClick={
                            pagination.page >= pagination.pages
                              ? () => {}
                              : hadleNextPage
                          }
                        >
                          PROXIMO
                        </button>
                      </div>
                    </FooterContainer>
                  </ContainerList>
                </ModalBody>
              </Modal>
            )}
          </div>

          <div className="field">
            <div className="selectClient">
              <button
                type="button"
                onClick={() => setModalVisible(!modalVisible)}
              >
                Selecionar cliente
              </button>
            </div>

            {formikForm.touched.client?.cod && formikForm.errors.client?.cod ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formikForm.errors.client?.cod} </span>
              </Error>
            ) : null}
          </div>

          <h3>Configurações da negociação</h3>
          <div className="field">
            <label htmlFor="dbtValue">Data Vencimento</label>
            <input type="date" {...formikForm.getFieldProps("debt.dueDate")} />
            {formikForm.touched.debt?.dueDate &&
            formikForm.errors.debt?.dueDate ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formikForm.errors.debt?.dueDate} </span>
              </Error>
            ) : null}
          </div>

          <h3>Informações sobre pagamento</h3>
          <div className="field-group">
            <div className="field">
              <label htmlFor="dbtValue">Valor</label>
              <input
                name="debt.value"
                type="text"
                onChange={hadleInputChange}
                value={
                  formikForm.values.debt.value
                    ? Number(formikForm.values.debt.value).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )
                    : ""
                }
                // {...formikForm.getFieldProps("debt.value")}
              />
              {formikForm.touched.debt?.value &&
              formikForm.errors.debt?.value ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikForm.errors.debt?.value} </span>
                </Error>
              ) : null}
            </div>

            <Select
              label="Tipo"
              {...formikForm.getFieldProps("debt.type")}
              data={[
                { name: "Renegociação", value: 2 },
                { name: "Pedido", value: 1 },
              ]}
              error={
                formikForm.touched.debt?.type && formikForm.errors.debt?.type
                  ? formikForm.errors.debt?.type
                  : undefined
              }
            />
          </div>

          <GroupInput>
            <Select
              label="Máximo de Parcelas"
              {...formikForm.getFieldProps("debt.parcel")}
              data={new Array(
                String(formikForm.values.debt.type) === "1"
                  ? gateway.parcel
                  : String(formikForm.values.debt.type) === "2"
                  ? gateway.parcel_renegotiation
                  : 0
              )
                .fill(null)
                .map((_, index) => ({
                  name: `${index + 1} X
                ${
                  formikForm.values.debt.value
                    ? Number(
                        Number(formikForm.values.debt.value) / (index + 1)
                      ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    : "-"
                }`,
                  value: index + 1,
                }))}
              error={
                formikForm.touched.debt?.parcel &&
                formikForm.errors.debt?.parcel
                  ? formikForm.errors.debt?.parcel
                  : undefined
              }
            />

            {formikForm.values.debt.type === "1" && (
              <div className="field">
                <label htmlFor="codeOrder">Código do pedido</label>
                <input
                  id="codeOrder"
                  type="number"
                  maxLength={10}
                  {...formikForm.getFieldProps("debt.codeOrder")}
                />
                {formikForm.touched.debt?.codeOrder &&
                formikForm.errors.debt?.codeOrder ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formikForm.errors.debt?.codeOrder} </span>
                  </Error>
                ) : null}
              </div>
            )}
          </GroupInput>

          <div className="containerButton">
            <ButtunSubmit type="submit">
              {loading ? (
                <Loading
                  size={22}
                  borderSize={4}
                  colorLoading="rgba(255,255,255)"
                  borderColor="rgba(255,255,255, 0.3)"
                />
              ) : (
                "Criar"
              )}
            </ButtunSubmit>
          </div>
        </Form>
      </ContainerForm>
    </Container>
  );
};

export default CreateDecolucao;
