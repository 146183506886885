import filesize from "filesize";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FiDownload, FiSearch } from "react-icons/fi";
import { IoMdCloseCircle, IoMdTrash } from "react-icons/io";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { FileProps, HeaderDatasheetAndDatasheet } from "../../";
import { IPermissionUser } from "../../../../../@types";
import Loading from "../../../../../components/loadings/Loading";
import Modal from "../../../../../components/ModalLib";
import { ContainerPreviews } from "../../../../../components/ModalModificationDatasheet/Files/styles";
import api from "../../../../../service/api";
import { dataZero } from "../../../../../utils/dataProcessing";
import selectIcon from "../../../../../utils/selectIcon";
import { PopUpConfirmation } from "../../../../detail/styles";
import {
  Filter,
  FilterCheck,
  FilterSelects,
  FooterContainer,
  Form,
} from "../../../../panel/styles";
import { Container } from "./styles";

interface DatasheetActionsFileProps {
  isActive: boolean;
  data: HeaderDatasheetAndDatasheet;
  onRequestUpdate: () => void;

  admin?: boolean;
  permission?: IPermissionUser;
  provider?: boolean;
}

interface IFilter {
  categoryFile: string;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IFilterValue {
  name: string;
  value: string;
}

interface CategotyFile {
  id: number;
  name: string;
}

export const DatasheetActionsFile: React.FC<DatasheetActionsFileProps> = ({
  isActive,
  data,
  onRequestUpdate,
  admin,
  permission,
}) => {
  const outSideRef = useRef(null);
  const { addToast } = useToasts();

  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [fileDelete, setFileDelete] = useState<FileProps>();
  const [loadingModal, setLoadingModal] = useState(false);

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({} as IFilter);

  const [categoriesFile, setCategoriesFile] = useState<IFilterValue[]>([]);
  const [categoriesFilesDB, setCategoriesFilesDB] = useState<CategotyFile[]>(
    []
  );

  const [files, setFiles] = useState<FileProps[]>([]);
  // const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  // const [requestPagination, setRequestPagination] =
  //   useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  useEffect(() => {
    (async () => {
      const gategoriesFileDB = await api.get<CategotyFile[]>(
        `headerDatasheet/datasheet/categoryFile`
      );
      setCategoriesFilesDB(gategoriesFileDB.data);
    })();
  }, []);

  useEffect(() => {
    setFiles(data.files);

    const categories = data.files.map((f) => f.category_file);
    var reduced: IFilterValue[] = [];

    categories.forEach((item) => {
      var duplicated =
        reduced.findIndex((redItem) => {
          return String(item.id) === String(redItem.value);
        }) > -1;

      if (!duplicated) {
        reduced.push({ name: item.name, value: String(item.id) });
      }
    });

    setCategoriesFile(reduced);
  }, [data]);

  useEffect(() => {
    if (filters.categoryFile) {
      const normalized = data.files.filter(
        (f) => String(f.category_file.id) === String(filters.categoryFile)
      );

      setFiles(normalized);
    } else {
      setFiles(data.files);
    }
  }, [filters, data.files]);

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    // setRequestPagination({
    //   ...requestPagination,
    //   pageRequest: 0,
    // });
    setFilterView(false);
  }

  async function handleDeleteFile(id: number) {
    setLoadingModal(true);
    try {
      await api.delete(`/headerDatasheet/datasheet/files/${id}`);
      onRequestUpdate();
      setConfirmationDelete(false);

      setLoadingModal(false);
      addToast("Arquivo excluído com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setLoadingModal(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function onChangeSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    const updateFiles = files.filter((f) => f.name !== null);

    const [, idFile] = e.target.name.split("-");
    const findOneFile = updateFiles.find((f) => +f.id === +idFile);
    if (!findOneFile) return;

    findOneFile.category_file.id = +e.target.value;

    try {
      const updateFile = {
        categoryId: findOneFile.category_file.id,
      };
      addToast("Alterando categoria...", {
        appearance: "info",
        autoDismiss: true,
      });
      await api.put(`/headerDatasheet/datasheet/files/${idFile}`, updateFile);
      setFiles(updateFiles);
      addToast("Categoria alterado com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  // function hadleNextPage() {
  //   setRequestPagination({
  //     ...requestPagination,
  //     pageRequest: pagination.page + 1,
  //   });
  // }
  // function hadlePreviousPage() {
  //   setRequestPagination({
  //     ...requestPagination,
  //     pageRequest: pagination.page - 1,
  //   });
  // }

  return (
    <>
      <Container style={{ display: !isActive ? "none" : "" }}>
        <Filter>
          <button
            onClick={() => {
              setFilterView(!filterView);
            }}
            type="button"
          >
            <FiSearch />
          </button>
          <input
            onClick={() => {
              setFilterView(!filterView);
            }}
            type="text"
            name="filter"
            id="filter"
            readOnly={true}
          />
          <FilterSelects>
            <ul>
              {filters.categoryFile && (
                <li>
                  <span>
                    TIPO:{" "}
                    {
                      categoriesFile.find(
                        (f) => String(f.value) === filters.categoryFile
                      )?.name
                    }
                  </span>
                  <IoMdCloseCircle
                    onClick={() => setFilters({ ...filters, categoryFile: "" })}
                  />
                </li>
              )}
            </ul>
          </FilterSelects>

          {filterView && (
            <FilterCheck ref={outSideRef} style={{ zIndex: 3 }}>
              <nav style={{ justifyContent: "end", top: 0 }}>
                <button
                  onClick={() => {
                    setFilterView(false);
                  }}
                  style={{
                    top: -35,
                    position: "absolute",
                  }}
                >
                  Fechar
                </button>
              </nav>
              <main>
                <Formik
                  initialValues={{
                    categoryFile: filters.categoryFile,
                  }}
                  validationSchema={Yup.object({})}
                  onSubmit={(data) =>
                    hadleFilter({
                      ...data,
                    })
                  }
                >
                  {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                      <div className="field-group">
                        <div className="field">
                          <label htmlFor="categoryFile">TIPO</label>
                          <select {...formik.getFieldProps("categoryFile")}>
                            <option value=""></option>
                            {categoriesFile.map((f) => (
                              <option key={f.value} value={f.value}>
                                {f.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <footer>
                        <button
                          className="clearFilter"
                          type="button"
                          onClick={() => {
                            setFilterView(false);
                            setFilters({} as IFilter);
                          }}
                        >
                          LIMPAR FILTRO
                        </button>
                        <button type="submit">BUSCAR</button>
                      </footer>
                    </Form>
                  )}
                </Formik>
              </main>
            </FilterCheck>
          )}
        </Filter>

        <ContainerPreviews>
          {files &&
            files.map((file, index) => (
              <li key={index}>
                {!file.isLink && (
                  <img
                    src={
                      selectIcon(file.mimetype)
                        ? selectIcon(file.mimetype)
                        : file.url
                    }
                    alt={file.name}
                  />
                )}

                <div className="fileInfo">
                  {!file.isLink ? (
                    <div>
                      <strong>{file.name}</strong>
                      <span>{filesize(file.size)}</span>
                    </div>
                  ) : (
                    <div style={{ height: 55 }}>
                      <strong>{file.name}</strong>

                      <div
                        style={{
                          display: "flex",
                          height: "60%",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ marginBottom: 2, width: "8ch" }}>
                          Acessar:
                        </span>
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            height: "100%",
                            background: "#fff",
                            padding: 4,
                            borderRadius: 6,
                            boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <p
                            style={{
                              maxWidth: "50ch",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <strong>{file.url}</strong>
                          </p>
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="action-file">
                    <div className="field-file">
                      <label htmlFor="category">Tipo:</label>
                      <select
                        name={`category-${file.id}-${file.category_file.id}`}
                        disabled={
                          !admin
                            ? true
                            : !data.datasheetId
                            ? permission && permission.datasheet > 2
                              ? false
                              : true
                            : !data.isClassic
                            ? permission && permission.datasheet > 2
                              ? false
                              : true
                            : true
                        }
                        onChange={onChangeSelect}
                        style={{ borderStyle: admin ? undefined : "solid" }}
                        value={file.category_file.id}
                        className={
                          !file.category_file.id
                            ? "fileInfo-field-required"
                            : ""
                        }
                      >
                        {categoriesFilesDB.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="field-file">
                      <span>
                        Criação:
                        {" " +
                          new Date(file.created_at).toLocaleDateString(
                            "pt-BR",
                            {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                      </span>
                    </div>

                    <a
                      href={file.url}
                      className="download-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FiDownload size={"1.8rem"} />
                    </a>

                    {admin && permission && permission.datasheet > 2 && (
                      <button
                        type="button"
                        onClick={() => {
                          setFileDelete(file);
                          setConfirmationDelete(true);
                        }}
                      >
                        <IoMdTrash size={"1.8rem"} />
                      </button>
                    )}
                  </div>
                </div>
              </li>
            ))}
        </ContainerPreviews>

        <FooterContainer style={{ marginBottom: 0 }}>
          <span>
            Mostrado {dataZero(Number(files.length))} de{" "}
            {dataZero(Number(files.length))} resultados
          </span>
          {/* <span>
          Mostrado{" "}
          {dataZero(Number(files.length + pagination.limit * pagination.page))}{" "}
          de {dataZero(Number(pagination.total))} resultados
        </span>

        <div>
          <button
            type="button"
            className={pagination.page < 1 ? "buttonNull" : ""}
            onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
          >
            ANTERIOR
          </button>
          <p style={{ marginRight: 8 }}>{dataZero(pagination.page + 1)}</p>
          <button
            className={pagination.page >= pagination.pages ? "buttonNull" : ""}
            type="button"
            onClick={
              pagination.page >= pagination.pages ? () => {} : hadleNextPage
            }
          >
            PROXIMO
          </button>
        </div> */}
        </FooterContainer>
      </Container>

      {confirmationDelete && fileDelete && (
        <Modal
          title="Selecione o cliente"
          modalVisible={confirmationDelete}
          setModalVisible={setConfirmationDelete}
          headerOff={true}
          maxHeight={350}
          maxWidth={650}
        >
          <PopUpConfirmation>
            <div className="headerPopUp">
              <nav>
                <FaTrashAlt size={95} opacity={0.2} />
              </nav>
              <span>
                Você tem certeza que deseja excluir este arquivo "
                {fileDelete.name}"?
              </span>
            </div>
            <div className="buttonsPopUp">
              <button
                type="button"
                onClick={() => setConfirmationDelete(false)}
              >
                Cancelar
              </button>
              <button
                style={{ background: "#d21e26" }}
                type="button"
                onClick={() =>
                  loadingModal ? () => {} : handleDeleteFile(fileDelete.id)
                }
              >
                {!loadingModal ? (
                  "Deletar"
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Loading
                      size={22}
                      borderSize={4}
                      colorLoading="rgba(255,255,255)"
                      borderColor="rgba(255,255,255, 0.3)"
                    />
                  </div>
                )}
              </button>
            </div>
          </PopUpConfirmation>
        </Modal>
      )}
    </>
  );
};
