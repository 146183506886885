import filesize from "filesize";
import React, { useEffect, useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { MdAddBox } from "react-icons/md";
import { FilesProps } from "../";
import { FileProps } from "../../../pages/detail/Datasheet";
import api from "../../../service/api";
import selectIcon from "../../../utils/selectIcon";
import { Input } from "../../Form/Input";
import { ContainerPreviews } from "./styles";

interface FileComponentProps {
  fileQueryOver?: FileProps[];

  files: FilesProps[];
  setFiles: React.Dispatch<React.SetStateAction<FilesProps[]>>;
}

interface CategotyFile {
  id: number;
  name: string;
}

export const LinkFile: React.FC<FileComponentProps> = ({
  files,
  setFiles,
  fileQueryOver,
}) => {
  const [categoriesFile, setCategoriesFile] = useState<CategotyFile[]>([]);

  useEffect(() => {
    (async () => {
      const gategoriesFileDB = await api.get<CategotyFile[]>(
        `headerDatasheet/datasheet/categoryFile`
      );
      setCategoriesFile(gategoriesFileDB.data);
    })();
  }, []);

  async function handleRemoveFile(indece: number) {
    const arrDelete = files.filter((f, index) => index !== indece);

    setFiles(arrDelete);
  }

  function onChangeSelect(e: React.ChangeEvent<HTMLSelectElement>) {
    const updateFiles = files.filter((e) => e);

    const [, index] = e.target.name.split("-");
    const findOneFile = updateFiles.find((_, indexF) => +indexF === +index);
    if (!findOneFile) return;
    findOneFile.category = e.target.value;

    setFiles(updateFiles);
  }
  function onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
    const updateFiles = files.filter((e) => e);

    const [name, index] = e.target.name.split("-");
    const findOneFile = updateFiles.find((_, indexF) => +indexF === +index);
    if (!findOneFile) return;

    //@ts-ignore
    findOneFile[name.trim()] = e.target.value;

    setFiles(updateFiles);
  }

  return (
    <>
      <div>
        <div className="container-subtitle">
          <h3>Link dos Arquivos</h3>

          <button
            type="button"
            className="action-button"
            onClick={() => {
              const newFile = {
                category: "",
                isLink: true,
              };

              setFiles([...files, newFile]);
            }}
          >
            <MdAddBox />
          </button>
        </div>
      </div>

      <ContainerPreviews
        style={{
          overflowY: "auto",
          maxHeight: "45vh",
        }}
      >
        {files &&
          files.map(
            ({ isLink, link, category, name }, index) =>
              isLink && (
                <li
                  key={index}
                  className={!category || !link || !name ? "required-on" : ""}
                >
                  <div className="fileInfo">
                    <div style={{ display: "flex" }}>
                      <div style={{ marginBottom: 8 }}>
                        <Input
                          name={`name-${index}`}
                          label="Nome"
                          value={name}
                          onChange={onChangeInput}
                          requiredOn={!name}
                        />
                      </div>
                      <div>
                        <Input
                          name={`link-${index}`}
                          label="Link"
                          value={link}
                          onChange={onChangeInput}
                          requiredOn={!link}
                        />
                      </div>
                    </div>

                    <div className="action-file">
                      <div className="field-file">
                        <label htmlFor="category">Tipo:</label>
                        <select
                          name={`category-${index}`}
                          onChange={onChangeSelect}
                          value={category}
                          className={!category ? "fileInfo-field-required" : ""}
                        >
                          <option value="">Selecionar</option>
                          {categoriesFile.map((item) => (
                            <option key={item.id} value={String(item.id)}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <button
                        type="button"
                        onClick={() => {
                          handleRemoveFile(index);
                        }}
                      >
                        <IoMdTrash size={30} />
                      </button>
                    </div>
                  </div>
                </li>
              )
          )}

        {fileQueryOver &&
          fileQueryOver.map(
            ({ category_file, name, size, mimetype, url }, index) => (
              <li
                key={index}
                className={!category_file.id ? "required-on" : ""}
              >
                <img
                  src={selectIcon(mimetype) ? selectIcon(mimetype) : url}
                  alt={name}
                />

                <div className="fileInfo">
                  <div>
                    <strong>{name}</strong>
                    <span>{filesize(size)}</span>
                  </div>

                  <div className="action-file">
                    <div className="field-file">
                      <label htmlFor="category">Tipo:</label>
                      <select
                        name={`category-${index}`}
                        onChange={onChangeSelect}
                        value={category_file.id}
                        disabled={true}
                        className={
                          !category_file.id ? "fileInfo-field-required" : ""
                        }
                      >
                        <option value="">Selecionar</option>
                        {categoriesFile.map((item) => (
                          <option key={item.id} value={String(item.id)}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </li>
            )
          )}
      </ContainerPreviews>
    </>
  );
};
