import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ReactPlayer from "react-player/youtube";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import api from "../../../service/api";

import { PopUpConfirmation } from "../../createAndDetail/styles";
import { ListFile } from "../styles";

import { BiDownload } from "react-icons/bi";
import { FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

import { IPermissionUser } from "../../../@types";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import Loading from "../../../components/Loading";
import Modal from "../../../components/ModalLib";
import { ModalModificationShowFile } from "../../../components/ModalModificationShowFile";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { useOneShowFile } from "../../../hook/queries/useShowFile";
import selectIcon from "../../../utils/selectIcon";

interface HomeProps {
  admin: boolean;
  seller: boolean;
  permission: IPermissionUser;

  match: {
    params: {
      id: number;
    };
  };
}

interface IFile {
  id: string;
  name: string;
  size: Number;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype?: string;
}

const Seller: React.FC<HomeProps> = ({ match, admin, permission }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [files, setFiles] = useState<IFile[]>([]);
  const [activeModify, setActiveModify] = useState(false);
  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  async function handleDelete() {
    setLoadingModal(true);
    try {
      await api.delete(`/showFiles/${data?.id}`);

      setLoadingModal(false);
      addToast("Arquivo excluído com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/arquivos");
    } catch (error) {
      setLoadingModal(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  const { data, isLoading } = useOneShowFile(match?.params?.id);

  useEffect(() => {
    (async () => {
      if (data && data.id) {
        const fileDB = await api.get<IFile[]>(`/showFiles/file/${data.id}`);
        setFiles(fileDB.data);
      }
    })();
  }, [data]);

  return (
    <>
      <CreateAndDetailLayout isLoading={isLoading}>
        <PanelAndDetailAndCreateHeader title="Tutorial" goBack>
          {admin && permission?.showFile > 2 && (
            <div className="containerActions">
              <button
                type="button"
                className="buttonActionDatasheet"
                onClick={async () => {
                  setActiveModify(true);
                }}
              >
                {
                  <div>
                    <span>
                      <FiEdit color="#8f6e09" />
                    </span>
                    Editar
                  </div>
                }
              </button>

              <button
                type="button"
                className="buttonActionDatasheet"
                onClick={() => {
                  setConfirmationDelete(true);
                }}
              >
                {
                  <div>
                    <span>
                      <RiDeleteBinLine color="#d21e26" />
                    </span>
                    Excluir
                  </div>
                }
              </button>
            </div>
          )}
        </PanelAndDetailAndCreateHeader>

        <ReactPlayer
          url={data?.link}
          width="100%"
          height="65vh"
          style={{ borderRadius: 8, overflow: "hidden" }}
          controls
        />

        <div style={{ marginTop: "1rem" }}>
          <h2 style={{ color: "#333", fontSize: "2rem" }}>{data?.name}</h2>

          {admin && (
            <span
              style={{
                color: "#555",
                fontSize: "0.875rem",
                fontWeight: "lighter",
              }}
            >
              Marcas possuem acesso:
              {data?.brands?.map((brand) => brand.name)?.join(", ")}
            </span>
          )}

          <p style={{ color: "#555", fontSize: "1rem", marginTop: "1.5rem" }}>
            {data?.description}
          </p>

          {files.length > 0 && (
            <>
              <p
                style={{
                  color: "#333",
                  fontSize: "1.5rem",
                  marginTop: "1.2rem",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <span style={{ marginRight: "0.5rem" }}>Anexos</span>
                <BiDownload color="#333" />
              </p>

              <ListFile style={{ background: "transparent" }}>
                {files.map((item) => (
                  <button
                    style={{
                      background: "white",
                      height: "100%",
                      borderRadius: 6,
                      padding: 8,
                    }}
                    key={item.id}
                    type="button"
                    onClick={() => {
                      window.open(item.preview_url);
                    }}
                  >
                    <LazyLoadImage
                      effect="blur"
                      src={
                        selectIcon(item.mimetype)
                          ? selectIcon(item.mimetype)
                          : item.url
                      }
                      alt={item.name}
                    />
                    <span>{item.name}</span>
                  </button>
                ))}
              </ListFile>
            </>
          )}
        </div>
      </CreateAndDetailLayout>
      {data && (
        <>
          <Modal
            title="Tutorial"
            modalVisible={activeModify}
            setModalVisible={setActiveModify}
            maxWidth={750}
            scrollOff={true}
            maxHeight={500}
          >
            <ModalModificationShowFile
              data={{
                id: data.id,
                description: data.description,
                name: data.name,
                link: data.link,
                brands: data.brands,
              }}
              onRequestClose={() => {
                setActiveModify(false);
                history.push("/admin/arquivos");
              }}
            />
          </Modal>

          <Modal
            title="Selecione o cliente"
            modalVisible={confirmationDelete}
            setModalVisible={setConfirmationDelete}
            headerOff={true}
            maxHeight={350}
            maxWidth={650}
          >
            <PopUpConfirmation>
              <div className="headerPopUp">
                <nav>
                  <FaTrashAlt size={95} opacity={0.2} />
                </nav>
                <span>
                  Você tem certeza que deseja excluir este arquivo "{data.name}
                  "?
                </span>
              </div>
              <div className="buttonsPopUp">
                <button
                  type="button"
                  onClick={() => setConfirmationDelete(false)}
                >
                  Cancelar
                </button>
                <button
                  style={{ background: "#d21e26" }}
                  type="button"
                  onClick={() => (loadingModal ? () => {} : handleDelete())}
                >
                  {!loadingModal ? (
                    "Deletar"
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Loading
                        size={22}
                        borderSize={4}
                        colorLoading="rgba(255,255,255)"
                        borderColor="rgba(255,255,255, 0.3)"
                      />
                    </div>
                  )}
                </button>
              </div>
            </PopUpConfirmation>
          </Modal>
        </>
      )}
    </>
  );
};

export default Seller;
