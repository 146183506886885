import { useFormik } from "formik";
import React, { memo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { Contact } from "..";
import { Input } from "../../../../../components/Form/Input";
import Loading from "../../../../../components/loadings/Loading";
import { ButtunSubmit as Button, Form } from "../../../styles";
import { Container } from "./styles";

interface FormModificationContactProps {
  onChangeData: (a: Contact) => void;
  handleCloseModal: () => void;

  type: "create" | "edit";
  currentDate?: Contact;
}

const FormModificationContact: React.FC<FormModificationContactProps> = ({
  type,
  onChangeData,
  currentDate,
}) => {
  const { addToast } = useToasts();

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      id: currentDate?.id ?? "",
      name: currentDate?.name ?? "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Nome é obrigatório"),
    }),

    onSubmit: (data: Contact) => {
      handleSubmit(data);
    },
  });

  async function handleSubmit(data: Contact) {
    setIsLoading(true);

    try {
      onChangeData(data);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <Input
          label="Nome"
          {...formik.getFieldProps("name")}
          error={
            formik.touched.name && formik.errors.name
              ? formik.errors.name
              : undefined
          }
        />

        <Button type="submit">
          {isLoading ? (
            <Loading
              size={22}
              borderSize={4}
              colorLoading="rgba(255,255,255)"
              borderColor="rgba(255,255,255, 0.3)"
            />
          ) : type === "create" ? (
            "Criar"
          ) : (
            "Editar"
          )}
        </Button>
      </Form>
    </Container>
  );
};

export default memo(FormModificationContact);
