import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { IPermissionUser } from "../../../@types";
import LoadingDot from "../../../components/loadings/LoadingDot";
import useOutsideAlerter from "../../../hook/outsideAlerter";
import api from "../../../service/api";
import { dataUndefined, dataZero } from "../../../utils/dataProcessing";
import {
  Container,
  ContainerList,
  Filter,
  FilterCheck,
  FilterSelects,
  FooterContainer,
  Form,
  LoadingPanel,
  TableContainer,
} from "../styles";

interface IProvider {
  id: string;
  cod?: string;
  fullname?: string;
  brand?: string;
  email?: string;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IResponseAPI {
  contents: IProvider[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IFilter {
  id?: string;
  cod?: string;
  fullname?: string;
  email?: string;
}

interface IHeaderProps {
  permission: IPermissionUser;
}

const PanelUsers: React.FC<IHeaderProps> = ({ permission }) => {
  const history = useHistory();
  const outSideRef = useRef(null);

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({});

  const [contents, setContents] = useState<IProvider[]>([]);
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  const [loading, setLoading] = useState({
    panel: true,
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading({ ...loading, panel: true });

        const providersDB = await api.get<IResponseAPI>("/provider", {
          params: { ...requestPagination, ...filters },
        });

        const { limit, page, pages, total } = providersDB.data;

        setContents(providersDB.data.contents);
        setPagination({ limit, page, pages, total });
        setLoading({ ...loading, panel: false });
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, requestPagination]);

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setFilterView(false);
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  function hadleToNewUser() {
    history.push("/admin/criar/fornecedor");
  }

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  return (
    <Container>
      <header>
        <h2>Lista de fornecedores</h2>
      </header>

      <Filter>
        <button
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="button"
        >
          <FiSearch />
        </button>
        <input
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="text"
          name="filter"
          id="filter"
          readOnly={true}
        />
        <FilterSelects>
          <ul>
            {filters.id && (
              <li>
                <span>ID: {filters.id}</span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, id: "" })}
                />
              </li>
            )}
            {filters.cod && (
              <li>
                <span>Código ERP: {filters.cod}</span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, cod: "" })}
                />
              </li>
            )}
            {filters.fullname && (
              <li>
                <span>Nome: {filters.fullname}</span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, fullname: "" })}
                />
              </li>
            )}
            {filters.email && (
              <li>
                <span>Email: {filters.email}</span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, email: "" })}
                />
              </li>
            )}
          </ul>
        </FilterSelects>

        {filterView && (
          <FilterCheck ref={outSideRef}>
            <header>
              <button
                onClick={() => {
                  setFilterView(false);
                }}
              >
                Fechar
              </button>
            </header>
            <main>
              <Formik
                initialValues={{
                  id: filters.id,
                  cod: filters.cod,
                  fullname: filters.fullname,
                  email: filters.email,
                }}
                validationSchema={Yup.object({})}
                onSubmit={(data) => {
                  hadleFilter({
                    ...data,
                  });
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="id">ID</label>
                        <input id="id" {...formik.getFieldProps("id")} />
                      </div>
                      <div className="field">
                        <label htmlFor="cod">Código ERP</label>
                        <input id="cod" {...formik.getFieldProps("cod")} />
                      </div>
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="fullname">Nome</label>
                        <input
                          id="fullname"
                          {...formik.getFieldProps("fullname")}
                        />
                      </div>
                      <div className="field">
                        <label htmlFor="email">Email</label>
                        <input id="email" {...formik.getFieldProps("email")} />
                      </div>
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                )}
              </Formik>
            </main>
          </FilterCheck>
        )}
      </Filter>

      <ContainerList>
        <div className="headerTable">
          <h3>Fornecedores</h3>

          {permission && permission.provider && permission.provider > 2 && (
            <button onClick={hadleToNewUser} type="button">
              <MdAdd size={22} /> <span> NOVO FORNECEDOR </span>
            </button>
          )}
        </div>

        <TableContainer>
          {!loading.panel ? (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>CÓDIGO ERP</th>
                  <th>NOME</th>
                  <th>EMAIL</th>
                  <th>MARCA</th>
                </tr>
              </thead>

              <tbody>
                {contents.map((provide) => (
                  <tr
                    key={provide.id}
                    onClick={() =>
                      history.push(`/admin/fornecedor/${provide.id}`)
                    }
                  >
                    <td> {provide.id} </td>
                    <td> {dataUndefined(provide.cod)} </td>
                    <td> {provide.fullname} </td>
                    <td> {provide.email} </td>
                    <td> {dataUndefined(provide.brand)} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>
        <FooterContainer>
          <span>
            Mostrado{" "}
            {dataZero(
              Number(contents.length + pagination.limit * pagination.page)
            )}{" "}
            de {dataZero(Number(pagination.total))} resultados
          </span>

          <div>
            <button
              className={pagination.page < 1 ? "buttonNull" : ""}
              onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
              type="button"
            >
              ANTERIOR
            </button>
            <p style={{ marginRight: 8 }}>
              {dataZero(Number(pagination.page + 1))}
            </p>
            <button
              className={
                pagination.page >= pagination.pages ? "buttonNull" : ""
              }
              type="button"
              onClick={
                Number(pagination.page) >= Number(pagination.pages)
                  ? () => {}
                  : hadleNextPage
              }
            >
              PROXIMO
            </button>
          </div>
        </FooterContainer>
      </ContainerList>
    </Container>
  );
};

export default PanelUsers;
