import { scaleOrdinal } from "d3-scale";
import { schemeTableau10 } from "d3-scale-chromatic";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { HiFilter } from "react-icons/hi";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";
import { BoxAnalyticCharArea } from "../../../components/Analytic/BoxAnalyticCharArea";
import { BoxAnalyticCharBar } from "../../../components/Analytic/BoxAnalyticCharBar";
import { BoxHeaderInfo } from "../../../components/BoxHeaderInfo";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import Modal from "../../../components/Modal";
import LoadingDot from "../../../components/loadings/LoadingDot";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { useDevolutionAnalytic } from "../../../hook/queries/useDevolution";
import api from "../../../service/api";
import { ButtunSubmit } from "../../../styles/global";
import { dataUndefined, dataZero } from "../../../utils/dataProcessing";
import ufs from "../../../utils/ufs";
import { ContainerBoxHeaderInfo, LoadingPanel } from "../../panel/styles";
import {
  BoxAnalytic,
  BoxFilter,
  ContainerBoxAnalytic,
  ContainerCheckbox,
  ContainerList,
  Error,
  FooterContainer,
  Form,
  ModalBody,
  NoStyleLink,
  SlaContainer,
  TableContainer,
} from "../styles";

const COLORS = scaleOrdinal(schemeTableau10).range();

interface IProps {
  admin: boolean;
}

interface IUser {
  id: string;
  name?: string;
  lastname?: string;
  email?: string;
}

interface IFilter {
  requestType?: string;
  status?: string;

  refusal?: number;
  finisher?: number;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  responsible?: string;
  brand?: string;
  subReason?: number;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;

  finisherPeriod?: number;
  presetDateFinisher?: number;
  specificDateFinisher?: Date;
  startDateFinisher?: Date;
  endDateFinisher?: Date;
}

interface IStatus {
  id: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_active: boolean;
}

interface IBrand {
  id: number;
  cod: number;
  name: string;
  is_active: boolean;
}

interface IDevolution {
  id: number;
  email: string;
  client: string | undefined;
  numberInvoice: string;
  valueInvoice: number | null;
  brand: string;
  requestTime: number;
  status: IStatus | null;
  reason: string;
  brandManager: string;
  sla: number;
  freight_responsible: string | null;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IDevolutionApi {
  contents: IDevolution[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface reasonInternalDevolution {
  id: number;

  cod: number;
  name: string;
  is_active: boolean;

  created_at: Date;
  updated_at: Date;
}

const PieTooltip = (props: any) => {
  if (props.active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: 8,
          border: "0.5px solid #333",
          opacity: 0.9,
        }}
      >
        <p className="label">{`${props.payload[0].payload.name}`}</p>
        <p className="label">{`Qtd : ${props.payload[0].payload.length}`}</p>
        <p className="label">{`Valor : ${props.payload[0].payload.value.toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        )}`}</p>
      </div>
    );
  }

  return null;
};

const APP_LINK =
  process.env.NODE_ENV !== "development"
    ? "http://portal.alpardobrasil.com.br"
    : "http://localhost:3000";

const Devolution: React.FC<IProps> = ({ admin }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [filters, setFilters] = useState<IFilter>({});

  const [status, setStatus] = useState<IStatus[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [subReasons, setSubReasons] = useState<reasonInternalDevolution[]>([]);

  const [filterCheck, setFilterCheck] = useState<any>({
    status: {},
    subReasons: {},
    brands: {},
    ufs: {},
    responsables: {},
  });

  const [contents, setContents] = useState<IDevolution[]>([]);
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  const { isLoading, data: analyticDevolution } =
    useDevolutionAnalytic(filters);

  const [loading, setLoading] = useState({
    devolution: true,
  });

  const formikFilter = useFormik({
    initialValues: {
      // ufs: "",
      brands: undefined,
      status: undefined,
      subReasons: undefined,
      ufs: undefined,
      responsables: undefined,

      brand: filters.brand,
      refusal: filters.refusal,
      finisher: filters.finisher,
      subReason: filters.subReason,

      creationPeriod: filters.creationPeriod,
      presetDate: filters.presetDate,
      specificDate: filters.specificDate,
      startDate: filters.startDate,
      endDate: filters.endDate,

      finisherPeriod: filters.finisherPeriod,
      presetDateFinisher: filters.presetDateFinisher,
      specificDateFinisher: filters.specificDateFinisher,
      startDateFinisher: filters.startDateFinisher,
      endDateFinisher: filters.endDateFinisher,
    },
    onSubmit: (data) => {
      hadleFilter(data);
    },
  });

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setModalVisible(false);
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  function setChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterCheck({
      ...filterCheck,
      [e.target.name]: {
        ...filterCheck[e.target.name],
        [e.target.value]: e.target.checked,
      },
    });
  }

  function clearFilter() {
    setModalVisible(false);
    setFilterCheck({
      status: {},
      subReasons: {},
      brands: {},
      ufs: {},
      responsables: {},
    });
    setFilters({});
  }

  useEffect(() => {
    (async () => {
      try {
        setLoading({ ...loading, devolution: true });

        const response = await api.get<IDevolutionApi>(
          "/analytic/devolution/list",
          {
            params: { ...filters, ...requestPagination },
          }
        );

        const { limit, page, pages, total } = response.data;
        setContents(response.data.contents);
        setPagination({ limit, page, pages, total });

        if (
          Number(contents.length + pagination.limit * pagination.page) >
          response.data.total
        ) {
          setRequestPagination({ ...requestPagination, pageRequest: 0 });
        }
        setLoading({ ...loading, devolution: false });
      } catch (error) {
        alert("error");
      }
    })();
    // eslint-disable-next-line
  }, [filters, requestPagination]);

  useEffect(() => {
    (async () => {
      const statusDB = await api.get<IStatus[]>("/devolution/status");
      setStatus(statusDB.data);

      const usersDB = await api.get<IUser[]>("/populate/userAdmin", {});
      setUsers(usersDB.data);

      const brandsDB = await api.get<IBrand[]>("/populate/brand", {
        params: {
          active: 1,
        },
      });
      setBrands(brandsDB.data);

      const reasonInternalDB = await api.get<any>(
        "/populate/reason/devolution",
        {
          params: {
            active: 1,
          },
        }
      );
      setSubReasons(reasonInternalDB.data.reasonInternal);
    })();
  }, []);

  useEffect(() => {
    let statusFilter = "";
    const arrStatus = Object.getOwnPropertyNames(filterCheck.status);
    for (const item of arrStatus) {
      if (filterCheck.status[item]) statusFilter += `${item},`;
    }
    formikFilter.setFieldValue("status", statusFilter);

    let subReasonsFilter = "";
    const arrSubReasons = Object.getOwnPropertyNames(filterCheck.subReasons);
    for (const item of arrSubReasons) {
      if (filterCheck.subReasons[item]) subReasonsFilter += `${item},`;
    }
    formikFilter.setFieldValue("subReasons", subReasonsFilter);

    let brandsFilter = "";
    const arrBrands = Object.getOwnPropertyNames(filterCheck.brands);
    for (const itemBrand of arrBrands) {
      if (filterCheck.brands[itemBrand]) brandsFilter += `${itemBrand},`;
    }
    formikFilter.setFieldValue("brands", brandsFilter);

    let ufsFilter = "";
    const arrUfs = Object.getOwnPropertyNames(filterCheck.ufs);
    for (const item of arrUfs) {
      if (filterCheck.ufs[item]) ufsFilter += `${item},`;
    }
    formikFilter.setFieldValue("ufs", ufsFilter);

    let responsablesFilter = "";
    const arrResponsables = Object.getOwnPropertyNames(
      filterCheck.responsables
    );
    for (const item of arrResponsables) {
      if (filterCheck.responsables[item]) responsablesFilter += `${item},`;
    }
    formikFilter.setFieldValue("responsables", responsablesFilter);

    // eslint-disable-next-line
  }, [filterCheck]);

  return (
    <CreateAndDetailLayout isLoading={isLoading}>
      <PanelAndDetailAndCreateHeader title={"Análise de Devoluções"} goBack />

      <ContainerBoxHeaderInfo>
        <BoxHeaderInfo
          data={{
            title: "NF ORIGEM/DEVOLUÇÃO",
            value:
              Number(
                analyticDevolution?.valueInvoice
                  ? analyticDevolution?.valueInvoice
                  : 0
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              }) ?? "-",
          }}
        />
        <BoxHeaderInfo
          data={{
            title: "Nº CHAMADOS",
            value: String(dataUndefined(analyticDevolution?.total)),
          }}
        />
      </ContainerBoxHeaderInfo>

      <ContainerBoxAnalytic style={{ marginTop: 9, marginBottom: 0 }}>
        <BoxAnalyticCharBar
          title="Situações"
          height={600}
          descriptions={
            analyticDevolution?.statusData?.map((reason) => reason.name) ?? []
          }
          values={
            analyticDevolution?.statusData?.map((reason) => reason.length) ?? []
          }
          // distributed={false}
        />

        <BoxAnalyticCharBar
          title="Motivos internos"
          height={`600`}
          descriptions={
            analyticDevolution?.reasonData?.map((reason) => reason.name) ?? []
          }
          values={
            analyticDevolution?.reasonData?.map((reason) => reason.length) ?? []
          }
          // distributed={false}
        />
      </ContainerBoxAnalytic>

      <ContainerBoxAnalytic style={{ marginTop: 9, marginBottom: 0 }}>
        <BoxAnalytic>
          <h3>Tipo de solicitação</h3>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <PieChart width={300} height={340}>
              <Pie
                data={analyticDevolution?.normalAndRefusal ?? []}
                dataKey="length"
                innerRadius={80}
                outerRadius={140}
              >
                {analyticDevolution?.normalAndRefusal?.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<PieTooltip />} />

              <Legend />
            </PieChart>
          </div>
        </BoxAnalytic>
      </ContainerBoxAnalytic>

      <ContainerBoxAnalytic style={{ marginTop: 9 }}>
        <BoxAnalyticCharArea
          title="Fluxo de Devolução"
          typeDate="number"
          dates={analyticDevolution?.devolutionFlow.date ?? []}
          values={analyticDevolution?.devolutionFlow.content ?? []}
          obs={
            <>
              * Range dos últimos 12 meses, dados não sofrem alterações com
              adição de filtros.
            </>
          }
        />

        <BoxAnalyticCharArea
          title="Prazo médio"
          dates={analyticDevolution?.mediumTerm.date ?? []}
          values={analyticDevolution?.mediumTerm.content ?? []}
          typeDate="number"
          obs={
            <>
              * Range dos últimos 12 meses, dados não sofrem alterações com
              adição de filtros.
            </>
          }
        />
      </ContainerBoxAnalytic>

      <ContainerList>
        <div className="headerTable">
          <h3>Solicitação</h3>
        </div>

        <TableContainer style={{ maxHeight: "50vh" }}>
          {!loading.devolution ? (
            <table>
              <thead>
                <tr>
                  <th>CLIENTE</th>
                  <th>NF ORIGEM</th>
                  <th>VALOR</th>
                  <th>MARCA</th>
                  <th>GESTOR</th>
                  <th>STATUS</th>
                  <th>MOTIVO</th>
                  <th>RESPONSÁVEL FRETE</th>
                  <th>TEMPO DA SOLICITAÇÃO</th>
                  <th>SLA</th>
                </tr>
              </thead>

              <tbody>
                {contents.map((element, index) => (
                  <tr key={index} style={{ cursor: "auto" }}>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <NoStyleLink
                        href={
                          admin
                            ? `${APP_LINK}/admin/devolucao/${element.id}`
                            : `${APP_LINK}/devolucao/${element.id}`
                        }
                        target="_blank"
                      >
                        {element.client}
                      </NoStyleLink>
                    </td>

                    <td>
                      <NoStyleLink
                        href={
                          admin
                            ? `${APP_LINK}/admin/devolucao/${element.id}`
                            : `${APP_LINK}/devolucao/${element.id}`
                        }
                        target="_blank"
                      >
                        {element.numberInvoice}
                      </NoStyleLink>
                    </td>

                    <td>
                      <NoStyleLink
                        href={
                          admin
                            ? `${APP_LINK}/admin/devolucao/${element.id}`
                            : `${APP_LINK}/devolucao/${element.id}`
                        }
                        target="_blank"
                      >
                        {element.valueInvoice
                          ? Number(element.valueInvoice).toLocaleString(
                              "pt-br",
                              {
                                style: "currency",
                                currency: "BRL",
                              }
                            )
                          : dataUndefined(element.valueInvoice)}
                      </NoStyleLink>
                    </td>

                    <td>
                      <NoStyleLink
                        href={
                          admin
                            ? `${APP_LINK}/admin/devolucao/${element.id}`
                            : `${APP_LINK}/devolucao/${element.id}`
                        }
                        target="_blank"
                      >
                        {dataUndefined(element.brand)}
                      </NoStyleLink>
                    </td>
                    <td>
                      <NoStyleLink
                        href={
                          admin
                            ? `${APP_LINK}/admin/devolucao/${element.id}`
                            : `${APP_LINK}/devolucao/${element.id}`
                        }
                        target="_blank"
                      >
                        {dataUndefined(element.brandManager)}
                      </NoStyleLink>
                    </td>

                    <td>
                      <NoStyleLink
                        href={
                          admin
                            ? `${APP_LINK}/admin/devolucao/${element.id}`
                            : `${APP_LINK}/devolucao/${element.id}`
                        }
                        target="_blank"
                      >
                        <span
                          className="status"
                          style={{
                            backgroundColor: element.status?.color,
                          }}
                        >
                          {dataUndefined(element.status?.name)}
                        </span>
                      </NoStyleLink>
                    </td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <NoStyleLink
                        href={
                          admin
                            ? `${APP_LINK}/admin/devolucao/${element.id}`
                            : `${APP_LINK}/devolucao/${element.id}`
                        }
                        target="_blank"
                      >
                        {dataUndefined(element.reason)}
                      </NoStyleLink>
                    </td>
                    <td>
                      <NoStyleLink
                        href={
                          admin
                            ? `${APP_LINK}/admin/devolucao/${element.id}`
                            : `${APP_LINK}/devolucao/${element.id}`
                        }
                        target="_blank"
                      >
                        {dataUndefined(element.freight_responsible)}
                      </NoStyleLink>
                    </td>
                    <td>
                      <NoStyleLink
                        href={
                          admin
                            ? `${APP_LINK}/admin/devolucao/${element.id}`
                            : `${APP_LINK}/devolucao/${element.id}`
                        }
                        target="_blank"
                      >
                        {dataUndefined(`${element.requestTime} Dias`)}
                      </NoStyleLink>
                    </td>
                    <td>
                      <NoStyleLink
                        href={
                          admin
                            ? `${APP_LINK}/admin/devolucao/${element.id}`
                            : `${APP_LINK}/devolucao/${element.id}`
                        }
                        target="_blank"
                      >
                        <SlaContainer>
                          <div className="boxSla">
                            <div className="percentageSla">
                              <span>{element.sla}%</span>
                            </div>
                            <div
                              className="valueSla"
                              style={{
                                width: `${element.sla}%`,
                                backgroundColor:
                                  element.sla < 30
                                    ? "rgba(90, 182, 95, 0.8)"
                                    : element.sla < 60
                                    ? " rgba(255, 220, 0, 0.6)"
                                    : "rgba(255, 61, 61, 0.8)",
                              }}
                            />
                          </div>
                        </SlaContainer>
                      </NoStyleLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>

        <FooterContainer>
          <span>
            Mostrado{" "}
            {dataZero(
              Number(contents.length + pagination.limit * pagination.page)
            )}{" "}
            de {dataZero(Number(pagination.total))} resultados
          </span>

          <div>
            <button
              className={pagination.page < 1 ? "buttonNull" : ""}
              onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
              type="button"
            >
              ANTERIOR
            </button>
            <p style={{ marginRight: 8 }}>
              {dataZero(Number(pagination.page + 1))}
            </p>
            <button
              className={
                pagination.page >= pagination.pages ? "buttonNull" : ""
              }
              type="button"
              onClick={
                Number(pagination.page) >= Number(pagination.pages)
                  ? () => {}
                  : hadleNextPage
              }
            >
              PROXIMO
            </button>
          </div>
        </FooterContainer>
      </ContainerList>

      <BoxFilter>
        <div className="fixedButton">
          <button onClick={() => setModalVisible(!modalVisible)}>
            <HiFilter color="#FFF" size={26} />
          </button>
        </div>
      </BoxFilter>

      {modalVisible && (
        <Modal
          title="Filtros"
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          maxHeight={600}
          scrollOff
        >
          <ModalBody>
            <Form onSubmit={formikFilter.handleSubmit}>
              <div
                style={{
                  overflowY: "auto",
                  height: 475,
                  maxHeight: 475,
                  padding: 10,
                }}
              >
                <div className="field-group">
                  <div className="field">
                    <ContainerCheckbox>
                      <label className="titleCheckbox" htmlFor="">
                        Status
                      </label>
                      <div className="contentsCheckbox">
                        {status.map((element, index) => (
                          <div key={index} className="boxCheckbox">
                            <input
                              type="checkbox"
                              onChange={setChangeCheckbox}
                              checked={
                                filterCheck.status[element.cod] ? true : false
                              }
                              value={element.cod}
                              name={`status`}
                            />
                            <label htmlFor="">{element.name}</label>
                          </div>
                        ))}
                      </div>
                    </ContainerCheckbox>
                  </div>

                  <div className="field">
                    <ContainerCheckbox>
                      <label className="titleCheckbox" htmlFor="">
                        MOTIVO INTERNO
                      </label>
                      <div className="contentsCheckbox">
                        {subReasons.map((element, index) => (
                          <div key={index} className="boxCheckbox">
                            <input
                              type="checkbox"
                              onChange={setChangeCheckbox}
                              checked={
                                filterCheck.subReasons[element.cod]
                                  ? true
                                  : false
                              }
                              value={element.cod}
                              name={`subReasons`}
                            />
                            <label htmlFor="">{element.name}</label>
                          </div>
                        ))}
                      </div>
                    </ContainerCheckbox>
                  </div>
                </div>

                <div className="field-group">
                  {!!admin && (
                    <div className="field">
                      <ContainerCheckbox>
                        <label className="titleCheckbox" htmlFor="">
                          Marca
                        </label>
                        <div className="contentsCheckbox">
                          {brands.map((element, index) => (
                            <div key={index} className="boxCheckbox">
                              <input
                                type="checkbox"
                                onChange={setChangeCheckbox}
                                checked={
                                  filterCheck.brands[element.cod] ? true : false
                                }
                                value={element.cod}
                                name={`brands`}
                              />
                              <label htmlFor="">{element.name}</label>
                            </div>
                          ))}
                        </div>
                      </ContainerCheckbox>
                    </div>
                  )}

                  <div className="field">
                    <ContainerCheckbox>
                      <label className="titleCheckbox" htmlFor="">
                        UF
                      </label>
                      <div className="contentsCheckbox">
                        {ufs.map((element, index) => (
                          <div key={index} className="boxCheckbox">
                            <input
                              type="checkbox"
                              onChange={setChangeCheckbox}
                              checked={
                                filterCheck.ufs[element.sigla] ? true : false
                              }
                              value={element.sigla}
                              name={`ufs`}
                            />
                            <label htmlFor="">{element.nome}</label>
                          </div>
                        ))}
                      </div>
                    </ContainerCheckbox>
                  </div>

                  {!!admin && (
                    <div className="field">
                      <ContainerCheckbox>
                        <label className="titleCheckbox" htmlFor="">
                          RESPONSÁVEL
                        </label>
                        <div className="contentsCheckbox">
                          {users.map((element, index) => (
                            <div key={index} className="boxCheckbox">
                              <input
                                type="checkbox"
                                onChange={setChangeCheckbox}
                                checked={
                                  filterCheck.responsables[element.id]
                                    ? true
                                    : false
                                }
                                value={element.id}
                                name={`responsables`}
                              />
                              <label htmlFor="">{`${element.name} ${element.lastname}`}</label>
                            </div>
                          ))}
                        </div>
                      </ContainerCheckbox>
                    </div>
                  )}
                </div>

                <div className="field-group">
                  <div className="field">
                    <label htmlFor="finisher">TIPO STATUS</label>
                    <select
                      id="finisher"
                      {...formikFilter.getFieldProps("finisher")}
                    >
                      <option value=""></option>
                      <option value={1}>Finalizado</option>
                      <option value={0}>Em aberto</option>
                    </select>
                  </div>

                  <div className="field">
                    <label htmlFor="refusal">TIPO SOLICITAÇÃO</label>
                    <select
                      id="refusal"
                      {...formikFilter.getFieldProps("refusal")}
                    >
                      <option value=""></option>
                      <option value={1}>Recusa</option>
                      <option value={0}>Devolução</option>
                    </select>
                  </div>
                </div>

                <div className="field-group">
                  <div className="field">
                    <label htmlFor="typeDate">PERÍODO DE FINALIZAÇÃO</label>
                    <select {...formikFilter.getFieldProps("finisherPeriod")}>
                      <option value=""></option>
                      <option value={1}>Predefinida</option>
                      <option value={2}>Específica</option>
                      <option value={3}>Intervalo</option>
                    </select>
                  </div>
                </div>
                {Number(formikFilter.values.finisherPeriod) === 1 ? (
                  <div className="field">
                    <label htmlFor="typeDate">DATA PREDEFINIDA</label>
                    <select
                      {...formikFilter.getFieldProps("presetDateFinisher")}
                    >
                      <option value=""></option>
                      <option value={0}>Hoje</option>
                      <option value={1}>Ontem</option>
                      <option value={7}>Últimos 7 dias</option>
                      <option value={15}>Últimos 15 dias</option>
                      <option value={30}>Últimos 30 dias</option>
                    </select>
                    {formikFilter.touched.presetDate &&
                    formikFilter.errors.presetDate ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span>{formikFilter.errors.presetDateFinisher}</span>
                      </Error>
                    ) : null}
                  </div>
                ) : null}

                {Number(formikFilter.values.finisherPeriod) === 2 ? (
                  <div className="field">
                    <label htmlFor="typeDate">DATA ESPECÍFICA</label>
                    <input
                      type="date"
                      {...formikFilter.getFieldProps("specificDateFinisher")}
                    />
                    {formikFilter.touched.specificDateFinisher &&
                    formikFilter.errors.specificDateFinisher ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span>{formikFilter.errors.specificDateFinisher}</span>
                      </Error>
                    ) : null}
                  </div>
                ) : null}

                <div className="field-group">
                  {Number(formikFilter.values.finisherPeriod) === 3 ? (
                    <>
                      <div className="field">
                        <label htmlFor="typeDate">DATA INICIAL</label>
                        <input
                          type="date"
                          {...formikFilter.getFieldProps("startDateFinisher")}
                        />
                        {formikFilter.touched.startDateFinisher &&
                        formikFilter.errors.startDateFinisher ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span>{formikFilter.errors.startDateFinisher}</span>
                          </Error>
                        ) : null}
                      </div>
                      <div className="field">
                        <label htmlFor="typeDate">DATA FINAL</label>
                        <input
                          type="date"
                          {...formikFilter.getFieldProps("endDateFinisher")}
                        />
                        {formikFilter.touched.endDateFinisher &&
                        formikFilter.errors.endDateFinisher ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span>{formikFilter.errors.endDateFinisher}</span>
                          </Error>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field" style={{ marginBottom: 0 }}>
                  <ButtunSubmit
                    onClick={clearFilter}
                    style={{
                      background: "#fff",
                      color: "#d21e26",
                    }}
                    type="button"
                  >
                    LIMPAR FILTRO
                  </ButtunSubmit>
                </div>

                <div className="field" style={{ marginBottom: 0 }}>
                  <ButtunSubmit type="submit">BUSCAR</ButtunSubmit>
                </div>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </CreateAndDetailLayout>
  );
};

export default Devolution;
