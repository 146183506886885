import React from "react";
import { Container } from "./styles";

interface Props {
  cards: Card[];
}

interface Card {
  title: string;
  value: string;
  colorLine?: string;
  isNotVisible?: boolean;
}

export function InfoCards({ cards }: Props) {
  return (
    <Container>
      {cards
        .filter((f) => !f.isNotVisible)
        .map((card) => (
          <div className="containerStatus" key={card.title}>
            {card.colorLine && (
              <div
                className="bannerLine"
                style={{
                  backgroundColor: card.colorLine,
                }}
              />
            )}

            <div className="box">
              <div>
                <h5>{card.title}</h5>
              </div>
              <p>{card.value}</p>
            </div>
          </div>
        ))}
    </Container>
  );
}
