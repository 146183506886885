import styled from "styled-components";

export const Container = styled.div`
  height: 21rem;
  position: relative;

  @media (max-width: 550px) {
    height: 16.5rem;
  }
`;

export const ContainerImages = styled.div`
  height: 100%;
  width: 100%;

  position: relative;

  .labelImages {
    max-height: 100%;
    height: 100%;
    overflow-y: scroll;
    width: 4.2rem;

    @media (max-width: 550px) {
      width: 3.2rem;
    }
  }

  span > figure {
    position: absolute;
    top: 0;
    left: 4.3rem;
    @media (max-width: 550px) {
      left: 3.2rem;
    }
  }

  span > figure > img {
    max-width: 100%;
    max-height: 21rem;

    @media (max-width: 550px) {
      max-height: 16.5rem;
    }
  }
`;

interface ContainerImages {
  isActiveImg: boolean;
}

interface ContainerImages {
  isActiveImg: boolean;
}
export const LabelImage = styled.img<ContainerImages>`
  width: 100%;

  border-radius: 4px;
  border: ${(props) =>
    props.isActiveImg
      ? "2px solid #3483fa;"
      : "1px solid rgba(0, 0, 0, 0.25);"};
`;
