import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { ButtunSubmit, Form, ScreenLoading } from "../../pages/create/styles";
import api from "../../service/api";
import { Input } from "../Form/Input";
import { Error } from "../Form/Input/styles";
import { ContainerCheckbox } from "../Form/InputCheckbox/styles";
import { Textarea } from "../Form/Textarea";
import Loading from "../loadings/Loading";
import { Container, ContainerForm } from "./styles";

interface ModalModificationDevolutionProps {
  onRequestClose: () => void;
  data: ShowFile;
}

interface ShowFile {
  id: number;
  name: string;
  link: string;
  description: string;
  brands: Brand[];
}

interface Brand {
  id: number;
  name: string;
  cod: number;
}

export const ModalModificationShowFile: React.FC<
  ModalModificationDevolutionProps
> = ({ data, onRequestClose }) => {
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const { addToast } = useToasts();

  const [brandsDB, setBrandsDB] = useState<Brand[]>([]);
  const [brands, setBrands] = useState<number[]>([]);
  const [inputCheck, setInputCheck] = useState<any>({
    brands: { 3: true },
  });
  const [brandError, setBrandError] = useState("");

  const formik = useFormik({
    initialValues: {
      id: data?.id,
      name: data?.name,
      link: data?.link,
      description: data?.description,
    },
    validationSchema: Yup.object({}),

    onSubmit: async (data) => await handleSubmitUpdate(data),
  });

  async function handleSubmitUpdate(item: {
    id: number;
    name: string;
    link: string;
    description: string;
  }) {
    setLoading(true);
    try {
      if (!brands[0]) {
        setLoading(false);
        setBrandError("Marca e obrigatório.");
      }

      if (brands[0]) {
        const data = {
          name: item.name,
          link: item.link,
          description: item.description,
          brands: brands,
        };

        await api.put(`/showFiles/${item.id}`, data);

        setLoading(false);
        onRequestClose();
      }
    } catch (err) {
      const error = err as any;

      setLoading(false);
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function setChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setInputCheck({
      ...inputCheck,
      [e.target.name]: {
        ...inputCheck[e.target.name],
        [e.target.value]: e.target.checked,
      },
    });
  }

  useEffect(() => {
    setLoadingPage(false);

    if (data.brands.length > 0) {
      let brandsAtive = {};

      data.brands.forEach(
        (brand) => (brandsAtive = { ...brandsAtive, [brand.id]: true })
      );

      setInputCheck({ ...inputCheck, brands: brandsAtive });
    }

    (async () => {
      const brandDB = await api.get<Brand[]>("/populate/brand", {
        params: { active: 1 },
      });
      setBrandsDB(brandDB.data);
    })();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (inputCheck["brands"]) {
      var normalized: number[] = [];
      const values = Object.values(inputCheck["brands"]);
      const keys = Object.keys(inputCheck["brands"]);

      for (let index = 0; index < values.length; index++) {
        const value = values[index];
        const key = keys[index];

        if (value) {
          normalized.push(Number(key));
        }
      }

      if (normalized[0]) setBrandError("");

      setBrands(normalized);
    }
  }, [inputCheck]);

  return (
    <Container>
      <Form
        onSubmit={
          loading
            ? (e) => {
                e.preventDefault();
              }
            : formik.handleSubmit
        }
      >
        <ContainerForm>
          <div className="containerForm">
            {!loadingPage ? (
              <>
                <h3>Dados do tutorial</h3>
                <Input
                  {...formik.getFieldProps("name")}
                  label="Titulo"
                  error={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : undefined
                  }
                />

                <Textarea
                  {...formik.getFieldProps("description")}
                  label="Descrição"
                  error={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : undefined
                  }
                />

                <Input
                  {...formik.getFieldProps("link")}
                  label="Link"
                  error={
                    formik.touched.link && formik.errors.link
                      ? formik.errors.link
                      : undefined
                  }
                />

                <div className="field">
                  <ContainerCheckbox>
                    <label className="titleCheckbox" htmlFor="">
                      Marca
                    </label>
                    <div className="contentsCheckbox">
                      {brandsDB.map((element, index) => (
                        <div key={index} className="boxCheckbox">
                          <input
                            type="checkbox"
                            onChange={setChangeCheckbox}
                            checked={
                              inputCheck.brands[element.id] ? true : false
                            }
                            value={element.id}
                            name={`brands`}
                          />
                          <label htmlFor="">{element.name}</label>
                        </div>
                      ))}
                    </div>
                  </ContainerCheckbox>
                  {brandError && (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {brandError} </span>
                    </Error>
                  )}
                </div>
              </>
            ) : (
              <ScreenLoading>
                <Loading
                  colorLoading="#333"
                  size={40}
                  borderColor={"#888"}
                  borderSize={5}
                />
              </ScreenLoading>
            )}
          </div>

          <div className="containerFormButton">
            <ButtunSubmit style={{ margin: 0 }} type="submit">
              {loading ? (
                <div>
                  <Loading borderSize={2} size={20} />
                </div>
              ) : (
                "Editar"
              )}
            </ButtunSubmit>
          </div>
        </ContainerForm>
      </Form>
    </Container>
  );
};
