import styled from "styled-components";

export const TableSizes = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

interface SizeItemProps {
  active: boolean;
  disabled?: boolean;
}

export const SizeItem = styled.li<SizeItemProps>`
  width: 85px;
  height: 40px;
  padding: 2px;

  button {
    height: 100%;
    width: 100%;

    border: 1px solid #555;
    padding: 4px;
    background-color: transparent;
    padding: 0.645rem;
    font-size: 0.875rem;
    color: #333;

    transition: all 0.2s;
  }

  ${(props) =>
    props.active &&
    `button {
      color: #fff;
      background-color: #333;
    }`}

  ${(props) =>
    !props.disabled &&
    `button:hover {
      color: #fff;
      background-color: #333;
    }`}
`;

export const ContainerGrid = styled.div`
  max-height: 150px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
