import styled from "styled-components";

export const Container = styled.div`
  padding: 2rem 1.5rem;
`;

export const Content = styled.div`
  &:nth-child(1) {
    width: 40%;
  }
  &:nth-child(2) {
    width: 60%;
  }

  .description-table-clean {
    width: 100%;

    span {
      color: #333;
      font-weight: 500;
      font-size: 0.765rem;
      width: 100%;
      display: block;
      text-align: end;
    }
  }

  @media (max-width: 1000px) {
    &:nth-child(1) {
      width: 100%;
    }
    &:nth-child(2) {
      width: 100%;
    }

    & + & {
      margin-top: 2rem;
    }
  }
`;

export const Info = styled.div`
  padding: 0 1.5rem;

  .description-info {
    margin-bottom: 0.4rem;
    font-weight: lighter;

    span {
      font-weight: 500;
      color: #333;
    }
  }

  .group-info {
    display: flex;
    flex-wrap: wrap;

    column-gap: 2rem;
  }
`;
