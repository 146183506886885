import styled from "styled-components";

export const Container = styled.div`
  grid-area: APP;
  padding: 10px 30px;

  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  header {
    width: 100%;
    background: transparent;
    padding: 8px 0;
    color: #333;
    border-radius: 4px 4px 0 0;

    display: flex;
    flex-direction: row;

    max-width: 1100px;

    h2 {
      font-weight: 500;
      font-size: 1.2rem;
    }

    .arrowBack {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;

      margin-right: 10px;

      height: 30px;
      width: 30px;

      border-radius: 50%;
      color: #333;

      transition: all 0.4s;

      &:hover {
        background-color: #aaa;
      }
    }
  }
`;

export const ContainerForm = styled.div`
  width: 100%;
  padding: 10px 20px;
  background: #fff;
  border-radius: 8px;

  max-width: 1100px;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
`;

export const Form = styled.form`
  border-radius: 4px 0px 0px 4px;
  width: 100%;
  height: 100%;
  background-color: #fff;

  h3 {
    font-size: 18;
    color: #333;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0 0px;
  }

  .subheader {
    display: flex;

    button {
      background-color: transparent !important;
      margin-left: 1rem;
      color: #333;
    }
  }

  .field-group {
    width: 100%;
    column-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
  }

  .containerCnpj {
    display: flex;
    width: 100%;
    button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 15%;
      height: 35px;

      color: #fff;
      font-size: 16px;
      border-radius: 4px;
      background-color: #d21e26;
      transition: all 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
    input {
      width: 85%;
      margin-right: 8px;
    }
  }

  .container-subtitle {
    display: flex;
    align-items: center;

    button {
      height: 46px;
      width: 46px;
      border-radius: 50%;

      margin-left: 0.5rem;

      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      svg {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
      }
    }
  }

  .field {
    display: flex;
    flex-direction: column;

    flex: 1;
    margin-bottom: 24px;
    max-width: 100%;
    min-width: 150px;

    label {
      font-size: 14px;
      margin-bottom: 4px;
      color: #555;

      .required {
        color: #f00;
        font-size: 11px;
      }
    }
    input {
      padding: 0 10px;
      background: #fff;
      height: 35px;
      font-size: 16px;
      color: #222;
      border: 2px solid #aaa;
      border-radius: 5px;
    }

    .selectClient {
      height: 40px;
      width: 100%;

      button {
        width: 100%;
        height: 100%;
        background: #d21e26;

        font-weight: bold;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
      }
    }

    input:focus {
      border: 2px solid #333;
    }

    input::placeholder {
      color: #a0a0b2;
    }

    textarea {
      padding: 10px 10px;
      background: #fff;
      height: 80px;
      font-size: 16px;
      color: #222;
      border: 2px solid #aaa;
      border-radius: 5px;
      resize: vertical;

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-thumb {
        background: #333;
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background: #888;
      }

      &:focus {
        border: 2px solid #333;
      }
    }

    select {
      height: 35px;

      background: #fff;
      border-radius: 5px;
      border: 2px solid #aaa;
      padding: 4px 8px;

      font-size: 16px;
      color: #222 !important;
      cursor: pointer;
      /* -webkit-appearance: none; */
      -moz-appearance: none;
      /* appearance: none;  */

      &:focus {
        border: 2px solid #333;
        color: #333;
      }
    }
  }

  .containerLabelButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;

    button {
      border-radius: 4px;
      background: transparent;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #d21e26;
      padding: 2px 4px;
      font-weight: 600;
    }
  }

  /* @media (max-width: 900px) {
    .field-group {
      flex-flow: column;
    }
  } */

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  .containerButton {
  }
`;

export const ButtunSubmit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 35px;

  font-weight: 700;
  font-size: 16px;
  color: #fff;

  background: #d21e26;
  border-radius: 4px;

  &:hover {
    opacity: 0.85;
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 2px;
  span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #f00;
  }
`;

interface ContainerCheckProps {
  disabled?: boolean;
}

export const ContainerCheck = styled.div<ContainerCheckProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 40px;

  border: 2px solid #d21e26;
  border-radius: 4px;
  overflow: hidden;

  button {
    border-radius: 0px;
    flex: 1;

    background: #fff;
    font-weight: bold;
    color: #333;
  }

  button:first-child {
    border-right: 2px solid #d21e26;
  }

  button:last-child {
    border-left: 2px solid #d21e26;
  }

  button:hover {
    opacity: 1;
  }

  .check {
    background: #d21e26;
    color: #fff;
  }
`;

export const HeaderPage = styled.div`
  width: 100%;
  background: #ccc;
  padding: 10px 15px;
  color: #333;
  border-radius: 4px 4px 0 0;
  margin-bottom: 15px;

  h2 {
    font-weight: 500;
  }
`;

export const ContainerDropZone = styled.div`
  width: 100%;
`;

// export const ContainerPreviews = styled.ul`
//   margin-top: 25px;
//   width: 100%;

//   display: flex;
//   justify-content: flex-start;

//   max-width: 73vw;
//   overflow-x: scroll;

//   ::-webkit-scrollbar {
//     display: none;
//   }

//   li {
//     margin-right: 20px;
//     img {
//       border-radius: 10px;
//       height: 80px;
//     }

//     .fileInfo {
//       margin-left: 5px;
//       display: flex;
//       flex-direction: column;

//       button {
//         margin-top: 5px;
//         background: none;
//         color: #f00;
//         width: 20px;
//       }
//     }
//   }
// `;

export const ContainerPreviews = styled.ul`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  li {
    width: 100%;
    display: flex;
    align-items: center;
    background: #f0f0f0;
    padding: 10px 10px;
    margin-top: 10px;
    border-radius: 8px;

    img {
      height: 50px;
    }

    .fileInfo {
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      div {
        display: flex;
        flex-direction: column;
        margin: 0 5px;
      }

      strong {
        font-size: 14px;
        word-break: break-all;
      }

      span {
        font-size: 12px;
        color: #333;
      }

      button {
        margin-right: 10px;
        background: none;
        color: #f00;
      }
    }
  }
`;

export const ContainerList = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  height: 100%;

  .headerTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    height: 45px;
    background-color: #ccc;

    h3 {
      font-size: 18px;
      color: #111;
      font-weight: 500;
    }

    button {
      padding: 8px 10px;
      height: 28px;
      background-color: #d21e26;
      border-radius: 5px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s;

      &:hover {
        opacity: 0.6;
      }

      svg {
        color: #fff;
        margin-right: 2px;
      }

      span {
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }

      @media (max-width: 550px) {
        span {
          display: none;
        }
      }
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 80%;

  overflow: auto;

  ::-webkit-scrollbar {
    height: 6px;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #eee;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 10;

    thead {
      background-color: #eee;

      tr {
        th {
          /* text-align: justify; */
          padding: 6px 6px;
          font-size: 16px;
          color: #444;
        }
      }
    }

    tbody {
      background-color: #fff;

      tr {
        color: #777;
        border-bottom: 2px solid #eee;
        cursor: pointer;

        &:hover {
          background-color: #eee;
        }

        td {
          height: 35px;
          text-align: center;
        }

        .td-img-logo {
          img {
            width: 6rem;
            height: 5rem;
            object-fit: cover;
            margin: auto auto;
            display: block;
            padding: 4px;
            border-radius: 12px;
          }
        }
      }
    }
  }
`;

export const FooterContainer = styled.div`
  height: 55px;
  width: 100%;
  padding: 10px 10px;

  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 550px) {
    height: 80px;
  }

  span {
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      height: 30px;
      padding: 15px 15px;
      margin-right: 10px;

      font-weight: 700;
      background-color: #fff;
      transition: all 0.8s;
      color: #555;
    }

    button:hover {
      background-color: #ccc;
    }

    .buttonNull {
      cursor: not-allowed;
    }
  }
`;

export const FilterSelects = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: absolute;
  height: 35px;
  width: 100%;
  padding: 0 0 0 48px;

  ul {
    display: fixed;
    align-items: center;
    justify-content: center;

    list-style: none;
    color: #fff;
    z-index: 50;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 3px;

      border-radius: 4px;
      padding: 2px 5px;
      background-color: #f00;
      margin-right: 5px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        margin-left: 3px;
        cursor: pointer;
      }
    }
  }
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;

  position: relative;
  z-index: 1;

  margin-bottom: 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 4px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 2;
    width: 40px;
    background-color: #ccc;
    transition: all 0.5s;

    &:hover {
      background-color: #aaa;
    }
  }

  input {
    background-color: #fff;
    width: 100%;
    cursor: pointer;
    border: 2px solid #ccc;
    border-left: 0;
    z-index: 2;
  }
`;

export const FilterCheck = styled.div`
  width: 100%;
  position: absolute;

  footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 110px;
      padding: 10px 5px;
      margin-right: 10px;

      border-radius: 4px;
      font-weight: 700;
      background-color: #d21e26;
      transition: all 0.3s;
      color: #fff;
    }

    button:hover {
      opacity: 0.7;
      background-color: #db4047;
    }

    .clearFilter {
      color: #d21e26;
      background-color: #fff;

      &:hover {
        background-color: #f5cbcb;
      }
    }
  }
`;

export const ModalBody = styled.div`
  padding: 10px 10px;
  width: 100%;
`;

export const HeaderFilter = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;

  button {
    z-index: 2;
    display: flex;
    justify-content: center;

    align-items: center;
    border-radius: 4px;

    height: 35px;
    width: 100px;

    font-weight: 700;
    transition: all 0.5s;
    color: #555;
  }

  button:hover {
    background-color: #aaa;
  }
`;

export const MainFilter = styled.div`
  margin-top: 35px;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  border: 2px solid #ccc;
  border-top: none;
  padding: 10px 4px;
`;

export const ContainerCheckGrey = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 40px;

  border: 2px solid #aaa;
  border-radius: 4px;
  overflow: hidden;

  button {
    border-radius: 0px;
    flex: 1;

    background: #fff;
    font-weight: bold;
    color: #333;
  }

  button:first-child {
    border-right: 2px solid #aaa;
  }

  button:last-child {
    border-left: 2px solid #aaa;
  }

  .check {
    background: #aaa;
    color: #fff;
  }
`;

export const HeaderUploadFile = styled.div`
  display: flex;
  justify-content: space-between;

  nav {
    margin-top: 15px;
    margin-bottom: 20px;
    a,
    button {
      width: 230px;
      padding: 4px 2px;

      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 20px;
      background: rgba(200, 200, 200, 0.8);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

      &:hover {
        background: rgba(180, 180, 180, 0.8);
      }
      span {
        margin-left: 6px;
        margin-top: 2px;
      }

      display: flex;
      align-items: center;
      text-decoration: none;

      color: #333;
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

export const DivUploadFile = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;

export const ScreenLoading = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerCheckbox = styled.div`
  position: relative;
  height: 100px;
  width: 100%;

  .titleCheckbox {
    position: absolute;
    background-color: #fff;
    top: -11px;
    left: 6px;

    margin-bottom: 4px;
  }

  .contentsCheckbox {
    border: 2px solid #ccc;
    padding: 6px;
    overflow: hidden;
    border-radius: 5px;

    overflow-y: scroll;
    height: 100%;

    .boxCheckbox {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 4px;

      label {
        font-size: 14px;
        margin-left: 4px;
        margin-bottom: 0;
        color: #555;
      }

      input[type="checkbox"] {
        height: 16px;
        width: 16px;
      }

      @media (max-width: 550px) {
        input[type="checkbox"] {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
`;
