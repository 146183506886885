import React, { useEffect, useState } from "react";

import api from "../../../service/api";

import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";

import LoadingDot from "../../../components/loadings/LoadingDot";

import {
  Container,
  ContainerList,
  ExternalLink,
  ExternalLinkA,
  LoadingPanel,
  TableContainer,
} from "../styles";

import { FaRegCalendarAlt } from "react-icons/fa";

import { BiLinkExternal } from "react-icons/bi";
import { RiFileExcel2Fill } from "react-icons/ri";
import { OptionsFilter } from "../../../@types";
import { FieldProps } from "../../../@types/panelTypes";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { OrderBy } from "../../../components/panel/PanelOrderBy";
import { Pagination } from "../../../components/panel/table/Pagination";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { generateXlsx } from "../../../utils/generateXlsx";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

interface IStatus {
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_active: boolean;
}

interface IScheduling {
  id: number;
  name: string;
  total: number;
  start_date: Date;
  end_date: Date;
  created_at: Date;
  dtValid: Date;
  closing_at: Date;

  provider?: {
    id: number;
    fullname: string;
  };

  status?: {
    name: string;
    cod: number;
    color: string;
    finisher: boolean;
  };
}

interface IHeaderProps {
  admin: boolean;
  provider: boolean;
  seller: boolean;
}

interface IFilter {
  cod?: string;
  status?: string;

  finisher?: number;
  brand?: string;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;

  finisherPeriod?: number;
  presetDateFinisher?: number;
  specificDateFinisher?: Date;
  startDateFinisher?: Date;
  endDateFinisher?: Date;
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_active: boolean;
}

interface IBrand {
  id: number;
  cod: number;
  name: string;
  is_active: boolean;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IResponseApi {
  filters: IFilterOption;
  contents: IScheduling[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IFilterOption {
  status: OptionsFilter[];

  brand: OptionsFilter[];

  provider: OptionsFilter[];
}

const APP_LINK =
  process.env.NODE_ENV !== "development"
    ? "http://portal.alpardobrasil.com.br"
    : "http://localhost:3000";

const columns: FieldProps[] = [
  {
    name: "cod",
    field: "CÓDIGO",
    viewList: true,
  },
  {
    name: "status",
    field: "STATUS",
    viewList: true,
  },
  {
    name: "brand",
    field: "MARCA",
    viewList: true,
  },
  {
    name: "provider",
    field: "FORNECEDOR",
    viewList: true,
  },
  {
    name: "finisher",
    field: "TIPO STATUS",
    viewList: true,
  },

  {
    name: "creationPeriod",
    field: "PERÍODO DE CRIAÇÃO",
    viewList: true,
  },
  {
    name: "presetDate",
    field: "DATA PREDEFINIDA",
    viewList: true,
  },
  {
    name: "specificDate",
    field: "DATA ESPECÍFICA",
    viewList: true,
  },
  {
    name: "startDate",
    field: "DATA INICIAL",
    viewList: true,
  },
  {
    name: "endDate",
    field: "DATA FINAL",
    viewList: true,
  },
  {
    name: "finisherPeriod",
    field: "PERÍODO DE FINALIZAÇÃO",
    viewList: true,
  },
  {
    name: "presetDateFinisher",
    field: "DATA PREDEFINIDA",
    viewList: true,
  },
  {
    name: "specificDateFinisher",
    field: "DATA ESPECÍFICA",
    viewList: true,
  },
  {
    name: "startDateFinisher",
    field: "DATA INICIAL",
    viewList: true,
  },
  {
    name: "endDateFinisher",
    field: "DATA FINAL",
    viewList: true,
  },
];

const PanelScheduling: React.FC<IHeaderProps> = ({
  admin,
  provider,
  seller,
}) => {
  const history = useHistory();
  const query = useQueryParams();

  const [filtersOptions, setFiltersOptions] = useState<IFilterOption>({
    status: [],
    brand: [],
    provider: [],
  });
  const [orderBy, setOrderBy] = useState("start_date.desc");

  const [viewFilter, setViewFilter] = useState(false);
  const [filters, setFilters] = useState<IFilter>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });

  const [loading, setLoading] = useState({
    panel: true,
  });

  const [contents, setContents] = useState<IScheduling[]>([]);

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const formikFilter = useFormik({
    initialValues: {
      cod: "",
      status: "",
      brand: "",
      finisher: "",
      provider: "",

      creationPeriod: "",
      presetDate: "",
      specificDate: "",
      startDate: "",
      endDate: "",

      finisherPeriod: "",
      presetDateFinisher: "",
      specificDateFinisher: "",
      startDateFinisher: "",
      endDateFinisher: "",
    },
    validationSchema: Yup.object({
      presetDate: Yup.string().when("creationPeriod", {
        is: "1",
        then: Yup.string().required("Data predefinida é obrigatório"),
      }),

      specificDate: Yup.string().when("creationPeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      startDate: Yup.string().when("creationPeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      endDate: Yup.string().when("creationPeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),

      //Finalização

      presetDateFinisher: Yup.string().when("finisherPeriod", {
        is: "1",
        then: Yup.string().required("Data predefinida é obrigatório"),
      }),

      specificDateFinisher: Yup.string().when("finisherPeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      startDateFinisher: Yup.string().when("finisherPeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      endDateFinisher: Yup.string().when("finisherPeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),
    }),
    onSubmit: (data) => {
      handleFilter(data as any);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading({ ...loading, panel: true });

        const response = await api.get<IResponseApi>("/scheduling/", {
          params: {
            ...filters,
            limitRequest: 15,
            pageRequest: page - 1,
            orderBy: orderBy,
          },
        });

        const {
          contents,
          limit,
          page: pageReponse,
          pages,
          total,
          filters: filtersData,
        } = response.data;

        setContents(contents);
        setPagination({ limit, page: pageReponse, pages, total });
        setFiltersOptions(filtersData);

        setLoading({ ...loading, panel: false });
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, page, orderBy]);

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);
  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);
  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  function hadleToCalendar() {
    history.push("calendario");
  }

  function handleFilter(filter: IFilter) {
    setFilters(filter);
    setPage(1);

    setViewFilter(false);
  }

  function selectDescription(st: any, title: string) {
    let data = String(st);
    const arrTitle = [
      "brand",
      "provider",
      "status",
      "orderPeriod",
      "billingOrderPeriod",
      "orderPresetDate",
      "billingOrderPresetDate",
      "finisher",
    ];
    if (arrTitle.includes(title)) {
      switch (title) {
        case "brand":
          const findBrand = filtersOptions.brand.find(
            (brand) => Number(brand.id) === Number(st)
          );
          if (findBrand) data = findBrand.name;

          break;
        case "status":
          const findStatus = filtersOptions.status.find(
            (brand) => Number(brand.id) === Number(st)
          );
          if (findStatus) data = findStatus.name;

          break;
        case "provider":
          const findProvider = filtersOptions.provider.find(
            (provider) => Number(provider.id) === Number(st)
          );
          if (findProvider) data = findProvider.name;

          break;

        case "finisher":
          if (Number(st) === 0) {
            data = "Em andamento";
          } else {
            data = "Finalizado";
          }

          break;

        case "creationPeriod":
          if (Number(st) === 1) {
            data = "Predefinida";
          }
          if (Number(st) === 2) {
            data = "Específica";
          }
          if (Number(st) === 3) {
            data = "Intevalo";
          }

          break;
        case "finisherPeriod":
          if (Number(st) === 1) {
            data = "Predefinida";
          }
          if (Number(st) === 2) {
            data = "Específica";
          }
          if (Number(st) === 3) {
            data = "Intevalo";
          }

          break;
        case "presetDate":
          if (Number(st) === 0) {
            data = "Hoje";
          }
          if (Number(st) === 2) {
            data = "Ontem";
          }
          if (Number(st) === 7) {
            data = "Últimos 7 dias";
          }
          if (Number(st) === 15) {
            data = "Últimos 15 dias";
          }
          if (Number(st) === 30) {
            data = "Últimos 30 dias";
          }

          break;
        case "presetDateFinisher":
          if (Number(st) === 0) {
            data = "Hoje";
          }
          if (Number(st) === 2) {
            data = "Ontem";
          }
          if (Number(st) === 7) {
            data = "Últimos 7 dias";
          }
          if (Number(st) === 15) {
            data = "Últimos 15 dias";
          }
          if (Number(st) === 30) {
            data = "Últimos 30 dias";
          }

          break;

        default:
          break;
      }
    }

    return data;
  }

  function openRow(item: IScheduling) {
    admin
      ? history.push(`/admin/agendamento/${item.id}`, {
          content: item,
        })
      : provider
      ? history.push(`/fornecedor/agendamento/${item.id}`, {
          content: item,
        })
      : seller
      ? history.push(`/representante/agendamento/${item.id}`, {
          content: item,
        })
      : history.push(`/404`);
  }

  async function handleExport() {
    const response = await api.get<IResponseApi>("/scheduling/", {
      params: {
        ...filters,
        limitRequest: 99999,
        pageRequest: 0,
        orderBy: orderBy,
      },
    });

    const data: any[] = response.data.contents.map((item) => ({
      CÓDIGO: item.id,
      TÍTULO: item.name,
      STATUS: item.status?.name,
      "DATA AGENDAMENTO": `${moment(item.start_date).format("DD/MM/YYYY")} - ${
        moment(item.start_date).format("A") === "AM" ||
        Number(moment(item.start_date).format("hh")) === 12
          ? Number(moment(item.start_date).format("hh"))
          : Number(moment(item.start_date).format("hh")) + 12
      }:${moment(item.start_date).format("mm")}h às ${
        moment(item.end_date).format("A") === "AM" ||
        Number(moment(item.end_date).format("hh")) === 12
          ? Number(moment(item.end_date).format("hh"))
          : Number(moment(item.end_date).format("hh")) + 12
      }:${moment(item.end_date).format("mm")}h`,
      "VALOR TOTAL": item.total,
      FORNECEDOR: item.provider?.fullname,
      "CRIADO EM": item.created_at
        ? moment(item.created_at).format("DD/MM/YYYY")
        : "-",
      "FINALIZADO EM": item.closing_at
        ? moment(item.closing_at).format("DD/MM/YYYY")
        : "-",
      "APROVADO (BACKOFFICE) EM": item.dtValid
        ? moment(item.dtValid).format("DD/MM/YYYY")
        : "-",
    }));

    await generateXlsx<any[]>({
      filename: `Agendamentos`,
      data: data,
    });
  }

  return (
    <Container>
      <header>
        <h2>Lista de Agendamentos</h2>
      </header>

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={selectDescription}
      >
        <GroupInput>
          <Input label="CÓDIGO" {...formikFilter.getFieldProps("cod")} />

          {admin && (
            <>
              <Select
                label="MARCA"
                data={filtersOptions.brand.map((brand) => ({
                  name: brand.name,
                  value: brand.id,
                }))}
                {...formikFilter.getFieldProps("brand")}
              />

              <Select
                label="FORNECEDOR"
                data={filtersOptions.provider.map((provider) => ({
                  name: provider.name,
                  value: provider.id,
                }))}
                {...formikFilter.getFieldProps("provider")}
              />
            </>
          )}
        </GroupInput>

        <GroupInput>
          <Select
            label="STATUS"
            data={filtersOptions.status.map((status) => ({
              name: status.name,
              value: status.id,
            }))}
            {...formikFilter.getFieldProps("status")}
          />

          <Select
            label="TIPO STATUS"
            data={[
              {
                name: "Finalizado",
                value: 1,
              },
              {
                name: "Em andamento",
                value: 0,
              },
            ]}
            {...formikFilter.getFieldProps("finisher")}
          />
        </GroupInput>

        <Select
          label="PERÍODO DE CRIAÇÃO"
          data={[
            { name: "Predefinida", value: 1 },
            { name: "Específica", value: 2 },
            { name: "Intervalo", value: 3 },
          ]}
          {...formikFilter.getFieldProps("creationPeriod")}
        />

        {Number(formikFilter.values.creationPeriod) === 1 && (
          <Select
            label="DATA DO PEDIDO"
            data={[
              { name: "Hoje", value: 0 },
              { name: "Ontem", value: 1 },
              { name: "Últimos 7 dias", value: 7 },
              { name: "Últimos 15 dias", value: 15 },
              { name: "Últimos 30 dias", value: 30 },
            ]}
            {...formikFilter.getFieldProps("presetDate")}
            error={
              formikFilter.touched.presetDate && formikFilter.errors.presetDate
                ? formikFilter.errors.presetDate
                : undefined
            }
          />
        )}
        {Number(formikFilter.values.creationPeriod) === 2 && (
          <Input
            type="date"
            label="DATA DO PEDIDO"
            {...formikFilter.getFieldProps("specificDate")}
            error={
              formikFilter.touched.specificDate &&
              formikFilter.errors.specificDate
                ? formikFilter.errors.specificDate
                : undefined
            }
          />
        )}
        {Number(formikFilter.values.creationPeriod) === 3 && (
          <GroupInput>
            <Input
              type="date"
              label="DATA INICIAL"
              {...formikFilter.getFieldProps("startDate")}
              error={
                formikFilter.touched.startDate && formikFilter.errors.startDate
                  ? formikFilter.errors.startDate
                  : undefined
              }
            />
            <Input
              type="date"
              label="DATA FINAL"
              {...formikFilter.getFieldProps("endDate")}
              error={
                formikFilter.touched.endDate && formikFilter.errors.endDate
                  ? formikFilter.errors.endDate
                  : undefined
              }
            />
          </GroupInput>
        )}

        <Select
          label="PERÍODO DE FINALIZAÇÃO"
          data={[
            { name: "Predefinida", value: 1 },
            { name: "Específica", value: 2 },
            { name: "Intervalo", value: 3 },
          ]}
          {...formikFilter.getFieldProps("finisherPeriod")}
        />

        {Number(formikFilter.values.finisherPeriod) === 1 && (
          <Select
            label="DATA DO PEDIDO"
            data={[
              { name: "Hoje", value: 0 },
              { name: "Ontem", value: 1 },
              { name: "Últimos 7 dias", value: 7 },
              { name: "Últimos 15 dias", value: 15 },
              { name: "Últimos 30 dias", value: 30 },
            ]}
            {...formikFilter.getFieldProps("presetDateFinisher")}
            error={
              formikFilter.touched.presetDateFinisher &&
              formikFilter.errors.presetDateFinisher
                ? formikFilter.errors.presetDateFinisher
                : undefined
            }
          />
        )}
        {Number(formikFilter.values.finisherPeriod) === 2 && (
          <Input
            type="date"
            label="DATA DO PEDIDO"
            {...formikFilter.getFieldProps("specificDateFinisher")}
            error={
              formikFilter.touched.specificDateFinisher &&
              formikFilter.errors.specificDateFinisher
                ? formikFilter.errors.specificDateFinisher
                : undefined
            }
          />
        )}
        {Number(formikFilter.values.finisherPeriod) === 3 && (
          <GroupInput>
            <Input
              type="date"
              label="DATA INICIAL"
              {...formikFilter.getFieldProps("startDateFinisher")}
              error={
                formikFilter.touched.startDateFinisher &&
                formikFilter.errors.startDateFinisher
                  ? formikFilter.errors.startDateFinisher
                  : undefined
              }
            />
            <Input
              type="date"
              label="DATA FINAL"
              {...formikFilter.getFieldProps("endDateFinisher")}
              error={
                formikFilter.touched.endDateFinisher &&
                formikFilter.errors.endDateFinisher
                  ? formikFilter.errors.endDateFinisher
                  : undefined
              }
            />
          </GroupInput>
        )}
      </PanelFilter>

      <OrderBy
        data={[
          {
            name: "Data Agendamento Decrescente",
            value: "start_date.desc",
          },
          {
            name: "Data Agendamento Crescente",
            value: "start_date.asc",
          },
          {
            name: "Código Crescente",
            value: "id.asc",
          },
          {
            name: "Código Decrescente",
            value: "id.desc",
          },
        ]}
        setState={setOrderBy}
        state={orderBy}
      />

      <ContainerList>
        <div className="headerTable">
          <h3>Agendamentos</h3>

          <div style={{ display: "flex", columnGap: "1rem" }}>
            <button onClick={hadleToCalendar} type="button">
              <FaRegCalendarAlt size={18} /> <span> CALENDÁRIO </span>
            </button>
            <button
              onClick={handleExport}
              style={{ background: "#207245" }}
              type="button"
            >
              <RiFileExcel2Fill size={18} /> <span> EXPORTAR </span>
            </button>
          </div>
        </div>

        <TableContainer>
          {!loading.panel ? (
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>CÓDIGO</th>
                  <th>TÍTULO</th>
                  <th>STATUS</th>
                  <th>DATA AGENDAMENTO</th>
                  <th>VALOR TOTAL</th>
                  {admin && <th>FORNECEDOR</th>}
                  <th>CRIADO EM</th>
                </tr>
              </thead>

              <tbody>
                {contents.map((item) => (
                  <tr key={item.id}>
                    <ExternalLink>
                      <ExternalLinkA
                        href={
                          admin
                            ? `${APP_LINK}/admin/agendamento/${item.id}`
                            : provider
                            ? `${APP_LINK}/fornecedor/agendamento/${item.id}`
                            : `404`
                        }
                        target="_blank"
                      >
                        <BiLinkExternal size={24} />
                      </ExternalLinkA>
                    </ExternalLink>
                    <td
                      onClick={() => {
                        openRow(item);
                      }}
                    >
                      {Number(item.id)}
                    </td>
                    <td
                      onClick={() => {
                        openRow(item);
                      }}
                    >
                      {item.name}
                    </td>
                    <td
                      onClick={() => {
                        openRow(item);
                      }}
                    >
                      <span
                        className="status"
                        style={{ backgroundColor: item.status?.color }}
                      >
                        {item.status?.name}
                      </span>
                    </td>

                    <td
                      onClick={() => {
                        openRow(item);
                      }}
                    >
                      {`${moment(item.start_date).format("DD/MM/YYYY")} - ${
                        moment(item.start_date).format("A") === "AM" ||
                        Number(moment(item.start_date).format("hh")) === 12
                          ? Number(moment(item.start_date).format("hh"))
                          : Number(moment(item.start_date).format("hh")) + 12
                      }:${moment(item.start_date).format("mm")}h às ${
                        moment(item.end_date).format("A") === "AM" ||
                        Number(moment(item.end_date).format("hh")) === 12
                          ? Number(moment(item.end_date).format("hh"))
                          : Number(moment(item.end_date).format("hh")) + 12
                      }:${moment(item.end_date).format("mm")}h`}
                    </td>

                    <td
                      onClick={() => {
                        openRow(item);
                      }}
                    >
                      {item.total.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </td>

                    {admin && (
                      <td
                        onClick={() => {
                          openRow(item);
                        }}
                      >
                        {item.provider?.fullname}
                      </td>
                    )}

                    <td
                      onClick={() => {
                        openRow(item);
                      }}
                    >
                      {moment(item.created_at).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>

        <Pagination
          totalCountOfRegisters={pagination.total}
          registersPerPage={pagination.limit}
          currentPage={page}
          onPageChange={setPage}
        />
      </ContainerList>
    </Container>
  );
};

export default PanelScheduling;
