import React, { ReactNode, memo } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";

interface PanelAndDetailAndCreateHeaderProps {
  title: string;
  goBack?: boolean;
  handleGoBack?: () => void;
  updateAt?: string;

  children?: ReactNode;
}

const PanelAndDetailAndCreateHeaderComponent: React.FC<
  PanelAndDetailAndCreateHeaderProps
> = ({ title, goBack = false, handleGoBack, updateAt, children }) => {
  const history = useHistory();

  return (
    <Container>
      <div style={{ display: "flex" }}>
        {(goBack || handleGoBack) && (
          <button
            type="button"
            onClick={() => {
              if (handleGoBack) {
                handleGoBack();
              } else {
                history.goBack();
              }
            }}
            className="arrowBack"
          >
            <FaArrowLeft size={18} />
          </button>
        )}

        <h2>{title}</h2>

        {children}
      </div>

      {updateAt && (
        <div className="infoUpdate">
          <span>Dados atualizados em {updateAt}</span>
        </div>
      )}
    </Container>
  );
};

export const PanelAndDetailAndCreateHeader = memo(
  PanelAndDetailAndCreateHeaderComponent,
  (oldData, newData) => {
    return (
      oldData.title === newData.title && oldData.updateAt === newData.updateAt
    );
  }
);
