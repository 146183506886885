import React, { useRef, useState } from "react";

import { Link } from "react-router-dom";
//@ts-ignore
import { mask } from "remask";

import { InfoUser, OptionNav } from "./styles";

import * as clientStorage from "../../service/localStorage/client";
import * as UserStorage from "../../service/localStorage/user";
import * as SellerStorage from "../../service/localStorage/seller";
import * as ProviderStorage from "../../service/localStorage/provider";

import { TiUser } from "react-icons/ti";
import { MdExitToApp } from "react-icons/md";
import { FaUser, FaLock } from "react-icons/fa";
import { BsFillCaretDownFill } from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";
import useOutsideAlerter from "../../hook/outsideAlerter";
import logout from "../../utils/logout";

interface Client {
  id?: string;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
}

interface User {
  id: string;
  name: string;
  lastname: string;
  email: string;
  token: string;
}

interface Seller {
  id: number;
  cod: number;
  fullName?: string;
  abbreviation?: string;
  email: string;
}

interface Provider {
  id: number;
  fullname: string;
  phone: string;
  email: number;
}

interface HeaderProps {
  admin?: boolean;
  client?: boolean;
  seller?: boolean;
  provider?: boolean;
}

const NavUserInfo: React.FC<HeaderProps> = ({
  admin,
  client,
  seller,
  provider,
}) => {
  const outSideRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const clientData = clientStorage.getUser() as Client;
  const UserData = UserStorage.getUser() as User;
  const SellerData = SellerStorage.getUser() as Seller;
  const ProviderData = ProviderStorage.getUser() as Provider;

  useOutsideAlerter(outSideRef, () => {
    if (visible) setVisible(false);
  });

  function handleClick() {
    setVisible(!visible);
  }

  function hadleLogout() {
    logout();

    return window.location.reload();
  }

  return (
    <>
      <InfoUser onClick={handleClick}>
        <div className={"info-user"}>
          <h3>
            {admin ? `${UserData.name} ${UserData.lastname}` : ""}
            {client ? clientData.trade_name : ""}
            {seller ? `${SellerData.cod} - ${SellerData.fullName}` : ""}
            {provider ? ProviderData.fullname : ""}
          </h3>
          <span>
            {admin ? UserData.email : ""}
            {client ? mask(clientData.cnpj, "99.999.999/9999-99") : ""}
            {seller ? SellerData.email : ""}
            {provider ? ProviderData.email : ""}
          </span>
        </div>
        <div className="icon-user">
          <TiUser color="#fff" size={40} />
        </div>
        <BsFillCaretDownFill
          className={visible ? "triangulo-active" : "triangulo"}
          color="#333"
          size={23}
        />
      </InfoUser>
      {visible && (
        <OptionNav isClient={client ? true : false} ref={outSideRef}>
          <Link
            to={
              admin
                ? "/admin/perfil"
                : seller
                ? "/representante/perfil"
                : provider
                ? "/fornecedor/perfil"
                : "/perfil"
            }
            onClick={handleClick}
          >
            <li>
              <FaUser size={20} />
              Meu Perfil
            </li>
          </Link>

          <Link
            to={
              admin
                ? "/admin/seguranca"
                : seller
                ? "/representante/seguranca"
                : provider
                ? "/fornecedor/seguranca"
                : "/seguranca"
            }
            onClick={handleClick}
          >
            <li>
              <FaLock size={20} />
              Segurança
            </li>
          </Link>

          {client && (
            <Link to={"/holding"} onClick={handleClick}>
              <li>
                <HiUserGroup size={20} />
                Grupo Comercial
              </li>
            </Link>
          )}

          <Link
            to={
              admin
                ? "/admin"
                : seller
                ? "/representante"
                : provider
                ? "/fornecedor"
                : "/"
            }
            onClick={hadleLogout}
          >
            <li>
              <MdExitToApp size={20} />
              Sair
            </li>
          </Link>
        </OptionNav>
      )}
    </>
  );
};

export default NavUserInfo;
