import React, { ReactNode, useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { FieldProps } from "../../../@types";
import useOutsideAlerter from "../../../hook/outsideAlerter";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { objectToForEach } from "../../../utils/objectToForEach";
import {
  Filter as FilterContainer,
  FilterCheck,
  FilterSelects,
  Form,
} from "./style";

interface IPropsHeader<T> {
  onUpdate?: () => void;

  onSubmit: () => void;
  onResetForm: () => void;
  filters: any;
  setFilters: (a: any) => void;
  viewFilter: boolean;
  setViewFilter: (a: boolean) => void;
  columns: FieldProps[];
  children: ReactNode;

  normalizedFilters?: (value: any, label: string) => string;
}

interface FilterSelectsProps extends FieldProps {
  value: string;
  notViewFilter?: boolean;
}

export function PanelFilter<T>({
  onSubmit,
  onResetForm,
  filters,
  setFilters,
  viewFilter,
  setViewFilter,
  columns,
  children,
  normalizedFilters,
  onUpdate,
}: IPropsHeader<T>) {
  const query = useQueryParams();
  const history = useHistory();

  const [filterSelects, setFilterSelects] = useState<FilterSelectsProps[]>([]);

  useEffect(() => {
    let filterSelectsTrated: FilterSelectsProps[] = [];

    objectToForEach(filters, (key, value) => {
      const findOneKey = columns.find((f) => f.name === key)?.field ?? key;

      filterSelectsTrated.push({
        name: key,
        field: findOneKey,
        value: value,
      });
    });

    const filterSelectsTratedFilter = filterSelectsTrated.filter((f) => f);

    setFilterSelects(filterSelectsTratedFilter);
  }, [filters, columns]);

  async function handleRemoveFilter(key: string) {
    onUpdate && onUpdate();
    setFilters((oldData: any) => ({ ...oldData, [key]: undefined }));
  }

  const outSideRef = useRef(null);
  useOutsideAlerter(outSideRef, () => {
    if (viewFilter) setViewFilter(false);
  });

  return (
    <FilterContainer>
      <button
        style={{ background: "#d21e26" }}
        onClick={() => {
          setViewFilter(!viewFilter);
        }}
        type="button"
      >
        <span>Filtrar</span>
        <FiSearch />
      </button>
      <input
        onClick={() => {
          setViewFilter(!viewFilter);
        }}
        type="text"
        name="filter"
        id="filter"
        readOnly={true}
      />
      <FilterSelects>
        <ul>
          {filterSelects.map((filter, index) => {
            if (filter.value && filter.notViewFilter !== true) {
              return (
                <li key={index}>
                  <span>
                    {filter.field}:{" "}
                    {normalizedFilters
                      ? normalizedFilters(filter.value, filter.name)
                      : filter.value}
                  </span>
                  <IoMdCloseCircle
                    onClick={() => handleRemoveFilter(filter.name)}
                  />
                </li>
              );
            }

            return null;
          })}
        </ul>
      </FilterSelects>

      {viewFilter && (
        <FilterCheck ref={outSideRef}>
          <nav>
            <button
              onClick={() => {
                setViewFilter(false);
              }}
            >
              Fechar
            </button>
          </nav>
          <main>
            <Form onSubmit={onSubmit}>
              {children}

              <footer>
                <button
                  className="clearFilter"
                  type="button"
                  onClick={() => {
                    query.forEach(function (_, key) {
                      if (key !== "page")
                        setQueryParams({
                          data: {
                            field: key,
                          },
                          type: "delete",
                          history: history,
                        });
                    });

                    onResetForm();
                    setFilters({});

                    setViewFilter(false);
                  }}
                >
                  LIMPAR FILTRO
                </button>
                <button type="submit">BUSCAR</button>
              </footer>
            </Form>
          </main>
        </FilterCheck>
      )}
    </FilterContainer>
  );
}
