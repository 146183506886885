import React from "react";

import { Container, ContainerImages } from "./styles";

import Displanlogo from "../../assets/traking/logoDisplan.jpg";
import Dylogo from "../../assets/traking/logoDy.jpg";
import PlimorLogo from "../../assets/traking/logoPlimor.png";
import Tntlogo from "../../assets/traking/logoTnt.png";
import ViaPajucaralogo from "../../assets/traking/logoViaPajucara.png";

const Home: React.FC = () => {
  return (
    <>
      <Container>
        <h2>Acesse uma transportadora</h2>

        <ContainerImages>
          <a
            // eslint-disable-next-line
            target="_blank"
            href="https://radar.tntbrasil.com.br/radar/public/localizacaoSimplificada;SESSAO=WefWKglzTYlysODKDL1Sv0kQc3ULCl4MDEIIhhkgWHNgXjgewITM!-670184905"
          >
            <img src={Tntlogo} alt="Tnt" />
          </a>
          <a
            // eslint-disable-next-line
            target="_blank"
            href="https://ssw.inf.br/2/ssw_rastreamento"
          >
            <img src={PlimorLogo} alt="Plimor" />
          </a>
          <a
            // eslint-disable-next-line
            target="_blank"
            href="http://rast.pajunet.com.br/"
          >
            <img src={ViaPajucaralogo} alt="Via Pajucara" />
          </a>
          <a
            // eslint-disable-next-line
            target="_blank"
            href="https://ssw.inf.br/2/rastreamento_dest?pwd=2"
          >
            <img src={Dylogo} alt="Dy" />
          </a>
          <a
            // eslint-disable-next-line
            target="_blank"
            href="http://www.sislognet.com.br/cliente/detalhe/ConsultarNotaPorCPFCNPJ/33"
          >
            <img src={Displanlogo} alt="Displan" />
          </a>
        </ContainerImages>
      </Container>
    </>
  );
};

export default Home;
