import React, { useEffect, useState } from "react";
import Loading from "../../../components/loadings/Loading";
import { ButtunSubmit, Container, ContainerForm, Error, Form } from "../styles";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "../../../components/Form/Input";
import api from "../../../service/api";

interface IHeaderProps {
  admin: boolean;
}

interface ISeller {
  id: number;
  cod: number;
  abbreviation: string;
  fullName: string;
  email: string;
  phone: string | null;
  is_active: boolean;
  is_manager: boolean;
  is_supervisor: boolean;
  cod_manager: number | null;
  cod_supervisor: number | null;
  created_at: Date;
  updated_at: Date;
}

interface IBrand {
  id?: number;
  cod: number;
  name: string;
  is_active: boolean;
}

interface IDescription {
  id: number;
  description: string;
  is_active: boolean;
}

const CreateDatasheet: React.FC<IHeaderProps> = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [years, setYears] = useState<
    {
      id: number;
      year: string;
    }[]
  >([]);

  const [loading, setLoading] = useState(false);

  const [collections, setCollections] = useState<IDescription[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      collection: "",
      year: "",
      title: "",
      brand: "",
      markUpStore: "",
    },
    validationSchema: Yup.object({
      collection: Yup.string().required("Obrigatório"),
      year: Yup.string().required("Obrigatório"),
      brand: Yup.string().required("Obrigatório"),
    }),

    onSubmit: async (data) => {
      await handleSubmit(data);
    },
  });

  useEffect(() => {
    let dateYears = [];
    const year = new Date().getFullYear();

    for (let index = 0; index < 5; index++) {
      dateYears.push({ id: index + 1, year: String(year + index) });
    }

    setYears(dateYears);
  }, []);

  useEffect(() => {
    (async () => {
      const brandDB = await api.get<IBrand[]>("/populate/brand", {
        params: { active: 1, licensed: 1 },
      });
      setBrands(brandDB.data);

      const descriptionDB = await api.get<IDescription[]>(
        "/populate/forecast/description",
        {
          params: { active: 1 },
        }
      );
      setCollections(descriptionDB.data);
    })();
  }, []);

  async function handleSubmit(item: any) {
    setLoading(true);
    try {
      await api.post("/headerDatasheet", {
        brandId: Number(item.brand),
        collectionId: Number(item.collection),
        year: Number(item.year),
        markUpStore: Number(item.markUpStore),
      });

      setLoading(false);

      addToast("Coleção criada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/cabecalho-fichas-tecnicas");
    } catch (err) {
      const error = err as any;
      setLoading(false);
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data: any;

    if (name === "markUpStore" && value.replace(/\D/g, "").length <= 4) {
      data = value.replace(/[!@#$%&,*{}[^\]A-Za-z]/g, "");
      formik.setFieldValue(name, data);
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2> Nova Coleção de Fichas Técnicas</h2>
      </header>

      <ContainerForm>
        <Form
          onSubmit={
            loading
              ? (e) => {
                  e.preventDefault();
                }
              : formik.handleSubmit
          }
        >
          {/* <h3>Descrição</h3> */}
          <div className="field-group">
            <div className="field">
              <label htmlFor="collection">Coleção</label>
              <select {...formik.getFieldProps("collection")} name="collection">
                <option value="">Selecione uma coleção</option>
                {collections.map((item) => (
                  <option value={item.id}>{item.description}</option>
                ))}
              </select>
              {formik.touched.collection && formik.errors.collection ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.collection} </span>
                </Error>
              ) : null}
            </div>
            <div className="field">
              <label htmlFor="year">Ano</label>
              <select {...formik.getFieldProps("year")} name="year">
                <option value="">Selecione um ano</option>;
                {years.map((item) => (
                  <option key={item.id} value={item.year}>
                    {item.year}
                  </option>
                ))}
              </select>
              {formik.touched.year && formik.errors.year ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.year} </span>
                </Error>
              ) : null}
            </div>
          </div>

          <div className="field-group">
            <Input
              label="Markup Alpar"
              name="markUpStore"
              value={formik.values.markUpStore}
              onChange={handleInputChange}
              onBlur={formik.handleBlur("markUpStore")}
              error={
                formik.touched.markUpStore && formik.errors.markUpStore
                  ? formik.errors.markUpStore
                  : undefined
              }
            />

            <div className="field">
              <label htmlFor="lastname">Marca</label>
              <select {...formik.getFieldProps("brand")}>
                <option value="">Selecionar marca</option>
                {brands.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {formik.touched.brand && formik.errors.brand ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.brand} </span>
                </Error>
              ) : null}
            </div>
          </div>

          <ButtunSubmit type="submit">
            {loading ? (
              <div>
                <Loading borderSize={2} size={20} />
              </div>
            ) : (
              "Criar Coleção"
            )}
          </ButtunSubmit>
        </Form>
      </ContainerForm>
    </Container>
  );
};

export default CreateDatasheet;
