import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { IPermissionUser, OptionsFilter } from "../../../@types";
import { Input } from "../../../components/Form/Input";
import { OrderBy } from "../../../components/OrderBy";
import LoadingDot from "../../../components/loadings/LoadingDot";
// import Loading from "../../../components/Loading";
import { IoReload } from "react-icons/io5";
import useOutsideAlerter from "../../../hook/outsideAlerter";
import api from "../../../service/api";
import { dataZero } from "../../../utils/dataProcessing";
import {
  Container,
  ContainerList,
  Filter,
  FilterCheck,
  FilterSelects,
  FooterContainer,
  Form,
  LoadingPanel,
  Reaload,
  TableContainer,
} from "../styles";

interface DatasheetProps {
  id: number;
  collection: string;
  year: number;

  finished: boolean;
  finished_at: Date | null;
  created_at: Date;

  status: {
    cod: number;
    name: string;
    color: string;
    finisher: boolean;
  };

  brand: {
    id: number;
    cod: number;
    name: string;
  };
}

interface IHeaderProps {
  admin: boolean;
  permission: IPermissionUser;
}

interface IFilter {
  status?: string;
  collection?: string;
  year?: string;
  brand?: string;
  alternative_code?: string;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IFilterOption {
  year: OptionsFilter[];
  collection: OptionsFilter[];
  status: OptionsFilter[];
  brand: OptionsFilter[];
}

interface IResponseAPI {
  contents: DatasheetProps[];
  filters: IFilterOption;
  total: number;
  limit: number;
  page: number;
  pages: number;
}

const PanelDatasheet: React.FC<IHeaderProps> = ({ admin, permission }) => {
  const history = useHistory();
  const outSideRef = useRef(null);

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({});

  const [filtersOptions, setFiltersOptions] = useState<IFilterOption>({
    brand: [],
    collection: [],
    status: [],
    year: [],
  });

  const [contentData, setContentData] = useState<DatasheetProps[]>([]);
  const [orderBy, setOrderBy] = useState("id.desc");

  const [loading, setLoading] = useState({
    panel: true,
  });

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);
  const [update, setUpdate] = useState(1);

  useEffect(() => {
    setLoading({ ...loading, panel: true });
    (async () => {
      try {
        if (admin) {
          const responseAdmin = await api.get<IResponseAPI>(
            "/headerDatasheet",
            {
              params: { ...filters, ...requestPagination, orderBy: orderBy },
            }
          );

          const {
            contents,
            limit,
            page,
            pages,
            total,
            filters: filtersData,
          } = responseAdmin.data;

          setContentData(contents);
          setFiltersOptions(filtersData);
          setPagination({ limit, page, pages, total });
          setLoading({ ...loading, panel: false });
        }
        //  else {
        //   const responseClient = await api.get<IResponseAPI>(
        //     `/forecast/seller/`,
        //     {
        //       params: { ...filters, ...requestPagination },
        //     }
        //   );
        //   const {
        //     contents,
        //     limit,
        //     page,
        //     pages,
        //     total,
        //     filters: filtersData,
        //   } = responseClient.data;
        //   setFiltersOptions(filtersData);
        //   setContentData(contents);
        //   setPagination({ limit, page, pages, total });
        //   setLoading({ ...loading, panel: false });
        // }
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, requestPagination, orderBy, update]);

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setRequestPagination({
      ...requestPagination,
      pageRequest: 0,
    });
    setFilterView(false);
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  function selectDescription(
    st: number,
    title: "status" | "collection" | "brand"
  ) {
    let data = "";
    filtersOptions[title].forEach((item) => {
      if (item.id === st) {
        return (data = item.name);
      }
    });

    return data;
  }

  function hadleToNew() {
    if (admin) {
      history.push("/admin/criar/cabecalho-ficha-tecnica/");
    }
  }

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  return (
    <Container>
      <header>
        <h2>Lista de Coleções de Fichas Técnicas</h2>
      </header>

      <Filter>
        <button
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="button"
        >
          <FiSearch />
        </button>
        <input
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="text"
          name="filter"
          id="filter"
          readOnly={true}
        />
        <FilterSelects>
          <ul>
            {filters.alternative_code && (
              <li>
                <span>REF: {filters.alternative_code}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, alternative_code: undefined })
                  }
                />
              </li>
            )}
            {filters.status && (
              <li>
                <span>
                  SITUAÇÃO:{" "}
                  {selectDescription(Number(filters.status), "status")}
                </span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, status: undefined })}
                />
              </li>
            )}
            {filters.brand && (
              <li>
                <span>
                  MARCA: {selectDescription(Number(filters.brand), "brand")}
                </span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, brand: undefined })}
                />
              </li>
            )}
            {filters.collection && (
              <li>
                <span>
                  COLEÇÃO:{" "}
                  {selectDescription(Number(filters.collection), "collection")}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, collection: undefined })
                  }
                />
              </li>
            )}
            {filters.year && (
              <li>
                <span>ANO: {filters.year}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, year: undefined })}
                />
              </li>
            )}
          </ul>
        </FilterSelects>

        {filterView && (
          <FilterCheck ref={outSideRef}>
            <header>
              <button
                onClick={() => {
                  setFilterView(false);
                }}
              >
                Fechar
              </button>
            </header>
            <main>
              <Formik
                initialValues={{
                  status: filters.status,
                  collection: filters.collection,
                  year: filters.year,
                  brand: filters.brand,
                }}
                validationSchema={Yup.object({})}
                onSubmit={(data) =>
                  hadleFilter({
                    ...data,
                  })
                }
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <Input
                      label="REF"
                      {...formik.getFieldProps("alternative_code")}
                    />

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="status">SITUAÇÃO</label>
                        <select {...formik.getFieldProps("status")}>
                          <option value=""></option>
                          {filtersOptions.status.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="field">
                        <label htmlFor="brand">MARCA</label>
                        <select {...formik.getFieldProps("brand")}>
                          <option value=""></option>
                          {filtersOptions.brand.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="collection">COLEÇÃO</label>
                        <select {...formik.getFieldProps("collection")}>
                          <option value=""></option>
                          {filtersOptions.collection.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="field">
                        <label htmlFor="year">ANO</label>
                        <select {...formik.getFieldProps("year")}>
                          <option value=""></option>
                          {filtersOptions.year.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                )}
              </Formik>
            </main>
          </FilterCheck>
        )}
      </Filter>

      <OrderBy
        data={[
          {
            name: "Data criação decrescente",
            value: "created_at.desc",
          },
          {
            name: "Data criação crescente",
            value: "created_at.asc",
          },
          {
            name: "Ano decrescente ",
            value: "year.desc",
          },
          {
            name: "Ano crescente",
            value: "year.asc",
          },
        ]}
        setState={setOrderBy}
        state={orderBy}
      />

      <ContainerList>
        <div className="headerTable">
          <h3>Coleções de Fichas Técnicas</h3>

          <div className="container-actions">
            <Reaload onClick={() => setUpdate(update + 1)}>
              <IoReload size={19} />
              <span>RECARREGAR</span>
            </Reaload>

            {admin && permission.datasheet > 2 && (
              <button onClick={hadleToNew} type="button">
                <MdAdd size={22} />{" "}
                <span> NOVA COLEÇÃO DE FICHAS TÉCNICAS </span>
              </button>
            )}
          </div>
        </div>

        <TableContainer>
          {!loading.panel ? (
            <table>
              <thead>
                <tr>
                  <th>MARCA</th>
                  <th>COLEÇÃO</th>
                  <th>ANO</th>
                  <th>SITUAÇÃO</th>
                  <th>DATA CRIAÇÃO</th>
                </tr>
              </thead>

              <tbody>
                {contentData.map((element) => (
                  <tr
                    key={element.id}
                    onClick={() => {
                      admin
                        ? history.push(
                            `/admin/cabecalho-ficha-tecnica/${element.id}`,
                            {
                              content: element,
                            }
                          )
                        : history.push("/404");
                    }}
                  >
                    <td>{element.brand.name}</td>
                    <td>{element.collection}</td>
                    <td>{element.year}</td>
                    <td>
                      <span
                        className="status"
                        style={{ backgroundColor: element.status.color }}
                      >
                        {element.status.name}
                      </span>
                    </td>

                    <td>{moment(element.created_at).format(`DD/MM/YYYY`)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>

        <FooterContainer>
          <span>
            Mostrado{" "}
            {dataZero(
              Number(contentData.length + pagination.limit * pagination.page)
            )}{" "}
            de {dataZero(Number(pagination.total))} resultados
          </span>

          <div>
            <button
              className={pagination.page < 1 ? "buttonNull" : ""}
              onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
              type="button"
            >
              ANTERIOR
            </button>
            <p style={{ marginRight: 8 }}>
              {dataZero(Number(pagination.page + 1))}
            </p>
            <button
              className={
                pagination.page >= pagination.pages ? "buttonNull" : ""
              }
              type="button"
              onClick={
                Number(pagination.page) >= Number(pagination.pages)
                  ? () => {}
                  : hadleNextPage
              }
            >
              PROXIMO
            </button>
          </div>
        </FooterContainer>
      </ContainerList>
    </Container>
  );
};

export default PanelDatasheet;
