import React, { useMemo } from "react";
import { MdAddBox, MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { useTable } from "react-table";
import Modal from "../ModalLib";
import { ColorAndCategoryProps } from "../ModalModificationDatasheet";
import { ContainerBody } from "../TableClean/styles";
import { Container } from "./styles";

interface TableEditableProps {
  data: object[];
  header?: string;
  isDisabledHeader?: boolean;
  colums: ColumConfigProps[];

  FormCreateComponent: React.FC;
  FormEditComponent: React.FC;

  modalVisibleEdit: boolean;
  setModalVisibleEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabledModalEdit?: boolean;
  setIsDisabledModalEdit?: React.Dispatch<React.SetStateAction<boolean>>;

  modalVisibleCreate: boolean;
  setModalVisibleCreate: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabledModalCreate?: boolean;
  setIsDisabledModalCreate?: React.Dispatch<React.SetStateAction<boolean>>;

  setEditData: (p: any) => void;

  updateData?: (oldData: object, newData: object) => void;
  deleteData: (data: any) => void;

  maxHeightModal?: number;

  disabled?: boolean;

  custonActions?: React.FC;
}

interface ColumConfigProps {
  title: string;
  field: string;

  editable?: boolean;
  inputType: "input" | "select";
  option?: ColorAndCategoryProps[];
  required?: boolean;
}

export const TableEditable: React.FC<TableEditableProps> = ({
  isDisabledHeader,
  header,
  data,
  colums,
  FormCreateComponent,
  FormEditComponent,

  modalVisibleCreate,
  setModalVisibleCreate,
  isDisabledModalCreate,
  setIsDisabledModalCreate,

  modalVisibleEdit,
  setModalVisibleEdit,
  isDisabledModalEdit,
  setIsDisabledModalEdit,

  setEditData,
  updateData,

  deleteData,
  maxHeightModal,

  disabled = false,

  custonActions: CustonActions,
}) => {
  const columns = useMemo(
    () => [
      {
        Header: header ?? "---",
        columns: colums.map((colum) => ({
          Header: colum.title,
          accessor: colum.field,
        })),
      },
    ],
    [header, colums]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        defaultColumn: {
          Cell: ({ value: initialValue, row, column }) => {
            const onChange = (
              e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
            ) => {
              const oldData = row.original;
              const newData = { ...row.original, [column.id]: e.target.value };

              updateData && updateData(oldData, newData);
            };

            const onBlur = (
              e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
            ) => {
              // console.log(e.target.value);
            };

            const findOne = colums.find((f) => f.field === column.id);
            if (!findOne) return;

            if (findOne.inputType === "select") {
              return (
                <select
                  value={initialValue}
                  onChange={onChange}
                  onBlur={onBlur}
                >
                  {!findOne.required && <option value=""></option>}
                  {findOne.option?.map((item) => (
                    <option
                      key={item.id}
                      value={item.cod}
                    >{`${item.cod} ${item.description}`}</option>
                  ))}
                </select>
              );
            } else {
              return (
                <input
                  style={{ opacity: 1 }}
                  readOnly={!findOne.editable}
                  value={initialValue}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              );
            }
          },
        },
      },

      (hooks) => {
        if (!disabled) {
          hooks.visibleColumns.push((columns) => [
            {
              id: "selection",

              Header: () => {
                return <div>Ações</div>;
              },

              Cell: ({ row }) => {
                return (
                  <div className="container-action">
                    <button
                      type="button"
                      onClick={() => deleteData(row.original)}
                    >
                      <MdDeleteOutline />
                    </button>
                    <button
                      type="button"
                      onClick={() => handleEditRow(row.original)}
                    >
                      <MdModeEditOutline />
                    </button>

                    {CustonActions && <CustonActions />}
                  </div>
                );
              },
            },
            ...columns,
          ]);
        }
      }
    );

  function handleEditRow(row: object) {
    setModalVisibleEdit(true);
    setEditData(row);
  }

  return (
    <ContainerBody>
      <Container>
        {!isDisabledHeader && (
          <div>
            <div className="container-subtitle">
              <h3>{header}</h3>

              {!disabled && (
                <button
                  type="button"
                  className="action-button"
                  onClick={() => {
                    setModalVisibleCreate(true);
                  }}
                >
                  <MdAddBox />
                </button>
              )}
            </div>
          </div>
        )}

        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    prepareRow(row);
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        {data.length < 1 && (
          <div className="row-none">Não há registros a serem exibidos</div>
        )}
      </Container>

      <Modal
        title={`Criar ${header}`}
        modalVisible={modalVisibleCreate}
        setModalVisible={setModalVisibleCreate}
        maxWidth={600}
        scrollOff={true}
        maxHeight={maxHeightModal ?? 335}
        isDisabled={isDisabledModalCreate}
        setIsDisabled={setIsDisabledModalCreate}
      >
        {<FormCreateComponent />}
      </Modal>

      <Modal
        title={`Editar ${header}`}
        modalVisible={modalVisibleEdit}
        setModalVisible={setModalVisibleEdit}
        maxWidth={600}
        scrollOff={true}
        maxHeight={maxHeightModal ?? 335}
        isDisabled={isDisabledModalEdit}
        setIsDisabled={setIsDisabledModalEdit}
      >
        {<FormEditComponent />}
      </Modal>
    </ContainerBody>
  );
};
