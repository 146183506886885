import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: relative;

  #logo-alpar {
    width: 22vw;
    min-width: 200px;
    margin-bottom: 30px;
  }

  #arrowBack {
    position: absolute;
    top: 20px;
    left: 25px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: transparent;
    height: 40px;
    width: 40px;

    padding: 8px 8px;

    border-radius: 50%;
    transition: all 0.4s;

    &:hover {
      background-color: rgba(100, 100, 100, 0.3);
    }
  }

  a {
    color: #d0222d;
  }

  .headerViewPassword {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;

    p {
      display: flex;
      justify-items: center;
      align-items: center;

      svg {
        margin-right: 5px;
        margin-bottom: 2px;
      }
    }
  }

  .containerButtons {
    width: 100%;
    display: grid;

    grid-auto-columns: 1fr 1fr;
    grid-auto-rows: auto;
    column-gap: 20px;
    grid-template-areas: "button button";
  }
`;

export const Form = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

  width: 90vw;
  max-width: 420px;
  padding: 20px 15px 30px 15px;
  border-radius: 4px;
  background: #fff;

  form {
    width: 95%;
    height: 100%;
  }

  a {
    font-weight: 400;
    font-size: 14px;
  }
`;

export const ContainerInput = styled.div`
  height: 40px;
  width: 100%;
  margin-bottom: 28px;
  position: relative;

  svg {
    position: absolute;
    top: 8px;
    left: 5px;
    color: #f00;
  }

  input {
    height: 100%;
    width: 100%;

    padding: 1px 0px 0px 35px;
    font-size: 15px;

    color: #888;
    border: none;
    border-bottom: 1.3px solid #999;
  }

  input:focus-within {
    color: #333;
    border-bottom: 1.3px solid #333;
  }
`;

export const Error = styled.div`
  height: 30px;
  width: 95%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 5px;
  margin: 0 0 5px 0;

  svg {
    margin-right: 5px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
`;
