import { useFormik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IPermissionUser } from "../../../@types";
import { BoxAnalyticCharArea } from "../../../components/Analytic/BoxAnalyticCharArea";
import { BoxAnalyticCharBar } from "../../../components/Analytic/BoxAnalyticCharBar";
import { BoxAnalyticCount } from "../../../components/Analytic/BoxAnalyticCount";
import { BoxHeaderInfo } from "../../../components/BoxHeaderInfo";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { TableOrders } from "../../../components/TableListOrders/TableOrders";
import { TableStatus } from "../../../components/TableListOrders/TableStatus";
import { formikFilterOrder } from "../../../components/TableListOrders/formikFilterOrder";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Pagination } from "../../../components/panel/table/Pagination";
import { useBilletsCountClient } from "../../../hook/queries/useBillet";
import { useOneClient } from "../../../hook/queries/useClients";
import { useDevolutionsCountClient } from "../../../hook/queries/useDevolution";
import {
  statusColorAnalytic,
  useOrderAnalytic,
} from "../../../hook/queries/useOrderAnalytic";
import { OrderFilters, useOrders } from "../../../hook/queries/useOrders";
import { ContainerBoxHeaderInfo } from "../../panel/styles";
import { ContainerBoxAnalytic } from "../styles";

interface ClientAnalityProps {
  admin?: boolean;
  client?: boolean;
  seller?: boolean;
  is_manager?: boolean;
  is_supervisor?: boolean;

  permission: IPermissionUser;

  match: {
    params: {
      id: number;
    };
  };
}

const Client = ({
  seller,
  client,
  admin,
  is_manager,
  match,
}: ClientAnalityProps) => {
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<OrderFilters>({});
  const formikFilter = useFormik({
    ...formikFilterOrder({ handleFilter }),
  });

  const orderAnalytic = useOrderAnalytic({
    client: true,
    clientId: match.params.id,
  });
  const totalOrdersExpiredTitles = useOrders(
    1,
    {
      highlighter: "TITULO VENCIDO",
      status: "Aguardando Faturamento, Recusado",
      type: "pronta entrega",
      refuse: 39,
      idClient: match.params.id,
    },
    1
  );
  const totalOrdersCreditLimit = useOrders(
    1,
    {
      status: "Recusado",
      type: "pronta entrega",
      refuse: 44,
      idClient: match.params.id,
    },
    1
  );

  const getClient = useOneClient(match.params.id);
  const totalBillet = useBilletsCountClient(match.params.id);
  const totalDevolution = useDevolutionsCountClient(match.params.id);
  const getOrders = useOrders(page, { ...filters, idClient: match.params.id });

  function handleFilter(filter: OrderFilters) {
    setFilters(filter);
    setPage(1);
  }

  return (
    <CreateAndDetailLayout isLoading={getClient?.isLoading}>
      <PanelAndDetailAndCreateHeader title={"Análise do cliente"} goBack />

      <ContainerBoxHeaderInfo>
        <BoxHeaderInfo
          data={{
            title: "CÓD. CLIENTE",
            value: getClient?.data?.cod?.toString() ?? "-",
          }}
        />
        <BoxHeaderInfo
          data={{ title: "CNPJ", value: getClient?.data?.cnpj ?? "-" }}
        />
        <BoxHeaderInfo
          data={{ title: "CLIENTE", value: getClient?.data?.trade_name ?? "-" }}
        />
      </ContainerBoxHeaderInfo>

      <ContainerBoxAnalytic style={{ marginTop: 24, marginBottom: 0 }}>
        <BoxAnalyticCount
          description="Devoluções Pendentes"
          qtd={totalDevolution.data?.totalCount ?? 0}
          isLoading={totalDevolution.isLoading}
        />

        <BoxAnalyticCount
          description="Boletos em Aberto"
          qtd={totalBillet.data?.totalCount ?? 0}
          isLoading={totalBillet.isLoading}
          obs="Boletos em aberto referente á 15 dias antecedente ao dia de hoje."
        />
      </ContainerBoxAnalytic>
      <ContainerBoxAnalytic style={{ marginTop: 10, marginBottom: 0 }}>
        <BoxAnalyticCount
          description="Pedidos pronta entrega pendentes (TITULO VENCIDO)"
          qtd={totalOrdersExpiredTitles.data?.totalCount ?? 0}
          isLoading={totalOrdersExpiredTitles.isLoading}
          externalLink={`/${
            admin ? "admin" : "representante"
          }/pedidos/situacao/pedidos?page=1&status=Aguardando+Faturamento,Recusado&refuse=44&type=pronta+entrega&highlighter=TITULO+VENCIDO&codClient=${getClient?.data?.cod?.toString()}`}
        />
        <BoxAnalyticCount
          description="Pedidos pronta entrega pendentes (LIMITE CREDITO)"
          qtd={totalOrdersCreditLimit.data?.totalCount ?? 0}
          isLoading={totalOrdersCreditLimit.isLoading}
          externalLink={`/${
            admin ? "admin" : "representante"
          }/pedidos/situacao/pedidos?page=1&type=pronta+entrega&status=Recusado&refuse=44&codClient=${getClient?.data?.cod?.toString()}`}
        />
      </ContainerBoxAnalytic>

      <ContainerBoxAnalytic style={{ marginTop: 9 }}>
        <BoxAnalyticCharBar
          title="Pedidos por Situação"
          descriptions={orderAnalytic?.data?.totalAmount.description ?? []}
          values={orderAnalytic?.data?.totalAmount.amount ?? []}
          colors={orderAnalytic?.data?.totalAmount.description.map((s) => {
            return statusColorAnalytic.find((f) => f.name === s)?.color ?? "";
          })}
          obs={
            seller ? (
              <span className="span-alert">
                *Pedidos{" "}
                <Link
                  to={
                    // SellerData.isSankhya
                    //   ? "/representante/pedidos-snk/situacao/credito?status=8"
                    //   : "/representante/pedidos/situacao/Recusados"
                    "/representante/pedidos-snk/situacao/pedidos?page=1&status=Recusado"
                  }
                >
                  RECUSADOS
                </Link>{" "}
                são cancelados em ate 4 dias.
              </span>
            ) : undefined
          }
        />

        <BoxAnalyticCharArea
          title="Valores de Pedidos"
          dates={orderAnalytic?.data?.totalPriceMonths.date ?? []}
          values={orderAnalytic?.data?.totalPriceMonths.content ?? []}
          colors={orderAnalytic?.data?.totalPriceMonths.content.map((s) => {
            return (
              statusColorAnalytic.find((f) => f.name === s.name)?.color ?? ""
            );
          })}
          obs={
            <>
              Range 6 meses de pedidos utilizando as datas de
              Faturamento/Cancelamento de acordo com situação do pedido.
            </>
          }
        />
      </ContainerBoxAnalytic>

      <TableStatus
        data={
          getOrders.data?.filters.status.map((status) => ({
            name: status.name,
            value: String(status.id),
          })) ?? []
        }
        setFilters={setFilters}
        isLoading={getOrders.isLoading}
      />

      <TableOrders
        orders={getOrders.data?.orders ?? []}
        userType={
          admin
            ? "admin"
            : client
            ? "client"
            : seller && is_manager
            ? "sellerManager"
            : "seller"
        }
        filters={{ ...filters, idClient: match.params.id }}
        setFilters={setFilters}
        formikFilter={formikFilter}
        refetch={getOrders.refetch}
        isFetching={getOrders.isFetching}
        isLoading={getOrders.isLoading}
        isExportData={admin || seller}
      />

      <Pagination
        totalCountOfRegisters={getOrders.data?.totalCount ?? 0}
        registersPerPage={getOrders.data?.registersPerPage ?? 0}
        currentPage={page}
        onPageChange={(data) => {
          setPage(data);
        }}
      />
    </CreateAndDetailLayout>
  );
};

export default Client;
