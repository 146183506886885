import React, { useEffect, useRef, useState } from "react";

import { useToasts } from "react-toast-notifications";
import {
  RowNone,
  TabletSelect,
} from "../../../../components/ModalModificationDatasheet/SelectProvider/styles";
import {
  ButtunSubmit,
  ContainerList,
  Filter,
  FilterCheck,
  FilterSelects,
  FooterContainer,
  Form,
  MainFilter,
  ModalBody,
  ScreenLoading,
  TableContainer,
} from "../../../../pages/create/styles";
import { Container, ContainerForm, ContainerTable } from "./styles";

import { MdAddBox } from "react-icons/md";
import { OptionsFilter } from "../../../../@types";
import NoImage from "../../../../assets/no-image.jpg";
import Loading from "../../../../components/loadings/Loading";
import { dataUndefined, dataZero } from "../../../../utils/dataProcessing";

import { useFormik } from "formik";
import { FiSearch } from "react-icons/fi";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import * as Yup from "yup";
import Modal from "../../../../components/ModalLib";
import {
  DatasheetProps,
  HeaderDatasheetAndDatasheet,
} from "../../../../pages/detail/Datasheet";
import api from "../../../../service/api";

interface ModalLinkDatasheetProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onRequestUpdate: () => void;

  id?: number;
  headerDatasheetId?: number;
  brandId: number;
}
interface IFilter {
  notId?: number;
  notHeaderDatasheetId?: number;
  status?: string;
  alternative_code?: string;
  collection?: string;
  year?: string;
  brand?: number;

  isFinished?: boolean;
  isGenerateBarcode?: boolean;
}

interface IFilterOption {
  status: OptionsFilter[];
  brand: OptionsFilter[];
  collection: OptionsFilter[];
  year: OptionsFilter[];
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IResponseAPI {
  contents: HeaderDatasheetAndDatasheet[];
  filters: IFilterOption;
  total: number;
  limit: number;
  page: number;
  pages: number;
}

export const ModalLinkDatasheet: React.FC<ModalLinkDatasheetProps> = ({
  isOpen,

  onRequestUpdate,
  onRequestClose,

  headerDatasheetId,
  brandId,
  id,
}) => {
  const { addToast } = useToasts();
  const outSideRefFilter = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [modalVisibleLinkDatasheet, setModalVisibleLinkDatasheet] =
    useState(false);
  const [isDisabledModal, setIsDisabledModal] = useState<boolean>(false);

  const [selectDatashets, setSelectDatashets] = useState<
    HeaderDatasheetAndDatasheet[]
  >([]);

  const [datashets, setDatashets] = useState<HeaderDatasheetAndDatasheet[]>([]);

  const [filters, setFilters] = useState<IFilter>({
    notHeaderDatasheetId: headerDatasheetId,
    brand: brandId,
    // isFinished: true,
    // isGenerateBarcode: true,
  });
  const [filtersOptions, setFiltersOptions] = useState<IFilterOption>({
    status: [],
    brand: [],
    collection: [],
    year: [],
  });
  const [filterView, setFilterView] = useState(false);
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 4 } as IRequestPagination);

  const formikFilter = useFormik({
    initialValues: {
      status: filters.status,
      collection: filters.collection,
      year: filters.year,
      brand: filters.brand,
      alternative_code: filters.alternative_code,
    },

    validationSchema: Yup.object({}),

    onSubmit: (data) => {
      hadleFilter({
        ...data,
        alternative_code: data.alternative_code?.toUpperCase(),
      });
    },
  });

  useEffect(() => {
    (async () => {
      if (isOpen) {
        setLoadingPage(false);
        try {
          const datasheetsDB = await api.get<IResponseAPI>(
            "/headerDatasheet/datasheet/list/all",
            {
              params: { ...requestPagination, ...filters },
            }
          );

          const {
            limit,
            page,
            pages,
            total,
            filters: filtersData,
          } = datasheetsDB.data;

          setDatashets(datasheetsDB.data.contents);
          setFiltersOptions(filtersData);
          setPagination({ limit, page, pages, total });
          setLoadingPage(false);
        } catch (error) {
          setLoadingPage(false);
        }
      }
    })();
    // eslint-disable-next-line
  }, [filters, requestPagination]);

  function hadleFilter(filter: IFilter) {
    setFilters({ ...filters, ...filter });
    setFilterView(false);
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }
  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  function selectDescription(
    st: number,
    title: "status" | "collection" | "brand"
  ) {
    let data = "";
    filtersOptions[title].forEach((item) => {
      if (item.id === st) {
        return (data = item.name);
      }
    });

    return data;
  }

  function handleSelectDatasheet(datasheet: HeaderDatasheetAndDatasheet) {
    const existProvider = selectDatashets.find((f) => f.id === datasheet.id);

    if (!existProvider) {
      setSelectDatashets((oldData) => [...oldData, datasheet]);
    }

    if (!isDisabledModal) setModalVisibleLinkDatasheet(false);
  }
  function handleRemoveDatasheet(datasheet: DatasheetProps) {
    const datasheetFilter = selectDatashets.filter(
      (f) => f.id !== datasheet.id
    );

    setSelectDatashets([...datasheetFilter]);
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);

    if (selectDatashets.length < 1) {
      setLoading(false);
      return addToast("Nenhum dado selecionado", {
        appearance: "warning",
        autoDismiss: true,
      });
    }

    try {
      await api.post(`/headerDatasheet/datasheet/clone/${headerDatasheetId}`, {
        datasheets: selectDatashets.map((datashet) => ({ id: datashet.id })),
      });

      onRequestUpdate();
      onRequestClose();
      setLoading(false);

      return addToast("Fichas técnicas adicionadas com sucesso. ", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setLoading(false);

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <>
      <Container>
        <Form onSubmit={handleSubmit}>
          <ContainerForm>
            <div className="containerForm">
              {!loadingPage ? (
                <>
                  {/* <h3>Tipo</h3>
                  <div className="field">
                    <ContainerCheck>
                      <button
                        type="button"
                        className={
                          typeLinkDatasheet === "isCarryOver" ? "check" : ""
                        }
                        onClick={() => {
                          setTypeLinkDatasheet("isCarryOver");
                        }}
                      >
                        CARRY OVER
                      </button>

                      <button
                        type="button"
                        className={
                          typeLinkDatasheet === "isClassic" ? "check" : ""
                        }
                        onClick={() => {
                          setTypeLinkDatasheet("isClassic");
                        }}
                      >
                        CLÁSSICO
                      </button>
                    </ContainerCheck>
                  </div>

                  <br />
                  <br />
                  */}

                  <div className="container-subtitle">
                    <h3>Fichas técnicas selecionados</h3>

                    <button
                      type="button"
                      className="action-button"
                      onClick={() => {
                        setModalVisibleLinkDatasheet(true);
                      }}
                    >
                      <MdAddBox />
                    </button>
                  </div>

                  <ContainerTable style={{ maxHeight: "90%" }}>
                    <TabletSelect>
                      <thead>
                        <tr>
                          <th>Cód. Alternativo</th>
                          <th>Coleção - Ano</th>
                          <th>Descrição</th>
                          <th>Tipo</th>
                          <th>Ação</th>
                        </tr>
                      </thead>

                      <tbody>
                        {selectDatashets.map((datasheet, indexData) => (
                          <tr key={indexData}>
                            <td>{datasheet.alternative_code}</td>

                            <td>{`${
                              //@ts-ignore
                              datasheet.header_datasheet.collection.description
                            } - ${datasheet.header_datasheet.year}`}</td>
                            <td>{datasheet.description}</td>
                            <td>
                              {datasheet.isClassic ? "Clássico" : "Carryover"}
                            </td>

                            <td>
                              <button
                                type="button"
                                className="action-button"
                                onClick={() => {
                                  handleRemoveDatasheet(datasheet);
                                }}
                              >
                                <IoMdClose />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </TabletSelect>
                  </ContainerTable>

                  {selectDatashets.length < 1 && (
                    <RowNone>Não há registros a serem exibidos</RowNone>
                  )}
                </>
              ) : (
                <ScreenLoading>
                  <Loading
                    colorLoading="#333"
                    size={40}
                    borderColor={"#888"}
                    borderSize={5}
                  />
                </ScreenLoading>
              )}
            </div>
            <div className="containerFormButton">
              <ButtunSubmit style={{ margin: 0 }} type="submit">
                {loading ? (
                  <div>
                    <Loading borderSize={2} size={20} />
                  </div>
                ) : (
                  "VINCULAR"
                )}
              </ButtunSubmit>
            </div>
          </ContainerForm>
        </Form>
      </Container>

      <Modal
        title="Fichas técnicas"
        modalVisible={modalVisibleLinkDatasheet}
        setModalVisible={setModalVisibleLinkDatasheet}
        isDisabled={isDisabledModal}
        setIsDisabled={setIsDisabledModal}
        maxWidth={750}
        maxHeight={560}
      >
        <ModalBody>
          <Filter>
            <button
              onClick={() => {
                setFilterView(!filterView);
              }}
              type="button"
            >
              <FiSearch />
            </button>
            <input
              onClick={() => {
                setFilterView(!filterView);
              }}
              type="text"
              name="filter"
              id="filter"
              readOnly={true}
            />

            <FilterSelects>
              <ul>
                {filters.alternative_code && (
                  <li>
                    <span>Código Alternativo: {filters.alternative_code}</span>
                    <IoMdCloseCircle
                      onClick={() =>
                        setFilters({
                          ...filters,
                          alternative_code: undefined,
                        })
                      }
                    />
                  </li>
                )}
                {filters.status && (
                  <li>
                    <span>
                      Situação:{" "}
                      {selectDescription(Number(filters.status), "status")}
                    </span>
                    <IoMdCloseCircle
                      onClick={() =>
                        setFilters({ ...filters, status: undefined })
                      }
                    />
                  </li>
                )}
                {filters.brand &&
                  selectDescription(Number(filters.brand), "brand") && (
                    <li>
                      <span>
                        Marca:{" "}
                        {selectDescription(Number(filters.brand), "brand")}
                      </span>
                    </li>
                  )}
                {filters.collection && (
                  <li>
                    <span>
                      Coleção:{" "}
                      {selectDescription(
                        Number(filters.collection),
                        "collection"
                      )}
                    </span>
                    <IoMdCloseCircle
                      onClick={() =>
                        setFilters({ ...filters, collection: undefined })
                      }
                    />
                  </li>
                )}
                {filters.year && (
                  <li>
                    <span>ANO: {filters.year}</span>
                    <IoMdCloseCircle
                      onClick={() =>
                        setFilters({ ...filters, year: undefined })
                      }
                    />
                  </li>
                )}
              </ul>
            </FilterSelects>

            {filterView && (
              <FilterCheck ref={outSideRefFilter}>
                <MainFilter>
                  <Form onSubmit={formikFilter.handleSubmit}>
                    <div className="field">
                      <label htmlFor="alternative_code">
                        Código Alternativo
                      </label>
                      <input
                        {...formikFilter.getFieldProps("alternative_code")}
                      />
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="status">Situação</label>
                        <select {...formikFilter.getFieldProps("status")}>
                          <option value=""></option>
                          {filtersOptions.status.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="collection">Coleção</label>
                        <select {...formikFilter.getFieldProps("collection")}>
                          <option value=""></option>
                          {filtersOptions.collection.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="field">
                        <label htmlFor="year">Ano</label>
                        <select {...formikFilter.getFieldProps("year")}>
                          <option value=""></option>
                          {filtersOptions.year.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({
                            notId: id,
                            notHeaderDatasheetId: headerDatasheetId,
                            brand: brandId,
                            isFinished: true,
                            isGenerateBarcode: true,
                          });
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                </MainFilter>
              </FilterCheck>
            )}
          </Filter>

          <ContainerList>
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th>Imagem</th>
                    <th>Cód. Alternativo</th>
                    <th>Coleção - Ano</th>
                    <th>Descrição</th>
                    <th>Tipo</th>
                  </tr>
                </thead>

                <tbody>
                  {datashets.map((datasheet) => (
                    <tr
                      key={datasheet.id}
                      onClick={() => handleSelectDatasheet(datasheet)}
                    >
                      <td className="td-img-logo">
                        <img
                          src={
                            datasheet.files[0]
                              ? datasheet.files[0].url
                              : NoImage
                          }
                          alt={datasheet.description}
                        />
                      </td>
                      <td> {dataUndefined(datasheet.alternative_code)} </td>
                      <td>{`${
                        //@ts-ignore
                        datasheet.header_datasheet.collection.description
                      } - ${datasheet.header_datasheet.year}`}</td>
                      <td> {dataUndefined(datasheet.description)} </td>
                      <td>{datasheet.isClassic ? "Clássico" : "Carryover"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
            <FooterContainer>
              <span>
                Mostrado{" "}
                {datashets.length > 0
                  ? dataZero(
                      Number(
                        datashets.length + pagination.limit * pagination.page
                      )
                    )
                  : 0}{" "}
                de {dataZero(Number(pagination.total))} resultados
              </span>

              <div>
                <button
                  className={pagination.page <= 0 ? "buttonNull" : ""}
                  type="button"
                  onClick={pagination.page <= 0 ? () => {} : hadlePreviousPage}
                >
                  ANTERIOR
                </button>
                <p style={{ marginRight: 8 }}>{pagination.page + 1}</p>
                <button
                  className={
                    pagination.page >= pagination.pages ? "buttonNull" : ""
                  }
                  type="button"
                  onClick={
                    pagination.page >= pagination.pages
                      ? () => {}
                      : hadleNextPage
                  }
                >
                  PROXIMO
                </button>
              </div>
            </FooterContainer>
          </ContainerList>
        </ModalBody>
      </Modal>
    </>
  );
};
