import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { HeaderDatasheetAndDatasheet } from "../../";
import { TableClean } from "../../../../../components/TableClean";
import {
  ImageProps,
  ViewImageProducts,
} from "../../../../../components/ViewImageProducts";
import api from "../../../../../service/api";
import { dataUndefined } from "../../../../../utils/dataProcessing";
import { ContentGroup } from "../../styles";
import { ModalVariation } from "./ModalVariation";
import { Container, Content, Info } from "./styles";

interface DatasheetActionsDetailProps {
  isActive: boolean;
  setUpdate: () => void;

  data: HeaderDatasheetAndDatasheet;

  admin?: boolean;
  provider?: boolean;
}

export const DatasheetActionsDetail: React.FC<DatasheetActionsDetailProps> = ({
  isActive,
  data,
  setUpdate,
  admin,
}) => {
  const { addToast } = useToasts();
  const [images, setImages] = useState<ImageProps[]>([]);
  const [selectVariation, setSelectVariation] = useState(
    data?.prioritize_variation_book_id
  );

  useEffect(() => {
    const filesImages: ImageProps[] = data.files
      .filter((f) => f.category_file.id === 1 || f.category_file.id === 3)
      .map((d) => ({
        id: d.id,
        name: d.name,
        url: d.url,
      }));

    setImages(filesImages);
  }, [data]);

  return (
    <Container style={{ display: !isActive ? "none" : "" }}>
      <ContentGroup>
        <Content>
          <ViewImageProducts data={images} />
        </Content>
        <Content>
          <Info>
            <div
              style={{ marginBottom: 12, fontSize: "1.15rem" }}
              className="description-info"
            >
              Cód. Alternativo:{" "}
              <span>{dataUndefined(data?.alternative_code)}</span>
            </div>

            <div className="description-info">
              Coleção:{" "}
              <span>
                {data.header_datasheet?.collection &&
                data.header_datasheet?.year
                  ? `${data.header_datasheet.collection} - ${data.header_datasheet.year}`
                  : "-"}
              </span>
            </div>

            <div className="description-info">
              Descrição: <span>{dataUndefined(data.description)}</span>
            </div>

            {admin && (
              <div className="group-info">
                <div className="description-info">
                  Preço de custo:{" "}
                  <span>
                    {dataUndefined(
                      Number(data?.price_cost)?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    )}
                  </span>
                </div>
                <div className="description-info">
                  Preço de venda:{" "}
                  <span>
                    {dataUndefined(
                      Number(data?.price_sale)?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    )}
                  </span>
                </div>
              </div>
            )}

            <div className="description-info">
              Grade:{" "}
              <span>
                {dataUndefined(
                  data?.grids
                    .map((grid) => grid.size.description)
                    .sort((a, b) => {
                      if (!isNaN(Number(a)) && !isNaN(Number(b))) {
                        return Number(a) - Number(b);
                      } else {
                        if (a === "G" && b === "GG") {
                          return -1;
                        }

                        if (a === "GG" && b === "G") {
                          return 1;
                        }

                        if (a > b) {
                          return -1;
                        }
                        if (a < b) {
                          return 1;
                        }

                        return 0;
                      }
                    })
                    .join()
                )}
              </span>
            </div>
            <div className="description-info">
              Linha: <span>{dataUndefined(data?.product_line?.name)}</span>
            </div>

            <div className="description-info">
              Grupo: <span>{dataUndefined(data?.product_group?.name)}</span>
            </div>

            <div className="group-info">
              <div className="description-info">
                Tecido Comercial:{" "}
                <span>{dataUndefined(data?.material?.description)}</span>
              </div>
              <div className="description-info">
                Tecido/Artigo: <span>{dataUndefined(data?.tissue)}</span>
              </div>
            </div>

            <div className="description-info">
              Gênero: <span>{dataUndefined(data?.genre?.name)}</span>
            </div>
            <div className="description-info">
              Unid. Medida Embalagem:{" "}
              <span>
                {dataUndefined(
                  data?.unit_measure?.initials?.toLocaleUpperCase()
                )}
              </span>
            </div>
            <div className="description-info">
              Tipo: <span>{dataUndefined(data?.product_type?.name)}</span>
            </div>
            <div className="description-info">
              Classe de produto:{" "}
              <span>{dataUndefined(data?.category?.description)}</span>
            </div>
            <div className="description-info">
              Características:{" "}
              <span>{dataUndefined(data?.characteristics)}</span>
            </div>

            <div className="description-info">
              Composição:{" "}
              <div>
                <textarea
                  rows={3}
                  readOnly={true}
                  style={{
                    fontWeight: 500,
                    color: "#333",
                    fontSize: "1rem",
                    opacity: 1,
                    width: "100%",
                    resize: "vertical",
                  }}
                >
                  {dataUndefined(data?.composition)}
                </textarea>
              </div>
            </div>
          </Info>
        </Content>
      </ContentGroup>

      <Content
        className="contentTable"
        style={{
          marginTop: "2rem",
        }}
      >
        <div className="description-table-clean">
          <h3>Tabela de Varições</h3>
        </div>

        <TableClean
          admin={admin}
          columns={[
            {
              field: "reference",
              title: "Referência",
            },
            {
              field: "primary_color",
              title: "1ª Cor Predominante ",
            },
            {
              field: "secondary_color",
              title: "2ª Cor Predominante ",
            },
          ]}
          data={
            data?.variations.filter((f) => f.is_drop === false).length === 0
              ? [
                  {
                    reference: "-",
                    primary_color: "-",
                    secondary_color: "-",
                  },
                ]
              : [
                  ...data?.variations
                    .filter((f) => f.is_drop === false)
                    .map((item) => ({
                      id: item.id,
                      reference: item.reference,
                      primary_color: `${item.primary_color} ${item.primary_color_name}`,
                      secondary_color:
                        item.secondary_color !== "-"
                          ? `${item.secondary_color} ${item.secondary_color_name}`
                          : "-",
                      variations: item.variations,
                      is_generate_barcode_sample:
                        data.status.is_generate_barcode_sample,
                    })),
                ]
          }
          modal={ModalVariation}
          setUpdate={setUpdate}
          selectTitle="Mostruário"
          setSelectDisabled={data.status.is_generate_book ? false : true}
          setSelect={
            admin
              ? async (e) => {
                  try {
                    addToast("Alterado dados...", {
                      appearance: "info",
                      autoDismiss: true,
                    });

                    await api.put(`/headerDatasheet/datasheet/${data.id}`, {
                      prioritize_variation_book_id: e.id,
                    });
                    setSelectVariation(e.id);

                    addToast("Alterado com sucesso", {
                      appearance: "success",
                      autoDismiss: true,
                    });
                  } catch (error) {
                    return addToast(
                      "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
                      {
                        appearance: "error",
                        autoDismiss: true,
                      }
                    );
                  }
                }
              : undefined
          }
          selectActive={selectVariation}
        />

        <br />
        <br />

        <div className="description-table-clean">
          <h3>Fornecedores</h3>
        </div>
        <TableClean
          admin={admin}
          columns={[
            {
              field: "cod",
              title: "Cod.",
            },
            {
              field: "fullname",
              title: "Nome",
            },
          ]}
          data={
            data?.provider.length === 0
              ? [
                  {
                    cod: "-",
                    fullname: "-",
                  },
                ]
              : [
                  ...data?.provider.map((item) => ({
                    ...item,
                    cod: item.cod ?? "-",
                  })),
                ]
          }
        />

        {/* TABELA DE MEDIDAS */}
        {/* <br />
        <br />

        <div className="description-table-clean">
          <h3>Tabela de Medidas</h3>
          <span>
            UNIDADE DE MEDIDA:{" "}
            {dataUndefined(data?.measurement_table?.unit_measurement)}
          </span>
        </div>

        <TableClean
          columns={[
            {
              field: "description",
              title: "Descrição",
            },
            {
              field: "size",
              title: "Tamanho",
            },
          ]}
          data={
            data?.measurement_table.measurement_data.length === 0
              ? [
                  {
                    description: "-",
                    size: "-",
                  },
                ]
              : [...data?.measurement_table.measurement_data]
          }
        />
        <div className="description-table-clean">
          <span>
            MEDIDA REF.:{" "}
            {dataUndefined(data?.measurement_table?.description_measured)}
          </span>
        </div> */}
      </Content>
    </Container>
  );
};
