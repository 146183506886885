import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import { useBanners } from "../../hook/queries/useBanners";
import { ContainerBanner, PaginationSlide, Slide } from "./styles";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface Props {
  admin?: boolean;
  client?: boolean;
  seller?: boolean;
  provider?: boolean;
  accessSsoCatalog: () => void;
}

export function HomeBanners({ accessSsoCatalog }: Props) {
  const [indexSlide, setIndexSlide] = useState(0);

  const { data } = useBanners(1, undefined, 999, { listByUser: true });

  function handleOnClickBannerLink(link: string) {
    if (link.trim() === "https://app.alpardobrasil.com.br") {
      accessSsoCatalog();
    } else {
      setTimeout(() => {
        window.open(link, "_blank");
      });
    }
  }

  if (!data?.banners || data?.banners.length <= 0) return null;

  return (
    <ContainerBanner>
      <PaginationSlide>
        {data?.banners.map((_, index) => (
          <div
            key={index}
            onClick={() => setIndexSlide(index)}
            style={index === indexSlide ? { background: "#333" } : undefined}
          />
        ))}
      </PaginationSlide>

      <AutoPlaySwipeableViews
        enableMouseEvents
        index={indexSlide}
        onChangeIndex={setIndexSlide}
        interval={
          6 * 1000 // 6 seconds
        }
      >
        {data?.banners.map((banner) => (
          <Slide
            key={banner.id}
            style={banner.link ? { cursor: "pointer" } : undefined}
            onClick={
              banner.link
                ? () => handleOnClickBannerLink(banner?.link ?? "")
                : undefined
            }
          >
            <img alt={banner.title} src={banner.file.url} />
          </Slide>
        ))}
      </AutoPlaySwipeableViews>
    </ContainerBanner>
  );
}
