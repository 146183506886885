import React from "react";
import { Container } from "./styles";

interface OrderByProps {
  data: OrderByValueProps[];

  setState: React.Dispatch<React.SetStateAction<string>>;
  state: string;

  setUpdate?: () => void;
}

export interface OrderByValueProps {
  name: string;
  value: string;
}

export const OrderBy: React.FC<OrderByProps> = ({
  data,
  setState,
  state,
  setUpdate,
}) => {
  function handleOnChenge(event: React.ChangeEvent<HTMLSelectElement>) {
    setUpdate && setUpdate();
    setState(event.target.value);
  }

  return (
    <Container>
      <label htmlFor="orderby">
        Ordenar por
        <select onChange={handleOnChenge} value={state} name="orderby">
          {data.length < 1 && <option value=""></option>}
          {data.map((order) => (
            <option key={order.value} value={order.value}>
              {order.name}
            </option>
          ))}
        </select>
      </label>
    </Container>
  );
};
