import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const ContainerForm = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 60px;
  grid-template-areas:
    "FORM"
    "BTN";

  & > div:nth-child(1) {
    grid-area: FORM;
    overflow-y: scroll;
  }
  & > div:nth-child(2) {
    grid-area: BTN;
  }
`;

export const ContainerInputButtonScanner = styled.div`
  display: flex;
  align-items: flex-start;

  button {
    height: 100%;
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.765rem;
    margin-right: 10px;

    background-color: rgb(65, 105, 225);
    color: white;

    svg {
      font-size: 2rem;
    }

    span {
      font-size: 1rem;
      font-weight: 400;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  margin-bottom: 35px;
  gap: 0.6rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 50px;
    padding: 0.465rem;

    font-weight: 700;
    font-size: 16px;
    color: #fff;

    &:nth-child(1) {
      background: #e4b82b;
    }
    &:nth-child(3) {
      background: #b0cd61;
    }
    &:nth-child(2) {
      background: #d21e26;
    }
    border-radius: 4px;
    /* &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    } */

    &:hover {
      opacity: 0.85;
    }
  }
`;

export const ButtonIncrement = styled.button`
  width: 3rem;
  height: 3rem;
  /* margin-top: auto; */
  margin-bottom: auto;
  border-radius: 6px;
  background: rgb(65, 105, 225);

  span {
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
  }
`;
