export function dataUndefined(data: string | number | Date | null | undefined) {
  let setData = data;

  if (
    data === undefined ||
    data === null ||
    data === "" ||
    data === "undefined" ||
    !data
  ) {
    setData = "-";
  }

  return setData;
}

export function dateVerify(data: string | number | Date | null | undefined) {
  let setData = data;

  if (data === undefined || data === null || Number(data) < 0) {
    setData = "-";
  }

  return setData;
}

export function dataZero(data: number | null | undefined) {
  let setData = data;

  if (data === undefined || data === null || isNaN(data)) {
    setData = 0;
  }

  return setData;
}
