import React from "react";

import { Conatiner } from "./styles";

interface ButtonSubmitProps {
  type?: "button" | "submit" | "reset" | undefined;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const ButtonSubmit: React.FC<ButtonSubmitProps> = ({
  children,
  type = "submit",
  style,
  onClick,
  disabled,
}) => {
  return (
    <Conatiner disabled={disabled} onClick={onClick} type={type} style={style}>
      {children}
    </Conatiner>
  );
};
