import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  grid-area: APP;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }
`;

export const ExternalLinkA = styled.a`
  height: 50px;
  width: 50px;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
`;

export const ContainerFloatButton = styled.div`
  position: fixed;
  bottom: 2px;
  right: 2px;
  z-index: 3;

  .fixedButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0.4;

    position: absolute;
    bottom: 10px;
    right: 20px;

    button {
      width: 50px;
      height: 50px;

      background-color: #d21e26;
      border-radius: 100%;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);

      transition: all 0.2s ease-in-out;

      &:hover {
        box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.8);
        transform: scale(1.1);
      }
    }
  }
`;

export const ContainerAppointmentForm = styled.div`
  .headerAppointment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 5px;
    height: 40px;
    width: 100%;

    .closeAppointment {
      height: 40px;
      width: 40px;

      display: flex;
      justify-content: center;
      align-items: center;

      border-radius: 50%;
      font-size: 22px;
      background: transparent;

      transition: all 0.4s;

      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    .actionAppointment {
      height: 90%;
      padding: 0 8px 0 8px;
      border-radius: 6px;
      background: rgb(50, 168, 82);
      span {
        font-weight: 700;
        color: #fff;
        font-size: 14px;
      }

      transition: all 0.4s;
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .bodyAppointment {
    padding: 10px 0 0 0;
  }
`;

export const FormAppointment = styled.form`
  border-radius: 4px 0px 0px 4px;
  width: 100%;
  height: 100%;
  background-color: #fff;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 60px;

  grid-template-areas:
    "FORM"
    "BTN";

  .containerForm {
    grid-area: FORM;
    overflow-y: scroll;
    padding: 10px;
  }

  .containerFormButton {
    grid-area: BTN;
    padding: 0 8px;
  }

  h3 {
    font-size: 18;
    color: #333;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0 0px;
  }

  .field-group {
    width: 100%;
    display: flex;
    flex-flow: row;
  }

  .field {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-bottom: 24px;
    padding: 0 10px;

    .containerLabelButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2px;

      button {
        border-radius: 4px;
        background: transparent;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #d21e26;
        padding: 2px 4px;
        font-weight: 600;
      }
    }

    label {
      font-size: 14px;
      margin-bottom: 4px;
      color: #555;

      .required {
        color: #f00;
        font-size: 11px;
      }
    }
    input {
      padding: 0 10px;
      background: #fff;
      height: 35px;
      font-size: 16px;
      color: #000;
      border: 2px solid #aaa;
      border-radius: 5px;
    }

    .timePicker {
      span {
        font-weight: 700;
        margin: 3px 2px 0 3px;
      }

      select {
        width: 48%;
      }
    }

    .selectClient {
      height: 40px;
      width: 100%;

      button {
        width: 100%;
        height: 100%;
        background: #d21e26;

        font-weight: bold;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
      }
    }

    input:focus {
      border: 2px solid #333;
    }

    input::placeholder {
      color: #a0a0b2;
    }

    textarea {
      padding: 10px 10px;
      background: #fff;
      height: 80px;
      font-size: 16px;
      color: #000;
      border: 2px solid #aaa;
      border-radius: 5px;
      resize: vertical;

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-thumb {
        background: #333;
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background: #888;
      }

      &:focus {
        border: 2px solid #333;
      }
    }

    select {
      height: 35px;

      background: #fff;
      border-radius: 5px;
      border: 2px solid #aaa;
      padding: 4px 8px;

      font-size: 16px;
      color: #aaa;

      cursor: pointer;
      /* -webkit-appearance: none; */
      -moz-appearance: none;
      /* appearance: none;  */

      &:focus {
        border: 2px solid #333;
        color: #333;
      }
    }
  }

  .field-group-picker-time {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .containerCnpj {
    display: flex;
    width: 100%;
    button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 15%;
      height: 35px;

      color: #fff;
      font-size: 16px;
      border-radius: 4px;
      background-color: #d21e26;
      transition: all 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
    input {
      width: 85%;
      margin-right: 8px;
    }
  }

  @media (max-width: 900px) {
    .field-group {
      flex-flow: column;
    }

    .field {
      padding: 0 2px;
      input {
        padding: 0 0;
      }
    }
  }
`;

export const ButtunSubmit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 50px;

  font-weight: 700;
  font-size: 16px;
  color: #fff;

  background: #d21e26;
  border-radius: 4px;

  &:hover {
    opacity: 0.85;
  }
`;

export const ContainerPreviews = styled.ul`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  li {
    width: 100%;
    display: flex;
    align-items: center;
    background: #f0f0f0;
    padding: 10px 10px;
    margin-top: 10px;
    border-radius: 8px;

    img {
      height: 50px;
    }

    .fileInfo {
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      div {
        display: flex;
        flex-direction: column;
        margin: 0 5px;
      }

      strong {
        font-size: 14px;
        word-break: break-all;
      }

      span {
        font-size: 12px;
        color: #333;
      }

      button {
        margin-right: 10px;
        background: none;
        color: #f00;
      }
    }
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 2px;
  span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #f00;
  }
`;

export const ScreenLoading = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0px;
  z-index: 9898989;

  background: rgba(255, 255, 255, 0.6);
`;
