import styled from "styled-components";

export const BodyTab = styled.main`
  @keyframes animeTransctionTabsIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  @keyframes animeTransctionTabsOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }
`;

export const ContentGroup = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-flow: column;
  }
`;
