import styled from "styled-components";

export const RowNone = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 7px 0;
  color: #333;
  font-size: 0.875rem;
`;

export const BoxSelectSeller = styled.div`
  background-color: #f0f0f0;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 1rem;

  span {
    font-size: 0.875rem;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: transparent;

    border-radius: 100%;
    padding: 0.5rem;

    height: 2.5rem;
    width: 2.5rem;

    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;
