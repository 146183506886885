import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 0;
  z-index: 30;

  .close {
    animation: fadeoutprivacy 0.4s;

    @keyframes fadeinprivacy {
      from {
        transform: scale(1);
        opacity: 1;
      }

      to {
        transform: scale(0);
        opacity: 0.95;
      }
    }

    opacity: 0;
    display: none;
  }

  .on {
    animation: fadeinprivacy 0.6s;
    opacity: 1;

    @keyframes fadeinprivacy {
      from {
        transform: scale(0);
        opacity: 0;
      }

      to {
        transform: scale(1);
        opacity: 0.95;
      }
    }
  }

  .content {
    width: 90%;
    max-width: 400px;
    border-radius: 6px;
    padding: 1.8rem;
    margin-left: auto;
    margin-right: 1rem;
    background-color: #1e1e1e;
    opacity: 0.95;
    color: #fff;

    @media (max-width: 900px) {
      margin: 0 auto;
    }

    p {
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 0.6rem;
    }

    span {
      display: block;
      font-weight: 300;
      margin-bottom: 1.3rem;
    }

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        background-color: #fff;
        padding: 0.875rem;
        border-radius: 4px;
        flex: 1;
        gap: 4px;
        font-weight: 600;
        font-size: 1rem;

        transition: all 0.2s ease;

        &:hover {
          transform: scale(0.95);
        }
      }

      a {
        flex: 1;
        display: block;
        padding: 0.875rem;
        text-align: center;
        gap: 4px;
        background-color: transparent;
        color: #fff;
        font-weight: 400;
        text-decoration: underline !important;
      }
    }
  }
`;
