import {
  EditingState,
  Resource,
  ViewState,
} from "@devexpress/dx-react-scheduler";
import {
  AllDayPanel,
  AppointmentForm,
  AppointmentTooltip,
  Appointments,
  DateNavigator,
  DayView,
  MonthView,
  Resources,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from "@devexpress/dx-react-scheduler-material-ui";
import Paper from "@material-ui/core/Paper";
import filesize from "filesize";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { BiLinkExternal } from "react-icons/bi";
import { FiAlertCircle } from "react-icons/fi";
import { IoMdTrash } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { IPermissionUser } from "../../@types";
import icon_xlsx from "../../assets/icon_excel.png";
import icon_pdf from "../../assets/icon_pdf.png";
import icon_xml from "../../assets/icon_xml.png";
import Modal from "../../components/Modal";
import Dropzone from "../../components/Upload/DropzoneArr";
import DropzoneXlsx from "../../components/Upload/DropzoneXlsx";
import Loading from "../../components/loadings/Loading";
import ModalLoading from "../../components/loadings/ScreenLoading";
import api from "../../service/api";
import generateHash from "../../utils/generateHash";
import {
  ButtunSubmit,
  Container,
  ContainerFloatButton,
  ContainerPreviews,
  Error,
  ExternalLinkA,
  FormAppointment,
  ScreenLoading,
} from "./styles";

interface IBrand {
  id: string;
  cod?: number;
  name?: string;
  is_licensed: boolean;
}

interface IAppointment {
  id: number;
  title: string;
  volume: number;
  footwear?: number;
  apparel?: number;
  equipment?: number;
  statusId: number;
  brandId: number;
  startDate: string;
  endDate: string;
}
interface IOnAppointment {
  id?: string | number | undefined;
  title?: string;
  volume?: number;
  footwear?: number;
  apparel?: number;
  equipment?: number;
  statusId?: number;
  brandId?: number;
  startDate: string;
  endDate: string;
}
interface IStatusRecive {
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
}
interface IStatus {
  id: number;
  color: string;
  text: string;
}
interface IFile {
  id: string;
  name: string;
  size: Number;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype?: string;
}

interface HeaderProps extends AppointmentTooltip.HeaderProps {
  admin?: boolean;
  client?: boolean;
  seller?: boolean;
  provider?: boolean;

  permission: IPermissionUser;
}

const allDayLocalizationMessages: any = {
  "pt-BR": {
    allDay: "Todo Dia",
  },
  "en-US": {
    allDay: "All Day",
  },
};
const getAllDayMessages = (locale: any) => allDayLocalizationMessages[locale];
const Header = ({ admin, provider, ...restProps }: HeaderProps) => {
  return (
    <AppointmentTooltip.Header style={{ height: "100%" }} {...restProps}>
      <div
        style={{
          width: 1,
          height: 28,
          backgroundColor: "rgba(0, 0, 0, 0.12)",
          marginTop: 10,
          marginRight: 8,
          marginLeft: 8,
        }}
      />
      <ExternalLinkA
        href={
          admin
            ? `${APP_LINK}/admin/agendamento/${restProps.appointmentData?.id}`
            : provider
            ? `${APP_LINK}/fornecedor/agendamento/${restProps.appointmentData?.id}`
            : `${APP_LINK}/representante/agendamento/${restProps.appointmentData?.id}`
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <BiLinkExternal size={22} />
      </ExternalLinkA>
    </AppointmentTooltip.Header>
  );
};

const APP_LINK =
  process.env.NODE_ENV !== "development"
    ? "http://portal.alpardobrasil.com.br"
    : "http://localhost:3000";

const Home: React.FC<HeaderProps> = ({ provider, admin, permission }) => {
  const { addToast } = useToasts();
  const [windowsHeigth, setWindowsHeigth] = useState(0);

  //Calendario
  const [currentDate, setCurrentDate] = useState(new Date());
  const [appointmentEditing, setAppointmentEditing] = useState<IOnAppointment>(
    {} as IOnAppointment
  );
  const [appointmentSelect, setAppointmentSelect] = useState<IOnAppointment>(
    {} as IOnAppointment
  );

  const [appointmentType, setAppointmentType] = useState<"edit" | "create">(
    "create"
  );

  const [fileXml, setFileXml] = useState<File[]>([]);
  const [selectFileXml, setSelectFileXml] = useState<File[]>();
  const [filePdf, setFilePdf] = useState<File[]>([]);
  const [selectFilePdf, setSelectFilePdf] = useState<File[]>();

  const [fileXlsx, setFileXlsx] = useState<File>();
  const [selectFileXlsx, setSelectFileXlsx] = useState<File>();

  const [visibleForm, setVisibleForm] = useState(false);
  const [data, setData] = useState<IAppointment[]>([]);

  const [error, setError] = useState({
    total: "",
  });

  const [errorFilePdf, setErrorFilePdf] = useState("");
  const [errorFileXml, setErrorFileXml] = useState("");
  const [errorFileXlsx, setErrorFileXlsx] = useState("");

  const [onUploadProgress, setOnUploadProgress] = useState(0);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [brandsDB, setBrandsDB] = useState<IBrand[]>([]);

  const [status, setStatus] = useState<IStatus[]>([]);
  const [updateCalendar, setUpdateCalendar] = useState(1);
  const [allowFeatures, setAllowFeatures] = useState({
    adding: false,
    updating: false,
    deleting: false,
  });

  const [loading, setLoading] = useState({
    submit: false,
    calendar: true,
  });

  const resourcesData: Resource[] = [
    {
      fieldName: "statusId",
      title: "Status",
      instances: status,
    },
  ];
  const timePicker = {
    hour: [
      { value: "08", name: "08" },
      { value: "09", name: "09" },
      { value: "10", name: "10" },
      { value: "11", name: "11" },
      { value: "12", name: "12" },
      { value: "14", name: "14" },
      { value: "15", name: "15" },
      { value: "16", name: "16" },
      { value: "17", name: "17" },
      // { value: "18", name: "18" },
    ],
    minute: [
      { value: "00", name: "00" },
      { value: "30", name: "30" },
    ],
  };

  const formikData = useFormik({
    validateOnChange: false,

    initialValues: {
      id: undefined,
      type: undefined,
      brandId: undefined,
      title: undefined,
      volume: undefined,
      footwear: undefined,
      apparel: undefined,
      equipment: undefined,
      date: undefined,
      dateHourStart: undefined,
      dateMinuteStart: undefined,
      dateHourEnd: undefined,
      dateMinuteEnd: undefined,
    },

    validationSchema: Yup.object({
      brandId: Yup.string().required("Marca é obrigatório"),
      volume: Yup.string().required("Volumes é obrigatório"),
      date: Yup.string()
        .required("Data é obrigatório")
        .test(
          "viana_test",
          "Período indisponível para agendamentos",
          (value) => {
            if (
              ["2024-06-03", "2024-06-04", "2024-06-05", "2024-06-06"].includes(
                String(value)
              )
            ) {
              return false;
            }
            return true;
          }
        ),
      dateHourStart: Yup.string().required("Horário de início é obrigatório"),
      dateMinuteStart: Yup.string().required("Minuto de início é obrigatório"),
      type: Yup.string().test(
        "is_licensed",
        "Tipo é obrigatório.",
        function (value) {
          let error = true;

          if (
            brandsDB.find(
              (brand) => Number(brand.id) === Number(formikData.values.brandId)
            )?.is_licensed &&
            value === undefined
          )
            error = false;

          return error;
        }
      ),
    }),

    onSubmit: async (values) =>
      !loading.submit ? await handleSubmit(values) : () => {},
  });

  const ComponentAppointmentForm = () => {
    return (
      <FormAppointment onSubmit={formikData.handleSubmit}>
        <div className="containerForm">
          {appointmentType === "edit" && (
            <div className="field-group">
              <div className="field">
                <label htmlFor="id">CÓDIGO</label>
                <input
                  disabled
                  style={{ borderStyle: "dashed" }}
                  value={formikData.values.id}
                />
              </div>
              <div className="field">
                <label htmlFor="numberInvoice">Status</label>

                <select
                  {...formikData.getFieldProps("status")}
                  disabled={!allowFeatures.updating}
                  // style={{ borderStyle: "dashed" }}
                >
                  {status.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          <div className="field">
            <label htmlFor="brandId">Marca</label>
            <select {...formikData.getFieldProps("brandId")}>
              <option value="">Selecionar...</option>
              {brandsDB.map((brand) => (
                <option key={brand.id} value={brand.id}>
                  {brand.name}
                </option>
              ))}
            </select>
            {formikData.touched.brandId && formikData.errors.brandId ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formikData.errors.brandId} </span>
              </Error>
            ) : null}
          </div>

          {brandsDB.find(
            (brand) => Number(brand.id) === Number(formikData.values.brandId)
          )?.is_licensed && (
            <div className="field">
              <label htmlFor="brandId">Tipo</label>
              <select {...formikData.getFieldProps("type")}>
                <option value="">Selecionar...</option>

                <option value="mostruário">Mostruário</option>
                <option value="produção">Produção</option>
              </select>
              {formikData.touched.type && formikData.errors.type ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikData.errors.type} </span>
                </Error>
              ) : null}
            </div>
          )}

          <div className="field-group">
            <div className="field">
              <label htmlFor="volume">Volumes</label>
              <input
                name="volume"
                onChange={hadleInputChange}
                value={formikData.values.volume}
                onBlur={formikData.handleBlur}
                readOnly={appointmentType === "edit"}
                // {...formikData.getFieldProps("volume")}
              />
              {formikData.touched.volume && formikData.errors.volume ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikData.errors.volume} </span>
                </Error>
              ) : null}
            </div>
          </div>

          <div className="field-group">
            <div className="field">
              <label htmlFor="footwear">Valor dos Calçados (FTW)</label>
              <input
                name="footwear"
                onChange={hadleInputChange}
                onBlur={testTotal}
                value={
                  formikData.values.footwear
                    ? Number(formikData.values.footwear).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )
                    : ""
                }
                readOnly={appointmentType === "edit"}
              />
            </div>

            <div className="field">
              <label htmlFor="apparel">Valor dos Vestuários (APP)</label>
              <input
                name="apparel"
                onChange={hadleInputChange}
                onBlur={testTotal}
                value={
                  formikData.values.apparel
                    ? Number(formikData.values.apparel).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )
                    : ""
                }
                readOnly={appointmentType === "edit"}
              />
            </div>

            <div className="field">
              <label htmlFor="equipment">Valor dos Equipamentos (EQ)</label>
              <input
                name="equipment"
                onChange={hadleInputChange}
                onBlur={testTotal}
                value={
                  formikData.values.equipment
                    ? Number(formikData.values.equipment).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )
                    : ""
                }
                readOnly={appointmentType === "edit"}
              />
            </div>
          </div>

          <div className="field">
            <label htmlFor="total">Valor total do agendamento</label>
            <input
              style={{ borderStyle: "dashed" }}
              readOnly
              value={Number(
                Number(formikData.values.footwear) +
                  Number(formikData.values.apparel) +
                  Number(formikData.values.equipment)
              ).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            />

            {error.total ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {error.total} </span>
              </Error>
            ) : null}
          </div>

          <div className="field">
            <label htmlFor="date">Data</label>
            <input
              type="date"
              {...formikData.getFieldProps("date")}
              readOnly={appointmentType === "edit"}
            />

            {formikData.touched.date && formikData.errors.date ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formikData.errors.date} </span>
              </Error>
            ) : null}
          </div>

          <div className="field-group-picker-time">
            <div className="field">
              <label htmlFor="numberInvoice">
                {appointmentType === "edit"
                  ? "Horário de Inicio"
                  : "Horário do agendamento"}
              </label>

              <div className="timePicker">
                <select
                  {...formikData.getFieldProps("dateHourStart")}
                  disabled={appointmentType === "edit"}
                  style={{ borderStyle: "solid" }}
                >
                  <option value=""></option>
                  {timePicker.hour.map((hour, key) => (
                    <option key={key} value={hour.value}>
                      {hour.name}
                    </option>
                  ))}
                </select>
                <span>:</span>
                <select
                  {...formikData.getFieldProps("dateMinuteStart")}
                  disabled={appointmentType === "edit"}
                  style={{ borderStyle: "solid" }}
                >
                  <option value=""></option>
                  {timePicker.minute.map((minute, key) => (
                    <option key={key} value={minute.value}>
                      {minute.name}
                    </option>
                  ))}
                </select>
              </div>

              {formikData.touched.dateHourStart &&
              formikData.errors.dateHourStart ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikData.errors.dateHourStart} </span>
                </Error>
              ) : null}

              {formikData.touched.dateMinuteStart &&
              formikData.errors.dateMinuteStart ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikData.errors.dateMinuteStart} </span>
                </Error>
              ) : null}
            </div>

            {appointmentType === "edit" && (
              <div className="field">
                <label htmlFor="numberInvoice">Horário de Fim</label>

                <div className="timePicker">
                  <select
                    {...formikData.getFieldProps("dateHourEnd")}
                    disabled={appointmentType === "edit"}
                    style={{ borderStyle: "solid" }}
                  >
                    <option value=""></option>
                    {timePicker.hour.map((hour, key) => (
                      <option key={key} value={hour.value}>
                        {hour.name}
                      </option>
                    ))}
                  </select>
                  <span>:</span>
                  <select
                    {...formikData.getFieldProps("dateMinuteEnd")}
                    disabled={appointmentType === "edit"}
                    style={{ borderStyle: "solid" }}
                  >
                    <option value=""></option>
                    {timePicker.minute.map((minute, key) => (
                      <option key={key} value={minute.value}>
                        {minute.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>

          {appointmentType === "create" ? (
            <>
              <div className="field">
                <div className="containerLabelButton">
                  <label htmlFor="pdf">Romaneio</label>
                  <button
                    type="button"
                    onClick={() => {
                      setFileXlsx(undefined);
                    }}
                  >
                    <IoMdTrash size={15} />
                    Apagar todos
                  </button>
                </div>

                <DropzoneXlsx onFileUploaded={(e) => setSelectFileXlsx(e)} />
                <ContainerPreviews
                  style={{
                    overflowY: "auto",
                    maxHeight: "25vh",
                  }}
                >
                  {fileXlsx && (
                    <li key={`${generateHash()}-${fileXlsx.name}`}>
                      <img src={icon_xlsx} alt="icon_xlsx" />
                      <div className="fileInfo">
                        <div>
                          <strong>{fileXlsx.name}</strong>
                          <span>{filesize(fileXlsx.size)}</span>
                        </div>

                        <button
                          type="button"
                          onClick={() => {
                            setFileXlsx(undefined);
                          }}
                        >
                          <IoMdTrash size={30} />
                        </button>
                      </div>
                    </li>
                  )}
                </ContainerPreviews>

                {errorFileXlsx ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {errorFileXlsx} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field-group">
                <div className="field">
                  <div className="containerLabelButton">
                    <label htmlFor="pdf">Arquivos PDF</label>
                    <button
                      type="button"
                      onClick={() => {
                        setFilePdf([]);
                      }}
                    >
                      <IoMdTrash size={15} />
                      Apagar todos
                    </button>
                  </div>

                  <Dropzone
                    accept={["application/pdf"]}
                    onFileUploaded={(e) => setSelectFilePdf(e)}
                  />
                  <ContainerPreviews
                    style={{
                      overflowY: "auto",
                      maxHeight: "25vh",
                    }}
                  >
                    {filePdf &&
                      filePdf.map((file, index) => (
                        <li key={`${generateHash()}-${file.name}`}>
                          <img src={icon_pdf} alt="icon_pdf" />
                          <div className="fileInfo">
                            <div>
                              <strong>{file.name}</strong>
                              <span>{filesize(file.size)}</span>
                            </div>

                            <button
                              type="button"
                              onClick={() => {
                                handleRemoveFile(index, setFilePdf, filePdf);
                              }}
                            >
                              <IoMdTrash size={30} />
                            </button>
                          </div>
                        </li>
                      ))}
                  </ContainerPreviews>

                  {errorFilePdf ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {errorFilePdf} </span>
                    </Error>
                  ) : null}
                </div>

                <div className="field">
                  <div className="containerLabelButton">
                    <label htmlFor="xml">Arquivos XML</label>
                    <button
                      type="button"
                      onClick={() => {
                        setFileXml([]);
                      }}
                    >
                      <IoMdTrash size={15} />
                      Apagar todos
                    </button>
                  </div>

                  <Dropzone
                    accept={["application/xml", "text/xml"]}
                    onFileUploaded={(e) => setSelectFileXml(e)}
                  />
                  <ContainerPreviews
                    style={{
                      overflowY: "auto",
                      maxHeight: "25vh",
                    }}
                  >
                    {fileXml &&
                      fileXml.map((file, index) => (
                        <li key={`${generateHash()}-${file.name}`}>
                          <img src={icon_xml} alt="icon_xml" />
                          <div className="fileInfo">
                            <div>
                              <strong>{file.name}</strong>
                              <span>{filesize(file.size)}</span>
                            </div>

                            <button
                              type="button"
                              onClick={() => {
                                handleRemoveFile(index, setFileXml, fileXml);
                              }}
                            >
                              <IoMdTrash size={30} />
                            </button>
                          </div>
                        </li>
                      ))}
                  </ContainerPreviews>

                  {errorFileXml ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {errorFileXml} </span>
                    </Error>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="containerFormButton">
          {appointmentType === "edit"
            ? allowFeatures.updating && (
                <div className="field">
                  <ButtunSubmit type="submit">
                    {!loading.submit ? (
                      "EDITAR"
                    ) : (
                      <Loading
                        size={22}
                        borderSize={4}
                        colorLoading="rgba(255,255,255)"
                        borderColor="rgba(255,255,255, 0.3)"
                      />
                    )}
                  </ButtunSubmit>
                </div>
              )
            : allowFeatures.adding && (
                <div className="field">
                  <ButtunSubmit
                    onClick={() => {
                      testFile();
                      testTotal();
                    }}
                    type="submit"
                  >
                    {!loading.submit ? (
                      "CRIAR SOLICITAÇÃO"
                    ) : (
                      <>
                        <Loading
                          size={22}
                          borderSize={4}
                          colorLoading="rgba(255,255,255)"
                          borderColor="rgba(255,255,255, 0.3)"
                        />
                      </>
                    )}
                  </ButtunSubmit>
                </div>
              )}
        </div>
      </FormAppointment>
    );
  };

  useEffect(() => {
    (async () => {
      const brandDB = await api.get("/populate/brand", {
        params: { active: 1, isBrandsProvider: provider ? true : false },
      });
      setBrandsDB(brandDB.data);
    })();
  }, [provider]);

  useEffect(() => {
    if (provider) {
      setAllowFeatures({
        adding: true,
        deleting: false,
        updating: false,
      });
    }

    if (admin) {
      if (permission.scheduling > 2) {
        setAllowFeatures({
          adding: false,
          updating: true,
          deleting: true,
        });
      } else {
        setAllowFeatures({
          adding: false,
          updating: false,
          deleting: true,
        });
      }
    }
  }, [provider, admin, permission]);

  useEffect(() => {
    if (selectFileXlsx) {
      setErrorFileXlsx("");
      setFileXlsx(selectFileXlsx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFileXlsx]);

  useEffect(() => {
    if (selectFileXml) {
      var arrFiles = selectFileXml;

      for (var index = 0; index < arrFiles.length; index++) {
        for (const fileExist of fileXml) {
          if (arrFiles[index] && arrFiles[index].name === fileExist.name) {
            arrFiles.splice(index, 1);
          }
        }
      }

      setErrorFileXml("");
      setFileXml([...fileXml, ...arrFiles]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFileXml]);

  useEffect(() => {
    if (selectFilePdf) {
      var arrFiles = selectFilePdf;

      for (var index = 0; index < arrFiles.length; index++) {
        for (const fileExist of filePdf) {
          if (arrFiles[index] && arrFiles[index].name === fileExist.name) {
            arrFiles.splice(index, 1);
          }
        }
      }

      setErrorFilePdf("");
      setFilePdf([...filePdf, ...arrFiles]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFilePdf]);

  useEffect(() => {
    (async () => {
      clearFormik();
      clearFiles();

      if (appointmentType === "edit") {
        fillFormikEdit();
      } else {
        fillFormikCreate();
      }
    })();

    // eslint-disable-next-line
  }, [appointmentEditing, appointmentSelect, appointmentType]);

  useEffect(() => {
    (async () => {
      const statusDB = await api.get<IStatusRecive[]>(
        "/populate/scheduling/status"
      );
      let status: IStatus[] = statusDB.data.map((item) => ({
        id: item.cod,
        color: item.color,
        text: item.name,
      }));
      setStatus(status);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading({ ...loading, calendar: true });
      setData([]);

      const calendarDB = await api.get("/scheduling/calendar");
      setData(calendarDB.data.calendar);

      setLoading({ ...loading, calendar: false });
    })();
    // eslint-disable-next-line
  }, [updateCalendar]);

  // eslint-disable-next-line
  useEffect(() => {
    setWindowsHeigth(window.innerHeight);
  });

  useEffect(() => {
    if (onUploadProgress >= 100) {
      clearFormik();
      clearFiles();

      setLoading({ ...loading, submit: false });
      setLoadingUpload(false);
      setOnUploadProgress(0);

      addToast("Agendamento solicitado com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
      setUpdateCalendar(updateCalendar + 1);
      setVisibleForm(false);
    }
    // eslint-disable-next-line
  }, [onUploadProgress]);

  useEffect(() => {
    if (loadingUpload) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [loadingUpload]);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "As alterações feitas podem não ser salvas.";
  };

  window.addEventListener("resize", function () {
    setWindowsHeigth(window.innerHeight);
  });

  function testTotal() {
    if (
      Number(
        Number(formikData.values.footwear) +
          Number(formikData.values.apparel) +
          Number(formikData.values.equipment)
      ) > 0
    ) {
      setError({ ...error, total: "" });
    } else {
      setError({ ...error, total: "Valor total deve ser maior que R$ 0,00" });
    }
  }

  function testFile() {
    var errorTemp = { filePdf: "", fileXml: "", fileXlsx: "" };

    if (filePdf[0]) {
      errorTemp = { ...errorTemp, filePdf: "" };
    } else {
      errorTemp = { ...errorTemp, filePdf: "Arquivo PDF é obrigatório" };
    }

    if (fileXml[0]) {
      errorTemp = { ...errorTemp, fileXml: "" };
    } else {
      errorTemp = { ...errorTemp, fileXml: "Arquivo XML é obrigatório" };
    }

    if (fileXlsx) {
      errorTemp = { ...errorTemp, fileXlsx: "" };
    } else {
      errorTemp = {
        ...errorTemp,
        fileXlsx: "Arquivo de Romaneio é obrigatório",
      };
    }

    setErrorFilePdf(errorTemp.filePdf);
    setErrorFileXml(errorTemp.fileXml);
    setErrorFileXlsx(errorTemp.fileXlsx);
  }

  async function handleSubmit(data: any) {
    setLoading({ ...loading, submit: true });

    if (appointmentType === "edit") {
      try {
        let start_date = new Date(
          Number(moment(data.date).format("YYYY")),
          Number(moment(data.date).format("MM")) - 1,
          Number(moment(data.date).format("DD")),
          Number(data.dateHourStart),
          Number(data.dateMinuteStart)
        );

        let end_date = new Date(
          Number(moment(data.date).format("YYYY")),
          Number(moment(data.date).format("MM")) - 1,
          Number(moment(data.date).format("DD")),
          Number(data.dateHourEnd),
          Number(data.dateMinuteEnd)
        );

        let updateData = {
          brandId: Number(data.brandId),
          start_date,
          end_date,
          volume: Number(data.volume),
          apparel: Number(data.apparel),
          equipment: Number(data.equipment),
          footwear: Number(data.footwear),
          status: data.status,
          type: data.type,
        };

        await api.put(`/scheduling/${appointmentEditing.id}`, updateData);
        setLoading({ ...loading, submit: false });

        addToast("Agendamento editado com sucesso.", {
          appearance: "success",
          autoDismiss: true,
        });

        formikData.setFieldValue("dateMinuteEnd", "");
        setUpdateCalendar(updateCalendar + 1);
        setVisibleForm(false);
      } catch (error) {
        addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );

        setLoading({ ...loading, submit: false });
        console.log(error);
      }
    } else {
      setLoading({ ...loading, submit: true });
      testFile();
      testTotal();

      if (
        !error.total &&
        !errorFilePdf &&
        !errorFileXml &&
        !errorFileXlsx &&
        error.total === "" &&
        errorFileXml === "" &&
        errorFilePdf === "" &&
        errorFileXlsx === ""
      ) {
        const formData = new FormData();
        if (fileXml) {
          for (var i = 0; i < fileXml.length; i++) {
            formData.append("file", fileXml[i]);
          }
        }
        if (filePdf) {
          for (var index = 0; index < filePdf.length; index++) {
            formData.append("file", filePdf[index]);
          }
        }
        if (fileXlsx) {
          formData.append("file", fileXlsx);
        }

        let start_date = new Date(
          Number(moment(data.date).format("YYYY")),
          Number(moment(data.date).format("MM")) - 1,
          Number(moment(data.date).format("DD")),
          Number(data.dateHourStart),
          Number(data.dateMinuteStart)
        );

        let end_date = new Date(
          Number(moment(data.date).format("YYYY")),
          Number(moment(data.date).format("MM")) - 1,
          Number(moment(data.date).format("DD")),
          Number(data.dateHourStart) + 1,
          Number(data.dateMinuteStart)
        );

        let createData = {
          brandId: Number(data.brandId),
          name: data.title,
          start_date,
          end_date,
          volume: Number(data.volume),
          apparel: Number(data.apparel),
          equipment: Number(data.equipment),
          footwear: Number(data.footwear),
          status: 1,
          type: data.type,
        };

        const scheduling = await api.post("/scheduling/", createData);
        setVisibleForm(false);

        setLoadingUpload(true);
        api.post(`/scheduling/upload/${scheduling.data.id}`, formData, {
          onUploadProgress: (e) => {
            const loaded = +e.loaded;
            const total = +e.total;

            //@ts-ignore
            var progress = parseInt(Math.round((loaded * 100) / total));

            setOnUploadProgress(progress);
          },
        });
      } else {
        setLoading({ ...loading, submit: false });
      }
    }
  }

  function hadleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data: any;
    data = value;

    if (name === "footwear" || name === "apparel" || name === "equipment") {
      data = Number(value.replace(/\D/g, "")) / 100;
    }

    if (name === "volume") {
      value ? (data = Number(value.replace(/\D/g, ""))) : (data = value);
    }

    formikData.setFieldValue(name, data);
  }

  function clearFormik() {
    formikData.setFieldValue("id", "");
    formikData.setFieldValue("title", "");
    formikData.setFieldValue("date", "");
    formikData.setFieldValue("volume", "");
    formikData.setFieldValue("footwear", "");
    formikData.setFieldValue("apparel", "");
    formikData.setFieldValue("equipment", "");
    formikData.setFieldValue("status", "");
    formikData.setFieldValue("dateHourStart", "");
    formikData.setFieldValue("dateMinuteStart", "");
    formikData.setFieldValue("dateHourEnd", "");
    formikData.setFieldValue("dateMinuteEnd", "");
    formikData.setFieldValue("brandId", "");
  }

  function clearFiles() {
    setFilePdf([]);
    setFileXlsx(undefined);
    setFileXml([]);
  }

  function fillFormikEdit() {
    if (appointmentEditing.id)
      formikData.setFieldValue("id", appointmentEditing.id);
    if (appointmentEditing.title)
      formikData.setFieldValue("title", appointmentEditing.title);
    if (appointmentEditing.volume)
      formikData.setFieldValue("volume", appointmentEditing.volume);
    if (appointmentEditing.footwear)
      formikData.setFieldValue("footwear", appointmentEditing.footwear);
    if (appointmentEditing.apparel)
      formikData.setFieldValue("apparel", appointmentEditing.apparel);
    if (appointmentEditing.equipment)
      formikData.setFieldValue("equipment", appointmentEditing.equipment);

    if (appointmentEditing.brandId)
      formikData.setFieldValue("brandId", appointmentEditing.brandId);
    if (appointmentEditing.statusId)
      formikData.setFieldValue("status", appointmentEditing.statusId);
    if (appointmentEditing.startDate)
      formikData.setFieldValue(
        "date",
        moment(appointmentEditing.startDate).format("YYYY-MM-DD")
      );

    if (appointmentEditing.startDate) {
      formikData.setFieldValue(
        "dateHourStart",
        moment(appointmentEditing.startDate).format("hh")
      );
      formikData.setFieldValue(
        "dateMinuteStart",
        moment(appointmentEditing.startDate).format("mm")
      );
    }

    if (appointmentEditing.startDate) {
      formikData.setFieldValue(
        "dateMinuteStart",
        moment(appointmentEditing.startDate).format("mm")
      );

      if (
        moment(appointmentEditing.startDate).format("A") === "PM" &&
        Number(moment(appointmentEditing.endDate).format("hh")) !== 12
      ) {
        formikData.setFieldValue(
          "dateHourStart",
          String(Number(moment(appointmentEditing.startDate).format("hh")) + 12)
        );
      } else {
        formikData.setFieldValue(
          "dateHourStart",
          moment(appointmentEditing.startDate).format("hh")
        );
      }
    }

    if (appointmentEditing.endDate) {
      formikData.setFieldValue(
        "dateMinuteEnd",
        moment(appointmentEditing.endDate).format("mm")
      );

      if (
        moment(appointmentEditing.endDate).format("A") === "PM" &&
        Number(moment(appointmentEditing.endDate).format("hh")) !== 12
      ) {
        formikData.setFieldValue(
          "dateHourEnd",
          String(Number(moment(appointmentEditing.endDate).format("hh")) + 12)
        );
      } else {
        formikData.setFieldValue(
          "dateHourEnd",
          moment(appointmentEditing.endDate).format("hh")
        );
      }
    }
  }

  async function handleRemoveFile(
    indece: number,
    setFile: (value: React.SetStateAction<File[]>) => void,
    file: File[]
  ) {
    let arr = file;
    arr.splice(indece, 1);

    setFile([]);

    setTimeout(() => {
      setFile(arr);
    }, 0.1);

    testFile();
  }

  function fillFormikCreate() {
    if (appointmentSelect.startDate) {
      formikData.setFieldValue(
        "date",
        moment(appointmentSelect.startDate).format("YYYY-MM-DD")
      );
    } else {
      formikData.setFieldValue("date", undefined);
    }
  }

  return (
    <>
      <Container>
        <Paper>
          <Scheduler height={windowsHeigth - 50} data={data} locale={"pt-BR"}>
            <ViewState
              defaultCurrentDate={currentDate}
              onCurrentDateChange={(e) => setCurrentDate(new Date(e))}
              defaultCurrentViewName="Month"
            />
            <MonthView displayName="Mês" />
            <WeekView startDayHour={8} endDayHour={18} displayName="Semana" />
            <DayView startDayHour={8} endDayHour={18} displayName="Dia" />

            <EditingState
              onCommitChanges={() => {}}
              onAddedAppointmentChange={(e: any) => {
                setAppointmentType("create");

                setAppointmentSelect({
                  startDate: String(e.startDate),
                  endDate: String(e.endDate),
                });

                fillFormikCreate();
              }}
              onEditingAppointmentChange={(e) => {
                setAppointmentType("edit");
                setAppointmentEditing({
                  id: e.id,
                  title: e.title,
                  volume: e.volume,
                  footwear: e.footwear,
                  apparel: e.apparel,
                  equipment: e.equipment,
                  statusId: e.statusId,
                  brandId: e.brandId,
                  startDate: String(e.startDate),
                  endDate: String(e.endDate),
                });

                fillFormikEdit();
              }}
            />

            <Appointments />
            <Toolbar />
            <ViewSwitcher />
            <DateNavigator />
            <TodayButton messages={{ today: "HOJE" }} />
            <AllDayPanel messages={getAllDayMessages("pt-BR")} />
            <AppointmentTooltip
              showCloseButton
              showOpenButton
              headerComponent={(e) =>
                Header({
                  ...e,
                  provider: provider,
                  admin: admin,
                  permission: permission,
                })
              }
            />

            <AppointmentForm
              visible={false}
              onVisibilityChange={(e) => setVisibleForm(e)}
              onAppointmentDataChange={(e) =>
                e.id ? setAppointmentType("edit") : setAppointmentType("create")
              }
            />

            <Resources data={resourcesData} />
          </Scheduler>
        </Paper>

        {visibleForm && appointmentType === "create" && allowFeatures.adding && (
          <Modal
            maxHeight={750}
            title="Agendamento"
            modalVisible={visibleForm}
            setModalVisible={setVisibleForm}
            scrollOff
          >
            {ComponentAppointmentForm()}
          </Modal>
        )}

        {visibleForm && appointmentType === "edit" && (
          <Modal
            maxHeight={610}
            title="Agendamento"
            modalVisible={visibleForm}
            setModalVisible={setVisibleForm}
            scrollOff
          >
            {ComponentAppointmentForm()}
          </Modal>
        )}

        {allowFeatures.adding && (
          <ContainerFloatButton>
            <div className="fixedButton">
              <button
                onClick={() => {
                  setAppointmentType("create");
                  setAppointmentSelect({
                    startDate: String(new Date()),
                    endDate: String(new Date()),
                  });

                  setVisibleForm(true);
                }}
              >
                <MdAdd color="#FFF" size={26} />
              </button>
            </div>
          </ContainerFloatButton>
        )}

        {loading.calendar && (
          <ScreenLoading>
            <Loading
              colorLoading="#333"
              size={40}
              borderColor={"#888"}
              borderSize={5}
            />
          </ScreenLoading>
        )}
      </Container>

      {loadingUpload ? <ModalLoading progress={onUploadProgress} /> : null}
    </>
  );
};

export default Home;
