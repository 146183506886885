import { IPermissionUser } from "../../@types";

export interface PermissionUserProps extends IPermissionUser {}

export const STORAGE = "@Permission-user";

export const getUser = () => {
  const user = localStorage.getItem(STORAGE);

  return JSON.parse(user as string);
};

export const setUser = (user: IPermissionUser) => {
  localStorage.setItem(STORAGE, JSON.stringify(user));
};

export const removeUser = () => {
  localStorage.removeItem(STORAGE);
};
