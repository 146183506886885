import React from "react";

import { OrderFilters, selectIcon } from "../../hook/queries/useOrders";
import {
  ContainerTableStatus,
  TableStatus as TableStatusContainer,
} from "../../pages/panel/styles";

interface TableStatusProps {
  setFilters: React.Dispatch<React.SetStateAction<OrderFilters>>;
  isLoading?: boolean;

  data: {
    name: string;
    value: string;
  }[];
}

export const TableStatus = ({
  setFilters,
  data,
  isLoading = false,
}: TableStatusProps) => {
  return (
    <ContainerTableStatus>
      <header>
        <span>Status do Pedido</span>
      </header>
      <TableStatusContainer>
        <tbody>
          {data.map((status) => (
            <tr
              key={status.name}
              onClick={() => {
                if (!isLoading) {
                  setFilters((old) => {
                    const setFilterStatus =
                      old.status === String(status.value)
                        ? ""
                        : String(status.value);

                    return {
                      ...old,
                      status: setFilterStatus,
                    };
                  });
                }
              }}
            >
              <td>{selectIcon(String(status.value))}</td>
              <td>{status.name}</td>
            </tr>
          ))}

          {data.length <= 0 && (
            <tr>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
        </tbody>
      </TableStatusContainer>
    </ContainerTableStatus>
  );
};
