import filesize from "filesize";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

import { FaTrashAlt } from "react-icons/fa";
import { MdOutlineHideImage } from "react-icons/md";

import { AlertInfo } from "../../../components/AlertInfo";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { ImageClient } from "../../../components/ImageClient";
import Loading from "../../../components/loadings/LoadingDot";
import ModalLoading from "../../../components/loadings/ScreenLoading";

import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";

import dayjs from "dayjs";
import { useMutation } from "react-query";
import { IFile } from "../RequestUpdateClient";
import { DropzoneClientFile, FileProps } from "./DropzoneClientFile";

interface Props {
  clientCod: string;
  isOnlyRead?: boolean;
  isOnlySend?: boolean;
}

export function ClientFiles({
  clientCod,
  isOnlyRead = false,
  isOnlySend = false,
}: Props) {
  const { addToast } = useToasts();
  // const { data, isLoading, refetch } = useClientFiles(clientCod);

  const [files, setFiles] = useState<FileProps[]>([]);
  const [onErrorSelect, setOnErrorSelect] = useState(false);

  const [alertTrashFile, setAlertTrashFile] = useState<string | undefined>();

  const [onUploadProgress, setOnUploadProgress] = useState(0);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [showFiles, setShowFiles] = useState<IFile[]>([]);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(true);

  const deleteBanner = useMutation(
    async () => {
      await api.patch(`/clientPJ/trashFile/${alertTrashFile}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Client-Files", clientCod]);
      },
    }
  );

  async function handleUploadFile() {
    try {
      if (
        files.map((f) => f.typeFile).filter((f) => f).length !== files.length
      ) {
        addToast("É necessário informar tipo todos arquivos", {
          appearance: "warning",
          autoDismiss: true,
        });
        return setOnErrorSelect(true);
      }

      if (
        files.length < 3 ||
        files.filter((f) => f.typeFile === "FACHADA").length <= 0 ||
        files.filter((f) => f.typeFile === "INTERIOR").length <= 0
      ) {
        return addToast(
          "Necessário no mínimo 3 imagens, e ao menos uma em cada categoria ( FACHADA E INTERIOR )",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }

      const formData = new FormData();
      files.forEach((element) => {
        formData.append("file", element.file);
        formData.append("typeFile", String(element.typeFile));
      });
      api.post(`/clientPJ/upload/${clientCod}`, formData, {
        onUploadProgress: (e) => {
          const loaded = +e.loaded;
          const total = +e.total;
          //@ts-ignore
          var progress = parseInt(Math.round((loaded * 100) / total));

          setOnUploadProgress(progress);
        },
      });

      setLoadingUpload(true);
      setFiles([]);
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  useEffect(() => {
    if (loadingUpload) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [loadingUpload]);

  useEffect(() => {
    (async () => {
      if (onUploadProgress >= 100) {
        setLoadingUpload(false);
        setOnUploadProgress(0);

        queryClient.invalidateQueries(["Client-Files", clientCod]);

        addToast("Enviado para avaliação interna com sucesso.", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    })();

    // eslint-disable-next-line
  }, [onUploadProgress]);

  useEffect(() => {
    (async () => {
      const response = await api.get<IFile[]>(
        `/request-update-client/show-file/client-cod/${clientCod}`
      );

      setShowFiles(response.data);
      setIsLoadingFile(false);
    })();
  }, [clientCod]);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "As alterações feitas podem não ser salvas.";
  };

  return (
    <>
      <AlertInfo
        icon={FaTrashAlt}
        title="Você tem certeza que deseja excluir este arquivo ?"
        buttonText="Deletar"
        isViewModal={!!alertTrashFile}
        setIsViewModal={(e) => setAlertTrashFile(e ? alertTrashFile : "")}
        handle={async () => {
          await deleteBanner.mutateAsync();
          addToast("Arquivo excluído.", {
            appearance: "success",
            autoDismiss: true,
          });
          setAlertTrashFile(undefined);
        }}
      />
      {loadingUpload ? <ModalLoading progress={onUploadProgress} /> : null}

      <LayoutForm style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        {!isLoadingFile && (showFiles?.length ?? 0) <= 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "auto",
            }}
          >
            <MdOutlineHideImage fontSize="5rem" color="#777" />
            <span style={{ color: "#444", fontWeight: "bold" }}>
              Sem imagens a serem listadas
            </span>
          </div>
        )}

        {isLoadingFile && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "auto",
            }}
          >
            <Loading />
          </div>
        )}

        <div>
          {showFiles[0] && (
            <h2 style={{ fontSize: "1.1rem" }}>
              {`Data alteração ${dayjs(
                showFiles[0].clientImageUpdateRequestDate
              ).format("DD/MM/YYYY")}`}
            </h2>
          )}
        </div>

        <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
          {showFiles?.map((item) => (
            <ImageClient
              key={item.id}
              onTrash={
                isOnlySend
                  ? undefined
                  : isOnlyRead
                  ? undefined
                  : () => {
                      setAlertTrashFile(item.id);
                    }
              }
              data={{
                uri: item.preview_url ?? "",
                name: item.name,
                size: filesize(item.size) ?? "",
                type: item.storeTypeImage,
              }}
            />
          ))}
        </div>
      </LayoutForm>

      {!isOnlyRead && (
        <DropzoneClientFile
          files={files}
          setFiles={setFiles}
          onErrorSelect={onErrorSelect}
          handleSubmit={handleUploadFile}
        />
      )}
    </>
  );
}
