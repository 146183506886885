import React, { useState, useEffect, useRef } from "react";

import api from "../../../service/api";
import * as clientLocalStorage from "../../../service/localStorage/client";

import { useHistory } from "react-router-dom";
import useOutsideAlerter from "../../../hook/outsideAlerter";

import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import LoadingDot from "../../../components/loadings/LoadingDot";
import { dataZero } from "../../../utils/dataProcessing";

import {
  Container,
  Filter,
  FilterCheck,
  ContainerList,
  TableContainer,
  FooterContainer,
  Form,
  FilterSelects,
  Error,
  LoadingPanel,
} from "../styles";
import { FiSearch } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { FiAlertCircle } from "react-icons/fi";

interface IClient {
  id?: string;
  status?: string;

  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
}

interface IDevolution {
  id?: string;
  protocol: number;
  status: string;

  requesterName: string;
  phone: string;
  email: string;

  //Dados da solicitação
  numberInvoice: string;
  situationRequest: string;
  products: string;

  requestType: string;

  //Dados da Devolução & indenização
  reason: string;

  //devolução
  dateInvoice: string;

  //indenização
  brand: string;
  stateProduct: string;
  dateEmissionNFconsumer: string;
  invoiceOrcoupon: string;

  //Dados bancários
  bank: string;
  agency: string;
  account: string;

  clients_pj: IClient;

  //Dados internos
  //Dados NFD
  nfdNumber?: string;
  nfdValue?: string;
  nfdDate?: Date;

  //Representante / Gestor
  salesman?: string;
  brandManager?: string;

  //Trasporte
  freightBrand?: string;
  freightValue?: string;

  //Notificação
  notificationUser: boolean;

  created_at: Date;
}

interface IClient {
  id?: string;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
  created_at?: Date;
  acess_client?: {
    password?: string;
  };
  adresses?: {
    zip_code?: string;
    uf?: string;
    city?: string;
    street?: string;
    numberStreet?: string;
  };
}

interface IHeaderProps {
  admin: boolean;
}

interface IFilter {
  protocol?: string;
  requestType?: string;
  status?: string;
  notificationUser?: number;
  numberInvoice?: string;
  responsible?: string;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;

  finisherPeriod?: number;
  presetDateFinisher?: number;
  specificDateFinisher?: Date;
  startDateFinisher?: Date;
  endDateFinisher?: Date;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IIndemnityApi {
  indemnities: IDevolution[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

const PanelDevolutions: React.FC<IHeaderProps> = ({ admin }) => {
  const clietStorage = clientLocalStorage.getUser() as IClient;
  const history = useHistory();
  const outSideRef = useRef(null);

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({});
  const [devolutions, setDevolutions] = useState<IDevolution[]>([]);

  const [loading, setLoading] = useState({
    panel: true,
  });

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  useEffect(() => {
    (async () => {
      try {
        setLoading({ ...loading, panel: true });
        if (admin) {
          const responseAdmin = await api.get<IIndemnityApi>(
            "/devolutionLegacy",
            {
              params: { ...filters, ...requestPagination },
            }
          );

          const { indemnities, limit, page, pages, total } = responseAdmin.data;

          setDevolutions(indemnities);
          setPagination({ limit, page, pages, total });
          setLoading({ ...loading, panel: false });
        } else {
          const responseClient = await api.get<IIndemnityApi>(
            `/devolutionLegacy/client/${clietStorage.id}`,
            {
              params: { ...filters, ...requestPagination },
            }
          );
          const { indemnities, limit, page, pages, total } =
            responseClient.data;
          setDevolutions(indemnities);
          setPagination({ limit, page, pages, total });
          setLoading({ ...loading, panel: false });
        }
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, requestPagination]);

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setRequestPagination({
      ...requestPagination,
      pageRequest: 0,
    });
    setFilterView(false);
  }

  function viewPresetDate(view: number): string {
    let returnValue = "";
    if (Number(view) === 0) {
      returnValue = "Hoje";
    }
    if (Number(view) === 2) {
      returnValue = "Ontem";
    }
    if (Number(view) === 7) {
      console.log(view);
      returnValue = "Últimos 7 dias";
    }
    if (Number(view) === 15) {
      console.log(view);
      returnValue = "Últimos 15 dias";
    }
    if (Number(view) === 30) {
      console.log(view);
      returnValue = "Últimos 30 dias";
    }

    return returnValue;
  }

  function viewPeriodDate(view: number): string {
    let returnValue = "";
    if (Number(view) === 1) {
      returnValue = "Predefinida";
    }
    if (Number(view) === 2) {
      returnValue = "Específica";
    }
    if (Number(view) === 3) {
      console.log(view);
      returnValue = "Intevalo";
    }

    return returnValue;
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  return (
    <Container>
      <header>
        <h2>Lista de Devoluções Legado</h2>
      </header>

      <Filter>
        <button
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="button"
        >
          <FiSearch />
        </button>
        <input
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="text"
          name="filter"
          id="filter"
          readOnly={true}
        />
        <FilterSelects>
          <ul>
            {filters.protocol && (
              <li>
                <span>PROTOCOLO: {filters.protocol}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, protocol: "" })}
                />
              </li>
            )}
            {filters.status && (
              <li>
                <span>STATUS: {filters.status}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, status: "" })}
                />
              </li>
            )}

            {filters.numberInvoice && (
              <li>
                <span>NF: {filters.numberInvoice}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, numberInvoice: "" })}
                />
              </li>
            )}

            {filters.codClient && (
              <li>
                <span>COD CLIENTE: {filters.codClient}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, codClient: undefined })
                  }
                />
              </li>
            )}

            {filters.cnpj && (
              <li>
                <span>CNPJ: {filters.cnpj}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, cnpj: undefined })}
                />
              </li>
            )}

            {filters.company_name && (
              <li>
                <span>RAZÃO SOCIAL: {filters.company_name}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, company_name: undefined })
                  }
                />
              </li>
            )}
            {filters.responsible && (
              <li>
                <span>RESPONSÁVEL: {filters.responsible}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, responsible: "" })}
                />
              </li>
            )}

            {filters.creationPeriod && (
              <li>
                <span>
                  PERÍODO DE CRIAÇÃO: {viewPeriodDate(filters.creationPeriod)}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      creationPeriod: undefined,
                      presetDate: undefined,
                      specificDate: undefined,
                      startDate: undefined,
                      endDate: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.presetDate && (
              <li>
                <span>
                  DATA PREDEFINIDA: {viewPresetDate(Number(filters.presetDate))}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      presetDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.specificDate && (
              <li>
                <span>DATA ESPECÍFICA: {filters.specificDate}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      specificDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.startDate && (
              <li>
                <span>DATA INICIAL: {filters.startDate}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      startDate: undefined,
                      endDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.endDate && (
              <li>
                <span>DATA FINAL: {filters.endDate}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      endDate: undefined,
                      startDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.finisherPeriod && (
              <li>
                <span>
                  PERÍODO DE FINALIZAÇÃO:
                  {viewPeriodDate(filters.finisherPeriod)}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      finisherPeriod: undefined,
                      presetDate: undefined,
                      specificDate: undefined,
                      startDate: undefined,
                      endDate: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.presetDateFinisher && (
              <li>
                <span>
                  DATA PREDEFINIDA:
                  {viewPresetDate(Number(filters.presetDateFinisher))}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      presetDateFinisher: undefined,
                      finisherPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.specificDateFinisher && (
              <li>
                <span>DATA ESPECÍFICA: {filters.specificDateFinisher}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      specificDateFinisher: undefined,
                      finisherPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.startDateFinisher && (
              <li>
                <span>DATA INICIAL: {filters.startDateFinisher}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      startDateFinisher: undefined,
                      endDateFinisher: undefined,
                      finisherPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.endDateFinisher && (
              <li>
                <span>DATA FINAL: {filters.endDateFinisher}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      endDateFinisher: undefined,
                      startDateFinisher: undefined,
                      finisherPeriod: undefined,
                    })
                  }
                />
              </li>
            )}
          </ul>
        </FilterSelects>

        {filterView && (
          <FilterCheck ref={outSideRef}>
            <header>
              <button
                onClick={() => {
                  setFilterView(false);
                }}
              >
                Fechar
              </button>
            </header>
            <main>
              <Formik
                initialValues={{
                  protocol: filters.protocol,
                  status: filters.status,
                  numberInvoice: filters.numberInvoice,
                  responsible: filters.responsible,

                  requestType: filters.requestType,

                  codClient: filters.codClient,
                  cnpj: filters.cnpj,
                  company_name: filters.company_name,

                  creationPeriod: filters.creationPeriod,
                  presetDate: filters.presetDate,
                  specificDate: filters.specificDate,
                  startDate: filters.startDate,
                  endDate: filters.endDate,

                  finisherPeriod: filters.finisherPeriod,
                  presetDateFinisher: filters.presetDateFinisher,
                  specificDateFinisher: filters.specificDateFinisher,
                  startDateFinisher: filters.startDateFinisher,
                  endDateFinisher: filters.endDateFinisher,
                }}
                validationSchema={Yup.object({
                  presetDate: Yup.string().when("creationPeriod", {
                    is: "1",
                    then: Yup.string().required(
                      "Data predefinida é obrigatório"
                    ),
                  }),

                  specificDate: Yup.string().when("creationPeriod", {
                    is: "2",
                    then: Yup.string().required(
                      "Data específica é obrigatório"
                    ),
                  }),

                  startDate: Yup.string().when("creationPeriod", {
                    is: "3",
                    then: Yup.string().required("Data inicio é obrigatório"),
                  }),

                  endDate: Yup.string().when("creationPeriod", {
                    is: "3",
                    then: Yup.string().required("Data final é obrigatório"),
                  }),

                  //Finalização

                  presetDateFinisher: Yup.string().when("finisherPeriod", {
                    is: "1",
                    then: Yup.string().required(
                      "Data predefinida é obrigatório"
                    ),
                  }),

                  specificDateFinisher: Yup.string().when("finisherPeriod", {
                    is: "2",
                    then: Yup.string().required(
                      "Data específica é obrigatório"
                    ),
                  }),

                  startDateFinisher: Yup.string().when("finisherPeriod", {
                    is: "3",
                    then: Yup.string().required("Data inicio é obrigatório"),
                  }),

                  endDateFinisher: Yup.string().when("finisherPeriod", {
                    is: "3",
                    then: Yup.string().required("Data final é obrigatório"),
                  }),
                })}
                onSubmit={(data) => {
                  hadleFilter(data);
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="protocol">PROTOCOLO</label>
                        <input
                          id="protocol"
                          {...formik.getFieldProps("protocol")}
                        />
                      </div>
                      <div className="field">
                        <label htmlFor="numberInvoice">NF</label>
                        <input
                          id="numberInvoice"
                          {...formik.getFieldProps("numberInvoice")}
                        />
                      </div>
                      {/* <div className="field">
                        <label htmlFor="numberInvoice">TIPO SOLICITAÇÃO</label>
                        <select {...formik.getFieldProps("requestType")}>
                          <option value="">Selecionar Status</option>

                          <option value={"Devolução (divergência comercial)"}>
                            Devolução
                          </option>
                          <option
                            value={
                              "Indenização (somente produtos com problemas de qualidade)"
                            }
                          >
                            Indenização
                          </option>
                        </select>
                      </div> */}
                    </div>

                    {admin && (
                      <div className="field-group">
                        <div className="field">
                          <label htmlFor="codClient">CODIGO CLIENTE</label>
                          <input
                            id="codClient"
                            {...formik.getFieldProps("codClient")}
                          />
                        </div>
                        <div className="field">
                          <label htmlFor="cnpj">CNPJ</label>
                          <input id="cnpj" {...formik.getFieldProps("cnpj")} />
                        </div>
                        <div className="field">
                          <label htmlFor="company_name">RAZÃO SOCIAL</label>
                          <input
                            id="company_name"
                            {...formik.getFieldProps("company_name")}
                          />
                        </div>
                      </div>
                    )}

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="status">STATUS</label>
                        <select {...formik.getFieldProps("status")}>
                          <option value="">Selecionar Status</option>

                          <option value={"Cancelado pelo cliente"}>
                            Cancelado pelo cliente
                          </option>
                          <option value={"Em andamento"}>Em andamento</option>
                          <option value={"Finalizado"}>Finalizado</option>
                          <option value={"Recebido"}>Recebido</option>
                          <option value={"Recusado conforme politica - Alpar"}>
                            Recusado conforme politica - Alpar
                          </option>
                          <option value={"Recusado ou Negado - Alpar"}>
                            Recusado ou Negado - Alpar
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="typeDate">PERÍODO DE CRIAÇÃO</label>
                        <select {...formik.getFieldProps("creationPeriod")}>
                          <option value=""></option>
                          <option value={2}>Específica</option>
                          <option value={3}>Intervalo</option>
                        </select>
                      </div>
                    </div>
                    {Number(formik.values.creationPeriod) === 1 ? (
                      <div className="field">
                        <label htmlFor="typeDate">DATA PREDEFINIDA</label>
                        <select {...formik.getFieldProps("presetDate")}>
                          <option value=""></option>
                          <option value={0}>Hoje</option>
                          <option value={1}>Ontem</option>
                          <option value={7}>Últimos 7 dias</option>
                          <option value={15}>Últimos 15 dias</option>
                          <option value={30}>Últimos 30 dias</option>
                        </select>
                        {formik.touched.presetDate &&
                        formik.errors.presetDate ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.presetDate} </span>
                          </Error>
                        ) : null}
                      </div>
                    ) : null}

                    {Number(formik.values.creationPeriod) === 2 ? (
                      <div className="field">
                        <label htmlFor="typeDate">DATA ESPECÍFICA</label>
                        <input
                          type="date"
                          {...formik.getFieldProps("specificDate")}
                        />
                        {formik.touched.specificDate &&
                        formik.errors.specificDate ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.specificDate} </span>
                          </Error>
                        ) : null}
                      </div>
                    ) : null}

                    <div className="field-group">
                      {Number(formik.values.creationPeriod) === 3 ? (
                        <>
                          <div className="field">
                            <label htmlFor="typeDate">DATA INICIAL</label>
                            <input
                              type="date"
                              {...formik.getFieldProps("startDate")}
                            />
                            {formik.touched.startDate &&
                            formik.errors.startDate ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span> {formik.errors.startDate} </span>
                              </Error>
                            ) : null}
                          </div>
                          <div className="field">
                            <label htmlFor="typeDate">DATA FINAL</label>
                            <input
                              type="date"
                              {...formik.getFieldProps("endDate")}
                            />
                            {formik.touched.endDate && formik.errors.endDate ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span> {formik.errors.endDate} </span>
                              </Error>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                )}
              </Formik>
            </main>
          </FilterCheck>
        )}
      </Filter>

      <ContainerList>
        <div className="headerTable">
          <h3>Devoluções Legado</h3>
        </div>

        <TableContainer>
          {!loading.panel ? (
            <table>
              <thead>
                <tr>
                  <th>PROTOCOLO</th>
                  {admin && <th>RAZÃO SOCIAL</th>}
                  {admin && <th>NF</th>}
                  <th>TIPO SOLICITAÇÃO</th>
                  <th>STATUS</th>
                  <th>CRIADO EM</th>
                </tr>
              </thead>

              <tbody>
                {devolutions.map((devolution) => (
                  <tr
                    key={devolution.id}
                    onClick={() => {
                      admin
                        ? history.push("/admin/devolucaoLegado", {
                            devolution: devolution,
                          })
                        : history.push("/devolucaoLegado", {
                            devolution: devolution,
                          });
                    }}
                  >
                    <td>{devolution.protocol}</td>
                    {admin && <td>{devolution.clients_pj.company_name}</td>}
                    {admin && <td>{devolution.numberInvoice}</td>}
                    <td>
                      {devolution.requestType ===
                      "Indenização (somente produtos com problemas de qualidade)"
                        ? "Indenização"
                        : devolution.requestType ===
                          "Devolução (divergência comercial)"
                        ? "Devolução"
                        : "Fiscal"}
                    </td>
                    <td>
                      <span
                        className="status"
                        style={{ backgroundColor: devolution.status }}
                      >
                        {devolution.status}
                      </span>
                    </td>

                    <td>
                      {moment(devolution.created_at).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>

        <FooterContainer>
          <span>
            Mostrado{" "}
            {dataZero(
              Number(devolutions.length + pagination.limit * pagination.page)
            )}{" "}
            de {dataZero(Number(pagination.total))} resultados
          </span>

          <div>
            <button
              className={pagination.page < 1 ? "buttonNull" : ""}
              onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
              type="button"
            >
              ANTERIOR
            </button>
            <p style={{ marginRight: 8 }}>
              {dataZero(Number(pagination.page + 1))}
            </p>
            <button
              className={
                pagination.page >= pagination.pages ? "buttonNull" : ""
              }
              type="button"
              onClick={
                Number(pagination.page) >= Number(pagination.pages)
                  ? () => {}
                  : hadleNextPage
              }
            >
              PROXIMO
            </button>
          </div>
        </FooterContainer>
      </ContainerList>
    </Container>
  );
};

export default PanelDevolutions;
