import React from "react";

import useAlertCCTOrder from "../../hook/useAlertCCTOrder";
import { Container } from "./styles";

export function AlertCCT() {
  const { cookiesAlertCCT, setCookiesAlertCCT } = useAlertCCTOrder();

  if (cookiesAlertCCT) return null;

  return (
    <Container>
      <nav className={`content ${!cookiesAlertCCT ? "on" : "close"}`}>
        <p>Prezado(a) Cliente,</p>

        <section>
          <p>
            <br />
          </p>
          <p>
            Em caso de atraso da entrega por parte da transportadora, pedimos
            que não recuse sua mercadoria, receba a mesma e solicite prorrogação
            dos seus boletos diretamente pelo e-mail: (
            <a
              href="mailto:cct@alpardobrasil.com.br"
              // eslint-disable-next-line
              target="_blank"
            >
              cct@alpardobrasil.com.br
            </a>
            ).
          </p>
          <p>
            <br />
          </p>
          <p>
            Agradecemos sua compreensão e estamos à disposição para qualquer
            dúvida!
          </p>

          <p>Central de Controle de Transportes</p>
        </section>

        <div>
          <button type="button" onClick={() => setCookiesAlertCCT(true)}>
            Eu entendo
          </button>
        </div>
      </nav>
    </Container>
  );
}
