import styled from "styled-components";

export const TabelaCalculadora = styled.table`
  background-color: #fff;
  color: black;
  margin-bottom: 40px;
  border-radius: 4px;
  padding: 1rem;

  th {
    border: 1px solid;
    border-radius: 2px;
  }
`;
