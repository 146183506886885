import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const Content = styled.div`
  width: 100%;
  position: relative;
`;

export const Title = styled.h3`
  font-size: 1rem;
  color: #333;
  font-weight: lighter;
  margin-bottom: 0 !important;
`;

export const ExpectedDate = styled.span`
  height: 1rem;
  font-size: 0.875rem;
  margin-bottom: 6px;
  font-weight: bold;
  color: #0083cb;
  display: block;
  text-align: end;
`;

export const ContentItem = styled.div`
  border-radius: 16px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  display: flex;
`;

interface ItemProps {}

export const Item = styled.div<ItemProps>`
  background-color: #0083cb;
  height: 1.5rem;
  width: 100px;
  border-radius: 16px;

  position: relative;

  ::after {
    content: "";
    width: 1rem;
    height: 1rem;
    background-color: #006194;
    position: absolute;
    border-radius: 50%;
    top: 0.23rem;
    right: 0.3rem;
  }

  ::before {
    content: "";
    width: 0.765rem;
    height: 0.765rem;
    background-color: #006194;
    position: absolute;
    border-radius: 50%;
    top: 0.36rem;
    left: 0.4rem;
  }

  & + & {
    border-radius: 0 16px 16px 0;
  }
`;

export const ContainerInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

export const InfoItem = styled.div`
  display: flex;
  font-size: 0.75rem;
  flex-direction: column;
  color: #000;

  b {
    color: #888;
  }
`;

export const InfoItemTracking = styled.div``;
