import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { FieldProps } from "../../../@types/panelTypes";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { FilterOptions as IFilterOption } from "../../../hook/queries/useOrders";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";
import { ContainerCheckbox } from "../../create/styles";

type FilterProps = {
  setViewFilter: React.Dispatch<React.SetStateAction<boolean>>;
  viewFilter: boolean;
  formikFilter: any;

  columns: FieldProps[];
  filters: IFilter;
  setFilters: (data: IFilter) => void;

  filterOptions: IFilterOption;
  onUpdateData: () => void;

  is_admin?: boolean;
  is_client?: boolean;
  is_seller?: boolean;
  is_manager?: boolean;
};

export interface IFilter {
  cod?: string;
  documentNumber?: string;
  status?: string;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  origin?: string;
  highlighter?: string;
  type?: string;
  pointedDesc?: string;

  codSeller?: string;
  brand?: string;

  orderPeriod?: number;
  orderPresetDate?: number;
  orderSpecificDate?: Date;
  orderStartDate?: Date;
  orderEndDate?: Date;

  billingOrderPeriod?: number;
  billingOrderPresetDate?: number;
  billingOrderSpecificDate?: Date;
  billingOrderStartDate?: Date;
  billingOrderEndDate?: Date;
}

export const Filter = ({
  formikFilter,
  columns,
  filters,
  setFilters,
  is_client = false,
  is_manager,
  is_admin,
  onUpdateData,
  filterOptions,
  setViewFilter,
  viewFilter,
}: FilterProps) => {
  const query = useQueryParams();
  const history = useHistory();

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  function selectDescription(st: any, title: string) {
    let data = String(st);
    const arrTitle = [
      "brand",
      "status",
      "pointed",
      "refuse",
      "orderPeriod",
      "billingOrderPeriod",
      "orderPresetDate",
      "billingOrderPresetDate",
    ];
    if (arrTitle.includes(title)) {
      switch (title) {
        case "brand":
          const findBrand = filterOptions.brands.find(
            (brand) => Number(brand.id) === Number(st)
          );
          if (findBrand) data = findBrand.name;

          break;
        case "status":
          const findStatus = filterOptions.status.find(
            (status) => status.id === st
          );
          if (findStatus) data = findStatus.name;

          break;
        case "pointed":
          const findPointed = filterOptions.pointeds.find(
            (pointed) => Number(pointed.id) === Number(st)
          );
          if (findPointed) data = findPointed.name;

          break;
        case "refuse":
          const findRefuse = filterOptions.refuses.find(
            (pointed) => Number(pointed.id) === Number(st)
          );
          if (findRefuse) data = findRefuse.name;

          break;

        case "billingOrderPeriod":
          if (Number(st) === 1) {
            data = "Predefinida";
          }
          if (Number(st) === 2) {
            data = "Específica";
          }
          if (Number(st) === 3) {
            data = "Intevalo";
          }

          break;
        case "orderPeriod":
          if (Number(st) === 1) {
            data = "Predefinida";
          }
          if (Number(st) === 2) {
            data = "Específica";
          }
          if (Number(st) === 3) {
            data = "Intevalo";
          }

          break;
        case "orderPresetDate":
          if (Number(st) === 0) {
            data = "Hoje";
          }
          if (Number(st) === 2) {
            data = "Ontem";
          }
          if (Number(st) === 7) {
            data = "Últimos 7 dias";
          }
          if (Number(st) === 15) {
            data = "Últimos 15 dias";
          }
          if (Number(st) === 30) {
            data = "Últimos 30 dias";
          }

          break;
        case "billingOrderPresetDate":
          if (Number(st) === 0) {
            data = "Hoje";
          }
          if (Number(st) === 2) {
            data = "Ontem";
          }
          if (Number(st) === 7) {
            data = "Últimos 7 dias";
          }
          if (Number(st) === 15) {
            data = "Últimos 15 dias";
          }
          if (Number(st) === 30) {
            data = "Últimos 30 dias";
          }

          break;

        default:
          break;
      }
    }

    return data;
  }

  function setOnChangeCheckboxStatus(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { value, checked } = event.target;

    let normalizedFilter: string[] =
      formikFilter.values.status.split(",").filter((f: any) => f) ?? [];

    if (normalizedFilter.includes(value) && checked === false) {
      const findIndex = normalizedFilter.findIndex((i) => i === value);
      normalizedFilter.splice(findIndex, 1);
    }

    if (checked) {
      normalizedFilter.push(value);
    }

    formikFilter.setFieldValue("status", normalizedFilter.join(","));
  }

  function isCheckedOnCheckboxStatus(value: string) {
    const arrStatus =
      formikFilter.values.status.split(",").filter((f: any) => f) ?? [];

    return arrStatus.includes(value);
  }

  return (
    <PanelFilter
      filters={filters}
      setFilters={setFilters}
      onUpdate={onUpdateData}
      columns={columns}
      onSubmit={() => {
        // onUpdateData();
        formikFilter.handleSubmit();
      }}
      onResetForm={() => {
        formikFilter.resetForm();
        onUpdateData();
      }}
      setViewFilter={setViewFilter}
      viewFilter={viewFilter}
      normalizedFilters={selectDescription}
    >
      <GroupInput>
        <Input
          label="CÓDIGO DO PEDIDO"
          {...formikFilter.getFieldProps("cod")}
        />
        <Input
          label="Nº NOTA FISCAL"
          {...formikFilter.getFieldProps("documentNumber")}
        />
      </GroupInput>

      {!is_client && (
        <GroupInput>
          <Input
            label="CÓDIGO CLIENTE"
            {...formikFilter.getFieldProps("codClient")}
          />
          <Input label="CNPJ" {...formikFilter.getFieldProps("cnpj")} />
          <Input
            label="RAZÃO SOCIAL"
            {...formikFilter.getFieldProps("company_name")}
          />
        </GroupInput>
      )}

      <GroupInput>
        <Select
          label="CÓD. VENDEDOR"
          data={filterOptions.sellers.map((brand) => ({
            name: `${String(brand.id)} ${String(brand.name)}`,
            value: String(brand.id),
          }))}
          {...formikFilter.getFieldProps("codSeller")}
        />
        <Select
          label="Origen pedido"
          data={[{ name: "Alparhub", value: "alparhub" }]}
          {...formikFilter.getFieldProps("origin")}
        />
      </GroupInput>

      <div className="field">
        <ContainerCheckbox>
          <label className="titleCheckbox" htmlFor="">
            Status
          </label>
          <div className="contentsCheckbox">
            {filterOptions.status.map((element, index) => (
              <div key={index} className="boxCheckbox">
                <input
                  type="checkbox"
                  onChange={setOnChangeCheckboxStatus}
                  checked={
                    isCheckedOnCheckboxStatus(element.id.toString())
                      ? true
                      : false
                  }
                  value={element.name}
                  name={`status`}
                />
                <label htmlFor="">{element.name}</label>
              </div>
            ))}
          </div>
        </ContainerCheckbox>
      </div>

      <GroupInput>
        <Select
          label="MARCA"
          data={filterOptions.brands.map((brand) => ({
            name: String(brand.name),
            value: String(brand.id),
          }))}
          {...formikFilter.getFieldProps("brand")}
        />
        <Select
          label="TIPO"
          data={[
            { name: "Venda Futura", value: "venda futura" },
            { name: "Pronta Entrega", value: "pronta entrega" },
          ]}
          {...formikFilter.getFieldProps("type")}
        />
      </GroupInput>

      <GroupInput>
        {!is_client && filterOptions.pointeds.length > 0 && (
          <Select
            label="MOTIVO CANCELAMENTO"
            data={filterOptions.pointeds.map((brand) => ({
              name: String(brand.name),
              value: String(brand.id),
            }))}
            {...formikFilter.getFieldProps("pointed")}
          />
        )}
        {(is_manager || is_admin) && filterOptions.refuses.length > 0 && (
          <Select
            label="MOTIVO RECUSA"
            data={filterOptions.refuses.map((brand) => ({
              name: String(brand.name),
              value: String(brand.id),
            }))}
            {...formikFilter.getFieldProps("refuse")}
          />
        )}

        {filterOptions?.highlighters &&
          filterOptions?.highlighters.length > 0 && (
            <Select
              label="Destacador"
              data={
                filterOptions?.highlighters?.map((item) => ({
                  name: item.name,
                  value: item.name,
                })) ?? []
              }
              {...formikFilter.getFieldProps("highlighter")}
            />
          )}
      </GroupInput>

      <Select
        label="DATA DO PEDIDO"
        data={[
          { name: "Predefinida", value: 1 },
          { name: "Específica", value: 2 },
          { name: "Intervalo", value: 3 },
        ]}
        {...formikFilter.getFieldProps("orderPeriod")}
      />

      {Number(formikFilter.values.orderPeriod) === 1 && (
        <Select
          label="DATA DO PEDIDO"
          data={[
            { name: "Hoje", value: 0 },
            { name: "Ontem", value: 1 },
            { name: "Últimos 7 dias", value: 7 },
            { name: "Últimos 15 dias", value: 15 },
            { name: "Últimos 30 dias", value: 30 },
          ]}
          {...formikFilter.getFieldProps("orderPresetDate")}
          error={
            formikFilter.touched.orderPresetDate &&
            formikFilter.errors.orderPresetDate
              ? formikFilter.errors.orderPresetDate
              : undefined
          }
        />
      )}
      {Number(formikFilter.values.orderPeriod) === 2 && (
        <Input
          type="date"
          label="DATA DO PEDIDO"
          {...formikFilter.getFieldProps("orderSpecificDate")}
          error={
            formikFilter.touched.orderSpecificDate &&
            formikFilter.errors.orderSpecificDate
              ? formikFilter.errors.orderSpecificDate
              : undefined
          }
        />
      )}

      {Number(formikFilter.values.orderPeriod) === 3 && (
        <GroupInput>
          <Input
            type="date"
            label="DATA INICIAL"
            {...formikFilter.getFieldProps("orderStartDate")}
            error={
              formikFilter.touched.orderStartDate &&
              formikFilter.errors.orderStartDate
                ? formikFilter.errors.orderStartDate
                : undefined
            }
          />
          <Input
            type="date"
            label="DATA FINAL"
            {...formikFilter.getFieldProps("orderEndDate")}
            error={
              formikFilter.touched.orderEndDate &&
              formikFilter.errors.orderEndDate
                ? formikFilter.errors.orderEndDate
                : undefined
            }
          />
        </GroupInput>
      )}

      <Select
        label="DATA DE FATURAMENTO"
        data={[
          { name: "Predefinida", value: 1 },
          { name: "Específica", value: 2 },
          { name: "Intervalo", value: 3 },
        ]}
        {...formikFilter.getFieldProps("billingOrderPeriod")}
      />

      {Number(formikFilter.values.billingOrderPeriod) === 1 && (
        <Select
          label="DATA DO PEDIDO"
          data={[
            { name: "Hoje", value: 0 },
            { name: "Ontem", value: 1 },
            { name: "Últimos 7 dias", value: 7 },
            { name: "Últimos 15 dias", value: 15 },
            { name: "Últimos 30 dias", value: 30 },
          ]}
          {...formikFilter.getFieldProps("billingOrderPresetDate")}
          error={
            formikFilter.touched.billingOrderPresetDate &&
            formikFilter.errors.billingOrderPresetDate
              ? formikFilter.errors.billingOrderPresetDate
              : undefined
          }
        />
      )}
      {Number(formikFilter.values.billingOrderPeriod) === 2 && (
        <Input
          type="date"
          label="DATA DO PEDIDO"
          {...formikFilter.getFieldProps("billingOrderSpecificDate")}
          error={
            formikFilter.touched.billingOrderSpecificDate &&
            formikFilter.errors.billingOrderSpecificDate
              ? formikFilter.errors.billingOrderSpecificDate
              : undefined
          }
        />
      )}
      {Number(formikFilter.values.billingOrderPeriod) === 3 && (
        <GroupInput>
          <Input
            type="date"
            label="DATA INICIAL"
            {...formikFilter.getFieldProps("billingOrderStartDate")}
            error={
              formikFilter.touched.billingOrderStartDate &&
              formikFilter.errors.billingOrderStartDate
                ? formikFilter.errors.billingOrderStartDate
                : undefined
            }
          />
          <Input
            type="date"
            label="DATA FINAL"
            {...formikFilter.getFieldProps("billingOrderEndDate")}
            error={
              formikFilter.touched.billingOrderEndDate &&
              formikFilter.errors.billingOrderEndDate
                ? formikFilter.errors.billingOrderEndDate
                : undefined
            }
          />
        </GroupInput>
      )}
    </PanelFilter>
  );
};
