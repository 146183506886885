import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";
// @ts-ignore
import { mask } from "remask";

import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { InputCheckbox } from "../../../components/Form/InputCheckbox";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { OptionsDataProps } from "../../../components/ModalModificationDatasheet";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Ncm, useOneNcm } from "../../../hook/queries/useNcms";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { setFormikValues } from "../../../utils/setFormikValues";

export interface MatchParams {
  id?: string;
}

type NcmCreateOmitProps = Omit<Ncm, "id" | "created_at">;

interface NcmCreateProps extends NcmCreateOmitProps {
  categories: string[];
  genres: string[];
  materials: string[];
  materials_natures: string[];
  types: string[];
  groups: string[];
}
interface NcmUpdateProps extends NcmCreateProps {
  id: number;
}

const Ncms: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const isUpdating = match?.params?.id ? true : false;
  const oneNcm = useOneNcm(Number(match.params.id));

  const [materialNatureFields, setMaterialNatureFields] = useState<any>({});
  const [materialFields, setMaterialFields] = useState<any>({});
  const [categoryFields, setCategoryFields] = useState<any>({});
  const [typeFields, setTypeFields] = useState<any>({});
  const [genreFields, setGenreFields] = useState<any>({});
  const [groupFields, setGroupFields] = useState<any>({});

  const [isLoading, setIsLoading] = useState({
    mainData: true,
    optionsData: true,
  });

  const [optionsData, setOptionsData] = useState<OptionsDataProps>({
    genres: [],
    unit_measures: [],
    product_origins: [],
    age_types: [],
    product_types: [],
    product_lines: [],
    product_groups: [],
    sleeves: [],
    ncms: [],
    grids: [],
    categories: [],
    materials: [],
    price_classification: [],
    materials_natures: [],
  });

  const createNcm = useMutation(
    async (data: NcmCreateProps) => {
      const response = await api.post("/populate/ncms/create", data);

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("ncms");
      },
    }
  );
  const updateNcm = useMutation(
    async (color: NcmUpdateProps) => {
      const response = await api.put(
        `/populate/ncms/update/${color.id}`,
        color
      );

      return response.data;
    },
    {
      onSuccess: (color: Ncm) => {
        queryClient.invalidateQueries("ncms");
        queryClient.invalidateQueries(["ncm", color.id]);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      id: "",
      code: "",
      cest: "",
      gpc: "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .min(8, "Inválido, NCM possui 8 dígitos")
        .max(8, "Inválido, NCM possui 8 dígitos"),
      cest: Yup.string()
        .nullable()
        .min(7, "Inválido, CEST possui 7 dígitos")
        .max(7, "Inválido, CEST possui 7 dígitos"),
      gpc: Yup.string()
        .nullable()
        .min(8, "Inválido, GPC possui 8 dígitos")
        .max(8, "Inválido, GPC possui 8 dígitos"),
    }),
    onSubmit: (a) => {
      //@ts-ignore
      !isUpdating ? handleCreateNcm(a) : handleUpdateNcm(a);
    },
  });

  useEffect(() => {
    setIsLoading((old) => ({ ...old, mainData: !!oneNcm?.isLoading }));
    // eslint-disable-next-line
  }, [oneNcm?.isLoading]);

  useEffect(() => {
    (async () => {
      const optionsDataDB = await api.get<OptionsDataProps>(
        `/headerDatasheet/datasheet/options`
      );

      setOptionsData(optionsDataDB.data);
      setIsLoading((old) => ({ ...old, optionsData: false }));
    })();
  }, []);

  useEffect(() => {
    if (oneNcm?.isSuccess && oneNcm?.data && isUpdating)
      setFormikValues(oneNcm?.data, formik);
    // eslint-disable-next-line
  }, [oneNcm?.data, isUpdating, oneNcm?.isSuccess]);

  async function handleCreateNcm(data: NcmCreateProps) {
    try {
      const { categories, genres, materials, types, materials_natures } =
        await normalizedCheckbox();

      await createNcm.mutateAsync({
        ...data,
        materials_natures,
        categories,
        genres,
        materials,
        types,
      });

      addToast("NCM alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/ncms");
    } catch (err) {
      const error = err as AxiosError | undefined;

      if (error?.response?.data?.error === "code already exists") {
        return addToast("Este NCM já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleUpdateNcm(data: Ncm) {
    try {
      const {
        categories,
        genres,
        materials,
        types,
        materials_natures,
        groups,
      } = await normalizedCheckbox();

      await updateNcm.mutateAsync({
        ...data,
        materials_natures,
        categories,
        genres,
        materials,
        types,
        groups,
      });

      addToast("NCM alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/ncms");
    } catch (err) {
      const error = err as AxiosError | undefined;

      if (error?.response?.data?.error === "code already exists") {
        return addToast("Este NCM já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data: any;

    if (
      ["code", "gpc"].includes(name) &&
      value.replace(/\D/g, "").length <= 8
    ) {
      data = value.replace(/\D/g, "");
      formik.setFieldValue(name, data);
    }
    if (name === "cest" && value.replace(/\D/g, "").length <= 7) {
      data = value.replace(/\D/g, "");
      formik.setFieldValue(name, data);
    }
  }

  async function normalizedCheckbox() {
    let materials: string[] = [];
    for (const item of Object.getOwnPropertyNames(materialFields)) {
      if (materialFields[item]) materials.push(item);
    }
    let materials_natures: string[] = [];
    for (const item of Object.getOwnPropertyNames(materialNatureFields)) {
      if (materialNatureFields[item]) materials_natures.push(item);
    }
    let categories: string[] = [];
    for (const item of Object.getOwnPropertyNames(categoryFields)) {
      if (categoryFields[item]) categories.push(item);
    }
    let types: string[] = [];
    for (const item of Object.getOwnPropertyNames(typeFields)) {
      if (typeFields[item]) types.push(item);
    }
    let genres: string[] = [];
    for (const item of Object.getOwnPropertyNames(genreFields)) {
      if (genreFields[item]) genres.push(item);
    }
    let groups: string[] = [];
    for (const item of Object.getOwnPropertyNames(groupFields)) {
      if (groupFields[item]) groups.push(item);
    }

    return {
      materials,
      materials_natures,
      categories,
      types,
      genres,
      groups,
    };
  }

  return (
    <CreateAndDetailLayout
      isLoading={isLoading.mainData && isLoading.optionsData}
    >
      <PanelAndDetailAndCreateHeader
        title={isUpdating ? "Ncm" : "Criar Ncm"}
        goBack
      />

      <LayoutForm onSubmit={formik.handleSubmit}>
        <Input
          label="NCM"
          name="code"
          value={mask(formik.values.code, ["9999.99.99"])}
          onChange={handleInputChange}
          onBlur={formik.handleBlur("code")}
          error={
            formik.touched.code && formik.errors.code
              ? formik.errors.code
              : undefined
          }
        />

        <GroupInput>
          <Input
            label="CEST"
            name="cest"
            value={mask(formik.values.cest, ["99.999.99"])}
            onChange={handleInputChange}
            onBlur={formik.handleBlur("cest")}
            error={
              formik.touched.cest && formik.errors.cest
                ? formik.errors.cest
                : undefined
            }
          />
          <Input
            label="GPC"
            name="gpc"
            value={mask(formik.values.gpc, ["9999.99.99"])}
            onChange={handleInputChange}
            onBlur={formik.handleBlur("gpc")}
            error={
              formik.touched.gpc && formik.errors.gpc
                ? formik.errors.gpc
                : undefined
            }
          />
        </GroupInput>

        <GroupInput>
          <InputCheckbox
            height="50vh"
            name="material"
            label="Material"
            permissionFields={materialFields}
            setPermissionFields={setMaterialFields}
            data={optionsData.materials.map((material) => ({
              name: String(material.id),
              description: material.description,
            }))}
            permissionFieldsAllowed={oneNcm?.data?.material?.map((item) =>
              String(item.id)
            )}
          />
          <InputCheckbox
            height="50vh"
            name="material_nature"
            label="Natureza do Material"
            permissionFields={materialNatureFields}
            setPermissionFields={setMaterialNatureFields}
            data={optionsData.materials_natures.map((material) => ({
              name: String(material.id),
              description: material.description,
            }))}
            permissionFieldsAllowed={oneNcm?.data?.material_nature?.map(
              (item) => String(item.id)
            )}
          />
          <InputCheckbox
            height="50vh"
            name="category"
            label="Classe de Produto"
            permissionFields={categoryFields}
            setPermissionFields={setCategoryFields}
            data={optionsData.categories.map((category) => ({
              name: String(category.id),
              description: category.description,
            }))}
            permissionFieldsAllowed={oneNcm?.data?.category?.map((item) =>
              String(item.id)
            )}
          />
          <InputCheckbox
            name="type"
            label="Tipo"
            permissionFields={typeFields}
            setPermissionFields={setTypeFields}
            data={optionsData.product_types.map((type) => ({
              name: String(type.id),
              description: type.name,
            }))}
            permissionFieldsAllowed={oneNcm?.data?.type?.map((item) =>
              String(item.id)
            )}
          />
          <InputCheckbox
            name="genre"
            label="Gênero"
            permissionFields={genreFields}
            setPermissionFields={setGenreFields}
            data={optionsData.genres.map((genre) => ({
              name: genre.initials,
              description: genre.name,
            }))}
            permissionFieldsAllowed={oneNcm?.data?.genre?.map((item) =>
              String(item.initials)
            )}
          />
          <InputCheckbox
            name="group"
            label="Categoria"
            permissionFields={groupFields}
            setPermissionFields={setGroupFields}
            data={optionsData.product_groups.map((group) => ({
              name: String(group.id),
              description: group.name,
            }))}
            permissionFieldsAllowed={oneNcm?.data?.group?.map((item) =>
              String(item.id)
            )}
          />
        </GroupInput>
        <ButtonSubmit>{isUpdating ? "Editar" : "Criar"}</ButtonSubmit>
      </LayoutForm>
    </CreateAndDetailLayout>
  );
};

export default Ncms;
