import React, { CSSProperties } from "react";
import { Container } from "./styles";

interface GroupInputProps {
  style?: CSSProperties;
}

export const GroupInput: React.FC<GroupInputProps> = ({
  children,
  ...rest
}) => {
  return (
    <Container {...rest} className="field-group">
      {children}
    </Container>
  );
};
