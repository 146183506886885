import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0 !important;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  *, button, input {
    border: 0;
    outline: 0;

    font-family: 'Roboto', sans-serif;
  }


  body {
    background: #F0F0F0;
    -webkit-font-smoothing: antialiased;
  }

  html, body, #root {
    height: 100%;
    ::-webkit-scrollbar {
    width: 8px;
  }

   // font-size: 16px (Desktop)
   html{
    @media(max-width:1080px) {
      font-size: 93.75%; // 15px
    }
    
    @media(max-width:720px) {
      font-size: 87.5%; // 14px
    }
  }

  [readonly] {
    opacity: 0.5;
  }
 

  [disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    border-style: dashed
  }
 
  [disabled]:hover {
    opacity: 0.5!important;
  }

  select[disabled] {
    color: #333!important;
    opacity: 0.5!important;
  }
  select[disabled]:hover {
    color: #333!important;
    opacity: 0.5!important;
  }

  ul {
    list-style-type: none;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  button {
    cursor: pointer;
  }
  a{
    text-decoration:none; 
  }
  input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }
  }

  .buttonNull {
      cursor: not-allowed;
    }

    .logo-details{
      margin-top: 8px;
      margin-bottom: 12px;
    }
}
  
`;

export const ButtunSubmit = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;

  font-weight: 700;
  font-size: 16px;
  color: #fff;

  background: #d21e26;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  /* box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2); */
  &:hover {
    opacity: 0.85;
  }
`;

interface GridProps {
  isOpenNav: boolean;
  isClient: boolean;
}

export const Grid = styled.div<GridProps>`
  display: grid;
  height: 100vh;

  grid-template-columns: ${(props) => (!props.isOpenNav ? 78 : 260)}px auto;
  grid-template-rows: ${(props) =>
    props.isClient ? "80px auto" : "60px auto"};
  padding-top: ${(props) => (props.isClient ? "10px" : 0)};

  grid-template-areas:
    "NAV NAVTOP"
    "NAV APP";

  @media (max-width: 900px) {
    grid-template-columns: 0px auto;
    grid-template-rows: ${(props) =>
      props.isClient ? "60px auto" : "60px auto"};
    padding-top: ${(props) => (props.isClient ? "10px" : 0)};
  }
`;
