import styled from "styled-components";

export const Container = styled.div`
  grid-area: APP;
  padding: 10px 30px;

  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  header {
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .infoUpdate {
      span {
        font-size: 0.8rem;
        color: #555;
        font-weight: 300;
      }
    }
  }
`;

export const Form = styled.form`
  border-radius: 4px 0px 0px 4px;
  width: 100%;
  height: 100%;
  background-color: #fff;

  h3 {
    font-size: 18;
    color: #333;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0 0px;
  }
  .field-group {
    width: 100%;
    display: flex;
    flex-flow: row;
  }

  .containerCnpj {
    display: flex;
    width: 100%;
    button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 15%;
      height: 35px;

      color: #fff;
      font-size: 16px;
      border-radius: 4px;
      background-color: #d21e26;
      transition: all 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
    input {
      width: 85%;
      margin-right: 8px;
    }
  }

  @media (max-width: 900px) {
    .field-group {
      flex-flow: column;
    }

    .field {
      padding: 0 2px;
      input {
        padding: 0 0;
      }
    }
  }

  .field {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-bottom: 24px;
    padding: 0 10px;

    label {
      font-size: 14px;
      margin-bottom: 4px;
      color: #555;

      .required {
        color: #f00;
        font-size: 11px;
      }
    }
    input {
      padding: 0 10px;
      background: #fff;
      height: 35px;
      font-size: 16px;
      color: #000;
      border: 2px solid #aaa;
      border-radius: 5px;
    }

    .selectClient {
      height: 40px;
      width: 100%;

      button {
        width: 100%;
        height: 100%;
        background: #d21e26;

        font-weight: bold;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
      }
    }

    input:focus {
      border: 2px solid #333;
    }

    input::placeholder {
      color: #a0a0b2;
    }

    textarea {
      padding: 10px 10px;
      background: #fff;
      height: 80px;
      font-size: 16px;
      color: #000;
      border: 2px solid #aaa;
      border-radius: 5px;
      resize: vertical;

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-thumb {
        background: #333;
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background: #888;
      }

      &:focus {
        border: 2px solid #333;
      }
    }

    select {
      height: 35px;

      background: #fff;
      border-radius: 5px;
      border: 2px solid #aaa;
      padding: 4px 8px;

      font-size: 16px;
      color: #aaa;

      cursor: pointer;
      /* -webkit-appearance: none; */
      -moz-appearance: none;
      /* appearance: none;  */

      &:focus {
        border: 2px solid #333;
        color: #333;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  .containerButton {
  }
`;

export const ContainerList = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  .headerTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    height: 45px;
    background-color: #ccc;

    h3 {
      font-size: 18px;
      color: #111;
      font-weight: 500;
    }

    button {
      padding: 8px 10px;
      height: 28px;
      background-color: #d21e26;
      border-radius: 5px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s;

      &:hover {
        opacity: 0.6;
      }

      svg {
        color: #fff;
        margin-right: 5px;
      }

      span {
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }

      @media (max-width: 550px) {
        svg {
          margin-right: 0px;
        }
        span {
          display: none;
        }
      }
    }
  }
`;

export const TableContainer = styled.div`
  width: 100%;

  max-width: 100vw;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #eee;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 10px;

    thead {
      background-color: #eee;

      tr {
        th {
          padding: 6px 8px;
          font-size: 16px;
          color: #444;
          white-space: nowrap;
        }
      }
    }

    tbody {
      background-color: #fff;

      tr {
        color: #777;
        border-bottom: 2px solid #eee;
        cursor: pointer;

        &:hover {
          background-color: #eee;
        }

        .containerTracking {
          display: flex;
          align-items: center;
          justify-content: center;

          background: transparent;

          width: 100%;
          height: 100%;

          button,
          a {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            width: 80px;
            max-width: 100%;
            height: 28px;
            color: #000;

            background-color: #ddd;
            padding: 5px 8px;
            border: 2px solid #333;

            border-radius: 6px;
            font-weight: 400;
            font-size: 11px;

            transition: all 0.2s ease-in;

            span {
              margin-right: 2px;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }

        td {
          position: relative;
          height: 35px;
          text-align: center;
          padding: 0 6px;

          .notification {
            display: flex;
            align-items: center;
            position: absolute;
            left: 8px;
            top: 0px;
            height: 100%;
          }
          .status {
            color: #000;
            font-size: 13px;
            font-weight: 500;
            border-radius: 4px;
            padding: 2px 5px;
            white-space: nowrap;
          }

          .stuationContainer {
            background-color: transparent;
            width: 100%;

            div {
              width: 40px;
              margin: 0 auto;
            }

            svg {
              transition: transform 0.2s ease-in-out;

              &:hover {
                transform: scale(1.3);
              }
            }
          }
        }

        .accessButton {
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            width: 70%;
            height: 80%;

            display: flex;
            justify-content: center;
            align-items: center;

            background: #d21e26;
            border-radius: 6px;

            color: #fff;
            font-weight: 700;
            svg {
              margin-left: 4px;
              font-size: 22px;
            }

            @media (max-width: 1000px) {
              span {
                display: none;
              }

              svg {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
`;

export const FooterContainer = styled.div`
  height: 55px;
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 20px;

  background-color: #fff;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 550px) {
    height: 80px;
  }

  span {
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 400;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      height: 30px;
      padding: 15px 15px;
      margin-right: 10px;

      font-weight: 700;
      background-color: #fff;
      transition: all 0.8s;
      color: #555;
    }

    button:hover {
      background-color: #ccc;
    }

    .buttonNull {
      cursor: not-allowed;
    }
  }
`;

export const LoadingPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;

  padding: 30px 0;
`;
