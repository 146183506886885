import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { ButtunSubmit, Form, ScreenLoading } from "../../pages/create/styles";
import {
  IBrand,
  IBrandManager,
  ISalesman,
} from "../../pages/detail/Devolution";
import api from "../../service/api";
import { Input } from "../Form/Input";
import { Select } from "../Form/Select";
import Loading from "../loadings/Loading";
import { Container, ContainerForm } from "./styles";

interface ModalModificationDevolutionProps {
  type: "update";
  onRequestUpdate: () => void;
  onRequestClose: () => void;
  data: Devolution;

  inputSelect: {
    salesmans: ISalesman[];
    brandManagers: IBrandManager[];
    brands: IBrand[];
  };
}

interface Devolution {
  id: number;
  numberInvoice?: string;
  brand?: string;
  salesman?: string;
  brandManager?: string;
  situationRequest?: string;
}

export const ModalModificationDevolution: React.FC<
  ModalModificationDevolutionProps
> = ({ type, data, inputSelect, onRequestUpdate, onRequestClose }) => {
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const { addToast } = useToasts();

  const formik = useFormik({
    // validateOnChange: false,
    validateOnMount: false,

    initialValues: {
      id: type === "update" ? data.id : 0,
      numberInvoice: type === "update" ? data.numberInvoice : "",
      brand: type === "update" ? data.brand : "",
      salesman: type === "update" ? data.salesman : "",
      brandManager: type === "update" ? data.brandManager : "",
      situationRequest: type === "update" ? data.situationRequest : "",
    },
    validationSchema: Yup.object({}),

    onSubmit: async (data) =>
      type === "update" ? await handleSubmitUpdate(data) : () => {},
  });

  useEffect(() => {
    setLoadingPage(false);
  }, []);

  async function handleSubmitUpdate(props: Devolution) {
    try {
      if (data && type === "update") {
        setLoading(true);
        await api.put(`/devolution/${data.id}`, {
          numberInvoice: String(props.numberInvoice),
          brand: String(props.brand),
          salesman: String(props.salesman),
          brandManager: String(props.brandManager),
          situationRequest: String(props.situationRequest),
        });
        addToast("Devolução alterado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        onRequestUpdate();
        onRequestClose();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <Form
        onSubmit={
          loading
            ? (e) => {
                e.preventDefault();
              }
            : formik.handleSubmit
        }
      >
        <ContainerForm>
          <div className="containerForm">
            {!loadingPage ? (
              <>
                <h3>Dados da solitação</h3>
                <Input
                  label="Nota fiscal nº"
                  {...formik.getFieldProps("numberInvoice")}
                />

                <div className="field-group">
                  <Select
                    label="Marca"
                    {...formik.getFieldProps("brand")}
                    data={inputSelect?.brands.map((brand) => ({
                      value: brand.cod,
                      name: brand.name,
                    }))}
                  />
                  <Select
                    label="TIPO DE DEVOLUÇÃO"
                    {...formik.getFieldProps("situationRequest")}
                    data={[
                      { value: "parcial", name: "parcial" },
                      { value: "total", name: "total" },
                    ]}
                  />
                </div>

                <h3>Representante / Gestor</h3>

                <div className="field-group">
                  <Select
                    label="Representante"
                    {...formik.getFieldProps("salesman")}
                    data={inputSelect?.salesmans.map((salesman) => ({
                      value: salesman.cod,
                      name: `${salesman.cod} ${salesman.fullName}`,
                    }))}
                  />
                  <Select
                    label="Gestor da Marca"
                    {...formik.getFieldProps("brandManager")}
                    data={inputSelect?.brandManagers.map((brandManager) => ({
                      value: brandManager.cod,
                      name: `${brandManager.cod} ${brandManager.fullName}`,
                    }))}
                  />
                </div>
              </>
            ) : (
              <ScreenLoading>
                <Loading
                  colorLoading="#333"
                  size={40}
                  borderColor={"#888"}
                  borderSize={5}
                />
              </ScreenLoading>
            )}
          </div>

          <div className="containerFormButton">
            <ButtunSubmit style={{ margin: 0 }} type="submit">
              {loading ? (
                <div>
                  <Loading borderSize={2} size={20} />
                </div>
              ) : type === "update" ? (
                "Editar Devolução"
              ) : (
                ""
              )}
            </ButtunSubmit>
          </div>
        </ContainerForm>
      </Form>
    </Container>
  );
};
