import React, { createContext, ReactNode, useContext, useState } from "react";
import { IconType } from "react-icons";

interface SideBarContextData {
  isOpen: boolean;
  handleSetIsOpen: (b: boolean) => Promise<void>;
  handleActiveGroup: (b: string) => void;
  handleSetItems: (b: ItemsNavDataProps[]) => void;
  itemsNav: ItemsNavDataProps[];
}

interface SideBarProviderProps {
  children: ReactNode;
}

export interface ItemsNavDataGroupProps {
  name: string;
  path: string;
}

export interface ItemsNavDataProps {
  Icon: IconType;
  name: string;
  path: string;

  isGroup: boolean;
  isGroupActive?: boolean;
  groupPath?: ItemsNavDataGroupProps[];
}

const SideBarContext = createContext<SideBarContextData>(
  {} as SideBarContextData
);

export function SideBarProvider({ children }: SideBarProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [itemsNav, setItems] = useState<ItemsNavDataProps[]>([]);

  async function handleSetIsOpen(b: boolean) {
    setIsOpen(b);
  }

  async function handleActiveGroup(name: string) {
    const itemsNavFind = itemsNav.filter((item) => item.name !== null);
    const findItem = itemsNavFind.find((item) => item.name === name);
    if (findItem && findItem.isGroupActive !== undefined) {
      findItem.isGroupActive = !findItem.isGroupActive;
    }

    setItems(itemsNavFind);
  }

  async function handleSetItems(items: ItemsNavDataProps[]) {
    setItems(items);
  }

  return (
    <SideBarContext.Provider
      value={{
        isOpen,
        handleSetIsOpen,
        itemsNav,
        handleActiveGroup,
        handleSetItems,
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
}

export function useSideBar() {
  const context = useContext(SideBarContext);

  return context;
}
