import api from "../service/api";

export type JwtSsoPayload = {
  entity: "seller" | "user" | "client";
  sellerCod?: string;
  email?: string;
};

export function useSsoCatalog({ entity, sellerCod, email }: JwtSsoPayload) {
  async function accessSsoCatalog() {
    const response = await api.post("sso/create/catalog", {
      entity,
      sellerCod,
      email,
    });
    const token = response.data;
    const url = `https://app.alpardobrasil.com.br/sso/${token}`;

    setTimeout(() => {
      window.open(url, "_blank");
    });

    return;
  }

  return { accessSsoCatalog };
}
