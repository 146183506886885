import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";

import { HiOutlineClipboardCheck } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { Select } from "../../../components/Form/Select";
import { Textarea } from "../../../components/Form/Textarea";
import Modal from "../../../components/ModalLib";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Quality as QualityProps } from "../../../hook/queries/useQualities";
import api from "../../../service/api";
import * as UserStorage from "../../../service/localStorage/user";
import { queryClient } from "../../../service/queryClient";
import { dataUndefined } from "../../../utils/dataProcessing";
import {
  DatasheetVariationProps,
  HeaderDatasheetAndDatasheet,
} from "../../detail/Datasheet";
import { FileProps, ModalQualityProduct } from "./ModalQualityProduct";
import { IDefect } from "./SelectDefect";
import {
  ContainerTabletSelect,
  RowNone,
  TabletSelect,
} from "./SelectDefect/styles";

export interface MatchParams {
  datasheetId?: string;
  headerId?: string;
}

export interface ProductQuality {
  id: string;
  barcode: string;
  barcodeId?: string;
  approvalBarcode: boolean;
  size: string;
  reference: string;
  primaryColor: string;
  defects: IDefect[];
  files: FileProps[];
}

interface ProductQualitySend {
  barcode: string;
  barcodeId?: string;
  approvalBarcode: boolean;
  defects: { id: string }[];
  files: { id: string }[];
}

interface QualityCreateProps extends Omit<QualityProps, "id"> {
  qualityProducts: ProductQualitySend[];
  variations: { id: string; qtd?: number }[];
}

interface QualityNqa {
  sampling: number;
  accpet: number;
  reject: number;

  qtdMin: number;
  qtdMax: number;
}

const Quality: React.FC = () => {
  const UserData = UserStorage.getUser() as UserStorage.User;
  const { addToast } = useToasts();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();

  const [datasheet, setDatasheet] = useState<HeaderDatasheetAndDatasheet>(
    {} as HeaderDatasheetAndDatasheet
  );
  const [nqa, setNqa] = useState<QualityNqa[]>([]);

  const [qualityProducts, setQualityProducts] = useState<ProductQuality[]>([]);

  const [activeProductModal, setActiveProductModal] = React.useState(false);

  const createQuality = useMutation(
    async (quality: QualityCreateProps) => {
      const response = await api.post(
        `/headerDatasheet/datasheet/qualities/${match.params.datasheetId}/`,
        quality
      );

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("qualities");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      inspectorId: String(UserData.id),
      providerId: "",
      status: "",
      inspectionType: "",
      oc: "",
      oc_total_quantity: "",
      sampling: "",
      samplingMinimum: "",
      observation: "",
    },
    validationSchema: Yup.object({
      inspectorId: Yup.string().required("É obrigatório"),
      providerId: Yup.string().required("É obrigatório"),
      oc: Yup.string().required("É obrigatório"),
      oc_total_quantity: Yup.number()
        .min(1, "É obrigatório preenchimento das Variantes de Cor")
        .required("É obrigatório preenchimento das Variantes de Cor"),
      samplingMinimum: Yup.string().required("É obrigatório"),
      sampling: Yup.string().required("É obrigatório"),
      inspectionType: Yup.string().required("É obrigatório"),
      status: Yup.string().required("É obrigatório"),
    }),
    onSubmit: (a) => {
      handleCreateColor(a as any);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        if (
          !Boolean(match.params.datasheetId) ||
          !Boolean(match.params.headerId)
        ) {
          history.push("/404");
        }

        const headerDatasheetDB = await api.get<HeaderDatasheetAndDatasheet>(
          `/headerDatasheet/datasheet/show/${match.params.datasheetId}`
        );

        if (!Boolean(headerDatasheetDB.data)) {
          return history.push("/404");
        }

        var normalized = headerDatasheetDB.data;

        if (normalized.variations?.length > 0) {
          normalized.variations = normalized.variations.map((variantion) => ({
            ...variantion,
            reference:
              variantion.variations &&
              variantion.variations?.length > 0 &&
              variantion.variations[0]?.reference
                ? variantion.variations[0].reference
                : normalized.alternative_code && variantion.primary_color_cod
                ? `${normalized.alternative_code}${(
                    "0000" + variantion.primary_color_cod
                  ).slice(-4)}`
                : "-",
            primary_color: variantion.primary_color_cod
              ? ("0000" + dataUndefined(variantion.primary_color_cod)).slice(-4)
              : "-",
            secondary_color: variantion.secondary_color_cod
              ? ("0000" + dataUndefined(variantion.secondary_color_cod)).slice(
                  -4
                )
              : "-",
          }));
        } else {
          normalized.variations = [];
        }

        setDatasheet(normalized);
      } catch (error) {
        console.log(error);
        return history.push("/404");
      }
    })();
  }, [history, match]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<QualityNqa[]>(
          `/headerDatasheet/datasheet/qualities/nqa/list`
        );
        setNqa(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    formik.setFieldValue("sampling", qualityProducts.length);

    formik.setFieldValue(
      "samplingMinimum",
      qualityProducts.filter((f) => f.defects.length > 0).length
    );

    if (Number(validSampling.missingQuantity) <= 0) {
      if (validSampling.reject) {
        formik.setFieldValue("status", "3");
      } else {
        formik.setFieldValue("status", "1");
      }
    } else {
      formik.setFieldValue("status", "");
    }

    // eslint-disable-next-line
  }, [qualityProducts]);

  useEffect(() => {
    if (datasheet && datasheet?.variations && datasheet.variations.length > 0) {
      const oc_total_quantity = datasheet.variations.reduce((acc, value) => {
        if (value.quantity_quality) {
          return acc + value.quantity_quality;
        }
        return acc;
      }, 0);

      formik.setFieldValue("oc_total_quantity", oc_total_quantity);
    }
    // eslint-disable-next-line
  }, [datasheet.variations]);

  const validSampling = validQtdSampling(
    nqa,
    qualityProducts,
    Number(formik.values.oc_total_quantity)
  );

  async function handleCreateColor(quality: QualityCreateProps) {
    try {
      const normalizedSendQualityProducts = qualityProducts.map((product) => ({
        ...product,

        defects: product.defects.map((defect) => ({ id: defect.id })),
        files: product.files
          .filter((f) => f.uploaded)
          .map((file) => ({ id: file.id })),
      }));

      if (!validSampling) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (Number(validSampling?.missingQuantity) >= 1) {
        return addToast(
          `Faltam ${validSampling?.missingQuantity} amostragem para realizar finalização.`,
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }

      await createQuality.mutateAsync({
        ...quality,
        oc: String(quality.oc),
        nqa_accpet: validSampling.nqa?.accpet ?? 0,
        nqa_reject: validSampling.nqa?.reject ?? 0,
        qualityProducts: normalizedSendQualityProducts,
        variations: datasheet.variations.map((variation) => ({
          id: String(variation.id),
          qtd: variation.quantity_quality,
        })),
      });

      addToast("Relatório de Auditoria alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push(
        `/admin/cabecalho-ficha-tecnica/${match.params.headerId}/ficha-tecnica/${match.params.datasheetId}`
      );
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleRemoveProduct(product: ProductQuality) {
    setQualityProducts((state) => {
      const filters = state.filter((f) => f.id !== product.id);

      return filters;
    });

    for (const file of product.files) {
      if (file.uploaded) {
        await api.post(
          `/headerDatasheet/datasheet/qualities/file/remove/${file.id}`
        );
      }
    }
  }

  function handleOnChangeVariations(
    variation: DatasheetVariationProps,
    qtd: number
  ) {
    setDatasheet((state) => ({
      ...state,
      variations: state.variations.map((variationState) => {
        if (variationState.id === variation.id) {
          return { ...variationState, quantity_quality: qtd };
        }
        return { ...variationState };
      }),
    }));
  }

  function validQtdSampling(
    nqaData: QualityNqa[],
    qualityProductsData: ProductQuality[],
    oc_total_quantity: number
  ) {
    const findNqa = nqaData.find((f) => oc_total_quantity <= f.qtdMax);

    if (findNqa && oc_total_quantity > 0) {
      const samplingNormalized =
        findNqa.sampling > oc_total_quantity
          ? oc_total_quantity
          : findNqa.sampling;
      const missingQuantity = samplingNormalized - qualityProductsData.length;

      const reject =
        qualityProductsData.filter((f) => f.defects.length > 0).length >=
        findNqa.reject
          ? true
          : false;

      return {
        reject,
        missingQuantity,
        nqa: findNqa,
      };
    }

    return {};
  }

  return (
    <>
      <CreateAndDetailLayout isLoading={!datasheet}>
        <PanelAndDetailAndCreateHeader
          title={"Relatório de Auditoria"}
          goBack
        />

        <LayoutForm onSubmit={formik.handleSubmit}>
          <Input
            readOnly
            label="Referência"
            name="alternative_code"
            value={datasheet.alternative_code}
          />
          <Select
            label="Tipo de Auditoria"
            {...formik.getFieldProps("inspectionType")}
            data={[
              {
                name: "Inspeção final",
                value: "1",
              },
              {
                name: "Reinspeção",
                value: "2",
              },
              {
                name: "Inspeção em processos",
                value: "3",
              },
            ]}
            error={
              formik.touched.inspectionType && formik.errors.inspectionType
                ? formik.errors.inspectionType
                : undefined
            }
          />

          <GroupInput>
            <Select
              label="Auditor(a)"
              {...formik.getFieldProps("inspectorId")}
              data={[
                {
                  name: `${UserData.name} ${UserData.lastname}`,
                  value: String(UserData.id),
                },
              ]}
              error={
                formik.touched.inspectorId && formik.errors.inspectorId
                  ? formik.errors.inspectorId
                  : undefined
              }
            />

            <Select
              label="Fornecedor"
              {...formik.getFieldProps("providerId")}
              data={
                datasheet.providers
                  ? datasheet.providers?.map((provider) => ({
                      name: provider.fullname ?? "",
                      value: String(provider.id),
                    }))
                  : []
              }
              error={
                formik.touched.providerId && formik.errors.providerId
                  ? formik.errors.providerId
                  : undefined
              }
            />
          </GroupInput>

          <Input
            label="O.C"
            type="number"
            {...formik.getFieldProps("oc")}
            error={
              formik.touched.oc && formik.errors.oc
                ? formik.errors.oc
                : undefined
            }
          />

          <h3>
            Variantes de Cor{" "}
            <span style={{ fontWeight: "lighter", fontSize: "0.765rem" }}>
              (Informar quantidades)
            </span>
          </h3>
          <GroupInput>
            {datasheet?.variations?.map((variation) => {
              return (
                <Input
                  key={variation.id}
                  styleField={{ minWidth: 80 }}
                  label={`${variation.primary_color}`}
                  name={String(variation.id)}
                  onChange={(e) =>
                    handleOnChangeVariations(variation, Number(e.target.value))
                  }
                  value={variation.quantity_quality}
                  type="number"
                />
              );
            })}
          </GroupInput>

          <GroupInput>
            <Input
              label="Quantidade total de itens"
              readOnly
              type="number"
              {...formik.getFieldProps("oc_total_quantity")}
              error={
                formik.touched.oc_total_quantity &&
                formik.errors.oc_total_quantity
                  ? formik.errors.oc_total_quantity
                  : undefined
              }
            />
          </GroupInput>

          <GroupInput
            style={{
              marginBottom: 0,
            }}
          >
            <ButtonSubmit
              // disabled={!formik.values.reference}
              onClick={() => setActiveProductModal(true)}
              type="button"
              style={{
                background: "#4169e1",
                margin: 0,
                marginBottom: "1.5rem",
              }}
            >
              <HiOutlineClipboardCheck
                style={{ color: "#fff", fontSize: "2rem", marginRight: 10 }}
              />
              INSPECIONAR PRODUTO
            </ButtonSubmit>
          </GroupInput>

          <h3>{`Lista de Produtos (${qualityProducts.length}) ${
            validSampling &&
            validSampling?.missingQuantity &&
            validSampling.missingQuantity >= 0
              ? `- Faltam ${validSampling.missingQuantity} amostras`
              : ""
          }`}</h3>

          <ContainerTabletSelect
            style={{ maxHeight: "30vh", marginBottom: 20, marginTop: 0 }}
          >
            <TabletSelect>
              <thead>
                <tr>
                  <th>Ref/Tamanho/Cor</th>
                  <th>EAN</th>
                  <th>EAN Válido</th>
                  <th>Defeitos</th>
                  {<th>Ação</th>}
                </tr>
              </thead>

              <tbody>
                {qualityProducts.map((product) => (
                  <tr key={product.id}>
                    <td>
                      <div>
                        <div style={{ marginBottom: 3 }}>
                          {product.reference ?? "-"}
                        </div>
                        <div>
                          {product.size
                            ? `${product.size} - ${product.primaryColor}`
                            : "-"}
                        </div>
                      </div>
                    </td>
                    <td>{product.barcode}</td>
                    <td>{product.approvalBarcode ? "SIM" : "NÃO"}</td>
                    <td>
                      {product.defects.length > 0
                        ? product.defects.map((i) => i.description).join()
                        : "-"}
                    </td>
                    {
                      <td>
                        <button
                          type="button"
                          className="action-button"
                          onClick={() => handleRemoveProduct(product)}
                        >
                          <IoMdClose />
                        </button>
                      </td>
                    }
                  </tr>
                ))}
              </tbody>
            </TabletSelect>
            {qualityProducts.length < 1 && (
              <RowNone>Não há registros a serem exibidos</RowNone>
            )}
          </ContainerTabletSelect>

          <GroupInput>
            <Input
              label="Amostragem"
              readOnly
              {...formik.getFieldProps("sampling")}
              error={
                formik.touched.sampling && formik.errors.sampling
                  ? formik.errors.sampling
                  : undefined
              }
            />

            <Input
              label="Aceita/Não aceita"
              readOnly={true}
              value={
                validSampling && Number(validSampling?.missingQuantity) <= 0
                  ? validSampling.reject
                    ? "Não aceita"
                    : "Aceita"
                  : "-"
              }
              name="acceptedSampling"
            />
          </GroupInput>

          <Select
            label="Situação"
            {...formik.getFieldProps("status")}
            data={[
              {
                name: "APROVADO",
                value: "1",
              },
              {
                name: "REPROVADO",
                value: "3",
              },
            ]}
            error={
              formik.touched.status && formik.errors.status
                ? formik.errors.status
                : undefined
            }
          />

          <Textarea
            label="Observações"
            {...formik.getFieldProps("observation")}
          />

          <ButtonSubmit>Finalizar</ButtonSubmit>
        </LayoutForm>
      </CreateAndDetailLayout>

      <Modal
        modalVisible={activeProductModal}
        setModalVisible={setActiveProductModal}
        maxHeight={"95%"}
        maxWidth={"1200px"}
        title={`Referência ${datasheet.alternative_code} ${
          validSampling &&
          validSampling?.missingQuantity &&
          validSampling.missingQuantity >= 0
            ? `- Faltam ${validSampling.missingQuantity} amostras`
            : ""
        }`}
        scrollOff
      >
        <ModalQualityProduct
          data={datasheet}
          qualityProducts={qualityProducts}
          setQualityProducts={setQualityProducts}
          onClose={() => setActiveProductModal(false)}
        />
      </Modal>
    </>
  );
};

export default Quality;
