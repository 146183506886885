import React from "react";
import { FieldProps } from "../../../@types/panelTypes";
import { BoxAnalyticCharArea } from "../../../components/Analytic/BoxAnalyticCharArea";
import { BoxAnalyticCharDonut } from "../../../components/Analytic/BoxAnalyticCharDonut";
import { BoxAnalyticCount } from "../../../components/Analytic/BoxAnalyticCount";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { TableContent } from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import { useAccessAnalytic } from "../../../hook/queries/useAccessAnalytic";
import { ContainerBoxAnalytic, SubTitle } from "../styles";

const Access = () => {
  const accessAnalytic = useAccessAnalytic();

  const columns: FieldProps[] = [
    {
      name: "cod",
      field: "Cód",
      viewList: true,
    },
    {
      name: "abbreviation",
      field: "Nome",
      viewList: true,
    },
  ];

  return (
    <CreateAndDetailLayout isLoading={accessAnalytic?.isLoading}>
      <PanelAndDetailAndCreateHeader title={"Análise de acessos"} goBack />

      <SubTitle>Clientes</SubTitle>

      <ContainerBoxAnalytic style={{ marginTop: 24, marginBottom: 0 }}>
        <BoxAnalyticCount
          description="Total de acessos"
          qtd={accessAnalytic.data?.totalAccessClientLastDays ?? 0}
          isLoading={accessAnalytic.isLoading}
          obs={`Últimos ${accessAnalytic.data?.lastDays ?? "-"} dias.`}
          isNotColor
          minWidth={"auto"}
        />

        <BoxAnalyticCount
          description="Média de acessos por dia"
          qtd={accessAnalytic.data?.dayMediaClient ?? 0}
          isLoading={accessAnalytic.isLoading}
          obs={`Últimos ${accessAnalytic.data?.lastDays ?? "-"} dias.`}
          isNotColor
          minWidth={"auto"}
        />

        <BoxAnalyticCount
          description="Quantos clientes já acessaram o portal"
          qtd={accessAnalytic.data?.totalClientAccess ?? 0}
          isLoading={accessAnalytic.isLoading}
          obs={`Ao menos uma vez.`}
          isNotColor
          minWidth={"auto"}
        />
      </ContainerBoxAnalytic>

      <ContainerBoxAnalytic style={{ marginTop: 9 }}>
        <BoxAnalyticCharArea
          title="Acessos mês"
          dates={accessAnalytic?.data?.accessThreeMonthsClient.date ?? []}
          values={accessAnalytic?.data?.accessThreeMonthsClient.content ?? []}
          typeDate="number"
          obs={<>* Range dos últimos 3 meses.</>}
        />
      </ContainerBoxAnalytic>

      <SubTitle style={{ marginTop: "3rem" }}>Representante</SubTitle>

      <ContainerBoxAnalytic style={{ marginTop: 24, marginBottom: 0 }}>
        <BoxAnalyticCount
          description="Total de acessos"
          qtd={accessAnalytic.data?.totalAccessSellerLastDays ?? 0}
          isLoading={accessAnalytic.isLoading}
          obs={`Últimos ${accessAnalytic.data?.lastDays ?? "-"} dias.`}
          isNotColor
          minWidth={"auto"}
        />

        <BoxAnalyticCount
          description="Média de acessos por dia"
          qtd={accessAnalytic.data?.dayMediaSeller ?? 0}
          isLoading={accessAnalytic.isLoading}
          obs={`Últimos ${accessAnalytic.data?.lastDays ?? "-"} dias.`}
          isNotColor
          minWidth={"auto"}
        />

        <BoxAnalyticCount
          description="Quantos representantes já acessaram o portal"
          qtd={accessAnalytic.data?.totalSellerAccess ?? 0}
          isLoading={accessAnalytic.isLoading}
          obs={`Ao menos uma vez.`}
          isNotColor
          minWidth={"auto"}
        />
      </ContainerBoxAnalytic>

      <ContainerBoxAnalytic style={{ marginTop: 9 }}>
        <BoxAnalyticCharArea
          title="Acessos mês"
          dates={accessAnalytic?.data?.accessThreeMonthsSeller.date ?? []}
          values={accessAnalytic?.data?.accessThreeMonthsSeller.content ?? []}
          typeDate="number"
          obs={<>* Range dos últimos 3 meses.</>}
        />

        <BoxAnalyticCharDonut
          title="Comparação de representantes acessaram e nunca acessaram"
          values={[
            {
              name: "Acessaram",
              length: accessAnalytic.data?.sellerActiveAccess ?? 0,
            },
            {
              name: "Nunca Acessaram",
              length: accessAnalytic.data?.sellerNeverAccess.length ?? 0,
            },
          ]}
          obs={<>*Representantes ativos.</>}
        />
      </ContainerBoxAnalytic>

      <TableHeader
        title={`Representantes nunca acessaram (${accessAnalytic.data?.sellerNeverAccess.length})`}
        style={{ marginTop: "2rem" }}
      />

      <TableContent
        data={accessAnalytic?.data?.sellerNeverAccess}
        columns={columns.filter((f) => f.viewList)}
        isLoading={accessAnalytic.isLoading}
        height={"30vh"}
      />

      <div style={{ height: "3rem", width: "100%", background: "#fff" }}>
        <span></span>
      </div>

      {/* <ContainerBoxAnalytic style={{ marginTop: 9 }}>
        <BoxAnalyticCharBar
          title="Acessos"
          descriptions={orderAnalytic?.data?.totalAmount.description ?? []}
          values={orderAnalytic?.data?.totalAmount.amount ?? []}
          colors={orderAnalytic?.data?.totalAmount.description.map((s) => {
            return statusColorAnalytic.find((f) => f.name === s)?.color ?? "";
          })}
          obs={<>Últimos 3 meses.</>}
        />
      </ContainerBoxAnalytic> */}
    </CreateAndDetailLayout>
  );
};

export default Access;
