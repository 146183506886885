import React, { useState, useEffect } from "react";

import moment from "moment";
import Loading from "../../../components/loadings/Loading";
//@ts-ignore
import JSONPretty from "react-json-prettify";

import {
  Container,
  InfoDevolution,
  InfoStatus,
  Infogroup,
  PaymentDetailCard,
  BodyPaymentDetailCard,
  HeaderPaymentDetailCard,
  ScreenLoading,
} from "../styles";
// import { AiFillCloseCircle } from "react-icons/ai";
import { FaArrowLeft } from "react-icons/fa";
import { CgDetailsMore } from "react-icons/cg";

import { useHistory } from "react-router-dom";
import api from "../../../service/api";
import { dataUndefined } from "../../../utils/dataProcessing";

interface HomeProps {
  location: {
    state: {
      content: IPayment;
    };
  };

  match: {
    params: {
      id: number;
    };
  };

  admin: boolean;
}

interface IPaymentDetail {
  status: string;
  status_color: string;
  authorization_code: string;
  authorized_at: Date;
  transaction_id: string;
  terminal_nsu: string;
  purchaser: string;
  reason_message: string;
  error_message: string;
}

interface IPayment {
  id: number;
  created_at: Date;
  status: string;
  status_color: string;
  amount: number;
  card_brand: string;
  card_number: string;
  card_name: string;
  paid: boolean;
  payment_detail: IPaymentDetail[];
  custumer: {
    name: string | null;
    email: string;
    phone_number: string;
    document_number: string;
    adresses: {
      street: string;
      numberStreet: string;
      zip_code: string;
      uf: string;
      city: string;
    };
  };
  client_pj?: {
    cod: string;
    company_name: string;
    trade_name: string;
    cnpj: string;
  };
  debt?: {
    codeOrder: string;
  };
}

const Payment: React.FC<HomeProps> = ({ location, admin, match }) => {
  const history = useHistory();

  const [contentPage, setContentPage] = useState<IPayment | null>(null);
  // const [update, setUpdate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [detailError, setDetailError] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (Boolean(location) /* && !update*/) {
          setContentPage(location.state.content);
        }
        throw new Error("update");
      } catch (error) {
        if (Boolean(match.params.id)) {
          try {
            const showPayment = await api.get<IPayment>(
              `/payment/${match.params.id}`
            );

            setLoading(false);

            if (!Boolean(showPayment.data)) {
              return history.push("/404");
            }

            setContentPage(showPayment.data);
          } catch (error) {
            return history.push("/404");
          }
        } else {
          history.push("/404");
        }
      }
    })();
  }, [location, history, /*update, */ match]);

  // async function hadleCancel(id: Number) {
  //   setLoading(true);
  //   try {
  //     await api.post(`/payment/canceled/${id}`);
  //     setUpdate(true);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // }

  return (
    <Container>
      {contentPage !== null && !loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Detalhes da negociação</h2>
          </header>

          <InfoStatus>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>CÓDIGO DA NEGOCIAÇÃO REALIZADA</h5>
                </div>
                <p>{contentPage.id}</p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>SITUAÇÃO</h5>
                </div>
                <p>
                  <span
                    style={{
                      backgroundColor: contentPage.status_color,
                    }}
                  >
                    {contentPage.status}
                  </span>
                </p>
              </div>
            </div>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>VALOR</h5>
                </div>
                <p>
                  {Number(contentPage.amount / 100).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>CÓDIGO DO PEDIDO</h5>
                </div>
                <p>{contentPage.debt?.codeOrder}</p>
              </div>
            </div>
          </InfoStatus>

          <InfoDevolution>
            <div className="headerDevoltion">
              <h2>Informações</h2>

              {/* {contentPage.paid && admin && (
                <div className="containerActions">
                  <button className="buttonActions" type="button">
                    Ações
                  </button>
                  <ul className="itemsActions">
                    <li
                      onClick={() => {
                        hadleCancel(contentPage.id);
                      }}
                    >
                      <AiFillCloseCircle /> CANCELAR PAGAMENTO
                    </li>
                  </ul>
                </div>
              )} */}
            </div>

            <div className="bodyDevoltion">
              {admin && (
                <div className="conatinerInfo">
                  <h3>Cliente</h3>
                  <div className="line"></div>

                  <div>
                    <h4>CÓDIGO CLIENTE</h4>
                    <span>{contentPage.client_pj?.cod}</span>

                    <h4>CNPJ</h4>
                    <span>{contentPage.client_pj?.cnpj}</span>

                    <h4>RAZÃO SOCIAL</h4>
                    <span>{contentPage.client_pj?.company_name}</span>

                    <h4>NOME FANTASIA</h4>
                    <span>{contentPage.client_pj?.trade_name}</span>
                  </div>
                </div>
              )}

              <div className="conatinerInfo">
                <h3>Dados do titular pagamento</h3>
                <div className="line"></div>

                <div>
                  <h4>Nome completo</h4>
                  <span>{contentPage.custumer.name}</span>
                  <h4>Documento</h4>
                  <span>{contentPage.custumer.document_number}</span>
                  <h4>Celular</h4>
                  <span>{contentPage.custumer.phone_number}</span>
                  <h4>E-Mail</h4>
                  <span>{contentPage.custumer.email}</span>
                </div>
              </div>

              <div className="conatinerInfo">
                <h3>Endereços de cobrança</h3>
                <div className="line"></div>

                <div>
                  <h4>CEP </h4>
                  <span>{contentPage.custumer.adresses.zip_code}</span>
                  <h4>UF </h4>
                  <span>{contentPage.custumer.adresses.uf}</span>
                  <h4>Cidade </h4>
                  <span>{contentPage.custumer.adresses.city}</span>
                  <h4>Endereço </h4>
                  <span>{`${contentPage.custumer.adresses.street}, ${contentPage.custumer.adresses.numberStreet}`}</span>
                </div>
              </div>
            </div>
          </InfoDevolution>

          <Infogroup>
            <InfoDevolution style={{ gridArea: "FP" }}>
              <div className="headerDevoltion">
                <h2>Forma de pagamento</h2>
              </div>
              <div className="bodyDevoltion">
                <div className="conatinerInfo">
                  <div>
                    <h4>CARTÃO DE CRÉDITO</h4>
                    <span>{contentPage.card_brand}</span>
                  </div>
                  <div>
                    <h4>Nome</h4>
                    <span>{contentPage.card_name}</span>
                  </div>
                  <div>
                    <h4>Número</h4>
                    <span>**** **** **** {contentPage.card_number}</span>
                  </div>
                </div>
              </div>
            </InfoDevolution>

            <InfoDevolution style={{ gridArea: "HT" }}>
              <div className="headerDevoltion">
                <h2>Histórico de transações</h2>
              </div>
              {contentPage.payment_detail.map((element) => (
                <PaymentDetailCard>
                  <HeaderPaymentDetailCard>
                    <div>
                      <h4
                        style={{
                          color: element.status_color,
                        }}
                      >
                        {element.status}
                      </h4>
                      <span>
                        {moment(element.authorized_at).format(
                          "DD/MM/YYYY - hh:mm:ss"
                        )}
                      </span>
                    </div>
                  </HeaderPaymentDetailCard>
                  <BodyPaymentDetailCard>
                    <div>
                      <h4>Transaction Id</h4>
                      <span>{dataUndefined(element.transaction_id)}</span>
                    </div>
                    <div>
                      <h4>Adquirente</h4>
                      <span>{dataUndefined(element.purchaser)}</span>
                    </div>

                    <div>
                      <h4>NSU</h4>
                      <span>{dataUndefined(element.terminal_nsu)}</span>
                    </div>
                    <div>
                      <h4>Cód. de autorização</h4>
                      <span>{dataUndefined(element.authorization_code)}</span>
                    </div>

                    {element.error_message && admin && (
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            setDetailError(!detailError);
                          }}
                        >
                          <h4>Detalhes da requisição</h4>
                          <CgDetailsMore size={20} />
                        </button>

                        {detailError && (
                          <span>
                            {element.error_message ? (
                              <JSONPretty
                                style={{ borderRadius: 8 }}
                                json={JSON.parse(element.error_message)}
                              />
                            ) : (
                              "-"
                            )}
                          </span>
                        )}
                      </div>
                    )}
                  </BodyPaymentDetailCard>
                </PaymentDetailCard>
              ))}
            </InfoDevolution>
          </Infogroup>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Payment;
