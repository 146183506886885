import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { FaArrowLeft, FaTrashAlt } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { IoIosBarcode } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { IPermissionUser } from "../../../@types";
import { HeaderTab } from "../../../components/HeaderTab";
import Loading from "../../../components/loadings/Loading";
import Modal from "../../../components/ModalLib";
import {
  IProvider,
  ModalModificationDatasheet,
} from "../../../components/ModalModificationDatasheet";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import api from "../../../service/api";
import { dataUndefined } from "../../../utils/dataProcessing";
import { Container, PopUpConfirmation, ScreenLoading } from "../styles";
import { DatasheetActionsChat, IMessage } from "./Actions/Chat";
import { DatasheetActionsDetail } from "./Actions/Detail";
import { DatasheetActionsFile } from "./Actions/File";
import { DatasheetActionsQuality } from "./Actions/Quality";
import { BodyTab } from "./styles";

interface HomeProps {
  location: {
    state: {
      search?: string;
    };
  };

  match: {
    params: {
      idDatasheet?: number;
      idHeaderDatasheet?: number;
    };
  };

  admin?: boolean;
  seller: boolean;
  provider: boolean;

  permission?: IPermissionUser;
}

interface HeaderDatasheetProps {
  id: number;
  collection: string;
  year: number;

  finished: boolean;
  finished_at: Date | null;
  created_at: Date;

  status: {
    cod: number;
    name: string;
    color: string;
    finisher: boolean;
  };

  brand: IBrand;
}

interface IBrand {
  id: number;
  cod: number;
  name: string;
  initials?: string;
}

interface IGridDb {
  id: number;
  size: {
    description: string;
    id: number;
  };
  sizesProductsId: number;
}

export interface DatasheetProps {
  id: number;
  prioritize_variation_book_id: number;
  codGridSankhya?: number;
  headerDatasheetId: number;
  description: string;
  bar_code: string;
  alternative_code: string;

  composition: string;
  characteristics: string;
  created_at: Date;
  tissue: string;

  minimum?: number;
  price_cost: number;
  price_sale: number;
  pre_price_cost: number;
  pre_price_sale: number;

  barcode_ncm: number;
  barcode_cest: number;
  barcode_gpc: number;

  weight_net: number;
  weight_gross: number;

  isFinished: boolean;
  isCarryOver: boolean;
  isGeneratedBarcode: boolean;
  isGeneratedBarcodeSample: boolean;
  isClassic: boolean;
  datasheetId: boolean;

  product_group?: {
    id: number;
    name: string;
  };

  genre?: {
    id: number;
    name: string;
  };
  sleeve?: {
    id: number;
    name: string;
  };

  product_type?: {
    id: number;
    name: string;
  };
  product_origin?: {
    id: number;
    name: string;
  };
  age_type?: {
    id: number;
    name: string;
  };

  product_line?: {
    id: number;
    name: string;
  };
  unit_measure?: {
    id: number;
    initials: string;
    name: string;
  };
  material?: {
    id: number;
    cod: number;
    description: number;
  };
  material_nature?: {
    id: number;
    cod: number;
    description: number;
  };
  modeling?: {
    id: number;
    cod: number;
    description: number;
  };
  category?: {
    id: number;
    cod: number;
    description: number;
  };

  price_classification?: {
    id: number;
    name: string;
  };

  grids: IGridDb[];

  measurement_table: DatasheetTableProps;
  providers: IProvider[];
  provider: IProvider[];

  status: {
    cod: number;
    name: string;
    color: string;
    finisher: boolean;
    is_generate_barcode: boolean;
    is_generate_barcode_sample: boolean;
    is_generate_book: boolean;

    responsibles: {
      userId: string;
    }[];
    permission_fields: {
      name: string;
    }[];
    required_fields: {
      field: {
        name: string;
      };
    }[];
  };

  variations: DatasheetVariationProps[];

  files: FileProps[];
}

export interface DatasheetTableProps {
  id: number;
  unit_measurement: string;
  description_measured: string;

  measurement_data: DatasheetTableDataProps[];
}
export interface DatasheetTableDataProps {
  id: number;
  description: string;
  size: number;

  tableId?: number;
}

export interface DatasheetVariationProps {
  id: number;
  reference: string;
  primary_color: string;
  secondary_color?: string;
  primary_color_hex?: string;
  primary_color_cod: number;
  secondary_color_cod?: number;
  primary_color_name?: string;
  secondary_color_name?: string;

  quantity_quality?: number;

  is_drop?: boolean;

  variations?: {
    id: string;
    reference?: string;
    barcode: string;
    size: string;
  }[];
}

export interface FileProps {
  id: number;
  name: string;
  url: string;
  isLink: boolean;
  key: string;
  mimetype: string;
  size: number;
  created_at: Date;
  category_file: CategoryFileProps;
}

export interface GridProps {
  id: number;
  name: string;
  sizeId: number;
}

interface CategoryFileProps {
  id: number;
  name: string;
}

export interface HeaderDatasheetAndDatasheet extends DatasheetProps {
  header_datasheet: HeaderDatasheetProps;
}

const Datasheet: React.FC<HomeProps> = ({
  match,
  admin,
  provider,
  permission,
  location,
}) => {
  const history = useHistory();
  const query = useQueryParams();
  const { addToast } = useToasts();

  const [contentPage, setContentPage] = useState<HeaderDatasheetAndDatasheet>(
    {} as HeaderDatasheetAndDatasheet
  );
  const [oldSearch, setOldSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [update, setUpdate] = useState(1);
  const [activeUpdateDatasheetModal, setActiveUpdateDatasheetModal] =
    useState<boolean>(false);

  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [confirmationGenerateBarcode, setConfirmationGenerateBarcode] =
    useState(false);
  const [loadingModalGenerateBarcode, setLoadingModalGenerateBarcode] =
    useState(false);

  const [keyActiveTab, setKeyActiveTab] = useState<
    "detail" | "file" | "chat" | "quality"
  >(() => {
    const optionQuery = query.get("option");

    if (optionQuery) {
      switch (String(optionQuery)) {
        case "detail":
          return "detail";

        case "file":
          return "file";

        case "chat":
          return "chat";

        case "quality":
          if (
            permission?.datasheetQuality &&
            permission?.datasheetQuality > 1
          ) {
            return "quality";
          } else {
            return "detail";
          }

        default:
          return "detail";
      }
    }

    return "detail";
  });

  const [chat, setChat] = useState<IMessage[]>([]);

  useEffect(() => {
    setLoading(true);

    (async () => {
      try {
        if (
          !Boolean(match.params.idHeaderDatasheet) ||
          !Boolean(match.params.idDatasheet)
        ) {
          history.push("/404");
        }

        if (!Boolean(admin) && !Boolean(provider)) history.push("/404");

        const headerDatasheetDB = await api.get<HeaderDatasheetAndDatasheet>(
          `/headerDatasheet/datasheet/show/${match.params.idDatasheet}`
        );
        const messagesApi = await api.get<IMessage[]>(
          `/headerDatasheet/datasheet/message/${match.params.idDatasheet}`
        );
        setChat(messagesApi.data);

        if (!Boolean(headerDatasheetDB.data)) {
          return history.push("/404");
        }

        var normalized = headerDatasheetDB.data;

        if (normalized.variations?.length > 0) {
          normalized.variations = normalized.variations.map((variantion) => ({
            ...variantion,
            reference:
              variantion.variations &&
              variantion.variations?.length > 0 &&
              variantion.variations[0]?.reference
                ? variantion.variations[0].reference
                : normalized.alternative_code && variantion.primary_color_cod
                ? `${normalized.alternative_code}${(
                    "0000" + variantion.primary_color_cod
                  ).slice(-4)}`
                : "-",
            primary_color: variantion.primary_color_cod
              ? ("0000" + dataUndefined(variantion.primary_color_cod)).slice(-4)
              : "-",
            secondary_color: variantion.secondary_color_cod
              ? ("0000" + dataUndefined(variantion.secondary_color_cod)).slice(
                  -4
                )
              : "-",
          }));
        } else {
          normalized.variations = [];
        }

        setContentPage(normalized);
        setLoading(false);
      } catch (error) {
        console.log(error);
        return history.push("/404");
      }
    })();

    // eslint-disable-next-line
  }, [history, admin, match, update]);

  useEffect(() => {
    if (loadingModalGenerateBarcode) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [loadingModalGenerateBarcode]);

  useEffect(() => {
    setQueryParams({
      data: {
        field: "option",
        value: String(keyActiveTab),
      },
      type: "set",
      history,
    });
  }, [keyActiveTab, history]);

  useEffect(() => {
    if (location.state?.search) {
      setOldSearch("?" + location.state.search);
    }

    // eslint-disable-next-line
  }, []);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "As alterações feitas podem não ser salvas.";
  };

  async function handleSendMessage(message: string, sendUser: string) {
    let newMessage: IMessage = {
      name: sendUser,
      textMessage: message,
      typeUser: admin ? "admin" : "provider",
    };

    await api.post(
      `/headerDatasheet/datasheet/message/${contentPage.id}`,
      newMessage
    );

    setChat([
      {
        id: chat.length + 1,
        created_at: new Date(),
        ...newMessage,
      },
      ...chat,
    ]);
  }

  async function handleGenerateBarcode() {
    setLoadingModalGenerateBarcode(true);

    addToast("Gerando EANs, esta ação pode levar alguns instantes.", {
      appearance: "info",
      autoDismiss: true,
    });

    try {
      await api.post(
        `/headerDatasheet/datasheet/generate/barcode/${contentPage.id}`
      );

      setUpdate(update + 1);
      setConfirmationGenerateBarcode(false);
      setLoadingModalGenerateBarcode(false);
      addToast("EANs da Ficha Técnica gerados com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setLoadingModalGenerateBarcode(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleDelete() {
    setLoadingModal(true);

    try {
      await api.delete(`/headerDatasheet/datasheet/${contentPage.id}`);

      setConfirmationDelete(false);
      setLoadingModal(false);
      history.push(
        `/admin/cabecalho-ficha-tecnica/${contentPage.header_datasheet.id}`
      );
      addToast("Ficha Técnica excluído com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setLoadingModal(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <Helmet
        title={`Ficha #${
          contentPage.alternative_code ?? "-"
        } - Portal Alpar do Brasil`}
      />

      {!loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                const [oldPath] =
                  history.location.pathname.split("/ficha-tecnica");

                if (oldPath) {
                  history.push(oldPath + oldSearch);
                }
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Ficha Técnica</h2>

            {admin && permission && permission.datasheet > 2 && (
              <div className="containerActions">
                {contentPage.status.is_generate_barcode && (
                  <button
                    type="button"
                    className="buttonActionDatasheet"
                    onClick={() => {
                      setConfirmationGenerateBarcode(true);
                    }}
                  >
                    {
                      <div>
                        <span>
                          <IoIosBarcode color="#3e6196" />
                        </span>
                        Gerar EANs
                      </div>
                    }
                  </button>
                )}

                <button
                  type="button"
                  className="buttonActionDatasheet"
                  onClick={() => {
                    setActiveUpdateDatasheetModal(true);
                  }}
                >
                  {
                    <div>
                      <span>
                        <FiEdit color="#8f6e09" />
                      </span>
                      Editar
                    </div>
                  }
                </button>

                {!contentPage.header_datasheet.status.finisher && (
                  <button
                    type="button"
                    className="buttonActionDatasheet"
                    onClick={() => {
                      setConfirmationDelete(true);
                    }}
                  >
                    {
                      <div>
                        <span>
                          <RiDeleteBinLine color="#d21e26" />
                        </span>
                        Excluir
                      </div>
                    }
                  </button>
                )}
              </div>
            )}
          </header>

          <HeaderTab
            keyActive={keyActiveTab}
            //@ts-ignore
            setKeyActive={setKeyActiveTab}
            tabs={[
              {
                key: "detail",
                description: "Detalhes",
              },

              {
                key: "file",
                description: "Arquivos",
              },
              {
                key: "chat",
                description: "Bate-papo",
              },
              ...(permission?.datasheetQuality &&
              permission?.datasheetQuality > 1
                ? [{ key: "quality", description: "Qualidade" }]
                : []),
            ]}
          />

          <BodyTab style={{ background: "#fff", width: "100%" }}>
            <DatasheetActionsDetail
              data={{
                ...contentPage,
              }}
              isActive={keyActiveTab === "detail" ? true : false}
              setUpdate={() => setUpdate(update + 1)}
              admin={admin}
            />

            <DatasheetActionsChat
              chat={chat}
              admin={admin}
              provider={provider}
              isActive={keyActiveTab === "chat" ? true : false}
              sendMessage={handleSendMessage}
            />

            <DatasheetActionsFile
              admin={admin}
              provider={provider}
              data={contentPage}
              isActive={keyActiveTab === "file" ? true : false}
              onRequestUpdate={() => setUpdate(update + 1)}
              permission={permission}
            />

            <DatasheetActionsQuality
              admin={admin}
              provider={provider}
              permission={permission}
              data={contentPage}
              isActive={keyActiveTab === "quality" ? true : false}
            />
          </BodyTab>

          {activeUpdateDatasheetModal && (
            <Modal
              title={`Ficha Técnica ${
                contentPage.alternative_code &&
                `- Cód. Alternativo ${contentPage.alternative_code}`
              }`}
              modalVisible={activeUpdateDatasheetModal}
              setModalVisible={setActiveUpdateDatasheetModal}
              maxWidth={1100}
              scrollOff={true}
              maxHeight={650}
              isShouldCloseOnEscAndClickOffline
            >
              <ModalModificationDatasheet
                type="update"
                data={contentPage}
                onRequestClose={() => {
                  setActiveUpdateDatasheetModal(false);
                }}
                onRequestUpdate={() => setUpdate(update + 1)}
                headerDatasheet={{
                  id: contentPage.header_datasheet.id,
                  collection: contentPage.header_datasheet.collection,
                  year: contentPage.header_datasheet.year,
                }}
              />
            </Modal>
          )}

          {confirmationDelete && (
            <Modal
              title="Selecione o cliente"
              modalVisible={confirmationDelete}
              setModalVisible={setConfirmationDelete}
              headerOff={true}
              maxHeight={350}
              maxWidth={650}
            >
              <PopUpConfirmation>
                <div className="headerPopUp">
                  <nav>
                    <FaTrashAlt size={95} opacity={0.2} />
                  </nav>
                  <span>
                    Você tem certeza que deseja excluir esta Ficha Técnica?
                  </span>
                </div>
                <div className="buttonsPopUp">
                  <button
                    type="button"
                    onClick={() => setConfirmationDelete(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    style={{ background: "#d21e26" }}
                    type="button"
                    onClick={() => (loadingModal ? () => {} : handleDelete())}
                  >
                    {!loadingModal ? (
                      "Deletar"
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <Loading
                          size={22}
                          borderSize={4}
                          colorLoading="rgba(255,255,255)"
                          borderColor="rgba(255,255,255, 0.3)"
                        />
                      </div>
                    )}
                  </button>
                </div>
              </PopUpConfirmation>
            </Modal>
          )}

          {confirmationGenerateBarcode && (
            <Modal
              modalVisible={confirmationGenerateBarcode}
              setModalVisible={setConfirmationGenerateBarcode}
              headerOff={true}
              maxHeight={350}
              maxWidth={650}
            >
              <PopUpConfirmation>
                <div className="headerPopUp">
                  <nav>
                    <IoIosBarcode size={95} opacity={0.2} />
                  </nav>
                  <span>
                    Você tem certeza que deseja gerar o EANs desta Ficha
                    Técnica? Após gerar não sera possivel realizar alterações na
                    Ficha Técnica
                  </span>
                </div>
                <div className="buttonsPopUp">
                  <button
                    type="button"
                    onClick={() => setConfirmationGenerateBarcode(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    style={{ background: "#3e6196" }}
                    type="button"
                    onClick={() =>
                      loadingModalGenerateBarcode
                        ? () => {}
                        : handleGenerateBarcode()
                    }
                  >
                    {!loadingModalGenerateBarcode ? (
                      "Gerar EANs"
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <Loading
                          size={22}
                          borderSize={4}
                          colorLoading="rgba(255,255,255)"
                          borderColor="rgba(255,255,255, 0.3)"
                        />
                      </div>
                    )}
                  </button>
                </div>
              </PopUpConfirmation>
            </Modal>
          )}
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Datasheet;
