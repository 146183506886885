import moment from "moment";
import React, { useRef, useState } from "react";

import { useFormik } from "formik";

import { AiOutlineClear } from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { IoReload } from "react-icons/io5";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { APP_LINK } from "../../App";
import useOutsideAlerter from "../../hook/outsideAlerter";
import { Order, OrderFilters, getOrders } from "../../hook/queries/useOrders";
import {
  ExternalLink,
  ExternalLinkA,
  LoadingPanel,
  ReportDownload,
  TableContainer,
} from "../../pages/panel/styles";
import api from "../../service/api";
import { ButtunSubmit } from "../../styles/global";
import { generateXlsx } from "../../utils/generateXlsx";
import { Input } from "../Form/Input";
import { ContainerForm, Form } from "../Form/LayoutForm/styles";
import Modal from "../ModalLib";
import { Popover } from "../Popover";
import Loading from "../loadings/Loading";
import LoadingDot from "../loadings/LoadingDot";
import { TableHeader } from "../panel/table/TableHeader";

interface TableOrdersProps {
  orders: Order[];
  userType: "admin" | "client" | "seller" | "sellerManager";
  filters: OrderFilters;
  setFilters: React.Dispatch<React.SetStateAction<OrderFilters>>;
  formikFilter?: any;
  refetch?: () => void;
  isFetching?: boolean;
  isLoading?: boolean;
  isExportData?: boolean;
}

export const TableOrders = ({
  isFetching = false,
  isLoading = false,
  isExportData = false,
  setFilters,
  filters,
  formikFilter,
  refetch,
  orders,
  userType,
}: TableOrdersProps) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const outSideRefEmail = useRef(null);

  const [isModalSendMail, setIsModalSendMail] = useState(false);
  const [isLoadingSendMail, setIsLoadingSendMail] = useState(false);

  const [optionsSendOrderEmail, setOptionsSendOrderEmail] = useState(false);
  const [optionsSendOrderEmailLoading, setOptionsSendOrderEmailLoading] =
    useState(false);

  const formikSendMail = useFormik({
    validateOnChange: false,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("E-mail invalido").required("Obrigatório"),
    }),

    onSubmit: async (data) => {
      setIsLoadingSendMail(true);

      const { orders: dataResponse } = await getOrders(1, filters, 999999);

      var idSendEmails: number[] = dataResponse.map((order) => order.id);

      for (const item of idSendEmails) {
        await api.post(`/orderSankhya/mail/${item}`, {
          to: {
            email: data.email,
            name: data.email,
          },
        });
      }

      addToast(
        `Pedidos(${idSendEmails.length}) foram enviado com sucesso para e-mail ${data.email}.`,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
      setIsLoadingSendMail(false);
      setIsModalSendMail(false);
    },
  });

  function handleAccessDetail(order: Order) {
    if (userType === "admin") {
      history.push(`/admin/pedidos/${order.id}`);
    }
    if (userType === "seller" || userType === "sellerManager") {
      history.push(`/representante/pedidos/${order.id}`);
    }
    if (userType === "client") {
      history.push(`/pedidos/${order.id}`);
    }
  }

  async function handleSendOrderMails({
    type,
  }: {
    type: "seller" | "client" | "both";
  }) {
    setOptionsSendOrderEmail(false);
    setOptionsSendOrderEmailLoading(true);

    try {
      const { orders: ordersReport } = await getOrders(1, filters, 999999);

      for (const item of ordersReport) {
        try {
          if (type === "client") {
            await api.post(`/orderSankhya/mail/${item.id}`, {
              to: {
                email: item.clients_pj?.email,
                name: item.clients_pj?.company_name,
              },
            });
          }
          if (type === "seller") {
            await api.post(`/orderSankhya/mail/${item.id}`, {
              to: {
                email: item.seller?.email,
                name: item.seller?.fullName,
              },
            });
          }
          if (type === "both") {
            await api.post(`/orderSankhya/mail/${item.id}`, {
              to: {
                email: item?.clients_pj?.email,
                name: item?.clients_pj?.company_name,
              },
              cp: item.seller
                ? {
                    email: item.seller?.email,
                    name: item.seller?.fullName,
                  }
                : undefined,
            });
          }
        } catch (error) {
          addToast(`Erro ao enviar pedidos ${item.cod}.`, {
            appearance: "warning",
            autoDismiss: true,
          });
        }
      }

      addToast(
        `Pedidos(${orders.length}) foram enviado com sucesso para e-mail.`,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );

      setOptionsSendOrderEmailLoading(false);
    } catch (error) {
      setOptionsSendOrderEmailLoading(false);

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function generateReport(type: number) {
    try {
      addToast(`Exportando dados.`, {
        appearance: "info",
        autoDismiss: true,
      });

      if (type === 1) {
        const { orders: ordersReport } = await getOrders(1, filters, 999999);
        if (!ordersReport) throw new Error("Sem dados");

        if (userType === "admin" || userType === "sellerManager") {
          const data = ordersReport.map((item) => ({
            // eslint-disable-next-line
            ["CÓD. PEDIDO"]: item.cod,

            // eslint-disable-next-line
            ["CLIENTE"]: item.clients_pj?.company_name,
            // eslint-disable-next-line
            ["REP."]: item.seller?.cod
              ? `${item.seller?.cod} ${item.seller?.abbreviation}`
              : "-",
            // eslint-disable-next-line
            ["MARCA"]: item.brands?.name,
            // eslint-disable-next-line
            ["STATUS DO PEDIDO"]: item?.status?.description,
            // eslint-disable-next-line
            ["MOTIVO CANCELAMENTO"]:
              item?.pointedCod &&
              item?.pointedCod !== "undefined" &&
              item.status?.isErrorValid
                ? `${item?.pointedCod} ${item?.pointedDesc}`
                : "-",
            // eslint-disable-next-line
            ["MOTIVO RECUSA"]:
              item?.refuseCod &&
              item?.refuseCod !== "undefined" &&
              item.status?.isAlertValid
                ? `${item?.refuseCod} ${item?.refuseDesc}`
                : "-",
            // eslint-disable-next-line
            ["TIPO"]:
              Number(item?.top) === 901 || item.species === 9
                ? "Venda Futura"
                : [1125, 1123].includes(Number(item?.top)) || item.species !== 9
                ? "Pronta entrega"
                : "-",
            // eslint-disable-next-line
            ["DIGITAÇÃO PEDIDO"]: moment(item.deliveryDate).format(
              `DD/MM/YYYY`
            ),
            // eslint-disable-next-line
            ["FATURAMENTO"]: moment(item.billingDate).format(
              Number(item?.top) === 901 || item.species === 9
                ? `MM/YYYY`
                : `DD/MM/YYYY`
            ),
            // eslint-disable-next-line
            ["VALOR TOTAL"]: item.merchandiseValue,
          })) as any[];
          await generateXlsx<object[]>({
            filename: `Pedidos`,
            data,
          });
        } else {
          const data = ordersReport.map((item) => ({
            // eslint-disable-next-line
            ["CÓD. PEDIDO"]: item.cod,
            // eslint-disable-next-line
            ["Nº NOTA FISCAL"]: item.documentNumber ?? "-",
            // eslint-disable-next-line
            ["CLIENTE"]: item.clients_pj?.company_name,
            // eslint-disable-next-line
            ["REP."]: item.seller?.cod,
            // eslint-disable-next-line
            ["MARCA"]: item.brands?.name,
            // eslint-disable-next-line
            ["STATUS DO PEDIDO"]: item?.status?.description,
            // eslint-disable-next-line
            ["MOTIVO CANCELAMENTO"]:
              item?.pointedCod && item?.pointedCod !== "undefined"
                ? `${item?.pointedCod} ${item?.pointedDesc}`
                : "-",
            // eslint-disable-next-line
            ["TIPO"]:
              Number(item?.top) === 901 || item.species === 9
                ? "Venda Futura"
                : [1125, 1123].includes(Number(item?.top)) || item.species !== 9
                ? "Pronta entrega"
                : "-",
            // eslint-disable-next-line
            ["DIGITAÇÃO PEDIDO"]: moment(item.deliveryDate).format(
              `DD/MM/YYYY`
            ),
            // eslint-disable-next-line
            ["FATURAMENTO"]: moment(item.billingDate).format(
              Number(item?.top) === 901 || item.species === 9
                ? `MM/YYYY`
                : `DD/MM/YYYY`
            ),
            // eslint-disable-next-line
            ["VALOR TOTAL"]: item.merchandiseValue,
          })) as any[];
          await generateXlsx<object[]>({
            filename: `Pedidos`,
            data,
          });
        }
      }

      if (type === 2) {
        const { orders: ordersReport } = await getOrders(
          1,
          { ...filters, isReport: true },
          999999
        );
        if (!ordersReport) throw new Error("Sem dados");

        let data: any[] = [];
        for (const order of ordersReport) {
          for (const item of order?.order_products ?? []) {
            data.push({
              // eslint-disable-next-line
              ["CÓD. PEDIDO"]: order.cod,
              // eslint-disable-next-line
              ["Nº NOTA FISCAL"]: item.documentNumber ?? "-",
              // eslint-disable-next-line
              ["DESDOBRAMENTO"]: item.currentOrderCod ?? "-",
              // eslint-disable-next-line
              ["CLIENTE"]: order.clients_pj?.company_name,
              // eslint-disable-next-line
              ["REPRESENTANTE"]: order.seller?.cod,
              // eslint-disable-next-line
              ["MARCA"]: order.brands?.name,
              // eslint-disable-next-line
              ["STATUS DO PEDIDO"]: order?.status?.description,
              // eslint-disable-next-line
              ["TIPO"]: order.species === 9 ? "Venda Futura" : "Pronta entrega",
              // eslint-disable-next-line
              ["DIGITAÇÃO PEDIDO"]: moment(order.deliveryDate).format(
                `DD/MM/YYYY`
              ),
              // eslint-disable-next-line
              ["VALOR TOTAL PEDIDO"]: order.merchandiseValue,
              // eslint-disable-next-line
              ["FATURAMENTO PEDIDO"]: moment(order.billingDate).format(
                Number(order?.top) === 901 || order.species === 9
                  ? `MM/YYYY`
                  : `DD/MM/YYYY`
              ),
              // eslint-disable-next-line
              ["FATURAMENTO ITEM"]: item.invoiceDate
                ? moment(item.invoiceDate).format(
                    Number(order?.top) === 901 || order.species === 9
                      ? `MM/YYYY`
                      : `DD/MM/YYYY`
                  )
                : "-",

              // eslint-disable-next-line
              ["CÓD. PRODUTO"]: item.product.cod,
              // eslint-disable-next-line
              ["REF"]: item.product.reference,
              // eslint-disable-next-line
              ["SITUAÇÃO"]: item.status,
              // eslint-disable-next-line
              ["DESCRIÇÃO"]: item.product.description,
              // eslint-disable-next-line
              ["GRADE"]: item.product.grid,
              // eslint-disable-next-line
              ["QUANTIDADE"]: item.quantity,
              // eslint-disable-next-line
              ["DESCONTO"]: item.discount,
              // eslint-disable-next-line
              ["PREÇO UNITÁRIO"]: item.value,
              // eslint-disable-next-line
              ["SUBTOTAL"]: item.totalValue,
            });
          }
        }

        await generateXlsx<object[]>({
          filename: `Itens dos pedidos`,
          data: data,
        });
      }
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function cleanFilter() {
    formikFilter.resetForm();
    setFilters({});
  }

  useOutsideAlerter(outSideRefEmail, () => {
    if (optionsSendOrderEmail) setOptionsSendOrderEmail(false);
  });

  return (
    <>
      <TableHeader title="Pedidos" isFetching={isFetching && isLoading}>
        {isExportData && (
          <ReportDownload className="containerTracking">
            {!optionsSendOrderEmailLoading ? (
              <button
                onClick={() => setOptionsSendOrderEmail((old) => !old)}
                style={{ background: "#026aa6" }}
              >
                <FiMail size={19} />
                <span>Enviar pedidos </span>
              </button>
            ) : (
              <button
                style={{ background: "#026aa6" }}
                type="button"
                onClick={() => {}}
              >
                <Loading
                  borderSize={5}
                  size={20}
                  colorLoading="#333"
                  borderColor="rgba(200, 200, 200, 0.8)"
                />
              </button>
            )}

            {optionsSendOrderEmail && (
              <ul ref={outSideRefEmail} className="optionReport">
                <li
                  onClick={async () => {
                    await handleSendOrderMails({ type: "client" });
                  }}
                >
                  <span>Enviar Cliente</span>
                </li>
                <li
                  onClick={async () => {
                    await handleSendOrderMails({ type: "seller" });
                  }}
                >
                  <span>Enviar Representante</span>
                </li>
                <li
                  onClick={async () => {
                    await handleSendOrderMails({ type: "both" });
                  }}
                >
                  <span>Enviar Ambos</span>
                </li>
                <li onClick={() => setIsModalSendMail(true)}>
                  <span>Informar e-mail</span>
                </li>
              </ul>
            )}
          </ReportDownload>
        )}

        <Popover
          isVisible
          Button={
            <button style={{ background: "#1d6f42" }}>
              <RiFileExcel2Fill size={19} />
              <span>Exportar</span>
            </button>
          }
        >
          <li
            onClick={() => {
              generateReport(1);
            }}
          >
            <span>Listagem de pedido</span>
          </li>
          <li
            onClick={() => {
              generateReport(2);
            }}
          >
            <span>Listagem de pedido com itens</span>
          </li>
        </Popover>

        {refetch && (
          <button onClick={() => refetch()}>
            <IoReload size={19} />
            <span>RECARREGAR</span>
          </button>
        )}

        <button onClick={cleanFilter}>
          <AiOutlineClear size={19} />
          <span>LIMPAR FILTRO</span>
        </button>
      </TableHeader>

      <TableContainer>
        {!isLoading && orders ? (
          <table>
            <thead>
              <tr>
                <th></th>
                <th>CÓD. PEDIDO</th>
                {userType !== "client" && <th>CLIENTE</th>}
                {userType !== "seller" && <th>REP.</th>}
                <th>MARCA</th>
                <th>STATUS DO PEDIDO</th>
                <th>TIPO</th>
                <th>DIGITAÇÃO PEDIDO</th>
                <th>VALOR TOTAL</th>
              </tr>
            </thead>

            <tbody>
              {orders.map((element) => (
                <tr key={element.id}>
                  <td
                    onClick={() => {
                      handleAccessDetail(element);
                    }}
                  >
                    {element.status?.Icon && element.status?.Icon}
                  </td>
                  <td
                    onClick={() => {
                      handleAccessDetail(element);
                    }}
                  >
                    {element.initialsOrder}/{element.cod}
                  </td>

                  {userType !== "client" && (
                    <td
                      onClick={() => {
                        handleAccessDetail(element);
                      }}
                    >
                      {element.clients_pj?.company_name}
                    </td>
                  )}

                  {userType !== "seller" && (
                    <td
                      onClick={() => {
                        handleAccessDetail(element);
                      }}
                    >
                      {element.seller?.cod}
                    </td>
                  )}
                  <td
                    onClick={() => {
                      handleAccessDetail(element);
                    }}
                  >
                    {element.brands?.name}
                  </td>

                  <td
                    onClick={() => {
                      handleAccessDetail(element);
                    }}
                  >
                    <span
                      className="status"
                      style={{
                        color: element.status?.color,
                      }}
                    >
                      {element?.status?.description}
                    </span>
                  </td>

                  <td
                    onClick={() => {
                      handleAccessDetail(element);
                    }}
                  >
                    {element?.typeAndBillingDate
                      ? element.typeAndBillingDate
                      : "-"}
                  </td>

                  <td
                    onClick={() => {
                      handleAccessDetail(element);
                    }}
                  >
                    {moment(element.deliveryDate).format(`DD/MM/YYYY`)}
                  </td>
                  <td
                    onClick={() => {
                      handleAccessDetail(element);
                    }}
                  >
                    {element.merchandiseValue.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <ExternalLink style={{ height: 50 }}>
                    <ExternalLinkA
                      href={
                        userType === "admin"
                          ? `${APP_LINK}/admin/pedidos/${element.id}`
                          : userType === "seller"
                          ? `${APP_LINK}/representante/pedidos/${element.id}`
                          : `${APP_LINK}/pedidos/${element.id}`
                      }
                      target="_blank"
                    >
                      <BiLinkExternal size={20} />
                    </ExternalLinkA>
                  </ExternalLink>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <LoadingPanel>
            <LoadingDot />
          </LoadingPanel>
        )}
      </TableContainer>

      <Modal
        modalVisible={isModalSendMail}
        setModalVisible={setIsModalSendMail}
        title="Enviar pedidos"
        maxHeight={280}
        scrollOff
      >
        <Form
          onSubmit={
            isLoadingSendMail
              ? (e) => {
                  e.preventDefault();
                }
              : formikSendMail.handleSubmit
          }
        >
          <ContainerForm>
            <div className="containerForm">
              <div className="field">
                <Input
                  label="E-mail que será enviado"
                  {...formikSendMail.getFieldProps("email")}
                  error={
                    formikSendMail.touched.email && formikSendMail.errors.email
                      ? formikSendMail.errors.email
                      : undefined
                  }
                />
              </div>
            </div>

            <div className="containerFormButton">
              <ButtunSubmit style={{ margin: 0 }} type="submit">
                {isLoadingSendMail ? (
                  <div>
                    <Loading borderSize={2} size={20} />
                  </div>
                ) : (
                  "Enviar pedidos"
                )}
              </ButtunSubmit>
            </div>
          </ContainerForm>
        </Form>
      </Modal>
    </>
  );
};
