import React, { useState } from "react";
import { MdAddBox, MdDelete, MdModeEdit } from "react-icons/md";
import Modal from "../../../../components/ModalLib";

import { RowNone, TabletSelect } from "./styles";

import { BiImport } from "react-icons/bi";
import { FaSync, FaTrashAlt } from "react-icons/fa";
import { ClientForecastProps } from "..";
import { Search } from "../../../../components/Search";
import { Pagination } from "../../../../components/panel/table/Pagination";
import { TableContent } from "../../../../components/panel/table/TableContent";
import { Client, useClients } from "../../../../hook/queries/useClients";
import api from "../../../../service/api";
import { ModalBody } from "../../../create/styles";
import { PopUpConfirmation } from "../../styles";
import { ModalEditForecastClient } from "./_components/ModalEditForecastClient";
import { ModalImportForecastClient } from "./_components/ModalImportForecastClient";

interface ProviderComponentProps {
  onChangeClients: (data: ClientForecastProps[]) => void;
  clients: ClientForecastProps[];

  disabled?: boolean;

  histories: {
    historic1: string;
    historic2: string;
    historic3: string;
    historic4: string;
  };

  style?: {
    maxHeight?: string;
  };
}

export const SelectClients: React.FC<ProviderComponentProps> = ({
  onChangeClients,
  clients,
  disabled = false,
  histories,
  style,
}) => {
  const [isDisabledModalClose, setIsDisabledModalClose] =
    useState<boolean>(false);
  const [modalVisibleNewClient, setModalVisibleNewClient] = useState(false);
  const [modalVisibleImportClient, setModalVisibleImportClient] =
    useState(false);
  const [confirmDeleteClient, setConfirmDeleteClient] =
    useState<ClientForecastProps>();
  const [modalUpdateClient, setModalUpdateClient] =
    useState<ClientForecastProps>();
  const [modalImportClientLoading, setModalImportClientLoading] =
    useState(false);

  const [searchCurrentClient, setSearchCurrentClient] = useState("");
  const [searchNewClient, setSearchNewClient] = useState("");

  const [page, setPage] = useState<number>(1);

  const getClients = useClients(page, {
    status: "ativo",
    search: searchNewClient,
  });

  function handleSelect(client: ClientForecastProps) {
    const alreadySelected = clients.find((f) => f.cod === client.cod);

    if (!alreadySelected) {
      onChangeClients([
        ...clients,
        {
          ...client,

          historic1: 0,
          historic2: 0,
          historic3: 0,
          historic4: 0,
        },
      ]);
    }

    if (!isDisabledModalClose) setModalVisibleNewClient(false);
  }
  function handleRemove(client: ClientForecastProps) {
    const filterClientRemove = clients.filter((f) => f.cod !== client.cod);
    setConfirmDeleteClient(undefined);

    onChangeClients(filterClientRemove);
  }
  function handleUpdate(clientUpdated: ClientForecastProps) {
    setModalUpdateClient(undefined);

    onChangeClients(
      clients.map((client) =>
        client.cod === clientUpdated.cod ? clientUpdated : client
      )
    );
  }
  async function handleUpdateMany(clientsImport: ClientForecastProps[]) {
    setModalImportClientLoading(true);
    setModalVisibleImportClient(false);

    let normalized = clients;
    const getInfoClient = await api.post<Client[]>(`/clientPJ/showByListCod`, {
      clients: clientsImport.map((f) => f.cod),
    });

    for (const item of clientsImport) {
      try {
        const existClient = normalized.find((f) => f.cod === item.cod);

        if (existClient) {
          existClient.historic1 = item.historic1;
          existClient.historic2 = item.historic2;
          existClient.historic3 = item.historic3;
          existClient.historic4 = item.historic4;
        } else {
          const getClient = getInfoClient.data.find((f) => f.cod === item.cod);

          if (getClient)
            normalized.push({
              ...getClient,
              historic1: item.historic1,
              historic2: item.historic2,
              historic3: item.historic3,
              historic4: item.historic4,
            });
        }
      } catch (error) {}
    }

    onChangeClients(normalized);
    setModalImportClientLoading(false);
  }

  function searchClients(search: string): ClientForecastProps[] {
    return !!searchCurrentClient
      ? clients.filter(
          (client) =>
            client.cod.toString().includes(search) ||
            client?.cnpj?.toLowerCase().includes(search.toLowerCase()) ||
            client?.company_name?.toLowerCase().includes(search.toLowerCase())
        )
      : clients;
  }

  const currentClientFilterApplied = searchClients(searchCurrentClient).sort(
    (a, b) => a.cod - b.cod
  );

  return (
    <>
      <div className="container-subtitle">
        <h3>Clientes ({clients.length})</h3>

        {!disabled && (
          <>
            <button
              type="button"
              className="action-button"
              onClick={() => {
                setModalVisibleNewClient(true);
              }}
            >
              <MdAddBox />
            </button>
            <button
              type="button"
              className="action-button"
              onClick={() => {
                setModalVisibleImportClient(true);
              }}
            >
              <BiImport />
            </button>
          </>
        )}
      </div>

      <Search
        currentSearch={searchCurrentClient}
        handleSearch={setSearchCurrentClient}
      />

      <div style={{ maxHeight: style?.maxHeight ?? "18rem", overflow: "auto" }}>
        <TabletSelect>
          <thead>
            <tr>
              {!disabled && <th>Ações</th>}
              <th>Código</th>
              <th>CNPJ</th>
              <th>Razão social</th>
              <th>{histories.historic1}</th>
              <th>{histories.historic2}</th>
              <th>{histories.historic3}</th>
              <th>{histories.historic4}</th>
            </tr>
          </thead>

          <tbody>
            {currentClientFilterApplied.map((client) => (
              <tr key={client.cod}>
                {!disabled && (
                  <td>
                    <div style={{ display: "flex" }}>
                      <button
                        type="button"
                        className="action-button"
                        onClick={() => {
                          setConfirmDeleteClient(client);
                        }}
                      >
                        <MdDelete size="1.5rem" color="#222" />
                      </button>
                      <button
                        type="button"
                        className="action-button"
                        onClick={() => {
                          setModalUpdateClient(client);
                        }}
                      >
                        <MdModeEdit size="1.5rem" color="#222" />
                      </button>
                    </div>
                  </td>
                )}

                <td>{client.cod}</td>
                <td>{client.cnpj}</td>
                <td>{client.company_name}</td>
                <td>
                  {client.historic1
                    ? Number(client.historic1).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    : "-"}
                </td>
                <td>
                  {client.historic2
                    ? Number(client.historic2).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    : "-"}
                </td>
                <td>
                  {client.historic3
                    ? Number(client.historic3).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    : "-"}
                </td>
                <td>
                  {client.historic4
                    ? Number(client.historic4).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </TabletSelect>
      </div>

      {currentClientFilterApplied.length < 1 && (
        <RowNone>Não há registros a serem exibidos</RowNone>
      )}

      <Modal
        modalVisible={modalImportClientLoading}
        setModalVisible={() => {
          setModalImportClientLoading(false);
        }}
        headerOff={true}
        maxHeight={250}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav style={{ animation: "spin 0.7s linear infinite" }}>
              <FaSync size={95} opacity={0.2} />
            </nav>
            <span>Buscando dados de importação dos clientes...</span>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        title="Vincular cliente"
        modalVisible={modalVisibleNewClient}
        setModalVisible={setModalVisibleNewClient}
        maxHeight={"36.5rem"}
        isDisabled={isDisabledModalClose}
        setIsDisabled={setIsDisabledModalClose}
      >
        <ModalBody>
          <Search
            currentSearch={searchNewClient}
            handleSearch={setSearchNewClient}
          />
          <TableContent<ClientForecastProps>
            data={getClients.data?.clients}
            columns={[
              {
                name: "cod",
                field: "Código",
                viewList: true,
              },
              {
                name: "cnpj",
                field: "CNPJ",
                viewList: true,
              },
              {
                name: "company_name",
                field: "Razão social",
                viewList: true,
              },
            ]}
            isLoading={getClients.isLoading}
            onClickRow={(row) => {
              handleSelect(row);
            }}
          />

          <Pagination
            totalCountOfRegisters={getClients?.data?.totalCount ?? 0}
            currentPage={page}
            onPageChange={setPage}
            registersPerPage={15}
          />
        </ModalBody>
      </Modal>

      <Modal
        modalVisible={!!confirmDeleteClient}
        setModalVisible={() => {
          setConfirmDeleteClient(undefined);
        }}
        headerOff={true}
        maxHeight={350}
        maxWidth={650}
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <FaTrashAlt size={95} opacity={0.2} />
            </nav>
            <span>Você tem certeza que deseja excluir?</span>
          </div>
          <div className="buttonsPopUp">
            <button
              type="button"
              onClick={() => setConfirmDeleteClient(undefined)}
            >
              Cancelar
            </button>
            <button
              style={{ background: "#d21e26" }}
              type="button"
              onClick={() => {
                if (confirmDeleteClient) {
                  handleRemove(confirmDeleteClient);
                }
              }}
            >
              Deletar
            </button>
          </div>
        </PopUpConfirmation>
      </Modal>

      <Modal
        modalVisible={!!modalUpdateClient}
        setModalVisible={() => {
          setModalUpdateClient(undefined);
        }}
        headerOff={true}
        maxHeight={450}
        maxWidth={650}
      >
        {!!modalUpdateClient && (
          <ModalEditForecastClient
            data={modalUpdateClient}
            histories={histories}
            onChange={handleUpdate}
          />
        )}
      </Modal>

      <Modal
        modalVisible={modalVisibleImportClient}
        setModalVisible={() => {
          setModalVisibleImportClient(false);
        }}
        headerOff={true}
        maxHeight={250}
        maxWidth={650}
      >
        <ModalImportForecastClient
          histories={histories}
          onChange={(importClients) => {
            handleUpdateMany(importClients);
          }}
        />
      </Modal>
    </>
  );
};
