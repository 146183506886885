import React, { useState, useEffect, useRef } from "react";

import * as userLocalStorage from "../../../service/localStorage/user";
import api from "../../../service/api";

import { useHistory } from "react-router-dom";
import { dataZero } from "../../../utils/dataProcessing";
// import calculateSla from "../../../utils/calculateSlaDates";

import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import LoadingDot from "../../../components/loadings/LoadingDot";

import {
  Container,
  Filter,
  FilterCheck,
  ContainerList,
  TableContainer,
  FooterContainer,
  Form,
  FilterSelects,
  Error,
  LoadingPanel,
  // SlaContainer,
} from "../styles";

import { FiSearch, FiAlertCircle } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";

import useOutsideAlerter from "../../../hook/outsideAlerter";

interface ICollection {
  id: number;
  title: string;
  year: string;
  brand: { cod: number; name: string };
  description: { description: string };
  status: IStatus;
  status_date: Date;
  created_at: Date;
}

interface IHeaderProps {
  admin: boolean;
  provider: boolean;
}

interface IFilter {
  cod?: string;
  status?: string;

  finisher?: number;
  brand?: string;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;

  finisherPeriod?: number;
  presetDateFinisher?: number;
  specificDateFinisher?: Date;
  startDateFinisher?: Date;
  endDateFinisher?: Date;
}

interface IStatus {
  id?: number;
  cod: 1;
  name: string;
  color: string;
  finisher: boolean;
  responsable_id: number;
}

interface IBrand {
  id: number;
  cod: number;
  name: string;
  is_active: boolean;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IResponseApi {
  contents: ICollection[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

const PanelCollection: React.FC<IHeaderProps> = ({ admin, provider }) => {
  const userStorage = userLocalStorage.getUser() as userLocalStorage.User;

  const history = useHistory();
  const outSideRef = useRef(null);

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({});

  const [status, setStatus] = useState<IStatus[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [loading, setLoading] = useState({
    panel: true,
  });

  const [contents, setContents] = useState<ICollection[]>([]);

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  useEffect(() => {
    (async () => {
      try {
        setLoading({ ...loading, panel: true });

        const response = await api.get<IResponseApi>("/collection/", {
          params: { ...filters, ...requestPagination },
        });

        const { contents, limit, page, pages, total } = response.data;

        setContents(contents);
        setPagination({ limit, page, pages, total });

        setLoading({ ...loading, panel: false });
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, requestPagination]);

  useEffect(() => {
    (async () => {
      const brandsDB = await api.get<IBrand[]>("/populate/brand/provider");
      setBrands(brandsDB.data);
      const statusDB = await api.get<IStatus[]>("/populate/collection/status");
      setStatus(statusDB.data);
    })();
  }, []);

  function hadleToNew() {
    history.push("/admin/criar/colecao");
  }

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setRequestPagination({
      ...requestPagination,
      pageRequest: 0,
    });
    setFilterView(false);
  }

  function viewPresetDate(view: number): string {
    let returnValue = "";
    if (Number(view) === 0) {
      returnValue = "Hoje";
    }
    if (Number(view) === 2) {
      returnValue = "Ontem";
    }
    if (Number(view) === 7) {
      returnValue = "Últimos 7 dias";
    }
    if (Number(view) === 15) {
      returnValue = "Últimos 15 dias";
    }
    if (Number(view) === 30) {
      returnValue = "Últimos 30 dias";
    }

    return returnValue;
  }

  function viewPeriodDate(view: number): string {
    let returnValue = "";
    if (Number(view) === 1) {
      returnValue = "Predefinida";
    }
    if (Number(view) === 2) {
      returnValue = "Específica";
    }
    if (Number(view) === 3) {
      returnValue = "Intevalo";
    }

    return returnValue;
  }

  function selectBrand(st: number): string {
    let data = "";
    brands.forEach((item) => {
      if (item.id === st) {
        return (data = item.name);
      }
    });

    return data;
  }

  function selectStatus(st: number): string {
    let statusName = "";
    status.forEach((item) => {
      if (item.id === st) {
        return (statusName = item.name);
      }
    });

    return statusName;
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  return (
    <Container>
      <header>
        <h2>Lista de Coleções</h2>
      </header>

      <Filter>
        <button
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="button"
        >
          <FiSearch />
        </button>
        <input
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="text"
          name="filter"
          id="filter"
          readOnly={true}
        />
        <FilterSelects>
          <ul>
            {filters.cod && (
              <li>
                <span>CÓDIGO: {filters.cod}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, cod: "" })}
                />
              </li>
            )}

            {filters.status && (
              <li>
                <span>STATUS: {selectStatus(Number(filters.status))}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, status: "" })}
                />
              </li>
            )}

            {filters.brand && (
              <li>
                <span>
                  MARCA:
                  {selectBrand(Number(filters.brand))}
                </span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, brand: undefined })}
                />
              </li>
            )}

            {filters.finisher && (
              <li>
                <span>
                  TIPO STATUS:{" "}
                  {filters.finisher > 0 ? "FINALIZADO" : "EM ANDAMENTO"}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, finisher: undefined })
                  }
                />
              </li>
            )}

            {filters.creationPeriod && (
              <li>
                <span>
                  PERÍODO DE CRIAÇÃO: {viewPeriodDate(filters.creationPeriod)}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      creationPeriod: undefined,
                      presetDate: undefined,
                      specificDate: undefined,
                      startDate: undefined,
                      endDate: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.presetDate && (
              <li>
                <span>
                  DATA PREDEFINIDA: {viewPresetDate(Number(filters.presetDate))}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      presetDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.specificDate && (
              <li>
                <span>DATA ESPECÍFICA: {filters.specificDate}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      specificDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.startDate && (
              <li>
                <span>DATA INICIAL: {filters.startDate}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      startDate: undefined,
                      endDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.endDate && (
              <li>
                <span>DATA FINAL: {filters.endDate}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      endDate: undefined,
                      startDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.finisherPeriod && (
              <li>
                <span>
                  PERÍODO DE FINALIZAÇÃO:
                  {viewPeriodDate(filters.finisherPeriod)}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      finisherPeriod: undefined,
                      presetDate: undefined,
                      specificDate: undefined,
                      startDate: undefined,
                      endDate: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.presetDateFinisher && (
              <li>
                <span>
                  DATA PREDEFINIDA:
                  {viewPresetDate(Number(filters.presetDateFinisher))}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      presetDateFinisher: undefined,
                      finisherPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.specificDateFinisher && (
              <li>
                <span>DATA ESPECÍFICA: {filters.specificDateFinisher}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      specificDateFinisher: undefined,
                      finisherPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.startDateFinisher && (
              <li>
                <span>DATA INICIAL: {filters.startDateFinisher}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      startDateFinisher: undefined,
                      endDateFinisher: undefined,
                      finisherPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.endDateFinisher && (
              <li>
                <span>DATA FINAL: {filters.endDateFinisher}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      endDateFinisher: undefined,
                      startDateFinisher: undefined,
                      finisherPeriod: undefined,
                    })
                  }
                />
              </li>
            )}
          </ul>
        </FilterSelects>

        {filterView && (
          <FilterCheck ref={outSideRef}>
            <header>
              <button
                onClick={() => {
                  setFilterView(false);
                }}
              >
                Fechar
              </button>
            </header>
            <main>
              <Formik
                initialValues={{
                  cod: filters.cod,
                  status: filters.status,
                  brand: filters.brand,
                  finisher: filters.finisher,

                  creationPeriod: filters.creationPeriod,
                  presetDate: filters.presetDate,
                  specificDate: filters.specificDate,
                  startDate: filters.startDate,
                  endDate: filters.endDate,

                  finisherPeriod: filters.finisherPeriod,
                  presetDateFinisher: filters.presetDateFinisher,
                  specificDateFinisher: filters.specificDateFinisher,
                  startDateFinisher: filters.startDateFinisher,
                  endDateFinisher: filters.endDateFinisher,
                }}
                validationSchema={Yup.object({
                  presetDate: Yup.string().when("creationPeriod", {
                    is: "1",
                    then: Yup.string().required(
                      "Data predefinida é obrigatório"
                    ),
                  }),

                  specificDate: Yup.string().when("creationPeriod", {
                    is: "2",
                    then: Yup.string().required(
                      "Data específica é obrigatório"
                    ),
                  }),

                  startDate: Yup.string().when("creationPeriod", {
                    is: "3",
                    then: Yup.string().required("Data inicio é obrigatório"),
                  }),

                  endDate: Yup.string().when("creationPeriod", {
                    is: "3",
                    then: Yup.string().required("Data final é obrigatório"),
                  }),

                  //Finalização

                  presetDateFinisher: Yup.string().when("finisherPeriod", {
                    is: "1",
                    then: Yup.string().required(
                      "Data predefinida é obrigatório"
                    ),
                  }),

                  specificDateFinisher: Yup.string().when("finisherPeriod", {
                    is: "2",
                    then: Yup.string().required(
                      "Data específica é obrigatório"
                    ),
                  }),

                  startDateFinisher: Yup.string().when("finisherPeriod", {
                    is: "3",
                    then: Yup.string().required("Data inicio é obrigatório"),
                  }),

                  endDateFinisher: Yup.string().when("finisherPeriod", {
                    is: "3",
                    then: Yup.string().required("Data final é obrigatório"),
                  }),
                })}
                onSubmit={(data) => {
                  hadleFilter(data);
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="cod">CÓDIGO</label>
                        <input id="cod" {...formik.getFieldProps("cod")} />
                      </div>

                      {admin && (
                        <div className="field">
                          <label htmlFor="brand">MARCA</label>
                          <select {...formik.getFieldProps("brand")}>
                            <option value="">Selecionar marca</option>
                            {brands.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="status">STATUS</label>
                        <select {...formik.getFieldProps("status")}>
                          <option value="">Selecionar Status</option>
                          {status.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="field">
                        <label htmlFor="finisher">TIPO STATUS</label>
                        <select
                          id="finisher"
                          {...formik.getFieldProps("finisher")}
                        >
                          <option value=""></option>
                          <option value={1}>Finalizado</option>
                          <option value={0}>Em andamento</option>
                        </select>
                      </div>
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="typeDate">PERÍODO DE CRIAÇÃO</label>
                        <select {...formik.getFieldProps("creationPeriod")}>
                          <option value=""></option>
                          <option value={1}>Predefinida</option>
                          <option value={2}>Específica</option>
                          <option value={3}>Intervalo</option>
                        </select>
                      </div>
                    </div>
                    {Number(formik.values.creationPeriod) === 1 ? (
                      <div className="field">
                        <label htmlFor="typeDate">DATA PREDEFINIDA</label>
                        <select {...formik.getFieldProps("presetDate")}>
                          <option value=""></option>
                          <option value={0}>Hoje</option>
                          <option value={1}>Ontem</option>
                          <option value={7}>Últimos 7 dias</option>
                          <option value={15}>Últimos 15 dias</option>
                          <option value={30}>Últimos 30 dias</option>
                        </select>
                        {formik.touched.presetDate &&
                        formik.errors.presetDate ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.presetDate} </span>
                          </Error>
                        ) : null}
                      </div>
                    ) : null}

                    {Number(formik.values.creationPeriod) === 2 ? (
                      <div className="field">
                        <label htmlFor="typeDate">DATA ESPECÍFICA</label>
                        <input
                          type="date"
                          {...formik.getFieldProps("specificDate")}
                        />
                        {formik.touched.specificDate &&
                        formik.errors.specificDate ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.specificDate} </span>
                          </Error>
                        ) : null}
                      </div>
                    ) : null}

                    <div className="field-group">
                      {Number(formik.values.creationPeriod) === 3 ? (
                        <>
                          <div className="field">
                            <label htmlFor="typeDate">DATA INICIAL</label>
                            <input
                              type="date"
                              {...formik.getFieldProps("startDate")}
                            />
                            {formik.touched.startDate &&
                            formik.errors.startDate ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span> {formik.errors.startDate} </span>
                              </Error>
                            ) : null}
                          </div>
                          <div className="field">
                            <label htmlFor="typeDate">DATA FINAL</label>
                            <input
                              type="date"
                              {...formik.getFieldProps("endDate")}
                            />
                            {formik.touched.endDate && formik.errors.endDate ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span> {formik.errors.endDate} </span>
                              </Error>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="typeDate">PERÍODO DE FINALIZAÇÃO</label>
                        <select {...formik.getFieldProps("finisherPeriod")}>
                          <option value=""></option>
                          <option value={1}>Predefinida</option>
                          <option value={2}>Específica</option>
                          <option value={3}>Intervalo</option>
                        </select>
                      </div>
                    </div>
                    {Number(formik.values.finisherPeriod) === 1 ? (
                      <div className="field">
                        <label htmlFor="typeDate">DATA PREDEFINIDA</label>
                        <select {...formik.getFieldProps("presetDateFinisher")}>
                          <option value=""></option>
                          <option value={0}>Hoje</option>
                          <option value={1}>Ontem</option>
                          <option value={7}>Últimos 7 dias</option>
                          <option value={15}>Últimos 15 dias</option>
                          <option value={30}>Últimos 30 dias</option>
                        </select>
                        {formik.touched.presetDate &&
                        formik.errors.presetDate ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span>{formik.errors.presetDateFinisher}</span>
                          </Error>
                        ) : null}
                      </div>
                    ) : null}

                    {Number(formik.values.finisherPeriod) === 2 ? (
                      <div className="field">
                        <label htmlFor="typeDate">DATA ESPECÍFICA</label>
                        <input
                          type="date"
                          {...formik.getFieldProps("specificDateFinisher")}
                        />
                        {formik.touched.specificDateFinisher &&
                        formik.errors.specificDateFinisher ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span>{formik.errors.specificDateFinisher}</span>
                          </Error>
                        ) : null}
                      </div>
                    ) : null}

                    <div className="field-group">
                      {Number(formik.values.finisherPeriod) === 3 ? (
                        <>
                          <div className="field">
                            <label htmlFor="typeDate">DATA INICIAL</label>
                            <input
                              type="date"
                              {...formik.getFieldProps("startDateFinisher")}
                            />
                            {formik.touched.startDateFinisher &&
                            formik.errors.startDateFinisher ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span>{formik.errors.startDateFinisher}</span>
                              </Error>
                            ) : null}
                          </div>
                          <div className="field">
                            <label htmlFor="typeDate">DATA FINAL</label>
                            <input
                              type="date"
                              {...formik.getFieldProps("endDateFinisher")}
                            />
                            {formik.touched.endDateFinisher &&
                            formik.errors.endDateFinisher ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span>{formik.errors.endDateFinisher}</span>
                              </Error>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                )}
              </Formik>
            </main>
          </FilterCheck>
        )}
      </Filter>

      <ContainerList>
        <div className="headerTable">
          <h3>Coleções</h3>

          {userStorage &&
            userStorage.permission &&
            (+userStorage.permission === 11 ||
              +userStorage.permission === 4) && (
              <div>
                <button onClick={hadleToNew} type="button">
                  <MdAdd size={22} /> <span> NOVA COLEÇÃO </span>
                </button>
              </div>
            )}
        </div>

        <TableContainer>
          {!loading.panel ? (
            <table>
              <thead>
                <tr>
                  <th>CÓDIGO</th>
                  {/* <th>TÍTULO</th> */}
                  <th>COLEÇÃO</th>
                  <th>ANO</th>
                  <th>MARCA</th>
                  <th>STATUS</th>
                  <th>CRIADO EM</th>
                  <th>DATA PREVISTA</th>
                  {/* <th>SLA DO STATUS</th> */}
                </tr>
              </thead>

              <tbody>
                {contents.map((item) => (
                  <tr
                    key={item.id}
                    onClick={() => {
                      history.push(`/admin/colecao/${item.id}`, {
                        content: item,
                      });
                    }}
                  >
                    <td>{Number(item.id)}</td>
                    {/* <td>{item.title}</td> */}
                    <td>{item.description?.description}</td>
                    <td>{item.year}</td>
                    <td>{item.brand.name}</td>
                    <td>
                      <span
                        className="status"
                        style={{ backgroundColor: item.status?.color }}
                      >
                        {item.status?.name}
                      </span>
                    </td>
                    <td>{moment(item.created_at).format(`DD/MM/YYYY`)}</td>
                    <td>
                      {item.status_date
                        ? moment(item.status_date)
                            .add(1, "days")
                            .format(`DD/MM/YYYY`)
                        : "-"}
                    </td>
                    {/* 
                    <td>
                      <SlaContainer>
                        {!item.status.finisher ? (
                          <div className="boxSla">
                            <div className="percentageSla">
                              <span>
                                {calculateSla(
                                  new Date(item.created_at),
                                  new Date(item.status_date)
                                )}
                                %
                              </span>
                            </div>
                            <div
                              className="valueSla"
                              style={{
                                width: `${calculateSla(
                                  new Date(item.created_at),
                                  new Date(item.status_date)
                                )}%`,
                                backgroundColor:
                                  calculateSla(
                                    new Date(item.created_at),
                                    new Date(item.status_date)
                                  ) < 30
                                    ? "rgba(90, 182, 95, 0.8)"
                                    : calculateSla(
                                        new Date(item.created_at),
                                        new Date(item.status_date)
                                      ) < 60
                                    ? " rgba(255, 220, 0, 0.6)"
                                    : "rgba(255, 61, 61, 0.8)",
                              }}
                            />
                          </div>
                        ) : (
                          "-"
                        )}
                      </SlaContainer>
                    </td>
                   */}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>

        <FooterContainer>
          <span>
            Mostrado{" "}
            {dataZero(
              Number(contents.length + pagination.limit * pagination.page)
            )}{" "}
            de {dataZero(Number(pagination.total))} resultados
          </span>

          <div>
            <button
              className={pagination.page < 1 ? "buttonNull" : ""}
              onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
              type="button"
            >
              ANTERIOR
            </button>
            <p style={{ marginRight: 8 }}>
              {dataZero(Number(pagination.page + 1))}
            </p>
            <button
              className={
                pagination.page >= pagination.pages ? "buttonNull" : ""
              }
              type="button"
              onClick={
                Number(pagination.page) >= Number(pagination.pages)
                  ? () => {}
                  : hadleNextPage
              }
            >
              PROXIMO
            </button>
          </div>
        </FooterContainer>
      </ContainerList>
    </Container>
  );
};

export default PanelCollection;
