import React from "react";
import Chart from "react-apexcharts";
import { BoxAnalytic } from "../../pages/analytic/styles";

interface BoxAnalyticCharBarProps {
  title: string;
  values: number[];
  descriptions: string[];
  obs?: JSX.Element;
  colors?: string[];
  height?: number | string;
  distributed?: boolean;
  horizontal?: boolean;
}

export const BoxAnalyticCharBar = ({
  title,
  values,
  descriptions,
  obs,
  colors,
  height = 200,
  distributed = true,
  horizontal = true,
}: BoxAnalyticCharBarProps) => {
  return (
    <BoxAnalytic>
      <h3>{title}</h3>

      <div style={{ width: "100%" }}>
        <Chart
          type="bar"
          height={height}
          series={[
            {
              name: "QTD",
              data: values,
            },
            // {
            //   name: "VALOR",
            //   data: amounts,
            // },
          ]}
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },
            colors: colors,

            plotOptions: {
              bar: {
                distributed: distributed,
                borderRadius: 4,
                horizontal: horizontal,
                dataLabels: {
                  position: "top",
                },
              },
            },
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "12px",
                colors: ["#304758"],
              },
            },

            xaxis: {
              labels: {
                show: false,
              },

              categories: descriptions,
            },
          }}
        />
        {obs && <span className="span-alert">{obs}</span>}
      </div>
    </BoxAnalytic>
  );
};
