import styled from "styled-components";

export const Container = styled.div`
  grid-area: APP;
  padding: 10px 30px;

  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  header {
    margin-bottom: 20px;

    h2 {
      color: #333;
    }

    .download-file {
      margin-top: 15px;
      margin-bottom: 20px;
      a {
        width: 230px;
        padding: 4px 2px;

        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 20px;
        background: rgba(200, 200, 200, 0.8);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

        &:hover {
          background: rgba(180, 180, 180, 0.8);
        }
        span {
          margin-left: 6px;
          margin-top: 2px;
        }

        display: flex;
        align-items: center;
        text-decoration: none;

        color: #333;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .buttonSubmit {
    margin-top: 20px;
  }
`;

export const ContainerPreviews = styled.ul`
  margin-top: 25px;
  width: 100%;

  display: flex;
  justify-content: flex-start;

  li {
    width: 100%;
    display: flex;
    background: #fff;
    padding: 10px 10px;
    border-radius: 8px;

    img {
      height: 60px;
    }

    @media (max-width: 900px) {
      img {
        height: 40px;
        width: 40px;
      }
    }

    strong {
      word-break: break-all;
    }

    .fileInfo {
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      div {
        display: flex;
        flex-direction: column;
        margin: 0 5px;
      }

      button {
        margin-right: 10px;
        background: none;
        color: #f00;
      }
    }
  }
`;
