import React, { useState } from "react";
import { ButtonSubmit } from "../Form/ButtonSubmit";
import { Input } from "../Form/Input";
import { LayoutForm } from "../Form/LayoutForm";
import { Select } from "../Form/Select";
import { TabelaCalculadora } from "./styled";

const iof = 0.004167;

interface CalculatorComponentProps {
  iofDia: number;
  taxaPadrao: number;
  parcelas: Parcela[];
}

interface Parcela {
  name: string;
  value: string;
}

export function CalculatorComponent({
  iofDia,
  taxaPadrao,
  parcelas,
}: CalculatorComponentProps) {
  const [pedido, setPedido] = useState(0);
  const [desconto, setDesconto] = useState(0);
  const [parcela, setParcela] = useState(0);
  const pedidoDesconto = pedido - pedido * (desconto / 100);
  const [calculoParcela, setCalculoParcela] = useState<any[]>([]);
  const [valorTotalVendor, setValorTotalVendor] = useState(0);
  const [prazoInicio, setPrazoInicio] = useState(30);

  function calcular() {
    let pedidoDesconto = pedido - pedido * (desconto / 100);
    let valorDaParcela = pedidoDesconto / parcela;
    let calculoIofdia = (iofDia * pedidoDesconto) / 100;

    let diasMes = 30;
    let resultadoParcela: any[] = [];
    let valorVendor = 0;
    for (let index = 0; index < parcela; index++) {
      let dias = prazoInicio + diasMes * index;
      let qtdrRealdaparcela = index + 1;
      let taxa = (taxaPadrao / diasMes) * dias;
      taxa = Number(taxa.toFixed(2));
      let juros = valorDaParcela * (taxa / 100);
      juros = Number(juros.toFixed(2));
      let calculoParcela = valorDaParcela + valorDaParcela * (taxa / 100);
      let calculoIof = (valorDaParcela * iof * dias) / 100;
      calculoIof = Number(calculoIof.toFixed(2));
      let valorTotal = calculoParcela + calculoIof;
      if (Number(qtdrRealdaparcela) === 1) {
        valorTotal = valorTotal + calculoIofdia;
      }
      valorTotal = Number(valorTotal.toFixed(2));
      valorVendor = valorTotal + valorVendor;
      resultadoParcela.push({
        qtdrRealdaparcela,
        taxa,
        calculoIof,
        calculoParcela,
        parcelasemdesconto: valorDaParcela,
        valorTotal,
        calculoIofdia,
        juros,
        diasdaParcela: dias,
      });
    }
    setCalculoParcela(resultadoParcela);
    setValorTotalVendor(valorVendor);
  }

  return (
    <>
      <LayoutForm>
        <Input
          name="reais"
          label="Valor do pedido"
          onChange={(e) => {
            setPedido(Number(e.target.value));
          }}
          placeholder="R$"
          // value={pedido.toLocaleString("pt-br", {
          //   style: "currency",
          //   currency: "BRL",
          // })}
        />

        <Select
          onChange={(e) => {
            setParcela(Number(e.target.value));
          }}
          label="Parcelas"
          data={
            prazoInicio === 30
              ? [
                  { name: "1 Parcela", value: "1" },
                  { name: "2 Parcela", value: "2" },
                  { name: "3 Parcela", value: "3" },
                  { name: "4 Parcela", value: "4" },
                  { name: "5 Parcela", value: "5" },
                ]
              : [
                  { name: "1 Parcela", value: "1" },
                  { name: "2 Parcela", value: "2" },
                  { name: "3 Parcela", value: "3" },
                  { name: "4 Parcela", value: "4" },
                ]
          }
          name="parcela"
        />

        <Select
          onChange={(e) => {
            setPrazoInicio(Number(e.target.value));
          }}
          label="Prazo inicio"
          data={parcelas}
          name="parcela"
        />

        <Input
          name="desconto"
          type="number"
          label="De quantos % é o Desconto?"
          onChange={(e) => {
            setDesconto(Number(e.target.value));
          }}
        />

        <div>
          <span style={{ fontSize: "1rem" }}>VALOR TOTAL COM DESCONTO: </span>
          <b style={{ fontSize: "1rem" }}>{`R$ ${pedidoDesconto}`}</b>
        </div>

        <ButtonSubmit onClick={calcular} type="button">
          Calcular
        </ButtonSubmit>
      </LayoutForm>

      {calculoParcela.length > 0 && (
        <p style={{ fontSize: "2rem", marginBottom: "1rem" }}>
          Detalhe das parcelas
        </p>
      )}

      {calculoParcela &&
        calculoParcela.map((item, indice) => {
          if (indice === 0) {
            return (
              <TabelaCalculadora>
                <tr>
                  <th>
                    Parcela {item.qtdrRealdaparcela}/{parcela}
                  </th>

                  <th>{item.diasdaParcela} Dias</th>
                  <th>{item.taxa.toFixed(2)}%</th>
                </tr>
                <tr></tr>
                <tr>
                  <th>Juros</th>
                  <th> R$ {item.juros.toFixed(2)}</th>
                </tr>
                <tr>
                  <th>IOF</th>
                  <th> R$ {item.calculoIofdia.toFixed(2)}</th>
                </tr>
                <tr>
                  <th>IOF ao dia</th>
                  <th> R$ {item.calculoIof.toFixed(2)}</th>
                </tr>
                <tr className="colunaTotal">
                  <th>Total</th>
                  <th> R$ {item.valorTotal}</th>
                </tr>
              </TabelaCalculadora>
            );
          } else {
            return (
              <TabelaCalculadora className="tabela">
                <tr>
                  <th>
                    Parcela {item.qtdrRealdaparcela}/{parcela}
                  </th>
                  <th>{item.diasdaParcela} Dias</th>
                  <th>{item.taxa.toFixed(2)}%</th>
                </tr>
                <tr></tr>
                <tr>
                  <th>Juros</th>
                  <th> R$ {item.juros.toFixed(2)}</th>
                </tr>
                <tr>
                  <th>IOF ao dia</th>
                  <th> R$ {item.calculoIof.toFixed(2)}</th>
                </tr>
                <tr className="colunaTotal">
                  <th>Total</th>
                  <th> R$ {item.valorTotal}</th>
                </tr>
              </TabelaCalculadora>
            );
          }
        })}
      {calculoParcela.length > 0 && (
        <>
          <p style={{ fontSize: "2rem" }}>
            Valor total das parcelas:
            <b>
              {` ${valorTotalVendor.toLocaleString("pt-br", {
                style: "currency",

                currency: "BRL",
              })}`}
            </b>
          </p>

          <p style={{ color: "red" }}>
            *Atenção: Cotação meramente indicativa. Taxa sujeita a alteração de
            acordo com a cotação na data da contratação da operação.
          </p>
        </>
      )}
    </>
  );
}
