import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

import api from "../../../service/api";
import * as sellerLocalStorage from "../../../service/localStorage/seller";
import { CalculatorDefaultConfig } from "../../config/Calculator";

import { CalculatorComponent } from "../../../components/Calculator";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";

interface Props {
  admin?: boolean;
  client?: boolean;
  seller?: boolean;
  provider?: boolean;
}

const Calculator: React.FC<Props> = ({ seller }) => {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(true);
  const [calculatorParameters, setCalculatorParameters] =
    useState<CalculatorDefaultConfig>({} as CalculatorDefaultConfig);

  const sellerStorage =
    sellerLocalStorage.getUser() as sellerLocalStorage.SellerProps;

  useEffect(() => {
    (async () => {
      try {
        const config = await api.get<CalculatorDefaultConfig>(
          `/calculator/config`
        );

        setCalculatorParameters(config.data);
        setIsLoading(false);
      } catch (error) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    })();

    // eslint-disable-next-line
  }, []);

  function selectParcel() {
    if (seller) {
      const existBrand = sellerStorage?.brands?.find((brand) =>
        [26, 2].includes(Number(brand))
      );

      if (existBrand) {
        return [
          { name: "30 Dias", value: "30" },
          { name: "60 Dias", value: "60" },
        ];
      } else {
        return [{ name: "30 Dias", value: "30" }];
      }
    }

    return [
      { name: "30 Dias", value: "30" },
      { name: "60 Dias", value: "60" },
    ];
  }

  return (
    <CreateAndDetailLayout isLoading={isLoading}>
      <PanelAndDetailAndCreateHeader
        title="Calculadora Vendor (SIMULAÇÃO)"
        goBack
      />

      <CalculatorComponent
        iofDia={calculatorParameters.taxIof}
        taxaPadrao={calculatorParameters.percentVendor}
        parcelas={selectParcel()}
      />
    </CreateAndDetailLayout>
  );
};

export default Calculator;
