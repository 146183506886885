import filesize from "filesize";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { IoMdTrash } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { useMutation } from "react-query";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import "react-quill/dist/quill.snow.css";
import { ContainerDropZone } from "../styles";

import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import Dropzone from "../../../components/Upload/Dropzone";
import ModalLoading from "../../../components/loadings/ScreenLoading";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";

import { GroupInput } from "../../../components/Form/GroupInput";
import { Select } from "../../../components/Form/Select";
import { Banner, useOneBanner } from "../../../hook/queries/useBanners";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { setFormikValues } from "../../../utils/setFormikValues";
import { ContainerPreviews } from "../../create/styles";

export interface MatchParams {
  id?: string;
}

type BannerCreateProps = Omit<
  Banner,
  "id" | "created_at" | "created_atFormatted" | "file"
>;
type BannerUpdateProps = Omit<
  Banner,
  "created_at" | "created_atFormatted" | "file"
>;

export default () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const isUpdating = match?.params?.id ? true : false;
  const getData = useOneBanner(match.params.id);

  const [file, setFile] = useState<File | undefined>();
  const [onUploadProgress, setOnUploadProgress] = useState(0);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const createBanner = useMutation(
    async (banner: BannerCreateProps) => {
      const response = await api.post("/banner", banner);

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("banners");
      },
    }
  );
  const updateBanner = useMutation(
    async (banner: BannerUpdateProps) => {
      const response = await api.put(`/banner/${banner.id}`, banner);

      return response.data;
    },
    {
      onSuccess: (banner: BannerUpdateProps) => {
        queryClient.invalidateQueries("banners");
        queryClient.invalidateQueries(["banner", banner.id]);
      },
    }
  );
  const deleteBanner = useMutation(
    async (banner: Banner) => {
      const response = await api.delete(`/banner/${banner.id}`);

      return response.data;
    },
    {
      onSuccess: (banner: Banner) => {
        queryClient.invalidateQueries("banners");
        queryClient.invalidateQueries(["banner", banner.id]);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      id: "",
      title: "",
      link: "",
      admin: "",
      client: "",
      provider: "",
      seller: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("É obrigatório"),
      admin: Yup.string().required("É obrigatório"),
      client: Yup.string().required("É obrigatório"),
      provider: Yup.string().required("É obrigatório"),
      seller: Yup.string().required("É obrigatório"),
    }),
    onSubmit: (a) => {
      //@ts-ignore
      !isUpdating ? handleCreate(a) : handleUpdate(a);
    },
  });

  async function handleCreate(banner: BannerCreateProps) {
    if (!file) {
      return addToast("E obrigatório anexar um arquivo!", {
        appearance: "warning",
        autoDismiss: true,
      });
    }

    try {
      const normalized = {
        title: banner.title,
        link: banner.link,
        admin: Boolean(Number(banner.admin)),
        seller: Boolean(Number(banner.seller)),
        client: Boolean(Number(banner.client)),
        provider: Boolean(Number(banner.provider)),
      };

      const bannerCreated = await createBanner.mutateAsync(normalized);

      const formData = new FormData();
      formData.append("file", file);

      api.post(`/banner/upload/${bannerCreated.id}`, formData, {
        onUploadProgress: (e) => {
          const loaded = +e.loaded;
          const total = +e.total;

          //@ts-ignore
          var progress = parseInt(Math.round((loaded * 100) / total));

          setOnUploadProgress(progress);
        },
      });

      setLoadingUpload(true);
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleUpdate(banner: Banner) {
    try {
      const normalized = {
        id: banner.id,
        title: banner.title,
        link: banner.link,
        admin: Boolean(Number(banner.admin)),
        seller: Boolean(Number(banner.seller)),
        client: Boolean(Number(banner.client)),
        provider: Boolean(Number(banner.provider)),
      };

      await updateBanner.mutateAsync(normalized);

      addToast("Banner alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/banners");
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleDelete() {
    try {
      if (!getData?.data) throw new Error("No data");

      deleteBanner.mutateAsync(getData?.data);
      addToast("Banner excluído com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/banners");
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  useEffect(() => {
    if (loadingUpload) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [loadingUpload]);

  useEffect(() => {
    if (onUploadProgress >= 100) {
      setLoadingUpload(false);
      setOnUploadProgress(0);

      addToast("Banner criado com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/banners");
    }
    // eslint-disable-next-line
  }, [onUploadProgress]);

  useEffect(() => {
    if (getData?.isSuccess && getData?.data && isUpdating)
      setFormikValues(
        {
          ...getData?.data,
          admin: getData?.data.admin ? "1" : "0",
          seller: getData?.data.seller ? "1" : "0",
          provider: getData?.data.provider ? "1" : "0",
          client: getData?.data.client ? "1" : "0",
        },
        formik
      );
    // eslint-disable-next-line
  }, [getData?.data, isUpdating, getData?.isSuccess]);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "As alterações feitas podem não ser salvas.";
  };

  return (
    <CreateAndDetailLayout isLoading={getData?.isLoading}>
      <PanelAndDetailAndCreateHeader
        title={isUpdating ? "Banner" : "Criar Banner"}
        goBack
      >
        {isUpdating && (
          <div className="containerActions">
            <button
              type="button"
              className="buttonActionDatasheet"
              onClick={handleDelete}
            >
              {
                <div>
                  <span>
                    <RiDeleteBinLine color="#d21e26" />
                  </span>
                  Excluir
                </div>
              }
            </button>
          </div>
        )}
      </PanelAndDetailAndCreateHeader>

      <LayoutForm onSubmit={formik.handleSubmit}>
        {isUpdating && (
          <Input label="ID" {...formik.getFieldProps("id")} disabled />
        )}
        <Input
          label="Titulo"
          {...formik.getFieldProps("title")}
          error={
            formik.touched.title && formik.errors.title
              ? formik.errors.title
              : undefined
          }
        />
        <Input
          label="Link"
          {...formik.getFieldProps("link")}
          error={
            formik.touched.link && formik.errors.link
              ? formik.errors.link
              : undefined
          }
        />

        <h3>Visualização usuários</h3>
        <GroupInput>
          <Select
            label="Admin"
            {...formik.getFieldProps("admin")}
            data={[
              { name: "Sim", value: "1" },
              { name: "Não", value: "0" },
            ]}
            error={
              formik.touched.admin && formik.errors.admin
                ? formik.errors.admin
                : undefined
            }
          />
          <Select
            label="Cliente"
            {...formik.getFieldProps("client")}
            data={[
              { name: "Sim", value: "1" },
              { name: "Não", value: "0" },
            ]}
            error={
              formik.touched.client && formik.errors.client
                ? formik.errors.client
                : undefined
            }
          />
        </GroupInput>

        <GroupInput>
          <Select
            label="Fornecedor"
            {...formik.getFieldProps("provider")}
            data={[
              { name: "Sim", value: "1" },
              { name: "Não", value: "0" },
            ]}
            error={
              formik.touched.provider && formik.errors.provider
                ? formik.errors.provider
                : undefined
            }
          />
          <Select
            label="Vendedor"
            {...formik.getFieldProps("seller")}
            data={[
              { name: "Sim", value: "1" },
              { name: "Não", value: "0" },
            ]}
            error={
              formik.touched.seller && formik.errors.seller
                ? formik.errors.seller
                : undefined
            }
          />
        </GroupInput>

        <div className="field">
          <div className="containerLabelButton">
            <h3>Arquivos</h3>
          </div>

          {isUpdating ? (
            getData?.data?.file && (
              <ContainerPreviews
                style={{
                  overflowY: "auto",
                  maxHeight: "30vh",
                }}
              >
                <li key={getData?.data?.file.name}>
                  <img
                    src={getData?.data?.file.url ?? ""}
                    alt="_"
                    style={{ height: "5rem" }}
                  />
                  <div className="fileInfo">
                    <div>
                      <strong>{getData?.data?.file.name}</strong>
                      <span>{filesize(getData?.data?.file.size ?? 0)}</span>
                    </div>
                  </div>
                </li>
              </ContainerPreviews>
            )
          ) : (
            <ContainerDropZone>
              <Dropzone
                accept={["image/*"]}
                onFileUploaded={(e) => setFile(e)}
              />
              <ContainerPreviews
                style={{
                  overflowY: "auto",
                  maxHeight: "30vh",
                }}
              >
                {file && (
                  <li key={file.name}>
                    <img src={URL.createObjectURL(file)} alt="_" />
                    <div className="fileInfo">
                      <div>
                        <strong>{file.name}</strong>
                        <span>{filesize(file.size)}</span>
                      </div>

                      <button
                        type="button"
                        onClick={() => {
                          setFile(undefined);
                        }}
                      >
                        <IoMdTrash size={30} />
                      </button>
                    </div>
                  </li>
                )}
              </ContainerPreviews>
            </ContainerDropZone>
          )}
        </div>

        <ButtonSubmit>{isUpdating ? "Editar" : "Criar"}</ButtonSubmit>
      </LayoutForm>

      {loadingUpload ? <ModalLoading progress={onUploadProgress} /> : null}
    </CreateAndDetailLayout>
  );
};
