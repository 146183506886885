import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Billet = {
  id: number;
  documentNumber: number;
  billetValue: number;
  dueDate: Date;
  unfolding: number;
  ourNumber: string;
  billingLocation?: string;
  bankCod?: string;
  agency?: string;
  billetNumberDV?: string;
  portion: string;
  idTypeDoc: string;
  shipping: boolean;
  paymentDate: Date;
  paymentOpened: boolean;
  isSankhya: boolean;
  digitableLine?: string;

  billingLocations?: {
    agency: string;
    isGeneratedBillet: boolean;
    bank: {
      cod: number;
    };
  };
  account?: {
    account: string;
    agency: string;
  };

  clients_pj: {
    id?: string;
    cod?: number;
    cnpj?: string;
    company_name?: string;
    is_dda: boolean;
  };

  created_at: Date;
  updated_at: Date;
};

export type BilletFilters = {
  documentNumber?: string;
  ourNumber?: string;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  duePeriod?: number;
  duePresetDate?: number;
  dueSpecificDate?: Date;
  dueStartDate?: Date;
  dueEndDate?: Date;
};

type GetBilletsCountResponse = {
  totalCount: number;
};

interface BilletApiResponse extends ResponsePanelApiDefault {
  contents: Billet[];
}

export async function getBilletsCount(
  filter?: BilletFilters
): Promise<GetBilletsCountResponse> {
  const { data } = await api.get<BilletApiResponse>("/billet/count", {
    params: { ...filter },
  });

  return {
    totalCount: data.total,
  };
}

export function useBilletsCount(
  filter?: BilletFilters
): UseQueryResult<GetBilletsCountResponse> {
  return useQuery(["billets", filter], () => getBilletsCount(filter), {
    staleTime: 1000 * 60 * 5, // 5 Minutos
  });
}

export async function getBilletsCountClient(
  clientId?: number,
  filter?: BilletFilters
): Promise<GetBilletsCountResponse | null> {
  if (!clientId) return null;

  const { data } = await api.get<BilletApiResponse>(
    `/billet/client/count/${clientId}`,
    {
      params: { ...filter },
    }
  );

  return {
    totalCount: data.total,
  };
}

export function useBilletsCountClient(
  clientId?: number,
  filter?: BilletFilters
): UseQueryResult<GetBilletsCountResponse> {
  return useQuery(
    ["billets", clientId, filter],
    () => getBilletsCountClient(clientId, filter),
    {
      staleTime: 1000 * 60 * 5, // 5 Minutos
    }
  );
}
