import React, { useState, useEffect, useRef } from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../../service/api";

import { useToasts } from "react-toast-notifications";

import useOutsideAlerter from "../../../hook/outsideAlerter";

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";

import {
  Container,
  Filter,
  FilterCheck,
  ContainerList,
  TableContainer,
  FooterContainer,
  Form,
  FilterSelects,
} from "../styles";

interface ISalesman {
  id: number;
  cod: number;
  abbreviation: string;
  is_active: boolean;
}

interface IFilter {
  cod?: string;
  name?: string;
  active?: number;
}

const PanelSalesman: React.FC = () => {
  const outSideRef = useRef(null);
  const { addToast } = useToasts();

  const [brands, setBrands] = useState<ISalesman[]>([]);
  const [brandUpdate, setbrandUpdate] = useState<ISalesman[]>([]);

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({ active: 1 });

  useEffect(() => {
    (async () => {
      const response = await api.get<ISalesman[]>("/populate/salesman", {
        params: filters,
      });
      setBrands(response.data);
    })();
  }, [filters]);

  useEffect(() => {
    setBrands([...brandUpdate]);
  }, [brandUpdate]);

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setFilterView(false);
  }

  function handleAlterActive(brandProps: ISalesman) {
    let arr = brands;
    arr.forEach(async (brand) => {
      if (brandProps.cod === brand.cod) {
        try {
          await api.put(`/populate/salesman/${brand.cod}`, {
            active: Number(!brand.is_active),
          });
          setbrandUpdate(arr);

          brand.is_active = !brand.is_active;
        } catch (err) {
          const error = err as any;
          if (!error.response) {
            return addToast(
              "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }

          if (error.response.data.error === "cannot be changed") {
            return addToast("Status não pode ser alterado", {
              appearance: "warning",
              autoDismiss: true,
            });
          }

          if (error.response.status === 401) {
            return addToast("Sem permissão para esta ação", {
              appearance: "warning",
              autoDismiss: true,
            });
          }

          addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      }
    });
  }

  return (
    <Container>
      <header>
        <h2>Lista de Representante</h2>
      </header>
      <Filter>
        <button
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="button"
        >
          <FiSearch />
        </button>
        <input
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="text"
          name="filter"
          id="filter"
          readOnly={true}
        />
        <FilterSelects>
          <ul>
            {filters.cod && (
              <li>
                <span>COD: {filters.cod}</span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, cod: "" })}
                />
              </li>
            )}
            {filters.name && (
              <li>
                <span>Nome: {filters.name}</span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, name: "" })}
                />
              </li>
            )}
            {filters.active && (
              <li>
                <span>
                  Situação: {Number(filters.active) !== 0 ? "Ativo" : "Inativo"}
                </span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, active: undefined })}
                />
              </li>
            )}
          </ul>
        </FilterSelects>

        {filterView && (
          <FilterCheck ref={outSideRef}>
            <header>
              <button
                onClick={() => {
                  setFilterView(false);
                }}
              >
                {" "}
                Fechar{" "}
              </button>
            </header>
            <main>
              <Formik
                initialValues={{
                  cod: filters.cod,
                  name: filters.name,
                  active: filters.active,
                }}
                validationSchema={Yup.object({})}
                onSubmit={(data) => {
                  hadleFilter({
                    cod: data.cod?.trim(),
                    name: data.name?.trim(),
                    active: data.active,
                  });
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="field">
                      <label htmlFor="cod">COD</label>
                      <input id="cod" {...formik.getFieldProps("cod")} />
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="company_name">NOME</label>
                        <input
                          id="company_name"
                          {...formik.getFieldProps("company_name")}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="active">SITUAÇÃO</label>
                      <select id="active" {...formik.getFieldProps("active")}>
                        <option value=""></option>
                        <option value={1}>Ativo</option>
                        <option value={0}>Inativo</option>
                      </select>
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                )}
              </Formik>
            </main>
          </FilterCheck>
        )}
      </Filter>

      <ContainerList>
        <div className="headerTable">
          <h3>Representante</h3>
        </div>

        <TableContainer>
          <table>
            <thead>
              <tr>
                <th>COD</th>
                <th>NOME</th>
                <th>SITUAÇÃO</th>
              </tr>
            </thead>

            <tbody>
              {brands.map((brand: ISalesman) => (
                <tr key={brand.id}>
                  <td style={{ opacity: brand.is_active ? 1 : 0.4 }}>
                    {" "}
                    {brand.cod}{" "}
                  </td>
                  <td style={{ opacity: brand.is_active ? 1 : 0.4 }}>
                    {" "}
                    {brand.abbreviation}{" "}
                  </td>
                  <td>
                    <div className="stuationContainer">
                      <div
                        onClick={() => {
                          handleAlterActive(brand);
                        }}
                      >
                        {brand.is_active ? (
                          <AiFillCheckCircle color={"#009c27"} size={24} />
                        ) : (
                          <AiFillCloseCircle size={24} color={"#d10a0a"} />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
        <FooterContainer>
          {/* <span>Mostrando { clints.length } de { pagination.total } resultados</span>          

          <div>
            <button className={ pagination.page < 1 ? "buttonNull": ""} type="button" onClick={ pagination.page < 1 ?  ()=>{} : hadlePreviousPage } >ANTERIOR</button>
            <p style={{marginRight: 8}}>{pagination.page + 1 }</p>
            <button className={ pagination.page > pagination.pages ? "buttonNull": ""} type="button" onClick={ pagination.page > pagination.pages ?  ()=>{} : hadleNextPage } >PROXIMO</button>
          </div> */}
        </FooterContainer>
      </ContainerList>
    </Container>
  );
};

export default PanelSalesman;
