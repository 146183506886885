import Axios from "axios";
// @ts-ignore
// import { mask } from "remask";

interface FetchClientRequest {
  cnpj: string;
  razao_social: string;
  nome_fantasia: string;
  ddd_telefone_1: string;
  ddd_telefone_2: string;

  cep: string;
  uf: string;
  bairro: string;
  logradouro: string;
  municipio: string;
  numero: string;
  complemento: string;

  data_inicio_atividade: string;

  type: string;
  message: string;
}

interface FetchClientResponse {
  cnpj: string;
  razao_social: string;
  nome_fantasia: string;
  ddd_telefone_1: string;
  ddd_telefone_2: string;

  cep: string;
  uf: string;
  bairro: string;
  logradouro: string;
  municipio: string;
  numero: string;
  complemento: string;

  data_inicio_atividade: string;

  type: string;
  message: string;

  suframa: string;
  inscricao_estadual: string;
}

export async function getClientByCnpj(
  cnpj: string
): Promise<FetchClientResponse> {
  let response: FetchClientResponse = {} as FetchClientResponse;

  const cnpjNormalized = cnpj.replace(/\D/g, "");

  try {
    const { data } = await Axios.get<FetchClientRequest>(
      `https://brasilapi.com.br/api/cnpj/v1/${cnpjNormalized}`
    );

    Object.assign(response, data);

    if (!response.nome_fantasia) {
      response.nome_fantasia = response.razao_social;
    }
  } catch (error) {}

  try {
    const { data: clientCnpjJa } = await Axios.get<{
      registrations: { number: string; state: string; enabled: boolean }[];
    }>(`https://api.cnpja.com/office/${cnpjNormalized}?registrations=BR`, {
      headers: {
        Authorization:
          "6d5cc349-8f2a-47f2-91d1-d54efdae2c14-8c8c1550-aa34-438f-9b0f-badb93f4ecbe",
      },
    });

    const findRegistration = clientCnpjJa?.registrations?.find(
      (item) => item.state === response.uf
    );

    if (findRegistration && findRegistration.enabled === true) {
      response.inscricao_estadual = findRegistration.number;
      // response.inscricao_estadual = mask(findRegistration.number, [
      //   "999/9999999",
      //   "999999999.99-99",
      // ]);
    }
  } catch (error) {}

  try {
    const { data: clientSuframa } = await Axios.get<{ number: string }>(
      `https://api.cnpja.com/suframa/${cnpjNormalized}`,
      {
        headers: {
          Authorization:
            "6d5cc349-8f2a-47f2-91d1-d54efdae2c14-8c8c1550-aa34-438f-9b0f-badb93f4ecbe",
        },
      }
    );

    response.suframa = clientSuframa.number;
  } catch (error) {}

  return response;
}
