import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContainerForm = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 60px;
  grid-template-areas:
    "FORM"
    "BTN";

  .containerForm {
    grid-area: FORM;
    overflow-y: scroll;
    padding: 18px;
  }

  .status-option {
    font-size: 13px;
    font-weight: 400;
    border-radius: 4px;
    padding: 2px 5px;
  }

  .containerFormButton {
    grid-area: BTN;
    padding: 0 8px;
  }

  .container-subtitle {
    display: flex;
    align-items: center;

    button {
      height: 46px;
      width: 46px;
      border-radius: 50%;

      margin-left: 0.5rem;

      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      svg {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
      }
    }
  }
`;

export const ContainerPreviews = styled.ul`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .required-on {
    border: 2px solid #d21e26;
  }

  li {
    width: 100%;
    display: flex;
    align-items: center;
    background: #f0f0f0;
    padding: 10px 10px;
    margin-top: 10px;
    border-radius: 8px;

    img {
      height: 50px;
    }

    .fileInfo {
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      div {
        display: flex;
        flex-direction: column;
        margin: 0 5px;
      }

      .action-file {
        display: flex;
        flex-direction: row;

        .field-file {
          margin-left: 0.6rem;

          display: flex;
          flex-direction: row;
          align-items: center;

          label {
            font-size: 14px;
            margin-right: 4px;
            color: #555;

            .required {
              color: #f00;
              font-size: 11px;
            }
          }
          select {
            height: 35px;

            background: #fff;
            border-radius: 5px;
            border: 2px solid #aaa;
            padding: 4px 8px;

            font-size: 16px;
            color: #aaa;

            cursor: pointer;
            /* -webkit-appearance: none; */
            -moz-appearance: none;
            /* appearance: none;  */

            &:focus {
              border: 2px solid #333;
              color: #333;
            }
          }
        }

        .download-link {
          display: flex;
          align-items: center;
          margin-right: 10px;
          margin-left: 10px;
          svg {
            background: none;
            color: #d21e26;
          }
        }

        .fileInfo-field-required {
          border: 2px solid #d21e26 !important;
        }
      }

      strong {
        font-size: 14px;
        word-break: break-all;
      }

      span {
        font-size: 12px;
        color: #333;
      }

      button {
        margin-right: 10px;
        background: none;
        color: #d21e26;
      }
    }
  }
`;

export const TabletSelect = styled.table`
  margin-bottom: 24px;
  /* border: 1px solid #ededed; */
  border-collapse: collapse;
  border-spacing: 0;
  color: rgba(0, 0, 0, 0.8);

  font-weight: 400;
  padding: 0;
  width: 100%;

  & > thead {
    clip-path: none;
    height: auto;
    overflow: auto;
    position: relative;
    width: auto;
  }

  & > thead > tr {
    border: 0;
    display: table-row;
  }

  & > thead > tr > th {
    padding: 11px 24px;
    /* background-color: #ededed;
    border-bottom: 1px solid #f5f5f5; */
    font-weight: 600;
    vertical-align: middle;
    text-align: initial;
    font-size: 0.95rem;
  }

  & > tbody {
    display: table-row-group;
  }
  & > tbody > tr {
    height: 40px;
    border: 0;
    display: table-row;
    /* border: 1px solid #ededed; */
  }
  & > tbody > tr > td {
    display: table-cell;
    box-sizing: border-box;
    font-weight: 500;
    padding: 0 24px;
    font-weight: 400;
    font-size: 0.75rem;
  }

  .action-button {
    height: 32px;
    width: 32px;
    border-radius: 50%;

    margin-left: 0.5rem;

    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    svg {
      color: rgba(0, 0, 0, 0.54);
      font-size: 24px;
    }
  }

  /* & > tbody > tr:nth-child(2n) {
    background-color: #f5f5f5;
  } */
`;
