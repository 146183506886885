import React, { CSSProperties } from "react";
import Loading from "../../../loadings/Loading";

import { Container } from "./styles";

interface TableLayoutProps {
  title: string;

  isFetching?: boolean;

  style?: CSSProperties | undefined;
}

export const TableHeader: React.FC<TableLayoutProps> = ({
  children,
  title,
  isFetching = false,
  style,
}) => {
  return (
    <Container style={style}>
      <div className="headerTable">
        <div className="containerTitle">
          <h3>{title}</h3>
          {isFetching && (
            <Loading size={14} colorLoading="#333" borderColor="#888" />
          )}
        </div>

        <div className="containerActions">{children}</div>
      </div>
    </Container>
  );
};
