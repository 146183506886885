import React from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { Formik, Field } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import * as Yup from "yup";

import api from "../../../service/api";
import {
  Container,
  ContainerForm,
  Form,
  ButtunSubmit,
  Error,
  ContainerCheck,
} from "../styles";

interface IStatus {
  id?: number;
  cod?: number | undefined;
  name?: string;
  color: string;
  finisher: boolean;
  is_active?: boolean;
}

const CreateUser: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  async function handleSubmit(user: IStatus) {
    try {
      await api.post("/scheduling/status", user);

      addToast("Status de agendamento criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/status/agendamentos");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "cod status already exists") {
        return addToast("Este código já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2>Criar status de Agendamento</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            cod: undefined,
            name: "",
            color: "",
            finisher: false,
          }}
          validationSchema={Yup.object({
            cod: Yup.string().required("Cod é obrigatório"),
            name: Yup.string().required("Descrição é obrigatório"),
            color: Yup.string().required("Cor é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="name">COD</label>
                <Field name="cod" />
                {formik.touched.cod && formik.errors.cod ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.cod} </span>
                  </Error>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="name">Descrição</label>
                <input id="name" {...formik.getFieldProps("name")} />
                {formik.touched.name && formik.errors.name ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.name} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="name">Finalização</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.finisher ? "check" : ""}
                    onClick={() => formik.setFieldValue("finisher", true)}
                  >
                    SIM
                  </button>

                  <button
                    type="button"
                    className={!formik.values.finisher ? "check" : ""}
                    onClick={() => formik.setFieldValue("finisher", false)}
                  >
                    NÃO
                  </button>
                </ContainerCheck>
              </div>

              <div className="field">
                <label htmlFor="color">Cor de fundo</label>
                <input id="color" {...formik.getFieldProps("color")} />
                {formik.touched.color && formik.errors.color ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.color} </span>
                  </Error>
                ) : null}
              </div>
              <ButtunSubmit type="submit"> Criar status </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default CreateUser;
