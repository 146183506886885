import styled from "styled-components";

export const PopUpConfirmation = styled.div`
  width: 100%;
  height: 100%;
  padding: 35px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .headerPopUp {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    nav {
      display: flex;
      justify-content: center;

      margin-bottom: 35px;
    }

    span {
      font-size: 18px;
      color: #555;
      font-weight: 600;
      padding: 0 15px;
      text-align: center;
      margin-bottom: 4px;
    }

    .field {
      flex: 1;
      max-width: 550px;
      margin-top: 10px;
      padding: 10px 30px;
      /* width: 100%; */

      label {
        font-size: 20px;
        margin-bottom: 4px;
        color: #555;
      }

      textarea {
        padding: 10px 10px;
        background: #fff;
        height: 80px;
        width: 100%;
        font-size: 16px;
        color: #000;
        border: 2px solid #aaa;
        border-radius: 5px;
        /* resize: vertical; */
        resize: unset;

        ::-webkit-scrollbar {
          width: 8px;
        }

        ::-webkit-scrollbar-thumb {
          background: #333;
          width: 4px;
        }

        ::-webkit-scrollbar-track {
          background: #888;
        }

        &:focus {
          border: 2px solid #333;
        }
      }
    }
  }

  .buttonsPopUp {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      flex: 1;
      border-radius: 40px;
      padding: 10px 4px;
      font-weight: 700;
      font-size: 14px;
      border: 1.5px solid rgba(97, 97, 97, 0.4);

      transition: all 0.4s;

      &:nth-child(1) {
        margin: 0 0 0 40px;
        background-color: #fafafa;

        &:hover {
          opacity: 0.8;
        }
      }

      &:nth-child(2) {
        margin: 0 40px 0 30px;
        background-color: #1aa33f;
        color: #fff;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .field-group {
    display: flex;
    padding: 10px 50px;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
    /* flex-flow: row; */

    .field {
      padding: 0;
    }
  }

  /* @media (max-width: 900px) {
    .field-group {
      flex-flow: column;
    }
  } */
`;
