import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import { IoMdSearch } from "react-icons/io";
import Loadind from "../../../components/loadings/Loading";
import ModalLoading from "../../../components/loadings/ScreenLoading";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import * as Yup from "yup";
import { panelTypesDefault } from "../../../@types/panelTypes";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Textarea } from "../../../components/Form/Textarea";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { getClientByCnpj } from "../../../use-cases/getClientByCnpj";
import { addressAbbreviation } from "../../../utils/addressAbbreviation";
import {
  DropzoneClientFile,
  FileProps,
} from "../../detail/Client/DropzoneClientFile";
import { ButtunSubmit, ContainerForm, Error, Form } from "../styles";
import { Contact, Contacts } from "./Contacts";

export interface ClientCreate {
  cnpj: string;
  company_name: string;
  trade_name: string;
  email: string;
  phone: string;
  phone2: string;
  emailAccountant: string;
  emailInvoice: string;
  suframa: string;
  stateRegistration: string;
  observation?: string;

  adress: {
    zip_code: string;
    uf: string;
    city: string;
    neighborhood: string;
    street: string;
    numberStreet: string;
    complement?: string;
  };
}

export interface FetchRequestClient {
  cnpj: string;
  razao_social: string;
  nome_fantasia: string;
  ddd_telefone_1: string;
  ddd_telefone_2: string;

  cep: string;
  uf: string;
  bairro: string;
  logradouro: string;
  municipio: string;
  numero: string;
  complemento: string;

  data_inicio_atividade: string;

  type: string;
  message: string;
}

interface Props extends panelTypesDefault {}

const CreateClient: React.FC<Props> = ({ admin }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [loadingFetchClient, setLoadingFetchClient] = useState(false);

  const [onUploadProgress, setOnUploadProgress] = useState(0);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [files, setFiles] = useState<FileProps[]>([]);
  const [onErrorSelect, setOnErrorSelect] = useState(false);

  const [contacts, setContacts] = useState<Contact[]>([]);

  const formik = useFormik({
    initialValues: {
      cnpj: "",
      company_name: "",
      trade_name: "",
      email: "",
      phone: "",
      cellPhone: "",
      phone2: "",
      emailAccountant: "",
      emailInvoice: "",
      suframa: "",
      stateRegistration: "",
      foundationDate: "",

      observation: "",

      adress: {
        zip_code: "",
        uf: "",
        city: "",
        neighborhood: "",
        street: "",
        numberStreet: "",
        complement: "",
      },
    },
    validationSchema: Yup.object({
      cnpj: Yup.number().typeError("Apenas números").required("É obrigatório"),
      company_name: Yup.string().required("É obrigatório"),
      trade_name: Yup.string().required("É obrigatório"),
      phone: Yup.string().required("É obrigatório"),

      emailAccountant: Yup.string()
        .email("E-mail inválido")
        .required("É obrigatório"),

      adress: Yup.object({
        zip_code: Yup.string().required("É obrigatório"),
        uf: Yup.string().required("É obrigatório"),
        city: Yup.string().required("É obrigatório"),
        neighborhood: Yup.string().required("É obrigatório"),
        street: Yup.string().required("É obrigatório"),
        numberStreet: Yup.string().required("É obrigatório"),
      }),
    }),
    onSubmit: (data) => handleSubmit(data as any),
  });

  async function handleSubmit(client: ClientCreate) {
    try {
      if (
        files.map((f) => f.typeFile).filter((f) => f).length !== files.length
      ) {
        addToast("É necessário informar tipo todos arquivos", {
          appearance: "warning",
          autoDismiss: true,
        });
        return setOnErrorSelect(true);
      }

      if (
        files.length < 3 ||
        files.filter((f) => f.typeFile === "FACHADA").length <= 0 ||
        files.filter((f) => f.typeFile === "INTERIOR").length <= 0
      ) {
        return addToast(
          "Necessário no mínimo 3 imagens, e ao menos uma em cada categoria ( FACHADA E INTERIOR )",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }

      if (contacts.length < 1) {
        return addToast("Necessário no mínimo 1 contato cadastrado.", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      const created = await api.post("/request-update-client/register/", {
        client,
        contacts,
      });

      const formData = new FormData();
      files.forEach((element) => {
        formData.append("file", element.file);
        formData.append("typeFile", String(element.typeFile));
      });

      setLoadingUpload(true);
      api.post(
        `request-update-client/register/upload/${created.data.id}`,
        formData,
        {
          onUploadProgress: (e) => {
            const loaded = +e.loaded;
            const total = +e.total;
            //@ts-ignore
            var progress = parseInt(Math.round((loaded * 100) / total));

            setOnUploadProgress(progress);
          },
        }
      );

      addToast("Subindo as imagens", {
        appearance: "info",
        autoDismiss: true,
      });
    } catch (err) {
      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este cliente já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast("Erro interno", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }

  async function handleFetchClient(cnpj: string) {
    setLoadingFetchClient(true);
    try {
      const client = await getClientByCnpj(cnpj);

      if (!client.cnpj) {
        addToast("Ocorreu um erro ao buscar dados, verifique seu cnpj.", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      formik.setFieldValue(
        "company_name",
        addressAbbreviation(client.razao_social)
      );
      formik.setFieldValue(
        "trade_name",
        addressAbbreviation(client.nome_fantasia)
      );
      formik.setFieldValue(
        "adress.neighborhood",
        addressAbbreviation(client.bairro)
      );
      formik.setFieldValue(
        "adress.street",
        addressAbbreviation(client.logradouro)
      );
      formik.setFieldValue("phone", client.ddd_telefone_1);
      formik.setFieldValue("phone2", client.ddd_telefone_2);
      formik.setFieldValue("adress.zip_code", client.cep);
      formik.setFieldValue("adress.uf", client.uf);
      formik.setFieldValue(
        "foundationDate",
        new Date(client.data_inicio_atividade)
      );
      formik.setFieldValue("adress.city", client.municipio);
      formik.setFieldValue(
        "adress.numberStreet",
        addressAbbreviation(String(client.numero).replace(/\D/g, ""))
      );
      formik.setFieldValue("adress.complement", client.complemento);
      formik.setFieldValue("suframa", client.suframa);
      formik.setFieldValue("stateRegistration", client.inscricao_estadual);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um problema ao consultar os dados, Tente novamente mais tarde",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingFetchClient(false);
    }
  }

  useEffect(() => {
    if (loadingUpload) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [loadingUpload]);

  useEffect(() => {
    (async () => {
      if (onUploadProgress >= 100) {
        setLoadingUpload(false);
        setOnUploadProgress(0);

        queryClient.invalidateQueries(["RequestUpdateClients"]);

        addToast("Enviado para avaliação interna com sucesso.", {
          appearance: "success",
          autoDismiss: true,
        });

        history.push(`/${admin ? "admin" : "representante"}/atualizacao-fotos`);
      }
    })();

    // eslint-disable-next-line
  }, [onUploadProgress]);

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "As alterações feitas podem não ser salvas.";
  };

  return (
    <CreateAndDetailLayout>
      <PanelAndDetailAndCreateHeader title={"Criar clientes"} goBack />

      <ContainerForm>
        <Form onSubmit={formik.handleSubmit}>
          <h3>Dados</h3>
          <div className="field">
            <label htmlFor="cnpj">CNPJ</label>
            <div className="containerCnpj">
              <input
                type="text"
                maxLength={18}
                {...formik.getFieldProps("cnpj")}
                placeholder="Exemplo CNPJ: 00000000000000"
              />
              <button
                type="button"
                onClick={() => {
                  handleFetchClient(formik.values.cnpj);
                }}
              >
                {loadingFetchClient ? (
                  <Loadind
                    size={22}
                    borderSize={3.5}
                    colorLoading="rgba(255,255,255)"
                    borderColor="rgba(255,255,255, 0.3)"
                  />
                ) : (
                  <>
                    <IoMdSearch />
                    Buscar
                  </>
                )}
              </button>
            </div>
            {formik.touched.cnpj && formik.errors.cnpj ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formik.errors.cnpj} </span>
              </Error>
            ) : null}
          </div>

          <GroupInput>
            <Input
              readOnly
              label="Razão social"
              {...formik.getFieldProps("company_name")}
              error={
                formik.touched.company_name && formik.errors.company_name
                  ? formik.errors.company_name
                  : undefined
              }
            />
            <Input
              readOnly
              label="Nome fantasia"
              {...formik.getFieldProps("trade_name")}
              error={
                formik.touched.trade_name && formik.errors.trade_name
                  ? formik.errors.trade_name
                  : undefined
              }
            />
          </GroupInput>

          <GroupInput>
            <Input
              readOnly
              label="Suframa"
              {...formik.getFieldProps("suframa")}
              error={
                formik.touched.suframa && formik.errors.suframa
                  ? formik.errors.suframa
                  : undefined
              }
            />
            <Input
              readOnly
              label="Inscrição estadual"
              {...formik.getFieldProps("stateRegistration")}
              error={
                formik.touched.stateRegistration &&
                formik.errors.stateRegistration
                  ? formik.errors.stateRegistration
                  : undefined
              }
            />
          </GroupInput>

          <GroupInput>
            <Input
              label="Email"
              {...formik.getFieldProps("emailAccountant")}
              error={
                formik.touched.emailAccountant && formik.errors.emailAccountant
                  ? formik.errors.emailAccountant
                  : undefined
              }
            />
            <Input
              label="Email NFE"
              {...formik.getFieldProps("emailInvoice")}
              error={
                formik.touched.emailInvoice && formik.errors.emailInvoice
                  ? formik.errors.emailInvoice
                  : undefined
              }
            />
          </GroupInput>

          <GroupInput>
            <Input
              label="Telefone"
              maxLength={11}
              {...formik.getFieldProps("phone")}
              error={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : undefined
              }
            />
            <Input
              label="Telefone 2"
              maxLength={11}
              {...formik.getFieldProps("phone2")}
              error={
                formik.touched.phone2 && formik.errors.phone2
                  ? formik.errors.phone2
                  : undefined
              }
            />
            <Input
              label="Celular"
              maxLength={11}
              {...formik.getFieldProps("cellPhone")}
              error={
                formik.touched.cellPhone && formik.errors.cellPhone
                  ? formik.errors.cellPhone
                  : undefined
              }
            />
          </GroupInput>

          <Textarea
            label="Observação"
            {...formik.getFieldProps("observation")}
            error={
              formik.touched.observation && formik.errors.observation
                ? formik.errors.observation
                : undefined
            }
          />

          <h3>Endereço</h3>
          <Input
            readOnly
            label="CEP"
            {...formik.getFieldProps("adress.zip_code")}
            error={
              formik.touched.adress?.zip_code && formik.errors.adress?.zip_code
                ? formik.errors.adress?.zip_code
                : undefined
            }
          />

          <GroupInput>
            <Input
              readOnly
              label="UF"
              {...formik.getFieldProps("adress.uf")}
              error={
                formik.touched.adress?.uf && formik.errors.adress?.uf
                  ? formik.errors.adress?.uf
                  : undefined
              }
            />
            <Input
              readOnly
              label="Cidade"
              {...formik.getFieldProps("adress.city")}
              error={
                formik.touched.adress?.city && formik.errors.adress?.city
                  ? formik.errors.adress?.city
                  : undefined
              }
            />
          </GroupInput>

          <GroupInput>
            <Input
              readOnly
              label="Bairro"
              {...formik.getFieldProps("adress.neighborhood")}
              error={
                formik.touched.adress?.neighborhood &&
                formik.errors.adress?.neighborhood
                  ? formik.errors.adress?.neighborhood
                  : undefined
              }
            />
            <Input
              label="Logradouro"
              {...formik.getFieldProps("adress.street")}
              error={
                formik.touched.adress?.street && formik.errors.adress?.street
                  ? formik.errors.adress?.street
                  : undefined
              }
            />
          </GroupInput>

          <GroupInput>
            <Input
              label="Número"
              {...formik.getFieldProps("adress.numberStreet")}
              error={
                formik.touched.adress?.numberStreet &&
                formik.errors.adress?.numberStreet
                  ? formik.errors.adress?.numberStreet
                  : undefined
              }
            />

            <Input
              label="Complemento"
              {...formik.getFieldProps("adress.complement")}
              error={
                formik.touched.adress?.complement &&
                formik.errors.adress?.complement
                  ? formik.errors.adress?.complement
                  : undefined
              }
            />
          </GroupInput>

          <Contacts contacts={contacts} setContacts={setContacts} />

          <DropzoneClientFile
            files={files}
            setFiles={setFiles}
            onErrorSelect={onErrorSelect}
          />

          <ButtunSubmit type="submit">Criar</ButtunSubmit>
        </Form>
      </ContainerForm>

      {loadingUpload ? <ModalLoading progress={onUploadProgress} /> : null}
    </CreateAndDetailLayout>
  );
};

export default CreateClient;
