export function arraysHaveSameElements<T>(arr1: T[], arr2: T[]): boolean {
  for (const one of arr1) {
    for (const two of arr2) {
      if (one === two) {
        return true;
      }
    }
  }

  return false;
}
