import styled from "styled-components";

export const ContainerBody = styled.div`
  overflow-x: scroll;

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    background: #fff;
    height: 2px;
  }
`;

export const Container = styled.table`
  border: 1px solid #ededed;
  border-collapse: collapse;
  border-spacing: 0;
  color: rgba(0, 0, 0, 0.8);

  font-weight: 400;
  padding: 0;
  width: 100%;

  .btn-action {
    button {
      background-color: #d21e26;
      border-radius: 6px;
      padding: 5px;

      color: #fff;
      font-weight: bold;
    }
  }

  & > thead {
    clip-path: none;
    height: auto;
    overflow: auto;
    position: relative;
    width: auto;
  }

  & > thead > tr {
    border: 0;
    display: table-row;
  }

  & > thead > tr > th {
    padding: 11px 24px;
    background-color: #ededed;
    border-bottom: 1px solid #f5f5f5;
    font-weight: 600;
    vertical-align: middle;
    text-align: initial;
    font-size: 0.95rem;
  }

  & > tbody {
    display: table-row-group;
  }

  & > tbody > tr {
    height: 40px;
    border: 0;
    display: table-row;
    border: 1px solid #ededed;
  }
  & > tbody > tr > td {
    display: table-cell;
    box-sizing: border-box;
    font-weight: 500;
    padding: 0 24px;
    font-weight: 400;
    font-size: 0.75rem;
  }

  & > tbody > tr:nth-child(2n) {
    background-color: #f5f5f5;
  }
`;
