import { useQuery, UseQueryResult } from "react-query";
import api from "../../service/api";

export interface AnalyticIten {
  description: string;
  amount: number;
}

export interface AnalyticResponse {
  totalAccessClientLastDays: number;
  totalAccessSellerLastDays: number;
  totalAccessUserLastDays: number;
  dayMediaClient: number;
  dayMediaSeller: number;
  totalClientAccess: number;
  totalSellerAccess: number;
  lastDays: number;
  sellerActiveAccess: number;
  sellerNeverAccess: {
    cod: number;
    abbreviation: string;
  }[];

  accessThreeMonthsClient: {
    data: {
      date: Date;
      content: AnalyticItem[];
    }[];
    descriptions: string[];
  };
  accessThreeMonthsSeller: {
    data: {
      date: Date;
      content: AnalyticItem[];
    }[];
    descriptions: string[];
  };
}

export interface AnalyticItem {
  description: string;
  total: number;
}

interface AnalyticResponseNormalized {
  totalAccessClientLastDays: number;
  totalAccessSellerLastDays: number;
  totalAccessUserLastDays: number;
  dayMediaClient: number;
  dayMediaSeller: number;
  totalClientAccess: number;
  totalSellerAccess: number;
  lastDays: number;
  sellerActiveAccess: number;
  sellerNeverAccess: {
    cod: number;
    abbreviation: string;
  }[];

  accessThreeMonthsClient: {
    date: Date[];
    content: {
      name: string;
      data: number[];
    }[];
  };
  accessThreeMonthsSeller: {
    date: Date[];
    content: {
      name: string;
      data: number[];
    }[];
  };
}

export async function getAccessAnalytic(): Promise<
  AnalyticResponseNormalized | undefined
> {
  const response = await api.get<AnalyticResponse>("/analytic/access");

  var contentAccessThreeMonthsClientNormalized: {
    name: string;
    data: number[];
  }[] = [];

  response.data.accessThreeMonthsClient.descriptions.forEach((position) =>
    contentAccessThreeMonthsClientNormalized.push({ name: position, data: [] })
  );
  response.data.accessThreeMonthsClient.data.forEach((item) => {
    item.content.forEach((content) => {
      const findOne = contentAccessThreeMonthsClientNormalized.find(
        (f) => f.name === content.description
      );
      if (findOne) {
        findOne.data.push(content.total);
      }
    });
  });
  var contentAccessThreeMonthsSellerNormalized: {
    name: string;
    data: number[];
  }[] = [];

  response.data.accessThreeMonthsSeller.descriptions.forEach((position) =>
    contentAccessThreeMonthsSellerNormalized.push({ name: position, data: [] })
  );
  response.data.accessThreeMonthsSeller.data.forEach((item) => {
    item.content.forEach((content) => {
      const findOne = contentAccessThreeMonthsSellerNormalized.find(
        (f) => f.name === content.description
      );
      if (findOne) {
        findOne.data.push(content.total);
      }
    });
  });

  return {
    ...response.data,
    accessThreeMonthsClient: {
      date: response.data.accessThreeMonthsClient.data.map((item) => item.date),
      content: contentAccessThreeMonthsClientNormalized,
    },

    accessThreeMonthsSeller: {
      date: response.data.accessThreeMonthsSeller.data.map((item) => item.date),
      content: contentAccessThreeMonthsSellerNormalized,
    },
  };
}

export function useAccessAnalytic(): UseQueryResult<AnalyticResponseNormalized> {
  return useQuery(["access-analytic"], () => getAccessAnalytic(), {
    staleTime: 1000 * 60 * 20, // 10 Minutos
  });
}
