import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { MdAddBox } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import Modal from "../../../../components/ModalLib";
import api from "../../../../service/api";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import { RowNone, TabletSelect } from "./styles";

import { IStatus } from "../";

import {
  Form,
  ModalBody,
  TableContainer,
  Filter,
  FooterContainer,
  ContainerList,
  FilterSelects,
  MainFilter,
  FilterCheck,
} from "../../../create/styles";

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IResponseAPI {
  contents: IStatus[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface ProviderComponentProps {
  title: string;

  setUsersLinks: React.Dispatch<React.SetStateAction<IStatus[]>>;
  usersLinks: IStatus[];

  disabled?: boolean;
}

interface IFilter {
  cod?: string;
  name?: string;
  active?: number;
  finisher?: number;
}

export const SelectStatus: React.FC<ProviderComponentProps> = ({
  title,
  setUsersLinks,
  usersLinks,

  disabled = false,
}) => {
  const outSideRefFilter = useRef(null);

  const [isDisabledModal, setIsDisabledModal] = useState<boolean>(false);

  const [providers, setProviders] = useState<IStatus[]>([]);
  const [filters, setFilters] = useState<IFilter>({});
  const [filterView, setFilterView] = useState(false);

  const [modalVisibleProvider, setModalVisibleProvider] = useState(false);
  // const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  // const [requestPagination, setRequestPagination] =
  //   useState<IRequestPagination>({ limitRequest: 10 } as IRequestPagination);

  const formikFilter = useFormik({
    initialValues: {
      cod: filters.cod,
      name: filters.name,
      finisher: filters.finisher,
      active: filters.active,
    },

    validationSchema: Yup.object({}),

    onSubmit: (data) => {
      hadleFilter({
        cod: data.cod?.trim(),
        name: data.name,
        finisher: data.finisher,
        active: data.active,
      });
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const contentsDB = await api.get("/headerDatasheet/datasheet/status", {
          params: { ...filters },
        });

        // const { limit, page, pages, total } = contentsDB.data;

        setProviders(contentsDB.data);
        // setPagination({ limit, page, pages, total });
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters]);

  // function hadleNextPage() {
  //   setRequestPagination({
  //     ...requestPagination,
  //     pageRequest: pagination.page + 1,
  //   });
  // }
  // function hadlePreviousPage() {
  //   setRequestPagination({
  //     ...requestPagination,
  //     pageRequest: pagination.page - 1,
  //   });
  // }
  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setFilterView(false);
  }

  function handleSelectProvider(provider: IStatus) {
    const existProvider = usersLinks.find((f) => f.id === provider.id);

    if (!existProvider) {
      setUsersLinks([...usersLinks, provider]);
    }

    if (!isDisabledModal) setModalVisibleProvider(false);
  }
  function handleRemoveProvider(provider: IStatus) {
    const providersNoRemove = usersLinks.filter((f) => f.id !== provider.id);

    setUsersLinks([...providersNoRemove]);
  }

  return (
    <>
      <div className="container-subtitle">
        <h3>{title}</h3>

        {!disabled && (
          <button
            type="button"
            className="action-button"
            onClick={() => {
              setModalVisibleProvider(true);
            }}
          >
            <MdAddBox />
          </button>
        )}
      </div>

      <TabletSelect>
        <thead>
          <tr>
            <th>Cód</th>
            <th>Descrição</th>
            {!disabled && <th>Ação</th>}
          </tr>
        </thead>

        <tbody>
          {usersLinks.map((provider, indexData) => (
            <tr key={indexData}>
              <td>{provider.cod}</td>
              <td>{provider.name}</td>
              {!disabled && (
                <td>
                  <button
                    type="button"
                    className="action-button"
                    onClick={() => {
                      handleRemoveProvider(provider);
                    }}
                  >
                    <IoMdClose />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </TabletSelect>

      {usersLinks.length < 1 && (
        <RowNone>Não há registros a serem exibidos</RowNone>
      )}

      <Modal
        title="Vincular situação"
        modalVisible={modalVisibleProvider}
        setModalVisible={setModalVisibleProvider}
        maxWidth={660}
        maxHeight={600}
        isDisabled={isDisabledModal}
        setIsDisabled={setIsDisabledModal}
      >
        <ModalBody>
          <Filter>
            <button
              onClick={() => {
                setFilterView(!filterView);
              }}
              type="button"
            >
              <FiSearch />
            </button>
            <input
              onClick={() => {
                setFilterView(!filterView);
              }}
              type="text"
              name="filter"
              id="filter"
              readOnly={true}
            />
            <FilterSelects>
              <ul>
                {filters.cod && (
                  <li>
                    <span>COD: {filters.cod}</span>{" "}
                    <IoMdCloseCircle
                      onClick={() => setFilters({ ...filters, cod: "" })}
                    />
                  </li>
                )}
                {filters.name && (
                  <li>
                    <span>Descrição: {filters.name}</span>{" "}
                    <IoMdCloseCircle
                      onClick={() => setFilters({ ...filters, name: "" })}
                    />
                  </li>
                )}
                {filters.finisher && (
                  <li>
                    <span>
                      Finalização:{" "}
                      {Number(filters.finisher) === 0 ? "Sim" : "Não"}
                    </span>{" "}
                    <IoMdCloseCircle
                      onClick={() =>
                        setFilters({ ...filters, finisher: undefined })
                      }
                    />
                  </li>
                )}
                {filters.active && (
                  <li>
                    <span>
                      Situação:{" "}
                      {Number(filters.active) === 0 ? "Inativo" : "Ativo"}
                    </span>{" "}
                    <IoMdCloseCircle
                      onClick={() =>
                        setFilters({ ...filters, active: undefined })
                      }
                    />
                  </li>
                )}
              </ul>
            </FilterSelects>

            {filterView && (
              <FilterCheck ref={outSideRefFilter}>
                <MainFilter>
                  <Form onSubmit={formikFilter.handleSubmit}>
                    <div className="field">
                      <label htmlFor="cod">COD</label>
                      <input id="cod" {...formikFilter.getFieldProps("cod")} />
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="name">DESCRIÇÃO</label>
                        <input
                          id="name"
                          {...formikFilter.getFieldProps("name")}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="finisher">FINALIZAÇÃO</label>
                      <select
                        id="finisher"
                        {...formikFilter.getFieldProps("finisher")}
                      >
                        <option value=""></option>
                        <option value={1}>SIM</option>
                        <option value={0}>NÃO</option>
                      </select>
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                </MainFilter>
              </FilterCheck>
            )}
          </Filter>

          <ContainerList>
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th>COD</th>
                    <th>DESCRIÇÃO</th>
                    <th>COR</th>
                  </tr>
                </thead>

                <tbody>
                  {providers.map((provider: IStatus) => (
                    <tr
                      key={provider.id}
                      onClick={() => handleSelectProvider(provider)}
                    >
                      <td> {provider.cod} </td>
                      <td> {provider.name} </td>
                      <td>
                        <span
                          className="status"
                          style={{
                            backgroundColor: provider.color,
                          }}
                        >
                          {provider.color}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
            <FooterContainer>
              <span>
                Mostrando {providers.length} de {providers.length} resultados
              </span>

              {/* <div>
                <button
                  className={pagination.page <= 0 ? "buttonNull" : ""}
                  type="button"
                  onClick={pagination.page <= 0 ? () => {} : hadlePreviousPage}
                >
                  ANTERIOR
                </button>
                <p style={{ marginRight: 8 }}>{pagination.page + 1}</p>
                <button
                  className={
                    pagination.page >= pagination.pages ? "buttonNull" : ""
                  }
                  type="button"
                  onClick={
                    pagination.page >= pagination.pages
                      ? () => {}
                      : hadleNextPage
                  }
                >
                  PROXIMO
                </button>
              </div> */}
            </FooterContainer>
          </ContainerList>
        </ModalBody>
      </Modal>
    </>
  );
};
