import React from "react";
import { Container } from "./styles";

interface BoxHeaderInfoProps {
  data: {
    title: string;
    value: string;
  };
}

export const BoxHeaderInfo = ({
  data: { title, value },
}: BoxHeaderInfoProps) => {
  return (
    <Container>
      <div className="box">
        <div>
          <h5>{title}</h5>
        </div>
        <p>{value}</p>
      </div>
    </Container>
  );
};
