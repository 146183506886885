import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Ncm = {
  id: number;
  code: number;

  group?: {
    id: number;
    description: string;
  }[];

  category?: {
    id: number;
    description: string;
  }[];
  material?: {
    id: number;
    description: string;
  }[];
  material_nature?: {
    id: number;
    description: string;
  }[];
  genre?: {
    initials: string;
    name: string;
  }[];
  type?: {
    id: number;
    name: string;
  }[];

  created_at: Date;
};

export type NcmFilters = {
  code?: string;
};

type GetNcmsResponse = {
  ncms: Ncm[];
  totalCount: number;
};

interface NcmApiResponse extends ResponsePanelApiDefault {
  contents: Ncm[];
}

export async function getNcms(
  page: number,
  filter?: NcmFilters,
  registersPerPag?: number
): Promise<GetNcmsResponse> {
  const { data } = await api.get<NcmApiResponse>("/populate/ncms", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag, ...filter },
  });

  return {
    totalCount: data.total,
    ncms: data.contents,
  };
}

export function useNcms(
  page: number,
  filter?: NcmFilters,
  registersPerPag?: number
): UseQueryResult<GetNcmsResponse> {
  return useQuery(
    ["ncms", page, filter],
    () => getNcms(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneNcm(id?: number): Promise<Ncm | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<Ncm>(`/populate/ncms/show/${id}`);

  return data;
}

export function useOneNcm(id?: number): UseQueryResult<Ncm> | undefined {
  return useQuery(["ncm", id], () => getOneNcm(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
