import React from "react";

import { Container, BtnTab } from "./styles";

interface HeaderTabProps {
  tabs: TabProps[];
  keyActive: string;
  setKeyActive: React.Dispatch<React.SetStateAction<string>>;
}

interface TabProps {
  key: string;
  description: string;
}

export const HeaderTab: React.FC<HeaderTabProps> = ({
  tabs,
  keyActive,
  setKeyActive,
}) => {
  return (
    <Container>
      {tabs.map((tab) => (
        <BtnTab
          type="button"
          key={tab.key}
          onClick={() => setKeyActive(tab.key)}
          isActive={tab.key === keyActive ? true : false}
        >
          {tab.description}
        </BtnTab>
      ))}
    </Container>
  );
};
