import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

export const Container = styled.div`
  padding: 0.645rem;

  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #eee;
  height: 6rem;

  display: flex;
  align-items: center;

  & + & {
    margin-top: 0.4rem;
  }

  @media (max-width: 650px) {
    height: 16rem;
  }
`;

export const ContentInfo = styled.div`
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    background: #808389;
    height: 1.5rem;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  & > strong {
    font-size: 0.9rem;
  }

  & > div {
    color: #808389;
    font-size: 0.875rem;
    font-weight: lighter;
  }

  & > div > div {
    display: flex;

    p + p {
      margin-left: 0.7rem;
    }
  }

  @media (max-width: 650px) {
    p {
      font-size: 0.6rem;
    }

    button {
      font-size: 0.6rem;
      height: 1rem;
    }

    & > div {
      width: 100%;
    }

    align-items: start;
    flex-direction: column;
  }
`;

export const Image = styled(LazyLoadImage)`
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  border-radius: 6px;

  img {
    height: 100% !important;
  }
`;

export const ContainerImage = styled.div`
  height: 100%;
  width: 6rem;
  height: 5rem;
  margin-right: 1.5rem;
`;

export const Table = styled.table``;
