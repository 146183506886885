import React from "react";
import { Container, ContainerImage, ContentInfo, Image } from "./styled";
interface ProductProps {
  data: {
    id: string;
    status: string;
    description: string;
    reference: string;
    grid: string;
    quantity: string;
    origin: string;
    value: string;
    discount: string;
    totalValue: string;
    uri: string;
  };
}

const NOIMAGE_LINK =
  "https://alpar2.sfo3.digitaloceanspaces.com/Alpar/no-image.jpg";

export const Product: React.FC<ProductProps> = ({ data: product }) => {
  return (
    <Container>
      <ContainerImage>
        <Image
          effect="blur"
          loading="lazy"
          height="100%"
          width="100%"
          src={product.uri}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = NOIMAGE_LINK;
          }}
          alt={product.description}
        />
      </ContainerImage>

      <ContentInfo>
        <strong>{product.description}</strong>
        <div>
          <p>
            Situação:{" "}
            <strong
              style={{
                color:
                  product.status === "Cancelado"
                    ? "#d21e26"
                    : product.status === "Faturado"
                    ? "#21b543"
                    : product.status === "Recusado" ||
                      product.status === "Em análise"
                    ? "#9700c2"
                    : "#417DAB",
              }}
            >
              {product.status}
            </strong>
          </p>
          <p>Referência: {product.reference}</p>
          <p>
            Grade: {product.grid} / Origem:{product.origin ?? "-"}
          </p>

          <p>
            Valor unitário: <strong>{product.value}</strong>
          </p>
          <p>
            Quantidade: <strong>{product.quantity}</strong>
          </p>
          {product.discount && (
            <p>
              Desconto: <strong>{product.discount}</strong>
            </p>
          )}
          <p>
            Subtotal: <strong>{product.totalValue}</strong>
          </p>
        </div>
      </ContentInfo>
    </Container>
  );
};
