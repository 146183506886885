import React from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useFormik } from "formik";
import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import * as Yup from "yup";

import { IPermissionUser } from "../../../@types";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Select } from "../../../components/Form/Select";
import api from "../../../service/api";
import { ButtunSubmit, Container, ContainerForm, Error, Form } from "../styles";

interface HeaderProps {
  permission: IPermissionUser;
}

const CreateUser: React.FC<HeaderProps> = ({ permission }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  async function handleSubmit(data: IPermissionUser) {
    try {
      const newDate = {
        ...data,
        is_distribution: Boolean(Number(data.is_distribution)),
        is_licensed: Boolean(Number(data.is_licensed)),
        is_validationSalesType: Boolean(Number(data.is_validationSalesType)),
      };

      await api.post("/permission", newDate);

      addToast("Permissão criada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/permissoes");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      name: undefined,

      devolution: 1,
      indemnity: 1,
      billet: 1,
      order: 1,
      forecast: 1,
      forecastInternal: 1,
      clientPJ: 1,
      userAdmin: 1,
      seller: 1,
      analytic: 1,
      config: 1,
      import: 1,
      permission: 1,
      devolutionLegacy: 1,
      payment: 1,
      provider: 1,
      scheduling: 1,
      collection: 1,
      showFile: 1,
      datasheet: 1,
      datasheetQuality: 1,
      requestUpdateClient: 1,
      serviceInvoice: 1,

      is_validationSalesType: undefined,
      is_licensed: undefined,
      is_distribution: undefined,
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Nome é obrigatório"),
      devolution: Yup.number().required("Obrigatório"),
      indemnity: Yup.number().required("Obrigatório"),
      billet: Yup.number().required("Obrigatório"),
      order: Yup.number().required("Obrigatório"),
      forecast: Yup.number().required("Obrigatório"),
      forecastInternal: Yup.number().required("Obrigatório"),
      clientPJ: Yup.number().required("Obrigatório"),
      userAdmin: Yup.number().required("Obrigatório"),
      seller: Yup.number().required("Obrigatório"),
      analytic: Yup.number().required("Obrigatório"),
      config: Yup.number().required("Obrigatório"),
      import: Yup.number().required("Obrigatório"),
      permission: Yup.number().required("Obrigatório"),
      devolutionLegacy: Yup.number().required("Obrigatório"),
      payment: Yup.number().required("Obrigatório"),
      provider: Yup.number().required("Obrigatório"),
      scheduling: Yup.number().required("Obrigatório"),
      collection: Yup.number().required("Obrigatório"),
      showFile: Yup.number().required("Obrigatório"),
      datasheet: Yup.number().required("Obrigatório"),
      datasheetQuality: Yup.number().required("Obrigatório"),
      requestUpdateClient: Yup.number().required("Obrigatório"),
      serviceInvoice: Yup.number().required("Obrigatório"),
    }),

    onSubmit: (data) => handleSubmit(data as any),
  });

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2>Criar perfil permissionamento</h2>
      </header>

      <ContainerForm>
        <Form
          onSubmit={
            permission && permission.permission && permission.permission > 2
              ? formik.handleSubmit
              : () => {}
          }
        >
          <div className="field">
            <label htmlFor="name">Nome</label>
            <input id="name" {...formik.getFieldProps("name")} />
            {formik.touched.name && formik.errors.name ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formik.errors.name} </span>
              </Error>
            ) : null}
          </div>

          <h3>Validar Tipo de Venda</h3>

          <Select
            data={[
              { name: "SIM", value: "1" },
              { name: "NÃO", value: "0" },
            ]}
            label="Validar Tipo de Venda (Distribuição e Licenciamento)"
            {...formik.getFieldProps("is_validationSalesType")}
            error={
              formik.touched.is_validationSalesType &&
              formik.errors.is_validationSalesType
                ? formik.errors.is_validationSalesType
                : undefined
            }
          />

          {Boolean(Number(formik.values.is_validationSalesType)) === true && (
            <GroupInput>
              <Select
                data={[
                  { name: "SIM", value: "1" },
                  { name: "NÃO", value: "0" },
                ]}
                label="É Licenciamento"
                {...formik.getFieldProps("is_licensed")}
                error={
                  formik.touched.is_licensed && formik.errors.is_licensed
                    ? formik.errors.is_licensed
                    : undefined
                }
              />
              <Select
                data={[
                  { name: "SIM", value: "1" },
                  { name: "NÃO", value: "0" },
                ]}
                label="É Distribuição"
                {...formik.getFieldProps("is_distribution")}
                error={
                  formik.touched.is_distribution &&
                  formik.errors.is_distribution
                    ? formik.errors.is_distribution
                    : undefined
                }
              />
            </GroupInput>
          )}

          <h3>Permissões</h3>

          <div className="field-group">
            <div className="field">
              <label>Devolução</label>
              <select {...formik.getFieldProps("devolution")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
                <option value={4}>Criação, Edição e Leitura</option>
              </select>
              {formik.touched.devolution && formik.errors.devolution ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.devolution} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label>Indenização</label>
              <select {...formik.getFieldProps("indemnity")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.indemnity && formik.errors.indemnity ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.indemnity} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label>Boleto</label>
              <select {...formik.getFieldProps("billet")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.billet && formik.errors.billet ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.billet} </span>
                </Error>
              ) : null}
            </div>
          </div>

          <div className="field-group">
            <div className="field">
              <label>Pedido</label>
              <select {...formik.getFieldProps("order")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.order && formik.errors.order ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.order} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label>Forecast</label>
              <select {...formik.getFieldProps("forecast")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.forecast && formik.errors.forecast ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.forecast} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label>Forecast dados internos</label>
              <select {...formik.getFieldProps("forecastInternal")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.forecastInternal &&
              formik.errors.forecastInternal ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.forecastInternal} </span>
                </Error>
              ) : null}
            </div>
          </div>

          <div className="field-group">
            <div className="field">
              <label>Cliente</label>
              <select {...formik.getFieldProps("clientPJ")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.clientPJ && formik.errors.clientPJ ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.clientPJ} </span>
                </Error>
              ) : null}
            </div>
            <div className="field">
              <label>Usuário</label>
              <select {...formik.getFieldProps("userAdmin")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.userAdmin && formik.errors.userAdmin ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.userAdmin} </span>
                </Error>
              ) : null}
            </div>
            <div className="field">
              <label>Representante</label>
              <select {...formik.getFieldProps("seller")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.seller && formik.errors.seller ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.seller} </span>
                </Error>
              ) : null}
            </div>
          </div>

          <div className="field-group">
            <div className="field">
              <label>Fornecedor</label>
              <select {...formik.getFieldProps("provider")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.provider && formik.errors.provider ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.provider} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label>Análise</label>
              <select {...formik.getFieldProps("analytic")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.analytic && formik.errors.analytic ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.analytic} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label>Configuração</label>
              <select {...formik.getFieldProps("config")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.config && formik.errors.config ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.config} </span>
                </Error>
              ) : null}
            </div>
          </div>

          <div className="field-group">
            <div className="field">
              <label>Permissão</label>
              <select {...formik.getFieldProps("permission")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.permission && formik.errors.permission ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.permission} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label>Importação</label>
              <select {...formik.getFieldProps("import")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.import && formik.errors.import ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.import} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label>Legado Devolução</label>
              <select {...formik.getFieldProps("devolutionLegacy")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.devolutionLegacy &&
              formik.errors.devolutionLegacy ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.devolutionLegacy} </span>
                </Error>
              ) : null}
            </div>
          </div>

          <div className="field-group">
            <div className="field">
              <label>Pagamento</label>
              <select {...formik.getFieldProps("payment")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.payment && formik.errors.payment ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.payment} </span>
                </Error>
              ) : null}
            </div>
            <div className="field">
              <label>Agendamento</label>
              <select {...formik.getFieldProps("scheduling")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.scheduling && formik.errors.scheduling ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.scheduling} </span>
                </Error>
              ) : null}
            </div>
            <div className="field">
              <label>Coleção</label>
              <select {...formik.getFieldProps("collection")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.collection && formik.errors.collection ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.collection} </span>
                </Error>
              ) : null}
            </div>
          </div>

          <div className="field-group">
            <div className="field">
              <label>Arquivos</label>
              <select {...formik.getFieldProps("showFile")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.showFile && formik.errors.showFile ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.showFile} </span>
                </Error>
              ) : null}
            </div>
            <div className="field">
              <label>Ficha técnica</label>
              <select {...formik.getFieldProps("datasheet")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.datasheet && formik.errors.datasheet ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.datasheet} </span>
                </Error>
              ) : null}
            </div>
            <div className="field">
              <label>Ficha técnica Qualidade</label>
              <select {...formik.getFieldProps("datasheetQuality")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.datasheetQuality &&
              formik.errors.datasheetQuality ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.datasheetQuality} </span>
                </Error>
              ) : null}
            </div>
          </div>

          <div className="field-group">
            <div className="field">
              <label>Atualização cadastral</label>
              <select {...formik.getFieldProps("requestUpdateClient")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.requestUpdateClient &&
              formik.errors.requestUpdateClient ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.requestUpdateClient} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label>Notas de serviço</label>
              <select {...formik.getFieldProps("serviceInvoice")}>
                <option value={1}>Sem permissão</option>
                <option value={2}>Leitura</option>
                <option value={3}>Edição e Leitura</option>
              </select>
              {formik.touched.serviceInvoice && formik.errors.serviceInvoice ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.serviceInvoice} </span>
                </Error>
              ) : null}
            </div>
          </div>

          {permission && permission.permission && permission.permission > 2 && (
            <ButtunSubmit type="submit"> Criar </ButtunSubmit>
          )}
        </Form>
      </ContainerForm>
    </Container>
  );
};

export default CreateUser;
