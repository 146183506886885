import React from "react";
import { IconType } from "react-icons";
import {
  FaBox,
  FaBoxOpen,
  FaCalculator,
  FaMoneyBillWave,
  FaRegCalendarAlt,
  FaStore,
  FaTabletAlt,
  FaUserPlus,
  FaUserTie,
  GiWideArrowDunk,
  ImUser,
  IoBook,
  RiShoppingBag3Fill,
} from "react-icons/all";
import { useHistory } from "react-router-dom";
import { IPermissionUser } from "../../@types";
import * as sellerLocalStorage from "../../service/localStorage/seller";
import { Box, ContainerBox } from "./styles";

interface HomeBoxesProps {
  admin?: boolean;
  client?: boolean;
  seller?: boolean;
  provider?: boolean;
  providerIsShippingCompany?: boolean;

  permission?: IPermissionUser;

  accessSsoCatalog: () => void;
  accessSsoAlparhub: () => void;
}

interface IBox {
  link: string;
  title: string;
  Icon: IconType;
  client?: boolean;
  seller?: boolean;
  admin?: boolean;
  provider?: boolean;
  providerIsShippingCompany?: boolean;
  permission:
    | "devolution"
    | "indemnity"
    | "billet"
    | "order"
    | "forecast"
    | "forecastInternal"
    | "clientPJ"
    | "userAdmin"
    | "seller"
    | "analytic"
    | "config"
    | "import"
    | "permission"
    | "devolutionLegacy"
    | "payment"
    | "scheduling"
    | "datasheet";
}

const boxs: IBox[] = [
  {
    link: "pedidos/situacao/pedidos",
    title: "Carteira de pedidos",
    Icon: RiShoppingBag3Fill,
    client: true,
    admin: true,
    seller: true,
    permission: "order",
  },
  {
    link: "boletos",
    title: "2ª via de Boletos",
    Icon: FaMoneyBillWave,
    client: true,
    admin: true,
    seller: true,
    permission: "billet",
  },
  {
    link: "devolucoes",
    title: "Devoluções",
    Icon: FaBox,
    client: true,
    seller: true,
    admin: true,
    provider: true,
    providerIsShippingCompany: true,
    permission: "devolution",
  },
  {
    link: "indenizacaos",
    title: "Indenizações",
    Icon: FaBoxOpen,
    client: true,
    admin: true,
    permission: "indemnity",
  },

  {
    link: "previsoes",
    title: "Forecast",
    Icon: GiWideArrowDunk,
    seller: true,
    admin: true,
    permission: "forecast",
  },
  {
    link: "clientes",
    title: "Clientes",
    Icon: FaStore,

    admin: true,
    permission: "clientPJ",
  },
  {
    link: "representantes",
    title: "Representantes",
    Icon: ImUser,

    admin: true,
    permission: "seller",
  },
  {
    link: "usuarios",
    title: "Usuários",
    Icon: FaUserTie,

    admin: true,
    permission: "userAdmin",
  },
  {
    link: "calendario",
    title: "Agendamentos",
    Icon: FaRegCalendarAlt,
    provider: true,
    permission: "scheduling",
  },
  {
    link: "calculadora-vendor",
    title: "Calculadora vendor",
    Icon: FaCalculator,
    permission: "order",
    seller: true,
  },
  {
    link: "acessos-tablet",
    title: "Acessos tablet",
    permission: "analytic",
    Icon: FaTabletAlt,
    seller: true,
  },
];

export const HomeBoxes = ({
  admin,
  client,
  permission,
  provider,
  seller,
  providerIsShippingCompany,
  accessSsoCatalog,
}: HomeBoxesProps) => {
  const sellerStorage =
    sellerLocalStorage.getUser() as sellerLocalStorage.SellerProps;
  const history = useHistory();

  return (
    <ContainerBox>
      {boxs.map((box, i) => {
        if (admin) {
          if (permission && permission[box.permission] > 1 && box.admin) {
            return (
              <Box key={i} onClick={() => history.push(`${box.link}`)}>
                <div className="boxExternal">
                  <span>{box.title}</span>
                  <div className="icon">
                    <box.Icon />
                  </div>
                </div>
              </Box>
            );
          }
        }

        if (client) {
          if (box.client) {
            return (
              <Box key={i} onClick={() => history.push(`${box.link}`)}>
                <div className="boxExternal">
                  <span>{box.title}</span>
                  <div className="icon">
                    <box.Icon />
                  </div>
                </div>
              </Box>
            );
          }
        }

        if (seller) {
          if (box.seller) {
            return (
              <Box key={i} onClick={() => history.push(`${box.link}`)}>
                <div className="boxExternal">
                  <span>{box.title}</span>
                  <div className="icon">
                    <box.Icon />
                  </div>
                </div>
              </Box>
            );
          }
        }

        if (provider) {
          if (
            box.provider &&
            box.providerIsShippingCompany === providerIsShippingCompany
          ) {
            return (
              <Box key={i} onClick={() => history.push(`${box.link}`)}>
                <div className="boxExternal">
                  <span>{box.title}</span>
                  <div className="icon">
                    <box.Icon />
                  </div>
                </div>
              </Box>
            );
          }
        }

        return null;
      })}

      {(admin || seller || client) && (
        <Box onClick={accessSsoCatalog}>
          <div className="boxExternal">
            <span>Catálogo</span>
            <div className="icon">
              <IoBook />
            </div>
          </div>
        </Box>
      )}

      {/* {(seller || client) && (
        <Box onClick={accessSsoAlparhub}>
          <div className="boxExternal">
            <span>Alparhub</span>
            <div className="icon">
              <FaShoppingCart />
            </div>
          </div>
        </Box>
      )} */}

      {seller &&
        (!!sellerStorage?.is_manager || !!sellerStorage?.is_supervisor) && (
          <Box
            onClick={() => {
              window.open(`https://sistema.alpardobrasil.com.br/login`);
            }}
          >
            <div className="boxExternal">
              <span>Cadastro representante</span>
              <div className="icon">
                <FaUserPlus />
              </div>
            </div>
          </Box>
        )}
    </ContainerBox>
  );
};
