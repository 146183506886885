import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SideBarProvider } from "./hook/useSideBar";
import * as serviceWorker from "./serviceWorker";
import GlobalStyles from "./styles/global";

import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "./service/queryClient";

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SideBarProvider>
        <GlobalStyles />
        <App />
        <ReactQueryDevtools />
      </SideBarProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register();
