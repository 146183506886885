import styled from "styled-components";

export const Error = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 2px;
  span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #f00;
  }
`;

export const InputColor = styled.input`
  position: absolute;

  top: 23px;
  right: 4px;

  height: 30px !important;
  width: 50px !important;
  border: 0 !important;
  padding: 0 !important;
`;
