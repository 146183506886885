// //@ts-ignore
// import Quagga from "quagga";
import React from "react";
import { IoMdClose } from "react-icons/io";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import "./styles.css";

interface ScannerProps {
  onDetected: (props: string) => void;
  onClose?: () => void;
}

const Scanner = ({ onDetected, onClose }: ScannerProps) => {
  // // eslint-disable-next-line
  // const detected = (result: any) => {
  //   onDetected(result.codeResult.code);
  //   Quagga.stop();
  // };

  // useEffect(() => {
  //   Quagga.init(
  //     {
  //       inputStream: {
  //         type: "LiveStream",
  //         constraints: {
  //           // width: { min: 450 },
  //           // height: { min: 300 },
  //           facingMode: "environment",
  //           aspectRatio: { min: 1, max: 2 },
  //         },
  //       },
  //       locator: {
  //         patchSize: " ",
  //         halfSample: true,
  //       },
  //       numOfWorkers: 2,
  //       frequency: 10,
  //       decoder: {
  //         readers: ["ean_reader"],
  //       },
  //       locate: true,
  //     },
  //     (err: any) => {
  //       if (err) {
  //         console.log(err, "error msg");
  //       }
  //       Quagga.start();
  //       return () => {
  //         Quagga.stop();
  //       };
  //     }
  //   );

  //   //detecting boxes on stream
  //   Quagga.onProcessed((result: any) => {
  //     var drawingCtx = Quagga.canvas.ctx.overlay,
  //       drawingCanvas = Quagga.canvas.dom.overlay;

  //     if (result) {
  //       if (result.boxes) {
  //         drawingCtx.clearRect(
  //           0,
  //           0,
  //           Number(drawingCanvas.getAttribute("width")),
  //           Number(drawingCanvas.getAttribute("height"))
  //         );
  //         result.boxes
  //           .filter(function (box: any) {
  //             return box !== result.box;
  //           })
  //           .forEach(function (box: any) {
  //             Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
  //               color: "#159c0b",
  //               lineWidth: 2,
  //             });
  //           });
  //       }

  //       if (result.box) {
  //         Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
  //           color: "#00F",
  //           lineWidth: 2,
  //         });
  //       }

  //       if (result.codeResult && result.codeResult.code) {
  //         Quagga.ImageDebug.drawPath(
  //           result.line,
  //           { x: "x", y: "y" },
  //           drawingCtx,
  //           { color: "red", lineWidth: 3 }
  //         );
  //       }
  //     }
  //   });

  //   Quagga.onDetected(detected);
  // }, [detected]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    // Quagga.stop();
  };

  return (
    <div className="container">
      {/* <div id="interactive" className="viewport" /> */}
      <BarcodeScannerComponent
        width={500}
        height={500}
        torch={true}
        onUpdate={(_, result: any) => {
          if (result) {
            if (result.text.length >= 13) {
              onDetected(result.text);
            }
          }
        }}
      />

      <button
        className="button-close-scanner"
        type="button"
        onClick={handleClose}
      >
        {" "}
        <IoMdClose size={28} />
      </button>
    </div>
  );
};

export default Scanner;
