import React, { useEffect, useState } from "react";

import moment from "moment";
import api from "../../../service/api";
import { dataUndefined, dataZero } from "../../../utils/dataProcessing";

import Chart from "react-apexcharts";

import {
  BoxAnalytic,
  BoxFilter,
  Container,
  ContainerBoxAnalytic,
  ContainerCheckbox,
  ContainerList,
  Error,
  FooterContainer,
  Form,
  InfoStatus,
  ModalBody,
  NoStyleLink,
  ScreenLoading,
  TableContainer,
} from "../styles";

import { LoadingPanel } from "../../panel/styles";

import { ButtunSubmit } from "../../../styles/global";

import { FiAlertCircle } from "react-icons/fi";
import { HiFilter } from "react-icons/hi";

import Loading from "../../../components/loadings/Loading";
import LoadingDot from "../../../components/loadings/LoadingDot";
import Modal from "../../../components/Modal";

import { useFormik } from "formik";

interface IProps {
  admin: boolean;
}

interface IFilter {
  status?: string;
  brand?: string;

  pending?: number;

  finisherPeriod?: number;
  presetDateFinisher?: number;
  specificDateFinisher?: Date;
  startDateFinisher?: Date;
  endDateFinisher?: Date;
}

interface IStatus {
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
}

interface IBrand {
  id: number;
  cod: number;
  name: string;
  is_active: boolean;
}

interface SchedulingProps {
  id: number;
  name: string;
  total: number;
  start_date: Date;
  end_date: Date;
  created_at: Date;

  status?: IStatus;

  provider?: ProviderProps;
}

interface ProviderProps {
  id: number;
  fullname: string;
}

interface SchedulingAnalyticProps {
  quantity: number;
  amount: number;

  brands: ItemAnalyticProps[];
  status: ItemAnalyticProps[];
}

interface ItemAnalyticProps {
  title: string;
  quantity: number;
  amount: number;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IResponseApi {
  analytic: SchedulingAnalyticProps;
  contents: SchedulingProps[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

const APP_LINK =
  process.env.NODE_ENV !== "development"
    ? "http://portal.alpardobrasil.com.br"
    : "http://localhost:3000";

const SchedulingAnalytic: React.FC<IProps> = ({ admin }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [filters, setFilters] = useState<IFilter>({});

  const [status, setStatus] = useState<IStatus[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);

  const [filterCheck, setFilterCheck] = useState<any>({
    status: {},
    brands: {},
  });

  const [screenLoading, setScreenLoading] = useState<boolean>(true);

  const [analytics, setAnalytics] = useState<SchedulingAnalyticProps>(
    {} as SchedulingAnalyticProps
  );

  const [contents, setContents] = useState<SchedulingProps[]>([]);
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  const [loading, setLoading] = useState(false);

  const formikFilter = useFormik({
    initialValues: {
      brands: undefined,
      status: undefined,
      brand: filters.brand,

      pending: filters.pending,

      finisherPeriod: filters.finisherPeriod,
      presetDateFinisher: filters.presetDateFinisher,
      specificDateFinisher: filters.specificDateFinisher,
      startDateFinisher: filters.startDateFinisher,
      endDateFinisher: filters.endDateFinisher,
    },
    onSubmit: (data) => {
      hadleFilter(data);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await api.get<IResponseApi>("/analytic/scheduling", {
          params: { ...filters, ...requestPagination },
        });

        const { limit, page, pages, total, analytic } = response.data;

        setAnalytics(analytic);
        setContents(response.data.contents);
        setPagination({ limit, page, pages, total });
        setScreenLoading(false);
        setLoading(false);
      } catch (error) {
        setScreenLoading(false);
        setLoading(false);
      }
    })();
  }, [filters, requestPagination]);

  useEffect(() => {
    (async () => {
      const brandsDB = await api.get<IBrand[]>("/populate/brand/provider", {
        params: { activeScheduling: 1 },
      });
      setBrands(brandsDB.data);
      const statusDB = await api.get<IStatus[]>("/populate/scheduling/status");
      setStatus(statusDB.data);
    })();
  }, []);

  useEffect(() => {
    let statusFilter = "";
    const arrStatus = Object.getOwnPropertyNames(filterCheck.status);
    for (const item of arrStatus) {
      if (filterCheck.status[item]) statusFilter += `${item},`;
    }
    formikFilter.setFieldValue("status", statusFilter);

    let brandsFilter = "";
    const arrBrands = Object.getOwnPropertyNames(filterCheck.brands);
    for (const itemBrand of arrBrands) {
      if (filterCheck.brands[itemBrand]) brandsFilter += `${itemBrand},`;
    }
    formikFilter.setFieldValue("brands", brandsFilter);

    // eslint-disable-next-line
  }, [filterCheck]);

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setModalVisible(false);
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  function setChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setFilterCheck({
      ...filterCheck,
      [e.target.name]: {
        ...filterCheck[e.target.name],
        [e.target.value]: e.target.checked,
      },
    });
  }

  function clearFilter() {
    setModalVisible(false);
    setFilterCheck({
      status: {},
      brands: {},
    });
    setFilters({});

    formikFilter.setFieldValue("finisherPeriod", "");
    formikFilter.setFieldValue("specificDateFinisher", "");
    formikFilter.setFieldValue("startDateFinisher", "");
    formikFilter.setFieldValue("endDateFinisher", "");
  }

  return (
    <Container>
      {!screenLoading ? (
        <>
          <header>
            <h2>Análise de Agendamentos</h2>
          </header>

          <InfoStatus>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>VALOR TOTAL</h5>
                </div>
                <p>
                  {analytics.amount
                    ? analytics.amount.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    : "-"}
                </p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>QUANTIDADE TOTAL</h5>
                </div>
                <p>{dataUndefined(analytics.quantity)}</p>
              </div>
            </div>
          </InfoStatus>

          <ContainerBoxAnalytic>
            <BoxAnalytic>
              <h3>Marcas</h3>

              {analytics?.brands && (
                <div style={{ width: "100%" }}>
                  <Chart
                    type="donut"
                    height={260}
                    width="100%"
                    series={analytics?.brands.map((item) => item.amount)}
                    options={{
                      series: analytics?.brands.map((item) => item.amount),
                      labels: analytics?.brands.map((item) => item.title),
                      yaxis: {
                        labels: {
                          formatter: function (value) {
                            return value.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            });
                          },
                        },
                      },
                    }}
                  />
                </div>
              )}
            </BoxAnalytic>
            <BoxAnalytic>
              <h3>Situações</h3>

              {analytics?.status && (
                <div style={{ width: "100%" }}>
                  <Chart
                    type="bar"
                    height={260}
                    width="100%"
                    options={{
                      chart: {
                        toolbar: {
                          show: false,
                        },
                      },

                      plotOptions: {
                        bar: {
                          distributed: true,
                          borderRadius: 4,
                          horizontal: false,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },

                      xaxis: {
                        categories: analytics?.status.map((item) => item.title),
                      },
                      yaxis: {
                        labels: {
                          formatter: function (value) {
                            return value.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            });
                          },
                        },
                      },
                    }}
                    series={[
                      {
                        name: "Valor",
                        data: analytics?.status.map((item) => item.amount),
                      },
                    ]}
                  />
                </div>
              )}
            </BoxAnalytic>
          </ContainerBoxAnalytic>

          <ContainerList>
            <div className="headerTable">
              <h3>Solicitação</h3>
            </div>

            <TableContainer style={{ maxHeight: "50vh" }}>
              {!loading ? (
                <table>
                  <thead>
                    <tr>
                      <th>CÓDIGO</th>
                      <th>TÍTULO</th>
                      <th>STATUS</th>
                      <th>DATA AGENDAMENTO</th>
                      <th>VALOR TOTAL</th>
                      <th>FORNECEDOR</th>
                      <th>CRIADO EM</th>
                    </tr>
                  </thead>

                  <tbody>
                    {contents.map((item, index) => (
                      <tr key={index} style={{ cursor: "auto" }}>
                        <td style={{ whiteSpace: "nowrap" }}>
                          <NoStyleLink
                            href={`${APP_LINK}/${
                              admin ? "admin" : "representante"
                            }/agendamento/${item.id}`}
                            target="_blank"
                          >
                            {Number(item.id)}
                          </NoStyleLink>
                        </td>

                        <td>
                          <NoStyleLink
                            href={`${APP_LINK}/${
                              admin ? "admin" : "representante"
                            }/agendamento/${item.id}`}
                            target="_blank"
                          >
                            {item.name}
                          </NoStyleLink>
                        </td>

                        <td>
                          <NoStyleLink
                            href={`${APP_LINK}/${
                              admin ? "admin" : "representante"
                            }/agendamento/${item.id}`}
                            target="_blank"
                          >
                            <span
                              className="status"
                              style={{ backgroundColor: item.status?.color }}
                            >
                              {item.status?.name}
                            </span>
                          </NoStyleLink>
                        </td>

                        <td>
                          <NoStyleLink
                            href={`${APP_LINK}/${
                              admin ? "admin" : "representante"
                            }/agendamento/${item.id}`}
                            target="_blank"
                          >
                            {`${moment(item.start_date).format("DD/MM/YYYY")} - 
                      
                      
                      ${
                        moment(item.start_date).format("A") === "AM" ||
                        Number(moment(item.start_date).format("hh")) === 12
                          ? Number(moment(item.start_date).format("hh"))
                          : Number(moment(item.start_date).format("hh")) + 12
                      }:${moment(item.start_date).format("mm")}h
                      às 
                      ${
                        moment(item.end_date).format("A") === "AM" ||
                        Number(moment(item.end_date).format("hh")) === 12
                          ? Number(moment(item.end_date).format("hh"))
                          : Number(moment(item.end_date).format("hh")) + 12
                      }:${moment(item.end_date).format("mm")}h
                      
                      `}
                          </NoStyleLink>
                        </td>
                        <td>
                          <NoStyleLink
                            href={`${APP_LINK}/${
                              admin ? "admin" : "representante"
                            }/agendamento/${item.id}`}
                            target="_blank"
                          >
                            {item.total.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </NoStyleLink>
                        </td>

                        <td>
                          <NoStyleLink
                            href={`${APP_LINK}/${
                              admin ? "admin" : "representante"
                            }/agendamento/${item.id}`}
                            target="_blank"
                          >
                            {item.provider?.fullname}
                          </NoStyleLink>
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          <NoStyleLink
                            href={`${APP_LINK}/${
                              admin ? "admin" : "representante"
                            }/agendamento/${item.id}`}
                            target="_blank"
                          >
                            {moment(item.created_at).format("DD/MM/YYYY")}
                          </NoStyleLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <LoadingPanel>
                  <LoadingDot />
                </LoadingPanel>
              )}
            </TableContainer>

            <FooterContainer>
              <span>
                Mostrado{" "}
                {dataZero(
                  Number(contents.length + pagination.limit * pagination.page)
                )}{" "}
                de {dataZero(Number(pagination.total))} resultados
              </span>

              <div>
                <button
                  className={pagination.page < 1 ? "buttonNull" : ""}
                  onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
                  type="button"
                >
                  ANTERIOR
                </button>
                <p style={{ marginRight: 8 }}>
                  {dataZero(Number(pagination.page + 1))}
                </p>
                <button
                  className={
                    pagination.page >= pagination.pages ? "buttonNull" : ""
                  }
                  type="button"
                  onClick={
                    Number(pagination.page) >= Number(pagination.pages)
                      ? () => {}
                      : hadleNextPage
                  }
                >
                  PROXIMO
                </button>
              </div>
            </FooterContainer>
          </ContainerList>

          <BoxFilter>
            <div className="fixedButton">
              <button onClick={() => setModalVisible(!modalVisible)}>
                <HiFilter color="#FFF" size={26} />
              </button>
            </div>
          </BoxFilter>

          {modalVisible && (
            <Modal
              title="Filtros"
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              maxHeight={475}
              scrollOff
            >
              <ModalBody>
                <Form onSubmit={formikFilter.handleSubmit}>
                  <div
                    style={{
                      overflowY: "auto",
                      height: 350,
                      maxHeight: 350,
                      padding: 10,
                    }}
                  >
                    <div className="field">
                      <label htmlFor="typeDate">Pendentes</label>
                      <select {...formikFilter.getFieldProps("pending")}>
                        <option value=""></option>
                        <option value={1}>Sim</option>
                        <option value={0}>Não</option>
                      </select>
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <ContainerCheckbox>
                          <label className="titleCheckbox" htmlFor="">
                            Status
                          </label>
                          <div className="contentsCheckbox">
                            {status.map((element, index) => (
                              <div key={index} className="boxCheckbox">
                                <input
                                  type="checkbox"
                                  onChange={setChangeCheckbox}
                                  checked={
                                    filterCheck.status[element.cod]
                                      ? true
                                      : false
                                  }
                                  value={element.cod}
                                  name={`status`}
                                />
                                <label htmlFor="">{element.name}</label>
                              </div>
                            ))}
                          </div>
                        </ContainerCheckbox>
                      </div>

                      {admin && (
                        <div className="field">
                          <ContainerCheckbox>
                            <label className="titleCheckbox" htmlFor="">
                              Marca
                            </label>
                            <div className="contentsCheckbox">
                              {brands.map((element, index) => (
                                <div key={index} className="boxCheckbox">
                                  <input
                                    type="checkbox"
                                    onChange={setChangeCheckbox}
                                    checked={
                                      filterCheck.brands[element.cod]
                                        ? true
                                        : false
                                    }
                                    value={element.cod}
                                    name={`brands`}
                                  />
                                  <label htmlFor="">{element.name}</label>
                                </div>
                              ))}
                            </div>
                          </ContainerCheckbox>
                        </div>
                      )}
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="typeDate">PERÍODO DE AGENDAMENTO</label>
                        <select
                          {...formikFilter.getFieldProps("finisherPeriod")}
                        >
                          <option value=""></option>
                          <option value={2}>Específica</option>
                          <option value={3}>Intervalo</option>
                        </select>
                      </div>
                    </div>
                    {Number(formikFilter.values.finisherPeriod) === 1 ? (
                      <div className="field">
                        <label htmlFor="typeDate">DATA PREDEFINIDA</label>
                        <select
                          {...formikFilter.getFieldProps("presetDateFinisher")}
                        >
                          <option value=""></option>
                          <option value={0}>Hoje</option>
                          <option value={1}>Ontem</option>
                          <option value={7}>Últimos 7 dias</option>
                          <option value={15}>Últimos 15 dias</option>
                          <option value={30}>Últimos 30 dias</option>
                        </select>
                        {formikFilter.touched.presetDateFinisher &&
                        formikFilter.errors.presetDateFinisher ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span>
                              {formikFilter.errors.presetDateFinisher}
                            </span>
                          </Error>
                        ) : null}
                      </div>
                    ) : null}

                    {Number(formikFilter.values.finisherPeriod) === 2 ? (
                      <div className="field">
                        <label htmlFor="typeDate">DATA ESPECÍFICA</label>
                        <input
                          type="date"
                          {...formikFilter.getFieldProps(
                            "specificDateFinisher"
                          )}
                        />
                        {formikFilter.touched.specificDateFinisher &&
                        formikFilter.errors.specificDateFinisher ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span>
                              {formikFilter.errors.specificDateFinisher}
                            </span>
                          </Error>
                        ) : null}
                      </div>
                    ) : null}

                    <div className="field-group">
                      {Number(formikFilter.values.finisherPeriod) === 3 ? (
                        <>
                          <div className="field">
                            <label htmlFor="typeDate">DATA INICIAL</label>
                            <input
                              type="date"
                              {...formikFilter.getFieldProps(
                                "startDateFinisher"
                              )}
                            />
                            {formikFilter.touched.startDateFinisher &&
                            formikFilter.errors.startDateFinisher ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span>
                                  {formikFilter.errors.startDateFinisher}
                                </span>
                              </Error>
                            ) : null}
                          </div>
                          <div className="field">
                            <label htmlFor="typeDate">DATA FINAL</label>
                            <input
                              type="date"
                              {...formikFilter.getFieldProps("endDateFinisher")}
                            />
                            {formikFilter.touched.endDateFinisher &&
                            formikFilter.errors.endDateFinisher ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span>
                                  {formikFilter.errors.endDateFinisher}
                                </span>
                              </Error>
                            ) : null}
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>

                  <div className="field-group">
                    <div className="field" style={{ marginBottom: 0 }}>
                      <ButtunSubmit
                        onClick={clearFilter}
                        style={{
                          background: "#fff",
                          color: "#d21e26",
                        }}
                        type="button"
                      >
                        LIMPAR FILTRO
                      </ButtunSubmit>
                    </div>

                    <div className="field" style={{ marginBottom: 0 }}>
                      <ButtunSubmit type="submit">BUSCAR</ButtunSubmit>
                    </div>
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          )}
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export { SchedulingAnalytic };
