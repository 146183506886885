import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContainerForm = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 60px;
  grid-template-areas:
    "FORM"
    "BTN";

  .containerForm {
    grid-area: FORM;
    overflow-y: scroll;
    padding: 18px;
  }

  .status-option {
    font-size: 13px;
    font-weight: 400;
    border-radius: 4px;
    padding: 2px 5px;
  }

  .containerFormButton {
    grid-area: BTN;
    padding: 0 8px;
  }

  .container-subtitle {
    display: flex;
    align-items: center;

    button {
      height: 46px;
      width: 46px;
      border-radius: 50%;

      margin-left: 0.5rem;

      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      svg {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
      }
    }
  }
`;

export const TabletSelect = styled.table`
  margin-bottom: 24px;
  /* border: 1px solid #ededed; */
  border-collapse: collapse;
  border-spacing: 0;
  color: rgba(0, 0, 0, 0.8);

  font-weight: 400;
  padding: 0;
  width: 100%;

  & > thead {
    clip-path: none;
    height: auto;
    overflow: auto;
    position: relative;
    width: auto;
  }

  & > thead > tr {
    border: 0;
    display: table-row;
  }

  & > thead > tr > th {
    padding: 11px 24px;
    /* background-color: #ededed;
    border-bottom: 1px solid #f5f5f5; */
    font-weight: 600;
    vertical-align: middle;
    text-align: initial;
    font-size: 0.95rem;
  }

  & > tbody {
    display: table-row-group;
  }
  & > tbody > tr {
    height: 40px;
    border: 0;
    display: table-row;
    /* border: 1px solid #ededed; */
  }
  & > tbody > tr > td {
    display: table-cell;
    box-sizing: border-box;
    font-weight: 500;
    padding: 0 24px;
    font-weight: 400;
    font-size: 0.75rem;
  }

  .action-button {
    height: 32px;
    width: 32px;
    border-radius: 50%;

    margin-left: 0.5rem;

    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    svg {
      color: rgba(0, 0, 0, 0.54);
      font-size: 24px;
    }
  }

  /* & > tbody > tr:nth-child(2n) {
    background-color: #f5f5f5;
  } */
`;
