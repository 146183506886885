import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Quality = {
  type: {
    cod: string;
    name: string;
  };
  status: {
    cod: string;
    color: string;
    name: string;
  };

  sampling: number;
  samplingMinimum: number;
  nqa_accpet: number;
  nqa_reject: number;

  datasheetCheck?: string;
  colorCheck?: string;
  generalAppearance?: string;
  composition?: string;
  hangTags?: string;
  toleranceMeasures?: string;
  observation?: string;

  id: number;
  reference: string;
  oc: string;
  oc_total_quantity: number;

  datasheet: {
    alternative_code: string;
    header_datasheet: {
      year: number;
      collection: {
        id: number;
        description: string;
      };
      brand: {
        cod: number;
        name: string;
      };
    };
  };
  quality_variation: {
    id: number;
    qtd: number;
    datasheet_variation: { primary_color_cod: number };
  }[];

  quality_defect: {
    cod: number;
    description: string;
    qtd: number;
  }[];

  files?: {
    id: number;
    name: string;
    size: number;
    url: string;
    qualityProductId: number;
    created_at: Date;
  }[];

  provider: {
    cod: number | null;
    fullname: string;
  };
  inspector: {
    name: string;
    lastname: string;
    email: string;
  };

  quality_product: {
    id: number;
    barcode: string;
    approvalBarcode: boolean;
  }[];

  created_at: Date;
};

export type QualityFilters = {
  id?: string;
};

interface QualityFiltersData {
  reference: OptionsFilter[];
  oc: OptionsFilter[];
  provider: OptionsFilter[];
  inspector: OptionsFilter[];
  type: OptionsFilter[];
  status: OptionsFilter[];
}
type OptionsFilter = {
  id: number | string;
  name: string;
};

type GetNcmsResponse = {
  qualities: Quality[];
  totalCount: number;
  filters: QualityFiltersData;
};

interface NcmApiResponse extends ResponsePanelApiDefault {
  contents: Quality[];
  filters: QualityFiltersData;
}

export async function getNcms(
  page: number,
  datasheetId: number,
  filter?: QualityFilters,
  registersPerPag?: number
): Promise<GetNcmsResponse> {
  const { data } = await api.get<NcmApiResponse>(
    `/headerDatasheet/datasheet/qualities/${datasheetId}`,
    {
      params: { pageRequest: page - 1, limit: registersPerPag, ...filter },
    }
  );

  return {
    totalCount: data.total,
    qualities: data.contents,
    filters: data.filters,
  };
}

export function useQualities(
  page: number,
  datasheetId: number,
  filter?: QualityFilters,
  registersPerPag?: number
): UseQueryResult<GetNcmsResponse> {
  return useQuery(
    ["qualities", page, datasheetId, filter],
    () => getNcms(page, datasheetId, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneQuality(id?: number): Promise<Quality | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<Quality>(
    `/headerDatasheet/datasheet/qualities/show/${id}`
  );

  return data;
}

export function useOneQualities(
  id: number
): UseQueryResult<Quality> | undefined {
  return useQuery(["qualities", id], () => getOneQuality(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
