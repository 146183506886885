import React, { ReactNode, useRef, useState } from "react";
import useOutsideAlerter from "../../hook/outsideAlerter";
import { Container, Content, Header } from "./styles";

interface Props {
  Button: ReactNode;
  isVisible?: boolean;

  children: ReactNode;
}

export function Popover({ Button, children }: Props) {
  const outSideRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useOutsideAlerter(outSideRef, () => {
    if (isVisible) setIsVisible(false);
  });

  return (
    <Container>
      <Header onClick={() => setIsVisible((initial) => !initial)}>
        {Button}
      </Header>
      {isVisible && (
        <Content ref={outSideRef} onClick={() => setIsVisible(false)}>
          {children}
        </Content>
      )}
    </Container>
  );
}
