import React, { FormEvent, useEffect, useState } from "react";

import * as clientStorage from "../../../service/localStorage/client";
import * as userLocalStorage from "../../../service/localStorage/user";

import { useToasts } from "react-toast-notifications";
import Modal from "../../../components/ModalVisibleImageBase64";
import Loading from "../../../components/loadings/Loading";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import filesize from "filesize";
import moment from "moment";
import Dropzone from "../../../components/Upload/Dropzone";
import generateHash from "../../../utils/generateHash";
import { ContainerDropZone, ContainerPreviews } from "../../create/styles";

import { AiFillCloseCircle } from "react-icons/ai";
import { VscTriangleRight } from "react-icons/all";
import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { IoMdSend } from "react-icons/io";
import {
  ButtunSubmit,
  Container,
  ContainerGroup,
  Error as ErrorDiv,
  Form,
  InfoDevolution,
  InfoStatus,
  ObsText,
  ScreenLoading,
} from "../styles";

import iconPdf from "../../../assets/icon_pdf.png";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useHistory } from "react-router-dom";
import { IPermissionUser } from "../../../@types";
import api from "../../../service/api";

interface HomeProps {
  location: {
    state: {
      content: IDevolution;
      requestPagination: any;
      filters: any;
    };
  };

  match: {
    params: {
      id: number;
    };
  };

  admin: boolean;
  client: boolean;
  permission: IPermissionUser;
}

interface IFile {
  id: string;
  name: string;
  size: Number;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype?: string;
  fileBase64?: string;
}

interface IDevolution {
  id?: string;
  status?: IStatus;

  requesterName: string;
  phone: string;
  email: string;

  //Dados da solicitação
  numberInvoice: string;
  situationRequest: string;
  products: string;

  requestType: string;

  //Dados da Devolução & indenização
  reason: string;
  subReason: string;

  dateInvoice: string;

  //indenização
  brand: {
    id: number;
    cod: number;
    name: string;
  };
  stateProducts: string;
  dateEmissionNFconsumer: string;

  nfdDateEmission: Date;
  invoiceORcoupon: string;

  //Dados bancários
  bank: string;
  agency: string;
  account: string;
  typeAccountBank: string;

  complementaryInformation: string;

  created_at: Date;

  user: IUser;

  clients_pj: {
    id: string;
    cnpj: string;
    company_name: string;
    trade_name: string;
    email: string;
  };

  //Dados internos
  //Dados NFD
  nfdNumber?: string;
  nfdValue: number;
  nfdDate: Date;
  closingDate: Date;
  internalObservation: string;

  //Representante / Gestor
  salesman?: string;
  brandManager?: string;

  //Trasporte
  freightBrand?: string;
  freightValue?: string;

  files: IFile[];

  observation: {
    authorType: number;
    text: string;

    user: {
      name: true;
      lastname: true;
    };
    client: {
      company_name: true;
      cnpj: true;
      cod: true;
    };
    statusIndemnity: { name: true };
  }[];
}

interface IUser {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
}

interface IChat {
  id?: number;
  name?: string;
  typeUser?: string;
  textMessage?: string;
  iDindemnity?: number;
  created_at?: Date;
}

interface IBrand {
  id?: number;
  cod: number;
  idBrand: number;
  name: string;
  is_active: boolean;
}

interface IInternalReason {
  id?: number;
  cod: number;
  name: string;
  is_active: boolean;
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  responsable_id: number;
  user_id: number;
  finisher: boolean;
  is_active: boolean;
}

const Devolution: React.FC<HomeProps> = ({
  location,
  admin,
  client,
  permission,
  match,
}) => {
  const clientData = clientStorage.getUser() as clientStorage.Client;
  const userStorage = userLocalStorage.getUser() as userLocalStorage.User;

  const { addToast } = useToasts();
  const history = useHistory();

  const [contentPage, setContentPage] = useState<IDevolution | null>(null);
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState<IUser[]>([]);
  const [internalReasons, setInternalReasons] = useState<IInternalReason[]>([]);

  const [chat, setChat] = useState<IChat[]>([]);
  const [textChat, setTextChat] = useState("");

  const [modalVisible, setModalVisible] = useState(false);
  const [modalFileOpen, setModalFileOpen] = useState<number>();

  const [status, setStatus] = useState<IStatus[]>([]);

  const [selectFile, setSelectFile] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);

  const [listfiles, setListFiles] = useState<IFile[]>([]);

  const [disabledInputs, setDisabledInputs] = useState(true);

  useEffect(() => {
    if (
      contentPage &&
      contentPage.status &&
      admin &&
      permission &&
      permission.indemnity &&
      permission.indemnity > 2 &&
      Number(contentPage.status.responsable_id) ===
        Number(userStorage.permission)
    )
      setDisabledInputs(false);
  }, [admin, permission, contentPage, userStorage]);

  useEffect(() => {
    if (admin) {
      (async () => {
        const userDB = await api.get<IUser[]>("/populate/userAdmin");
        setUsers(userDB.data);

        const reansonInternalDB = await api.get<IInternalReason[]>(
          "/populate/reason/indemnity",
          { params: { active: 1 } }
        );
        setInternalReasons(reansonInternalDB.data);
      })();
    }
  }, [admin]);

  useEffect(() => {
    if (contentPage && admin) {
      if (contentPage.user && contentPage.user.id)
        formikInternalData.setFieldValue("idUser", contentPage.user.id);
      if (contentPage.status && contentPage.status.cod)
        formikInternalData.setFieldValue("status", contentPage.status.cod);
      if (contentPage.subReason)
        formikInternalData.setFieldValue("subReason", contentPage.subReason);
      if (contentPage.internalObservation)
        formikInternalData.setFieldValue(
          "internalObservation",
          contentPage.internalObservation
        );
      if (contentPage.nfdNumber)
        formikInternalData.setFieldValue("nfdNumber", contentPage.nfdNumber);
      if (contentPage.nfdValue)
        formikInternalData.setFieldValue("nfdValue", contentPage.nfdValue);

      if (contentPage.nfdDate)
        formikInternalData.setFieldValue(
          "nfdDate",
          contentPage?.nfdDate !== null
            ? moment(contentPage?.nfdDate).add("day", 1).format("YYYY-MM-DD")
            : undefined
        );
      if (contentPage.nfdDateEmission)
        formikInternalData.setFieldValue(
          "nfdDateEmission",
          contentPage?.nfdDateEmission !== null
            ? moment(contentPage?.nfdDateEmission)
                .add("day", 1)
                .format("YYYY-MM-DD")
            : undefined
        );
    }

    (async () => {
      if (contentPage && contentPage.id) {
        try {
          const messageDB = await api.get<IChat[]>(
            `/message/indemnity/${contentPage.id}`
          );
          setChat(messageDB.data);

          const response = await api.get<IFile[]>(
            `/indemnity/file/${contentPage.id}`
          );
          setListFiles(response.data);
        } catch (error) {
          console.log(error);
          return addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage]);

  useEffect(() => {
    (async () => {
      const statusDB = await api.get<IStatus[]>("/indemnity/status", {
        params: { active: 1 },
      });
      setStatus(statusDB.data);
    })();
  }, []);

  useEffect(() => {
    if (selectFile) setFiles([...files, selectFile]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFile]);

  useEffect(() => {
    (async () => {
      try {
        if (false && Boolean(location)) {
          setContentPage(location.state.content);

          if (admin && permission.indemnity > 2) {
            await api.put(
              `/indemnity/notification/off/${location.state.content.id}`
            );
          }
        } else {
          throw new Error("update");
        }
      } catch (error) {
        if (Boolean(match.params.id)) {
          try {
            if (admin) {
              const devolution = await api.get<IDevolution>(
                `/indemnity/show/${match.params.id}`
              );

              if (!Boolean(devolution.data)) {
                return history.push("/404");
              }

              if (
                permission &&
                permission.indemnity &&
                permission.indemnity > 2
              ) {
                await api.put(
                  `/indemnity/notification/off/${devolution.data.id}`
                );
              }

              setContentPage(devolution.data);
            }

            if (client) {
              const devolutionClient = await api.get<IDevolution>(
                `/indemnity/show/${clientData.id}/${match.params.id}`
              );

              if (!Boolean(devolutionClient.data)) {
                return history.push("/404");
              }

              setContentPage(devolutionClient.data);
            }
          } catch (error) {
            return history.push("/404");
          }
        } else {
          history.push("/404");
        }
      }

      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [location, admin, permission, match.params.id]);

  const formikInternalData = useFormik({
    initialValues: {
      idUser: undefined,
      status: undefined,
      subReason: undefined,
      statusObservation: undefined,
      internalObservation: undefined,
      //Dados NFD
      nfdNumber: undefined,
      nfdValue: undefined,
      nfdDate: undefined,
      nfdDateEmission: undefined,
    },

    validationSchema: Yup.object({
      idUser: Yup.number().required(
        "Responsável pela solicitação é obrigatório"
      ),
      // nfdDate: Yup.date()
      subReason: Yup.string()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = await selectStatus(
            Number(formikInternalData.values.status)
          );

          if (status.finisher) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }
          return error;
        }),
      statusObservation: Yup.string()
        .nullable()
        .test(
          "Valid_Status",
          "Observação sobre alteração de status é obrigatório ",
          async function (value) {
            let error = true;

            const { status } = formikInternalData.values;

            if (
              contentPage &&
              contentPage.status &&
              Number(status) !== Number(contentPage.status.cod)
            ) {
              error = false;
            }

            if (value) {
              error = true;
            }

            return error;
          }
        ),
    }),

    //@ts-ignore
    onSubmit: (values) => hadleSubumit(values),
  });

  async function hadleDeleteFile(name: string) {
    files.forEach((element: File, index) => {
      if (element.name === name) {
        files.splice(index, 1);
      }
    });

    setFiles([]);

    setTimeout(() => {
      setFiles(files);
    }, 1);
  }

  async function hadleSubmitFiles() {
    if (!admin) {
      //@ts-ignore
      if (contentPage.status?.finisher) {
        return addToast("Solicitação finalizada", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    }

    const formData = new FormData();

    if (!selectFile) return;

    if (selectFile) {
      files.forEach((element) => {
        formData.append("file", element);
      });
    }

    try {
      const filesResponse = await api.post<IFile[]>(
        "/indemnity/upload",
        formData
      );

      if (!contentPage) throw Error("");

      await api.put(`/clientconfig/indemnity/${contentPage.id}`, {
        files: filesResponse.data.map((item) => ({
          id: item.id,
          name: item.name,
          size: item.size,
          url: item.url,
          mimetype: item.mimetype,
          key: item.key,
        })),
      });

      if (!admin) await api.put(`/indemnity/notification/on/${contentPage.id}`);

      filesResponse.data.forEach((element) => {
        setListFiles([
          ...listfiles,
          {
            id: element.id,
            name: element.name,
            size: element.size,
            url: element.preview_url,
            mimetype: element.mimetype,
          },
        ]);
      });

      setFiles([]);

      addToast("Arquivo(s) anexado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function hadleSubumit(data: IDevolution) {
    try {
      if (!contentPage) throw Error("");

      await api.put(`indemnity/${contentPage.id}`, data);
      addToast("Indenização alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/indenizacaos");
    } catch (error) {
      console.log(error);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function hadleSubitChat(e: FormEvent) {
    e.preventDefault();

    if (!contentPage) return;

    if (!admin) {
      //@ts-ignore
      if (contentPage.status?.finisher) {
        return addToast("Solicitação finalizada", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    }

    if (!textChat) return;

    let message: IChat = {
      name: admin
        ? `${userStorage.name} ${userStorage.lastname}`
        : contentPage.requesterName,
      textMessage: textChat,
      typeUser: admin ? "admin" : "client",
      iDindemnity: Number(contentPage.id),
    };

    if (admin) {
      await api.post(`/message/indemnity/admin`, message);
    } else {
      await api.put(`/indemnity/notification/on/${contentPage.id}`);
      await api.post(`/message/indemnity/client`, message);
    }

    setChat([
      {
        id: 1,
        name: admin
          ? `${userStorage.name} ${userStorage.lastname}`
          : contentPage.requesterName,

        textMessage: textChat,
        typeUser: admin ? "admin" : "client",

        created_at: new Date(),
      },
      ...chat,
    ]);

    setTextChat("");
  }

  async function hadleAlterStatusClient() {
    try {
      if (!contentPage) throw Error("");

      await api.put(`/clientconfig/indemnity/${contentPage.id}`, {
        status: 4,
      });

      addToast("Solicitação finalizada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });

      history.push("/indenizacaos");
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function selectStatus(s: number) {
    let value: IStatus = {} as IStatus;

    for (const i of status) {
      if (i.cod === s) {
        value = i;
      }
    }
    return value;
  }

  return (
    <Container>
      {contentPage !== null && !loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Detalhes da solicitação</h2>
          </header>

          <InfoStatus>
            <div className="containerStatus">
              <div
                className="bannerLine"
                style={{
                  backgroundColor: contentPage.status?.color,
                }}
              />
              <div className="box">
                <div>
                  <h5>STATUS</h5>
                </div>
                <span
                  style={{
                    backgroundColor: contentPage.status?.color,
                  }}
                >
                  {contentPage.status?.name}
                </span>
              </div>
            </div>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>RESPONSÁVEL</h5>
                </div>
                <p>
                  {contentPage.user.id !== undefined
                    ? `${contentPage.user.name} ${contentPage.user.lastname}`
                    : `-`}
                </p>
              </div>
            </div>

            {!contentPage.status?.finisher && (
              <div className="containerStatus">
                <div className="box">
                  <div>
                    <h5>CRIADO EM</h5>
                  </div>
                  <p>{moment(contentPage.created_at).format("DD/MM/YYYY")}</p>
                </div>
              </div>
            )}

            {contentPage.status?.finisher && (
              <>
                <div className="containerStatus">
                  <div className="box">
                    <div>
                      <h5>CRIADO EM</h5>
                    </div>
                    <p>{moment(contentPage.created_at).format("DD/MM/YYYY")}</p>
                  </div>
                </div>
                <div className="containerStatus">
                  <div className="box">
                    <div>
                      <h5>FINALIZADO EM</h5>
                    </div>
                    <p>
                      {moment(contentPage.closingDate).format("DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
              </>
            )}
          </InfoStatus>

          <InfoDevolution>
            <div className="headerDevoltion">
              <h2>Informações</h2>

              {!contentPage.status?.finisher && !admin && (
                <div className="containerActions">
                  <button className="buttonActions" type="button">
                    Ações
                  </button>
                  <ul className="itemsActions">
                    <li onClick={hadleAlterStatusClient}>
                      <AiFillCloseCircle /> Finalizar solicitação
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="bodyDevoltion">
              <div className="conatinerInfo">
                <h3>Cliente</h3>
                <div className="line"></div>

                <div>
                  <h4>CNPJ</h4>
                  <span>{contentPage.clients_pj.cnpj}</span>

                  <h4>RAZÃO SOCIAL</h4>
                  <span>{contentPage.clients_pj.company_name}</span>

                  <h4>NOME FANTASIA</h4>
                  <span>{contentPage.clients_pj.trade_name}</span>
                </div>
              </div>

              <div className="conatinerInfo">
                <h3>Solicitante</h3>
                <div className="line"></div>

                <div>
                  <h4>NOME SOLICITANTE</h4>
                  <span>{contentPage.requesterName}</span>

                  <h4>TELEFONE</h4>
                  <span>{contentPage.phone}</span>

                  <h4>E-MAIL</h4>
                  <span>{contentPage.email}</span>
                </div>
              </div>

              <div className="conatinerInfo">
                <h3>Solicitação</h3>
                <div className="line"></div>
                <div>
                  <h4>PROTOCOLO</h4>
                  <span>{contentPage.id}</span>

                  <h4>NOTA FISCAL N°</h4>
                  <span>{contentPage.numberInvoice}</span>

                  <h4>TIPO DE DEVOLUÇÃO</h4>
                  <span>{contentPage.situationRequest}</span>
                  {contentPage.situationRequest === "parcial" ? (
                    <>
                      <h4>PRODUTOS</h4>
                      <span>{contentPage.products}</span>
                    </>
                  ) : null}

                  <h4>TIPO DE SOLICITAÇÃO</h4>
                  <span>{contentPage.requestType}</span>
                </div>
              </div>

              {contentPage.requestType === "devolução" ? (
                <div className="conatinerInfo">
                  <h3>Devolução</h3>
                  <div className="line"></div>
                  <div>
                    <h4>MOTIVO</h4>
                    <span>{contentPage.reason}</span>

                    <h4>DATA NOTA FISCAL</h4>
                    <span>{contentPage.dateInvoice}</span>
                  </div>
                </div>
              ) : (
                <div className="conatinerInfo">
                  <h3>Indenização</h3>
                  <div className="line"></div>
                  <div>
                    <h4>MARCA</h4>
                    <span>{contentPage.brand.name}</span>

                    <h4>ESTADO DO PRODUTO</h4>
                    <span>{contentPage.stateProducts}</span>

                    <h4>NOTA FISCAL</h4>
                    <span>{contentPage.numberInvoice}</span>

                    <h4>DATA NOTA FISCAL</h4>
                    <span>
                      {contentPage.dateInvoice
                        ? moment(contentPage.dateInvoice).format("YY/MM/YYYY")
                        : "-"}
                    </span>
                  </div>
                </div>
              )}

              <div className="conatinerInfo">
                <h3>Dados bancários</h3>
                <div className="line"></div>
                <div>
                  <h4>BANCO</h4>
                  <span>{contentPage.bank}</span>

                  <h4>AGENCIA</h4>
                  <span>{contentPage.agency}</span>

                  <h4>CONTA</h4>
                  <span>{contentPage.account}</span>

                  <h4>TIPO DE CONTA</h4>
                  <span>{contentPage.typeAccountBank}</span>
                </div>
              </div>

              <div className="conatinerInfo">
                <h3>Informações complementares</h3>
                <div className="line" />
                <div>
                  <p>{contentPage.complementaryInformation}</p>
                </div>
              </div>

              {modalVisible &&
                modalFileOpen !== null &&
                modalFileOpen !== undefined && (
                  <Modal
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                    images={listfiles}
                    indexImg={modalFileOpen}
                  />
                )}

              <div className="conatinerInfo">
                <h3>Arquivos</h3>
                <div className="line" />
                <div className="containerImg">
                  {listfiles.map((img, index) => (
                    <LazyLoadImage
                      effect="blur"
                      onClick={() => {
                        setModalFileOpen(index);
                        setModalVisible(true);
                      }}
                      key={img.id}
                      src={
                        img.mimetype === "application/pdf"
                          ? iconPdf
                          : `data:image/png;base64,${img.fileBase64}`
                      }
                      alt={img.name}
                    />
                  ))}
                </div>
              </div>
            </div>
          </InfoDevolution>

          {admin && (
            <InfoDevolution>
              <div className="headerDevoltion">
                <h2>Dados internos</h2>
              </div>
              <div className="bodyDevoltion">
                <Form
                  onSubmit={
                    permission &&
                    permission.indemnity &&
                    permission.indemnity > 2
                      ? formikInternalData.handleSubmit
                      : () => {}
                  }
                >
                  <h3>Solicitação</h3>
                  <div className="field">
                    <label htmlFor="requesterName">Status da solicitação</label>
                    <select
                      disabled={disabledInputs}
                      {...formikInternalData.getFieldProps("status")}
                    >
                      <option
                        key={contentPage.status?.id}
                        value={contentPage.status?.cod}
                      >
                        {contentPage.status?.name}
                      </option>

                      {status.map((item) => {
                        if (
                          Number(item.user_id) ===
                          Number(userStorage.permission)
                        )
                          return (
                            <option key={item.id} value={item.cod}>
                              {item.name}
                            </option>
                          );

                        return null;
                      })}
                    </select>
                  </div>

                  {contentPage &&
                    contentPage.status &&
                    Number(formikInternalData.values.status) !==
                      Number(contentPage.status.cod) && (
                      <div className="field">
                        <label htmlFor="statusObservation">
                          Observação sobre alteração de status
                        </label>
                        <textarea
                          disabled={disabledInputs}
                          id="statusObservation"
                          {...formikInternalData.getFieldProps(
                            "statusObservation"
                          )}
                        ></textarea>
                        {formikInternalData.touched.statusObservation &&
                        formikInternalData.errors.statusObservation ? (
                          <ErrorDiv>
                            <FiAlertCircle color="#f00" size={16} />
                            <span>
                              {formikInternalData.errors.statusObservation}
                            </span>
                          </ErrorDiv>
                        ) : null}
                      </div>
                    )}

                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="requesterName">
                        Responsável pela solicitação
                      </label>
                      <select
                        disabled={disabledInputs}
                        {...formikInternalData.getFieldProps("idUser")}
                      >
                        <option value="">Selecionar responsável</option>
                        {users.map((item) => (
                          <option
                            key={item.id}
                            value={item.id}
                          >{`${item.name} ${item.lastname}`}</option>
                        ))}
                      </select>
                      {formikInternalData.touched.idUser &&
                      formikInternalData.errors.idUser ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formikInternalData.errors.idUser} </span>
                        </ErrorDiv>
                      ) : null}
                    </div>

                    <div className="field">
                      <label htmlFor="requesterName">Motivo Interno</label>
                      <select
                        disabled={disabledInputs}
                        {...formikInternalData.getFieldProps("subReason")}
                      >
                        <option value="">Selecionar responsável</option>
                        {internalReasons.map((item) => (
                          <option key={item.id} value={item.cod}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      {formikInternalData.touched.subReason &&
                      formikInternalData.errors.subReason ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formikInternalData.errors.subReason} </span>
                        </ErrorDiv>
                      ) : null}
                    </div>
                  </div>

                  <div className="field">
                    <label htmlFor="internalObservation">
                      Observação interna
                    </label>
                    <textarea
                      disabled={disabledInputs}
                      id="internalObservation"
                      {...formikInternalData.getFieldProps(
                        "internalObservation"
                      )}
                    ></textarea>
                    {/* {formikCreateDevolution.touched.complementaryInformation && formikCreateDevolution.errors.complementaryInformation ? (
                    <ErrorDiv><FiAlertCircle color="#f00" size={16} /><span> {formikCreateDevolution.errors.complementaryInformation} </span></ErrorDiv>
                    ) : null} */}
                  </div>

                  <div className="containerOBS">
                    <span>Observação sobre alteração de status</span>

                    <div>
                      {contentPage.observation &&
                        contentPage.observation[0] &&
                        contentPage.observation.map((item) => (
                          <ObsText>
                            <VscTriangleRight color="#000" />
                            <p>
                              {item.authorType === 1 &&
                                `Cliente  alterou status para ${item.statusIndemnity.name}`}
                              {item.authorType === 2 &&
                                `Operador ${item.user.name} ${item.user.lastname} alterou status para ${item.statusIndemnity.name} : `}

                              {item.authorType !== 1 && ` ${item.text}`}
                            </p>
                          </ObsText>
                        ))}
                    </div>
                  </div>

                  <h3>Dados do NFD</h3>
                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="requesterName">N° da NFD</label>
                      <input
                        disabled={disabledInputs}
                        className="nfdNumber"
                        {...formikInternalData.getFieldProps("nfdNumber")}
                      />
                      {formikInternalData.touched.nfdNumber &&
                      formikInternalData.errors.nfdNumber ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formikInternalData.errors.nfdNumber} </span>
                        </ErrorDiv>
                      ) : null}
                    </div>
                    <div className="field">
                      <label htmlFor="nfdValue">Valor da NFD</label>
                      <input
                        disabled={disabledInputs}
                        className="nfdValue"
                        type="number"
                        {...formikInternalData.getFieldProps("nfdValue")}
                      />
                      {formikInternalData.touched.nfdValue &&
                      formikInternalData.errors.nfdValue ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formikInternalData.errors.nfdValue} </span>
                        </ErrorDiv>
                      ) : null}
                    </div>
                  </div>
                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="nfdDate">Data de lançamento da NFD</label>
                      <input
                        disabled={disabledInputs}
                        className="nfdDate"
                        type="date"
                        {...formikInternalData.getFieldProps("nfdDate")}
                      />
                      {formikInternalData.touched.nfdDate &&
                      formikInternalData.errors.nfdDate ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formikInternalData.errors.nfdDate} </span>
                        </ErrorDiv>
                      ) : null}
                    </div>
                    <div className="field">
                      <label htmlFor="nfdDateEmission">
                        Data de emissão da NFD
                      </label>
                      <input
                        disabled={disabledInputs}
                        className="nfdDateEmission"
                        type="date"
                        {...formikInternalData.getFieldProps("nfdDateEmission")}
                      />
                      {formikInternalData.touched.nfdDateEmission &&
                      formikInternalData.errors.nfdDateEmission ? (
                        <ErrorDiv>
                          <FiAlertCircle color="#f00" size={16} />
                          <span>
                            {formikInternalData.errors.nfdDateEmission}
                          </span>
                        </ErrorDiv>
                      ) : null}
                    </div>
                  </div>

                  {!disabledInputs &&
                    permission &&
                    permission.indemnity &&
                    permission.indemnity > 2 && (
                      <div className="containerButton">
                        <ButtunSubmit type="submit">
                          Salvar alterações
                        </ButtunSubmit>
                      </div>
                    )}
                </Form>
              </div>
            </InfoDevolution>
          )}
          <ContainerGroup>
            <InfoDevolution style={{ boxShadow: "none" }}>
              <form onSubmit={hadleSubitChat}>
                <div className="headerDevoltion">
                  <h2>Mensagens</h2>
                </div>

                <div className="bodyChat">
                  {chat.map((item, i) => {
                    let messageDate = new Date(item.created_at as Date);

                    return (
                      <div
                        key={i}
                        className={
                          admin
                            ? item.typeUser === "client"
                              ? "to"
                              : "i"
                            : item.typeUser === "client"
                            ? "i"
                            : "to"
                        }
                      >
                        <div className="containerchat">
                          <div className="containerMessage">
                            <header>
                              <span>{item.name}</span>
                            </header>
                            <p>{item.textMessage}</p>
                          </div>
                          <footer>
                            {moment(messageDate).format("hh:mm A - DD/MM/YYYY")}
                            {` - `}
                            {item.typeUser === "client"
                              ? "Cliente"
                              : item.typeUser === "admin"
                              ? "Operador"
                              : item.typeUser === "seller_manager"
                              ? "Gerente"
                              : "Representante"}
                          </footer>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {((permission &&
                  permission.indemnity &&
                  permission.indemnity > 2) ||
                  client) && (
                  <footer>
                    <div>
                      <textarea
                        placeholder="Digite uma nova mensagem"
                        onChange={(e) => setTextChat(e.target.value)}
                        value={textChat}
                        typeof="text"
                      />
                      <button type="submit">
                        <IoMdSend size={24} color="#fff" />
                      </button>
                    </div>
                  </footer>
                )}
              </form>
            </InfoDevolution>

            {((permission &&
              permission.indemnity &&
              permission.indemnity > 2) ||
              client) && (
              <InfoDevolution style={{ boxShadow: "none" }}>
                <div className="headerDevoltion">
                  <h2>Anexar Arquivos</h2>
                </div>
                <div className="bodyDevoltion">
                  <ContainerDropZone>
                    <Dropzone
                      accept={["image/*", "application/pdf"]}
                      onFileUploaded={(e) => setSelectFile(e)}
                    />
                    <ContainerPreviews>
                      {files.map((file) => (
                        <li key={`${generateHash()}-${file.name}`}>
                          <img
                            src={
                              file.type === "application/pdf"
                                ? iconPdf
                                : URL.createObjectURL(file)
                            }
                            alt=""
                          />
                          <div className="fileInfo">
                            <strong>{file.name}</strong>
                            <span>{filesize(file.size)}</span>
                            <button
                              type="button"
                              onClick={() => {
                                hadleDeleteFile(file.name);
                              }}
                            >
                              Excluir
                            </button>
                          </div>
                        </li>
                      ))}
                    </ContainerPreviews>
                  </ContainerDropZone>

                  <ButtunSubmit onClick={hadleSubmitFiles}>
                    Enviar anexos
                  </ButtunSubmit>
                </div>
              </InfoDevolution>
            )}
          </ContainerGroup>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Devolution;
