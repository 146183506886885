import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionPrint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 100px;

  button {
    width: 100mm;
    height: 50px;

    background: #4b8bf5;

    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
    color: #fff;

    &:nth-child(1) {
      margin-right: 15mm;
      background: #4b8bf5;
    }

    &:nth-child(2) {
      background: #61bd4f;
    }

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
