import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import api from "../../../service/api";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";
// import moment from "moment";

import Loading from "../../../components/loadings/Loading";
import LoadingDot from "../../../components/loadings/LoadingDot";
import Modal from "../../../components/ModalLib";
import { dataUndefined, dataZero } from "../../../utils/dataProcessing";
import {
  ButtunSubmit,
  ContainerForm,
  PopUpConfirmation,
} from "../../detail/styles";

import moment from "moment";
import { BiMailSend } from "react-icons/bi";
import { FaTrashAlt } from "react-icons/fa";
import { FiAlertCircle, FiSearch } from "react-icons/fi";
import { IoMdCloseCircle, IoMdTrash } from "react-icons/io";
import { MdAdd, MdOutlineCancel } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { IPermissionUser } from "../../../@types";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { OrderBy } from "../../../components/panel/PanelOrderBy";
import useOutsideAlerter from "../../../hook/outsideAlerter";
import { differenceDate } from "../../../utils/differenceDate";
import {
  Container,
  ContainerList,
  Error,
  Filter,
  FilterCheck,
  FilterSelects,
  FooterContainer,
  Form,
  LoadingPanel,
  SlaContainer,
  TableContainer,
} from "../styles";

interface IData {
  id: number;
  codeOrder: string;
  value: number;
  dueDate?: Date;
  created_at: Date;
  isActive: boolean;

  clients_pj: {
    company_name: string;
    cnpj: string;
    email: string;
  };

  debt_type?: {
    cod: string;
    name: string;
  };

  payment?: {
    id: number;
    status: string;
    status_color: string;
  }[];
}

interface IHeaderProps {
  admin: boolean;
  client: boolean;
  permission: IPermissionUser;
}

interface IFilter {
  idDebt?: string;
  isActive?: number;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;

  duePeriod?: number;
  presetDateDue?: number;
  specificDateDue?: Date;
  startDateDue?: Date;
  endDateDue?: Date;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IResponseApi {
  contents: IData[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

const PanelPayment: React.FC<IHeaderProps> = ({
  admin,
  client,
  permission,
}) => {
  const history = useHistory();
  const outSideRef = useRef(null);
  const { addToast } = useToasts();

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({
    isActive: 1,
  });

  const [debts, setDebts] = useState<IData[]>([]);

  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState<IData>();

  const [isModalSendMail, setIsModalSendMail] = useState(false);
  const [isLoadingSendMail, setIsLoadingSendMail] = useState(false);
  const [dataMail, setDataMail] = useState<IData>();

  const [confirmationCancel, setConfirmationCancel] = useState(false);
  const [dataCancel, setDataCancel] = useState<IData>();

  const [loadingModal, setLoadingModal] = useState(false);
  const [update, setUpdate] = useState(1);

  const [loading, setLoading] = useState({
    panel: true,
  });

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  const [orderBy, setOrderBy] = useState("id.desc");

  const formikSendMail = useFormik({
    validateOnChange: false,

    initialValues: {
      email: "",
      option: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("E-mail invalido").required("Obrigatório"),
      option: Yup.string().required("Obrigatório"),
    }),

    onSubmit: async (data) => {
      setIsLoadingSendMail(true);

      try {
        await handleSendReport({ email: data.email });
        formikSendMail.resetForm();

        addToast(
          `
            Relatório de Auditoria enviado com sucesso!`,
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
        setIsLoadingSendMail(false);
        setIsModalSendMail(false);
      } catch (error) {
        setIsLoadingSendMail(false);
        addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading({ ...loading, panel: true });
        if (admin) {
          const responseAdmin = await api.get<IResponseApi>("/debt", {
            params: { ...filters, ...requestPagination, orderBy: orderBy },
          });

          const { contents, limit, page, pages, total } = responseAdmin.data;

          setDebts(contents);
          setPagination({ limit, page, pages, total });
          setLoading({ ...loading, panel: false });
        } else {
          const responseClient = await api.get<IResponseApi>(`/debt/client/`, {
            params: { ...filters, ...requestPagination, orderBy: orderBy },
          });
          const { contents, limit, page, pages, total } = responseClient.data;

          setDebts(contents);
          setPagination({ limit, page, pages, total });
          setLoading({ ...loading, panel: false });
        }
      } catch (error) {
        setLoading({ ...loading, panel: false });
      }
    })();
    // eslint-disable-next-line
  }, [filters, requestPagination, update, orderBy]);

  useEffect(() => {
    if (formikSendMail.values.option === "cliente") {
      formikSendMail.setFieldValue("email", dataMail?.clients_pj.email);
    }
    if (formikSendMail.values.option === "outro") {
      formikSendMail.setFieldValue("email", "");
    }
    // eslint-disable-next-line
  }, [formikSendMail.values.option]);

  function hadleToNew() {
    history.push("/admin/criar/negociacao/aberta");
  }

  function handleOpenRow(id: number) {
    if (admin) {
      history.push(`/admin/negociacao/info/${id}`);
    } else {
      history.push(`/negociacao/info/${id}`);
    }
  }

  async function handleSendReport(data: { email: string }) {
    const to = {
      email: data.email,
    };

    await api.post(`/debt/email/${dataMail?.id}`, {
      email: to.email,
    });
  }

  async function handleDelete(id: number) {
    setLoadingModal(true);
    try {
      await api.delete(`/debt/${id}`);
      setUpdate(update + 1);
      setConfirmationDelete(false);

      setLoadingModal(false);
      addToast("Negociação excluído com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setLoadingModal(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleCancel(id: number) {
    setLoadingModal(true);
    try {
      await api.post(`/debt/cancel/${id}`);
      setUpdate(update + 1);
      setConfirmationCancel(false);

      setLoadingModal(false);
      addToast("Negociação cancelada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setLoadingModal(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setFilterView(false);
  }

  function viewPresetDate(view: number): string {
    let returnValue = "";
    if (Number(view) === 0) {
      returnValue = "Hoje";
    }
    if (Number(view) === 2) {
      returnValue = "Ontem";
    }
    if (Number(view) === 7) {
      returnValue = "Últimos 7 dias";
    }
    if (Number(view) === 15) {
      returnValue = "Últimos 15 dias";
    }
    if (Number(view) === 30) {
      returnValue = "Últimos 30 dias";
    }

    return returnValue;
  }

  function viewPeriodDate(view: number): string {
    let returnValue = "";
    if (Number(view) === 1) {
      returnValue = "Predefinida";
    }
    if (Number(view) === 2) {
      returnValue = "Específica";
    }
    if (Number(view) === 3) {
      returnValue = "Intevalo";
    }

    return returnValue;
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  return (
    <Container>
      <header>
        <h2>Negociações</h2>
      </header>

      <Filter>
        <button
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="button"
        >
          <FiSearch />
        </button>
        <input
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="text"
          name="filter"
          id="filter"
          readOnly={true}
        />
        <FilterSelects>
          <ul>
            {filters.idDebt && (
              <li>
                <span>CÓDIGO NEGOCIAÇÃO EM ABERTO: {filters.idDebt}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, idDebt: undefined })}
                />
              </li>
            )}

            {filters.isActive && (
              <li>
                <span>
                  ATIVO: {Boolean(Number(filters.isActive)) ? "SIM" : "NÃO"}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, isActive: undefined })
                  }
                />
              </li>
            )}

            {filters.codClient && (
              <li>
                <span>COD CLIENTE: {filters.codClient}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, codClient: undefined })
                  }
                />
              </li>
            )}

            {filters.cnpj && (
              <li>
                <span>CNPJ: {filters.cnpj}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, cnpj: undefined })}
                />
              </li>
            )}

            {filters.company_name && (
              <li>
                <span>RAZÃO SOCIAL: {filters.company_name}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, company_name: undefined })
                  }
                />
              </li>
            )}

            {filters.creationPeriod && (
              <li>
                <span>
                  PERÍODO DE CRIAÇÃO: {viewPeriodDate(filters.creationPeriod)}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      creationPeriod: undefined,
                      presetDate: undefined,
                      specificDate: undefined,
                      startDate: undefined,
                      endDate: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.presetDate && (
              <li>
                <span>
                  DATA PREDEFINIDA: {viewPresetDate(Number(filters.presetDate))}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      presetDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.specificDate && (
              <li>
                <span>DATA ESPECÍFICA: {filters.specificDate}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      specificDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.startDate && (
              <li>
                <span>DATA INICIAL: {filters.startDate}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      startDate: undefined,
                      endDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.endDate && (
              <li>
                <span>DATA FINAL: {filters.endDate}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      endDate: undefined,
                      startDate: undefined,
                      creationPeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.duePeriod && (
              <li>
                <span>
                  PERÍODO DE VENCIMENTO:
                  {viewPeriodDate(filters.duePeriod)}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      duePeriod: undefined,
                      presetDateDue: undefined,
                      specificDateDue: undefined,
                      startDateDue: undefined,
                      endDateDue: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.presetDateDue && (
              <li>
                <span>
                  DATA PREDEFINIDA:
                  {viewPresetDate(Number(filters.presetDateDue))}
                </span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      presetDateDue: undefined,
                      duePeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.specificDateDue && (
              <li>
                <span>DATA ESPECÍFICA: {filters.specificDateDue}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      specificDateDue: undefined,
                      duePeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.startDateDue && (
              <li>
                <span>DATA INICIAL: {filters.startDateDue}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      startDateDue: undefined,
                      endDateDue: undefined,
                      duePeriod: undefined,
                    })
                  }
                />
              </li>
            )}

            {filters.endDateDue && (
              <li>
                <span>DATA FINAL: {filters.endDateDue}</span>
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({
                      ...filters,
                      endDateDue: undefined,
                      startDateDue: undefined,
                      duePeriod: undefined,
                    })
                  }
                />
              </li>
            )}
          </ul>
        </FilterSelects>

        {filterView && (
          <FilterCheck ref={outSideRef}>
            <header>
              <button
                onClick={() => {
                  setFilterView(false);
                }}
              >
                Fechar
              </button>
            </header>
            <main>
              <Formik
                initialValues={{
                  idDebt: filters.idDebt,
                  isActive: filters.isActive,

                  codClient: filters.codClient,
                  cnpj: filters.cnpj,
                  company_name: filters.company_name,

                  creationPeriod: filters.creationPeriod,
                  presetDate: filters.presetDate,
                  specificDate: filters.specificDate,
                  startDate: filters.startDate,
                  endDate: filters.endDate,

                  duePeriod: filters.duePeriod,
                  presetDateDue: filters.presetDateDue,
                  specificDateDue: filters.specificDateDue,
                  startDateDue: filters.startDateDue,
                  endDateDue: filters.endDateDue,
                }}
                validationSchema={Yup.object({
                  presetDate: Yup.string().when("creationPeriod", {
                    is: "1",
                    then: Yup.string().required(
                      "Data predefinida é obrigatório"
                    ),
                  }),

                  specificDate: Yup.string().when("creationPeriod", {
                    is: "2",
                    then: Yup.string().required(
                      "Data específica é obrigatório"
                    ),
                  }),

                  startDate: Yup.string().when("creationPeriod", {
                    is: "3",
                    then: Yup.string().required("Data inicio é obrigatório"),
                  }),

                  endDate: Yup.string().when("creationPeriod", {
                    is: "3",
                    then: Yup.string().required("Data final é obrigatório"),
                  }),

                  //Vencimento

                  presetDateDue: Yup.string().when("duePeriod", {
                    is: "1",
                    then: Yup.string().required(
                      "Data predefinida é obrigatório"
                    ),
                  }),

                  specificDateDue: Yup.string().when("duePeriod", {
                    is: "2",
                    then: Yup.string().required(
                      "Data específica é obrigatório"
                    ),
                  }),

                  startDateDue: Yup.string().when("duePeriod", {
                    is: "3",
                    then: Yup.string().required("Data inicio é obrigatório"),
                  }),

                  endDateDue: Yup.string().when("duePeriod", {
                    is: "3",
                    then: Yup.string().required("Data final é obrigatório"),
                  }),
                })}
                onSubmit={(data) => {
                  hadleFilter(data);
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="idDebt">
                          CÓDIGO NEGOCIAÇÃO EM ABERTO
                        </label>
                        <input
                          id="idDebt"
                          {...formik.getFieldProps("idDebt")}
                        />
                      </div>

                      <div className="field-group">
                        <div className="field">
                          <label htmlFor="isActive">ATIVO</label>
                          <select {...formik.getFieldProps("isActive")}>
                            <option value="">Selecionar Situação</option>
                            <option value={1}>SIM</option>
                            <option value={0}>NÃO</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {admin && (
                      <div className="field-group">
                        <div className="field">
                          <label htmlFor="codClient">CODIGO CLIENTE</label>
                          <input
                            id="codClient"
                            {...formik.getFieldProps("codClient")}
                          />
                        </div>
                        <div className="field">
                          <label htmlFor="cnpj">CNPJ</label>
                          <input id="cnpj" {...formik.getFieldProps("cnpj")} />
                        </div>
                        <div className="field">
                          <label htmlFor="company_name">RAZÃO SOCIAL</label>
                          <input
                            id="company_name"
                            {...formik.getFieldProps("company_name")}
                          />
                        </div>
                      </div>
                    )}

                    {admin && (
                      <>
                        <div className="field-group">
                          <div className="field">
                            <label htmlFor="typeDate">PERÍODO DE CRIAÇÃO</label>
                            <select {...formik.getFieldProps("creationPeriod")}>
                              <option value=""></option>
                              <option value={1}>Predefinida</option>
                              <option value={2}>Específica</option>
                              <option value={3}>Intervalo</option>
                            </select>
                          </div>
                        </div>
                        {Number(formik.values.creationPeriod) === 1 ? (
                          <div className="field">
                            <label htmlFor="typeDate">DATA PREDEFINIDA</label>
                            <select {...formik.getFieldProps("presetDate")}>
                              <option value=""></option>
                              <option value={0}>Hoje</option>
                              <option value={1}>Ontem</option>
                              <option value={7}>Últimos 7 dias</option>
                              <option value={15}>Últimos 15 dias</option>
                              <option value={30}>Últimos 30 dias</option>
                            </select>
                            {formik.touched.presetDate &&
                            formik.errors.presetDate ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span> {formik.errors.presetDate} </span>
                              </Error>
                            ) : null}
                          </div>
                        ) : null}

                        {Number(formik.values.creationPeriod) === 2 ? (
                          <div className="field">
                            <label htmlFor="typeDate">DATA ESPECÍFICA</label>
                            <input
                              type="date"
                              {...formik.getFieldProps("specificDate")}
                            />
                            {formik.touched.specificDate &&
                            formik.errors.specificDate ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span> {formik.errors.specificDate} </span>
                              </Error>
                            ) : null}
                          </div>
                        ) : null}

                        <div className="field-group">
                          {Number(formik.values.creationPeriod) === 3 ? (
                            <>
                              <div className="field">
                                <label htmlFor="typeDate">DATA INICIAL</label>
                                <input
                                  type="date"
                                  {...formik.getFieldProps("startDate")}
                                />
                                {formik.touched.startDate &&
                                formik.errors.startDate ? (
                                  <Error>
                                    <FiAlertCircle color="#f00" size={16} />
                                    <span> {formik.errors.startDate} </span>
                                  </Error>
                                ) : null}
                              </div>
                              <div className="field">
                                <label htmlFor="typeDate">DATA FINAL</label>
                                <input
                                  type="date"
                                  {...formik.getFieldProps("endDate")}
                                />
                                {formik.touched.endDate &&
                                formik.errors.endDate ? (
                                  <Error>
                                    <FiAlertCircle color="#f00" size={16} />
                                    <span> {formik.errors.endDate} </span>
                                  </Error>
                                ) : null}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </>
                    )}
                    {admin && false && (
                      <>
                        <div className="field-group">
                          <div className="field">
                            <label htmlFor="typeDate">
                              PERÍODO DE VENCIMENTO
                            </label>
                            <select {...formik.getFieldProps("duePeriod")}>
                              <option value=""></option>
                              <option value={1}>Predefinida</option>
                              <option value={2}>Específica</option>
                              <option value={3}>Intervalo</option>
                            </select>
                          </div>
                        </div>
                        {Number(formik.values.duePeriod) === 1 ? (
                          <div className="field">
                            <label htmlFor="typeDate">DATA PREDEFINIDA</label>
                            <select {...formik.getFieldProps("presetDateDue")}>
                              <option value=""></option>
                              <option value={0}>Hoje</option>
                              <option value={1}>Ontem</option>
                              <option value={7}>Últimos 7 dias</option>
                              <option value={15}>Últimos 15 dias</option>
                              <option value={30}>Últimos 30 dias</option>
                            </select>
                            {formik.touched.presetDateDue &&
                            formik.errors.presetDateDue ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span> {formik.errors.presetDateDue} </span>
                              </Error>
                            ) : null}
                          </div>
                        ) : null}

                        {Number(formik.values.duePeriod) === 2 ? (
                          <div className="field">
                            <label htmlFor="typeDate">DATA ESPECÍFICA</label>
                            <input
                              type="date"
                              {...formik.getFieldProps("specificDateDue")}
                            />
                            {formik.touched.specificDateDue &&
                            formik.errors.specificDateDue ? (
                              <Error>
                                <FiAlertCircle color="#f00" size={16} />
                                <span> {formik.errors.specificDateDue} </span>
                              </Error>
                            ) : null}
                          </div>
                        ) : null}

                        <div className="field-group">
                          {Number(formik.values.duePeriod) === 3 ? (
                            <>
                              <div className="field">
                                <label htmlFor="typeDate">DATA INICIAL</label>
                                <input
                                  type="date"
                                  {...formik.getFieldProps("startDateDue")}
                                />
                                {formik.touched.startDateDue &&
                                formik.errors.startDateDue ? (
                                  <Error>
                                    <FiAlertCircle color="#f00" size={16} />
                                    <span> {formik.errors.startDateDue} </span>
                                  </Error>
                                ) : null}
                              </div>
                              <div className="field">
                                <label htmlFor="typeDate">DATA FINAL</label>
                                <input
                                  type="date"
                                  {...formik.getFieldProps("endDateDue")}
                                />
                                {formik.touched.endDateDue &&
                                formik.errors.endDateDue ? (
                                  <Error>
                                    <FiAlertCircle color="#f00" size={16} />
                                    <span> {formik.errors.endDateDue} </span>
                                  </Error>
                                ) : null}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </>
                    )}

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                )}
              </Formik>
            </main>
          </FilterCheck>
        )}
      </Filter>

      <OrderBy
        data={[
          {
            name: "Negociações Recentes",
            value: "created_at.desc",
          },
          {
            name: "Negociações Antigas",
            value: "created_at.asc",
          },
          {
            name: "Alterações Recentes ",
            value: "updated_at.desc",
          },
        ]}
        setState={setOrderBy}
        state={orderBy}
      />

      <ContainerList>
        <div className="headerTable">
          <h3>Negociações</h3>

          <div>
            {admin && (
              <button onClick={hadleToNew} type="button">
                <MdAdd size={22} /> <span> NOVA NEGOCIAÇÕES EM ABERTO </span>
              </button>
            )}
          </div>
        </div>

        <TableContainer>
          {!loading.panel ? (
            <table>
              <thead>
                <tr>
                  {client && <th></th>}
                  <th>Cód. Negociação</th>
                  <th>Ativo</th>
                  <th>Tipo</th>
                  <th>Situação</th>
                  <th>Valor</th>
                  {admin && <th>Cliente</th>}
                  <th>Vencimento em</th>

                  {admin && permission && permission.payment > 2 && (
                    <th>Ações</th>
                  )}
                </tr>
              </thead>

              <tbody>
                {debts.map((element) => {
                  const slaDifferenceDate =
                    element.dueDate && element.isActive
                      ? differenceDate(new Date(), new Date(element.dueDate))
                      : undefined;

                  return (
                    <tr
                      key={element.id}
                      style={{ opacity: element.isActive ? 1 : 0.6 }}
                    >
                      {client &&
                        (element.isActive ? (
                          <td>
                            <button
                              className="stuationContainer"
                              type="button"
                              onClick={() => {
                                history.push(
                                  `/realizar/pagamento/${element.id}`
                                );
                              }}
                            >
                              <div>
                                <RiMoneyDollarCircleFill
                                  size={30}
                                  color={"#009c27"}
                                />
                                PAGAR
                              </div>
                            </button>
                          </td>
                        ) : (
                          <td> - </td>
                        ))}

                      <td onClick={() => handleOpenRow(element.id)}>
                        {Number(element.id)}
                      </td>

                      <td onClick={() => handleOpenRow(element.id)}>
                        <span
                          style={{
                            backgroundColor: element.isActive
                              ? "rgba(136, 227, 138, 0.5)"
                              : "rgba(255, 61, 61, 0.8)",
                            color: "#000",
                            borderRadius: 6,
                            padding: "2px 5px",
                          }}
                        >
                          {element.isActive ? "Sim" : "Não"}
                        </span>
                      </td>

                      <td onClick={() => handleOpenRow(element.id)}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span
                            style={{ marginTop: "4px", fontWeight: "bold" }}
                          >
                            {dataUndefined(element.debt_type?.name) === "-" &&
                            element.codeOrder
                              ? "Pedido"
                              : dataUndefined(element.debt_type?.name)}
                          </span>
                          {element.codeOrder && (
                            <span
                              style={{
                                marginTop: "4px",
                                fontWeight: "lighter",
                              }}
                            >
                              #{element.codeOrder}
                            </span>
                          )}
                        </div>
                      </td>

                      <td onClick={() => handleOpenRow(element.id)}>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {element.payment && element.payment[0] ? (
                            <span
                              className="status"
                              style={{
                                backgroundColor:
                                  element.payment[0].status_color,
                              }}
                            >
                              {element.payment[0].status}
                            </span>
                          ) : !element.isActive ? (
                            <span
                              className="status"
                              style={{
                                backgroundColor: "rgba(255, 61, 61, 0.8)",
                              }}
                            >
                              Inativo
                            </span>
                          ) : (
                            <span
                              className="status"
                              style={{
                                backgroundColor: "rgba(255, 238, 0, 0.5)",
                              }}
                            >
                              Em Aberto
                            </span>
                          )}
                        </div>
                      </td>

                      <td onClick={() => handleOpenRow(element.id)}>
                        {(element.value / 100).toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                      {admin && (
                        <td onClick={() => handleOpenRow(element.id)}>
                          {element.clients_pj.company_name}
                        </td>
                      )}

                      <td>
                        <SlaContainer>
                          {slaDifferenceDate !== undefined &&
                          element.isActive ? (
                            <>
                              <div>
                                <b
                                  style={{
                                    color:
                                      slaDifferenceDate + 1 > 3
                                        ? "rgba(90, 182, 95, 0.8)"
                                        : slaDifferenceDate + 1 >= 0
                                        ? " rgba(193, 169, 10, 0.6)"
                                        : "rgba(255, 61, 61, 0.8)",
                                  }}
                                >
                                  {slaDifferenceDate + 1 === 0
                                    ? "Último Dia Do Vencimento"
                                    : slaDifferenceDate < 0
                                    ? `Em Atraso ${
                                        (slaDifferenceDate + 1) * -1
                                      } dias`
                                    : `${
                                        slaDifferenceDate + 1
                                      } dias para o Vencimento`}
                                </b>
                              </div>
                              <div>
                                {element.dueDate
                                  ? moment(element.dueDate)
                                      .add("day", 1)
                                      .format("DD/MM/YYYY")
                                  : "-"}
                              </div>
                            </>
                          ) : (
                            "-"
                          )}
                        </SlaContainer>
                      </td>

                      {admin && permission && permission.payment > 2 && (
                        <td className="actionsPanel">
                          <div>
                            {(!element?.payment ||
                              (element?.payment && element?.payment?.length) <=
                                0) && (
                              <button
                                type="button"
                                onClick={() => {
                                  setDataDelete(element);
                                  setConfirmationDelete(true);
                                }}
                              >
                                <IoMdTrash color="rgba(255, 61, 61)" />
                              </button>
                            )}

                            {element.isActive && (
                              <>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setDataCancel(element);
                                    setConfirmationCancel(true);
                                  }}
                                >
                                  <MdOutlineCancel color="#f97a2e" />
                                </button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    setDataMail(element);
                                    setIsModalSendMail(true);
                                  }}
                                >
                                  <BiMailSend color="#0073c5" />
                                </button>
                              </>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>
        <FooterContainer>
          <span>
            Mostrado{" "}
            {dataZero(
              Number(debts.length + pagination.limit * pagination.page)
            )}{" "}
            de {dataZero(Number(pagination.total))} resultados
          </span>

          <div>
            <button
              className={pagination.page < 1 ? "buttonNull" : ""}
              onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
              type="button"
            >
              ANTERIOR
            </button>
            <p style={{ marginRight: 8 }}>
              {dataZero(Number(pagination.page + 1))}
            </p>
            <button
              className={
                pagination.page >= pagination.pages ? "buttonNull" : ""
              }
              type="button"
              onClick={
                Number(pagination.page) >= Number(pagination.pages)
                  ? () => {}
                  : hadleNextPage
              }
            >
              PROXIMO
            </button>
          </div>
        </FooterContainer>
      </ContainerList>

      {confirmationDelete && dataDelete && (
        <Modal
          title="Selecione o cliente"
          modalVisible={confirmationDelete}
          setModalVisible={setConfirmationDelete}
          headerOff={true}
          maxHeight={350}
          maxWidth={650}
        >
          <PopUpConfirmation>
            <div className="headerPopUp">
              <nav>
                <FaTrashAlt size={95} opacity={0.2} />
              </nav>
              <span>
                {`
                  
                Você tem certeza que deseja excluir a negociação código
                "${dataDelete.id}" do cliente ${
                  dataDelete.clients_pj.company_name
                } no valor de ${(dataDelete.value / 100).toLocaleString(
                  "pt-br",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )} ?`}
              </span>
            </div>
            <div className="buttonsPopUp">
              <button
                type="button"
                onClick={() => setConfirmationDelete(false)}
              >
                Cancelar
              </button>
              <button
                style={{ background: "#d21e26" }}
                type="button"
                onClick={() =>
                  loadingModal ? () => {} : handleDelete(dataDelete.id)
                }
              >
                {!loadingModal ? (
                  "Deletar"
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Loading
                      size={22}
                      borderSize={4}
                      colorLoading="rgba(255,255,255)"
                      borderColor="rgba(255,255,255, 0.3)"
                    />
                  </div>
                )}
              </button>
            </div>
          </PopUpConfirmation>
        </Modal>
      )}

      {confirmationCancel && dataCancel && (
        <Modal
          title="Selecione o cliente"
          modalVisible={confirmationCancel}
          setModalVisible={setConfirmationCancel}
          headerOff={true}
          maxHeight={350}
          maxWidth={650}
        >
          <PopUpConfirmation>
            <div className="headerPopUp">
              <nav>
                <MdOutlineCancel size={95} opacity={0.2} />
              </nav>
              <span>
                {`
                  
                Você tem certeza que deseja inativar a negociação código
                "${dataCancel.id}" do cliente ${
                  dataCancel.clients_pj.company_name
                } no valor de ${(dataCancel.value / 100).toLocaleString(
                  "pt-br",
                  {
                    style: "currency",
                    currency: "BRL",
                  }
                )} ?`}
              </span>
            </div>
            <div className="buttonsPopUp">
              <button
                type="button"
                onClick={() => setConfirmationCancel(false)}
              >
                Cancelar
              </button>
              <button
                style={{ background: "#d21e26" }}
                type="button"
                onClick={() =>
                  loadingModal ? () => {} : handleCancel(dataCancel.id)
                }
              >
                {!loadingModal ? (
                  "Deletar"
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Loading
                      size={22}
                      borderSize={4}
                      colorLoading="rgba(255,255,255)"
                      borderColor="rgba(255,255,255, 0.3)"
                    />
                  </div>
                )}
              </button>
            </div>
          </PopUpConfirmation>
        </Modal>
      )}

      <Modal
        modalVisible={isModalSendMail}
        setModalVisible={setIsModalSendMail}
        title={`Enviar Aviso de Negociação - Cód ${dataMail?.id}`}
        maxHeight={280}
        scrollOff
      >
        <Form
          onSubmit={
            isLoadingSendMail
              ? (e) => {
                  e.preventDefault();
                }
              : formikSendMail.handleSubmit
          }
        >
          <ContainerForm>
            <div className="containerForm">
              <GroupInput>
                <Select
                  label="Enviar E-mail para"
                  data={[
                    { name: "Cliente", value: "cliente" },
                    { name: "Outro", value: "outro" },
                  ]}
                  {...formikSendMail.getFieldProps("option")}
                />
                <Input
                  label="E-mail destinatário"
                  {...formikSendMail.getFieldProps("email")}
                  error={
                    formikSendMail.touched.email && formikSendMail.errors.email
                      ? formikSendMail.errors.email
                      : undefined
                  }
                />
              </GroupInput>
            </div>

            <div className="containerFormButton">
              <ButtunSubmit style={{ margin: 0 }} type="submit">
                {isLoadingSendMail ? (
                  <div>
                    <Loading borderSize={2} size={20} />
                  </div>
                ) : (
                  "Enviar"
                )}
              </ButtunSubmit>
            </div>
          </ContainerForm>
        </Form>
      </Modal>
    </Container>
  );
};

export default PanelPayment;
