import React from "react";
import { Link } from "react-router-dom";
import { IPermissionUser } from "../../@types";
import logo from "../../assets/icon_logo.png";
import PrivacyPolicy from "../../assets/privacy-policy.png";
import { useSideBar } from "../../hook/useSideBar";
import { ItemsNav } from "./ItemsNav";
import "./styles.css";

interface HeaderProps {
  admin?: boolean;
  seller?: boolean;
  client?: boolean;
  provider?: boolean;

  is_manager?: boolean;
  is_supervisor?: boolean;

  permission?: IPermissionUser;
}

const Navbar: React.FC<HeaderProps> = () => {
  const sidebar = useSideBar();

  return (
    <div className={`sidebar ${sidebar.isOpen ? "" : "close"}`}>
      <Link to="/" className="logo-details">
        <div className="container-img">
          <img src={logo} alt="Logo Alpar do Brasil" />
        </div>

        <span className="logo_name">Alpar do Brasil</span>
      </Link>

      <ItemsNav />

      <a
        // eslint-disable-next-line
        target="_blank"
        href="https://privacidade.alpardobrasil.com.br/"
        className="profile-details"
      >
        <div className="profile-content">
          <img src={PrivacyPolicy} alt="Política de privacidade" />
        </div>
        <div className="name-job">
          <div className="profile_name">Política de privacidade</div>
        </div>
      </a>
    </div>
  );
};

export default Navbar;
