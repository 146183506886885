import React, { memo } from "react";
import { ContainerForm, Form } from "./styles";

interface LayoutFormPros
  extends React.DetailedHTMLProps<
    React.FormHTMLAttributes<HTMLFormElement>,
    HTMLFormElement
  > {
  stylesContainerForm?: React.CSSProperties;
}

const LayoutFormComponent: React.FC<LayoutFormPros> = ({
  children,
  stylesContainerForm,
  ...rest
}) => {
  const restAny = rest as any;

  return (
    <ContainerForm style={stylesContainerForm}>
      <Form {...restAny}>{children}</Form>
    </ContainerForm>
  );
};

export const LayoutForm = memo(LayoutFormComponent);
