import React, { useRef } from "react";

import { Filter as FilterContainer, FilterCheck, FilterSelects } from "./style";

import useOutsideAlerter from "../../hook/outsideAlerter";

import { FiSearch } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";

interface IPropsHeader {
  setViewFilter: (value: React.SetStateAction<boolean>) => void;
  viewFilter: boolean;
  setFilters: (value: React.SetStateAction<IFilter[]>) => void;
  filters: IFilter[];
}

interface IFilter {
  name: string;
  description: string;
  value: any;
}

const Filter: React.FC<IPropsHeader> = ({
  viewFilter,
  setViewFilter,
  setFilters,
  children,
  filters,
}) => {
  const outSideRef = useRef(null);
  useOutsideAlerter(outSideRef, () => {
    if (viewFilter) setViewFilter(false);
  });

  async function handleRemoveFilter(item: IFilter) {
    const normilized = filters.filter(function (el) {
      return el.name !== item.name;
    });

    setFilters(normilized);
  }

  return (
    <FilterContainer>
      <button
        onClick={() => {
          setViewFilter(!viewFilter);
        }}
        type="button"
      >
        <FiSearch />
      </button>
      <input
        onClick={() => {
          setViewFilter(!viewFilter);
        }}
        type="text"
        name="filter"
        id="filter"
        readOnly={true}
      />
      <FilterSelects>
        <ul>
          {filters.map((item, index) => (
            <li key={index}>
              <span>
                {item.description}: {item.value}
              </span>
              <IoMdCloseCircle onClick={() => handleRemoveFilter(item)} />
            </li>
          ))}
        </ul>
      </FilterSelects>

      {viewFilter && (
        <FilterCheck ref={outSideRef}>
          <header>
            <button
              onClick={() => {
                setViewFilter(false);
              }}
            >
              Fechar
            </button>
          </header>
          <main>{children}</main>
        </FilterCheck>
      )}
    </FilterContainer>
  );
};

export default Filter;
