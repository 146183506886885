import React from "react";
import { OcScheduling } from "../../pages/detail/Scheduling";
import Modal from "../ModalLib";
import { TableClean } from "../TableClean";

interface Props {
  isVisible: boolean;
  onClose: () => void;
  data?: OcScheduling;
}

export function OcItemGrid({ data, isVisible, onClose }: Props) {
  return (
    <Modal
      height={"20rem"}
      modalVisible={isVisible}
      setModalVisible={onClose}
      title={`GRADE (${data?.reference})`}
    >
      <TableClean
        columns={[
          { title: "EAN", field: "ean" },
          { title: "TAMANHO", field: "size" },
          { title: "QTD", field: "qtd" },
          { title: "TOTAL", field: "value" },
        ]}
        data={
          data?.items?.map((item) => ({
            id: item.id,
            size: item.size,
            ean: item.ean,
            qtd: item.qtd,
            value: Number(item.value).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
          })) ?? []
        }
      />
    </Modal>
  );
}
