import styled from "styled-components";

export const Container = styled.div`
  background-color: transparent;
  width: 100%;
  height: 2rem;
`;

interface BtnTabProps {
  isActive?: boolean;
}

export const BtnTab = styled.button<BtnTabProps>`
  height: 100%;
  padding: 0.3rem 1rem;
  border-radius: 6px 6px 0 0;

  font-weight: ${(props) => (props.isActive ? "bolder" : "lighter")};
  font-size: 1rem;

  background-color: ${(props) => (props.isActive ? "#FFF" : "transparent")};
  cursor: ${(props) => (props.isActive ? "auto" : "poiter")}!important;

  transition: all 0.2s;

  :hover {
    background-color: ${(props) =>
      !props.isActive ? "rgba(255,255,255,0.4)" : undefined};
  }

  @media (max-width: 500px) {
    font-size: 0.8rem;
    padding: 0.3rem 0.5rem;
  }
`;
