import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  .containerStatus {
    position: relative;
    height: 72px;
    flex: 1;
  }

  .bannerLine {
    height: 5px;
    width: 100%;
    background-color: rgba(136, 227, 138, 0.5);
    border-radius: 4px 4px 0 0;
    position: absolute;
  }

  .containerStatus {
    /* margin-right: 8px; */
    margin-bottom: 8px;
  }

  /* .containerStatus:last-child {
    margin-right: 0px;
  } */

  .box {
    flex: 1;
    min-width: 200px;
    height: 100%;
    padding: 8px 14px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    overflow: auto;

    div {
      padding: 5px 0;
      h5 {
        font-size: 12px;
        color: #333;
      }
    }

    p {
      font-size: 15px;
      font-weight: 500;
    }

    span {
      font-size: 13px;
      font-weight: 400;
      border-radius: 4px;
      padding: 2px 5px;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .containerStatus {
      margin-left: 0;
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
`;
