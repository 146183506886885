import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import {
  ButtunSubmit,
  Error,
  Form,
  ScreenLoading,
} from "../../pages/create/styles";
import {
  HeaderDatasheetProps,
  StatusProps,
} from "../../pages/detail/HeaderDatasheet";
import api from "../../service/api";
import { Input } from "../Form/Input";
import Loading from "../loadings/Loading";
import { Container, ContainerForm } from "./styled";

interface ModalModificationDatasheetProps {
  type: "create" | "update";
  onRequestUpdate: () => void;
  onRequestClose: () => void;
  data: HeaderDatasheetProps;
}

interface IBrand {
  id?: number;
  cod: number;
  name: string;
  is_active: boolean;
}

interface IDescription {
  id: number;
  description: string;
  is_active: boolean;
}

export const ModalModificationHeaderDatasheet: React.FC<
  ModalModificationDatasheetProps
> = ({ type, onRequestUpdate, onRequestClose, data }) => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [contentPage, setContentPage] = useState<HeaderDatasheetProps>(
    {} as HeaderDatasheetProps
  );

  const [statusDatasheet, setStatusDatasheet] = useState<StatusProps[]>([]);
  const [collections, setCollections] = useState<IDescription[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [years, setYears] = useState<
    {
      id: number;
      year: string;
    }[]
  >([]);

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      statusId: "",
      collectionId: "",
      brandId: "",
      markUpStore: "",
      year: "",
    },
    validationSchema: Yup.object({
      statusId: Yup.string().required("Obrigatório"),
      collectionId: Yup.string().required("Obrigatório"),
      brandId: Yup.string().required("Obrigatório"),
      year: Yup.string().required("Obrigatório"),
    }),

    onSubmit: async (data) =>
      type === "update" ? await handleSubmitUpdate(data) : () => {},
  });

  useEffect(() => {
    (async () => {
      const headerDatasheetDB = await api.get<HeaderDatasheetProps>(
        `/headerDatasheet/show/${data.id}`
      );
      setContentPage(headerDatasheetDB.data);

      const statusDB = await api.get<StatusProps[]>(`/headerDatasheet/status`);
      setStatusDatasheet(statusDB.data);
      const brandDB = await api.get<IBrand[]>("/populate/brand", {
        params: { active: 1, licensed: 1 },
      });
      setBrands(brandDB.data);

      const descriptionDB = await api.get<IDescription[]>(
        "/populate/forecast/description",
        {
          params: { active: 1 },
        }
      );
      setCollections(descriptionDB.data);

      let dateYears = [];
      const year = new Date().getFullYear();

      for (let index = 0; index < 5; index++) {
        dateYears.push({ id: index + 1, year: String(year + index) });
      }
      setYears(dateYears);
    })();
  }, [data.id]);

  useEffect(() => {
    if (contentPage?.status?.id)
      formik.setFieldValue("statusId", contentPage.status.id);
    if (contentPage?.brand?.id)
      formik.setFieldValue("brandId", contentPage.brand.id);
    if (contentPage?.collectionId)
      formik.setFieldValue("collectionId", contentPage.collectionId);
    if (contentPage?.markUpStore)
      formik.setFieldValue("markUpStore", contentPage.markUpStore);
    if (contentPage?.year) formik.setFieldValue("year", contentPage.year);

    if (contentPage.id)
      setTimeout(() => {
        setLoadingPage(false);
      }, 400);
    // eslint-disable-next-line
  }, [contentPage]);

  async function handleSubmitUpdate(props: any) {
    setLoading(true);
    try {
      const updateDatasheet = {
        statusId: +props.statusId,
        brandId: +props.brandId,
        collectionId: +props.collectionId,
        year: +props.year,
        markUpStore: +props.markUpStore,
      };

      await api.put(`/headerDatasheet/${data.id}`, updateDatasheet);

      setLoading(false);
      onRequestClose();
      onRequestUpdate();

      addToast("Coleção Ficha técnica atualizada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setLoading(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data: any;

    if (name === "markUpStore" && value.replace(/\D/g, "").length <= 4) {
      data = value.replace(/[!@#$%&,*{}[^\]A-Za-z]/g, "");
      formik.setFieldValue(name, data);
    }
  }

  return (
    <>
      <Container>
        <Form
          onSubmit={
            loading
              ? (e) => {
                  e.preventDefault();
                }
              : formik.handleSubmit
          }
        >
          <ContainerForm>
            <div className="containerForm">
              {!loadingPage ? (
                <>
                  <h3>Situação</h3>
                  <div className="field">
                    <select {...formik.getFieldProps("statusId")}>
                      {statusDatasheet.map((status, index) => (
                        <option key={index} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                    </select>

                    {formik.touched.statusId && formik.errors.statusId ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formik.errors.statusId} </span>
                      </Error>
                    ) : null}
                  </div>

                  <h3>Informações</h3>
                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="collectionId">Coleção</label>
                      <select
                        {...formik.getFieldProps("collectionId")}
                        name="collectionId"
                      >
                        {collections.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </select>
                      {formik.touched.collectionId &&
                      formik.errors.collectionId ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formik.errors.collectionId} </span>
                        </Error>
                      ) : null}
                    </div>
                    <div className="field">
                      <label htmlFor="year">Ano</label>
                      <select {...formik.getFieldProps("year")} name="year">
                        {years.map((item) => (
                          <option key={item.id} value={item.year}>
                            {item.year}
                          </option>
                        ))}
                      </select>
                      {formik.touched.year && formik.errors.year ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formik.errors.year} </span>
                        </Error>
                      ) : null}
                    </div>
                  </div>

                  <div className="field-group">
                    <Input
                      label="Markup Alpar"
                      name="markUpStore"
                      value={formik.values.markUpStore}
                      onChange={handleInputChange}
                      onBlur={formik.handleBlur("markUpStore")}
                      error={
                        formik.touched.markUpStore && formik.errors.markUpStore
                          ? formik.errors.markUpStore
                          : undefined
                      }
                    />
                    <div className="field">
                      <label htmlFor="lastname">Marca</label>
                      <select {...formik.getFieldProps("brandId")}>
                        {brands.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      {formik.touched.brandId && formik.errors.brandId ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formik.errors.brandId} </span>
                        </Error>
                      ) : null}
                    </div>
                  </div>
                  {/* 
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="reference">Refêrencia</label>
                        <input
                          type="text"
                          {...formik.getFieldProps("reference")}
                        />

                        {formik.touched.reference && formik.errors.reference ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.reference} </span>
                          </Error>
                        ) : null}
                      </div>
                      <div className="field">
                        <label htmlFor="alternative_code">
                          Cód Alternativo
                        </label>
                        <input
                          type="text"
                          {...formik.getFieldProps("alternative_code")}
                        />

                        {formik.touched.alternative_code &&
                        formik.errors.alternative_code ? (
                          <Error>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.alternative_code} </span>
                          </Error>
                        ) : null}
                      </div>
                      <div className="field">
                  <label htmlFor="bar_code">EAN</label>
                  <input type="text" {...formik.getFieldProps("bar_code")} />

                  {formik.touched.bar_code && formik.errors.bar_code ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.bar_code} </span>
                    </Error>
                  ) : null}
                </div>
                    </div> */}
                </>
              ) : (
                <ScreenLoading>
                  <Loading
                    colorLoading="#333"
                    size={40}
                    borderColor={"#888"}
                    borderSize={5}
                  />
                </ScreenLoading>
              )}
            </div>

            <div className="containerFormButton">
              <ButtunSubmit style={{ margin: 0 }} type="submit">
                {loading ? (
                  <div>
                    <Loading borderSize={2} size={20} />
                  </div>
                ) : type === "create" ? (
                  "Criar Ficha Técnica"
                ) : (
                  "Editar Coleção Ficha Técnica"
                )}
              </ButtunSubmit>
            </div>
          </ContainerForm>
        </Form>
      </Container>
    </>
  );
};
