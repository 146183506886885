import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useFormik } from "formik";
import * as Yup from "yup";

import fileSize from "filesize";
import moment from "moment";
import { FiAlertCircle } from "react-icons/fi";
import { IoMdTrash } from "react-icons/io";
import iconVideo from "../../../assets/video.png";
import { Accordion } from "../../../components/Accordion";
import { ButtonCheck } from "../../../components/Form/ButtonCheck";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import Loading from "../../../components/LoadingDot";
import Modal from "../../../components/ModalLib";
import ModalVisibleImage from "../../../components/ModalVisibleImage";
import Dropzone from "../../../components/Upload/DropzoneArr";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Client } from "../../../hook/queries/useClientsEnchteRS";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import generateHash from "../../../utils/generateHash";
import { setFormikValues } from "../../../utils/setFormikValues";
import { ContainerDropZone, ContainerPreviews } from "../../create/styles";
import {
  ButtunSubmit as ButtonSubmit,
  Error as ContainerError,
  ContainerForm,
  Form,
} from "../styles";

interface EnchestesRs {
  reopening: undefined | boolean;
  phone: undefined | string;
  email: undefined | string;
  opening_forecast: undefined | string;
  evidence_contact: File[];
  evidence_store: File[];
  invoices: Invoices[];
}

interface EnchestesRsDb {
  id: number;
  email: string | null;
  phone: string | null;
  reopening: boolean | null;
  openingForecast: Date | null;
  brand: {
    name: string;
  };
  seller: {
    cod: number;
    abbreviation: string;
  };
  invoces_period: {
    id: number;
    amount: number;
    date: Date;
    period: string;
    invoice: boolean;
  }[];
}

type Invoices = {
  amount: number;
  amountFormat: string;
  period: string;
  date: Date;
  dateFormat: string;
  invoice?: boolean;
};

type FileProps = {
  id: string;
  name: string;
  size: number;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype: string;
};

type ResponseFiles = {
  evidence_store: FileProps[];
  evidence_contact: FileProps[];
};

interface EnchestesRsFormProps {
  client: Client;
  enchestesRs?: EnchestesRsDb;

  brandName?: string;

  setLoading: (a: boolean) => void;
}

export function EnchestesRsForm({
  client,
  enchestesRs,
  brandName,
  setLoading,
}: EnchestesRsFormProps) {
  const { addToast } = useToasts();
  const history = useHistory();

  const [imagens, setImagens] = useState<ResponseFiles>();

  const [loadingForm, setLoadingForm] = useState(false);

  const [modalVisible, setModalVisible] = useState<
    undefined | "evidence_store" | "evidence_contact"
  >();
  const [modalFileOpen, setModalFileOpen] = useState<number>();

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      reopening: undefined,
      phone: undefined,
      email: undefined,
      opening_forecast: undefined,
      evidence_contact: [] as File[],
      evidence_store: [] as File[],
      invoices: [] as Invoices[],
    },

    validationSchema: Yup.object({
      reopening: Yup.bool().required("É obrigatório"),
      phone: Yup.string().required("É obrigatório"),
      email: Yup.string()
        .email("informe um e-mail válido")
        .required("É obrigatório"),
      opening_forecast: Yup.date().when("reopening", {
        is: true,
        then: Yup.date().required("É obrigatório"),
      }),
      evidence_contact: Yup.array().test(
        "test-evidence_contact",
        "É obrigatório",
        function (value) {
          let error = true;
          if ((value?.length ?? 0) <= 0) error = false;

          return error;
        }
      ),
      // evidence_store: Yup.array().test(
      //   "test-evidence_store",
      //   "É obrigatório",
      //   function (value) {
      //     if ((value?.length ?? 0) <= 0 && formik.values.reopening === false)
      //       return false;

      //     return true;
      //   }
      // ),
      invoices: Yup.array().test(
        "test-evidence_store",
        "É obrigatório",
        function (row) {
          const value = row as Invoices[];

          if (
            value.filter((item) => item.invoice === undefined).length > 0 &&
            formik.values.reopening === false
          )
            return false;

          return true;
        }
      ),
    }),
    //@ts-ignore
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    (async () => {
      if (client) {
        if (!enchestesRs) {
          await getInvoices();

          setLoading(false);
        } else {
          await getImages();

          setFormikValues(
            {
              ...enchestesRs,
              opening_forecast: enchestesRs.openingForecast
                ? moment(enchestesRs.openingForecast).format("yyyy-MM-DD")
                : undefined,
            },
            formik,
            setLoading
          );

          const normalized = enchestesRs.invoces_period?.map((invoice) => ({
            ...invoice,
            dateFormat: new Date(invoice.date).toLocaleString("pt-br", {
              month: "long",
              year: "numeric",
            }),
            amountFormat: Number(invoice.amount ?? 0).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            }),
          }));

          formik.setFieldValue("invoices", normalized);
        }
      } else {
        setLoading(true);
      }
    })();

    // eslint-disable-next-line
  }, [client]);

  useEffect(() => {
    if (loadingForm) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [loadingForm]);

  async function getInvoices() {
    if (client.id) {
      const response = await api.get<{ invoices: Invoices[] }>(
        `/clientPJ/get-monthly-invoices/${client.id}`
      );
      const normalized = response?.data?.invoices?.map((invoice) => ({
        ...invoice,
        dateFormat: new Date(invoice.date).toLocaleString("pt-br", {
          month: "long",
          year: "numeric",
        }),
        amountFormat: Number(invoice.amount ?? 0).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
      }));

      formik.setFieldValue("invoices", normalized);
    }
  }
  async function getImages() {
    if (!!enchestesRs?.id) {
      const response = await api.get<ResponseFiles>(
        `/enchentes-rs/file/${enchestesRs.id}`
      );
      setImagens(response.data);
    }
  }

  async function handleSubmit(updates: EnchestesRs) {
    try {
      await api.post<{ created: EnchestesRsDb }>(
        `/enchentes-rs/${client.cod}`,
        {
          ...updates,
          invoices: updates.reopening === false ? updates.invoices : undefined,
        }
      );

      setLoadingForm(true);

      if (updates.evidence_contact.length > 0) {
        const formDataEvidenceContact = new FormData();
        updates.evidence_contact.forEach((element) => {
          formDataEvidenceContact.append("file", element);
        });
        await api.post(
          `/enchentes-rs/file/evidencia-contato/${client.cod}`,
          formDataEvidenceContact
        );
      }

      if (updates.evidence_store.length > 0) {
        const formDataEvidenceStore = new FormData();
        updates.evidence_store.forEach((element) => {
          formDataEvidenceStore.append("file", element);
        });
        await api.post(
          `/enchentes-rs/file/evidencia-loja/${client.cod}`,
          formDataEvidenceStore
        );
      }

      queryClient.invalidateQueries("ClientsEnchenteRS");

      addToast("Dados salvos com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });

      history.push("/representante/enchestes-rs");
    } catch (err) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "As alterações feitas podem não ser salvas.";
  };

  return (
    <>
      <PanelAndDetailAndCreateHeader
        title={
          enchestesRs
            ? `${brandName ?? ""} - ${enchestesRs.seller.abbreviation} (${
                enchestesRs.seller.cod
              })`
            : "Formulário"
        }
      />

      <ContainerForm>
        <Form onSubmit={formik.handleSubmit}>
          <h3>Atualização de contatos do cliente</h3>

          <GroupInput>
            <Input
              label="Email"
              {...formik.getFieldProps("email")}
              readOnly={!!enchestesRs?.id}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""
              }
            />

            <Input
              label="Telefone"
              readOnly={!!enchestesRs?.id}
              {...formik.getFieldProps("phone")}
              error={
                formik.touched.phone && formik.errors.phone
                  ? formik.errors.phone
                  : ""
              }
            />
          </GroupInput>

          <h3>Gestão de carteira RS</h3>

          <ButtonCheck
            name="reopening"
            label="Reabrirá a loja?"
            value={formik.values.reopening}
            error={
              formik.touched.reopening && formik.errors.reopening
                ? formik.errors.reopening
                : ""
            }
            setValue={(value) => {
              if ((value === true || value === false) && !enchestesRs?.id) {
                formik.setFieldValue("reopening", value);
                setTimeout(() => {
                  formik.validateField("reopening");
                }, 1);
              }
            }}
            options={[
              {
                value: true,
                field: "Sim",
              },
              {
                value: false,
                field: "Não",
              },
            ]}
          />

          {formik.values.reopening === true && (
            <Input
              label="Previsão de abertura"
              type="date"
              readOnly={!!enchestesRs?.id}
              {...formik.getFieldProps("opening_forecast")}
              error={
                formik.touched.opening_forecast &&
                formik.errors.opening_forecast
                  ? formik.errors.opening_forecast
                  : ""
              }
            />
          )}
          {!enchestesRs?.id && (
            <div className="field">
              <div className="containerLabelButton">
                <h3>Confirmação por parte do cliente</h3>
              </div>

              <ContainerDropZone>
                <Dropzone
                  accept={["image/*"]}
                  onFileUploaded={(files) => {
                    formik.setFieldValue("evidence_contact", [
                      ...formik.values.evidence_contact,
                      ...files,
                    ]);

                    setTimeout(() => {
                      formik.validateField("evidence_contact");
                    }, 1);
                  }}
                />
                <ContainerPreviews
                  style={{
                    overflowY: "auto",
                    maxHeight: "40vh",
                  }}
                >
                  {formik.values.evidence_contact.map((file) => (
                    <li key={`${generateHash()}-${file.name}`}>
                      <img alt={file.name} src={URL.createObjectURL(file)} />
                      <div className="fileInfo">
                        <div>
                          <strong>{file.name}</strong>
                          <span>{fileSize(file.size)}</span>
                        </div>

                        <button
                          type="button"
                          onClick={() => {
                            const deleted =
                              formik.values.evidence_contact.filter(
                                (f) => f.name !== file.name
                              );
                            formik.setFieldValue("evidence_contact", deleted);
                          }}
                        >
                          <IoMdTrash size={30} />
                        </button>
                      </div>
                    </li>
                  ))}
                </ContainerPreviews>
              </ContainerDropZone>

              {formik.touched.evidence_contact &&
              formik.errors.evidence_contact ? (
                <ContainerError>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.evidence_contact} </span>
                </ContainerError>
              ) : (
                ""
              )}
            </div>
          )}

          {formik.values.reopening === false && (
            <>
              <h3>Programação</h3>

              {formik.values.invoices?.map((invoice) => (
                <Accordion
                  key={invoice.period}
                  isOpenDefault={false}
                  title={`${invoice.dateFormat} (${invoice.amountFormat})`}
                  headerStyles={{
                    marginTop: "1rem",
                    background: "#f0f0f0",
                    borderRadius: 6,
                    border: !!enchestesRs?.id
                      ? invoice.invoice === true
                        ? "3px solid #21b543"
                        : invoice.invoice === false
                        ? "3px solid #d21e26"
                        : "3px solid #e9c912"
                      : formik.touched.invoices &&
                        formik.errors.invoices &&
                        invoice?.invoice === undefined
                      ? "3px solid #d21e26"
                      : "",
                  }}
                >
                  <div
                    style={{ background: "#f0f0f0", padding: "1rem 1rem 0" }}
                  >
                    <ButtonCheck
                      name="reopening"
                      label="FATURAR"
                      value={invoice.invoice}
                      setValue={(value) => {
                        if (
                          (value === true || value === false) &&
                          !enchestesRs?.id
                        ) {
                          const change = formik.values.invoices.map((item) =>
                            item.period === invoice.period
                              ? {
                                  ...item,
                                  invoice: value,
                                }
                              : item
                          );
                          formik.setFieldValue("invoices", change);

                          setTimeout(() => {
                            formik.validateField("invoices");
                          }, 1);
                        }
                      }}
                      options={[
                        {
                          value: true,
                          field: "Sim",
                        },
                        {
                          value: false,
                          field: "Não",
                        },
                      ]}
                    />
                  </div>
                </Accordion>
              ))}

              {formik.touched.invoices && formik.errors.invoices ? (
                <ContainerError>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.invoices} </span>
                </ContainerError>
              ) : (
                ""
              )}
            </>
          )}

          {formik.values.reopening === false && !enchestesRs?.id && (
            <div className="field" style={{ marginTop: "1rem" }}>
              <div className="containerLabelButton">
                <h3>Fotos da loja pós enchente</h3>
              </div>

              <ContainerDropZone>
                <Dropzone
                  accept={["image/*", "video/*"]}
                  onFileUploaded={(files) => {
                    formik.setFieldValue("evidence_store", [
                      ...formik.values.evidence_store,
                      ...files,
                    ]);

                    setTimeout(() => {
                      formik.validateField("evidence_store");
                    }, 1);
                  }}
                />
                <ContainerPreviews
                  style={{
                    overflowY: "auto",
                    maxHeight: "40vh",
                  }}
                >
                  {formik.values.evidence_store.map((file) => (
                    <li key={`${generateHash()}-${file.name}`}>
                      <img
                        alt={file.name}
                        src={
                          file.type.split("/")[0] === "video"
                            ? iconVideo
                            : URL.createObjectURL(file)
                        }
                      />
                      <div className="fileInfo">
                        <div>
                          <strong>{file.name}</strong>
                          <span>{fileSize(file.size)}</span>
                        </div>

                        <button
                          type="button"
                          onClick={() => {
                            const deleted = formik.values.evidence_store.filter(
                              (f) => f.name !== file.name
                            );
                            formik.setFieldValue("evidence_store", deleted);
                          }}
                        >
                          <IoMdTrash size={30} />
                        </button>
                      </div>
                    </li>
                  ))}
                </ContainerPreviews>
              </ContainerDropZone>

              {formik.touched.evidence_store && formik.errors.evidence_store ? (
                <ContainerError>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formik.errors.evidence_store} </span>
                </ContainerError>
              ) : (
                ""
              )}
            </div>
          )}

          {!enchestesRs?.id && (
            <ButtonSubmit type="submit"> Salvar </ButtonSubmit>
          )}
        </Form>
      </ContainerForm>

      {(imagens?.evidence_contact.length ?? 0) > 0 && (
        <>
          <PanelAndDetailAndCreateHeader
            title={"Confirmação por parte do cliente"}
          />
          <ContainerForm>
            <ContainerPreviews
              style={{
                overflowY: "auto",
                maxHeight: "40vh",
              }}
            >
              {imagens?.evidence_contact.map((file, index) => (
                <li
                  key={file.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setModalVisible("evidence_contact");
                    setModalFileOpen(index);
                  }}
                >
                  <img
                    alt={file.name}
                    src={
                      file.mimetype.split("/")[0] === "video"
                        ? iconVideo
                        : file.url
                    }
                  />
                  <div className="fileInfo">
                    <div>
                      <strong>{file.name}</strong>
                      <span>{fileSize(Number(file.size))}</span>
                    </div>
                  </div>
                </li>
              ))}
            </ContainerPreviews>
          </ContainerForm>
        </>
      )}

      {(imagens?.evidence_store.length ?? 0) > 0 && (
        <>
          <PanelAndDetailAndCreateHeader title={"Fotos da loja pós enchente"} />
          <ContainerForm>
            <ContainerPreviews
              style={{
                overflowY: "auto",
                maxHeight: "40vh",
              }}
            >
              {imagens?.evidence_store.map((file, index) => (
                <li
                  key={file.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setModalVisible("evidence_store");
                    setModalFileOpen(index);
                  }}
                >
                  <img
                    alt={file.name}
                    src={
                      file.mimetype.split("/")[0] === "video"
                        ? iconVideo
                        : file.url
                    }
                  />
                  <div className="fileInfo">
                    <div>
                      <strong>{file.name}</strong>
                      <span>{fileSize(file.size)}</span>
                    </div>
                  </div>
                </li>
              ))}
            </ContainerPreviews>
          </ContainerForm>
        </>
      )}

      <ModalVisibleImage
        modalVisible={!!modalVisible}
        setModalVisible={() => {
          setModalFileOpen(undefined);
          // setModalVisible(undefined);
        }}
        images={
          (modalVisible === "evidence_contact"
            ? imagens?.evidence_contact
            : imagens?.evidence_store) ?? []
        }
        indexImg={modalFileOpen}
      />

      <Modal
        modalVisible={loadingForm}
        setModalVisible={() => {}}
        headerOff
        height={200}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 style={{ marginBottom: "1rem" }}>Enviando dados...</h3>

          <Loading />
        </div>
      </Modal>
    </>
  );
}
