import React from "react";
import { IoMdClose } from "react-icons/io";
import ModalReact from "react-modal";
import Switch from "react-switch";
import { HeaderModal, ModalContainer } from "./styles";

interface IPropsHeader {
  setModalVisible: (value: boolean) => void;
  modalVisible: boolean;

  setIsDisabled?: (value: React.SetStateAction<boolean>) => void;
  isDisabled?: boolean;

  isShouldCloseOnEscAndClickOffline?: boolean;

  title?: string;
  headerOff?: boolean;
  scrollOff?: boolean;
  height?: number | string;
  width?: number | string;
  maxHeight?: number | string;
  maxWidth?: number | string;
}

const Modal: React.FC<IPropsHeader> = ({
  children,
  setModalVisible,
  modalVisible,
  title,
  headerOff,
  scrollOff,
  isShouldCloseOnEscAndClickOffline = false,
  height,
  width,
  maxHeight,
  maxWidth,

  isDisabled,
  setIsDisabled,
}) => {
  function closeModal() {
    setModalVisible(false);
  }

  return (
    <ModalReact
      style={{
        overlay: {
          zIndex: 999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0,0,0,0.5)",
          animation: "fadeOpacity 0.2s",
        },
        content: {
          inset: "0.5rem",
          padding: 0,
          borderRadius: 8,
          margin: "auto",
          height: height ? height : undefined,
          width: width ? width : undefined,
          maxHeight: maxHeight ? maxHeight : 800,
          maxWidth: maxWidth ? maxWidth : 563,
          animation: "fadeScale 0.2s",
        },
      }}
      isOpen={modalVisible}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={!isShouldCloseOnEscAndClickOffline}
      shouldCloseOnEsc={!isShouldCloseOnEscAndClickOffline}
    >
      <ModalContainer
        style={{
          display: "grid",
          gridTemplateAreas: !headerOff ? undefined : "'BODY' 'BODY'",
          gridTemplateColumns: !headerOff ? undefined : "auto",
          gridTemplateRows: !headerOff ? undefined : "auto",
        }}
      >
        {!headerOff && (
          <HeaderModal>
            <div>{title}</div>
            <div>
              {setIsDisabled && isDisabled !== undefined && (
                <div style={{ marginRight: 10 }}>
                  <span
                    style={{
                      fontWeight: 300,
                      fontSize: "1rem",
                      marginRight: 5,
                    }}
                  >
                    Manter aberto
                  </span>
                  <Switch
                    onChange={(e) => setIsDisabled(e)}
                    checked={isDisabled}
                    onColor="#d0575d"
                    onHandleColor="#d21e26"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                  />
                </div>
              )}

              <div className="closeModal" onClick={closeModal}>
                <IoMdClose size={28} />
              </div>
            </div>
          </HeaderModal>
        )}
        <div
          className="body"
          style={{ overflow: scrollOff ? "hidden" : undefined }}
        >
          {children}
        </div>
      </ModalContainer>
    </ModalReact>
  );
};

export default Modal;
