import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 55px;
  width: 100%;
  padding: 12px 12px;
  margin-bottom: 20px;

  background-color: #fff;

  @media (max-width: 500px) {
    height: 85px;
    flex-direction: column;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
