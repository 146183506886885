import React, { ReactNode, useState } from "react";

import { IoIosArrowDown } from "react-icons/io";
import { Body, Container, Header } from "./styles";

interface AccordionProps {
  title: ReactNode;

  children: ReactNode;

  isOpenDefault?: boolean;

  size?: "lg" | "sm";
  headerStyles?: React.CSSProperties;
  bodyStyles?: React.CSSProperties;
}

export function Accordion({
  title,
  size = "lg",
  isOpenDefault = true,
  children,
  headerStyles,
  bodyStyles,
}: AccordionProps) {
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  return (
    <Container>
      <Header
        size={size}
        isOpen={isOpen}
        style={headerStyles}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="accordion-title">{title}</span>

        <IoIosArrowDown
          className="accordion-arrow"
          color="#333"
          fontSize="1.5rem"
        />
      </Header>

      <Body isOpen={isOpen} style={bodyStyles}>
        {children}
      </Body>
    </Container>
  );
}
