import * as ExcelJS from "exceljs";
import { useFormik } from "formik";
import React from "react";
import { FaFileDownload, FaRegFile } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useToasts } from "react-toast-notifications";
import * as XLSX from "xlsx";
import * as Yup from "yup";

import { ClientForecastProps } from "../../..";
import {
  Container,
  ContainerForm,
} from "../../../../../../components/ModalModificationHeaderDatasheet/styled";
import Dropzone from "../../../../../../components/Upload/DropzoneArr";
import { ButtunSubmit, Form } from "../../../../../create/styles";
import { ContainerDropZone } from "../../../../styles";

interface ModalImportForecastClientProps {
  onChange?: (data: ClientForecastProps[]) => void;
  histories: {
    historic1: string;
    historic2: string;
    historic3: string;
    historic4: string;
  };
}

export const ModalImportForecastClient: React.FC<
  ModalImportForecastClientProps
> = ({ onChange, histories }) => {
  const { addToast } = useToasts();

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      file: undefined as any,
    },
    validationSchema: Yup.object({}),

    onSubmit: async (data) => await handleSubmitUpdate(data),
  });

  async function handleSubmitUpdate({ file }: { file: File }) {
    if (!file) {
      return addToast("É necessário importar um arquivo.", {
        appearance: "warning",
        autoDismiss: true,
      });
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      //@ts-ignore
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      let normalized = [];

      for (const row of jsonData as any[]) {
        normalized.push({
          cod: row["Cod.Cliente"],
          historic1: row[histories.historic1],
          historic2: row[histories.historic2],
          historic3: row[histories.historic3],
          historic4: row[histories.historic4],
        });
      }

      if (onChange) onChange(normalized as ClientForecastProps[]);
    };
    reader.readAsArrayBuffer(file);
  }

  async function handleExportExample() {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    sheet.getRow(1).height = 25;

    sheet.columns = [
      {
        header: "Cod.Cliente",
        key: "clientCod",
        width: 20,
      },
      {
        header: histories.historic1,
        key: "historic1",
        width: 20,
      },
      {
        header: histories.historic2,
        key: "historic2",
        width: 20,
      },
      {
        header: histories.historic3,
        key: "historic3",
        width: 20,
      },
      {
        header: histories.historic4,
        key: "historic4",
        width: 20,
      },
    ];

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `importação-clientes-forecast.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  }

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <ContainerForm>
          <div className="containerForm">
            <div
              style={{
                marginBottom: "1rem",
                display: "flex",
                columnGap: "1rem",
                alignItems: "center",
              }}
            >
              <h3>Importar clientes</h3>
              <button
                onClick={handleExportExample}
                type="button"
                style={{
                  background: "#f0f0f0",
                  display: "flex",
                  columnGap: "0.5rem",
                  borderRadius: 6,
                  padding: 6,
                }}
              >
                <FaFileDownload fontSize="1rem" color="#333" />
                <span>Arquivo de exemplo</span>
              </button>
            </div>

            {!formik.values.file && (
              <ContainerDropZone>
                <Dropzone
                  onFileUploaded={(e) => {
                    formik.setFieldValue("file", e[0]);
                  }}
                />
              </ContainerDropZone>
            )}

            {formik.values.file && (
              <div
                style={{
                  background: "#f0f0f0",
                  width: "100%",
                  padding: "0.5rem 1rem",
                  display: "flex",
                  borderRadius: "6px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FaRegFile />

                <span>{formik.values.file?.name}</span>

                <button
                  type="button"
                  onClick={() => {
                    formik.setFieldValue("file", undefined);
                  }}
                >
                  <IoClose fontSize="2rem" />
                </button>
              </div>
            )}
          </div>

          <div className="containerFormButton">
            <ButtunSubmit
              style={{ margin: 0 }}
              type="button"
              onClick={formik.submitForm}
            >
              Importar
            </ButtunSubmit>
          </div>
        </ContainerForm>
      </Form>
    </Container>
  );
};
