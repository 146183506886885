import * as React from "react";

export function CheckProgress(props: any) {
  console.log(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 512.000000 512.000000"
      fill={props.color}
    >
      <path
        d="M2320 5109c-146-15-259-36-293-57-74-42-119-141-103-225 8-45 50-108 89-134 51-35 103-42 198-26 127 20 436 28 570 14 645-68 1213-419 1579-976 74-113 172-319 219-460 77-231 111-440 111-687 0-573-218-1098-624-1504-351-351-795-564-1285-615-134-14-443-6-570 14-130 21-200-3-255-87-49-76-47-167 7-238 43-56 80-76 173-92 225-40 551-45 779-12 463 67 912 263 1260 551 514 424 831 996 927 1670 17 118 17 512 0 630-70 491-250 916-544 1284-142 179-376 395-560 519-325 219-690 360-1083 418-124 18-468 26-595 13zM1338 4799c-81-19-340-199-503-350-175-161-372-397-430-513-55-110-13-230 99-288 31-16 53-19 102-16 83 5 129 37 194 133 152 225 363 429 613 592 143 94 174 130 184 211 12 102-52 198-150 227-56 17-57 17-109 4z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
      <path
        d="M3538 3394c-26-11-218-208-704-722-368-389-671-707-674-707s-128 129-278 287c-257 271-276 288-325 303-105 31-204-11-252-109-31-64-32-124-2-186 10-19 158-183 330-365 285-302 318-333 380-364 61-31 78-35 147-35s86 4 147 35c64 32 106 74 784 791 394 417 721 770 728 784 65 142-38 305-191 303-29 0-69-7-90-15zM222 3300c-56-13-110-58-136-114-44-91-86-400-86-626s42-535 86-626c64-134 240-161 341-51 61 66 70 118 44 249-31 158-41 258-41 428s9 268 42 432c18 95 19 113 7 155-18 67-78 129-142 148-55 16-66 17-115 5zM505 1473c-113-59-155-180-100-289 58-116 255-352 430-513 172-159 423-331 510-351 93-21 187 27 231 117 23 45 26 62 21 109-9 87-38 121-184 217-220 144-425 334-558 517-33 45-73 101-90 123-42 56-104 87-174 87-31-1-69-8-86-17z"
        transform="matrix(.1 0 0 -.1 0 512)"
      />
    </svg>
  );
}
