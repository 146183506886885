import React, { useEffect, useRef } from "react";

import useOutsideAlerter from "../../hook/outsideAlerter";
import { Container, HeaderModal, ModalContainer } from "./styles";

import { IoMdClose } from "react-icons/io";

interface IPropsHeader {
  setModalVisible: (value: React.SetStateAction<boolean>) => void;
  modalVisible: boolean;
  title?: string;
  headerOff?: boolean;
  scrollOff?: boolean;
  maxHeight?: number | string;
  maxWidth?: number | string;
}

const Modal: React.FC<IPropsHeader> = ({
  children,
  setModalVisible,
  modalVisible,
  title,
  headerOff,
  scrollOff,
  maxHeight,
  maxWidth,
}) => {
  const outSideRef = useRef(null);

  function closeModal() {
    setModalVisible(false);
  }

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        closeModal();
      }
    });
  });

  useOutsideAlerter(outSideRef, () => {
    if (modalVisible) setModalVisible(false);
  });

  return (
    <Container>
      <ModalContainer
        style={{
          display: "grid",
          gridTemplateAreas: !headerOff ? undefined : "'BODY' 'BODY'",
          gridTemplateColumns: !headerOff ? undefined : "auto",
          gridTemplateRows: !headerOff ? undefined : "auto",
          maxHeight: maxHeight ? maxHeight : undefined,
          maxWidth: maxWidth ? maxWidth : undefined,
        }}
        ref={outSideRef}
      >
        {!headerOff && (
          <HeaderModal>
            <div>{title}</div>

            <div className="closeModal" onClick={closeModal}>
              <IoMdClose size={28} />
            </div>
          </HeaderModal>
        )}
        <div
          className="body"
          style={{ overflowY: scrollOff ? "hidden" : undefined }}
        >
          {children}
        </div>
      </ModalContainer>
    </Container>
  );
};

export default Modal;
