import React, { useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import api from "../../../service/api";

import { IPermissionUser, OptionsFilter } from "../../../@types";

import { MdAdd } from "react-icons/md";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import useOutsideAlerter from "../../../hook/outsideAlerter";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

interface IForecast {
  id: number;
  description: string;
  descriptionYear: string;
  totais: number;
  totaisCampaign: number;
  status: {
    cod: number;
    name: string;
    color: string;
    finisher: boolean;

    color_seller: string;
    name_seller: string;
  };
  finished: boolean;
  finished_at: Date | null;
  closing_at: Date;
  brandId: number;
  sellerId: number | null;
  created_at: Date;
  updated_at: Date;

  brand: {
    id: number;
    cod: number;
    name: string;
    is_active: boolean;
    created_at: Date;
    updated_at: Date;
  };

  forecast_seller: {
    forecast_data: [
      {
        id: number;
        Footwear: number | null;
        Apparel: number | null;
        Equipmen: number | null;
        finished: boolean;
        finished_at: Date | null;
        forecastId: number;
        created_at: Date;
        updated_at: Date;
        sellerId: number;
        client_pjId: number;

        client_pj?: {
          cod?: Number;
          cnpj?: String;
          company_name?: String;
          trade_name?: String;
          adresses?: {
            city?: String;
            uf?: String;
          };
        };
      }
    ];
  };
}

interface IHeaderProps {
  admin: boolean;
  seller: boolean;

  is_manager: boolean;
  is_supervisor: boolean;

  permission: IPermissionUser;
}

interface IFilter {
  status?: string;
  collection?: string;
  year?: string;
  brand?: string;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IFilterOption {
  year: OptionsFilter[];
  collection: OptionsFilter[];
  status: OptionsFilter[];
  brand: OptionsFilter[];
}

interface IResponseAPI {
  contents: IForecast[];
  filters: IFilterOption;
  total: number;
  limit: number;
  page: number;
  pages: number;
}

const PanelForecast: React.FC<IHeaderProps> = ({
  admin,
  seller,
  is_manager,
  is_supervisor,
  permission,
}) => {
  const history = useHistory();
  const query = useQueryParams();
  const outSideRef = useRef(null);

  const columns: FieldProps<IForecast>[] = [
    {
      name: "description",
      field: "COLEÇÃO",
      viewList: true,
    },
    {
      name: "descriptionYear",
      field: "ANO",
      viewList: true,
    },
    {
      name: "statusFORMAT",
      field: "SITUAÇÃO",
      viewList: true,
      render: (forecast) => {
        if (admin || is_manager || is_supervisor) {
          return (
            <span
              className="status"
              style={{ backgroundColor: forecast?.status?.color }}
            >
              {forecast?.status.name}
            </span>
          );
        }

        return (
          <span
            className="status"
            style={{
              backgroundColor: forecast?.status?.color_seller,
            }}
          >
            {forecast?.status?.name_seller}
          </span>
        );
      },
    },
    {
      name: "brandName",
      field: "MARCA",
      viewList: true,
    },
    {
      name: "totaisFORMAT",
      field: "TOTAL",
      viewList: true,
    },
    {
      name: "created_atFORMAT",
      field: "DATA CRIAÇÃO",
      viewList: true,
    },
    {
      name: "finished_atFORMAT",
      field: "DATA FINALIZAÇÃO",
      viewList: true,
    },

    {
      name: "status",
      field: "SITUAÇÃO",
      viewList: false,
    },
    {
      name: "brand",
      field: "MARCA",
      viewList: false,
    },
    {
      name: "collection",
      field: "COLEÇÃO",
      viewList: false,
    },
    {
      name: "year",
      field: "ANO",
      viewList: false,
    },
  ];

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });
  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const [filtersOptions, setFiltersOptions] = useState<IFilterOption>({
    brand: [],
    collection: [],
    status: [],
    year: [],
  });

  const [contentData, setContentData] = useState<IForecast[]>([]);

  const [loading, setLoading] = useState({
    panel: true,
  });

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  const formikFilter = useFormik({
    initialValues: {
      status: filters.status,
      collection: filters.collection,
      year: filters.year,
      brand: filters.brand,
      creationPeriod: filters.creationPeriod,
      presetDate: filters.presetDate,
      specificDate: filters.specificDate,
      startDate: filters.startDate,
      endDate: filters.endDate,
    },
    validationSchema: Yup.object({
      presetDate: Yup.string().when("creationPeriod", {
        is: "1",
        then: Yup.string().required("Data predefinida é obrigatório"),
      }),

      specificDate: Yup.string().when("creationPeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      startDate: Yup.string().when("creationPeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      endDate: Yup.string().when("creationPeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),
    }),

    onSubmit: (data) => {
      handleFilter({
        ...data,
      });
    },
  });

  function handleFilter(filter: IFilter) {
    setFilters(filter);
    setRequestPagination({
      ...requestPagination,
      pageRequest: 0,
    });
    setFilterView(false);
  }

  function selectDescription(st: any, title: string) {
    let data = String(st);
    const arrTitle = ["status", "collection", "brand"];
    if (arrTitle.includes(title)) {
      switch (title) {
        case "status":
          data =
            filtersOptions.status.find((f) => Number(f.id) === Number(st))
              ?.name ?? "-";
          break;
        case "collection":
          data =
            filtersOptions.collection.find((f) => Number(f.id) === Number(st))
              ?.name ?? "-";
          break;
        case "brand":
          data =
            filtersOptions.brand.find((f) => Number(f.id) === Number(st))
              ?.name ?? "-";
          break;
      }
    }

    return data;
  }

  function handleToNew() {
    history.push("/admin/criar/previsao");
  }

  useEffect(() => {
    setLoading({ ...loading, panel: true });
    (async () => {
      try {
        if (admin || is_manager || is_supervisor) {
          const responseAdmin = await api.get<IResponseAPI>("/forecast", {
            params: { ...filters, limitRequest: 15, pageRequest: page - 1 },
          });

          const {
            contents,
            limit,
            page: pageData,
            pages,
            total,
            filters: filtersData,
          } = responseAdmin.data;

          setContentData(contents);
          setFiltersOptions(filtersData);
          setPagination({ limit, page: pageData, pages, total });
          setLoading({ ...loading, panel: false });
        } else {
          const responseClient = await api.get<IResponseAPI>(
            `/forecast/seller/`,
            {
              params: { ...filters, ...requestPagination },
            }
          );
          const {
            contents,
            limit,
            page,
            pages,
            total,
            filters: filtersData,
          } = responseClient.data;
          setFiltersOptions(filtersData);
          setContentData(contents);
          setPagination({ limit, page, pages, total });
          setLoading({ ...loading, panel: false });
        }
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, page]);

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de Forecast" />

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={() => formikFilter.handleReset(formikFilter)}
        setViewFilter={setFilterView}
        viewFilter={filterView}
        normalizedFilters={selectDescription}
      >
        <GroupInput>
          <Select
            label="SITUAÇÃO"
            data={filtersOptions.status.map((status) => ({
              name: status.name,
              value: status.id,
            }))}
            {...formikFilter.getFieldProps("status")}
          />
          <Select
            label="MARCA"
            data={filtersOptions.brand.map((brand) => ({
              name: brand.name,
              value: brand.id,
            }))}
            {...formikFilter.getFieldProps("brand")}
          />
        </GroupInput>
        <GroupInput>
          <Select
            label="COLEÇÃO"
            data={filtersOptions.collection.map((collection) => ({
              name: collection.name,
              value: collection.id,
            }))}
            {...formikFilter.getFieldProps("collection")}
          />
          <Select
            label="ANO"
            data={filtersOptions.year.map((year) => ({
              name: year.name,
              value: year.id,
            }))}
            {...formikFilter.getFieldProps("year")}
          />
        </GroupInput>
      </PanelFilter>

      <TableHeader title="FORECAST" isFetching={loading.panel}>
        {admin && permission.forecast > 2 && (
          <button onClick={handleToNew} type="button">
            <MdAdd size={22} /> <span> NOVO FORECAST </span>
          </button>
        )}
      </TableHeader>

      <TableContent<IForecast>
        data={contentData.map((forecast) => ({
          ...forecast,
          brandName: forecast.brand.name ?? "-",
          totaisFORMAT: forecast?.totaisCampaign?.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
          created_atFORMAT: dayjs(forecast.created_at).format("DD/MM/YYYY"),
          finished_atFORMAT: forecast.finished_at
            ? dayjs(forecast.finished_at).format("DD/MM/YYYY")
            : "-",
        }))}
        onClickRow={(forecast) => {
          admin
            ? history.push(`/admin/previsao/${forecast.id}`, {
                content: forecast,
              })
            : seller
            ? history.push(`/representante/previsao/${forecast.id}`, {
                content: forecast,
              })
            : history.push("/404");
        }}
        columns={columns.filter((f) => f.viewList)}
        isLoading={loading.panel}
      />

      <Pagination
        totalCountOfRegisters={pagination.total}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={15}
      />
    </PanelLayout>
  );
};

export default PanelForecast;
