import React, { useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import { ColorAndCategoryProps } from "..";
import { DatasheetVariationProps } from "../../../pages/detail/Datasheet";
import { PopUpConfirmation } from "../../../pages/detail/styles";
import Modal from "../../ModalLib";
import { TableEditable } from "../../TableEditable";
import Loading from "../../loadings/Loading";
import FormModificationVariation from "./FormModificationVariation";

interface VariationModifyProps {
  colors: ColorAndCategoryProps[];

  alternative_code?: string;

  variations: DatasheetVariationProps[];
  setVariations: React.Dispatch<
    React.SetStateAction<DatasheetVariationProps[]>
  >;

  disabled?: boolean;
}

export const Variation: React.FC<VariationModifyProps> = ({
  colors,
  alternative_code,
  variations,
  setVariations,

  disabled = false,
}) => {
  const { addToast } = useToasts();

  const [variationEdit, setVariationEdit] = useState<DatasheetVariationProps>(
    {} as DatasheetVariationProps
  );
  const [modalVisibleCreateVariation, setModalVisibleCreateVariation] =
    useState(false);
  const [modalVisibleEditVariation, setModalVisibleEditVariation] =
    useState(false);

  const [isDisabledModalEdit, setIsDisabledModalEdit] =
    useState<boolean>(false);
  const [isDisabledModalCreate, setIsDisabledModalCreate] =
    useState<boolean>(false);

  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState<DatasheetVariationProps>();
  const [loadingModal, setLoadingModal] = useState(false);

  async function handleUpdateVariation(
    _oldData: DatasheetVariationProps,
    newData: DatasheetVariationProps
  ) {
    try {
      const variationsUpdate: DatasheetVariationProps[] = variations;
      const findOne = variationsUpdate.find((f) => f.id === newData.id);

      if (findOne) {
        findOne.primary_color_cod = newData.primary_color_cod;
        findOne.secondary_color_cod = newData.secondary_color_cod;
        findOne.reference = alternative_code
          ? alternative_code + ("0000" + newData.primary_color_cod).slice(-4)
          : "-";

        findOne.primary_color_name = colors.find(
          (q) => Number(q.cod) === Number(newData.primary_color_cod)
        )?.description;
        findOne.secondary_color_name = colors.find(
          (q) => Number(q.cod) === Number(newData.secondary_color_cod)
        )?.description;
      }

      addToast("Medidas editadas com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });

      setVariations(variationsUpdate);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleDeleteVariation(measurement: DatasheetVariationProps) {
    try {
      setLoadingModal(true);
      // addToast("Carregando alterações...", {
      //   appearance: "info",
      //   autoDismiss: true,
      // });

      const productsDelete = variations.filter(
        (item) => item.id !== measurement.id
      );
      setVariations(productsDelete);

      // if (type === "update") {
      //   await api.delete(
      //     `/headerDatasheet/datasheet/variation/${measurement.id}`
      //   );
      // }

      // addToast("Variação excluido com sucesso", {
      //   appearance: "success",
      //   autoDismiss: true,
      // });

      setLoadingModal(false);
      setConfirmationDelete(false);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <>
      <TableEditable
        header="Variações"
        disabled={disabled}
        setEditData={(props: any) => setVariationEdit(props)}
        data={variations.map((variation) => ({
          ...variation,
          primary_color: `${variation.primary_color} ${variation.primary_color_name}`,
          secondary_color:
            variation.secondary_color !== "-"
              ? `${variation.secondary_color} ${variation.secondary_color_name}`
              : "-",
          is_drop: variation.is_drop,
          is_drop_desc: variation.is_drop ? "Sim" : "Não",
        }))}
        colums={[
          {
            title: "Referência",
            field: "reference",
            inputType: "input",
          },
          {
            title: "1ª Cor Predominante",
            field: "primary_color",
            inputType: "input",
            option: colors,
            required: true,
          },
          {
            title: "2ª Cor Predominante",
            field: "secondary_color",
            inputType: "input",
            option: colors,
          },
          {
            title: "Dropado",
            field: "is_drop_desc",
            inputType: "input",
          },
        ]}
        modalVisibleEdit={modalVisibleEditVariation}
        setModalVisibleEdit={setModalVisibleEditVariation}
        isDisabledModalEdit={isDisabledModalEdit}
        setIsDisabledModalEdit={setIsDisabledModalEdit}
        modalVisibleCreate={modalVisibleCreateVariation}
        setModalVisibleCreate={setModalVisibleCreateVariation}
        isDisabledModalCreate={isDisabledModalCreate}
        setIsDisabledModalCreate={setIsDisabledModalCreate}
        maxHeightModal={395}
        updateData={(oldData, newData) =>
          handleUpdateVariation(
            oldData as DatasheetVariationProps,
            newData as DatasheetVariationProps
          )
        }
        deleteData={(data) => {
          setConfirmationDelete(true);
          setDataDelete(data as DatasheetVariationProps);
        }}
        FormCreateComponent={() => (
          <FormModificationVariation
            type="create"
            closeModal={() =>
              !isDisabledModalCreate && setModalVisibleCreateVariation(false)
            }
            setData={setVariations}
            colors={colors}
            alternativeCode={alternative_code}
          />
        )}
        FormEditComponent={() => (
          <FormModificationVariation
            type="edit"
            editData={variationEdit}
            closeModal={() =>
              !isDisabledModalEdit && setModalVisibleEditVariation(false)
            }
            setData={setVariations}
            colors={colors}
            alternativeCode={alternative_code}
          />
        )}
      />

      {confirmationDelete && dataDelete && (
        <Modal
          title="Selecione o cliente"
          modalVisible={confirmationDelete}
          setModalVisible={setConfirmationDelete}
          headerOff={true}
          maxHeight={350}
          maxWidth={650}
        >
          <PopUpConfirmation>
            <div className="headerPopUp">
              <nav>
                <FaTrashAlt size={95} opacity={0.2} />
              </nav>
              <span>Você tem certeza que deseja excluir esta variação?</span>
            </div>
            <div className="buttonsPopUp">
              <button
                type="button"
                onClick={() => setConfirmationDelete(false)}
              >
                Cancelar
              </button>
              <button
                style={{ background: "#d21e26" }}
                type="button"
                onClick={() =>
                  loadingModal
                    ? () => {}
                    : handleDeleteVariation(
                        dataDelete as DatasheetVariationProps
                      )
                }
              >
                {!loadingModal ? (
                  "Deletar"
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Loading
                      size={22}
                      borderSize={4}
                      colorLoading="rgba(255,255,255)"
                      borderColor="rgba(255,255,255, 0.3)"
                    />
                  </div>
                )}
              </button>
            </div>
          </PopUpConfirmation>
        </Modal>
      )}
    </>
  );
};
