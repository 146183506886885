import { useFormik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { Select } from "../../../components/Form/Select";
import Loading from "../../../components/Loading";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { useSellers } from "../../../hook/queries/useSeller";
import { ServiceInvoice } from "../../../hook/queries/useServiceInvoice";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";

export interface MatchParams {
  id?: string;
}

const CreateServiceInvoice: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const getSellers = useSellers(1, {}, 99999);
  const [loading, setLoading] = useState(false);

  const create = useMutation(
    async (invoice: ServiceInvoice) => {
      const response = await api.post("/service-invoice", {
        ...invoice,
        type: "outras",
      });

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("service-invoices");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      sellerCod: "",
    },
    validationSchema: Yup.object({
      sellerCod: Yup.number().required("É obrigatório"),
    }),
    onSubmit: (a) => {
      handleCreate(a as any);
    },
  });

  async function handleCreate(data: ServiceInvoice) {
    try {
      setLoading(true);

      await create.mutateAsync(data);

      addToast("Notas de serviço alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.goBack();
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <CreateAndDetailLayout isLoading={getSellers.isLoading}>
      <PanelAndDetailAndCreateHeader title="Criar notas de serviço" goBack />

      <LayoutForm onSubmit={formik.handleSubmit}>
        <Input label="Tipo nota" value="Outros" readOnly />

        <GroupInput>
          <Select
            label="Vendedor"
            {...formik.getFieldProps("sellerCod")}
            data={
              getSellers.data?.sellers.map((seller) => ({
                name: `${seller.cod} - ${seller.abbreviation}`,
                value: seller.cod,
              })) ?? []
            }
            error={
              formik.touched.sellerCod && formik.errors.sellerCod
                ? formik.errors.sellerCod
                : undefined
            }
          />
        </GroupInput>

        <ButtonSubmit disabled={loading}>
          {loading ? <Loading /> : "Criar"}
        </ButtonSubmit>
      </LayoutForm>
    </CreateAndDetailLayout>
  );
};

export default CreateServiceInvoice;
