import React, { useState, useEffect, useRef } from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../../service/api";

import LoadingDot from "../../../components/loadings/LoadingDot";
import { dataZero } from "../../../utils/dataProcessing";

import useOutsideAlerter from "../../../hook/outsideAlerter";

import { FiSearch } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";

import {
  Container,
  Filter,
  FilterCheck,
  ContainerList,
  TableContainer,
  FooterContainer,
  Form,
  FilterSelects,
  LoadingPanel,
} from "../styles";

interface IShipping {
  id: number;
  cod: number;
  description?: string;
  is_tracking: boolean;
  link: string;
}

interface IFilter {
  cod?: number;
  description?: string;
  tracking?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IResponseAPI {
  contents: IShipping[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

const PanelBrands: React.FC = () => {
  const outSideRef = useRef(null);

  const [contents, setcontents] = useState<IShipping[]>([{} as IShipping]);

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({} as IFilter);

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  const [loading, setLoading] = useState({
    panel: true,
  });

  useEffect(() => {
    (async () => {
      setLoading({ ...loading, panel: true });
      try {
        const response = await api.get<IResponseAPI>(
          "/populate/shipping_company",
          {
            params: { ...filters, ...requestPagination },
          }
        );

        const { contents, limit, page, pages, total } = response.data;
        setcontents(contents);
        setPagination({ limit, page, pages, total });
        setLoading({ ...loading, panel: false });
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line
  }, [filters, requestPagination]);

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  function hadleFilter(filter: IFilter) {
    setRequestPagination({
      ...requestPagination,
      pageRequest: 0,
    });
    setFilters(filter);
    setFilterView(false);
  }
  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  return (
    <Container>
      <header>
        <h2>Lista de Transportadoras</h2>
      </header>
      <Filter>
        <button
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="button"
        >
          <FiSearch />
        </button>
        <input
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="text"
          name="filter"
          id="filter"
          readOnly={true}
        />
        <FilterSelects>
          <ul>
            {filters.cod && (
              <li>
                <span>COD: {filters.cod}</span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, cod: undefined })}
                />
              </li>
            )}
            {filters.description && (
              <li>
                <span>Descrição: {filters.description}</span>{" "}
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, description: undefined })
                  }
                />
              </li>
            )}
            {filters.tracking && (
              <li>
                <span>
                  Rastreio: {Number(filters.tracking) === 0 ? "Não" : "Sim"}
                </span>{" "}
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, tracking: undefined })
                  }
                />
              </li>
            )}
          </ul>
        </FilterSelects>

        {filterView && (
          <FilterCheck ref={outSideRef}>
            <header>
              <button
                onClick={() => {
                  setFilterView(false);
                }}
              >
                {" "}
                Fechar{" "}
              </button>
            </header>
            <main>
              <Formik
                initialValues={{
                  cod: filters.cod,
                  description: filters.description,
                  tracking: filters.tracking,
                }}
                validationSchema={Yup.object({})}
                onSubmit={(data: any) => {
                  hadleFilter({
                    cod: data.cod?.trim(),
                    description: data.description?.trim(),
                    tracking: data.tracking,
                  });
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="field">
                      <label htmlFor="cod">COD</label>
                      <input {...formik.getFieldProps("cod")} />
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="name">DESCRIÇÃO</label>
                        <input {...formik.getFieldProps("description")} />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="active">RASTREIO</label>
                      <select {...formik.getFieldProps("tracking")}>
                        <option value=""></option>
                        <option value={1}>Sim</option>
                        <option value={0}>Não</option>
                      </select>
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                )}
              </Formik>
            </main>
          </FilterCheck>
        )}
      </Filter>

      <ContainerList>
        <div className="headerTable">
          <h3>Transportadoras</h3>
        </div>

        <TableContainer>
          {!loading.panel ? (
            <table>
              <thead>
                <tr>
                  <th>COD</th>
                  <th>DESCRIÇÃO</th>
                  <th>RASTREIO</th>
                </tr>
              </thead>

              <tbody>
                {contents.map((item: IShipping, index) => (
                  <tr key={index} style={{ cursor: "auto" }}>
                    <td>{item.cod}</td>
                    <td>{item.description}</td>
                    <td>{item.is_tracking ? "SIM" : "NÃO"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>
        <FooterContainer>
          <span>
            Mostrado{" "}
            {dataZero(
              Number(contents.length + pagination.limit * pagination.page)
            )}{" "}
            de {dataZero(Number(pagination.total))} resultados
          </span>

          <div>
            <button
              className={pagination.page < 1 ? "buttonNull" : ""}
              onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
              type="button"
            >
              ANTERIOR
            </button>
            <p style={{ marginRight: 8 }}>
              {dataZero(Number(pagination.page + 1))}
            </p>
            <button
              className={
                pagination.page >= pagination.pages ? "buttonNull" : ""
              }
              type="button"
              onClick={
                Number(pagination.page) >= Number(pagination.pages)
                  ? () => {}
                  : hadleNextPage
              }
            >
              PROXIMO
            </button>
          </div>
        </FooterContainer>
      </ContainerList>
    </Container>
  );
};

export default PanelBrands;
