import { useEffect, useState } from "react";
import * as localCookiesAlertClientUpdate from "../service/localStorage/alertClientUpdate";

export default function useAlertUpdateClientConsent() {
  const [cookiesAlertUpdateClient, setCookiesAlertUpdateClient] =
    useState(false);

  useEffect(() => {
    const cookiesConsentData = localCookiesAlertClientUpdate.get();

    if (cookiesConsentData && cookiesConsentData.date) {
      const dateBeforeDays = new Date();
      dateBeforeDays.setDate(dateBeforeDays.getDate() - 15);

      if (new Date(cookiesConsentData.date) < dateBeforeDays) {
        setCookiesAlertUpdateClient(false);
      } else {
        setCookiesAlertUpdateClient(true);
      }
    }

    // eslint-disable-next-line
  }, [localCookiesAlertClientUpdate.get()]);

  useEffect(() => {
    if (cookiesAlertUpdateClient) {
      localCookiesAlertClientUpdate.set({ date: new Date() });
    }
  }, [cookiesAlertUpdateClient]);

  return { cookiesAlertUpdateClient, setCookiesAlertUpdateClient };
}
