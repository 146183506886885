import React, { useEffect, useRef, useState } from "react";

import Loading from "../../../components/loadings/Loading";
import LoadingDot from "../../../components/loadings/LoadingDot";
import api from "../../../service/api";
import * as clientLocalStorage from "../../../service/localStorage/client";
import * as UserStorage from "../../../service/localStorage/user";
import { setFormikValues } from "../../../utils/setFormikValues";

import { useHistory } from "react-router-dom";
import useOutsideAlerter from "../../../hook/outsideAlerter";

import { MdAdd, MdArrowDownward, MdNotifications } from "react-icons/md";
import {
  RiAlertFill,
  RiCloseCircleFill,
  RiFileExcel2Line,
} from "react-icons/ri";

import { IconType } from "react-icons";

import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";

import { objectToForEach } from "../../../utils/objectToForEach";

import { BiLinkExternal } from "react-icons/bi";
import { FcClock } from "react-icons/fc";
import { HiBadgeCheck } from "react-icons/hi";
import { FieldProps } from "../../../@types/panelTypes";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { Pagination } from "../../../components/panel/table/Pagination";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import {
  Container,
  ContainerList,
  ExternalLink,
  ExternalLinkA,
  LoadingPanel,
  ReportDownload,
  TableContainer,
} from "../styles";

interface IClient {
  id?: string;
  status?: string;

  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
}

interface IDevolution {
  id?: string;
  status: IStatus;

  requesterName: string;
  phone: string;
  email: string;

  //Dados da solicitação
  numberInvoice: string;
  situationRequest: string;
  products: string;

  requestType: string;

  //Dados da Devolução & indenização
  reason: string;

  //devolução
  dateInvoice: string;

  //indenização
  brand: {
    id: number;
    cod: number;
    name: string;
  };
  stateProduct: string;
  dateEmissionNFconsumer: string;
  invoiceOrcoupon: string;

  //Dados bancários
  bank: string;
  agency: string;
  account: string;

  user: IUser;
  clients_pj: IClient;

  //Dados internos
  //Dados NFD
  nfdNumber?: string;
  nfdValue?: string;
  nfdDate?: Date;

  //Representante / Gestor
  salesman?: string;
  brandManager?: string;

  //Trasporte
  freightBrand?: string;
  freightValue?: string;

  //Notificação
  notificationUser: boolean;

  created_at: Date;
}

interface IUser {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
}

interface IClient {
  id?: string;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
  created_at?: Date;
  acess_client?: {
    password?: string;
  };
  adresses?: {
    zip_code?: string;
    uf?: string;
    city?: string;
    street?: string;
    numberStreet?: string;
  };
}

interface IHeaderProps {
  admin: boolean;
  client: boolean;
}

interface IFilter {
  protocol?: string;
  requestType?: string;
  status?: string;
  brand?: string;
  notificationUser?: number;
  numberInvoice?: string;
  responsible?: string;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;

  finisherPeriod?: number;
  presetDateFinisher?: number;
  specificDateFinisher?: Date;
  startDateFinisher?: Date;
  endDateFinisher?: Date;
}

interface IDownloadReport {
  url: string;
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  valid: boolean;
  finisher: boolean;
  is_active: boolean;
  responsable_id: number;
  user_id: number;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IIndemnityApi {
  indemnities: IDevolution[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IBrand {
  id?: number;
  cod: number;
  idBrand: number;
  name: string;
  is_active: boolean;
}

const APP_LINK =
  process.env.NODE_ENV !== "development"
    ? "http://portal.alpardobrasil.com.br"
    : "http://localhost:3000";

const columns: FieldProps[] = [
  {
    name: "protocol",
    field: "PROTOCOLO",
    viewList: true,
  },
  {
    name: "notificationUser",
    field: "AÇÃO CLIENTES",
    viewList: true,
  },
  {
    name: "status",
    field: "STATUS",
    viewList: true,
  },
  {
    name: "numberInvoice",
    field: "NF",
    viewList: true,
  },
  {
    name: "responsible",
    field: "RESPONSÁVEL",
    viewList: true,
  },
  {
    name: "brand",
    field: "MARCA",
    viewList: true,
  },
  {
    name: "codClient",
    field: "CÓDIGO CLIENTE",
    viewList: true,
  },
  {
    name: "cnpj",
    field: "CNPJ",
    viewList: true,
  },
  {
    name: "company_name",
    field: "RAZÃO SOCIAL",
    viewList: true,
  },
  {
    name: "creationPeriod",
    field: "PERÍODO DE CRIAÇÃO",
    viewList: true,
  },
  {
    name: "presetDate",
    field: "DATA PREDEFINIDA",
    viewList: true,
  },
  {
    name: "specificDate",
    field: "DATA ESPECÍFICA",
    viewList: true,
  },
  {
    name: "startDate",
    field: "DATA INICIAL",
    viewList: true,
  },
  {
    name: "endDate",
    field: "DATA FINAL",
    viewList: true,
  },
  {
    name: "finisherPeriod",
    field: "PERÍODO DE FINALIZAÇÃO",
    viewList: true,
  },
  {
    name: "presetDateFinisher",
    field: "DATA PREDEFINIDA",
    viewList: true,
  },
  {
    name: "specificDateFinisher",
    field: "DATA ESPECÍFICA",
    viewList: true,
  },
  {
    name: "startDateFinisher",
    field: "DATA INICIAL",
    viewList: true,
  },
  {
    name: "endDateFinisher",
    field: "DATA FINAL",
    viewList: true,
  },
];

const PanelIndemnity: React.FC<IHeaderProps> = ({ admin, client }) => {
  const clietStorage = clientLocalStorage.getUser() as IClient;
  const UserData = UserStorage.getUser() as UserStorage.User;
  const query = useQueryParams();
  const history = useHistory();
  const outSideRef = useRef(null);

  const [filters, setFilters] = useState<IFilter>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const [loadingReport, setLoadingReport] = useState(false);
  const [devolutions, setDevolutions] = useState<IDevolution[]>([]);
  const [status, setStatus] = useState<IStatus[]>([]);

  const [users, setUsers] = useState<IUser[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);

  const [viewFilter, setViewFilter] = useState(false);

  const [loading, setLoading] = useState({
    panel: true,
  });

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);

  const formikFilter = useFormik({
    initialValues: {
      protocol: "",
      status: "",
      numberInvoice: "",
      responsible: "",
      brand: "",

      codClient: "",
      cnpj: "",
      company_name: "",

      creationPeriod: "",
      presetDate: "",
      specificDate: "",
      startDate: "",
      endDate: "",

      finisherPeriod: "",
      presetDateFinisher: "",
      specificDateFinisher: "",
      startDateFinisher: "",
      endDateFinisher: "",
    },
    validationSchema: Yup.object({
      presetDate: Yup.string().when("creationPeriod", {
        is: "1",
        then: Yup.string().required("Data predefinida é obrigatório"),
      }),

      specificDate: Yup.string().when("creationPeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      startDate: Yup.string().when("creationPeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      endDate: Yup.string().when("creationPeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),

      //Finalização

      presetDateFinisher: Yup.string().when("finisherPeriod", {
        is: "1",
        then: Yup.string().required("Data predefinida é obrigatório"),
      }),

      specificDateFinisher: Yup.string().when("finisherPeriod", {
        is: "2",
        then: Yup.string().required("Data específica é obrigatório"),
      }),

      startDateFinisher: Yup.string().when("finisherPeriod", {
        is: "3",
        then: Yup.string().required("Data inicio é obrigatório"),
      }),

      endDateFinisher: Yup.string().when("finisherPeriod", {
        is: "3",
        then: Yup.string().required("Data final é obrigatório"),
      }),
    }),
    onSubmit: (data) => {
      handleFilter(data as any);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setLoading({ ...loading, panel: true });
        if (admin) {
          const responseAdmin = await api.get<IIndemnityApi>("/indemnity", {
            params: { ...filters, pageRequest: page - 1 },
          });

          const {
            indemnities,
            limit,
            page: pageReponse,
            pages,
            total,
          } = responseAdmin.data;

          setDevolutions(indemnities);
          setPagination({ limit, page: pageReponse, pages, total });
          setLoading({ ...loading, panel: false });
        } else {
          const responseClient = await api.get<IIndemnityApi>(
            `/indemnity/client/${clietStorage.id}`,
            {
              params: { ...filters, pageRequest: page - 1 },
            }
          );
          const {
            indemnities,
            limit,
            page: pageReponse,
            pages,
            total,
          } = responseClient.data;
          setDevolutions(indemnities);
          setPagination({ limit, page: pageReponse, pages, total });
          setLoading({ ...loading, panel: false });
        }
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, page]);

  useEffect(() => {
    if (admin)
      api
        .get<IUser[]>("/populate/userAdmin")
        .then((response) => setUsers(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const statusDB = await api.get<IStatus[]>("/indemnity/status");
      setStatus(statusDB.data);

      const brandDB = await api.get<IBrand[]>("/populate/brand");
      setBrands(brandDB.data);
    })();
  }, []);

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  function hadleToNewDevolutions() {
    history.push("/criar/indenizacaoOuDevolucao");
  }

  function handleAccessDetail(indemnity: IDevolution) {
    if (admin) {
      history.push(`/admin/indenizacao/${indemnity.id}`, {
        content: indemnity,
      });
    }

    if (client) {
      history.push(`/indenizacao/${indemnity.id}`, {
        content: indemnity,
      });
    }
  }

  function handleFilter(filter: IFilter) {
    setFilters(filter);
    setPage(1);

    setViewFilter(false);
  }

  async function hadleReportCreate() {
    setLoadingReport(true);
    try {
      const response = await api.post<IDownloadReport>("/report/indemnity", {
        ...filters,
      });
      window.open(response.data.url);
      setLoadingReport(false);
    } catch (error) {
      setLoadingReport(false);
    }
  }

  function selectDescription(st: any, title: string) {
    let data = String(st);
    const arrTitle = [
      "brand",
      "orderPeriod",
      "billingOrderPeriod",
      "orderPresetDate",
      "billingOrderPresetDate",
      "notificationUser",
    ];
    if (arrTitle.includes(title)) {
      switch (title) {
        case "brand":
          const findBrand = brands.find(
            (brand) => Number(brand.id) === Number(st)
          );
          if (findBrand) data = findBrand.name;

          break;

        case "notificationUser":
          if (Number(st) === 0) {
            data = "Inativo";
          } else {
            data = "Ativo";
          }

          break;

        case "creationPeriod":
          if (Number(st) === 1) {
            data = "Predefinida";
          }
          if (Number(st) === 2) {
            data = "Específica";
          }
          if (Number(st) === 3) {
            data = "Intevalo";
          }

          break;
        case "finisherPeriod":
          if (Number(st) === 1) {
            data = "Predefinida";
          }
          if (Number(st) === 2) {
            data = "Específica";
          }
          if (Number(st) === 3) {
            data = "Intevalo";
          }

          break;
        case "presetDate":
          if (Number(st) === 0) {
            data = "Hoje";
          }
          if (Number(st) === 2) {
            data = "Ontem";
          }
          if (Number(st) === 7) {
            data = "Últimos 7 dias";
          }
          if (Number(st) === 15) {
            data = "Últimos 15 dias";
          }
          if (Number(st) === 30) {
            data = "Últimos 30 dias";
          }

          break;
        case "presetDateFinisher":
          if (Number(st) === 0) {
            data = "Hoje";
          }
          if (Number(st) === 2) {
            data = "Ontem";
          }
          if (Number(st) === 7) {
            data = "Últimos 7 dias";
          }
          if (Number(st) === 15) {
            data = "Últimos 15 dias";
          }
          if (Number(st) === 30) {
            data = "Últimos 30 dias";
          }

          break;

        default:
          break;
      }
    }

    return data;
  }

  function selectIcon(status: IStatus): JSX.Element | null {
    const icons = {
      alert: {
        Icon: RiAlertFill,
        size: 26,
        color: "#cacf46",
      },
      wait: {
        Icon: FcClock,
        size: 26,
      },

      success: {
        Icon: HiBadgeCheck,
        size: 26,
        color: "#21b543",
      },

      error: {
        Icon: RiCloseCircleFill,
        size: 26,
        color: "#d21e26",
      },
    };

    var icon: { Icon: IconType; size: number; color?: string } | undefined =
      icons.wait;

    var entity: "Client" | "User" | undefined = undefined;

    if (client) entity = "Client";
    if (admin) entity = "User";

    if (!status.finisher) {
      switch (entity) {
        case "Client":
          if (status.responsable_id === 0) icon = icons.alert;
          break;

        case "User":
          if (status.responsable_id === Number(UserData.permission))
            icon = icons.alert;
          break;

        default:
          break;
      }
    } else {
      if (status.valid) {
        icon = icons.success;
      } else {
        icon = icons.error;
      }
    }

    return icon !== undefined ? (
      <icon.Icon size={icon.size} color={icon.color} />
    ) : null;
  }

  useOutsideAlerter(outSideRef, () => {
    if (viewFilter) setViewFilter(false);
  });

  return (
    <Container>
      <header>
        <h2>Lista de Indenização</h2>
      </header>

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={selectDescription}
      >
        <GroupInput>
          <Input
            label="PROTOCOLO"
            {...formikFilter.getFieldProps("protocol")}
          />
          <Select
            label="MARCA"
            data={brands.map((brand) => ({
              name: brand.name,
              value: brand.cod,
            }))}
            {...formikFilter.getFieldProps("brand")}
          />
        </GroupInput>

        {admin && (
          <GroupInput>
            <Input
              label="CÓDIGO CLIENTE"
              {...formikFilter.getFieldProps("codClient")}
            />
            <Input label="CNPJ" {...formikFilter.getFieldProps("cnpj")} />
            <Input
              label="RAZÃO SOCIAL"
              {...formikFilter.getFieldProps("company_name")}
            />
          </GroupInput>
        )}

        {admin && (
          <GroupInput>
            <Input
              label="NF"
              {...formikFilter.getFieldProps("numberInvoice")}
            />

            <Select
              label="AÇÃO CLIENTES"
              data={[
                { name: "Ativo", value: 1 },
                { name: "Inativo", value: 0 },
              ]}
              {...formikFilter.getFieldProps("notificationUser")}
            />
          </GroupInput>
        )}

        <GroupInput>
          <Select
            label="STATUS"
            data={status.map((status) => ({
              name: status.name,
              value: status.cod,
            }))}
            {...formikFilter.getFieldProps("status")}
          />

          {admin && (
            <Select
              label="RESPONSÁVEL"
              data={users.map((user) => ({
                name: `${user.name} ${user.lastname}`,
                value: `${user.name} ${user.lastname}`,
              }))}
              {...formikFilter.getFieldProps("responsible")}
            />
          )}
        </GroupInput>

        <Select
          label="PERÍODO DE CRIAÇÃO"
          data={[
            { name: "Predefinida", value: 1 },
            { name: "Específica", value: 2 },
            { name: "Intervalo", value: 3 },
          ]}
          {...formikFilter.getFieldProps("creationPeriod")}
        />

        {Number(formikFilter.values.creationPeriod) === 1 && (
          <Select
            label="DATA DO PEDIDO"
            data={[
              { name: "Hoje", value: 0 },
              { name: "Ontem", value: 1 },
              { name: "Últimos 7 dias", value: 7 },
              { name: "Últimos 15 dias", value: 15 },
              { name: "Últimos 30 dias", value: 30 },
            ]}
            {...formikFilter.getFieldProps("presetDate")}
            error={
              formikFilter.touched.presetDate && formikFilter.errors.presetDate
                ? formikFilter.errors.presetDate
                : undefined
            }
          />
        )}
        {Number(formikFilter.values.creationPeriod) === 2 && (
          <Input
            type="date"
            label="DATA DO PEDIDO"
            {...formikFilter.getFieldProps("specificDate")}
            error={
              formikFilter.touched.specificDate &&
              formikFilter.errors.specificDate
                ? formikFilter.errors.specificDate
                : undefined
            }
          />
        )}
        {Number(formikFilter.values.creationPeriod) === 3 && (
          <GroupInput>
            <Input
              type="date"
              label="DATA INICIAL"
              {...formikFilter.getFieldProps("startDate")}
              error={
                formikFilter.touched.startDate && formikFilter.errors.startDate
                  ? formikFilter.errors.startDate
                  : undefined
              }
            />
            <Input
              type="date"
              label="DATA FINAL"
              {...formikFilter.getFieldProps("endDate")}
              error={
                formikFilter.touched.endDate && formikFilter.errors.endDate
                  ? formikFilter.errors.endDate
                  : undefined
              }
            />
          </GroupInput>
        )}

        <Select
          label="PERÍODO DE FINALIZAÇÃO"
          data={[
            { name: "Predefinida", value: 1 },
            { name: "Específica", value: 2 },
            { name: "Intervalo", value: 3 },
          ]}
          {...formikFilter.getFieldProps("finisherPeriod")}
        />

        {Number(formikFilter.values.finisherPeriod) === 1 && (
          <Select
            label="DATA DO PEDIDO"
            data={[
              { name: "Hoje", value: 0 },
              { name: "Ontem", value: 1 },
              { name: "Últimos 7 dias", value: 7 },
              { name: "Últimos 15 dias", value: 15 },
              { name: "Últimos 30 dias", value: 30 },
            ]}
            {...formikFilter.getFieldProps("presetDateFinisher")}
            error={
              formikFilter.touched.presetDateFinisher &&
              formikFilter.errors.presetDateFinisher
                ? formikFilter.errors.presetDateFinisher
                : undefined
            }
          />
        )}
        {Number(formikFilter.values.finisherPeriod) === 2 && (
          <Input
            type="date"
            label="DATA DO PEDIDO"
            {...formikFilter.getFieldProps("specificDateFinisher")}
            error={
              formikFilter.touched.specificDateFinisher &&
              formikFilter.errors.specificDateFinisher
                ? formikFilter.errors.specificDateFinisher
                : undefined
            }
          />
        )}
        {Number(formikFilter.values.finisherPeriod) === 3 && (
          <GroupInput>
            <Input
              type="date"
              label="DATA INICIAL"
              {...formikFilter.getFieldProps("startDateFinisher")}
              error={
                formikFilter.touched.startDateFinisher &&
                formikFilter.errors.startDateFinisher
                  ? formikFilter.errors.startDateFinisher
                  : undefined
              }
            />
            <Input
              type="date"
              label="DATA FINAL"
              {...formikFilter.getFieldProps("endDateFinisher")}
              error={
                formikFilter.touched.endDateFinisher &&
                formikFilter.errors.endDateFinisher
                  ? formikFilter.errors.endDateFinisher
                  : undefined
              }
            />
          </GroupInput>
        )}
      </PanelFilter>

      <ContainerList>
        <div className="headerTable">
          <h3>Indenizações</h3>

          <div>
            {admin && (
              <ReportDownload onClick={hadleReportCreate}>
                {loadingReport ? (
                  <Loading
                    borderSize={5}
                    size={20}
                    colorLoading="#259457"
                    borderColor="rgba(32, 114, 69, 0.5)"
                  />
                ) : (
                  <div className="icon-execel">
                    <RiFileExcel2Line color="#207245" size={24} />
                    <div className={"row-excel"}>
                      <MdArrowDownward color="#207245" size={16} />
                    </div>
                  </div>
                )}
              </ReportDownload>
            )}

            {!admin && (
              <button onClick={hadleToNewDevolutions} type="button">
                <MdAdd size={22} /> <span> NOVA INDENIZAÇÃO </span>
              </button>
            )}
          </div>
        </div>

        <TableContainer>
          {!loading.panel ? (
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>PROTOCOLO</th>
                  {admin && <th>RAZÃO SOCIAL</th>}
                  {admin && <th>NF</th>}
                  <th>STATUS</th>
                  <th>MARCA</th>
                  <th>RESPONSÁVEL</th>
                  <th>CRIADO EM</th>
                </tr>
              </thead>

              <tbody>
                {devolutions.map((devolution) => (
                  <tr
                    key={devolution.id}
                    // onClick={() => {
                    //   admin
                    //     ? history.push(`/admin/indenizacao/${devolution.id}`, {
                    //         content: devolution,
                    //       })
                    //     : history.push(`/indenizacao/${devolution.id}`, {
                    //         content: devolution,
                    //       });
                    // }}
                  >
                    <td
                      onClick={() => {
                        handleAccessDetail(devolution);
                      }}
                    >
                      {selectIcon(devolution.status)}
                    </td>
                    <td
                      onClick={() => {
                        handleAccessDetail(devolution);
                      }}
                    >
                      {Number(devolution.id)}
                      {devolution.notificationUser && admin && (
                        <div className="notification">
                          <MdNotifications color="#c7c949" size={20} />
                        </div>
                      )}
                    </td>
                    {admin && (
                      <td
                        onClick={() => {
                          handleAccessDetail(devolution);
                        }}
                      >
                        {devolution.clients_pj.company_name}
                      </td>
                    )}
                    {admin && (
                      <td
                        onClick={() => {
                          handleAccessDetail(devolution);
                        }}
                      >
                        {devolution.numberInvoice}
                      </td>
                    )}
                    <td
                      onClick={() => {
                        handleAccessDetail(devolution);
                      }}
                    >
                      <span
                        className="status"
                        style={{ backgroundColor: devolution.status.color }}
                      >
                        {devolution.status.name}
                      </span>
                    </td>
                    <td
                      onClick={() => {
                        handleAccessDetail(devolution);
                      }}
                    >
                      {devolution.brand.name}
                    </td>
                    <td
                      onClick={() => {
                        handleAccessDetail(devolution);
                      }}
                    >
                      {devolution.user.id !== undefined
                        ? `${devolution.user.name} ${devolution.user.lastname}`
                        : `-`}
                    </td>
                    <td
                      onClick={() => {
                        handleAccessDetail(devolution);
                      }}
                    >
                      {moment(devolution.created_at).format("DD/MM/YYYY")}
                    </td>
                    <ExternalLink>
                      <ExternalLinkA
                        href={
                          admin
                            ? `${APP_LINK}/admin/indenizacao/${devolution.id}`
                            : `${APP_LINK}/indenizacao/${devolution.id}`
                        }
                        target="_blank"
                      >
                        <BiLinkExternal size={20} />
                      </ExternalLinkA>
                    </ExternalLink>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>

        <Pagination
          totalCountOfRegisters={pagination.total}
          registersPerPage={pagination.limit}
          currentPage={page}
          onPageChange={setPage}
        />
      </ContainerList>
    </Container>
  );
};

export default PanelIndemnity;
