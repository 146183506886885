import React from "react";

import Loading from "../../../components/Loading";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { useContacts } from "../../../hook/queries/useContact";
import { Card, InnerHtml } from "../../Welcome/style";
import { ScreenLoading } from "../../create/styles";

export default function () {
  const { data: getContacts, isLoading } = useContacts(1, ``, 100);

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de contatos" />

      {isLoading && (
        <ScreenLoading style={{ height: "80%" }}>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}

      {getContacts?.contacts.map((contact) => (
        <Card key={contact.id}>
          <div>
            <h3>{contact.title}</h3>
            <InnerHtml dangerouslySetInnerHTML={{ __html: contact.text }} />
          </div>
        </Card>
      ))}
    </PanelLayout>
  );
}
