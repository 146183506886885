import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { Formik } from "formik";
import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import * as Yup from "yup";

import api from "../../../service/api";
import {
  ButtunSubmit,
  Container,
  ContainerCheck,
  ContainerCheckbox,
  ContainerForm,
  Error,
  Form,
} from "../styles";

interface IUser {
  email?: string;
  password?: string;
}

interface IBrand {
  id: string;
  cod?: number;
  name?: string;
}

const CreateUser: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [brands, setBrands] = useState<number[]>([]);
  const [brandError, setbrandError] = useState("");
  const [brandsDB, setBrandsDB] = useState<IBrand[]>([]);
  const [inputCheck, setInputCheck] = useState<any>({
    brands: {},
  });

  useEffect(() => {
    (async () => {
      const brandDB = await api.get("/populate/brand", {
        params: { active: 1 },
      });
      setBrandsDB(brandDB.data);
    })();
  }, []);

  useEffect(() => {
    if (inputCheck["brands"]) {
      var normalized: number[] = [];
      const values = Object.values(inputCheck["brands"]);
      const keys = Object.keys(inputCheck["brands"]);

      for (let index = 0; index < values.length; index++) {
        const value = values[index];
        const key = keys[index];

        if (value) {
          normalized.push(Number(key));
        }
      }

      if (normalized[0]) setbrandError("");

      setBrands(normalized);
    }
  }, [inputCheck]);

  async function handleSubmit(user: IUser) {
    try {
      await api.post("/provider", { ...user, brands });

      addToast("Fornecedor criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/fornecedores");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este e-mail já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "cod already exists") {
        return addToast("Este Código ERP já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function setChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setInputCheck({
      ...inputCheck,
      [e.target.name]: {
        ...inputCheck[e.target.name],
        [e.target.value]: e.target.checked,
      },
    });
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2>Criar fornecedor</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            cod: undefined,
            fullname: undefined,
            phone: undefined,
            email: undefined,
            password: undefined,
            isShippingCompany: false,
            // brand: undefined,
          }}
          validationSchema={Yup.object({
            fullname: Yup.string().required("Nome completo é obrigatório"),
            phone: Yup.string().required("Telefone é obrigatório"),
            // brand: Yup.string().required("Marca é obrigatório"),

            email: Yup.string()
              .email("informe um e-mail válido")
              .required("email é obrigatório"),
            password: Yup.string()
              .min(8, "A senha precisa conter no mínimo 8 caracteres.")
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "A senha precisa conter letrar e numeros, no mínimo 8 caracteres e um caracter especial."
              )
              .required("Senha é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              {/* <h3>Perfil de permissionamento</h3> */}

              <div className="field">
                <label htmlFor="cod">Código ERP</label>
                <input
                  id="cod"
                  type="number"
                  {...formik.getFieldProps("cod")}
                />
                {formik.touched.cod && formik.errors.cod ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.cod} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="fullname">Nome</label>
                <input id="fullname" {...formik.getFieldProps("fullname")} />
                {formik.touched.fullname && formik.errors.fullname ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.fullname} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="phone">É transportadora</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={
                      formik.values.isShippingCompany === true ? "check" : ""
                    }
                    onClick={() =>
                      formik.setFieldValue("isShippingCompany", true)
                    }
                  >
                    SIM
                  </button>

                  <button
                    type="button"
                    onClick={() =>
                      formik.setFieldValue("isShippingCompany", false)
                    }
                    className={
                      formik.values.isShippingCompany === false ? "check" : ""
                    }
                  >
                    NÃO
                  </button>
                </ContainerCheck>
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="email">Email</label>
                  <input id="email" {...formik.getFieldProps("email")} />
                  {formik.touched.email && formik.errors.email ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.email} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="phone">Telefone</label>
                  <input id="phone" {...formik.getFieldProps("phone")} />
                  {formik.touched.phone && formik.errors.phone ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.phone} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              {/* <div className="field">
                <label htmlFor="brand">Marcas</label>
                <select id="brand" {...formik.getFieldProps("brand")}>
                  <option value={undefined}>Selecionar marca</option>
                  {brands &&
                    brands.map((item) => (
                      <option value={item.cod} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
                {formik.touched.brand && formik.errors.brand ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.brand} </span>
                  </Error>
                ) : null}
              </div> */}

              <div className="field">
                <ContainerCheckbox>
                  <label className="titleCheckbox" htmlFor="">
                    Marca
                  </label>
                  <div className="contentsCheckbox">
                    {brandsDB.map((element, index) => (
                      <div key={index} className="boxCheckbox">
                        <input
                          type="checkbox"
                          onChange={setChangeCheckbox}
                          checked={inputCheck.brands[element.id] ? true : false}
                          value={element.id}
                          name={`brands`}
                        />
                        <label htmlFor="">{element.name}</label>
                      </div>
                    ))}
                  </div>
                </ContainerCheckbox>
                {brandError && (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {brandError} </span>
                  </Error>
                )}
              </div>

              <div className="field">
                <label htmlFor="password">Senha</label>
                <input id="password" {...formik.getFieldProps("password")} />
                {formik.touched.password && formik.errors.password ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.password} </span>
                  </Error>
                ) : null}
              </div>

              <ButtunSubmit type="submit"> Criar </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default CreateUser;
