import React, { useEffect, useState } from "react";

import { useQueryParams } from "../../hook/useQueryParams";
import { Container } from "./styles";

interface CatalogProps {
  location: {
    state?: {
      html?: string;
    };
  };
}

export const InnerHtml: React.FC<CatalogProps> = (props) => {
  const query = useQueryParams();

  const [innerHtml, setInnerHtml] = useState("");

  useEffect(() => {
    const innerHtmlData = query.get("HTML");

    if (innerHtmlData) {
      setInnerHtml(innerHtmlData);
    }
  }, [query]);

  return (
    <Container>
      <div
        id="innerHtmlReact"
        dangerouslySetInnerHTML={{ __html: innerHtml }}
      />
    </Container>
  );
};
