import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 15;
  cursor: pointer;

  display: flex;

  span {
    padding: 2px 5px;
    align-self: center;
    user-select: none;
    white-space: nowrap;
    border-radius: 3px;
    font-size: 18px;
    background: #666;
    color: #fff;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
    margin-right: 10px;
  }
`;

export const BtnFixed = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #016fb9;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);

  &:hover {
    opacity: 0.9;
  }

  @media (max-width: 900px) {
    width: 50px;
    height: 50px;
  }
`;

export const BtnOptions = styled.ul`
  list-style-type: none;
  margin: 0;
  position: absolute;
  bottom: 70px;
  right: 0;
  transition: all 0.3s ease;
  transform-origin: 85% bottom;

  opacity: 0;
  transform: scale(0);

  @media (max-width: 900px) {
    font-size: 14px;
    bottom: 60px;
  }

  li {
    display: flex;
    justify-content: flex-end;
    padding: 5px;

    div {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);

      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: 900px) {
        width: 40px;
        height: 40px;
      }
    }

    span {
      padding: 2px 5px;
      align-self: center;
      user-select: none;
      white-space: nowrap;
      border-radius: 3px;
      font-size: 16px;
      background: #666;
      color: #fff;
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
      margin-right: 10px;

      @media (max-width: 900px) {
        font-size: 14px;
      }
    }
  }
`;
