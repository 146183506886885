import React from "react";

import {
  Container,
  Card,
  Spinner,
  Progress,
  Title,
  ContainerLoading,
} from "./styles";

interface props {
  progress: number;
}

const Loading: React.FC<props> = ({ progress }) => {
  return (
    <Container>
      <Card>
        <Title> Transferindo arquivos... </Title>

        <ContainerLoading>
          <Spinner />
          <Progress>
            {progress} <span>%</span>
          </Progress>
        </ContainerLoading>
      </Card>
    </Container>
  );
};

export default Loading;
