import styled from "styled-components";

export const ContainerDot = styled.div`
  .dot {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin: 10px;
    display: inline-block;
    background: #888;
    transform: scale(0.2);
    animation: animationDot 0.8s infinite ease-out;

    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }

  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  .dot:nth-child(4) {
    animation-delay: 0.7s;
  }
  .dot:nth-child(5) {
    animation-delay: 1s;
  }

  @keyframes animationDot {
    0%,
    100% {
      transform: scale(0.2);
    }

    50% {
      transform: scale(1);
    }
  }
`;
