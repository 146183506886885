import React, { useState } from "react";

import { Container } from "./styles";

import { useFormik } from "formik";
import { IoReload } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { HeaderDatasheetAndDatasheet } from "../..";

import { IPermissionUser } from "../../../../../@types";
import { GroupInput } from "../../../../../components/Form/GroupInput";
import { Select } from "../../../../../components/Form/Select";
import { PanelFilter } from "../../../../../components/panel/PanelFilter";
import { Pagination } from "../../../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../../../components/panel/table/TableContent";
import { TableHeader } from "../../../../../components/panel/table/TableHeader";
import {
  Quality,
  useQualities,
} from "../../../../../hook/queries/useQualities";
import { useQueryParams } from "../../../../../hook/useQueryParams";
// @ts-ignore

interface DatasheetActionsChatProps {
  isActive: boolean;

  admin?: boolean;
  provider?: boolean;
  seller?: boolean;

  permission?: IPermissionUser;

  data: HeaderDatasheetAndDatasheet;
}

interface QualityFilters {}

const columns: FieldProps<Quality>[] = [
  {
    name: "type",
    field: "Tipo",
  },
  {
    name: "status",
    field: "Situação",
  },
  {
    name: "typeAndStatus",
    field: "Tipo e Situação",
    render: (data: Quality) => {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", padding: "6px 0" }}
        >
          <span style={{ whiteSpace: "nowrap", marginBottom: 6 }}>
            {data.type.name}
          </span>
          <span
            className="status"
            style={{ backgroundColor: data.status.color }}
          >
            {data.status.name}
          </span>
        </div>
      );
    },
    viewList: true,
  },
  {
    name: "oc",
    field: "O.C",
    viewList: true,
  },

  {
    name: "providerName",
    field: "Fornecedor",
    viewList: true,
  },
  {
    name: "inspectorName",
    field: "Auditor(a)",
    viewList: true,
  },
  {
    name: "createdAt",
    field: "Data criação",
    viewList: true,
  },
];

export const DatasheetActionsQuality: React.FC<DatasheetActionsChatProps> = ({
  data: contentData,
  isActive,
  permission,
}) => {
  const query = useQueryParams();
  const history = useHistory();

  const [viewFilter, setViewFilter] = useState(false);
  const [filters, setFilters] = useState<QualityFilters>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });
  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const { data, isLoading, isFetching, refetch } = useQualities(
    page,
    contentData.id,
    filters
  );

  const formikFilter = useFormik({
    initialValues: {
      status: "",
      type: "",
      reference: "",
      oc: "",
      providerId: "",
      inspectorId: "",
    },
    onSubmit: (data) => {
      handleFilter(data);
    },
  });

  function handleFilter(data: QualityFilters) {
    setFilters(data);
    setPage(1);
    setViewFilter(false);
  }

  function handleNew() {
    history.push(
      `/admin/criar/qualidade/cabecalho-ficha-tecnica/${contentData.header_datasheet.id}/ficha-tecnica/${contentData.id}`
    );
  }
  function handleOpenQuality(quality: Quality) {
    history.push(
      `/admin/cabecalho-ficha-tecnica/${contentData.header_datasheet.id}/ficha-tecnica/${contentData.id}/qualidade/${quality.id}`
    );
  }

  function selectDescription(st: any, title: string) {
    let dataSt = String(st);
    const arrTitle = [
      "reference",
      "oc",
      "provider",
      "inspector",
      "type",
      "status",
    ];
    if (arrTitle.includes(title)) {
      // @ts-ignore
      data?.filters[title].forEach((item) => {
        if (Number(item.id) === Number(st)) {
          return (dataSt = item.name);
        }
      });
    }

    return dataSt;
  }

  return (
    <Container style={{ display: !isActive ? "none" : "" }}>
      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={selectDescription}
      >
        <GroupInput>
          <Select
            data={
              data?.filters.status.map((f) => ({
                name: f.name,
                value: f.id,
              })) ?? []
            }
            label="Situação"
            {...formikFilter.getFieldProps("status")}
          />
          <Select
            data={
              data?.filters.type.map((f) => ({
                name: f.name,
                value: f.id,
              })) ?? []
            }
            label="Tipo"
            {...formikFilter.getFieldProps("type")}
          />
        </GroupInput>
        <GroupInput>
          <Select
            data={
              data?.filters.oc.map((f) => ({
                name: f.name,
                value: f.id,
              })) ?? []
            }
            label="O.C"
            {...formikFilter.getFieldProps("oc")}
          />
        </GroupInput>
        <GroupInput>
          <Select
            data={
              data?.filters.provider.map((f) => ({
                name: f.name,
                value: f.id,
              })) ?? []
            }
            label="Fornecedor"
            {...formikFilter.getFieldProps("providerId")}
          />
          <Select
            data={
              data?.filters.inspector.map((f) => ({
                name: f.name,
                value: f.id,
              })) ?? []
            }
            label="Auditor(a)"
            {...formikFilter.getFieldProps("inspectorId")}
          />
        </GroupInput>
      </PanelFilter>

      <TableHeader
        title="Relatórios de Auditoria"
        isFetching={isFetching && !isLoading}
      >
        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>
        {permission?.datasheetQuality && permission.datasheetQuality > 2 && (
          <button onClick={handleNew} type="button">
            <MdAdd size={22} /> <span> NOVA </span>
          </button>
        )}
      </TableHeader>

      <TableContent<Quality>
        data={data?.qualities.map((quality) => ({
          ...quality,
          id: quality.id,
          oc: quality.oc,
          providerName: `${quality.provider.cod} - ${quality.provider.fullname}`,
          inspectorName: `${quality.inspector.name} - ${quality.inspector.lastname}`,
          createdAt: new Date(quality.created_at).toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          }),

          // code: mask(ncm.code, ["9999.99.99"]),
        }))}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) => handleOpenQuality(e)}
      />
      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
      />
    </Container>
  );
};
