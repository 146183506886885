import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import Loading from "../../../components/loadings/Loading";
import {
  ButtunSubmit,
  Container,
  ContainerForm,
  DivUploadFile,
  Error,
  Form,
  HeaderUploadFile,
} from "../styles";

import filesize from "filesize";
import Dropzone from "../../../components/Upload/DropzoneXlsx";
import generateHash from "../../../utils/generateHash";

import { IoMdTrash } from "react-icons/io";
import logoExecel from "../../../assets/logo-execel.svg";
import { ContainerDropZone } from "../../detail/styles";
import { ContainerPreviews } from "../../import/styles";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { Formik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import * as Yup from "yup";

import { MdArrowDownward } from "react-icons/md";
import { RiFileExcel2Line } from "react-icons/ri";

import { FaArrowLeft } from "react-icons/fa";
import api from "../../../service/api";

interface IHeaderProps {
  admin: boolean;
}

interface ISeller {
  id: number;
  cod: number;
  abbreviation: string;
  fullName: string;
  email: string;
  phone: string | null;
  is_active: boolean;
  is_manager: boolean;
  is_supervisor: boolean;
  cod_manager: number | null;
  cod_supervisor: number | null;
  created_at: Date;
  updated_at: Date;
}

interface IResponseAPI {
  contents: ISeller[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IBrand {
  id?: number;
  cod: number;
  name: string;
  is_active: boolean;
}

export interface IDescription {
  id: number;
  description: string;
  is_active: boolean;
}

const CreateForecast: React.FC<IHeaderProps> = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [years, setYears] = useState<
    {
      id: number;
      year: string;
    }[]
  >([]);

  const [selectFile, setSelectFile] = useState<File>();
  const [files, setFiles] = useState<File | undefined>();

  const [selectFileHistoric, setSelectFileHistoric] = useState<File>();
  const [filesHistoric, setFilesHistoric] = useState<File | undefined>();

  const [fileError, setFileError] = useState("");
  const [fileErrorHistoric, setFileErrorHistoric] = useState("");
  const [loading, setLoading] = useState(false);

  const [descriptions, setDescriptions] = useState<IDescription[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [sellers, setSellers] = useState<ISeller[]>([]);

  useEffect(() => {
    let dateYears = [];
    const year = new Date().getFullYear();

    for (let index = 0; index < 5; index++) {
      dateYears.push({ id: index + 1, year: String(year + index) });
    }

    setYears(dateYears);
  }, []);

  useEffect(() => {
    if (selectFileHistoric) {
      setFileErrorHistoric("");
      setFilesHistoric(selectFileHistoric);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFileHistoric]);

  useEffect(() => {
    if (selectFile) {
      setFileError("");
      setFiles(selectFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFile]);

  useEffect(() => {
    (async () => {
      const brandDB = await api.get<IBrand[]>("/populate/brand", {
        params: { active: 1 },
      });
      setBrands(brandDB.data);

      const sellerDB = await api.get<IResponseAPI>("/seller", {
        params: { active: 1, limitRequest: 9999 },
      });
      setSellers(sellerDB.data.contents);

      const descriptionDB = await api.get<IDescription[]>(
        "/populate/forecast/description",
        {
          params: { active: 1 },
        }
      );
      setDescriptions(descriptionDB.data);
    })();
  }, []);

  async function hadleDeleteFile() {
    setFiles(undefined);
  }

  async function hadleDeleteFileHistoric() {
    setFilesHistoric(undefined);
  }

  async function handleSubmit(item: any) {
    setLoading(true);
    try {
      if (!filesHistoric && !filesHistoric) {
        setLoading(false);
        setFileError("Arquivo e obrigatório.");
        setFileErrorHistoric("Arquivo e obrigatório.");
        return;
      }

      if (!files) {
        setLoading(false);
        return setFileError("Arquivo e obrigatório.");
      }

      if (!filesHistoric) {
        setLoading(false);
        return setFileErrorHistoric("Arquivo e obrigatório.");
      }

      const formDataTest = new FormData();
      formDataTest.append("file", files);
      await api.post("/forecast/test/file", formDataTest);

      const formDataHistoricTest = new FormData();
      formDataHistoricTest.append("file", filesHistoric);
      await api.post("/forecast/test/historic/file", formDataHistoricTest);

      const forecast = await api.post("/forecast", {
        ...item,
        equipment: Boolean(Number(item.equipment)),
        footwear: Boolean(Number(item.footwear)),
        apparel: Boolean(Number(item.apparel)),
      });

      const formData = new FormData();
      formData.append("file", files);
      formData.append("id", forecast.data.id);

      await api.post("/forecast/file", formData);

      //////////////////////////

      const formDataHistoric = new FormData();
      formDataHistoric.append("file", filesHistoric);
      formDataHistoric.append("id", forecast.data.id);

      await api.post("/forecast/historic/file", formDataHistoric);

      setLoading(false);

      addToast("Forecast criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/previsoes");
    } catch (err) {
      const error = err as any;
      setLoading(false);
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 406) {
        return addToast(
          `Falha ao importar arquivo de ${error.response.data.import}. Revise o arquivo "${error.response.data.file}"`,
          {
            appearance: "warning",
          }
        );
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleDownloadFile(uri: string, filename: string) {
    try {
      const fileResponse = await api.get(uri, {
        responseType: "blob",
      });

      fileDownload(fileResponse.data, filename);
    } catch (error) {
      return addToast(
        "Ocorreu um erro ao gerar arquivo, Tente novamente mais tarde ou entre em contato conosco (51) 3441-1000",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2
          style={{
            alignSelf: "center",
          }}
        >
          Criar forecast
        </h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            description: "",
            descriptionYear: "",
            title: "",
            brand: "",
            seller: "",
            historic1: "",
            historic2: "",
            historic3: "",
            historic4: "",
            equipment: 1,
            footwear: 1,
            apparel: 1,
            closing_at: undefined,
          }}
          validationSchema={Yup.object({
            description: Yup.string().required("Obrigatório"),
            descriptionYear: Yup.string().required("Obrigatório"),

            historic1: Yup.string().required("Historico 1 é obrigatório"),
            historic2: Yup.string().required("Historico 2 é obrigatório"),
            historic3: Yup.string().required("Historico 3 é obrigatório"),
            historic4: Yup.string().required("Historico 4 é obrigatório"),

            brand: Yup.string().required("Marca é obrigatório"),
            seller: Yup.string().required("Responsável é obrigatório"),
            closing_at: Yup.date().required("Data finalização é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form
              onSubmit={
                loading
                  ? (e) => {
                      e.preventDefault();
                    }
                  : formik.handleSubmit
              }
            >
              <h3>Descrição</h3>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="description">Coleção</label>
                  <select
                    {...formik.getFieldProps("description")}
                    name="description"
                  >
                    <option value="">Selecione uma coleção</option>
                    {descriptions.map((item) => (
                      <option value={item.id}>{item.description}</option>
                    ))}
                  </select>
                  {formik.touched.description && formik.errors.description ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.description} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="descriptionYear">Ano</label>
                  <select
                    {...formik.getFieldProps("descriptionYear")}
                    name="descriptionYear"
                  >
                    <option value="">Selecione um ano</option>
                    {years.map((item) => (
                      <option key={item.id} value={item.year}>
                        {item.year}
                      </option>
                    ))}
                  </select>
                  {formik.touched.descriptionYear &&
                  formik.errors.descriptionYear ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.descriptionYear} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <h3>Dados</h3>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="lastname">Marca</label>
                  <select {...formik.getFieldProps("brand")}>
                    <option value="">Selecionar marca</option>
                    {brands.map((item) => (
                      <option key={item.id} value={item.cod}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.brand && formik.errors.brand ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.brand} </span>
                    </Error>
                  ) : null}
                </div>

                <div className="field">
                  <label htmlFor="email">Responsável</label>
                  <select {...formik.getFieldProps("seller")}>
                    <option value="">Selecionar responsável</option>
                    {sellers
                      .filter((f) => f.is_manager || f.is_supervisor)
                      .map((item) => (
                        <option key={item.id} value={item.cod}>
                          {`${item.cod} - ${item.abbreviation}`}
                        </option>
                      ))}
                  </select>
                  {formik.touched.seller && formik.errors.seller ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.seller} </span>
                    </Error>
                  ) : null}
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="historic1">Historico 1</label>
                  <input
                    {...formik.getFieldProps("historic1")}
                    name="historic1"
                  />
                  {formik.touched.historic1 && formik.errors.historic1 ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.historic1} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="historic2">Historico 2</label>
                  <input
                    {...formik.getFieldProps("historic2")}
                    name="historic2"
                  />
                  {formik.touched.historic2 && formik.errors.historic2 ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.historic2} </span>
                    </Error>
                  ) : null}
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="historic3">Historico 3</label>
                  <input
                    {...formik.getFieldProps("historic3")}
                    name="historic3"
                  />
                  {formik.touched.historic3 && formik.errors.historic3 ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.historic3} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="historic4">Historico 4</label>
                  <input
                    {...formik.getFieldProps("historic4")}
                    name="historic4"
                  />
                  {formik.touched.historic4 && formik.errors.historic4 ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.historic4} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="equipment">Equipment</label>
                  <select {...formik.getFieldProps("equipment")}>
                    <option value={1}>SIM</option>
                    <option value={0}>NÃO </option>
                  </select>
                  {formik.touched.equipment && formik.errors.equipment ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.equipment} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="apparel">Apparel</label>
                  <select {...formik.getFieldProps("apparel")}>
                    <option value={1}>SIM</option>
                    <option value={0}>NÃO </option>
                  </select>
                  {formik.touched.apparel && formik.errors.apparel ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.apparel} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="footwear">Footwear</label>
                  <select {...formik.getFieldProps("footwear")}>
                    <option value={1}>SIM</option>
                    <option value={0}>NÃO </option>
                  </select>
                  {formik.touched.footwear && formik.errors.footwear ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.footwear} </span>
                    </Error>
                  ) : null}
                </div>
              </div>
              <div className="field">
                <label htmlFor="closing_at">Data finalização</label>
                <input
                  id="closing_at"
                  type="date"
                  {...formik.getFieldProps("closing_at")}
                />
                {formik.touched.closing_at && formik.errors.closing_at ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.closing_at} </span>
                  </Error>
                ) : null}
              </div>
              <DivUploadFile>
                <div>
                  <HeaderUploadFile>
                    <h3>Importação de participantes</h3>

                    <nav className="download-file">
                      <button
                        type="button"
                        onClick={() =>
                          handleDownloadFile(
                            "forecast/download/file/import",
                            "Importação de participantes.xlsx"
                          )
                        }
                      >
                        Baixar planilha modelo
                        <span>
                          <RiFileExcel2Line color="#207245" size={20} />
                          <MdArrowDownward color="#207245" size={14} />
                        </span>
                      </button>
                    </nav>
                  </HeaderUploadFile>

                  <ContainerDropZone>
                    <Dropzone onFileUploaded={(e) => setSelectFile(e)} />
                    <ContainerPreviews>
                      {files && (
                        <li key={`${generateHash()}-${files.name}`}>
                          <img src={logoExecel} alt="Logo_Execel" />
                          <div className="fileInfo">
                            <div>
                              <strong>{files.name}</strong>
                              <span>{filesize(files.size)}</span>
                            </div>

                            <button
                              type="button"
                              onClick={() => {
                                hadleDeleteFile();
                              }}
                            >
                              <IoMdTrash size={30} />
                            </button>
                          </div>
                        </li>
                      )}
                    </ContainerPreviews>
                  </ContainerDropZone>
                  {fileError && (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {fileError} </span>
                    </Error>
                  )}
                </div>

                <div>
                  <HeaderUploadFile>
                    <h3>Importação de historico</h3>

                    <nav className="download-file">
                      <button
                        type="button"
                        onClick={() =>
                          handleDownloadFile(
                            "forecast/download/historic/file/import",
                            "Importação de historico.xlsx"
                          )
                        }
                      >
                        Baixar planilha modelo
                        <span>
                          <RiFileExcel2Line color="#207245" size={20} />
                          <MdArrowDownward color="#207245" size={14} />
                        </span>
                      </button>
                    </nav>
                  </HeaderUploadFile>

                  <ContainerDropZone>
                    <Dropzone
                      onFileUploaded={(e) => setSelectFileHistoric(e)}
                    />
                    <ContainerPreviews>
                      {filesHistoric && (
                        <li key={`${generateHash()}-${filesHistoric.name}`}>
                          <img src={logoExecel} alt="Logo_Execel" />
                          <div className="fileInfo">
                            <div>
                              <strong>{filesHistoric.name}</strong>
                              <span>{filesize(filesHistoric.size)}</span>
                            </div>

                            <button
                              type="button"
                              onClick={() => {
                                hadleDeleteFileHistoric();
                              }}
                            >
                              <IoMdTrash size={30} />
                            </button>
                          </div>
                        </li>
                      )}
                    </ContainerPreviews>
                  </ContainerDropZone>
                  {fileErrorHistoric && (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {fileErrorHistoric} </span>
                    </Error>
                  )}
                </div>
              </DivUploadFile>
              <ButtunSubmit type="submit">
                {loading ? (
                  <div>
                    <Loading borderSize={2} size={20} />
                  </div>
                ) : (
                  "Criar forecast"
                )}
              </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default CreateForecast;
