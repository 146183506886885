import { useQuery, UseQueryResult } from "react-query";
import { OptionsFilter } from "../../@types";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Seller = {
  email: string;
  cod: number;
  abbreviation: string;
  fullName: string;
  is_active: boolean;
  is_activeNormalized: string;
  nextdata_activeNormalized: string;
  id: number;
  phone: string | null;
  typeSeller: string;
  cnpj: string;

  is_manager: boolean;
  is_supervisor: boolean;
  cod_manager?: number;
  cod_supervisor?: number;

  nextdata_login?: string;
  nextdata_password?: string;
  nextdata_active?: boolean;

  addrres?: {
    zip_code?: string;
    uf?: string;
    city?: string;
    street?: string;
    neighborhood?: string;
  };

  brands: {
    name: string;
    cod: number;
    id: number;
  }[];

  access: {
    date: Date;
    dateToString: string;
  }[];

  team: Seller[];

  groupsToSeller: {
    manager: {
      cod: number;
      abbreviation: string;
    };
    group: {
      cod: number;
      descrpition: string;
    };
  }[];
};

export type SellerFilters = {
  cod?: number;
  abbreviation?: string;
  fullName?: string;
  email?: string;
  active?: number;
  brandCode?: number;
  brands?: number[];
  groups?: number[];
  managerOrSupervisor?: number;
  search?: string;

  manager?: string;
  supervisor?: string;
};

export interface SellerFilterOptions {
  group: OptionsFilter[];
  brand: OptionsFilter[];
  manager: OptionsFilter[];
  supervisor: OptionsFilter[];
  uf: OptionsFilter[];
  city: OptionsFilter[];
}

type GetSellersResponse = {
  sellers: Seller[];
  filters: SellerFilterOptions;
  totalCount: number;
};

interface SellerApiResponse extends ResponsePanelApiDefault {
  contents: Seller[];
  filters: SellerFilterOptions;
}

export async function getSellers(
  page: number,
  filter?: SellerFilters,
  registersPerPag?: number
): Promise<GetSellersResponse> {
  const { data } = await api.get<SellerApiResponse>("/seller", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag, ...filter },
  });

  return {
    filters: data.filters,
    totalCount: data.total,
    sellers: data.contents.map((seller) => ({
      ...seller,
      is_activeNormalized: seller.is_active ? "ATIVO" : "INATIVO",
      nextdata_activeNormalized: seller.nextdata_active ? "ATIVO" : "INATIVO",
    })),
  };
}

export function useSellers(
  page: number,
  filter?: SellerFilters,
  registersPerPag?: number
): UseQueryResult<GetSellersResponse> {
  return useQuery(
    ["seller", page, filter],
    () => getSellers(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneSeller(id?: number): Promise<Seller | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<Seller>(`/seller/show/${id}`);

  const normalized = {
    ...data,

    access: data.access?.map((item) => ({
      ...item,
      dateToString: new Date(item.date).toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }),
    })),
  };

  return normalized;
}

export function useOneSeller(id?: number): UseQueryResult<Seller> | undefined {
  return useQuery(["seller", id], () => getOneSeller(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
