import React, { useEffect, useState } from "react";
import Loading from "../../../components/loadings/Loading";
import ModalLoading from "../../../components/loadings/ScreenLoading";
import {
  ButtunSubmit,
  ContainerCheckbox,
  ContainerDropZone,
  ContainerPreviews,
  Error,
  Form,
} from "../styles";

import filesize from "filesize";
import Dropzone from "../../../components/Upload/Dropzone";
import generateHash from "../../../utils/generateHash";

import { IoMdTrash } from "react-icons/io";
import selectIcon from "../../../utils/selectIcon";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { Formik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import * as Yup from "yup";

import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { Textarea } from "../../../components/Form/Textarea";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import api from "../../../service/api";

interface IHeaderProps {
  admin: boolean;
}

export interface BrandProps {
  id: number;
  cod: number;
  name: string;
  is_active: boolean;
  is_not_working?: boolean;
}

const Create: React.FC<IHeaderProps> = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [selectFile, setSelectFile] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);

  const [brandError, setbrandError] = useState("");
  const [loading, setLoading] = useState(false);

  const [brandsDB, setBrandsDB] = useState<BrandProps[]>([]);
  const [brands, setBrands] = useState<number[]>([]);

  const [onUploadProgress, setOnUploadProgress] = useState(0);
  const [loadingUpload, setLoadingUpload] = useState(false);

  const [inputCheck, setInputCheck] = useState<any>({
    brands: {},
  });

  useEffect(() => {
    (async () => {
      const brandDB = await api.get<BrandProps[]>("/populate/brand", {
        params: { active: 1 },
      });
      setBrandsDB(brandDB.data);
    })();
  }, []);

  useEffect(() => {
    if (selectFile) {
      setFiles([...files, selectFile]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFile]);

  useEffect(() => {
    if (loadingUpload) {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [loadingUpload]);

  useEffect(() => {
    if (onUploadProgress >= 100) {
      setLoadingUpload(false);
      setLoading(false);
      setOnUploadProgress(0);

      addToast("Tutorial criado com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/arquivos");
    }
    // eslint-disable-next-line
  }, [onUploadProgress]);

  useEffect(() => {
    if (inputCheck["brands"]) {
      var normalized: number[] = [];
      const values = Object.values(inputCheck["brands"]);
      const keys = Object.keys(inputCheck["brands"]);

      for (let index = 0; index < values.length; index++) {
        const value = values[index];
        const key = keys[index];

        if (value) {
          normalized.push(Number(key));
        }
      }

      if (normalized[0]) setbrandError("");

      setBrands(normalized);
    }
  }, [inputCheck]);

  async function handleDeleteFile(
    indece: number,
    setFile: (value: React.SetStateAction<File[]>) => void,
    file: File[]
  ) {
    let arr = file;
    arr.splice(indece, 1);

    setFile([]);

    setTimeout(() => {
      setFile(arr);
    }, 0.1);
  }

  async function handleSubmit(item: any) {
    setLoading(true);
    try {
      if (!brands[0]) {
        setLoading(false);
        setbrandError("Marca e obrigatório.");
      }

      if (brands[0]) {
        const data = {
          ...item,
          brands: brands,
        };

        const showFile = await api.post("/showFiles", data);

        if (files.length <= 0) {
          addToast("Tutorial criado com sucesso.", {
            appearance: "success",
            autoDismiss: true,
          });
          return history.push("/admin/arquivos");
        }

        if (files.length > 0) {
          setLoadingUpload(true);

          const formData = new FormData();
          files.forEach((element) => {
            formData.append("file", element);
          });

          api.post(`/showFiles/file/${showFile.data.id}`, formData, {
            onUploadProgress: (e) => {
              const loaded = +e.loaded;
              const total = +e.total;

              //@ts-ignore
              var progress = parseInt(Math.round((loaded * 100) / total));

              setOnUploadProgress(progress);
            },
          });
        }
      }
    } catch (err) {
      const error = err as any;

      setLoading(false);
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function setChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setInputCheck({
      ...inputCheck,
      [e.target.name]: {
        ...inputCheck[e.target.name],
        [e.target.value]: e.target.checked,
      },
    });
  }

  const alertUser = (e: any) => {
    e.preventDefault();
    e.returnValue = "As alterações feitas podem não ser salvas.";
  };

  return (
    <>
      <CreateAndDetailLayout>
        <PanelAndDetailAndCreateHeader title="Criar Tutorial" goBack />

        <LayoutForm>
          <Formik
            initialValues={{
              name: "",
              link: "",
              description: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().required("Nome é obrigatório"),
              description: Yup.string().required("Descrição é obrigatório"),
              link: Yup.string().required("Link é obrigatório"),
            })}
            onSubmit={(data) => handleSubmit(data)}
          >
            {(formik) => (
              <Form
                onSubmit={
                  loading
                    ? (e) => {
                        e.preventDefault();
                      }
                    : formik.handleSubmit
                }
              >
                <Input
                  {...formik.getFieldProps("name")}
                  label="Titulo"
                  error={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : undefined
                  }
                />

                <Textarea
                  {...formik.getFieldProps("description")}
                  label="Descrição"
                  error={
                    formik.touched.description && formik.errors.description
                      ? formik.errors.description
                      : undefined
                  }
                />

                <Input
                  {...formik.getFieldProps("link")}
                  label="Link"
                  error={
                    formik.touched.link && formik.errors.link
                      ? formik.errors.link
                      : undefined
                  }
                />

                <div className="field">
                  <ContainerCheckbox>
                    <label className="titleCheckbox" htmlFor="">
                      Marca
                    </label>
                    <div className="contentsCheckbox">
                      {brandsDB.map((element, index) => (
                        <div key={index} className="boxCheckbox">
                          <input
                            type="checkbox"
                            onChange={setChangeCheckbox}
                            checked={
                              inputCheck.brands[element.id] ? true : false
                            }
                            value={element.id}
                            name={`brands`}
                          />
                          <label htmlFor="">{element.name}</label>
                        </div>
                      ))}
                    </div>
                  </ContainerCheckbox>
                  {brandError && (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {brandError} </span>
                    </Error>
                  )}
                </div>

                <div className="field">
                  <div className="containerLabelButton">
                    <h3>Arquivos</h3>
                    <button
                      type="button"
                      onClick={() => {
                        setFiles([]);
                      }}
                    >
                      <IoMdTrash size={15} />
                      Apagar todos
                    </button>
                  </div>

                  <ContainerDropZone>
                    <Dropzone
                      accept={[
                        "image/*",
                        "application/pdf",
                        "application/vnd.ms-excel",
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/vnd.ms-powerpoint",
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                        "application/msword",
                      ]}
                      onFileUploaded={(e) => setSelectFile(e)}
                    />
                    <ContainerPreviews
                      style={{
                        overflowY: "auto",
                        maxHeight: "30vh",
                      }}
                    >
                      {files &&
                        files.map((file, index) => (
                          <li key={`${generateHash()}-${file.name}`}>
                            <img
                              src={
                                selectIcon(file.type)
                                  ? selectIcon(file.type)
                                  : URL.createObjectURL(file)
                              }
                              alt="_"
                            />
                            <div className="fileInfo">
                              <div>
                                <strong>{file.name}</strong>
                                <span>{filesize(file.size)}</span>
                              </div>

                              <button
                                type="button"
                                onClick={() => {
                                  handleDeleteFile(index, setFiles, files);
                                }}
                              >
                                <IoMdTrash size={30} />
                              </button>
                            </div>
                          </li>
                        ))}
                    </ContainerPreviews>
                  </ContainerDropZone>
                </div>

                <ButtunSubmit type="submit">
                  {loading ? (
                    <div>
                      <Loading borderSize={2} size={20} />
                    </div>
                  ) : (
                    "Criar"
                  )}
                </ButtunSubmit>
              </Form>
            )}
          </Formik>
        </LayoutForm>
      </CreateAndDetailLayout>

      {loadingUpload ? <ModalLoading progress={onUploadProgress} /> : null}
    </>
  );
};

export default Create;
