import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

import { useFormik } from "formik";

import { IoReload } from "react-icons/io5";
import { FieldProps, panelTypesDefault } from "../../../@types/panelTypes";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import { TableContent } from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import {
  Client,
  ClientFilters,
  useClients,
} from "../../../hook/queries/useClients";

interface PanelColorsProps extends panelTypesDefault {}

const columns: FieldProps[] = [
  {
    name: "cod",
    field: "CÓD",
    viewList: true,
  },
  {
    name: "company_name",
    field: "RAZÃO SOCIAL",
    viewList: true,
  },
  {
    name: "cnpj",
    field: "CNPJ",
    viewList: true,
  },
  {
    name: "email",
    field: "EMAIL",
    viewList: true,
  },
];

const PanelClients: React.FC<PanelColorsProps> = ({ seller }) => {
  const history = useHistory();
  const query = useQueryParams();

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });
  const [filters, setFilters] = useState<ClientFilters>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });

  const [viewFilter, setViewFilter] = useState(false);
  const { data, isLoading, isFetching, refetch } = useClients(
    page,
    { ...filters, hasOrder: true },
    15
  );

  const formikFilter = useFormik({
    initialValues: {
      cod: "",
      company_name: "",
      cnpj: "",
    },
    onSubmit: (data) => {
      handleFilter(data);
    },
  });

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  function handleFilter(data: ClientFilters) {
    setFilters(data);
    setPage(1);
    setViewFilter(false);
  }

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de Clientes" />

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
      >
        <GroupInput>
          <Input label="Cód" {...formikFilter.getFieldProps("cod")} />
          <Input
            label="Razão social"
            {...formikFilter.getFieldProps("company_name")}
          />
          <Input label="CNPJ" {...formikFilter.getFieldProps("cnpj")} />
        </GroupInput>
      </PanelFilter>

      <TableHeader title="CLIENTES" isFetching={isFetching && !isLoading}>
        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>
      </TableHeader>

      <TableContent<Client>
        data={data?.clients}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) =>
          history.push(
            seller
              ? `/representante/analise/clientes/${e.id}`
              : `/admin/analise/clientes/${e.id}`
          )
        }
      />
      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={15}
      />
    </PanelLayout>
  );
};

export default PanelClients;
