import styled from "styled-components";

export const Container = styled.div`
  grid-area: APP;
  padding: 10px 30px;

  height: 100%;
  max-height: 100vh;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }
`;
