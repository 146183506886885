import filesize from "filesize";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FiAlertCircle, FiEdit, FiSend } from "react-icons/fi";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { IPermissionUser } from "../../../@types";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { InfoCards } from "../../../components/InfoCards";
import Modal from "../../../components/ModalLib";
import { Tab } from "../../../components/Tab";
import Dropzone from "../../../components/Upload/Dropzone";
import Loading from "../../../components/loadings/Loading";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Seller } from "../../../hook/queries/useSeller";
import api from "../../../service/api";
import { downloadFilaBase64 } from "../../../utils/downloadFilaBase64";
import { generateXlsx } from "../../../utils/generateXlsx";
import selectIcon from "../../../utils/selectIcon";
import {
  ButtunSubmit,
  ContainerDropZone,
  ContainerPreviews,
  Error as ErrorDiv,
  Form,
  InfoDevolution as Info,
  PopUpConfirmation,
} from "../styles";
import { ModalModificationForecast } from "./ModalModificationForecast";
import { PanelClients } from "./PanelClients";
import { PanelSends } from "./PanelSends";

interface HomeProps {
  location: {
    state: {
      content: IForecast;
    };
  };

  match: {
    params: {
      id: number;
    };
  };

  admin?: boolean;
  seller?: boolean;

  is_manager?: boolean;
  is_supervisor?: boolean;
  permission?: IPermissionUser;
}

export interface IForecastData {
  id: number;
  footwear?: number;
  apparel?: number;
  equipment?: number;
  reason?: string;
  finished: boolean;
  isPendecy: boolean;
  finished_at: Date | null;
  forecastId: number;

  client_pj?: {
    cod?: Number;
    cnpj?: String;
    company_name?: String;
    trade_name?: String;
    adresses?: {
      city?: String;
      uf?: String;
    };

    forecastHistoric: {
      historic1: String;
      historic2: String;
      historic3: String;
      historic4: String;
    };
  };

  seller: {
    id: number;
    cod: number;
    abbreviation: string;
  };
}

export interface IForecast {
  id: number;
  title: string;
  status: {
    cod: number;
    name: string;
    color: string;
    finisher: boolean;

    name_seller: string;
    color_seller: string;
    name_manager: string;
    color_manager: string;
  };

  seller: {
    cod: number;
    fullName: string;
    abbreviation: string;
  };

  reasonRefuse?: string;

  finished: boolean;
  finished_at: Date | null;
  observation: string | null;
  review: number;
  closing_at: Date;
  sellerId: number | null;

  sale_completed_qtd: number | null;
  sale_completed_value: number | null;
  sale_completed_markup: number | null;

  branded_purchase_qtd: number | null;
  branded_purchase_value: number | null;

  receipt_products_qtd: number | null;
  receipt_products_value: number | null;

  brand_branded_purchase_qtd: number | null;
  brand_branded_purchase_value: number | null;
  brand_receipt_products_qtd: number | null;
  brand_receipt_products_value: number | null;

  internalObservation: string | null;

  forecast_description?: { id: number; description: string };
  descriptionYear?: string;

  historic1: string;
  historic2: string;
  historic3: string;
  historic4: string;

  collectionStartDate?: Date;
  collectionEndDate?: Date;

  equipment: boolean;
  footwear: boolean;
  apparel: boolean;

  brand: IBrand;

  forecast_data: IForecastData[];

  forecast_seller: {
    id: number;
    finished: boolean;
    finished_at: Date | null;
  };

  sellers?: Seller[];
}

export interface IResponseAPIForecast {
  contents: IForecast;
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IBrand {
  id?: number;
  cod: number;
  idBrand: number;
  name: string;
  is_active: boolean;
}

export interface IShippingAnalysis {
  id: number;

  apparel: number;
  equipment: number;
  footwear: number;
  campaignValue: number;

  clientTotal: number;
  cityTotal: number;

  shippingTotal: number;
  shippingNotSend: number;
  shippingSend: number;

  sale_completed_qtd: number | null;
  sale_completed_value: number | null;

  branded_purchase_qtd: number | null;
  branded_purchase_value: number | null;

  receipt_products_qtd: number | null;
  receipt_products_value: number | null;

  review: number;

  status: {
    cod: number;
    name_manager: string;
    color_manager: string;
    name_seller: string;
    color_seller: string;
    finisher: boolean;
  };

  seller: {
    cod: number;
    abbreviation: string;
  };
}

export interface IResponseAPIShippingAnalysis {
  contents: IShippingAnalysis[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface ITotais {
  totais: number;
  totaisApparel: number;
  totaisEquipment: number;
  totaisFootwear: number;

  totaisClient: number;
  totaisClientFilled: number;
  totaisCity: number;
  ticketMedium: string;

  campaignValue: number;
  totaisCampaign: number;
}

interface IFile {
  id: string;
  name: string;
  size: Number;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype?: string;
}

const Order: React.FC<HomeProps> = ({
  match,
  admin,
  is_manager,
  is_supervisor,
  permission,
}) => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [loadingExportReport, setLoadingExportReport] = useState(false);
  const [loadingInternal, setLoadingInternal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingUploadFiles, setLoadingUploadFiles] = useState<boolean>(false);
  const [loadingPanel, setLoadingPanel] = useState({
    normal: true,
    analysis: true,
  });

  const [contentPage, setContentPage] = useState<IForecast>({} as IForecast);
  const [update, setUpdate] = useState(1);

  const [activeUpdateForecastModal, setActiveUpdateForecastModal] =
    useState(false);

  const [confirmationFinished, setConfirmationFinished] =
    useState<boolean>(false);

  const [confirmationTrashForecast, setConfirmationTrashForecast] =
    useState(false);

  const [confirmationFinishedManager, setConfirmationFinishedManager] =
    useState(false);

  const [updateTotal, setUpdateTotal] = useState("");
  const [selectFile, setSelectFile] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);
  const [listFiles, setListFiles] = useState<IFile[]>([]);

  const [category, setCategory] = useState({
    apparel: false,
    equipment: false,
    footwear: false,
  });
  const [totais, setTotais] = useState<ITotais>({
    totais: 0,
    totaisApparel: 0,
    totaisEquipment: 0,
    totaisFootwear: 0,
    totaisCity: 0,
    totaisClient: 0,
    ticketMedium: "-",
    totaisClientFilled: 0,
    campaignValue: 0,
    totaisCampaign: 0,
  });

  useEffect(() => {
    // setLoading(true);

    (async () => {
      if (Boolean(match.params.id)) {
        try {
          setLoadingPanel({ ...loadingPanel, normal: true });
          if (Boolean(admin) || Boolean(is_manager) || Boolean(is_supervisor)) {
            const forecastDB = await api.get<IForecast>(
              `/forecast/${match.params.id}`
            );
            if (!Boolean(forecastDB.data)) {
              return history.push("/404");
            }

            setContentPage(forecastDB.data);
            const { equipment, footwear, apparel } = forecastDB.data;
            setCategory({ equipment, footwear, apparel });
            setLoadingPanel({ ...loadingPanel, normal: false });

            setLoading(false);
          } else {
            if (!Boolean(is_manager) && !Boolean(is_supervisor)) {
              const forecastDB = await api.get<IResponseAPIForecast>(
                `/forecast/seller/${match.params.id}`
              );

              if (!Boolean(forecastDB.data)) {
                return history.push("/404");
              }

              const { contents } = forecastDB.data;

              setContentPage({
                ...contents,
                forecast_data: [],
              });
              setCategory({
                equipment: contents.equipment,
                footwear: contents.footwear,
                apparel: contents.apparel,
              });

              setLoadingPanel({ ...loadingPanel, normal: false });
              setLoading(false);
            } else {
              return history.push("/404");
            }
          }
        } catch (error) {
          return history.push("/404");
        }
      } else {
        history.push("/404");
      }
    })();
    // eslint-disable-next-line
  }, [match?.params?.id, update]);

  useEffect(() => {
    if (contentPage.forecast_seller) {
      (async () => {
        const forecastTotais = await api.get<any>(
          `/forecast/forecastseller/totais/${contentPage.forecast_seller.id}`
        );

        const {
          totaisApparel,
          totaisEquipment,
          totaisFootwear,
          totais,
          totaisClient,
          totaisCity,
          campaignValue,
          totaisCampaign,
        } = forecastTotais.data;

        setTotais({
          ...totais,
          totais: totais,
          totaisFootwear: totaisFootwear,
          totaisApparel: totaisApparel,
          totaisEquipment: totaisEquipment,
          totaisClient: totaisClient,
          totaisCity: totaisCity,
          campaignValue,
          totaisCampaign,
          ticketMedium:
            totais && totaisClient && totais > totaisClient
              ? (totais / totaisClient).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              : "-",
        });
      })();
    }

    // eslint-disable-next-line
  }, [contentPage.forecast_seller, updateTotal]);

  useEffect(() => {
    if ((is_manager || is_supervisor || admin) && contentPage.id) {
      (async () => {
        const forecastTotais = await api.get<any>(
          `/forecast/totais/${contentPage.id}`
        );

        const {
          totaisApparel,
          totaisEquipment,
          totaisFootwear,
          totais,
          totaisClient,
          totaisCity,
          totaisClientFilled,
          campaignValue,
          totaisCampaign,
        } = forecastTotais.data;

        setTotais({
          ...totais,
          campaignValue,
          totaisCampaign,
          totais: totais,
          totaisFootwear: totaisFootwear,
          totaisApparel: totaisApparel,
          totaisEquipment: totaisEquipment,
          totaisClient: totaisClient,
          totaisCity: totaisCity,
          ticketMedium:
            totais && totaisClient && totais > totaisClient
              ? (totais / totaisClient).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              : "-",
          totaisClientFilled: totaisClientFilled,
        });
      })();

      if (
        (admin && permission && permission.forecastInternal > 1) ||
        is_manager
      ) {
        if (contentPage.sale_completed_qtd)
          formikInternalData.setFieldValue(
            "sale_completed_qtd",
            contentPage.sale_completed_qtd
          );
        if (contentPage.sale_completed_value)
          formikInternalData.setFieldValue(
            "sale_completed_value",
            contentPage.sale_completed_value
          );
        if (contentPage.branded_purchase_qtd)
          formikInternalData.setFieldValue(
            "branded_purchase_qtd",
            contentPage.branded_purchase_qtd
          );
        if (contentPage.branded_purchase_value)
          formikInternalData.setFieldValue(
            "branded_purchase_value",
            contentPage.branded_purchase_value
          );
        if (contentPage.receipt_products_qtd)
          formikInternalData.setFieldValue(
            "receipt_products_qtd",
            contentPage.receipt_products_qtd
          );
        if (contentPage.receipt_products_value)
          formikInternalData.setFieldValue(
            "receipt_products_value",
            contentPage.receipt_products_value
          );
        if (contentPage.brand_branded_purchase_qtd)
          formikInternalData.setFieldValue(
            "brand_branded_purchase_qtd",
            contentPage.brand_branded_purchase_qtd
          );
        if (contentPage.brand_branded_purchase_value)
          formikInternalData.setFieldValue(
            "brand_branded_purchase_value",
            contentPage.brand_branded_purchase_value
          );
        if (contentPage.brand_receipt_products_qtd)
          formikInternalData.setFieldValue(
            "brand_receipt_products_qtd",
            contentPage.brand_receipt_products_qtd
          );
        if (contentPage.brand_receipt_products_value)
          formikInternalData.setFieldValue(
            "brand_receipt_products_value",
            contentPage.brand_receipt_products_value
          );
        if (contentPage.sale_completed_markup)
          formikInternalData.setFieldValue(
            "sale_completed_markup",
            contentPage.sale_completed_markup
          );
        if (contentPage.internalObservation)
          formikInternalData.setFieldValue(
            "internalObservation",
            contentPage.internalObservation
          );
      }
    }
    // eslint-disable-next-line
  }, [contentPage]);

  useEffect(() => {
    (async () => {
      if (
        ((admin && permission && permission.forecastInternal > 1) ||
          is_manager) &&
        contentPage &&
        contentPage.id
      ) {
        const fileDB = await api.get<IFile[]>(
          `/forecast/file/internal/${contentPage.id}`
        );
        setListFiles(fileDB.data);
      }
    })();
  }, [admin, permission, contentPage, is_manager]);

  useEffect(() => {
    if (selectFile) setFiles([...files, selectFile]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFile]);

  const formikInternalData = useFormik({
    validateOnChange: false,
    initialValues: {
      sale_completed_qtd: undefined,
      sale_completed_value: undefined,
      sale_completed_markup: undefined,

      branded_purchase_qtd: undefined,
      branded_purchase_value: undefined,
      receipt_products_qtd: undefined,
      receipt_products_value: undefined,

      brand_receipt_products_qtd: undefined,
      brand_receipt_products_value: undefined,
      brand_branded_purchase_qtd: undefined,
      brand_branded_purchase_value: undefined,

      internalObservation: undefined,
    },

    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      handleSubmitInternal(values);
    },
  });

  function handleInputChangeInternal(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const { name, value } = event.target;

    let data: any;

    if (
      name === "sale_completed_value" ||
      name === "branded_purchase_value" ||
      name === "brand_branded_purchase_value" ||
      name === "brand_receipt_products_value" ||
      name === "receipt_products_value"
    ) {
      data = Number(value.replace(/\D/g, "")) / 100;
    } else {
      if (name === "sale_completed_markup") {
        data = Number(value);
      } else {
        data = value.replace(/\D/g, "");
      }
    }

    formikInternalData.setFieldValue(name, data);
  }

  async function handleDeleteFile(name: string) {
    files.forEach((element: File, index) => {
      if (element.name === name) {
        files.splice(index, 1);
      }
    });

    setFiles([]);

    setTimeout(() => {
      setFiles(files);
    }, 1);
  }

  async function handleExportData() {
    setLoadingExportReport(true);

    try {
      addToast("Gerando relatório...", {
        appearance: "info",
        autoDismiss: true,
      });

      const response =
        !admin && !is_manager && !is_supervisor
          ? await api.post(
              `/report/forecast/seller/${contentPage.forecast_seller.id}`
            )
          : await api.post<{ contents: any[] }>(
              `/report/forecast/${contentPage.id}`
            );

      await generateXlsx({
        data: response.data.contents,
        filename: `Forecast ${contentPage?.forecast_description?.description} ${contentPage.descriptionYear}`,
      });
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingExportReport(false);
    }
  }

  async function handleFinishForecastSeller() {
    setConfirmationFinished(false);

    let arrForecastSeller = contentPage.forecast_data.map((item) => ({
      id: item.id,
      apparel: item.apparel,
      footwear: item.footwear,
      equipment: item.equipment,
    }));

    await api.put(`/forecast/forecastdata/many`, {
      forecastData: arrForecastSeller,
      id: contentPage.id,
    });

    let input_empty = false;

    const finish = await api.get<boolean>(
      `/forecast/seller/finish/${contentPage.forecast_seller.id}`
    );

    input_empty = finish.data;

    if (input_empty) {
      return addToast(
        "Todos campos devem ser preenchidos para finalizar o forecast.",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
    }

    await api.put(
      `/forecast/forecastseller/${contentPage.forecast_seller.id}`,
      { status: 2, finished: true, finished_at: Date.now(), review: 0 }
    );

    addToast("Envio de forecast realizada com sucesso.", {
      appearance: "success",
      autoDismiss: true,
    });

    return history.push("/representante/previsoes");
  }

  async function handleFinishForecast() {
    try {
      setConfirmationFinished(false);
      let error = false;

      const finish = await api.get<boolean>(
        `/forecast/finish/${contentPage.id}`
      );
      error = finish.data;

      if (error) {
        return addToast(
          "Todos forecast devem ser validados para finalizar o forecast.",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }

      await api.put(`/forecast/${contentPage.id}`, {
        status: 5,
        finished: true,
        finished_at: new Date(),
      });

      addToast("Finalização forecast realizada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });

      return history.push("/representante/previsoes");
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleTrashForecast() {
    try {
      await api.delete(`/forecast/${contentPage.id}`);

      addToast("Forecast excluído com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });

      setConfirmationTrashForecast(false);

      return history.push("/admin/previsoes");
    } catch (err) {
      const error = err as any;
      setConfirmationTrashForecast(false);
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.data.message === "filled data") {
        return addToast(
          "Não foi possível realizar exclusão, já possui lançamentos de representante referente a este forecast ",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleSubmitInternal(data: any) {
    setLoadingInternal(true);
    try {
      await api.put(`/forecast/${contentPage.id}`, {
        sale_completed_qtd: Number(data.sale_completed_qtd),
        sale_completed_value: Number(data.sale_completed_value),
        sale_completed_markup: Number(data.sale_completed_markup),
        branded_purchase_qtd: Number(data.branded_purchase_qtd),
        branded_purchase_value: Number(data.branded_purchase_value),
        receipt_products_qtd: Number(data.receipt_products_qtd),
        receipt_products_value: Number(data.receipt_products_value),
        brand_branded_purchase_qtd: Number(data.brand_branded_purchase_qtd),
        brand_branded_purchase_value: Number(data.brand_branded_purchase_value),
        brand_receipt_products_qtd: Number(data.brand_receipt_products_qtd),
        brand_receipt_products_value: Number(data.brand_receipt_products_value),
        internalObservation: data.internalObservation,
      });

      addToast("Alterações realizada com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
      setLoadingInternal(false);
    } catch (error) {
      setLoadingInternal(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleSubmitFiles() {
    setLoadingUploadFiles(true);
    try {
      if (contentPage && contentPage.id && selectFile) {
        const formData = new FormData();
        files.forEach((element) => {
          formData.append("file", element);
        });

        const response = await api.post<IFile[]>(
          `/forecast/file/internal/${contentPage.id}`,
          formData
        );

        setFiles([]);
        setListFiles([...response.data]);
        setLoadingUploadFiles(false);
        addToast("Arquivo(s) anexado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    } catch (error) {
      setLoadingUploadFiles(false);
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleDownloadFile({
    key,
    filename,
    mimetype,
  }: {
    key: string;
    filename: string;
    mimetype: string;
  }) {
    addToast(`Gerando arquivo...`, {
      appearance: "info",
      autoDismiss: true,
      autoDismissTimeout: 10000,
    });

    try {
      const getFileBase64 = await api.post(`forecast/file/getFileBase64`, {
        key: key,
      });

      downloadFilaBase64({
        base64: getFileBase64.data ?? "",
        filename: filename,
        mimetype: mimetype ?? "",
      });
    } catch (error) {
      return addToast(
        "Ocorreu um erro ao gerar arquivo, Tente novamente mais tarde ou entre em contato conosco (51) 3441-1000",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  var infoCards = [
    {
      title: "COLEÇÃO - ANO",
      value: `${contentPage?.forecast_description?.description ?? ""} - ${
        contentPage?.descriptionYear ?? ""
      }`,
    },
    {
      title: "SITUAÇÃO",
      value:
        admin || is_manager || is_supervisor
          ? contentPage?.status?.name
          : contentPage?.status?.name_seller,
      colorLine:
        admin || is_manager || is_supervisor
          ? contentPage?.status?.color
          : contentPage?.status?.color_seller,
    },
    {
      title: "MARCA",
      value: contentPage?.brand?.name ?? "-",
    },
    {
      title: "PREVISÃO ENCERRAMENTO",
      value:
        moment(contentPage?.closing_at).add("days", 1).format("DD/MM/YYYY") ??
        "-",
    },
  ];

  if (
    (admin || is_manager || is_supervisor) &&
    !!contentPage?.collectionStartDate &&
    !!contentPage?.collectionEndDate
  ) {
    infoCards.push({
      title: "INICIO E FIM COLEÇÃO",
      value: `${moment(contentPage?.collectionStartDate)
        .add("days", 1)
        .format("DD/MM/YY")} - ${moment(contentPage?.collectionEndDate)
        .add("days", 1)
        .format("DD/MM/YY")}`,
    });
  }

  return (
    <CreateAndDetailLayout isLoading={loading}>
      <PanelAndDetailAndCreateHeader
        title={"Detalhes do forecast"}
        handleGoBack={() => {
          history.push(admin ? "/admin/previsoes" : "/representante/previsoes");
        }}
      >
        <div className="containerActions">
          {admin && permission && permission.forecast > 2 && (
            <button
              type="button"
              className="buttonAction"
              onClick={() => {
                setActiveUpdateForecastModal(true);
              }}
            >
              <FiEdit color="#8f6e09" />
              <span>Editar</span>
            </button>
          )}

          {admin &&
            permission &&
            permission.forecast > 2 &&
            !!contentPage &&
            !contentPage?.status?.finisher && (
              <button
                type="button"
                className="buttonAction"
                onClick={() => {
                  setConfirmationTrashForecast(true);
                }}
              >
                <FaTrashAlt color="#da0909" />
                <span>Apagar</span>
              </button>
            )}

          <button
            type="button"
            className="buttonAction"
            style={{
              background: "#207245",
              color: "white",
              display: "flex",
            }}
            onClick={() => {
              handleExportData();
            }}
            disabled={loadingExportReport}
          >
            {loadingExportReport ? (
              <Loading
                borderSize={2}
                size={16}
                colorLoading="#207245"
                borderColor="#fff"
              />
            ) : (
              <RiFileExcel2Fill color="white" />
            )}

            <span>Exportar</span>
          </button>
        </div>
      </PanelAndDetailAndCreateHeader>

      <InfoCards cards={infoCards} />

      <>
        {(admin || is_manager || is_supervisor) && (
          <Info>
            <div className="headerDevoltion">
              <h2>Informações</h2>

              {confirmationTrashForecast && (
                <Modal
                  title="Selecione o cliente"
                  modalVisible={confirmationTrashForecast}
                  setModalVisible={setConfirmationTrashForecast}
                  headerOff={true}
                  maxHeight={350}
                  maxWidth={650}
                >
                  <PopUpConfirmation>
                    <div className="headerPopUp">
                      <nav>
                        <FaTrashAlt size={95} opacity={0.2} />
                      </nav>
                      <span>
                        Você tem certeza que deseja apagar o forecast?
                      </span>
                    </div>
                    <div className="buttonsPopUp">
                      <button
                        type="button"
                        onClick={() => setConfirmationTrashForecast(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        style={{
                          background: "#d21e26",
                        }}
                        onClick={handleTrashForecast}
                      >
                        Excluir
                      </button>
                    </div>
                  </PopUpConfirmation>
                </Modal>
              )}
            </div>
            <div className="bodyDevoltion">
              <div className="conatinerInfo">
                <h3>Dados</h3>
                <div className="line" />

                {category.footwear && (
                  <div>
                    <h4>Total por Footwear</h4>
                    <span>
                      {Number(totais.totaisFootwear).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </span>
                  </div>
                )}

                {category.apparel && (
                  <div>
                    <h4>Total por Apparel</h4>
                    <span>
                      {Number(totais.totaisApparel).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </span>
                  </div>
                )}

                {category.equipment && (
                  <div>
                    <h4>Total por Equipment</h4>
                    <span>
                      {Number(totais.totaisEquipment).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </span>
                  </div>
                )}

                <div>
                  <h4>Totais</h4>
                  <span
                    style={{
                      textDecoration:
                        totais.campaignValue > 0 ? "line-through" : undefined,
                      color: totais.campaignValue > 0 ? "#aaa" : undefined,
                    }}
                  >
                    {Number(totais.totais).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                </div>

                {totais.campaignValue > 0 && (
                  <div>
                    <h4>Campanha</h4>
                    <span>
                      {Number(totais.totaisCampaign ?? 0).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </span>
                  </div>
                )}
              </div>

              {!admin &&
                !is_manager &&
                !is_supervisor &&
                contentPage.review === 1 &&
                contentPage.observation && (
                  <div className="conatinerInfo">
                    <h3>Observação</h3>

                    <div className="line" />
                    <span>{contentPage.observation}</span>
                  </div>
                )}

              <div className="conatinerInfo">
                <h3>Análises</h3>
                <div className="line"></div>

                <div>
                  <h4>Qtd total de Cliente</h4>
                  <span>{totais.totaisClient ?? "-"}</span>
                </div>
                <div>
                  <h4>
                    Qtd total de Cliente Preenchidos{" "}
                    <span style={{ color: "#555", fontSize: "0.775rem" }}>
                      (Valor maior 0)
                    </span>
                  </h4>
                  <span>{totais.totaisClientFilled}</span>
                </div>
                <div>
                  <h4>Qtd total de Cidade</h4>
                  <span>{totais.totaisCity ?? "-"}</span>
                </div>
                <div>
                  <h4>Ticket médio por cliente</h4>
                  <span>{totais?.ticketMedium ?? "-"}</span>
                </div>
              </div>

              <div className="conatinerInfo">
                <h3>Gerente responsável</h3>
                <div className="line"></div>

                <div>
                  <span>{`${contentPage?.seller?.fullName} (${contentPage?.seller?.cod})`}</span>
                </div>
              </div>

              {contentPage?.status?.cod === 4 && (
                <div className="conatinerInfo">
                  <h3>Motivo recusa diretor</h3>
                  <div className="line"></div>

                  <div>
                    <span>{contentPage.reasonRefuse}</span>
                  </div>
                </div>
              )}
            </div>
          </Info>
        )}

        {!admin && !is_manager && !is_supervisor && (
          <Info>
            <div className="headerDevoltion">
              <h2>Informações</h2>
            </div>
            <div className="bodyDevoltion">
              <div className="conatinerInfo">
                <h3>Dados</h3>
                <div className="line"></div>

                {category.footwear && (
                  <div>
                    <h4>Total por Footwear</h4>
                    <span>
                      {Number(totais.totaisFootwear).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </span>
                  </div>
                )}
                {category.apparel && (
                  <div>
                    <h4>Total por Apparel</h4>
                    <span>
                      {Number(totais.totaisApparel).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </span>
                  </div>
                )}
                {category.equipment && (
                  <div>
                    <h4>Total por Equipment</h4>
                    <span>
                      {Number(totais.totaisEquipment).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </span>
                  </div>
                )}

                <div>
                  <h4>Totais</h4>
                  <span
                    style={{
                      textDecoration:
                        totais.campaignValue > 0 ? "line-through" : undefined,
                      color: totais.campaignValue > 0 ? "#aaa" : undefined,
                    }}
                  >
                    {Number(totais.totais).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                </div>
                {totais.campaignValue > 0 && (
                  <div>
                    <h4>Campanha</h4>
                    <span>
                      {Number(totais.totaisCampaign ?? 0).toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </span>
                  </div>
                )}
              </div>

              {!admin &&
                !is_manager &&
                !is_supervisor &&
                contentPage.review === 1 &&
                contentPage.observation && (
                  <div className="conatinerInfo">
                    <h3>Observação</h3>

                    <div className="line" />
                    <span>{contentPage.observation}</span>
                  </div>
                )}
            </div>
          </Info>
        )}
      </>

      <Tab
        tabs={[
          {
            description: "Listagem",
            component: (
              <div style={{ background: "white", padding: "1rem" }}>
                {(admin || is_manager || is_supervisor) && (
                  <PanelSends
                    data={{
                      id: contentPage.id,
                      finished: contentPage?.status?.finisher,
                      statusCode: contentPage?.status?.cod ?? 0,
                      brandCode: contentPage?.brand?.cod ?? 0,
                      histories: {
                        historic1: contentPage.historic1,
                        historic2: contentPage.historic2,
                        historic3: contentPage.historic3,
                        historic4: contentPage.historic4,
                      },
                    }}
                    category={category}
                    admin={admin}
                    is_supervisor={is_supervisor}
                    is_manager={is_manager}
                    permission={permission}
                  />
                )}

                {!admin && !is_manager && !is_supervisor && (
                  <>
                    <PanelClients
                      data={{
                        id: contentPage.id,
                        finished: contentPage.finished,

                        historic1: contentPage.historic1,
                        historic2: contentPage.historic2,
                        historic3: contentPage.historic3,
                        historic4: contentPage.historic4,

                        forecast_data: contentPage.forecast_data ?? [],
                        forecast_seller: contentPage.forecast_seller,
                      }}
                      setData={setContentPage}
                      setUpdateTotal={setUpdateTotal}
                      category={category}
                      admin={admin}
                      is_supervisor={is_supervisor}
                      is_manager={is_manager}
                    />
                  </>
                )}
              </div>
            ),
          },
          {
            description: "Informações complementares",
            component: (
              <>
                {((admin && permission && permission.forecastInternal > 1) ||
                  is_manager) && (
                  <>
                    <Info>
                      <div className="bodyDevoltion">
                        <Form
                          onSubmit={
                            !loadingInternal &&
                            permission &&
                            permission.forecastInternal > 2
                              ? formikInternalData.handleSubmit
                              : (e) => e.preventDefault()
                          }
                          style={{ padding: "0.8rem" }}
                        >
                          <h3 style={{ marginBottom: 0, whiteSpace: "normal" }}>
                            Venda Realizada na Coleção (Efetiva)
                          </h3>
                          <div className="line" style={{ marginBottom: 15 }} />
                          <div className="field-group">
                            <div className="field">
                              <label htmlFor="sale_completed_qtd">
                                QUANTIDADE
                              </label>
                              <input
                                readOnly={
                                  permission && permission.forecastInternal > 2
                                    ? false
                                    : true
                                }
                                name="sale_completed_qtd"
                                onChange={handleInputChangeInternal}
                                value={
                                  formikInternalData.values.sale_completed_qtd
                                }
                              />
                              {formikInternalData.touched.sale_completed_qtd &&
                              formikInternalData.errors.sale_completed_qtd ? (
                                <ErrorDiv>
                                  <FiAlertCircle color="#f00" size={16} />
                                  <span>
                                    {
                                      formikInternalData.errors
                                        .sale_completed_qtd
                                    }
                                  </span>
                                </ErrorDiv>
                              ) : null}
                            </div>
                            <div className="field">
                              <label htmlFor="sale_completed_value">
                                VALOR
                              </label>
                              <input
                                readOnly={
                                  permission && permission.forecastInternal > 2
                                    ? false
                                    : true
                                }
                                name="sale_completed_value"
                                onChange={handleInputChangeInternal}
                                value={
                                  formikInternalData.values.sale_completed_value
                                    ? Number(
                                        formikInternalData.values
                                          .sale_completed_value
                                      ).toLocaleString("pt-br", {
                                        style: "currency",
                                        currency: "BRL",
                                      })
                                    : ""
                                }
                              />
                              {formikInternalData.touched
                                .sale_completed_value &&
                              formikInternalData.errors.sale_completed_value ? (
                                <ErrorDiv>
                                  <FiAlertCircle color="#f00" size={16} />
                                  <span>
                                    {
                                      formikInternalData.errors
                                        .sale_completed_value
                                    }
                                  </span>
                                </ErrorDiv>
                              ) : null}
                            </div>
                            <div className="field">
                              <label htmlFor="sale_completed_markup">
                                MARK UP
                              </label>
                              <input
                                readOnly={
                                  permission && permission.forecastInternal > 2
                                    ? false
                                    : true
                                }
                                name="sale_completed_markup"
                                maxLength={5}
                                type="number"
                                onChange={handleInputChangeInternal}
                                value={
                                  formikInternalData.values
                                    .sale_completed_markup
                                }
                              />
                              {formikInternalData.touched
                                .sale_completed_markup &&
                              formikInternalData.errors
                                .sale_completed_markup ? (
                                <ErrorDiv>
                                  <FiAlertCircle color="#f00" size={16} />
                                  <span>
                                    {
                                      formikInternalData.errors
                                        .sale_completed_markup
                                    }
                                  </span>
                                </ErrorDiv>
                              ) : null}
                            </div>
                          </div>

                          <div className="field-group">
                            <div className="field">
                              <h3
                                style={{
                                  marginBottom: 0,
                                  whiteSpace: "normal",
                                }}
                              >
                                Intenção de Compra (compra enviada pela Alpar)
                              </h3>
                              <div
                                className="line"
                                style={{ marginBottom: 15 }}
                              />
                              <div className="field-group">
                                <div className="field">
                                  <label htmlFor="branded_purchase_qtd">
                                    Quantidade Comprada
                                  </label>
                                  <input
                                    readOnly={
                                      permission &&
                                      permission.forecastInternal > 2
                                        ? false
                                        : true
                                    }
                                    name="branded_purchase_qtd"
                                    onChange={handleInputChangeInternal}
                                    value={
                                      formikInternalData.values
                                        .branded_purchase_qtd
                                    }
                                  />
                                  {formikInternalData.touched
                                    .branded_purchase_qtd &&
                                  formikInternalData.errors
                                    .branded_purchase_qtd ? (
                                    <ErrorDiv>
                                      <FiAlertCircle color="#f00" size={16} />
                                      <span>
                                        {
                                          formikInternalData.errors
                                            .branded_purchase_qtd
                                        }
                                      </span>
                                    </ErrorDiv>
                                  ) : null}
                                </div>
                                <div className="field">
                                  <label htmlFor="branded_purchase_value">
                                    Valor Comprado
                                  </label>
                                  <input
                                    name="branded_purchase_value"
                                    readOnly={
                                      permission &&
                                      permission.forecastInternal > 2
                                        ? false
                                        : true
                                    }
                                    onChange={handleInputChangeInternal}
                                    value={
                                      formikInternalData.values
                                        .branded_purchase_value
                                        ? Number(
                                            formikInternalData.values
                                              .branded_purchase_value
                                          ).toLocaleString("pt-br", {
                                            style: "currency",
                                            currency: "BRL",
                                          })
                                        : ""
                                    }
                                  />
                                  {formikInternalData.touched
                                    .branded_purchase_value &&
                                  formikInternalData.errors
                                    .branded_purchase_value ? (
                                    <ErrorDiv>
                                      <FiAlertCircle color="#f00" size={16} />
                                      <span>
                                        {
                                          formikInternalData.errors
                                            .branded_purchase_value
                                        }
                                      </span>
                                    </ErrorDiv>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div className="field">
                              <h3
                                style={{
                                  marginBottom: 0,
                                  whiteSpace: "normal",
                                }}
                              >
                                Compra Confirmada (Order Confirmation)
                              </h3>
                              <div
                                className="line"
                                style={{ marginBottom: 15 }}
                              />
                              <div className="field-group">
                                <div className="field">
                                  <label htmlFor="receipt_products_qtd">
                                    Quantidade Confirmada
                                  </label>
                                  <input
                                    readOnly={
                                      permission &&
                                      permission.forecastInternal > 2
                                        ? false
                                        : true
                                    }
                                    name="receipt_products_qtd"
                                    onChange={handleInputChangeInternal}
                                    value={
                                      formikInternalData.values
                                        .receipt_products_qtd
                                    }
                                  />
                                  {formikInternalData.touched
                                    .receipt_products_qtd &&
                                  formikInternalData.errors
                                    .receipt_products_qtd ? (
                                    <ErrorDiv>
                                      <FiAlertCircle color="#f00" size={16} />
                                      <span>
                                        {
                                          formikInternalData.errors
                                            .receipt_products_qtd
                                        }
                                      </span>
                                    </ErrorDiv>
                                  ) : null}
                                </div>
                                <div className="field">
                                  <label htmlFor="receipt_products_value">
                                    Valor Confirmado
                                  </label>
                                  <input
                                    readOnly={
                                      permission &&
                                      permission.forecastInternal > 2
                                        ? false
                                        : true
                                    }
                                    name="receipt_products_value"
                                    onChange={handleInputChangeInternal}
                                    value={
                                      formikInternalData.values
                                        .receipt_products_value
                                        ? Number(
                                            formikInternalData.values
                                              .receipt_products_value
                                          ).toLocaleString("pt-br", {
                                            style: "currency",
                                            currency: "BRL",
                                          })
                                        : ""
                                    }
                                  />
                                  {formikInternalData.touched
                                    .receipt_products_value &&
                                  formikInternalData.errors
                                    .receipt_products_value ? (
                                    <ErrorDiv>
                                      <FiAlertCircle color="#f00" size={16} />
                                      <span>
                                        {
                                          formikInternalData.errors
                                            .receipt_products_value
                                        }
                                      </span>
                                    </ErrorDiv>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="field">
                            <h3
                              style={{ marginBottom: 0, whiteSpace: "normal" }}
                            >
                              Cancelamento/Drop de Compra
                            </h3>
                            <div
                              className="line"
                              style={{ marginBottom: 15 }}
                            />
                            <div className="field-group">
                              <div className="field">
                                <label htmlFor="receipt_products_qtd">
                                  Quantidade Confirmada
                                </label>
                                <input
                                  style={{ borderStyle: "dashed" }}
                                  readOnly={true}
                                  value={
                                    formikInternalData.values
                                      .branded_purchase_qtd &&
                                    formikInternalData.values
                                      .receipt_products_qtd
                                      ? Number(
                                          formikInternalData.values
                                            .branded_purchase_qtd
                                        ) -
                                        Number(
                                          formikInternalData.values
                                            .receipt_products_qtd
                                        )
                                      : "-"
                                  }
                                />
                              </div>
                              <div className="field">
                                <label htmlFor="receipt_products_value">
                                  Valor Confirmado
                                </label>
                                <input
                                  style={{ borderStyle: "dashed" }}
                                  readOnly={true}
                                  value={
                                    formikInternalData.values
                                      .branded_purchase_value &&
                                    formikInternalData.values
                                      .receipt_products_value
                                      ? Number(
                                          Number(
                                            formikInternalData.values
                                              .branded_purchase_value
                                          ) -
                                            Number(
                                              formikInternalData.values
                                                .receipt_products_value
                                            )
                                        ).toLocaleString("pt-br", {
                                          style: "currency",
                                          currency: "BRL",
                                        })
                                      : "-"
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="field-group">
                            <div className="field">
                              <h3 style={{ marginBottom: 0 }}>Entrega</h3>
                              <div
                                className="line"
                                style={{ marginBottom: 15 }}
                              />
                              <div className="field-group">
                                <div className="field">
                                  <label htmlFor="brand_branded_purchase_qtd">
                                    Quantidade Entregue
                                  </label>
                                  <input
                                    readOnly={
                                      permission &&
                                      permission.forecastInternal > 2
                                        ? false
                                        : true
                                    }
                                    name="brand_branded_purchase_qtd"
                                    onChange={handleInputChangeInternal}
                                    value={
                                      formikInternalData.values
                                        .brand_branded_purchase_qtd
                                    }
                                  />
                                  {formikInternalData.touched
                                    .brand_branded_purchase_qtd &&
                                  formikInternalData.errors
                                    .brand_branded_purchase_qtd ? (
                                    <ErrorDiv>
                                      <FiAlertCircle color="#f00" size={16} />
                                      <span>
                                        {
                                          formikInternalData.errors
                                            .brand_branded_purchase_qtd
                                        }
                                      </span>
                                    </ErrorDiv>
                                  ) : null}
                                </div>
                                <div className="field">
                                  <label htmlFor="brand_branded_purchase_value">
                                    Valor Entregue
                                  </label>
                                  <input
                                    readOnly={
                                      permission &&
                                      permission.forecastInternal > 2
                                        ? false
                                        : true
                                    }
                                    name="brand_branded_purchase_value"
                                    onChange={handleInputChangeInternal}
                                    value={
                                      formikInternalData.values
                                        .brand_branded_purchase_value
                                        ? Number(
                                            formikInternalData.values
                                              .brand_branded_purchase_value
                                          ).toLocaleString("pt-br", {
                                            style: "currency",
                                            currency: "BRL",
                                          })
                                        : ""
                                    }
                                  />
                                  {formikInternalData.touched
                                    .brand_branded_purchase_value &&
                                  formikInternalData.errors
                                    .brand_branded_purchase_value ? (
                                    <ErrorDiv>
                                      <FiAlertCircle color="#f00" size={16} />
                                      <span>
                                        {
                                          formikInternalData.errors
                                            .brand_branded_purchase_value
                                        }
                                      </span>
                                    </ErrorDiv>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div className="field">
                              <h3 style={{ marginBottom: 0 }}>
                                Quebra de Entrega
                              </h3>
                              <div
                                className="line"
                                style={{ marginBottom: 15 }}
                              />
                              <div className="field-group">
                                <div className="field">
                                  <label htmlFor="brand_receipt_products_qtd">
                                    Quantidade não entregue
                                  </label>
                                  <input
                                    readOnly={
                                      permission &&
                                      permission.forecastInternal > 2
                                        ? false
                                        : true
                                    }
                                    name="brand_receipt_products_qtd"
                                    onChange={handleInputChangeInternal}
                                    value={
                                      formikInternalData.values
                                        .brand_receipt_products_qtd
                                    }
                                  />
                                  {formikInternalData.touched
                                    .brand_receipt_products_qtd &&
                                  formikInternalData.errors
                                    .brand_receipt_products_qtd ? (
                                    <ErrorDiv>
                                      <FiAlertCircle color="#f00" size={16} />
                                      <span>
                                        {
                                          formikInternalData.errors
                                            .brand_receipt_products_qtd
                                        }
                                      </span>
                                    </ErrorDiv>
                                  ) : null}
                                </div>
                                <div className="field">
                                  <label htmlFor="brand_receipt_products_value">
                                    Valor não entregue
                                  </label>
                                  <input
                                    readOnly={
                                      permission &&
                                      permission.forecastInternal > 2
                                        ? false
                                        : true
                                    }
                                    name="brand_receipt_products_value"
                                    onChange={handleInputChangeInternal}
                                    value={
                                      formikInternalData.values
                                        .brand_receipt_products_value
                                        ? Number(
                                            formikInternalData.values
                                              .brand_receipt_products_value
                                          ).toLocaleString("pt-br", {
                                            style: "currency",
                                            currency: "BRL",
                                          })
                                        : ""
                                    }
                                  />
                                  {formikInternalData.touched
                                    .brand_receipt_products_value &&
                                  formikInternalData.errors
                                    .brand_receipt_products_value ? (
                                    <ErrorDiv>
                                      <FiAlertCircle color="#f00" size={16} />
                                      <span>
                                        {
                                          formikInternalData.errors
                                            .brand_receipt_products_value
                                        }
                                      </span>
                                    </ErrorDiv>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <h3 style={{ marginBottom: 0 }}>
                            Observação interna
                          </h3>
                          <div className="line" style={{ marginBottom: 15 }} />
                          <div className="field">
                            <textarea
                              readOnly={
                                permission && permission.forecastInternal > 2
                                  ? false
                                  : true
                              }
                              id="internalObservation"
                              {...formikInternalData.getFieldProps(
                                "internalObservation"
                              )}
                            ></textarea>
                          </div>

                          {permission && permission.forecastInternal > 2 && (
                            <div className="containerButton">
                              <ButtunSubmit type="submit">
                                {!loadingInternal ? (
                                  "Salvar alterações"
                                ) : (
                                  <div
                                    style={{
                                      flex: 1,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Loading
                                      colorLoading="#fff"
                                      size={20}
                                      borderSize={2}
                                    />
                                  </div>
                                )}
                              </ButtunSubmit>
                            </div>
                          )}
                        </Form>
                      </div>
                    </Info>

                    <Info style={{ boxShadow: "none" }}>
                      <Info>
                        <div className="headerDevoltion">
                          <h2>Arquivos</h2>
                        </div>
                        <div className="bodyDevoltion">
                          <ContainerPreviews
                            style={{
                              overflowY: "auto",
                              maxHeight: "40vh",
                            }}
                          >
                            {/* {listFiles.map((item) => (
                        <p>{item.name}</p>
                      ))} */}

                            {listFiles.map((file, index) => (
                              <li
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleDownloadFile({
                                    filename: file.name,
                                    key: file.key ?? "",
                                    mimetype: file.mimetype ?? "",
                                  })
                                }
                              >
                                <img
                                  src={
                                    selectIcon(file.mimetype)
                                      ? selectIcon(file.mimetype)
                                      : "https://alpar.sfo3.digitaloceanspaces.com/Alpar/image-icon.png"
                                  }
                                  alt="_"
                                />
                                <div className="fileInfo">
                                  <div>
                                    <strong>{file.name}</strong>
                                    <span>
                                      {isNaN(Number(file.size))
                                        ? filesize(0)
                                        : filesize(Number(file.size))}
                                    </span>
                                  </div>
                                  {/* <button
                              type="button"
                              onClick={() => {
                                handleDeleteFile(file.name);
                              }}
                            >
                              Excluir
                            </button> */}
                                </div>
                              </li>
                            ))}
                          </ContainerPreviews>
                        </div>
                      </Info>
                      {admin && permission && permission.forecastInternal > 2 && (
                        <Info>
                          <div className="headerDevoltion">
                            <h2>Anexar Arquivos</h2>
                          </div>
                          <div className="bodyDevoltion">
                            <ContainerDropZone style={{ maxHeight: "40vh" }}>
                              <Dropzone
                                accept={[
                                  "image/*",
                                  "application/pdf",
                                  "application/vnd.ms-excel",
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                ]}
                                onFileUploaded={(e) => setSelectFile(e)}
                              />

                              <ContainerPreviews
                                style={{
                                  overflowY: "auto",
                                  maxHeight: "80%",
                                }}
                              >
                                {files.map((file, index) => (
                                  <li key={index}>
                                    <img
                                      src={
                                        selectIcon(file.type)
                                          ? selectIcon(file.type)
                                          : URL.createObjectURL(file)
                                      }
                                      alt="_"
                                    />
                                    <div className="fileInfo">
                                      <div>
                                        <strong>{file.name}</strong>
                                        <span>{filesize(file.size)}</span>
                                      </div>
                                      <button
                                        type="button"
                                        onClick={() => {
                                          handleDeleteFile(file.name);
                                        }}
                                      >
                                        Excluir
                                      </button>
                                    </div>
                                  </li>
                                ))}
                              </ContainerPreviews>
                            </ContainerDropZone>

                            <div className="field" style={{ flex: 1 }}>
                              <ButtunSubmit onClick={handleSubmitFiles}>
                                {loadingUploadFiles ? (
                                  <Loading
                                    size={22}
                                    borderSize={4}
                                    colorLoading="rgba(255,255,255)"
                                    borderColor="rgba(255,255,255, 0.3)"
                                  />
                                ) : (
                                  "Enviar anexos"
                                )}
                              </ButtunSubmit>
                            </div>
                          </div>
                        </Info>
                      )}
                    </Info>
                  </>
                )}
              </>
            ),
          },
        ].filter((f) =>
          (admin && permission && permission.forecastInternal > 1) || is_manager
            ? true
            : f.description !== "Informações complementares"
        )}
      />

      <>
        {confirmationFinishedManager && (
          <Modal
            title="Selecione o cliente"
            modalVisible={confirmationFinishedManager}
            setModalVisible={setConfirmationFinishedManager}
            headerOff={true}
            maxHeight={350}
            maxWidth={650}
          >
            <PopUpConfirmation>
              <div className="headerPopUp">
                <nav>
                  <FiSend size={95} opacity={0.2} />
                </nav>
                <span>
                  Você tem certeza que deseja finalizar o forecast? Após
                  finalização os dados não poderão ser alterados.
                </span>
              </div>
              <div className="buttonsPopUp">
                <button
                  type="button"
                  onClick={() => setConfirmationFinishedManager(false)}
                >
                  Cancelar
                </button>
                <button type="button" onClick={handleFinishForecast}>
                  Finalizar
                </button>
              </div>
            </PopUpConfirmation>
          </Modal>
        )}

        <Modal
          title="Editar Forecast"
          modalVisible={activeUpdateForecastModal}
          setModalVisible={setActiveUpdateForecastModal}
          maxWidth={800}
          scrollOff={true}
          maxHeight={450}
        >
          <ModalModificationForecast
            type="update"
            data={contentPage}
            onRequestClose={() => {
              setUpdate(update + 2);
              setActiveUpdateForecastModal(false);
            }}
          />
        </Modal>

        {confirmationFinished && (
          <Modal
            title="Selecione o cliente"
            modalVisible={confirmationFinished}
            setModalVisible={setConfirmationFinished}
            headerOff={true}
            maxHeight={350}
            maxWidth={650}
          >
            <PopUpConfirmation>
              <div className="headerPopUp">
                <nav>
                  <FiSend size={95} opacity={0.2} />
                </nav>
                <span>
                  Você tem certeza que deseja finalizar o forecast? Após o envio
                  os dados não poderão ser alterados.
                </span>
              </div>
              <div className="buttonsPopUp">
                <button
                  type="button"
                  onClick={() => setConfirmationFinished(false)}
                >
                  Cancelar
                </button>
                <button type="button" onClick={handleFinishForecastSeller}>
                  Finalizar
                </button>
              </div>
            </PopUpConfirmation>
          </Modal>
        )}
      </>
    </CreateAndDetailLayout>
  );
};

export default Order;
