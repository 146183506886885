import React from "react";
import { Link } from "react-router-dom";
import { BoxAnalyticCharArea } from "../../components/Analytic/BoxAnalyticCharArea";
import { BoxAnalyticCharBar } from "../../components/Analytic/BoxAnalyticCharBar";
import { BoxAnalyticCount } from "../../components/Analytic/BoxAnalyticCount";
import { useBilletsCountClient } from "../../hook/queries/useBillet";
import { useDevolutionsCountClient } from "../../hook/queries/useDevolution";
import {
  statusColorAnalytic,
  useOrderAnalytic,
} from "../../hook/queries/useOrderAnalytic";
import { ContainerBoxAnalytic } from "../analytic/styles";
import { HomeAnalyticSeller } from "./HomeAnalyticSeller";

interface HomeAnalyticProps {
  userType: "admin" | "client" | "seller" | "sellerManager";
  clientId?: number;
  userPermission?: string;
}

export const HomeAnalytic = ({ userType, clientId }: HomeAnalyticProps) => {
  const totalBilletClient = useBilletsCountClient(clientId);
  const totalDevolutionClient = useDevolutionsCountClient(clientId, {
    pendencyType: 1,
  });
  const orderAnalytic = useOrderAnalytic({
    seller: ["seller", "sellerManager"].includes(userType),
    client: userType === "client",
  });

  if (["admin"].includes(userType)) return null;

  return (
    <>
      {["seller", "sellerManager"].includes(userType) && (
        <HomeAnalyticSeller userType={userType} />
      )}

      {["client"].includes(userType) && (
        <ContainerBoxAnalytic style={{ marginTop: 24, marginBottom: 0 }}>
          <BoxAnalyticCount
            description="Devoluções Pendentes"
            qtd={totalDevolutionClient.data?.totalCount ?? 0}
            isLoading={totalDevolutionClient.isLoading}
            externalLink={`/devolucoes`}
          />

          <BoxAnalyticCount
            description="Boletos em Aberto"
            qtd={totalBilletClient.data?.totalCount ?? 0}
            isLoading={totalBilletClient.isLoading}
            externalLink="/boletos"
            obs="Boletos em aberto referente á 15 dias antecedente ao dia de hoje."
          />
        </ContainerBoxAnalytic>
      )}

      {["seller", "client", "sellerManager"].includes(userType) && (
        <ContainerBoxAnalytic style={{ marginTop: 9 }}>
          <BoxAnalyticCharBar
            title="Pedidos por Situação"
            descriptions={orderAnalytic?.data?.totalAmount.description ?? []}
            values={orderAnalytic?.data?.totalAmount.amount ?? []}
            colors={orderAnalytic?.data?.totalAmount.description.map((s) => {
              return statusColorAnalytic.find((f) => f.name === s)?.color ?? "";
            })}
            obs={
              ["seller", "sellerManager"].includes(userType) ? (
                <span className="span-alert">
                  *Pedidos{" "}
                  <Link
                    to={
                      "/representante/pedidos/situacao/pedidos?page=1&status=Recusado"
                    }
                  >
                    RECUSADOS
                  </Link>{" "}
                  são cancelados em ate 4 dias.
                </span>
              ) : undefined
            }
          />

          <BoxAnalyticCharArea
            title="Valores dos Itens de pedidos"
            dates={orderAnalytic?.data?.totalPriceMonths.date ?? []}
            values={orderAnalytic?.data?.totalPriceMonths.content ?? []}
            colors={orderAnalytic?.data?.totalPriceMonths.content.map((s) => {
              return (
                statusColorAnalytic.find((f) => f.name === s.name)?.color ?? ""
              );
            })}
            obs={
              <>
                Range 7 meses de pedidos utilizando as datas de
                Faturamento/Cancelamento de acordo com situação do pedido.
              </>
            }
          />
        </ContainerBoxAnalytic>
      )}
    </>
  );
};
