export default function calculateSla(
  created_at: Date,
  closingDate: Date | null
) {
  let percentage = 0;
  let firstDateMilissegundos: number, closingDateMilissegundos: number;

  firstDateMilissegundos = new Date(created_at).getTime();
  closingDateMilissegundos =
    Boolean(closingDate) || closingDate !== null
      ? new Date(String(closingDate)).getTime()
      : new Date().getTime();

  // Transforme 1 dia em milissegundos
  let oneDayMilissegundos = 1000 * 60 * 60 * 24;

  // Calcule a diferença em milissegundos
  let diferencaMilissegundos =
    firstDateMilissegundos - closingDateMilissegundos;

  // Converta novamente para data
  let diferencaData =
    Math.round(diferencaMilissegundos / oneDayMilissegundos) * -1;

  percentage = Math.round((diferencaData * 100) / 30);

  return percentage;
}
