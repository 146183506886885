import React, { useState, useEffect } from "react";
import { Container, ContainerImages, LabelImage } from "./styles";

import NoImage from "../../assets/no-image.jpg";

interface ViewImageProductsProps {
  data: ImageProps[];
}

export interface ImageProps {
  id: number;
  name: string;
  url: string;
}

export const ViewImageProducts: React.FC<ViewImageProductsProps> = ({
  data,
}) => {
  const [images, setImages] = useState<ImageProps[]>([]);
  const [imageSelect, setImageSelect] = useState<String>();
  const [noImage, setNoImage] = useState<boolean>(false);

  useEffect(() => {
    if (data.length <= 0) {
      setNoImage(true);
      setImages([{ id: 1, name: "Sem Imagem", url: "" }]);
      setImageSelect("1");
    } else {
      setNoImage(false);
      setImages(data);
      setImageSelect(String(data[0].id));
    }
  }, [data]);

  return (
    <Container>
      <ContainerImages>
        <div className="labelImages">
          {images.map((image, index) => (
            <span key={index}>
              <label
                onClick={() => {
                  setImageSelect(String(image.id));
                }}
                htmlFor={image.name}
              >
                <div>
                  <LabelImage
                    isActiveImg={
                      String(imageSelect) === String(image.id) ? true : false
                    }
                    src={noImage ? NoImage : image.url}
                    alt={image.name}
                  />
                </div>
              </label>
            </span>
          ))}
        </div>

        {images.map((image, index) => (
          <span key={index}>
            <figure>
              <img
                style={{
                  opacity: String(imageSelect) === String(image.id) ? 1 : 0,
                }}
                src={noImage ? NoImage : image.url}
                alt={image.name}
              />
            </figure>
          </span>
        ))}
      </ContainerImages>
    </Container>
  );
};
