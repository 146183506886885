import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useFormik } from "formik";
import * as Yup from "yup";

import entity from "../../../utils/entityDevolution";

import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { IPermissionUser } from "../../../@types";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Select } from "../../../components/Form/Select";
import api from "../../../service/api";
import {
  ButtunSubmit,
  Container,
  ContainerCheck,
  ContainerForm,
  Error,
  Form,
} from "../styles";

interface HomeProps {
  location: {
    state: {
      brand: IStatus;
    };
  };

  permission: IPermissionUser;
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  approval: boolean;
  is_active: boolean;
  exclusive: string;
  valid: string;
  limitDays: number;
  responsable: number;
  user: number;
  responsable_permission_id: number;
  user_permission_id: number;
  is_licensed: boolean;
  is_distribution: boolean;
}

interface IPermission {
  id?: string;
  name?: string;
}

const User: React.FC<HomeProps> = ({ location, permission }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const {
    id,
    cod,
    name,
    color,
    finisher,
    is_active,
    exclusive,
    valid,
    limitDays,
    responsable,
    user,
    approval,
    responsable_permission_id,
    user_permission_id,
    is_licensed,
    is_distribution,
  } = location.state.brand;

  const [listPermission, setListPermission] = useState<IPermission[]>([]);

  const formik = useFormik({
    initialValues: {
      id: id,
      cod: cod,
      name: name,
      valid: valid,
      limitDays: limitDays,
      responsable: responsable,
      user: user,
      responsable_permission_id: responsable_permission_id,
      user_permission_id: user_permission_id,
      color: color,
      finisher: finisher,
      approval: approval,
      is_active: is_active,
      exclusive: exclusive,
      is_licensed: is_licensed ? "1" : "0",
      is_distribution: is_distribution ? "1" : "0",
    },
    validationSchema: Yup.object({
      cod: Yup.string().required("Código é obrigatório"),
      name: Yup.string().required("Descrição é obrigatório"),
      color: Yup.string().required("Cor é obrigatório"),
      limitDays: Yup.string().required("Limite de dias é obrigatório"),
      responsable: Yup.string().required("Responsável é obrigatório"),
      user: Yup.string().required("Utilizador é obrigatório"),
      responsable_permission_id: Yup.number()
        .nullable()
        .test(
          "is_Valid",
          "Permissão do responsável é obrigatório",
          async function (value) {
            let error = true;

            if (+formik.values.responsable === 2) {
              if (value === undefined || value === null) {
                error = false;
              }
            }

            return error;
          }
        ),
      user_permission_id: Yup.number()
        .nullable()
        .test(
          "is_Valid",
          "Permissão do utilizador é obrigatório",
          async function (value) {
            let error = true;

            console.log(value);

            if (+formik.values.user === 2) {
              if (value === undefined || value === null) {
                error = false;
              }
            }

            return error;
          }
        ),
    }),
    onSubmit: (data) =>
      handleSubmit({
        ...data,
        is_distribution: Boolean(Number(data.is_distribution)) ? true : false,
        is_licensed: Boolean(Number(data.is_licensed)) ? true : false,
      }),
  });

  useEffect(() => {
    (async () => {
      const permissionDB = await api.get("/populate/permission");
      setListPermission(permissionDB.data);
    })();
  }, []);

  async function handleSubmit(updates: IStatus) {
    try {
      await api.put(`/devolution/status/${updates.id}`, updates);
      addToast("Status de Devolução alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/status/devolucoes");
    } catch (err) {
      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.data.error === "cannot be changed") {
        return addToast("Status não pode ser alterado", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este e-mail já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>
        <h2>Status da Devolução</h2>
      </header>

      <ContainerForm>
        <Form
          onSubmit={
            permission && permission.config && permission.config > 2
              ? formik.handleSubmit
              : () => {}
          }
        >
          <div className="field">
            <label htmlFor="COD">Código</label>
            <input
              type="text"
              className="id"
              {...formik.getFieldProps("cod")}
              readOnly={true}
            />
          </div>
          <div className="field">
            <label htmlFor="name">Descrição</label>
            <input id="name" {...formik.getFieldProps("name")} />
            {formik.touched.name && formik.errors.name ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formik.errors.name} </span>
              </Error>
            ) : null}
          </div>

          <div className="field">
            <label htmlFor="color">Cor de fundo</label>
            <input id="color" {...formik.getFieldProps("color")} />
            {formik.touched.color && formik.errors.color ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formik.errors.color} </span>
              </Error>
            ) : null}
          </div>

          <div className="field">
            <label htmlFor="limitDays">Limite de dias</label>
            <input id="limitDays" {...formik.getFieldProps("limitDays")} />
            {formik.touched.limitDays && formik.errors.limitDays ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formik.errors.limitDays} </span>
              </Error>
            ) : null}
          </div>

          <div className="field-group">
            <div className="field">
              <label htmlFor="responsable">Responsável</label>

              <select {...formik.getFieldProps("responsable")}>
                {entity.map((item, i) => (
                  <option key={i} value={Number(item.id)}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            {+formik.values.responsable === 2 && (
              <div className="field">
                <label htmlFor="responsable_permission_id">
                  Permissão do responsável
                </label>

                <select {...formik.getFieldProps("responsable_permission_id")}>
                  <option value="">Selecionar responsável</option>
                  {listPermission.map((item, i) => (
                    <option key={i} value={Number(item.id)}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {formik.touched.responsable_permission_id &&
                formik.errors.responsable_permission_id ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.responsable_permission_id} </span>
                  </Error>
                ) : null}
              </div>
            )}
          </div>
          <div className="field-group">
            <div className="field">
              <label htmlFor="user">Utilizador</label>

              <select {...formik.getFieldProps("user")}>
                {entity.map((item, i) => (
                  <option key={i} value={Number(item.id)}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            {+formik.values.user === 2 && (
              <div className="field">
                <label htmlFor="user_permission_id">
                  Permissão do utilizador
                </label>

                <select {...formik.getFieldProps("user_permission_id")}>
                  <option value="">Selecionar utilizador</option>
                  {listPermission.map((item, i) => (
                    <option key={i} value={Number(item.id)}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {formik.touched.user_permission_id &&
                formik.errors.user_permission_id ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.user_permission_id} </span>
                  </Error>
                ) : null}
              </div>
            )}
          </div>

          <GroupInput>
            <Select
              data={[
                { name: "SIM", value: "1" },
                { name: "NÃO", value: "0" },
              ]}
              label="É Licenciamento"
              {...formik.getFieldProps("is_licensed")}
              error={
                formik.touched.is_licensed && formik.errors.is_licensed
                  ? formik.errors.is_licensed
                  : undefined
              }
            />
            <Select
              data={[
                { name: "SIM", value: "1" },
                { name: "NÃO", value: "0" },
              ]}
              label="É Distribuição"
              {...formik.getFieldProps("is_distribution")}
              error={
                formik.touched.is_distribution && formik.errors.is_distribution
                  ? formik.errors.is_distribution
                  : undefined
              }
            />
          </GroupInput>

          <div className="field">
            <label htmlFor="name">Exclusivo</label>
            <ContainerCheck>
              <button
                type="button"
                className={
                  formik.values.exclusive === "devolução" ? "check" : ""
                }
                onClick={() => {
                  if (formik.values.exclusive === "devolução") {
                    formik.setFieldValue("exclusive", " ");
                  } else {
                    formik.setFieldValue("exclusive", "devolução");
                  }
                }}
              >
                Devolução
              </button>

              <button
                type="button"
                className={formik.values.exclusive === "recusa" ? "check" : ""}
                onClick={() => {
                  if (formik.values.exclusive === "recusa") {
                    formik.setFieldValue("exclusive", " ");
                  } else {
                    formik.setFieldValue("exclusive", "recusa");
                  }
                }}
              >
                Recusa
              </button>
            </ContainerCheck>
          </div>

          <div className="field">
            <label htmlFor="name">Solicitação valida</label>
            <ContainerCheck>
              <button
                type="button"
                className={formik.values.valid ? "check" : ""}
                onClick={() => formik.setFieldValue("valid", true)}
              >
                SIM
              </button>

              <button
                type="button"
                className={!formik.values.valid ? "check" : ""}
                onClick={() => formik.setFieldValue("valid", false)}
              >
                NÃO
              </button>
            </ContainerCheck>
          </div>

          <div className="field">
            <label htmlFor="name">Aprovação</label>
            <ContainerCheck>
              <button
                type="button"
                className={formik.values.approval ? "check" : ""}
                onClick={() => formik.setFieldValue("approval", true)}
              >
                SIM
              </button>

              <button
                type="button"
                className={!formik.values.approval ? "check" : ""}
                onClick={() => formik.setFieldValue("approval", false)}
              >
                NÃO
              </button>
            </ContainerCheck>
          </div>

          <div className="field">
            <label htmlFor="name">Finalização</label>
            <ContainerCheck>
              <button
                type="button"
                className={formik.values.finisher ? "check" : ""}
                onClick={() => formik.setFieldValue("finisher", true)}
              >
                SIM
              </button>

              <button
                type="button"
                className={!formik.values.finisher ? "check" : ""}
                onClick={() => formik.setFieldValue("finisher", false)}
              >
                NÃO
              </button>
            </ContainerCheck>
          </div>

          <div className="field">
            <label htmlFor="name">Situação</label>
            <ContainerCheck>
              <button
                type="button"
                className={formik.values.is_active ? "check" : ""}
                onClick={() => formik.setFieldValue("is_active", true)}
              >
                Ativo
              </button>

              <button
                type="button"
                className={!formik.values.is_active ? "check" : ""}
                onClick={() => formik.setFieldValue("is_active", false)}
              >
                Inativo
              </button>
            </ContainerCheck>
          </div>

          {permission && permission.config && permission.config > 2 && (
            <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
          )}
        </Form>
      </ContainerForm>
    </Container>
  );
};

export default User;
