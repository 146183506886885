import React, { memo, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
import { ColorAndCategoryProps } from "../..";
import { ButtunSubmit, Error, Form } from "../../../../pages/create/styles";
import { DatasheetVariationProps } from "../../../../pages/detail/Datasheet";
import { Select } from "../../../Form/Select";
import Loading from "../../../loadings/Loading";
import { Container } from "./styles";

interface FormModificationVariationProps {
  setData: React.Dispatch<React.SetStateAction<DatasheetVariationProps[]>>;
  colors: ColorAndCategoryProps[];
  closeModal: () => void;
  alternativeCode?: string;

  type: "create" | "edit";
  editData?: DatasheetVariationProps;
}

const FormModificationVariation: React.FC<FormModificationVariationProps> = ({
  colors,
  setData: setVariation,
  closeModal,
  alternativeCode,
  type,
  editData,
}) => {
  const { addToast } = useToasts();

  const [data, setData] = useState({
    primary_color_cod:
      type === "edit" ? String(editData?.primary_color_cod) : "",
    secondary_color_cod:
      type === "edit" ? String(editData?.secondary_color_cod) : "",
    is_drop: type === "edit" ? editData?.is_drop : "",
  });
  const [error, setError] = useState({
    primary_color_cod: "",
    secondary_color_cod: "",
  });
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event?: React.FormEvent) {
    setLoading(true);
    verifyError();

    if (event) {
      event.preventDefault();
    }

    if (!data.primary_color_cod) return setLoading(false);

    try {
      if (type === "create") {
        const id = Math.random() * 10;
        const newData = {
          id,
          is_drop: Boolean(data.is_drop),
          primary_color_cod: Number(data.primary_color_cod),
          secondary_color_cod: !isNaN(Number(data.secondary_color_cod))
            ? Number(data.secondary_color_cod)
            : undefined,
          reference: alternativeCode
            ? `${alternativeCode}${("0000" + data.primary_color_cod).slice(-4)}`
            : "-",

          primary_color: data.primary_color_cod
            ? ("0000" + data.primary_color_cod).slice(-4)
            : "-",
          secondary_color: data.secondary_color_cod
            ? ("0000" + data.secondary_color_cod).slice(-4)
            : "-",
          primary_color_name: colors.find(
            (q) => Number(q.cod) === Number(data.primary_color_cod)
          )?.description,
          secondary_color_name: colors.find(
            (q) => Number(q.cod) === Number(data.secondary_color_cod)
          )?.description,
        };

        setVariation((oldData) => [...oldData, newData]);

        setLoading(false);
        closeModal();
      } else {
        const updateData = {
          id: editData?.id ?? 0,
          is_drop: Boolean(data.is_drop),
          primary_color_cod: Number(data.primary_color_cod),
          secondary_color_cod: !isNaN(Number(data.secondary_color_cod))
            ? Number(data.secondary_color_cod)
            : undefined,
          reference: alternativeCode
            ? `${alternativeCode}${("0000" + data.primary_color_cod).slice(-4)}`
            : "-",

          primary_color: data.primary_color_cod
            ? ("0000" + data.primary_color_cod).slice(-4)
            : "-",
          secondary_color: !isNaN(Number(data.secondary_color_cod))
            ? ("0000" + data.secondary_color_cod).slice(-4)
            : "-",
          primary_color_name: colors.find(
            (q) => Number(q.cod) === Number(data.primary_color_cod)
          )?.description,
          secondary_color_name: colors.find(
            (q) => Number(q.cod) === Number(data.secondary_color_cod)
          )?.description,
        };

        setVariation((oldData) => {
          return oldData.map((item) => {
            if (Number(item.id) === Number(updateData.id)) {
              return updateData;
            } else {
              return item;
            }
          });
        });

        setLoading(false);
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function verifyError() {
    var errorVerify = { primary_color_cod: "", secondary_color_cod: "" };

    if (!data.primary_color_cod) {
      errorVerify = { ...errorVerify, primary_color_cod: "Obrigatório" };
    }

    setError(errorVerify);
  }

  function onChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value, name } = event.target;

    setData({
      ...data,
      [name]: value,
    });
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <div className="field">
          <label htmlFor="reference">Referência</label>
          <input
            style={{ borderStyle: "dashed" }}
            disabled={true}
            value={
              alternativeCode && data.primary_color_cod
                ? `${alternativeCode}${("0000" + data.primary_color_cod).slice(
                    -4
                  )}`
                : "-"
            }
          />
        </div>

        <div className="field-group">
          <div className="field">
            <label htmlFor="primary_color_cod">1ª Cor Predominante</label>

            <select
              name="primary_color_cod"
              value={data.primary_color_cod}
              onChange={onChange}
              onBlur={verifyError}
              disabled={type === "edit"}
            >
              <option value=""></option>
              {colors.map((color, index) => (
                <option key={index} value={color.cod}>
                  {`${color.cod} ${color.description}`}
                </option>
              ))}
            </select>

            {error.primary_color_cod ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {error.primary_color_cod} </span>
              </Error>
            ) : null}
          </div>
          <div className="field">
            <label htmlFor="secondary_color_cod">2ª Cor Predominante</label>

            <select
              name="secondary_color_cod"
              value={data.secondary_color_cod}
              onChange={onChange}
              onBlur={verifyError}
            >
              <option value=""></option>
              {colors.map((color, index) => (
                <option key={index} value={color.cod}>
                  {`${color.cod} ${color.description}`}
                </option>
              ))}
            </select>

            {error.secondary_color_cod ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {error.secondary_color_cod} </span>
              </Error>
            ) : null}
          </div>
        </div>

        <Select
          name="is_drop"
          value={data.is_drop ? 1 : 0}
          onChange={(e) =>
            setData((oldData) => ({
              ...oldData,
              is_drop: Boolean(Number(e.target.value)),
            }))
          }
          label="Dropado"
          data={[
            { name: "SIM", value: 1 },
            { name: "NÃO", value: 0 },
          ]}
        />

        <div className="field">
          <ButtunSubmit type="button" onClick={() => handleSubmit()}>
            {loading ? (
              <Loading
                size={22}
                borderSize={4}
                colorLoading="rgba(255,255,255)"
                borderColor="rgba(255,255,255, 0.3)"
              />
            ) : type === "create" ? (
              "Criar"
            ) : (
              "Editar"
            )}
          </ButtunSubmit>
        </div>
      </Form>
    </Container>
  );
};

export default memo(FormModificationVariation);
