import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { OptionsFilter } from "../../../@types";
import { FieldProps } from "../../../@types/panelTypes";
import { AlertCCT } from "../../../components/AlertCCT";
import { OrderBy } from "../../../components/OrderBy";
import { TableOrders } from "../../../components/TableListOrders/TableOrders";
import { TableStatus } from "../../../components/TableListOrders/TableStatus";
import { formikFilterOrder } from "../../../components/TableListOrders/formikFilterOrder";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Pagination } from "../../../components/panel/table/Pagination";
import { useOrders } from "../../../hook/queries/useOrders";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import * as sellerLocalStorage from "../../../service/localStorage/seller";
import { objectToForEach } from "../../../utils/objectToForEach";
import { Container } from "../styles";
import { Filter, IFilter } from "./Filter";

interface IHeaderProps {
  match: {
    params: {
      position: number;
    };
  };

  admin?: boolean;
  seller?: boolean;
  client?: boolean;

  is_manager?: boolean;
  is_supervisor?: boolean;
}

export interface IFilterOption {
  status: OptionsFilter[];
  brands: OptionsFilter[];
  pointeds: OptionsFilter[];
  refuses: OptionsFilter[];
  sellers: OptionsFilter[];
}

const columns: FieldProps[] = [
  {
    name: "cod",
    field: "CÓDIGO DO PEDIDO",
    viewList: true,
  },
  {
    name: "codClient",
    field: "CÓDIGO CLIENTE",
    viewList: true,
  },
  {
    name: "cnpj",
    field: "CNPJ",
    viewList: true,
  },
  {
    name: "company_name",
    field: "RAZÃO SOCIAL",
    viewList: true,
  },
  {
    name: "brand",
    field: "MARCA",
    viewList: true,
  },
  {
    name: "pointed",
    field: "Motivo Cancelamento",
    viewList: true,
  },
  {
    name: "refuse",
    field: "Motivo Recusa",
    viewList: true,
  },
  {
    name: "status",
    field: "SITUAÇÃO",
    viewList: true,
  },
  {
    name: "codSeller",
    field: "CÓD. VENDEDOR",
    viewList: true,
  },

  {
    name: "orderPeriod",
    field: "PERÍODO DE CRIAÇÃO",
    viewList: true,
  },
  {
    name: "orderPresetDate",
    field: "DATA PREDEFINIDA",
    viewList: true,
  },
  {
    name: "orderSpecificDate",
    field: "DATA ESPECÍFICA",
    viewList: true,
  },
  {
    name: "orderStartDate",
    field: "DATA INICIAL",
    viewList: true,
  },
  {
    name: "orderEndDate",
    field: "DATA FINAL",
    viewList: true,
  },
  {
    name: "type",
    field: "Tipo",
    viewList: true,
  },
  {
    name: "billingOrderPeriod",
    field: "PERÍODO DE FATURAMENTO",
    viewList: true,
  },
  {
    name: "billingOrderPresetDate",
    field: "DATA PREDEFINIDA",
    viewList: true,
  },
  {
    name: "billingOrderSpecificDate",
    field: "DATA ESPECÍFICA",
    viewList: true,
  },
  {
    name: "billingOrderStartDate",
    field: "DATA INICIAL",
    viewList: true,
  },
  {
    name: "billingOrderEndDate",
    field: "DATA FINAL",
    viewList: true,
  },
  {
    name: "position_detail",
    field: "SITUAÇÃO",
    viewList: true,
  },
  {
    name: "origin",
    field: "Origem",
    viewList: false,
  },
  {
    name: "highlighter",
    field: "Destacador",
    viewList: false,
  },
  {
    name: "documentNumber",
    field: "Nº NOTA FISCAL",
    viewList: false,
  },
];

const PanelOrder: React.FC<IHeaderProps> = ({
  admin,
  match,
  client,
  seller,
  is_manager,
}) => {
  const query = useQueryParams();

  const formikFilter = useFormik({
    ...formikFilterOrder({ handleFilter }),
  });

  const sellerStorage =
    sellerLocalStorage.getUser() as sellerLocalStorage.SellerProps;
  const history = useHistory();

  const [filters, setFilters] = useState<IFilter>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });
  const [orderBy, setOrderBy] = useState("deliveryDate.desc");
  const [viewFilter, setViewFilter] = useState(false);
  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const getOrders = useOrders(page, { ...filters, orderBy });

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    if (getOrders && getOrders.data && getOrders.data.totalCount > page) {
      setPage(1);
    }

    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    // eslint-disable-next-line
  }, [match.params?.position]);

  function handleFilter(filter: IFilter) {
    setFilters(filter);
    setPage(1);
    setViewFilter(false);
  }

  return (
    <Container>
      {client && <AlertCCT />}

      <PanelAndDetailAndCreateHeader
        title="Lista de Pedidos"
        updateAt={getOrders.data?.lastUpdateToString}
      />

      <TableStatus
        data={
          getOrders.data?.filters.status.map((status) => ({
            name: status.name,
            value: String(status.id),
          })) ?? []
        }
        setFilters={setFilters}
        isLoading={getOrders.isLoading}
      />

      <Filter
        formikFilter={formikFilter}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        columns={columns}
        filters={filters}
        setFilters={setFilters}
        filterOptions={
          getOrders.data?.filters ?? {
            brands: [],
            status: [],
            pointeds: [],
            sellers: [],
            refuses: [],
            highlighters: [],
          }
        }
        onUpdateData={() => {}}
        is_admin={admin}
        is_client={client}
        is_seller={seller}
        is_manager={sellerStorage?.is_manager}
      />

      <OrderBy
        data={[
          {
            name: "Digitação Crescente",
            value: "deliveryDate.asc",
          },
          {
            name: "Digitação Decrescente",
            value: "deliveryDate.desc",
          },

          {
            name: "Mês de entrega Crescente",
            value: "billingDate.asc",
          },
          {
            name: "Mês de entrega Decrescente",
            value: "billingDate.desc",
          },
        ]}
        setState={setOrderBy}
        state={orderBy}
      />

      <TableOrders
        orders={getOrders.data?.orders ?? []}
        userType={
          admin
            ? "admin"
            : client
            ? "client"
            : seller && is_manager
            ? "sellerManager"
            : "seller"
        }
        filters={{ ...filters }}
        setFilters={setFilters}
        formikFilter={formikFilter}
        refetch={getOrders.refetch}
        isFetching={getOrders.isFetching}
        isLoading={getOrders.isLoading}
        isExportData={admin || seller}
      />

      <Pagination
        totalCountOfRegisters={getOrders.data?.totalCount ?? 0}
        registersPerPage={getOrders.data?.registersPerPage ?? 0}
        currentPage={page}
        onPageChange={(data) => {
          setPage(data);
        }}
      />
    </Container>
  );
};

export default PanelOrder;
