import React, { useRef, useState } from "react";
import { MdArrowDownward } from "react-icons/md";
import useOutsideAlerter from "../../hook/outsideAlerter";
import { Container } from "./styles";

interface Props {
  data: {
    title: string;
    onClick: () => void;
  }[];
}

export function SelectMenu({ data }: Props) {
  const outSideRef = useRef(null);
  const [visiblePreFilter, setVisiblePreFilter] = useState(false);

  useOutsideAlerter(outSideRef, () => {
    if (visiblePreFilter) setVisiblePreFilter(false);
  });

  return (
    <Container ref={outSideRef} onClick={() => setVisiblePreFilter((a) => !a)}>
      <div className="pre-filter-title">
        <span>Filtros pré-selecionar</span>
        <div
          className={
            visiblePreFilter
              ? "pre-filter-title-arrow-active"
              : "pre-filter-title-arrow"
          }
        >
          <MdArrowDownward color="#333" size={16} />
        </div>
      </div>

      {visiblePreFilter && (
        <ul className="pre-filter-option">
          {data.map((item) => (
            <li onClick={item.onClick}>
              <span>{item.title}</span>
            </li>
          ))}
        </ul>
      )}
    </Container>
  );
}
