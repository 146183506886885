import React from "react";

import { IconBaseProps, IconType } from "react-icons";
import {
  ContainerTableStatus,
  TableStatus as TableStatusContainer,
} from "../../../pages/panel/styles";

interface TableStatusProps {
  isLoading?: boolean;
  title: string;

  onPress?: (value: string) => void;

  data: {
    label: string;
    value: string;

    icon: {
      icon: IconType;
      props?: IconBaseProps;
    };
  }[];
}

export const TableStatus = ({
  title,
  data,
  onPress,
  isLoading = false,
}: TableStatusProps) => {
  return (
    <ContainerTableStatus>
      <header>
        <span>{title}</span>
      </header>
      <TableStatusContainer>
        <tbody>
          {data.map(({ value, label, icon }) => (
            <tr
              key={value}
              onClick={() => {
                if (!isLoading && onPress) {
                  onPress(value);
                }
              }}
            >
              <td>{<icon.icon {...icon.props} />}</td>
              <td>{label}</td>
            </tr>
          ))}

          {data.length <= 0 && (
            <tr>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
        </tbody>
      </TableStatusContainer>
    </ContainerTableStatus>
  );
};
