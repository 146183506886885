import React, { FormEvent, useEffect, useState } from "react";

import api from "../../../service/api";

import { useToasts } from "react-toast-notifications";

import { Container, Form, ContainerForm, ButtunSubmit } from "../styles";

interface HeaderProps {
  admin: boolean;
}

interface IData {
  days_history_client: number;
  days_history_user: number;
  days_history_before_seller: number;
  days_history_later_seller: number;
  show_agent: boolean;
}

interface ISubmit {}

const OrderConfig: React.FC<HeaderProps> = () => {
  const { addToast } = useToasts();

  const [data, setData] = useState<IData>({} as IData);

  useEffect(() => {
    (async () => {
      try {
        const orderConfigDB = await api.get<IData>(`/order/config/`);

        setData(orderConfigDB.data);
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, []);

  function hadleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setData({
      ...data,
      [name]: value,
    });
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    let submit: ISubmit = {
      days_history_client: data.days_history_client,
      days_history_user: data.days_history_user,
      days_history_before_seller: data.days_history_before_seller,
      days_history_later_seller: data.days_history_later_seller,
      show_agent: data.show_agent,
    };
    try {
      await api.put(`/order/config/`, submit);

      addToast(
        "Alterção de dados das configurações na Gestão de pedidos realizada com sucesso",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    } catch (error) {
      console.log(error);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <h2>Configurações nas gestões de pedidos</h2>
      </header>

      <ContainerForm>
        <Form onSubmit={handleSubmit}>
          <h3>Cliente</h3>

          <div className="field">
            <label htmlFor="days_history_client">
              Quantidade de dias anterior dia de hoje para vizualizar o pedidos
            </label>
            <input
              type="number"
              value={data.days_history_client}
              onChange={hadleInputChange}
              name="days_history_client"
              maxLength={6}
            />
          </div>

          <h3>Operador</h3>

          <div className="field">
            <label htmlFor="days_history_user">
              Quantidade de dias anterior dia de hoje para vizualizar o pedidos
            </label>
            <input
              type="number"
              value={data.days_history_user}
              onChange={hadleInputChange}
              name="days_history_user"
              maxLength={6}
            />
          </div>

          <h3>Representante</h3>
          {/* 
          <div className="field">
            <label htmlFor="show_agent">Preposto visualizar pedidos</label>

            <select
              name="show_agent"
              value={data.show_agent ? 1 : 0}
              onChange={(e) =>
                setData({
                  ...data,
                  show_agent: Number(e.target.value) === 1 ? true : false,
                })
              }
            >
              <option value={1}>SIM</option>
              <option value={0}>NÃO</option>
            </select>
          </div> */}

          <div className="field-group">
            <div className="field">
              <label htmlFor="days_history_before_seller">
                Dias anterior ao dia de hoje
              </label>
              <input
                type="number"
                value={data.days_history_before_seller}
                onChange={hadleInputChange}
                name="days_history_before_seller"
                maxLength={6}
              />
            </div>
            <div className="field">
              <label htmlFor="days_history_later_seller">
                Dias apos ao dia de hoje
              </label>
              <input
                type="number"
                value={data.days_history_later_seller}
                onChange={hadleInputChange}
                name="days_history_later_seller"
                maxLength={6}
              />
            </div>
          </div>

          <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
        </Form>
      </ContainerForm>
    </Container>
  );
};

export default OrderConfig;
