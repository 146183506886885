import styled from "styled-components";

export const Filter = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 35px;

  position: relative;

  margin-bottom: 20px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 4px 0 0 4px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1;
    width: 80px;
    background-color: #d21e26;
    transition: all 0.5s;
    color: #fff;
    font-weight: 700;

    span {
      color: #fff;
      font-weight: 700;
      margin-right: 4px;
    }

    &:hover {
      opacity: 0.85;
    }
  }

  input {
    width: 100%;
    border: 2px solid #ccc;
    border-left: 0;

    font-size: 1rem;
    padding: 0 1rem;
  }
`;

export const FilterCheck = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 35px;
  z-index: 2;

  nav {
    position: absolute;
    display: flex;
    top: -35px;
    right: 0;
    justify-content: flex-end;
    z-index: 4;

    button {
      display: flex;
      justify-content: center;

      align-items: center;
      border-radius: 4px;

      height: 35px;
      width: 100px;

      z-index: 4;
      font-weight: 700;
      transition: all 0.5s;
      color: #fff;
    }

    button:hover {
      opacity: 0.85;
    }
  }

  main {
    width: 100%;
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    border: 2px solid #ccc;
    border-top: none;
    padding: 10px 4px;
  }

  footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 110px;
      padding: 10px 5px;
      margin-right: 10px;

      border-radius: 4px;
      font-weight: 700;
      background-color: #d21e26;
      transition: all 0.3s;
      color: #fff;
    }

    button:hover {
      opacity: 0.7;
      background-color: #db4047;
    }

    .clearFilter {
      color: #d21e26;
      background-color: #fff;

      &:hover {
        background-color: #f5cbcb;
      }
    }
  }
`;

export const FilterSelects = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: absolute;
  height: 35px;
  width: 100%;
  padding: 0 0 0 84px;

  ul {
    display: fixed;
    align-items: center;
    justify-content: center;

    list-style: none;
    color: #fff;
    z-index: 2;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 3px;

      border-radius: 4px;
      padding: 2px 5px;
      background-color: #f00;
      margin-right: 5px;

      span {
      }

      svg {
        margin-left: 3px;
        cursor: pointer;
      }
    }
  }
`;

export const Form = styled.form`
  border-radius: 4px 0px 0px 4px;
  width: 100%;
  height: 100%;
  background-color: #fff;

  h3 {
    font-size: 18;
    color: #333;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0 0px;
  }
  .field-group {
    width: 100%;
    display: flex;
    flex-flow: row;
  }

  .containerCnpj {
    display: flex;
    width: 100%;
    button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 15%;
      height: 35px;

      color: #fff;
      font-size: 16px;
      border-radius: 4px;
      background-color: #d21e26;
      transition: all 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
    input {
      width: 85%;
      margin-right: 8px;
    }
  }

  @media (max-width: 900px) {
    .field-group {
      flex-flow: column;
    }

    .field {
      padding: 0 2px;
      input {
        padding: 0 0;
      }
    }
  }

  .field {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-bottom: 24px;
    padding: 0 10px;

    label {
      font-size: 14px;
      margin-bottom: 4px;
      color: #555;

      .required {
        color: #f00;
        font-size: 11px;
      }
    }
    input {
      padding: 0 10px;
      background: #fff;
      height: 35px;
      font-size: 16px;
      color: #000;
      border: 2px solid #aaa;
      border-radius: 5px;
    }

    .selectClient {
      height: 40px;
      width: 100%;

      button {
        width: 100%;
        height: 100%;
        background: #d21e26;

        font-weight: bold;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
      }
    }

    input:focus {
      border: 2px solid #333;
    }

    input::placeholder {
      color: #a0a0b2;
    }

    textarea {
      padding: 10px 10px;
      background: #fff;
      height: 80px;
      font-size: 16px;
      color: #000;
      border: 2px solid #aaa;
      border-radius: 5px;
      resize: vertical;

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-thumb {
        background: #333;
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background: #888;
      }

      &:focus {
        border: 2px solid #333;
      }
    }

    select {
      height: 35px;

      background: #fff;
      border-radius: 5px;
      border: 2px solid #aaa;
      padding: 4px 8px;

      font-size: 16px;
      color: #aaa;

      cursor: pointer;
      /* -webkit-appearance: none; */
      -moz-appearance: none;
      /* appearance: none;  */

      &:focus {
        border: 2px solid #333;
        color: #333;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  .containerButton {
  }
`;
