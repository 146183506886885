import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { IPermissionUser } from "../../../@types";
import { HistoryAccess } from "../../../components/HistoryAccess";
import Loading from "../../../components/loadings/Loading";
import api from "../../../service/api";
import { ContainerCheck } from "../../create/styles";
import {
  ButtunSubmit,
  Container,
  ContainerForm,
  Error,
  Form,
  ScreenLoading,
} from "../styles";

interface HomeProps {
  location: {
    state: {
      user: IUser;
    };
  };

  match: {
    params: {
      id: number;
    };
  };

  permission: IPermissionUser;
}

interface IUser {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
  isActive?: boolean;
  permission?: {
    id: number;
  };
  access?: {
    date: Date;
    dateToString: string;
  }[];
}

interface IPermission {
  id?: string;
  name?: string;
}

const User: React.FC<HomeProps> = ({ permission, match }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [contentPage, setContentPage] = useState<IUser>();
  const [listPermission, setListPermission] = useState<IPermission[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (match.params.id) {
      (async () => {
        const permissionDB = await api.get<IUser>(
          `/userAdmin/${match.params.id}`
        );

        setContentPage({
          ...permissionDB.data,
          access: permissionDB?.data?.access?.map((item) => ({
            ...item,
            dateToString: new Date(item.date).toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "long",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            }),
          })),
        });
        setLoading(false);
      })();
    }
  }, [match.params.id]);

  useEffect(() => {
    if (contentPage) {
      if (contentPage.id) formik.setFieldValue("id", contentPage.id);
      if (contentPage.name) formik.setFieldValue("name", contentPage.name);
      if (contentPage.lastname)
        formik.setFieldValue("lastname", contentPage.lastname);
      if (contentPage.email) formik.setFieldValue("email", contentPage.email);
      if (contentPage.permission)
        formik.setFieldValue("permission", contentPage.permission.id);
      if (contentPage.isActive === true || contentPage.isActive === false)
        formik.setFieldValue("active", contentPage.isActive);

      if (contentPage.permission) console.log(contentPage.permission.id);
    }
    // eslint-disable-next-line
  }, [contentPage]);

  useEffect(() => {
    (async () => {
      const permissionDB = await api.get("/populate/permission");
      setListPermission(permissionDB.data);
    })();
  }, []);

  async function handleSubmit(updates: IUser) {
    try {
      await api.put(`/userAdmin/${updates.id}`, updates);
      addToast("Usuário alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/usuarios");
    } catch (err) {
      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este e-mail já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      id: undefined,
      name: undefined,
      lastname: undefined,
      email: undefined,
      permission: undefined,
      active: undefined,
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nome é obrigatório"),
      lastname: Yup.string().required("Sobrenome é obrigatório"),
      permission: Yup.string().required("Permissionamento é obrigatório"),
      email: Yup.string()
        .email("informe um e-mail válido")
        .required("email é obrigatório"),
      password: Yup.string()
        .min(8, "A senha precisa conter no mínimo 8 caracteres.")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "A senha precisa conter letrar e numeros, no mínimo 8 caracteres e um caracter especial."
        ),
    }),
    onSubmit: (data) => handleSubmit(data),
  });

  return (
    <Container>
      {!loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Usuário</h2>
          </header>

          <ContainerForm>
            <Form
              onSubmit={
                permission && permission.userAdmin && permission.userAdmin > 2
                  ? formik.handleSubmit
                  : () => {}
              }
            >
              <div className="field">
                <label htmlFor="phone">Situação</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.active === true ? "check" : ""}
                    onClick={() => formik.setFieldValue("active", true)}
                  >
                    ATIVO
                  </button>

                  <button
                    type="button"
                    onClick={() => formik.setFieldValue("active", false)}
                    className={formik.values.active === false ? "check" : ""}
                  >
                    INATIVO
                  </button>
                </ContainerCheck>
              </div>

              <div className="field">
                <label htmlFor="id">ID</label>
                <input
                  type="text"
                  style={{ borderStyle: "dashed" }}
                  className="id"
                  {...formik.getFieldProps("id")}
                  readOnly={true}
                />
              </div>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="name">Nome</label>
                  <input id="name" {...formik.getFieldProps("name")} />
                  {formik.touched.name && formik.errors.name ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.name} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="lastname">Sobrenome</label>
                  <input id="lastname" {...formik.getFieldProps("lastname")} />
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.lastname} </span>
                    </Error>
                  ) : null}
                </div>
              </div>
              <div className="field">
                <label htmlFor="email">Email</label>
                <input id="email" {...formik.getFieldProps("email")} />
                {formik.touched.email && formik.errors.email ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.email} </span>
                  </Error>
                ) : null}
              </div>

              <h3>Perfil de permissionamento</h3>
              <div className="field">
                <label htmlFor="permission">Permissão</label>
                <select id="permission" {...formik.getFieldProps("permission")}>
                  <option value={undefined}>Selecionar permissão</option>
                  {listPermission &&
                    listPermission.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
                {formik.touched.permission && formik.errors.permission ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.permission} </span>
                  </Error>
                ) : null}
              </div>

              <h3>Alterar a senha</h3>
              <div className="field">
                <label htmlFor="password">Nova senha</label>
                <input
                  id="password"
                  type="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.password} </span>
                  </Error>
                ) : null}
              </div>

              {permission &&
                permission.userAdmin &&
                permission.userAdmin > 2 && (
                  <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
                )}
            </Form>
          </ContainerForm>

          <HistoryAccess
            data={
              contentPage?.access
                ? contentPage.access.map((acces) => ({
                    id: String(contentPage.id),
                    cod: String(contentPage.id),
                    name: `${contentPage?.name} ${contentPage?.lastname}`,
                    date: acces.date,
                    dateToString: acces.dateToString,
                  }))
                : []
            }
          />
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default User;
