import React, { useState } from "react";
import { Container, ContainerBody } from "./styles";

interface ModalProps {
  modalVisible: boolean;
  setModalVisible: (value: React.SetStateAction<boolean>) => void;

  data: any;
  setUpdate?: () => void;
  admin?: boolean;
}

interface TableCleanProps {
  columns: TitleProps[];
  data: any[];

  modal?: React.FC<ModalProps>;

  selectActive?: number;
  selectTitle?: string;
  setSelect?: (e: any) => void;
  setSelectDisabled?: boolean;

  setAction?: (e: any) => void;
  actionTitle?: string;
  actionName?: string;
  setUpdate?: () => void;

  admin?: boolean;
}

interface TitleProps {
  title: string;
  field: string;
}

export const TableClean: React.FC<TableCleanProps> = ({
  columns,
  data: datas,
  modal: Modal,

  selectActive,
  selectTitle,
  setSelect,
  setSelectDisabled,

  setAction,
  actionTitle,
  actionName,
  setUpdate,
  admin,
}) => {
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [data, setData] = useState({});

  return (
    <ContainerBody>
      <Container>
        <thead>
          <tr>
            {columns.map((colum, index) => (
              <th key={index}>{colum.title}</th>
            ))}
            {setAction && <th>{actionTitle ? actionTitle : "Ações"}</th>}
            {setSelect && <th>{selectTitle ? selectTitle : "Selecionado"}</th>}
          </tr>
        </thead>

        <tbody>
          {datas.map((data, indexData) => (
            <tr
              key={indexData}
              style={{ cursor: Modal ? "pointer" : undefined }}
            >
              {columns.map((colum, indexColum) => (
                <td
                  key={indexColum}
                  onClick={() => {
                    setIsActiveModal(true);
                    setData(data);
                  }}
                >
                  {data[colum.field]}
                </td>
              ))}

              {setAction && (
                <td className="btn-action">
                  <button type="button" onClick={() => setAction(data)}>
                    {actionName ? actionName : "Ação"}
                  </button>
                </td>
              )}
              {setSelect && (
                <td>
                  <input
                    disabled={setSelectDisabled}
                    type="checkbox"
                    checked={Number(selectActive) === Number(data?.id)}
                    onClick={() => setSelect(data)}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Container>

      {isActiveModal && Modal && (
        <Modal
          admin={admin}
          modalVisible={isActiveModal}
          setModalVisible={() => setIsActiveModal(false)}
          data={data}
          setUpdate={setUpdate}
        />
      )}
    </ContainerBody>
  );
};
