import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";

import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { InputColor } from "../../../components/Form/InputColor";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Color, useOneColor } from "../../../hook/queries/useColors";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { setFormikValues } from "../../../utils/setFormikValues";

export interface MatchParams {
  id?: string;
}

type ColorCreateProps = Omit<Color, "id">;

const Colors: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const isUpdating = match?.params?.id ? true : false;
  const oneColor = useOneColor(Number(match.params.id));

  const createColor = useMutation(
    async (color: ColorCreateProps) => {
      const response = await api.post("/populate/colors/create", color);

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("colors");
      },
    }
  );
  const updateColor = useMutation(
    async (color: Color) => {
      const response = await api.put(
        `/populate/colors/update/${color.id}`,
        color
      );

      return response.data;
    },
    {
      onSuccess: (color: Color) => {
        queryClient.invalidateQueries("colors");
        queryClient.invalidateQueries(["color", color.id]);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      id: "",
      cod: "",
      description: "",
      hex: "",
    },
    validationSchema: Yup.object({
      cod: Yup.number().required("É obrigatório"),
      description: Yup.string().required("É obrigatório"),
      hex: Yup.string(),
    }),
    onSubmit: (a) => {
      //@ts-ignore
      !isUpdating ? handleCreateColor(a) : handleUpdateColor(a);
    },
  });

  useEffect(() => {
    if (oneColor?.isSuccess && oneColor?.data && isUpdating)
      setFormikValues(oneColor?.data, formik);
    // eslint-disable-next-line
  }, [oneColor?.data, isUpdating, oneColor?.isSuccess]);

  async function handleCreateColor(color: ColorCreateProps) {
    try {
      await createColor.mutateAsync(color);

      addToast("Cor alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/cores");
    } catch (err) {
      const error = err as AxiosError | undefined;

      if (error?.response?.data?.error === "cod already exists") {
        return addToast("Este código ERP já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleUpdateColor(color: Color) {
    try {
      await updateColor.mutateAsync(color);

      addToast("Cor alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/cores");
    } catch (err) {
      const error = err as AxiosError | undefined;

      if (error?.response?.data?.error === "cod already exists") {
        return addToast("Este código ERP já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <CreateAndDetailLayout isLoading={oneColor?.isLoading}>
      <PanelAndDetailAndCreateHeader
        title={isUpdating ? "Cor" : "Criar Cor"}
        goBack
      />

      <LayoutForm onSubmit={formik.handleSubmit}>
        {isUpdating && (
          <Input
            label="ID"
            {...formik.getFieldProps("id")}
            type="number"
            disabled
          />
        )}
        <Input
          label="Cód. ERP"
          {...formik.getFieldProps("cod")}
          type="number"
          error={
            formik.touched.cod && formik.errors.cod
              ? formik.errors.cod
              : undefined
          }
        />

        <GroupInput>
          <Input
            label="Nome"
            {...formik.getFieldProps("description")}
            error={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : undefined
            }
          />

          <InputColor
            label="HEX"
            {...formik.getFieldProps("hex")}
            error={
              formik.touched.hex && formik.errors.hex
                ? formik.errors.hex
                : undefined
            }
          />
        </GroupInput>

        <ButtonSubmit>{isUpdating ? "Editar" : "Criar"}</ButtonSubmit>
      </LayoutForm>
    </CreateAndDetailLayout>
  );
};

export default Colors;
