import styled from "styled-components";

export const ContainerPreviews = styled.ul`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .required-on {
    border: 2px solid #d21e26;
  }

  li {
    width: 100%;
    display: flex;
    align-items: center;
    background: #f0f0f0;
    padding: 10px 10px;
    margin-top: 10px;
    border-radius: 8px;

    img {
      height: 50px;
    }

    .fileInfo {
      margin-left: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      div {
        display: flex;
        flex-direction: column;
        margin: 0 5px;
      }

      .action-file {
        display: flex;
        flex-direction: row;

        .field-file {
          margin-left: 0.6rem;

          display: flex;
          flex-direction: row;
          align-items: center;

          label {
            font-size: 14px;
            margin-right: 4px;
            color: #555;

            .required {
              color: #f00;
              font-size: 11px;
            }
          }
          select {
            height: 35px;

            background: #fff;
            border-radius: 5px;
            border: 2px solid #aaa;
            padding: 4px 8px;

            font-size: 16px;
            color: #aaa;

            cursor: pointer;
            /* -webkit-appearance: none; */
            -moz-appearance: none;
            /* appearance: none;  */

            &:focus {
              border: 2px solid #333;
              color: #333;
            }
          }
        }

        .download-link {
          display: flex;
          align-items: center;
          margin-right: 10px;
          margin-left: 10px;
          svg {
            background: none;
            color: #d21e26;
          }
        }

        .fileInfo-field-required {
          border: 2px solid #d21e26 !important;
        }
      }

      strong {
        font-size: 14px;
        word-break: break-all;
      }

      span {
        font-size: 12px;
        color: #333;
      }

      button {
        margin-right: 10px;
        background: none;
        color: #d21e26;
      }
    }
  }
`;
