import styled from "styled-components";

export const Container = styled.div`
  .row-none {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 7px 0;
    color: #333;
    font-size: 0.875rem !important;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .container-action {
    display: flex;
    column-gap: 6px;
  }

  .container-subtitle {
    display: flex;
    align-items: center;

    button {
      height: 46px;
      width: 46px;
      border-radius: 50%;

      margin-left: 0.5rem;

      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      svg {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 10px;

    thead {
      tr {
        th {
          font-weight: 500 !important;

          font-size: 0.875rem !important;
          padding: 6px 8px;
          text-align: start;
          padding: 6px 16px 6px 10px;

          border-bottom: 1px solid rgba(224, 224, 224, 1);
        }
      }

      tr:nth-child(1) {
        display: none;
      }
    }
    tbody {
      display: table-row-group;
      tr {
        display: table-row;
        outline: 0;
        vertical-align: middle;
        font-size: 1rem !important;

        border-bottom: 1px solid rgba(224, 224, 224, 1);

        td {
          padding: 6px 16px 6px 12px;

          select {
            background-color: transparent;
          }

          button {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: transparent;
            padding: 0;

            &:hover {
              background-color: rgba(0, 0, 0, 0.04);
            }

            svg {
              color: #222;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
`;
