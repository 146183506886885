export interface CookiesConsentProps {
  date: Date;
}

export const STORAGE = "@Alert-client-update";

export const get = () => {
  const cookiesConsent = localStorage.getItem(STORAGE);

  return JSON.parse(cookiesConsent as string) as
    | CookiesConsentProps
    | undefined;
};

export const set = (user: CookiesConsentProps) => {
  localStorage.setItem(STORAGE, JSON.stringify(user));
};

export const remove = () => {
  localStorage.removeItem(STORAGE);
};
