import React, { useEffect } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { ColorAndCategoryProps } from "..";
import { Error as ErrorComponent } from "../../../pages/create/styles";
import { GridProps } from "../../../pages/detail/Datasheet";
import { Select } from "../../Form/Select";
import { ContainerGrid, SizeItem, TableSizes } from "./styles";

interface SelectGridComponentProps {
  formik: any;
  setGridsSelect: React.Dispatch<React.SetStateAction<GridProps[]>>;
  gridsSelect: GridProps[];

  sizes: ColorAndCategoryProps[];
  setSizes: React.Dispatch<React.SetStateAction<ColorAndCategoryProps[]>>;

  disabled?: boolean;

  grids: GridErpProps[];
}

interface SizeProps {
  id: number;
  name: string;
  active: boolean;
}

export interface GridErpProps {
  codSankhya: number;
  name: string;
  measure: string[];
}

export const SelectGrid: React.FC<SelectGridComponentProps> = ({
  formik,
  gridsSelect,
  setGridsSelect,
  sizes,
  setSizes,
  grids,
  disabled = false,
}) => {
  function handleSelectGrid(size: SizeProps) {
    const gridsSelectUpdate = gridsSelect;
    const findOne = gridsSelectUpdate.find(
      (f) => Number(f.sizeId) === Number(size.id)
    );

    setSizes((oldSize) => {
      return oldSize.map((sizeTrated) =>
        sizeTrated.id === size.id
          ? { ...sizeTrated, active: !sizeTrated.active }
          : sizeTrated
      );
    });

    if (findOne) {
      const gridsSelectDelete = gridsSelect.filter(
        (f) => +f.sizeId !== +size.id
      );
      setGridsSelect(gridsSelectDelete);
    } else {
      const newGrid = {
        id: Math.random() * 10,
        name: size.name,
        sizeId: size.id,
      };

      setGridsSelect((old) => [...old, newGrid]);
    }
  }

  useEffect(() => {
    const findOne = grids.find(
      (grid) =>
        Number(grid?.codSankhya) === Number(formik.values.codGridSankhya)
    );

    if (findOne) {
      var normalizedGridId: number[] = [];
      var normalizedGridSelect: GridProps[] = [];

      findOne.measure.forEach((measure) => {
        const findSize = sizes.find(
          (size) =>
            String(size.description).toLocaleUpperCase() ===
            String(measure).toLocaleUpperCase()
        );
        if (findSize) {
          normalizedGridId.push(findSize.id);

          const newGrid = {
            id: Math.random() * 10,
            name: findSize.description,
            sizeId: findSize.id,
          };
          normalizedGridSelect.push(newGrid);
        }
      });

      setSizes((oldSize) => {
        return oldSize.map((sizeTrated) => {
          const findOne = normalizedGridId.some((f) => f === sizeTrated.id);

          return findOne
            ? { ...sizeTrated, active: true }
            : { ...sizeTrated, active: false };
        });
      });

      setGridsSelect(normalizedGridSelect);
    }

    // eslint-disable-next-line
  }, [formik.values.codGridSankhya]);

  return (
    <>
      <h3>Definição de Grade</h3>

      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <Select
            disabled={disabled}
            label="Selecionar Grade:"
            name="Grades"
            data={grids.map((item) => ({
              name: item.name,
              value: item.codSankhya,
            }))}
            {...formik.getFieldProps("codGridSankhya")}
          />

          <ContainerGrid>
            <TableSizes>
              {sizes
                .sort((c, d) => {
                  const a = c.description.replace(",", ".");
                  const b = d.description.replace(",", ".");

                  if (!isNaN(Number(a)) && !isNaN(Number(b))) {
                    return Number(a) - Number(b);
                  } else {
                    if (a === "G" && b === "GG") {
                      return -1;
                    }

                    if (a === "GG" && b === "G") {
                      return 1;
                    }

                    if (a > b) {
                      return -1;
                    }
                    if (a < b) {
                      return 1;
                    }

                    return 0;
                  }
                })
                .map((size, index) => (
                  <SizeItem
                    key={index}
                    active={size.active}
                    disabled={disabled}
                  >
                    <button
                      type="button"
                      disabled={disabled}
                      onClick={() =>
                        handleSelectGrid({
                          id: size.id,
                          name: size.description,
                          active: size.active,
                        })
                      }
                    >
                      {size.description}
                    </button>
                  </SizeItem>
                ))}
            </TableSizes>

            {formik.touched.grid && formik.errors.grid ? (
              <ErrorComponent>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formik.errors.grid} </span>
              </ErrorComponent>
            ) : null}
          </ContainerGrid>
        </div>
      </div>
    </>
  );
};
