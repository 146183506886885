import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  grid-area: NAVTOP;
  display: flex;
  background-color: #fff;
  border-bottom: 2px solid #d21e26;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 900px) {
    justify-content: space-between;

    position: fixed;
    width: 100%;
    z-index: 888;
  }
`;

export const InfoUser = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: flex-end;

  margin-right: 10px;
  border-radius: 8px;

  z-index: 6;
  cursor: pointer;
  overflow: hidden;

  .info-user {
    display: flex;
    flex-direction: column;

    align-items: flex-end;
    justify-content: center;

    margin-right: 20px;

    h3 {
      font-size: 18px;
    }

    span {
      font-size: 14px;
      color: #555;
    }
  }

  .icon-user {
    height: 45px;
    width: 45px;

    background: #333;
    border-radius: 50%;
    display: flex;

    align-items: center;
    justify-content: center;
  }

  .triangulo {
    margin: 5px 5px 0 5px;

    transition: 0.4s;
    transform: rotate(0deg);
  }

  .triangulo-active {
    margin: 5px 5px 0 5px;
    transform: rotate(180deg);

    transition: 0.4s;
  }

  &:active:hover {
    background: none;
  }

  @media (max-width: 901px) {
    .info-user {
      margin-right: 0px;

      h3 {
        display: none;
      }

      span {
        display: none;
      }
    }
  }
`;

export const OptionNav = styled.ul`
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.59);

  position: absolute;
  top: 53px;
  right: -95px;
  width: 250px;
  border-radius: 5px;

  background-color: #fff;
  transform: translateX(-45%);
  overflow: hidden;
  list-style: none;
  z-index: 5;

  li {
    display: flex;
    align-items: center;
    transition: all 0.3s;

    padding: 12px 15px;
    width: 100%;

    svg {
      margin-right: 10px;
    }
  }

  li:hover {
    background-color: #ccc;
  }

  a {
    text-decoration: none;
    color: #333;
  }

  animation: fadeout 0.5s;

  @keyframes fadeout {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const NavbarHambuerguer = styled.div`
  margin-left: 10px;

  @media (min-width: 901px) {
    display: none;
  }
`;
export const LogoAlpar = styled.div`
  margin-left: 65px;
  margin-top: 7px;
  img {
    height: 40px;
  }

  @media (min-width: 901px) {
    display: none;
  }
`;

export const IconWhats = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  padding: 5px;
  margin-right: 1rem;
  margin-left: 0.875rem;

  background-color: rgb(0, 230, 118);

  @media (max-width: 901px) {
    display: none;
  }
`;

export const IconHolding = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  padding: 5px;
  margin-left: 10px;

  background-color: #333;
`;

export const ContainerActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 901px) {
    display: none;
  }
`;

export const ContainerIconHolding = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #5991eb;

  span {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerBar = styled.div`
  margin-left: 1rem;
  position: fixed;
  z-index: 998;

  display: flex;
  align-items: flex-start;

  @media (max-width: 901px) {
    display: none;
    margin-left: 3rem;
    svg {
      color: #aaa;
    }
  }

  button {
    background-color: transparent;

    svg {
      height: 1.8rem;
      width: 1.8rem;
    }
  }
`;
export const ContainerBarMobile = styled.div`
  margin-left: 1.5rem;
  margin-top: 10px;
  position: fixed;
  z-index: 998;

  display: flex;
  align-items: flex-start;

  @media (max-width: 901px) {
    /* margin-left: 3rem; */
    svg {
      color: #aaa;
    }
  }

  @media (min-width: 900px) {
    display: none;
  }

  button {
    background-color: transparent;

    svg {
      height: 1.8rem;
      width: 1.8rem;
    }
  }
`;

export const InfoCod = styled.div`
  width: 100%;
  background: #141310;
  color: white;
  text-align: center;

  position: fixed;
  z-index: 889;
  top: 0;
  border-bottom: 2px solid #d21e26;
  height: 21px;

  @media (max-width: 900px) {
    height: 15px;
    font-size: 12px;
  }
`;
