import { useFormik } from "formik";
import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useHistory, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";

import { FiAlertCircle } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";
import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { Input } from "../../../components/Form/Input";
import { LayoutForm } from "../../../components/Form/LayoutForm";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { Contact, useOneContact } from "../../../hook/queries/useContact";
import api from "../../../service/api";
import { queryClient } from "../../../service/queryClient";
import { setFormikValues } from "../../../utils/setFormikValues";
import { Error as ComponentError } from "../styles";

export interface MatchParams {
  id?: string;
}

type ContactCreateProps = Omit<Contact, "id">;

export default () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const match = useRouteMatch<MatchParams>();
  const isUpdating = match?.params?.id ? true : false;
  const getData = useOneContact(match.params.id);

  const createContact = useMutation(
    async (contact: ContactCreateProps) => {
      const response = await api.post("/contact", contact);

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contacts");
      },
    }
  );
  const updateContact = useMutation(
    async (contact: Contact) => {
      const response = await api.put(`/contact/${contact.id}`, contact);

      return response.data;
    },
    {
      onSuccess: (contact: Contact) => {
        queryClient.invalidateQueries("contacts");
        queryClient.invalidateQueries(["contact", contact.id]);
      },
    }
  );
  const deleteContact = useMutation(
    async (contact: Contact) => {
      const response = await api.delete(`/contact/${contact.id}`);

      return response.data;
    },
    {
      onSuccess: (contact: Contact) => {
        queryClient.invalidateQueries("contacts");
        queryClient.invalidateQueries(["contact", contact.id]);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      id: "",
      title: "",
      text: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("É obrigatório"),
      text: Yup.string().required("É obrigatório"),
    }),
    onSubmit: (a) => {
      //@ts-ignore
      !isUpdating ? handleCreate(a) : handleUpdate(a);
    },
  });

  useEffect(() => {
    if (getData?.isSuccess && getData?.data && isUpdating)
      setFormikValues(getData?.data, formik);
    // eslint-disable-next-line
  }, [getData?.data, isUpdating, getData?.isSuccess]);

  async function handleCreate(contact: ContactCreateProps) {
    try {
      await createContact.mutateAsync(contact);

      addToast("Contato alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/contatos");
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleUpdate(contact: Contact) {
    try {
      await updateContact.mutateAsync(contact);

      addToast("Contato alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/contatos");
    } catch (err) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleDelete() {
    try {
      if (!getData?.data) throw new Error("No data");

      deleteContact.mutateAsync(getData?.data);
      addToast("Contato excluído com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/contatos");
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <CreateAndDetailLayout isLoading={getData?.isLoading}>
      <PanelAndDetailAndCreateHeader
        title={isUpdating ? "Contato" : "Criar Contato"}
        goBack
      >
        {isUpdating && (
          <div className="containerActions">
            <button
              type="button"
              className="buttonActionDatasheet"
              onClick={handleDelete}
            >
              {
                <div>
                  <span>
                    <RiDeleteBinLine color="#d21e26" />
                  </span>
                  Excluir
                </div>
              }
            </button>
          </div>
        )}
      </PanelAndDetailAndCreateHeader>

      <LayoutForm onSubmit={formik.handleSubmit}>
        {isUpdating && (
          <Input label="ID" {...formik.getFieldProps("id")} disabled />
        )}
        <Input
          label="Titulo"
          {...formik.getFieldProps("title")}
          error={
            formik.touched.title && formik.errors.title
              ? formik.errors.title
              : undefined
          }
        />
        <div className="field">
          <ReactQuill
            theme="snow"
            onChange={(e) => {
              formik.setFieldValue("text", String(e));
              formik.handleChange("text");
            }}
            value={formik.values.text}
          />
          {formik.touched.text && formik.errors.text && (
            <ComponentError>
              <FiAlertCircle color="#f00" size={16} />
              <span> {formik.errors.text} </span>
            </ComponentError>
          )}
        </div>

        <ButtonSubmit>{isUpdating ? "Editar" : "Criar"}</ButtonSubmit>
      </LayoutForm>
    </CreateAndDetailLayout>
  );
};
