import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

import { useFormik } from "formik";

import moment from "moment";
import { HiBadgeCheck } from "react-icons/hi";
import { IoReload } from "react-icons/io5";
import {
  RiAlertFill,
  RiCloseCircleFill,
  RiFileExcel2Fill,
} from "react-icons/ri";
import { useToasts } from "react-toast-notifications";
import { panelTypesDefault } from "../../../@types/panelTypes";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { TableStatus } from "../../../components/panel/TableStatus";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import { ClientFilters } from "../../../hook/queries/useClients";
import {
  Client,
  getClientsEnchenteRS,
  useClientsEnchenteRS,
} from "../../../hook/queries/useClientsEnchteRS";
import { generateXlsx } from "../../../utils/generateXlsx";

interface PanelColorsProps extends panelTypesDefault {}

const statusTable = [
  {
    label: "Pendente",
    value: "pending",
    icon: {
      icon: RiAlertFill,
      props: {
        size: 26,
        color: "#e9c912",
      },
    },
  },
  {
    label: "Não reabrirá",
    value: "notReopen",
    icon: {
      icon: RiCloseCircleFill,
      props: {
        size: 26,
        color: "#d21e26",
      },
    },
  },
  {
    label: "Reabrirá",
    value: "reopen",
    icon: {
      icon: HiBadgeCheck,
      props: {
        size: 26,
        color: "#21b543",
      },
    },
  },
];

const statusNormalized = (row: Client) =>
  row.client_enchete_rs?.length === 0
    ? "pending"
    : row.client_enchete_rs?.filter((f) => f.reopening === true).length ===
      row.client_enchete_rs?.length
    ? "reopen"
    : "notReopen";

const columns: FieldProps<Client>[] = [
  {
    name: "icon",
    field: "",
    render(row) {
      const normalizedStatus = statusNormalized(row);

      const findStatus = statusTable.find((f) => f.value === normalizedStatus);

      if (findStatus)
        return (
          <span>{<findStatus.icon.icon {...findStatus.icon.props} />}</span>
        );
    },
    viewList: true,
  },
  {
    name: "cod",
    field: "CÓD",
    viewList: true,
  },
  {
    name: "company_name",
    field: "RAZÃO SOCIAL",
    viewList: true,
  },
  {
    name: "cnpj",
    field: "CNPJ",
    viewList: true,
  },
  {
    name: "city",
    field: "CIDADE",
    viewList: true,
    render: (row) => <span>{row.adresses?.city}</span>,
  },
  {
    name: "neighborhood",
    field: "BAIRRO",
    viewList: true,
    render: (row) => <span>{row.adresses?.neighborhood}</span>,
  },

  {
    name: "enchentesStatus",
    field: "Situação",
  },
];

const PanelEnchestesRs: React.FC<PanelColorsProps> = ({ seller }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const query = useQueryParams();

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });
  const [filters, setFilters] = useState<ClientFilters>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });

  const [viewFilter, setViewFilter] = useState(false);
  const { data, isLoading, isFetching, refetch } = useClientsEnchenteRS(page, {
    ...filters,
    uf: "RS",
    hasOrder: true,
    orderType: "venda-futura",
    billingOrderPeriod: 3,
    billingOrderStartDate: "2024-05-01",
    billingOrderEndDate: "2024-07-30",
  });

  const formikFilter = useFormik({
    initialValues: {
      cod: "",
      company_name: "",
      cnpj: "",
      city: "",
      neighborhood: "",
      enchentesStatus: "",
    },
    onSubmit: (data) => {
      handleFilter(data);
    },
  });

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  function handleFilter(data: ClientFilters) {
    setFilters(data);
    setPage(1);
    setViewFilter(false);
  }

  async function handleExportList() {
    addToast("Gerando...", {
      appearance: "info",
      autoDismiss: true,
    });

    const getAllClients = await getClientsEnchenteRS(
      1,
      {
        ...filters,
        uf: "RS",
        hasOrder: true,
        orderType: "venda-futura",
        billingOrderPeriod: 3,
        billingOrderStartDate: "2024-05-01",
        billingOrderEndDate: "2024-07-30",
      },
      99999
    );

    let data = [];
    for (const client of getAllClients.clients) {
      let row: any = {
        CÓD: client.cod,
        "RAZÃO SOCIAL": client.company_name,
        CNPJ: client.cnpj,
        CIDADE: client.adresses?.city,
        BAIRRO: client.adresses?.neighborhood,
        "TELEFONE 1": client.phone,
        "TELEFONE 2": client.phone2,
        CELULAR: client.cellPhone,
        SITUAÇÃO: statusTable.find((f) => f.value === statusNormalized(client))
          ?.label,
      };

      for (const enchente of client?.client_enchete_rs ?? []) {
        const maio = enchente?.invoces_period.find(
          (f) => f.period === "05-2024"
        );
        const junho = enchente?.invoces_period.find(
          (f) => f.period === "06-2024"
        );
        const julho = enchente?.invoces_period.find(
          (f) => f.period === "07-2024"
        );

        row = {
          ...row,
          MARCA: enchente?.brand?.name ?? "-",
          REPRESENTANTE: enchente?.seller
            ? `${enchente?.seller?.cod}-${enchente?.seller?.abbreviation}`
            : "-",
          "PREVISÃO DE ABERTURA":
            enchente?.reopening === true
              ? moment(enchente?.openingForecast).format("DD/MM/yyyy")
              : "-",
          "FUTURAR EM MAIO 2024":
            enchente?.reopening === false && maio
              ? maio?.invoice === true
                ? "SIM"
                : "NÃO"
              : "-",
          "FUTURAR EM JUNHO 2024":
            enchente?.reopening === false && junho
              ? enchente?.invoces_period.find((f) => f.period === "06-2024")
                  ?.invoice
                ? "SIM"
                : "NÃO"
              : "-",
          "FUTURAR EM JULHO 2024":
            enchente?.reopening === false && julho
              ? enchente?.invoces_period.find((f) => f.period === "07-2024")
                  ?.invoice
                ? "SIM"
                : "NÃO"
              : "-",
        };
      }

      data.push(row);
    }

    await generateXlsx<any>({
      filename: `Exportar gestão clientes RS`,
      data: data ?? [],
    });
  }

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de Clientes Rio Grande do Sul" />

      <TableStatus
        title="SITUAÇÕES"
        data={statusTable}
        onPress={(status) => {
          setFilters((old) => ({ ...old, enchentesStatus: status }));
        }}
      />

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={(value, label) =>
          label === "enchentesStatus"
            ? statusTable.find((f) => f.value === value)?.label
            : value
        }
      >
        <GroupInput>
          <Input label="Cód" {...formikFilter.getFieldProps("cod")} />
          <Input
            label="Razão social"
            {...formikFilter.getFieldProps("company_name")}
          />
          <Input label="CNPJ" {...formikFilter.getFieldProps("cnpj")} />
        </GroupInput>
        <GroupInput>
          <Select
            label="Cidade"
            {...formikFilter.getFieldProps("city")}
            data={
              data?.filters.city.map((city) => ({
                name: city.name,
                value: city.id,
              })) ?? []
            }
          />
          <Select
            label="Bairro"
            {...formikFilter.getFieldProps("neighborhood")}
            data={
              data?.filters.neighborhood.map((neighborhood) => ({
                name: neighborhood.name,
                value: neighborhood.id,
              })) ?? []
            }
          />
        </GroupInput>
      </PanelFilter>

      <TableHeader title="CLIENTES" isFetching={isFetching && !isLoading}>
        <button style={{ background: "#1d6f42" }} onClick={handleExportList}>
          <RiFileExcel2Fill size={19} />
          <span>Exportar</span>
        </button>
        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>
      </TableHeader>

      <TableContent<Client>
        data={data?.clients}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) =>
          history.push(
            seller
              ? `/representante/enchestes-rs/${e.id}`
              : `/admin/enchestes-rs/${e.id}`
          )
        }
      />
      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={15}
      />
    </PanelLayout>
  );
};

export default PanelEnchestesRs;
