import React from "react";
import Chart from "react-apexcharts";
import { BoxAnalytic } from "../../pages/analytic/styles";

interface BoxAnalyticCharAreaProps {
  title: string;
  values: {
    name: string;
    length: number;
  }[];

  isAmount?: boolean;
  obs?: JSX.Element;
}

export const BoxAnalyticCharDonut = ({
  title,
  values,
  obs,
  isAmount = false,
}: BoxAnalyticCharAreaProps) => {
  return (
    <BoxAnalytic>
      <h3>{title}</h3>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "100%" }}>
          <Chart
            type="donut"
            height={260}
            width="100%"
            series={values.map((item) => item.length)}
            options={{
              series: values.map((item) => item.length),
              labels: values.map((item) => item.name),
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return isAmount
                      ? value.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })
                      : String(value);
                  },
                },
              },
            }}
          />
        </div>

        {obs && <span className="span-alert">{obs}</span>}
      </div>
    </BoxAnalytic>
  );
};
