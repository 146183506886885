export function addressAbbreviation(address: string) {
  const normalized = address
    .toString()
    .toLocaleUpperCase()
    .replace(new RegExp("ADVOGADO", "g"), "ADV")
    .replace(new RegExp("ALMIRANTE", "g"), "ALM")
    .replace(new RegExp("ALTO", "g"), "ALTO")
    .replace(new RegExp("ANDAR", "g"), "AND")
    .replace(new RegExp("APARTAMENTO", "g"), "APTO")
    .replace(new RegExp("ARCEBISPO", "g"), "ARCEB")
    .replace(new RegExp("ARQUITETO", "g"), "ARQUITETO")
    .replace(new RegExp("ATALHO", "g"), "ATALHO")
    .replace(new RegExp("AVENIDA", "g"), "AV")
    .replace(new RegExp("BALNEÁRIO", "g"), "BAL")
    .replace(new RegExp("BARÃO", "g"), "BARÃO")
    .replace(new RegExp("BARONESA", "g"), "BARONESA")
    .replace(new RegExp("BECO", "g"), "BECO")
    .replace(new RegExp("BLOCO", "g"), "BL")
    .replace(new RegExp("BOSQUE", "g"), "BQ")
    .replace(new RegExp("BOX", "g"), "BOX")
    .replace(new RegExp("BRANCA", "g"), "BCA")
    .replace(new RegExp("BRANCO", "g"), "BCO")
    .replace(new RegExp("BRIGADEIRO", "g"), "BRIGAD")
    .replace(new RegExp("CAMPO", "g"), "CAMPO")
    .replace(new RegExp("CAPITÃO", "g"), "CAPITAO")
    .replace(new RegExp("CASA", "g"), "CS")
    .replace(new RegExp("CHÁCARA", "g"), "CHAC")
    .replace(new RegExp("CHAPADÃO", "g"), "CHAP")
    .replace(new RegExp("CIDADE", "g"), "CID")
    .replace(new RegExp("COLÔNIA", "g"), "COLONIA")
    .replace(new RegExp("COMANDANTE", "g"), "COMANDANTE")
    .replace(new RegExp("COMENDADOR", "g"), "CDOR")
    .replace(new RegExp("COMERCIAL", "g"), "COML")
    // .replace(new RegExp("COMERCIO", "g"), "COM")
    .replace(new RegExp("CONDE", "g"), "CONDE")
    .replace(new RegExp("CONDOMÍNIO", "g"), "COND")
    .replace(new RegExp("CONJUNTO", "g"), "CONJ")
    .replace(new RegExp("COMPLEXO", "g"), "COMPX")
    .replace(new RegExp("CONSELHEIRO", "g"), "CONS")
    .replace(new RegExp("CORONEL", "g"), "CEL")
    .replace(new RegExp("DEPUTADO", "g"), "DEP")
    .replace(new RegExp("DESEMBARGADOR", "g"), "DESEMB")
    .replace(new RegExp("DISTRITO", "g"), "DIST")
    .replace(new RegExp("DOM", "g"), "DOM")
    .replace(new RegExp("DONA", "g"), "DONA")
    .replace(new RegExp("DOUTOR", "g"), "DR")
    .replace(new RegExp("DOUTORA", "g"), "DRA")
    .replace(new RegExp("DUQUE", "g"), "DUQUE")
    .replace(new RegExp("DUQUESA", "g"), "DUQUESA")
    .replace(new RegExp("EDIFÍCIO", "g"), "ED")
    .replace(new RegExp("EMBAIXADOR", "g"), "EMB ")
    .replace(new RegExp("ENGENHEIRO", "g"), "ENG ")
    .replace(new RegExp("ESPLANADA", "g"), "ESPL")
    .replace(new RegExp("ESTRADA", "g"), "EST")
    .replace(new RegExp("EXPEDICIONÁRIO", "g"), "EXP")
    .replace(new RegExp("FAVELA", "g"), "FAV")
    .replace(new RegExp("FAZENDA", "g"), "FAZ")
    .replace(new RegExp("FERROVIA", "g"), "FERR")
    .replace(new RegExp("FONTE", "g"), "FONTE")
    .replace(new RegExp("FREGUESIA", "g"), "FREGUESIA")
    .replace(new RegExp("FREI", "g"), "FREI")
    .replace(new RegExp("FUNDOS", "g"), "FUNDOS")
    .replace(new RegExp("GALERIA", "g"), "GAL")
    .replace(new RegExp("GENERAL", "g"), "GEN")
    .replace(new RegExp("GOVERNADOR", "g"), "GOV")
    .replace(new RegExp("GRANDE", "g"), "GDE")
    .replace(new RegExp("GRANJA", "g"), "GRANJA")
    .replace(new RegExp("INDUSTRIAL", "g"), "INDL")
    .replace(new RegExp("INDUSTRIA", "g"), "IND")
    .replace(new RegExp("JARDIM", "g"), "JD")
    .replace(new RegExp("JUNIOR", "g"), "JR")
    .replace(new RegExp("LAGO", "g"), "LAGO")
    .replace(new RegExp("LAGOA", "g"), "LAGOA")
    .replace(new RegExp("LINHA", "g"), "LINHA")
    .replace(new RegExp("LOTEAMENTO", "g"), "LOT")
    .replace(new RegExp("MAESTRO", "g"), "MAESTRO")
    .replace(new RegExp("MAJOR", "g"), "MAJ")
    .replace(new RegExp("MARECHAL", "g"), "MAL")
    .replace(new RegExp("MARGEM", "g"), "MARGEM")
    .replace(new RegExp("MARQUES", "g"), "MARQUES")
    .replace(new RegExp("MINISTRO", "g"), "MIN")
    .replace(new RegExp("MONSENHOR", "g"), "MONSENHOR")
    .replace(new RegExp("NOSSA", "g"), "NSA")
    .replace(new RegExp("PADRE", "g"), "PE")
    .replace(new RegExp("PARADA", "g"), "PDA")
    .replace(new RegExp("PARQUE", "g"), "PQ")
    .replace(new RegExp("PASSAGEM", "g"), "PASSAGEM")
    .replace(new RegExp("PASTOR", "g"), "PASTOR")
    .replace(new RegExp("PAVIMENTO", "g"), "PAV ")
    .replace(new RegExp("PÁTIO", "g"), "PATIO")
    .replace(new RegExp("PIQUETE", "g"), "PIQ")
    .replace(new RegExp("PLANALTO", "g"), "PLANALTO")
    .replace(new RegExp("PONTE", "g"), "PONTE")
    .replace(new RegExp("PRAÇA", "g"), "PC")
    .replace(new RegExp("PREFEITO", "g"), "PREF")
    .replace(new RegExp("PRESIDENTE", "g"), "PRES")
    .replace(new RegExp("PRETO", "g"), "PTO")
    .replace(new RegExp("PROFESSORA", "g"), "PROFA")
    .replace(new RegExp("PROFESSOR", "g"), "PROF")
    .replace(new RegExp("REGENTE", "g"), "REGENTE")
    .replace(new RegExp("RODOVIA", "g"), "ROD")
    .replace(new RegExp("SALA", "g"), "SL")
    .replace(new RegExp("SANTA", "g"), "STA")
    .replace(new RegExp("SANTO", "g"), "STO")
    .replace(new RegExp("SARGENTO", "g"), "SRG")
    .replace(new RegExp("SENADOR", "g"), "SEN.")
    .replace(new RegExp("SENHORA", "g"), "SRA")
    .replace(new RegExp("SENHOR", "g"), "SR")
    .replace(new RegExp("SETOR", "g"), "ST")
    .replace(new RegExp("SOLDADO", "g"), "SOLD")
    .replace(new RegExp("TENENTE", "g"), "TEN.")
    .replace(new RegExp("TERMINAL", "g"), "TERM")
    .replace(new RegExp("TÉRREO", "g"), "TERR")
    .replace(new RegExp("TRAVESSA", "g"), "TRAV")
    .replace(new RegExp("VALE", "g"), "VALE")
    .replace(new RegExp("VERDE", "g"), "VDE")
    .replace(new RegExp("VEREADOR", "g"), "VER")
    .replace(new RegExp("VERMELHO", "g"), "VERM")
    .replace(new RegExp("VIGÁRIO", "g"), "VIG")
    .replace(new RegExp("VILA", "g"), "VL")
    .replace(new RegExp("VISCONDE", "g"), "VISC")
    .replace(new RegExp("PAVILHÃO", "g"), "PAV ");

  return normalized;
}
