import React from "react";
import useCookiesConsent from "../../hook/useCookiesConsent";

import { Container } from "./styles";

export function AcceptdCookies() {
  const { cookiesConsentAccept, setCookiesConsentAccept } = useCookiesConsent();
  return (
    <Container>
      <nav className={`content ${!cookiesConsentAccept ? "on" : "close"}`}>
        <p>Cookies</p>

        <span>
          Este site utiliza cookies para te proporcionar uma melhor experiência.
          Clique em <u> Saber mais</u> para saber mais sobre como usamos e
          cuidamos dos seus dados.
        </span>

        <div>
          <button type="button" onClick={() => setCookiesConsentAccept(true)}>
            Eu entendo
          </button>
          <a
            // eslint-disable-next-line
            target="_blank"
            href="https://privacidade.alpardobrasil.com.br/"
            className="profile-details"
          >
            Saber mais
          </a>
        </div>
      </nav>
    </Container>
  );
}
