import styled from "styled-components";

export const Container = styled.div<{ disabledHover: boolean }>`
  width: 100%;

  max-width: 100vw;
  font-size: 0.765rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #eee;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 10px;

    thead {
      background-color: #eee;

      tr {
        th {
          z-index: 1;
          background-color: #eee;
          position: sticky;
          top: 0;
          padding: 6px 8px;
          font-size: 16px;
          color: #444;
          white-space: nowrap;
        }
      }
    }

    tbody {
      background-color: #fff;

      tr {
        color: #777;
        border-bottom: 2px solid #eee;
        cursor: ${(props) => (props.disabledHover ? undefined : "pointer")};

        &:hover {
          background-color: ${(props) =>
            props.disabledHover ? undefined : "#eee"};
        }

        .containerTracking {
          display: flex;
          align-items: center;
          justify-content: center;

          background: transparent;

          width: 100%;
          height: 100%;

          button,
          a {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            width: 80px;
            max-width: 100%;
            height: 28px;
            color: #000;

            background-color: #ddd;
            padding: 5px 8px;
            border: 2px solid #333;

            border-radius: 6px;
            font-weight: 400;
            font-size: 11px;

            transition: all 0.2s ease-in;

            span {
              margin-right: 2px;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }

        .actionsPanel {
          display: flex;
          justify-content: space-evenly;
          cursor: auto;

          button {
            background: transparent;
            svg {
              font-size: 22px;
            }

            transition: all 0.2s linear;

            &:hover {
              opacity: 0.8;
              transform: scale(1.2);
            }
          }
        }

        td {
          position: relative;
          height: 35px;
          text-align: center;
          padding: 0 6px;

          .notification {
            display: flex;
            align-items: center;
            position: absolute;
            left: 8px;
            top: 0px;
            height: 100%;
          }
          .status {
            color: #000;
            font-size: 13px;
            font-weight: 500;
            border-radius: 4px;
            padding: 2px 5px;
            white-space: nowrap;
          }

          .stuationContainer {
            background-color: transparent;
            width: 100%;

            div {
              width: 40px;
              margin: 0 auto;
            }

            svg {
              transition: transform 0.2s ease-in-out;

              &:hover {
                transform: scale(1.3);
              }
            }
          }
        }
      }
    }

    tbody {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
`;

export const ContainerLoadingPanel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fff;

  padding: 30px 0;
`;

export const Text = styled.p`
  padding: 1rem;
  font-size: 1rem;
  color: #333;
`;
