import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type ShowFile = {
  id: number;
  name: string;
  description: string;
  link: string;
  keyVideo: string;

  brands: {
    id: number;
    name: string;
    cod: number;
  }[];
};

type GetShowFilesResponse = {
  showFiles: ShowFile[];
  totalCount: number;
};

interface ShowFileApiResponse extends ResponsePanelApiDefault {
  contents: ShowFile[];
}

export async function getShowFiles(
  page: number,
  search?: string,
  registersPerPag?: number
): Promise<GetShowFilesResponse> {
  const { data } = await api.get<ShowFileApiResponse>("/showFiles", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag, search },
  });

  const showFiles = data.contents.map((item) => ({
    ...item,
    keyVideo: videoKeyParser(item.link),
  }));
  const totalCount = data.total;

  return {
    totalCount,
    showFiles,
  };
}

export function useShowFiles(
  page: number,
  search?: string,
  registersPerPag?: number
): UseQueryResult<GetShowFilesResponse> {
  return useQuery(
    ["showFiles", page, search],
    () => getShowFiles(page, search, registersPerPag)
    // {
    //   staleTime: 1000 * 60 * 10, // 10 Minutos
    // }
  );
}

export async function getOneShowFile(
  id?: number
): Promise<ShowFile | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<ShowFile>(`/showFiles/show/${id}`);

  return data;
}

export function useOneShowFile(
  id?: number
): UseQueryResult<ShowFile | undefined> {
  return useQuery(["showFile", id], () => getOneShowFile(id), {
    // staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}

function videoKeyParser(url: string): string {
  if (!url) return "";

  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);

  return match && match[7].length === 11 ? match[7] : "";
}
