import React, { useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { MdAddBox } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import Modal from "../../../../components/ModalLib";
import api from "../../../../service/api";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import { RowNone, TabletSelect } from "./styles";

import {
  Form,
  ModalBody,
  TableContainer,
  Filter,
  FooterContainer,
  ContainerList,
  FilterSelects,
  MainFilter,
  FilterCheck,
} from "../../../create/styles";

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IResponseAPI {
  contents: any[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

export interface UserProps {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
  created_at?: Date;

  permission: {
    name: true;
  };
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface ProviderComponentProps {
  title: string;

  setUsersLinks: React.Dispatch<React.SetStateAction<UserProps[]>>;
  usersLinks: UserProps[];

  disabled?: boolean;
}

interface IFilter {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
}

export const SelectUser: React.FC<ProviderComponentProps> = ({
  title,
  setUsersLinks,
  usersLinks,

  disabled = false,
}) => {
  const outSideRefFilter = useRef(null);

  const [isDisabledModal, setIsDisabledModal] = useState<boolean>(false);

  const [providers, setProviders] = useState<UserProps[]>([]);
  const [filters, setFilters] = useState<IFilter>({});
  const [filterView, setFilterView] = useState(false);

  const [modalVisibleProvider, setModalVisibleProvider] = useState(false);
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 10 } as IRequestPagination);

  const formikFilter = useFormik({
    initialValues: {
      id: filters.id,
      name: filters.name,
      lastname: filters.lastname,
      email: filters.email,
    },

    validationSchema: Yup.object({}),

    onSubmit: (data) => {
      hadleFilter(data);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const contentsDB = await api.get<IResponseAPI>("/userAdmin", {
          params: { ...requestPagination, ...filters },
        });

        const { limit, page, pages, total } = contentsDB.data;

        setProviders(contentsDB.data.contents);
        setPagination({ limit, page, pages, total });
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, requestPagination]);

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }
  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }
  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setFilterView(false);
  }

  function handleSelectProvider(provider: UserProps) {
    const existProvider = usersLinks.find((f) => f.id === provider.id);

    if (!existProvider) {
      setUsersLinks([...usersLinks, provider]);
    }

    if (!isDisabledModal) setModalVisibleProvider(false);
  }
  function handleRemoveProvider(provider: UserProps) {
    const providersNoRemove = usersLinks.filter((f) => f.id !== provider.id);

    setUsersLinks([...providersNoRemove]);
  }

  return (
    <>
      <div className="container-subtitle">
        <h3>{title}</h3>

        {!disabled && (
          <button
            type="button"
            className="action-button"
            onClick={() => {
              setModalVisibleProvider(true);
            }}
          >
            <MdAddBox />
          </button>
        )}
      </div>

      <TabletSelect>
        <thead>
          <tr>
            <th>Cód</th>
            <th>Email</th>
            {!disabled && <th>Ação</th>}
          </tr>
        </thead>

        <tbody>
          {usersLinks.map((provider, indexData) => (
            <tr key={indexData}>
              <td>{provider.id}</td>
              <td>{provider.email}</td>
              {!disabled && (
                <td>
                  <button
                    type="button"
                    className="action-button"
                    onClick={() => {
                      handleRemoveProvider(provider);
                    }}
                  >
                    <IoMdClose />
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </TabletSelect>

      {usersLinks.length < 1 && (
        <RowNone>Não há registros a serem exibidos</RowNone>
      )}

      <Modal
        title="Vincular usuário"
        modalVisible={modalVisibleProvider}
        setModalVisible={setModalVisibleProvider}
        maxWidth={660}
        maxHeight={600}
        isDisabled={isDisabledModal}
        setIsDisabled={setIsDisabledModal}
      >
        <ModalBody>
          <Filter>
            <button
              onClick={() => {
                setFilterView(!filterView);
              }}
              type="button"
            >
              <FiSearch />
            </button>
            <input
              onClick={() => {
                setFilterView(!filterView);
              }}
              type="text"
              name="filter"
              id="filter"
              readOnly={true}
            />
            <FilterSelects>
              <ul>
                {filters.id && (
                  <li>
                    <span>Cód: {filters.id}</span>{" "}
                    <IoMdCloseCircle
                      onClick={() => setFilters({ ...filters, id: "" })}
                    />
                  </li>
                )}
                {filters.name && (
                  <li>
                    <span>Nome: {filters.name}</span>{" "}
                    <IoMdCloseCircle
                      onClick={() => setFilters({ ...filters, name: "" })}
                    />
                  </li>
                )}
                {filters.lastname && (
                  <li>
                    <span>Sobrenome: {filters.lastname}</span>{" "}
                    <IoMdCloseCircle
                      onClick={() => setFilters({ ...filters, lastname: "" })}
                    />
                  </li>
                )}
                {filters.email && (
                  <li>
                    <span>Email: {filters.email}</span>{" "}
                    <IoMdCloseCircle
                      onClick={() => setFilters({ ...filters, email: "" })}
                    />
                  </li>
                )}
              </ul>
            </FilterSelects>

            {filterView && (
              <FilterCheck ref={outSideRefFilter}>
                <MainFilter>
                  <Form onSubmit={formikFilter.handleSubmit}>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="id">Cód.</label>
                        <input id="id" {...formikFilter.getFieldProps("id")} />
                      </div>
                      <div className="field">
                        <label htmlFor="name">Nome</label>
                        <input
                          id="name"
                          {...formikFilter.getFieldProps("name")}
                        />
                      </div>
                    </div>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="lastname">Sobrenome</label>
                        <input
                          id="lastname"
                          {...formikFilter.getFieldProps("lastname")}
                        />
                      </div>
                      <div className="field">
                        <label htmlFor="email">Email</label>
                        <input
                          id="email"
                          {...formikFilter.getFieldProps("email")}
                        />
                      </div>
                    </div>
                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                </MainFilter>
              </FilterCheck>
            )}
          </Filter>
          <ContainerList>
            <TableContainer>
              <table>
                <thead>
                  <tr>
                    <th>CÓDIGO</th>
                    <th>NOME</th>
                    <th>EMAIL</th>
                    <th>MARCA</th>
                  </tr>
                </thead>

                <tbody>
                  {providers.map((provider: UserProps) => (
                    <tr
                      key={provider.id}
                      onClick={() => handleSelectProvider(provider)}
                    >
                      <td> {provider.id} </td>
                      <td> {`${provider.name} ${provider.lastname}`} </td>
                      <td> {provider.email} </td>
                      <td> {provider.permission.name} </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
            <FooterContainer>
              <span>
                Mostrando {providers.length} de {pagination.total} resultados
              </span>

              <div>
                <button
                  className={pagination.page <= 0 ? "buttonNull" : ""}
                  type="button"
                  onClick={pagination.page <= 0 ? () => {} : hadlePreviousPage}
                >
                  ANTERIOR
                </button>
                <p style={{ marginRight: 8 }}>{pagination.page + 1}</p>
                <button
                  className={
                    pagination.page >= pagination.pages ? "buttonNull" : ""
                  }
                  type="button"
                  onClick={
                    pagination.page >= pagination.pages
                      ? () => {}
                      : hadleNextPage
                  }
                >
                  PROXIMO
                </button>
              </div>
            </FooterContainer>
          </ContainerList>
        </ModalBody>
      </Modal>
    </>
  );
};
