import React from "react";
import { ImZoomIn } from "react-icons/im";
import { Link } from "react-router-dom";
import { BoxAnalytic } from "../../pages/analytic/styles";
import LoadingDot from "../loadings/LoadingDot";

interface BoxAnalyticCountProps {
  description: string;
  externalLink?: string;
  obs?: string;
  qtd: number;
  isLoading?: boolean;
  isNotColor?: boolean;
  minWidth?: string | number;
}

export const BoxAnalyticCount = ({
  description,
  qtd,
  obs,
  externalLink,
  isLoading = false,
  isNotColor,
  minWidth,
}: BoxAnalyticCountProps) => {
  return (
    <BoxAnalytic style={{ minWidth }}>
      {externalLink && (
        <nav className="navExternalLink">
          <Link to={externalLink}>
            <ImZoomIn size={22} color="#d21e26" />
          </Link>
        </nav>
      )}

      <h3>{description}</h3>

      <div style={{ padding: "6px 0 " }}>
        <p
          style={{
            fontSize: "2rem",
            fontWeight: 700,
            color: isNotColor
              ? "rgba(0, 143, 251, 0.85)"
              : Number(qtd) <= 0
              ? "rgba(0, 143, 251, 0.85)"
              : "#d21e26",
          }}
        >
          {!isLoading ? qtd : <LoadingDot />}
        </p>
      </div>

      {obs && <span className="span-alert">*{obs}</span>}
    </BoxAnalytic>
  );
};
