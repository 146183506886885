import React, { useEffect, useRef, useState } from "react";

import useOutsideAlerter from "../../hook/outsideAlerter";
import selectIcon from "../../utils/selectIcon";
import {
  BodyModal,
  Container,
  ContainerPage,
  HeaderModal,
  ModalContainer,
} from "./styles";

import { IoMdClose } from "react-icons/io";
import { IoChevronBack, IoChevronForwardOutline } from "react-icons/io5";
import { downloadFilaBase64 } from "../../utils/downloadFilaBase64";

interface IFile {
  id: string;
  name: string;
  size: Number;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype?: string;
  fileBase64?: string;
}

interface IPropsHeader {
  setModalVisible: (value: React.SetStateAction<boolean>) => void;
  modalVisible: boolean;
  images: IFile[];
  indexImg: number;
}

const Modal: React.FC<IPropsHeader> = ({
  setModalVisible,
  modalVisible,
  images,
  indexImg,
}) => {
  const [indexImage, setIndexImage] = useState<number>();
  const outSideRef = useRef(null);

  useEffect(() => {
    setIndexImage(indexImg);
  }, [indexImg]);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "ArrowLeft") {
        previousImg();
      }

      if (e.key === "ArrowRight") {
        nextImg();
      }

      if (e.key === "Escape") {
        closeModal();
      }
    });
  });

  function closeModal() {
    setModalVisible(false);
  }

  function nextImg() {
    if (indexImage !== undefined) {
      if (indexImage < images.length - 1) setIndexImage(indexImage + 1);
    }
    return;
  }

  function previousImg() {
    if (indexImage) {
      if (indexImage > 0) setIndexImage(indexImage - 1);
    }

    return;
  }

  useOutsideAlerter(outSideRef, () => {
    if (modalVisible) setModalVisible(false);
  });

  return (
    <>
      {indexImage !== undefined ? (
        <Container>
          <ModalContainer ref={outSideRef}>
            <HeaderModal>
              <div>
                {
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      downloadFilaBase64({
                        base64: images[indexImage].fileBase64 ?? "",
                        filename: images[indexImage].name,
                        mimetype: images[indexImage].mimetype ?? "",
                      })
                    }
                  >{`${images[indexImage].name}  `}</span>
                }
              </div>

              <div className="closeModal" onClick={closeModal}>
                <IoMdClose color="#FFF" size={35} />
              </div>
            </HeaderModal>
            <BodyModal>
              {images[indexImage].mimetype &&
              images[indexImage].mimetype === "application/pdf" ? (
                <div id="pdfContainer">
                  <embed
                    style={{
                      height: "100%",
                      width: "100%",
                      background: "white",
                    }}
                    title={images[indexImage].name}
                    src={`data:${images[indexImage].mimetype};base64,${images[indexImage].fileBase64}`}
                  ></embed>
                </div>
              ) : (
                <img
                  src={
                    selectIcon(images[indexImage].mimetype)
                      ? selectIcon(images[indexImage].mimetype)
                      : `data:${images[indexImage].mimetype};base64,${images[indexImage].fileBase64}`
                  }
                  alt={images[indexImage].name}
                  onClick={() => {
                    downloadFilaBase64({
                      base64: images[indexImage].fileBase64 ?? "",
                      filename: images[indexImage].name,
                      mimetype: images[indexImage].mimetype ?? "",
                    });
                  }}
                  style={{ cursor: "pointer" }}
                />
              )}
            </BodyModal>

            <ContainerPage>
              <button
                className="previous"
                style={{
                  display: indexImage > 0 ? undefined : "none",
                }}
                onClick={previousImg}
              >
                <IoChevronBack />
              </button>

              <button
                className="next"
                style={{
                  display: indexImage < images.length - 1 ? undefined : "none",
                }}
                onClick={nextImg}
              >
                <IoChevronForwardOutline />
              </button>
            </ContainerPage>
          </ModalContainer>
        </Container>
      ) : null}
    </>
  );
};

export default Modal;
