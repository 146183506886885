import { useQuery, UseQueryResult } from "react-query";
import { OptionsFilter } from "../../@types";
import api from "../../service/api";
import { ResponsePanelApiDefault } from "./../../@types/panelTypes.d";

export interface Concept {
  cod: number;
  description: string;
}

export type Client = {
  id: string;
  cod: number;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
  phone?: string;
  phone2?: string;
  cellPhone?: string;
  situation: number;
  is_active: boolean;
  created_at?: Date;
  lastOrderDate?: Date;
  lastOrderDateToString: string;

  concept?: Concept;

  registerGroup: {
    cod: number;
    name: string;
  };

  adresses?: {
    zip_code?: string;
    uf?: string;
    city?: string;
    street?: string;
    neighborhood?: string;
    latitude?: string;
    longitude?: string;
  };

  client_enchete_rs?: {
    id: number;
    email: string | null;
    phone: string | null;
    reopening: boolean | null;
    openingForecast: Date | null;
    seller: {
      cod: number;
      abbreviation: string;
    };
    brand: {
      name: string;
    };

    invoces_period: {
      id: number;
      amount: number;
      date: Date;
      period: string;
      invoice: boolean;
    }[];
  }[];

  access: {
    date: Date;
    dateToString: string;
  }[];

  isHasNetwork: boolean;
  qtdStores: string;
  cityPurchase: string;
  isCentralizedDelivery: boolean;
  cnpjToCentralizedDelivery: string;
  isStoreInShopping: boolean;
  managerNamePurchase: string;
  managerPhonePurchase: string;
  managerEmailPurchase: string;
  invoiceEmail: string;
  businessEmail: string;
  segment: string;

  logUpdateInternalData: {
    id: string;
    created_at: Date;
    created_atToString: string;
    user: {
      id: number;
      name: string;
      lastname: string;
    };
  }[];

  brands: {
    id: number;
    cod: number;
  }[];
  newbrands: {
    id: number;
    cod: number;
  }[];
  productTypes: {
    id: number;
    name: string;
  }[];

  lastImageUpdateDate?: Date;
};

export type ClientFilters = {
  cod?: string;
  company_name?: string;
  cnpj?: string;
  hasOrder?: boolean;
  status?: string;
  isClientContact?: string;
  uf?: string;
  orderType?: string;
  brandId?: number;
  billingOrderPeriod?: number;
  billingOrderStartDate?: string;
  billingOrderEndDate?: string;

  search?: string;

  updateImagePeriod?: number;
  updateImagePresetDate?: number;
  updateImageSpecificDate?: Date;
  updateImageStartDate?: Date;
  updateImageEndDate?: Date;
};

export interface FilterOptions {
  neighborhood: OptionsFilter[];
  city: OptionsFilter[];
  concept: OptionsFilter[];
}

type GetClientsResponse = {
  clients: Client[];
  filters: FilterOptions;
  totalCount: number;
};

interface ClientApiResponse extends ResponsePanelApiDefault {
  clients: Client[];
  filters: FilterOptions;
}

export async function getClients(
  page: number,
  filter?: ClientFilters,
  registersPerPag?: number
): Promise<GetClientsResponse> {
  const { data } = await api.get<ClientApiResponse>("/clientPJ", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag, ...filter },
  });

  const clients = data.clients;
  const totalCount = data.total;
  const filters = data.filters;

  return {
    totalCount,
    clients,
    filters,
  };
}

export function useClients(
  page: number,
  filter?: ClientFilters,
  registersPerPag?: number
): UseQueryResult<GetClientsResponse> {
  return useQuery(
    ["Clients", page, filter],
    () => getClients(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneClient(id?: number): Promise<Client | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<Client>(`/clientPJ/show/${id}`);

  const normalized = {
    ...data,

    concept_description: data.concept?.description,
    lastOrderDateToString: data.lastOrderDate
      ? new Date(data.lastOrderDate).toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })
      : "-",

    logUpdateInternalData: data?.logUpdateInternalData?.map((item) => ({
      ...item,
      created_atToString: new Date(item.created_at).toLocaleDateString(
        "pt-BR",
        {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      ),
    })),

    access: data.access?.map((item) => ({
      ...item,

      dateToString: new Date(item.date).toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }),
    })),
  };

  return normalized;
}

export function useOneClient(id?: number): UseQueryResult<Client> | undefined {
  return useQuery(["Client", id], () => getOneClient(id), {
    // staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}

export async function getOneClientByCod(cod: number): Promise<Client> {
  const { data } = await api.get<Client>(`/clientPJ/showByCod/${cod}`);

  const normalized = {
    ...data,

    concept_description: data.concept?.description,
    lastOrderDateToString: data.lastOrderDate
      ? new Date(data.lastOrderDate).toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })
      : "-",

    logUpdateInternalData: data?.logUpdateInternalData?.map((item) => ({
      ...item,
      created_atToString: new Date(item.created_at).toLocaleDateString(
        "pt-BR",
        {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      ),
    })),

    access: data.access?.map((item) => ({
      ...item,

      dateToString: new Date(item.date).toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }),
    })),
  };

  return normalized;
}
