import React from "react";

import { ContainerCheckbox } from "./styles";

interface InputCheckboxProps {
  height?: string;
  name: string;
  label?: string;
  error?: string;
  requiredOn?: boolean;

  data: CheckboxProps[];
  onChange: (a: string[]) => void;
  checks: string[];
}

export interface CheckboxProps {
  value: string;
  description: string;
}

export const InputCheckboxV2: React.FC<InputCheckboxProps> = ({
  name,
  label,
  data,
  height,

  onChange,
  checks,
}) => {
  function handleOnChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      onChange([...checks, e.target.value]);
    } else {
      onChange(checks.filter((f) => f !== e.target.value));
    }
  }

  return (
    <div className="field" style={{ height: height }}>
      <ContainerCheckbox style={{ height: height }}>
        {label && <label htmlFor={name}>{label}</label>}
        <div className="contentsCheckbox">
          {data?.map((element, index) => (
            <div key={index} className="boxCheckbox">
              <input
                type="checkbox"
                onChange={handleOnChangeCheckbox}
                checked={checks.includes(element.value)}
                value={element.value}
                name={`permission_fields`}
              />
              <label htmlFor="">{element.description}</label>
            </div>
          ))}
        </div>
      </ContainerCheckbox>
    </div>
  );
};
