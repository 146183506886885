import React, { useEffect, useState } from "react";

import api from "../../service/api";

import { useFormik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import * as Yup from "yup";
// @ts-ignore

import * as clientLocalStorage from "../../service/localStorage/client";
import * as providerLocalStorage from "../../service/localStorage/provider";
import * as sellerLocalStorage from "../../service/localStorage/seller";
import * as userLocalStorage from "../../service/localStorage/user";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { GroupInput } from "../../components/Form/GroupInput";
import { Input } from "../../components/Form/Input";
import { Select } from "../../components/Form/Select";
import Loading from "../../components/loadings/Loading";
import { setFormikValues } from "../../utils/setFormikValues";
import { BrandProps } from "../create/CreateShowFile";
import { ContainerCheck, ContainerCheckbox } from "../create/styles";
import { ClientFiles } from "../detail/Client/clientFiles";
import { ScreenLoading } from "../detail/styles";
import { ButtunSubmit, Container, ContainerForm, Error, Form } from "./styles";

interface HeaderProps {
  admin: boolean;
  client: boolean;
  seller: boolean;
  provider: boolean;
}

interface Client {
  id?: string;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  phone?: string;
  email?: string;
  created_at?: Date;

  adresses?: {
    zip_code?: string;
    uf?: string;
    city?: string;
    neighborhood?: string;
    street?: string;
    numberStreet?: string;
    latitude?: string;
    longitude?: string;
  };
}

interface User {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
}

interface Seller {
  cod: Number;
  id: Number;
  abbreviation: string;
  fullName: string;
  nextdata_login?: string;
  nextdata_password?: string;
  email: string;
  phone: string;
}

interface Provider {
  fullname: string;
  id: number;
  phone: string;

  acess_provider: {
    email: string;
  };

  brand: {
    cod: number;
    name: string;
  };
}

interface ProductTypeProps {
  id: string;
  name: string;
}

const Perfil: React.FC<HeaderProps> = ({ admin, client, seller, provider }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [productTypesDB, setProductTypesDB] = useState<ProductTypeProps[]>([]);
  const [brandsDB, setBrandsDB] = useState<BrandProps[]>([]);
  const [inputCheck, setInputCheck] = useState<any>({
    brands: {},
    newbrands: {},
    typesProduct: {},
  });

  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);

  const [clientStorage, setClientStorage] = useState<clientLocalStorage.Client>(
    {}
  );
  const sellerStorage = sellerLocalStorage.getUser() as Seller;
  const providerStorage = providerLocalStorage.getUser() as Provider;

  useEffect(() => {
    if (admin) {
      (async () => {
        try {
          const response = await api.get<User>(`/user/profile`);

          const { name, lastname, email } = response.data;
          formikUser.setFieldValue(`name`, name);
          formikUser.setFieldValue(`lastname`, lastname);
          formikUser.setFieldValue(`email`, email);

          setLoading(false);
        } catch (error) {
          addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      })();
    }

    if (clientStorage?.id) {
      (async () => {
        try {
          const response = await api.get<any>(
            `/clientconfig/${clientStorage.id}`
          );

          let inputCheckData = inputCheck;

          response?.data?.productTypes?.forEach((p: any) => {
            inputCheckData = {
              ...inputCheckData,
              typesProduct: { ...inputCheckData.typesProduct, [p.id]: true },
            };
          });
          response?.data?.brands?.forEach((b: any) => {
            inputCheckData = {
              ...inputCheckData,
              brands: { ...inputCheckData.brands, [b.id]: true },
            };
          });

          response?.data?.newbrands?.forEach((b: any) => {
            inputCheckData = {
              ...inputCheckData,
              newbrands: { ...inputCheckData.newbrands, [b.id]: true },
            };
          });

          setInputCheck(inputCheckData);
          setFormikValues(response.data, formikClient, setLoading);
        } catch (error) {
          addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      })();
    }

    if (seller) {
      (async () => {
        try {
          const response = await api.get<Seller>(
            `/sellerconfig/${sellerStorage.id}`
          );

          const {
            cod,
            abbreviation,
            email,
            fullName,
            phone,
            nextdata_login,
            nextdata_password,
          } = response.data;
          formikSeller.setFieldValue(`cod`, cod);
          formikSeller.setFieldValue(`abbreviation`, abbreviation);
          formikSeller.setFieldValue(`email`, email);
          formikSeller.setFieldValue(`fullName`, fullName);
          formikSeller.setFieldValue(`phone`, phone);
          formikSeller.setFieldValue(`nextdata_login`, nextdata_login);
          formikSeller.setFieldValue(`nextdata_password`, nextdata_password);

          setLoading(false);
        } catch (error) {
          addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      })();
    }

    if (provider) {
      (async () => {
        try {
          const response = await api.get<Provider>(
            `/provider/config/${providerStorage.id}`
          );

          const { id, brand, phone, fullname, acess_provider } = response.data;

          formikProvider.setFieldValue(`cod`, id);
          formikProvider.setFieldValue(`phone`, phone);
          formikProvider.setFieldValue(`fullname`, fullname);
          formikProvider.setFieldValue(`email`, acess_provider.email);
          formikProvider.setFieldValue(`brand`, brand?.name);
        } catch (error) {
          addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [admin, clientStorage, seller]);

  useEffect(() => {
    setClientStorage(clientLocalStorage.getUser());
  }, []);

  const formikClient = useFormik({
    initialValues: {
      cnpj: "",
      company_name: "",
      trade_name: "",
      email: "",
      emailInvoice: "",
      phone: "",
      phone2: "",
      cellPhone: "",

      adresses: {
        zip_code: "",
        uf: "",
        city: "",
        neighborhood: "",
        street: "",
        numberStreet: "",
      },

      isClientContact: true,
      isHasNetwork: null,
      qtdStores: null,
      cityPurchase: "",
      isCentralizedDelivery: null,
      cnpjToCentralizedDelivery: "",
      isStoreInShopping: null,
      instagram: "",
      managerNamePurchase: "",
      managerPhonePurchase: "",
      managerEmailPurchase: "",
      invoiceEmail: "",
      businessEmail: "",
      segment: "",
    },

    validationSchema: Yup.object({
      trade_name: Yup.string().required("É obrigatório"),
      email: Yup.string()
        .email("Informe um e-mail valido")
        .required("É obrigatório"),
      emailInvoice: Yup.string()
        .email("Informe um e-mail valido")
        .required("É obrigatório"),
      phone: Yup.string().required("É obrigatório"),
      phone2: Yup.string().required("É obrigatório"),
      cellPhone: Yup.string().required("É obrigatório"),

      adresses: Yup.object({
        zip_code: Yup.string().required("É obrigatório"),
        uf: Yup.string().required("É obrigatório"),
        city: Yup.string().required("É obrigatório"),
        neighborhood: Yup.string().required("É obrigatório"),
        street: Yup.string().required("É obrigatório"),
        numberStreet: Yup.string().required("É obrigatório"),
      }),

      isHasNetwork: Yup.boolean().required("É obrigatório"),
      qtdStores: Yup.string().when("isHasNetwork", {
        is: true,
        then: Yup.string().required("É obrigatório"),
      }),
      cityPurchase: Yup.string().when("isHasNetwork", {
        is: true,
        then: Yup.string().required("É obrigatório"),
      }),
      isCentralizedDelivery: Yup.boolean().when("isHasNetwork", {
        is: true,
        then: Yup.boolean().required("É obrigatório"),
      }),
      cnpjToCentralizedDelivery: Yup.string().when("isCentralizedDelivery", {
        is: true,
        then: Yup.string().required("É obrigatório"),
      }),
      isStoreInShopping: Yup.boolean().required("É obrigatório"),
      managerNamePurchase: Yup.string().required("É obrigatório"),
      managerPhonePurchase: Yup.string().required("É obrigatório"),
      managerEmailPurchase: Yup.string()
        .email("Informe um e-mail valido")
        .required("É obrigatório"),
      invoiceEmail: Yup.string()
        .email("Informe um e-mail valido")
        .required("É obrigatório"),
      businessEmail: Yup.string()
        .email("Informe um e-mail valido")
        .required("É obrigatório"),
      segment: Yup.string().required("É obrigatório"),
    }),

    onSubmit: (data) => {
      handleSubmitClient(data);
    },
  });

  const formikUser = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      email: "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Nome é obrigatório"),
      lastname: Yup.string().required("Sobrenome é obrigatório"),
      email: Yup.string()
        .email("informe um e-mail válido")
        .required("email é obrigatório"),
    }),

    onSubmit: (data) => {
      handleSubmitUser(data);
    },
  });

  const formikSeller = useFormik({
    initialValues: {
      cod: "",
      abbreviation: "",
      fullName: "",
      email: "",
      phone: "",
    },

    onSubmit: (data) => {
      handleSubmitClient(data);
    },
  });

  const formikProvider = useFormik({
    initialValues: {
      id: undefined,
      email: undefined,
      fullname: undefined,
      phone: undefined,
      brand: undefined,
    },

    onSubmit: (data) => {},
  });

  function getListValues(data: any) {
    if (data) {
      const keys = Object.keys(data);
      const values = Object.values(data);

      return keys
        .map((key, index) => (!!values[index] ? key : undefined))
        .filter((f) => f);
    }

    return [];
  }

  async function handleSubmitUser(user: User) {
    try {
      const response = await api.put<User>(`/user/profile`, user);
      userLocalStorage.setUser(response.data);
      addToast("Alterção de dados do perfil realizada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/home");
    } catch (error) {
      console.log(error);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleSubmitClient(client: Client) {
    try {
      setLoadingForm(true);

      await api.put<Client>(`/clientconfig/${clientStorage.id}`, {
        ...client,

        isClientContact: true,
        brands: getListValues(inputCheck.brands),
        newbrands: getListValues(inputCheck.newbrands),
        typesProduct: getListValues(inputCheck.typesProduct),
      });

      addToast(
        "Alteração de dados do perfil foi enviado para setor de cadastro",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );

      history.push("/home");
    } catch (error) {
      console.log(error);
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoadingForm(false);
    }
  }

  function setChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setInputCheck({
      ...inputCheck,
      [e.target.name]: {
        ...inputCheck[e.target.name],
        [e.target.value]: e.target.checked,
      },
    });
  }

  useEffect(() => {
    (async () => {
      if (client) {
        const brandDB = await api.get<BrandProps[]>("/populate/brand", {
          params: { active: 1, is_not_working: 1 },
        });
        setBrandsDB(brandDB.data);

        const productTypesDB = await api.get<any>("/populate/product_type");
        setProductTypesDB(productTypesDB.data.contents);
      }
    })();
  }, [client]);

  return (
    <Container>
      <header>
        <h2>Meu Perfil</h2>
      </header>

      <ContainerForm>
        {loading ? (
          <ScreenLoading style={{ height: "20vh" }}>
            <Loading
              colorLoading="#333"
              size={40}
              borderColor={"#888"}
              borderSize={5}
            />
          </ScreenLoading>
        ) : (
          <>
            {client && (
              <Form onSubmit={formikClient.handleSubmit}>
                <>
                  <div className="field">
                    <label htmlFor="cnpj">CNPJ</label>
                    <input
                      {...formikClient.getFieldProps("cnpj")}
                      readOnly={true}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="company_name">Razão social</label>
                    <input
                      {...formikClient.getFieldProps("company_name")}
                      readOnly={true}
                    />
                  </div>

                  <Input
                    label="Nome fantasia"
                    {...formikClient.getFieldProps("trade_name")}
                    error={
                      formikClient.touched.trade_name &&
                      formikClient.errors.trade_name
                        ? formikClient.errors.trade_name
                        : undefined
                    }
                  />

                  <GroupInput>
                    <Input
                      label="Email"
                      {...formikClient.getFieldProps("email")}
                      error={
                        formikClient.touched.email && formikClient.errors.email
                          ? formikClient.errors.email
                          : undefined
                      }
                    />

                    <Input
                      label="Email NFE"
                      {...formikClient.getFieldProps("emailInvoice")}
                      error={
                        formikClient.touched.emailInvoice &&
                        formikClient.errors.emailInvoice
                          ? formikClient.errors.emailInvoice
                          : undefined
                      }
                    />
                  </GroupInput>

                  <GroupInput>
                    <Input
                      label="Telefone"
                      {...formikClient.getFieldProps("phone")}
                      error={
                        formikClient.touched.phone && formikClient.errors.phone
                          ? formikClient.errors.phone
                          : undefined
                      }
                    />

                    <Input
                      label="Telefone 2"
                      {...formikClient.getFieldProps("phone2")}
                      error={
                        formikClient.touched.phone2 &&
                        formikClient.errors.phone2
                          ? formikClient.errors.phone2
                          : undefined
                      }
                    />
                  </GroupInput>

                  <Input
                    label="Celular"
                    {...formikClient.getFieldProps("cellPhone")}
                    error={
                      formikClient.touched.cellPhone &&
                      formikClient.errors.cellPhone
                        ? formikClient.errors.cellPhone
                        : undefined
                    }
                  />
                </>

                <h3>Endereço</h3>
                <>
                  <GroupInput>
                    <Input
                      label="CEP"
                      {...formikClient.getFieldProps("adresses.zip_code")}
                      error={
                        formikClient.touched.adresses?.zip_code &&
                        formikClient.errors?.adresses?.zip_code
                          ? formikClient.errors?.adresses?.zip_code
                          : undefined
                      }
                    />
                    <Input
                      label="UF"
                      {...formikClient.getFieldProps("adresses.uf")}
                      error={
                        formikClient.touched.adresses?.uf &&
                        formikClient.errors?.adresses?.uf
                          ? formikClient.errors?.adresses?.uf
                          : undefined
                      }
                    />
                  </GroupInput>
                  <GroupInput>
                    <Input
                      label="Cidade"
                      {...formikClient.getFieldProps("adresses.city")}
                      error={
                        formikClient.touched.adresses?.city &&
                        formikClient.errors?.adresses?.city
                          ? formikClient.errors?.adresses?.city
                          : undefined
                      }
                    />
                    <Input
                      label="Bairro"
                      {...formikClient.getFieldProps("adresses.neighborhood")}
                      error={
                        formikClient.touched.adresses?.neighborhood &&
                        formikClient.errors?.adresses?.neighborhood
                          ? formikClient.errors?.adresses?.neighborhood
                          : undefined
                      }
                    />
                  </GroupInput>

                  <GroupInput>
                    <Input
                      label="Logradouro"
                      {...formikClient.getFieldProps("adresses.street")}
                      error={
                        formikClient.touched.adresses?.street &&
                        formikClient.errors?.adresses?.street
                          ? formikClient.errors?.adresses?.street
                          : undefined
                      }
                    />
                    <Input
                      label="Número"
                      {...formikClient.getFieldProps("adresses.numberStreet")}
                      error={
                        formikClient.touched.adresses?.numberStreet &&
                        formikClient.errors?.adresses?.numberStreet
                          ? formikClient.errors?.adresses?.numberStreet
                          : undefined
                      }
                    />
                  </GroupInput>
                </>

                <h3>Dados do internos</h3>
                <>
                  <div className="field">
                    <label>Possui rede (mais de uma loja)</label>
                    <ContainerCheck>
                      <button
                        type="button"
                        className={
                          formikClient.values.isHasNetwork === true
                            ? "check"
                            : ""
                        }
                        onClick={() =>
                          formikClient.setFieldValue("isHasNetwork", true)
                        }
                      >
                        SIM
                      </button>

                      <button
                        type="button"
                        onClick={() =>
                          formikClient.setFieldValue("isHasNetwork", false)
                        }
                        className={
                          formikClient.values.isHasNetwork === false
                            ? "check"
                            : ""
                        }
                      >
                        NÃO
                      </button>
                    </ContainerCheck>

                    {formikClient.touched.isHasNetwork &&
                    formikClient.errors.isHasNetwork ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formikClient.errors.isHasNetwork} </span>
                      </Error>
                    ) : null}
                  </div>

                  {formikClient.values.isHasNetwork && (
                    <>
                      <GroupInput>
                        <Input
                          type="number"
                          label="Quantidade de lojas que possui"
                          {...formikClient.getFieldProps("qtdStores")}
                          error={
                            formikClient.touched.qtdStores &&
                            formikClient.errors.qtdStores
                              ? formikClient.errors.qtdStores
                              : undefined
                          }
                        />
                        <Input
                          label="Cidade/Estado da central de compras"
                          {...formikClient.getFieldProps("cityPurchase")}
                          error={
                            formikClient.touched.cityPurchase &&
                            formikClient.errors.cityPurchase
                              ? formikClient.errors.cityPurchase
                              : undefined
                          }
                        />
                      </GroupInput>
                      <div className="field">
                        <label>Entrega centralizada</label>
                        <ContainerCheck>
                          <button
                            type="button"
                            className={
                              formikClient.values.isCentralizedDelivery === true
                                ? "check"
                                : ""
                            }
                            onClick={() =>
                              formikClient.setFieldValue(
                                "isCentralizedDelivery",
                                true
                              )
                            }
                          >
                            SIM
                          </button>

                          <button
                            type="button"
                            onClick={() =>
                              formikClient.setFieldValue(
                                "isCentralizedDelivery",
                                false
                              )
                            }
                            className={
                              formikClient.values.isCentralizedDelivery ===
                              false
                                ? "check"
                                : ""
                            }
                          >
                            NÃO
                          </button>
                        </ContainerCheck>
                      </div>

                      {formikClient.values.isCentralizedDelivery && (
                        <Input
                          label="Qual CNPJ da entrega centralizada"
                          {...formikClient.getFieldProps(
                            "cnpjToCentralizedDelivery"
                          )}
                          error={
                            formikClient.touched.cnpjToCentralizedDelivery &&
                            formikClient.errors.cnpjToCentralizedDelivery
                              ? formikClient.errors.cnpjToCentralizedDelivery
                              : undefined
                          }
                        />
                      )}
                    </>
                  )}

                  <GroupInput>
                    <Input
                      label="Nome gerente da compra/comprador"
                      {...formikClient.getFieldProps("managerNamePurchase")}
                      error={
                        formikClient.touched.managerNamePurchase &&
                        formikClient.errors.managerNamePurchase
                          ? formikClient.errors.managerNamePurchase
                          : undefined
                      }
                    />
                    <Input
                      label="Telefone gerente da compra/comprador"
                      {...formikClient.getFieldProps("managerPhonePurchase")}
                      error={
                        formikClient.touched.managerPhonePurchase &&
                        formikClient.errors.managerPhonePurchase
                          ? formikClient.errors.managerPhonePurchase
                          : undefined
                      }
                    />
                  </GroupInput>

                  <div className="field">
                    <label>Loja em shopping</label>
                    <ContainerCheck>
                      <button
                        type="button"
                        className={
                          formikClient.values.isStoreInShopping === true
                            ? "check"
                            : ""
                        }
                        onClick={() =>
                          formikClient.setFieldValue("isStoreInShopping", true)
                        }
                      >
                        SIM
                      </button>

                      <button
                        type="button"
                        onClick={() =>
                          formikClient.setFieldValue("isStoreInShopping", false)
                        }
                        className={
                          formikClient.values.isStoreInShopping === false
                            ? "check"
                            : ""
                        }
                      >
                        NÃO
                      </button>
                    </ContainerCheck>

                    {formikClient.touched.isStoreInShopping &&
                    formikClient.errors.isStoreInShopping ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formikClient.errors.isStoreInShopping} </span>
                      </Error>
                    ) : null}
                  </div>

                  <GroupInput>
                    <Input
                      label="E-mail gerente de compra/comprador"
                      {...formikClient.getFieldProps("managerEmailPurchase")}
                      error={
                        formikClient.touched.managerEmailPurchase &&
                        formikClient.errors.managerEmailPurchase
                          ? formikClient.errors.managerEmailPurchase
                          : undefined
                      }
                    />
                    <Input
                      label="E-mail NF"
                      {...formikClient.getFieldProps("invoiceEmail")}
                      error={
                        formikClient.touched.invoiceEmail &&
                        formikClient.errors.invoiceEmail
                          ? formikClient.errors.invoiceEmail
                          : undefined
                      }
                    />
                    <Input
                      label="E-mail Comercial"
                      {...formikClient.getFieldProps("businessEmail")}
                      error={
                        formikClient.touched.businessEmail &&
                        formikClient.errors.businessEmail
                          ? formikClient.errors.businessEmail
                          : undefined
                      }
                    />
                  </GroupInput>

                  <Input
                    label="Instagram"
                    {...formikClient.getFieldProps("instagram")}
                    error={
                      formikClient.touched.instagram &&
                      formikClient.errors.instagram
                        ? formikClient.errors.instagram
                        : undefined
                    }
                  />

                  <GroupInput>
                    <div className="field">
                      <ContainerCheckbox style={{ height: 200 }}>
                        <label className="titleCheckbox" htmlFor="">
                          Informe marcas que voce trabalha atualmente:
                        </label>
                        <div className="contentsCheckbox">
                          {brandsDB.map((element, index) => (
                            <div key={index} className="boxCheckbox">
                              <input
                                type="checkbox"
                                onChange={setChangeCheckbox}
                                checked={
                                  inputCheck.brands[element.id] ? true : false
                                }
                                value={element.id}
                                name={`brands`}
                              />
                              <label>{element.name}</label>
                            </div>
                          ))}

                          {/* <div className="boxCheckbox">
                            <input
                              type="checkbox"
                              onChange={(a) => setOtherBrand(a.target.checked)}
                              checked={otherBrand}
                            />
                            <label>OUTRA</label>
                          </div> */}
                        </div>
                      </ContainerCheckbox>
                    </div>

                    <div className="field">
                      <ContainerCheckbox>
                        <label className="titleCheckbox" htmlFor="">
                          Deseja trabalhar com uma de nossas marca:
                        </label>
                        <div className="contentsCheckbox">
                          {brandsDB
                            .filter((f) => f.is_not_working === false)
                            .map((element, index) => (
                              <div key={index} className="boxCheckbox">
                                <input
                                  type="checkbox"
                                  onChange={setChangeCheckbox}
                                  checked={
                                    inputCheck.newbrands[element.id]
                                      ? true
                                      : false
                                  }
                                  value={element.id}
                                  name={`newbrands`}
                                />
                                <label>{element.name}</label>
                              </div>
                            ))}
                        </div>
                      </ContainerCheckbox>
                    </div>
                  </GroupInput>

                  <div className="field">
                    <ContainerCheckbox>
                      <label className="titleCheckbox" htmlFor="">
                        Tipos de produto
                      </label>
                      <div className="contentsCheckbox">
                        {productTypesDB.map((element) => (
                          <div key={element.id} className="boxCheckbox">
                            <input
                              type="checkbox"
                              onChange={setChangeCheckbox}
                              checked={
                                inputCheck.typesProduct[element.id]
                                  ? true
                                  : false
                              }
                              value={element.id}
                              name={`typesProduct`}
                            />
                            <label>{element.name}</label>
                          </div>
                        ))}
                      </div>
                    </ContainerCheckbox>
                  </div>

                  <GroupInput>
                    <Select
                      {...formikClient.getFieldProps("segment")}
                      error={
                        formikClient.touched.segment &&
                        formikClient.errors.segment
                          ? formikClient.errors.segment
                          : undefined
                      }
                      data={[
                        { name: "Sapataria", value: "Sapataria" },
                        { name: "Sneaker", value: "Sneaker" },
                        { name: "Boutique", value: "Boutique" },
                        { name: "Moda masculina", value: "Moda Masculina" },
                        { name: "Moda feminina", value: "Moda feminina" },
                        { name: "Moda ambos", value: "Moda ambos" },
                        {
                          name: "Especializado surf/skate",
                          value: "Especializado surf/skate",
                        },
                        {
                          name: "Especializado tennis/paddle/esportes com raquete",
                          value:
                            "Especializado tennis/paddle/esportes com raquete",
                        },
                        {
                          name: "Especializado loja infantil",
                          value: "Especializado loja infantil",
                        },
                        {
                          name: "Especializado loja futebol",
                          value: "Especializado loja futebol",
                        },
                        {
                          name: "Especializado running/crossfit/etc",
                          value: "Especializado running/crossfit/etc",
                        },
                        {
                          name: "Especializado chinelaria",
                          value: "Especializado chinelaria",
                        },
                      ]}
                      label="Segmento"
                    />
                  </GroupInput>
                </>

                <ButtunSubmit type="submit" disabled={loadingForm}>
                  {loadingForm ? (
                    <Loading size={20} borderSize={16} />
                  ) : (
                    "Alterar dados"
                  )}
                </ButtunSubmit>
              </Form>
            )}

            {seller && (
              <Form onSubmit={formikSeller.handleSubmit}>
                <div className="field-group">
                  <div className="field">
                    <label htmlFor="cod">Código</label>
                    <input
                      {...formikSeller.getFieldProps("cod")}
                      readOnly={true}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="abbreviation">Abreviação</label>
                    <input
                      {...formikSeller.getFieldProps("abbreviation")}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="fullName">Nome completo</label>
                  <input
                    {...formikSeller.getFieldProps("fullName")}
                    readOnly={true}
                  />
                </div>
                <div className="field-group">
                  <div className="field">
                    <label htmlFor="email">Email</label>
                    <input
                      {...formikSeller.getFieldProps("email")}
                      readOnly={true}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="phone">Telefone</label>
                    <input
                      {...formikSeller.getFieldProps("phone")}
                      readOnly={true}
                    />
                  </div>
                </div>

                <h3>Acessos Nextdata Commerce</h3>
                <div className="field-group">
                  <div className="field">
                    <label htmlFor="nextdata_login">Login</label>
                    <input
                      {...formikSeller.getFieldProps("nextdata_login")}
                      readOnly={true}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="nextdata_password">Senha</label>
                    <input
                      {...formikSeller.getFieldProps("nextdata_password")}
                      readOnly={true}
                    />
                  </div>
                </div>
              </Form>
            )}

            {admin && (
              <Form onSubmit={formikUser.handleSubmit}>
                <div className="field">
                  <label htmlFor="email">Email</label>
                  <input
                    {...formikUser.getFieldProps("email")}
                    readOnly={true}
                  />
                  {formikUser.touched.email && formikUser.errors.email ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formikUser.errors.email} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field-group">
                  <div className="field">
                    <label htmlFor="name">Nome</label>
                    <input {...formikUser.getFieldProps("name")} />
                    {formikUser.touched.name && formikUser.errors.name ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formikUser.errors.name} </span>
                      </Error>
                    ) : null}
                  </div>
                  <div className="field">
                    <label htmlFor="lastname">Sobrenome</label>
                    <input {...formikUser.getFieldProps("lastname")} />
                    {formikUser.touched.lastname &&
                    formikUser.errors.lastname ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formikUser.errors.lastname} </span>
                      </Error>
                    ) : null}
                  </div>
                </div>
                <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
              </Form>
            )}

            {provider && (
              <Form onSubmit={formikProvider.handleSubmit}>
                <h3>Dados do fornecedor </h3>

                <div className="field-group">
                  <div className="field">
                    <label htmlFor="cod">Código</label>
                    <input
                      style={{ borderStyle: "dashed" }}
                      {...formikProvider.getFieldProps("cod")}
                      readOnly={true}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="cod">Marca</label>
                    <input
                      style={{ borderStyle: "dashed" }}
                      {...formikProvider.getFieldProps("brand")}
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <label htmlFor="fullname">Nome Completo</label>
                  <input
                    id="fullname"
                    {...formikProvider.getFieldProps("fullname")}
                  />
                  {formikProvider.touched.fullname &&
                  formikProvider.errors.fullname ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formikProvider.errors.fullname} </span>
                    </Error>
                  ) : null}
                </div>

                <div className="field-group">
                  <div className="field">
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      {...formikProvider.getFieldProps("email")}
                    />
                    {formikProvider.touched.email &&
                    formikProvider.errors.email ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formikProvider.errors.email} </span>
                      </Error>
                    ) : null}
                  </div>
                  <div className="field">
                    <label htmlFor="phone">Telefone</label>
                    <input
                      id="phone"
                      {...formikProvider.getFieldProps("phone")}
                    />
                    {formikProvider.touched.phone &&
                    formikProvider.errors.phone ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formikProvider.errors.phone} </span>
                      </Error>
                    ) : null}
                  </div>
                </div>
              </Form>
            )}
          </>
        )}
      </ContainerForm>

      {clientStorage?.cod && (
        <>
          <header style={{ marginTop: "1.5rem" }}>
            <h2>Imagens</h2>
          </header>
          <ClientFiles clientCod={String(clientStorage.cod)} isOnlySend />
        </>
      )}
    </Container>
  );
};

export default Perfil;
