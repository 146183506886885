import React, { useState } from "react";

import { CreateAndDetailLayout } from "../../../components/CreateAndDetailLayout";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { useOneClient } from "../../../hook/queries/useClients";
import * as sellerLocalStorage from "../../../service/localStorage/seller";
import { ContainerForm } from "../styles";
import { EnchestesRsForm } from "./form";

interface HomeProps {
  admin?: boolean;
  seller?: boolean;

  match: {
    params: {
      clientId: number;
      sellerCod: number;
      brandName: string;
    };
  };
}

const Seller: React.FC<HomeProps> = ({ match, seller }) => {
  const getClient = useOneClient(match.params.clientId);
  const sellerStorage =
    sellerLocalStorage.getUser() as sellerLocalStorage.SellerProps;

  const [loading, setLoading] = useState(false);

  return (
    <CreateAndDetailLayout isLoading={getClient?.isLoading || loading}>
      <PanelAndDetailAndCreateHeader title={"Cliente"} goBack />

      <ContainerForm>
        <Input
          readOnly
          label="Código"
          name="cod"
          value={getClient?.data?.cod ?? "-"}
        />

        <Input
          readOnly
          label="CNPJ"
          name="cnpj"
          value={getClient?.data?.cnpj ?? "-"}
        />

        <GroupInput>
          <Input
            readOnly
            label="Razão social"
            name="company_name"
            value={getClient?.data?.company_name ?? "-"}
          />

          <Input
            readOnly
            label="Nome fantasia"
            name="trade_name"
            value={getClient?.data?.trade_name ?? "-"}
          />
        </GroupInput>

        <h3>Endereço</h3>
        <Input
          readOnly
          label="CEP"
          name="zip_code"
          value={getClient?.data?.adresses?.zip_code ?? "-"}
        />

        <GroupInput>
          <Input
            readOnly
            label="UF"
            name="uf"
            value={getClient?.data?.adresses?.uf ?? "-"}
          />
          <Input
            readOnly
            label="Cidade"
            name="city"
            value={getClient?.data?.adresses?.city ?? "-"}
          />
        </GroupInput>

        <GroupInput>
          <Input
            readOnly
            label="Bairro"
            name="neighborhood"
            value={getClient?.data?.adresses?.neighborhood ?? "-"}
          />
          <Input
            readOnly
            label="Logradouro"
            name="street"
            value={getClient?.data?.adresses?.street ?? "-"}
          />
        </GroupInput>
      </ContainerForm>

      {getClient?.data &&
        seller &&
        !sellerStorage.is_supervisor &&
        !sellerStorage.is_manager &&
        (getClient?.data.client_enchete_rs?.filter((f) =>
          seller ? f.seller.cod === sellerStorage.cod : true
        )?.length ?? 0) <= 0 && (
          <EnchestesRsForm setLoading={setLoading} client={getClient?.data} />
        )}

      {getClient?.data &&
        getClient?.data.client_enchete_rs
          ?.filter((f) =>
            seller && !sellerStorage.is_supervisor && !sellerStorage.is_manager
              ? f.seller.cod === sellerStorage.cod
              : Number(f.seller.cod) === Number(match.params.sellerCod)
          )
          ?.map((item) => (
            <EnchestesRsForm
              key={item.id}
              setLoading={setLoading}
              client={getClient?.data}
              enchestesRs={item}
              brandName={match.params.brandName}
            />
          ))}
    </CreateAndDetailLayout>
  );
};

export default Seller;
