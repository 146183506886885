import React from "react";
import { IconType } from "react-icons";
import Loading from "../Loading";
import Modal from "../ModalLib";
import { PopUpConfirmation } from "./styles";

interface Props {
  isLoading?: boolean;
  isViewModal: boolean;
  setIsViewModal: (b: boolean) => void;

  icon: IconType;
  title: string;
  buttonText: string;
  handle: () => void;
}

export function AlertInfo({
  setIsViewModal,
  icon: Icon,
  isViewModal,
  isLoading = false,
  buttonText,
  title,
  handle,
}: Props) {
  return (
    <Modal
      title="Selecione o cliente"
      modalVisible={isViewModal}
      setModalVisible={setIsViewModal}
      headerOff={true}
      maxHeight={350}
      maxWidth={650}
    >
      <PopUpConfirmation>
        <div className="headerPopUp">
          <nav>
            <Icon size={95} opacity={0.2} />
          </nav>
          <span>{title}</span>
        </div>
        <div className="buttonsPopUp">
          <button type="button" onClick={() => setIsViewModal(false)}>
            Cancelar
          </button>
          <button
            style={{ background: "#d21e26" }}
            type="button"
            onClick={() => (isLoading ? () => {} : handle())}
          >
            {!isLoading ? (
              buttonText
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Loading
                  size={22}
                  borderSize={4}
                  colorLoading="rgba(255,255,255)"
                  borderColor="rgba(255,255,255, 0.3)"
                />
              </div>
            )}
          </button>
        </div>
      </PopUpConfirmation>
    </Modal>
  );
}
