import React from "react";
import Modal from "react-modal";
import Routes from "./routes";

import { AcceptdCookies } from "./components/AcceptdCookies";

Modal.setAppElement("#root");

export const APP_LINK =
  process.env.NODE_ENV !== "development"
    ? "http://portal.alpardobrasil.com.br"
    : "http://localhost:3000";

function App() {
  return (
    <>
      <Routes />

      <AcceptdCookies />
    </>
  );
}

export default App;
