import React, { useEffect, useState } from "react";
import { IoReload } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { Banner, useBanners } from "../../../hook/queries/useBanners";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";

import { FieldProps, panelTypesDefault } from "../../../@types/panelTypes";

import { Search } from "../../../components/Search";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import { TableContent } from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";

interface PanelColorsProps extends panelTypesDefault {}

const columns: FieldProps[] = [
  {
    name: "id",
    field: "Id",
    viewList: false,
  },
  {
    name: "title",
    field: "Titulo",
    viewList: true,
  },
  {
    name: "created_atFormatted",
    field: "Criação",
    viewList: true,
  },
];

const PanelBanners: React.FC<PanelColorsProps> = () => {
  const history = useHistory();
  const query = useQueryParams();

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });
  const [search, setSearch] = useState(() => {
    const searchQuery = query.get("search");

    if (searchQuery && searchQuery.length > 0) {
      return String(searchQuery);
    }

    return "";
  });
  const { data, isLoading, isFetching, refetch } = useBanners(page, search);

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }

    if (search.length > 0) {
      setQueryParams({
        data: {
          field: "search",
          value: String(search),
        },
        type: "set",
        history,
      });
    } else {
      setQueryParams({
        data: {
          field: "search",
          value: String(search),
        },
        type: "delete",
        history,
      });
    }
  }, [page, history, search]);

  function handleNew() {
    history.push("/admin/criar/banners");
  }

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de Banners" />

      <Search
        handleSearch={(search) => {
          setPage(1);
          setSearch(search);
        }}
        currentSearch={search}
      />

      <TableHeader title="BANNERS" isFetching={isFetching && !isLoading}>
        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>
        <button onClick={handleNew} type="button">
          <MdAdd size={22} /> <span> NOVA </span>
        </button>
      </TableHeader>

      <TableContent<Banner>
        data={data?.banners}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) => history.push(`/admin/banners/${e.id}`)}
      />
      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
      />
    </PanelLayout>
  );
};

export default PanelBanners;
