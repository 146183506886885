import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Contact = {
  id: number;
  title: string;
  text: string;
  created_at: Date;
  created_atFormatted: String;
};

type GetContactsResponse = {
  contacts: Contact[];
  totalCount: number;
};

interface ContactApiResponse extends ResponsePanelApiDefault {
  contents: Contact[];
}

export async function getContents(
  page: number,
  search: string,
  registersPerPag?: number
): Promise<GetContactsResponse> {
  const { data } = await api.get<ContactApiResponse>("/contact", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag, search },
  });

  const contacts = data.contents.map((contact) => ({
    ...contact,
    created_atFormatted: new Date(contact.created_at).toLocaleDateString(
      "pt-BR",
      {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }
    ),
  }));
  const totalCount = data.total;

  return {
    totalCount,
    contacts,
  };
}

export function useContacts(
  page: number,
  search: string,
  registersPerPag?: number
): UseQueryResult<GetContactsResponse> {
  return useQuery(
    ["contacts", page, search],
    () => getContents(page, search, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneContact(id?: string): Promise<Contact | undefined> {
  if (!id) return undefined;

  const { data } = await api.get<Contact>(`/contact/${id}`);

  return data;
}

export function useOneContact(
  id?: string
): UseQueryResult<Contact> | undefined {
  return useQuery(["contact", id], () => getOneContact(id), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
