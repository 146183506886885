import { useEffect, useState } from "react";
import * as localCookiesAlertCCT from "../service/localStorage/alertCCT";

export default function useAlertCCTOrder() {
  const [cookiesAlertCCT, setCookiesAlertCCT] = useState(false);

  useEffect(() => {
    const cookiesConsentData = localCookiesAlertCCT.get();

    if (cookiesConsentData && cookiesConsentData.date) {
      const dateBeforeDays = new Date();
      dateBeforeDays.setDate(dateBeforeDays.getDate() - 7);

      if (new Date(cookiesConsentData.date) < dateBeforeDays) {
        setCookiesAlertCCT(false);
      } else {
        setCookiesAlertCCT(true);
      }
    }

    // eslint-disable-next-line
  }, [localCookiesAlertCCT.get()]);

  useEffect(() => {
    if (cookiesAlertCCT) {
      localCookiesAlertCCT.set({ date: new Date() });
    }
  }, [cookiesAlertCCT]);

  return { cookiesAlertCCT, setCookiesAlertCCT };
}
