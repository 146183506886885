import { useState, useEffect } from "react";
import * as cookiesConsent from "../service/localStorage/cookiesConsent";

export default function useCookiesConsent() {
  const [cookiesConsentAccept, setCookiesConsentAccept] = useState(false);

  useEffect(() => {
    const cookiesConsentData = cookiesConsent.get();
    if (cookiesConsentData && cookiesConsentData.accept) {
      setCookiesConsentAccept(cookiesConsentData.accept);
    }

    // eslint-disable-next-line
  }, [cookiesConsent.get()]);

  useEffect(() => {
    if (cookiesConsentAccept) {
      cookiesConsent.set({ accept: true });
    }
  }, [cookiesConsentAccept]);

  return { cookiesConsentAccept, setCookiesConsentAccept };
}
