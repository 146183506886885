import { useQuery, UseQueryResult } from "react-query";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Devolution = {};

export type DevolutionFilters = {
  protocol?: string;
  requestType?: string;
  status?: string;
  notificationUser?: number;
  refusal?: number | string;
  finisher?: number | string;
  blockClient?: number | string;
  blockClientAction?: number | string;
  pendencyType?: number | string;
  pendency?: number | string;
  shippingCompany?: number | string;

  codSalesman?: number;
  brandManager?: number;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  numberInvoice?: string;
  responsible?: string;
  brand?: string;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;

  finisherPeriod?: number;
  presetDateFinisher?: number;
  specificDateFinisher?: Date;
  startDateFinisher?: Date;
  endDateFinisher?: Date;
};

type GetDevolutionsCountResponse = {
  totalCount: number;
};

interface DevolutionApiResponse extends ResponsePanelApiDefault {
  contents: Devolution[];
}

export interface AnalyticItem {
  description: string;
  total: number;
}

interface ListStatus {
  name: string;
  length: number;
  value: number;
}

interface AnalyticDevolution {
  total: number;
  valueInvoice: number;
  normalAndRefusal: {
    name: string;
    value: number;
    length: number;
  }[];
  statusData: ListStatus[];
  reasonData: ListStatus[];

  devolutionFlow: {
    data: {
      date: Date;
      content: AnalyticItem[];
    }[];
    types: string[];
  };
  mediumTerm: {
    data: {
      date: Date;
      content: AnalyticItem[];
    }[];
    descriptions: string[];
  };
}

interface AnalyticResponseNormalized {
  total: number;
  valueInvoice: number;
  normalAndRefusal: {
    name: string;
    value: number;
    length: number;
  }[];
  statusData: ListStatus[];
  reasonData: ListStatus[];

  devolutionFlow: {
    date: Date[];
    content: {
      name: string;
      data: number[];
    }[];
  };

  mediumTerm: {
    date: Date[];
    content: {
      name: string;
      data: number[];
    }[];
  };
}

interface ListStatus {
  name: string;
  length: number;
  value: number;
}

export async function getDevolutionsCount(
  filter?: DevolutionFilters
): Promise<GetDevolutionsCountResponse> {
  const { data } = await api.get<DevolutionApiResponse>("/devolution/count", {
    params: { ...filter },
  });

  return {
    totalCount: data.total,
  };
}

export function useDevolutionsCount(
  filter?: DevolutionFilters
): UseQueryResult<GetDevolutionsCountResponse> {
  return useQuery(["devolution", filter], () => getDevolutionsCount(filter), {
    staleTime: 1000 * 60 * 5, // 5 Minutos
  });
}

export async function getDevolutionsCountClient(
  clientId?: number,
  filter?: DevolutionFilters
): Promise<GetDevolutionsCountResponse | null> {
  if (!clientId) return null;

  const { data } = await api.get<DevolutionApiResponse>(
    `/devolution/client/count/${clientId}`,
    {
      params: { ...filter },
    }
  );

  return {
    totalCount: data.total,
  };
}

export function useDevolutionsCountClient(
  clientId?: number,
  filter?: DevolutionFilters
): UseQueryResult<GetDevolutionsCountResponse> {
  return useQuery(
    ["devolution", clientId, filter],
    () => getDevolutionsCountClient(clientId, filter),
    {
      staleTime: 1000 * 60 * 5, // 5 Minutos
    }
  );
}

export async function getDevolutionAnalytic(
  filter?: DevolutionFilters
): Promise<AnalyticResponseNormalized> {
  const response = await api.get<AnalyticDevolution>("/analytic/devolution", {
    params: filter,
  });

  var contentDevolutionFlowNormalized: {
    name: string;
    data: number[];
  }[] = [];

  response.data.devolutionFlow.types.forEach((position) =>
    contentDevolutionFlowNormalized.push({ name: position, data: [] })
  );
  response.data.devolutionFlow.data.forEach((item) => {
    item.content.forEach((content) => {
      const findOne = contentDevolutionFlowNormalized.find(
        (f) => f.name === content.description
      );
      if (findOne) {
        findOne.data.push(content.total);
      }
    });
  });

  var contentMediumTermNormalized: {
    name: string;
    data: number[];
  }[] = [];

  response.data.mediumTerm.descriptions.forEach((position) =>
    contentMediumTermNormalized.push({ name: position, data: [] })
  );
  response.data.mediumTerm.data.forEach((item) => {
    item.content.forEach((content) => {
      const findOne = contentMediumTermNormalized.find(
        (f) => f.name === content.description
      );
      if (findOne) {
        findOne.data.push(content.total);
      }
    });
  });

  const normalized: AnalyticResponseNormalized = {
    ...response.data,

    devolutionFlow: {
      date: response.data.devolutionFlow.data.map((item) => item.date),
      content: contentDevolutionFlowNormalized,
    },

    mediumTerm: {
      date: response.data.mediumTerm.data.map((item) => item.date),
      content: contentMediumTermNormalized,
    },
  };

  return normalized;
}

export function useDevolutionAnalytic(
  filter?: DevolutionFilters
): UseQueryResult<AnalyticResponseNormalized> {
  return useQuery(
    ["devolution-analytic", filter],
    () => getDevolutionAnalytic(filter),
    {
      staleTime: 1000 * 60 * 20, // 10 Minutos
    }
  );
}
