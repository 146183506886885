import styled from "styled-components";

export const Conatiner = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 50px;
  margin-top: 20px;

  font-weight: 700;
  font-size: 16px;
  color: #fff;

  background: #d21e26;
  border-radius: 4px;

  &:hover {
    opacity: 0.85;
  }
`;
