import filesize from "filesize";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import { IoMdTrash } from "react-icons/io";
import { IoBarcodeOutline } from "react-icons/io5";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { ProductQuality } from "..";
import { GroupInput } from "../../../../components/Form/GroupInput";
import { Input } from "../../../../components/Form/Input";
import { LayoutForm } from "../../../../components/Form/LayoutForm";
import Modal from "../../../../components/ModalLib";
import Dropzone from "../../../../components/Upload/Dropzone";
import api from "../../../../service/api";
import genarateHash from "../../../../utils/generateHash";
import { HeaderDatasheetAndDatasheet } from "../../../detail/Datasheet";
import { ContainerDropZone, ContainerPreviews } from "../../styles";
import Scanner from "../Scanner";
import { IDefect, SelectDefect } from "../SelectDefect";
import {
  ButtonIncrement,
  Container,
  ContainerButtons,
  ContainerForm,
  ContainerInputButtonScanner,
} from "./styles";

interface ModalQualityProductProps {
  qualityProducts: ProductQuality[];
  setQualityProducts: React.Dispatch<React.SetStateAction<ProductQuality[]>>;

  onClose: () => void;
  data: HeaderDatasheetAndDatasheet;
}

export interface FileProps {
  id: string;

  url: string | null;
  name: string;
  readableSize: string;
  preview?: string;
  file: File;
  progress: number;
  uploaded: boolean;
  error: boolean;
}

export const ModalQualityProduct: React.FC<ModalQualityProductProps> = ({
  setQualityProducts,
  onClose,
  data: datasheet,
}) => {
  const { addToast } = useToasts();
  const [isOpenScanner, setIsOpenScanner] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<FileProps[]>([]);
  const [defects, setDefects] = useState<IDefect[]>([]);

  const formik = useFormik({
    initialValues: {
      barcode: "",
      approvalBarcode: false,
      datasheet: {
        barcodeId: "",
        reference: "",
        primaryColor: "",
        size: "",
      },
      qtd: 1,
    },
    validationSchema: Yup.object({
      barcode: Yup.string()
        .typeError("Apenas números")
        .required("É obrigatório")
        .min(13, "Inválido, EAN possui 13 dígitos")
        .max(13, "Inválido, EAN possui 13 dígitos"),
    }),

    onSubmit: (a) => {
      handleCreateQualityProduct(a);
    },
  });

  useEffect(() => {
    if (formik.values.barcode) {
      handleValidBarcode(String(formik.values.barcode));
    }
    // eslint-disable-next-line
  }, [formik.values.barcode]);

  function handleOnScanner(code: string) {
    formik.setFieldValue("barcode", code);
    setIsOpenScanner(false);
  }

  async function handleDeleteFile(file: FileProps) {
    const filesFilterDelete = uploadedFiles.filter((f) => f !== file);
    setUploadedFiles(filesFilterDelete);

    if (file.uploaded) {
      await api.post(
        `/headerDatasheet/datasheet/qualities/file/remove/${file.id}`
      );
    }
  }

  async function clearForm() {
    formik.resetForm();
    setUploadedFiles([]);
    setDefects([]);
  }

  async function handleClearForm() {
    await clearForm();

    for (const file of uploadedFiles) {
      if (file.uploaded) {
        await api.post(
          `/headerDatasheet/datasheet/qualities/file/remove/${file.id}`
        );
      }
    }
  }

  function handleCreateQualityProduct(data: typeof formik.values) {
    for (const file of uploadedFiles) {
      if (file.uploaded === false) {
        return addToast("Aguarde o fim do upload de todos arquivos", {
          appearance: "info",
          autoDismiss: true,
        });
      }
    }

    const qualityProduct: ProductQuality = {
      id: genarateHash(),
      barcode: String(data.barcode),
      barcodeId: data.datasheet.barcodeId,
      size: data.datasheet.size,
      reference: data.datasheet.reference,
      primaryColor: data.datasheet.primaryColor,
      approvalBarcode: data.approvalBarcode,
      defects: defects,
      files: uploadedFiles,
    };

    const qualityProductQtd = Array.from({ length: data.qtd }).map(
      () => qualityProduct
    );

    setQualityProducts((state) => [...state, ...qualityProductQtd]);

    clearForm();
  }

  function handleFinishedQualityProduct() {
    if (formik.values.barcode) {
      formik.handleSubmit();
      onClose();
    } else {
      onClose();
    }
  }

  function handleUploadFiles(files: File[]) {
    const uploadedFilesNormalized = files.map((file) => ({
      file,
      id: genarateHash(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setUploadedFiles((state) => [...state, ...uploadedFilesNormalized]);

    uploadedFilesNormalized.map((i) => processUpload(i));
  }

  function processUpload(uploadedFile: FileProps) {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    api
      .post("/headerDatasheet/datasheet/qualities/file/upload", data, {
        onUploadProgress: (e) => {
          const progress = parseInt(
            String(Math.round((e.loaded * 100) / e.total))
          );

          setUploadedFiles((state) =>
            state.map((item) => {
              if (item.id === uploadedFile.id) {
                return { ...item, progress };
              }

              return item;
            })
          );
        },
      })
      .then((response) => {
        setUploadedFiles((state) =>
          state.map((item) => {
            if (item.id === uploadedFile.id) {
              return {
                ...item,
                uploaded: true,
                id: response.data.id,
                url: response.data.url,
              };
            }

            return item;
          })
        );
      })
      .catch(() => {
        setUploadedFiles((state) =>
          state.map((item) => {
            if (item.id === uploadedFile.id) {
              return { ...item, error: true };
            }

            return item;
          })
        );
      });
  }

  function handleValidBarcode(barcode: string) {
    if (barcode.length === 13) {
      let findBarcodeData:
        | {
            id: string;
            reference?: string | undefined;
            barcode: string;
            size: string;
            primary_color_name: string;
          }
        | undefined = undefined;

      for (const variation of datasheet.variations) {
        const findBarcode = variation.variations?.find(
          (variation) => variation.barcode?.trim() === barcode?.trim()
        );

        if (findBarcode) {
          findBarcodeData = {
            ...findBarcode,
            reference: variation.reference,
            primary_color_name: variation.primary_color_name ?? "",
          };
        }
      }

      if (findBarcodeData) {
        formik.setFieldValue("approvalBarcode", true);
        formik.setFieldValue("datasheet.reference", findBarcodeData.reference);
        formik.setFieldValue("datasheet.barcodeId", findBarcodeData.id);
        formik.setFieldValue(
          "datasheet.primaryColor",
          findBarcodeData.primary_color_name
        );
        formik.setFieldValue("datasheet.size", findBarcodeData.size);
      } else {
        formik.setFieldValue("approvalBarcode", false);
        formik.setFieldValue("datasheet.reference", "");
        formik.setFieldValue("datasheet.primaryColor", "");
        formik.setFieldValue("datasheet.size", "");
        formik.setFieldValue("datasheet.barcodeId", "");

        return addToast(
          "Este código EAN não esta cadastrado nesta referência, verifique se o mesmo está correto",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }
    } else {
      formik.setFieldValue("approvalBarcode", false);
      formik.setFieldValue("datasheet.reference", "");
      formik.setFieldValue("datasheet.primaryColor", "");
      formik.setFieldValue("datasheet.size", "");

      // return addToast(
      //   "Este código EAN não é válido, verifique se o mesmo está correto",
      //   {
      //     appearance: "warning",
      //     autoDismiss: true,
      //   }
      // );
    }
  }

  function handleButtonsIncrement(type: "positive" | "negative") {
    var qtdValue = formik.values.qtd;

    if (type === "positive") {
      qtdValue++;
    } else {
      if (qtdValue > 1) {
        qtdValue--;
      }
    }

    formik.setFieldValue("qtd", qtdValue);
  }

  return (
    <>
      <Container>
        <LayoutForm
          stylesContainerForm={{
            height: "100%",
            maxWidth: "100%",
            marginTop: 0,
          }}
          style={{ height: "100%" }}
          onSubmit={formik.handleSubmit}
        >
          <ContainerForm>
            <div>
              <ContainerInputButtonScanner>
                <button
                  type="button"
                  onClick={() => {
                    setIsOpenScanner(true);
                  }}
                >
                  <IoBarcodeOutline /> <span>Escanear</span>
                </button>

                <Input
                  label="EAN"
                  type="number"
                  {...formik.getFieldProps("barcode")}
                  error={
                    formik.touched.barcode && formik.errors.barcode
                      ? formik.errors.barcode
                      : undefined
                  }
                />

                <nav
                  style={{
                    height: "100%",
                    width: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                >
                  {formik.values.barcode && formik.values.approvalBarcode && (
                    <FcApproval size={26} />
                  )}
                  {formik.values.barcode && !formik.values.approvalBarcode && (
                    <FcHighPriority size={26} />
                  )}
                </nav>
              </ContainerInputButtonScanner>
              <GroupInput>
                <Input
                  label="REFERENCE"
                  styleField={{ minWidth: 100 }}
                  readOnly
                  {...formik.getFieldProps("datasheet.reference")}
                />
                <Input
                  label="COR"
                  styleField={{ minWidth: 100 }}
                  readOnly
                  {...formik.getFieldProps("datasheet.primaryColor")}
                />
                <Input
                  readOnly
                  label="TAMANHO"
                  styleField={{ minWidth: 100 }}
                  {...formik.getFieldProps("datasheet.size")}
                />
              </GroupInput>

              <h3>Quantidade</h3>
              <div style={{ display: "flex" }}>
                <ButtonIncrement
                  type="button"
                  style={{ marginRight: "1rem" }}
                  onClick={() => {
                    handleButtonsIncrement("negative");
                  }}
                >
                  <span>-</span>
                </ButtonIncrement>

                <Input
                  readOnly
                  type="number"
                  {...formik.getFieldProps("qtd")}
                  style={{ borderColor: "#333" }}
                />

                <ButtonIncrement
                  type="button"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => {
                    handleButtonsIncrement("positive");
                  }}
                >
                  <span>+</span>
                </ButtonIncrement>
              </div>

              <GroupInput style={{ marginBottom: 24 }}>
                <div style={{ flex: 1, width: "100%" }}>
                  <SelectDefect
                    defectsLinks={defects}
                    setDefectsLinks={setDefects}
                  />
                </div>
                <div style={{ flex: 1, width: "100%" }}>
                  <h3 style={{ marginBottom: "24px" }}>Arquivos</h3>
                  <ContainerDropZone style={{ minWidth: 200 }}>
                    <Dropzone
                      accept={["image/*"]}
                      onFileUploaded={(i) => {
                        handleUploadFiles([i]);
                      }}
                    />
                    <ContainerPreviews
                      style={{
                        overflowY: "auto",
                        maxHeight: "40vh",
                      }}
                    >
                      {uploadedFiles.map((file) => (
                        <li key={file.id}>
                          <img src={file.preview} alt="icon_xml" />
                          <div className="fileInfo">
                            <div>
                              <strong>{file.name}</strong>
                              <span>{file.readableSize}</span>
                            </div>

                            {file.uploaded && !file.error && (
                              <button
                                type="button"
                                onClick={() => {
                                  handleDeleteFile(file);
                                }}
                              >
                                <IoMdTrash size={30} />
                              </button>
                            )}
                            {!file.uploaded && !file.error && (
                              <CircularProgressbar
                                styles={{
                                  root: { width: 24 },
                                  path: { stroke: "#5971c1" },
                                }}
                                strokeWidth={10}
                                value={file.progress}
                              />
                            )}
                            {file.error && <h1>error</h1>}
                          </div>
                        </li>
                      ))}
                    </ContainerPreviews>
                  </ContainerDropZone>
                </div>
              </GroupInput>
            </div>
            <div>
              <ContainerButtons>
                <button type="button" onClick={handleClearForm}>
                  Limpar
                </button>
                <button type="button" onClick={handleFinishedQualityProduct}>
                  Finalizar
                </button>
                <button type="submit">Proximo</button>
              </ContainerButtons>
            </div>
          </ContainerForm>
        </LayoutForm>
      </Container>

      <Modal
        modalVisible={isOpenScanner}
        setModalVisible={setIsOpenScanner}
        maxHeight={"100%"}
        maxWidth={"100%"}
        headerOff
        scrollOff
      >
        <Scanner
          onDetected={handleOnScanner}
          onClose={() => {
            setIsOpenScanner(false);
          }}
        />
      </Modal>
    </>
  );
};
