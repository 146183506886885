import { useQuery, UseQueryResult } from "react-query";
import api from "../../service/api";

export interface AnalyticIten {
  description: string;
  amount: number;
}

export interface AnalyticResponse {
  totalAmount: AnalyticIten[];

  totalPriceMonths: {
    data: {
      date: Date;
      content: AnalyticIten[];
    }[];
    positionOrder: string[];
  };
}

interface AnalyticItenNormilized {
  description: string[];
  amount: number[];
}
interface AnalyticMonthsNormilized {
  positionOrder: string[];
  date: Date[];
  content: {
    name: string;
    data: number[];
  }[];
}

interface AnalyticResponseNormilized {
  totalAmount: AnalyticItenNormilized;
  totalPriceMonths: AnalyticMonthsNormilized;
}

export const statusColorAnalytic = [
  {
    name: "Aguardando Faturamento",
    color: "rgb(0, 143, 251)",
  },
  {
    name: "Recusado",
    color: "#9700C2",
  },
  {
    name: "Faturado",
    color: "rgb(0, 227, 150)",
  },
  {
    name: "Cancelado",
    color: "rgb(255, 69, 96)",
  },
  {
    name: "Parcialmente faturado",
    color: "rgb(0, 143, 251)",
  },
  {
    name: "Em análise",
    color: "#9700C2",
  },
];

export async function getOrderAnalytic({
  seller,
  client,
  clientId,
}: {
  seller?: boolean;
  client?: boolean;
  clientId?: number;
}): Promise<AnalyticResponseNormilized | undefined> {
  if (!seller && !client) return undefined;

  const response = await api.get<AnalyticResponse>("/analytic/order", {
    params: {
      clientId,
    },
  });

  var contentNormalized: {
    name: string;
    data: number[];
  }[] = [];

  response.data.totalPriceMonths.positionOrder.forEach((position) =>
    contentNormalized.push({ name: position, data: [] })
  );
  response.data.totalPriceMonths.data.forEach((item) => {
    item.content.forEach((content) => {
      const findOne = contentNormalized.find(
        (f) => f.name === content.description
      );
      if (findOne) {
        findOne.data.push(content.amount);
      }
    });
  });

  return {
    totalAmount: {
      amount: response.data.totalAmount.map((data) => data.amount),
      description: response.data.totalAmount.map((data) => data.description),
    },
    totalPriceMonths: {
      date: response.data.totalPriceMonths.data.map((item) => item.date),
      positionOrder: response.data.totalPriceMonths.positionOrder,
      content: contentNormalized,
    },
  };
}

export function useOrderAnalytic({
  seller,
  client,
  clientId,
}: {
  seller?: boolean;
  client?: boolean;
  clientId?: number;
}): UseQueryResult<AnalyticResponseNormilized> {
  return useQuery(
    ["order-analytic", seller, client, clientId],
    () => getOrderAnalytic({ seller, client, clientId }),
    {
      staleTime: 1000 * 60 * 20, // 10 Minutos
    }
  );
}
