import React from "react";

import { ContainerDot } from "./styles";

interface props {}

const Loading: React.FC<props> = () => {
  return (
    <ContainerDot>
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </ContainerDot>
  );
};

export default Loading;
