import { useEffect, useState } from "react";
import * as localCookiesAlertBillet from "../service/localStorage/alertBillet";

export default function useAlertBilletConsent() {
  const [cookiesAlertBillet, setCookiesAlertBillet] = useState(false);

  useEffect(() => {
    const cookiesConsentData = localCookiesAlertBillet.get();

    if (cookiesConsentData && cookiesConsentData.date) {
      const dateBeforeDays = new Date();
      dateBeforeDays.setDate(dateBeforeDays.getDate() - 15);

      if (new Date(cookiesConsentData.date) < dateBeforeDays) {
        setCookiesAlertBillet(false);
      } else {
        setCookiesAlertBillet(true);
      }
    }

    // eslint-disable-next-line
  }, [localCookiesAlertBillet.get()]);

  useEffect(() => {
    if (cookiesAlertBillet) {
      localCookiesAlertBillet.set({ date: new Date() });
    }
  }, [cookiesAlertBillet]);

  return { cookiesAlertBillet, setCookiesAlertBillet };
}
