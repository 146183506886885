import { useQuery, UseQueryResult } from "react-query";
import api from "../../service/api";
import { AnalyticItem } from "./useDevolution";
import { typeToFormatted, userTypeToFormatted } from "./useRequestUpdateClient";

interface AnalyticRequest {
  internalData: {
    qtdTotalClientContact: number;
    qtdIsClientContact: number;
    qtdNotClientContact: number;
    rakingPermission: { name: string; qtd: number }[];
    rakingAdm: {
      dates: Date[];
      users: string[];
      data: { name: string; qtd: number; date: Date }[];
    };
  };

  requestUpdateClient: {
    qtdTotal: number;
    qtdPendency: number;
    qtdFinished: number;
    userTypes: {
      name: string;
      length: number;
    }[];
    requestTypes: {
      name: string;
      length: number;
    }[];
    requestFlow: {
      data: {
        date: Date;
        content: AnalyticItem[];
      }[];
      types: string[];
    };
    mediumTerm: {
      data: {
        date: Date;
        content: AnalyticItem[];
      }[];
      descriptions: string[];
    };
  };
}

interface AnalyticResponse {
  internalData: {
    qtdTotalClientContact: number;
    qtdIsClientContact: number;
    qtdNotClientContact: number;
    rakingPermission: { name: string; qtd: number }[];
    rakingAdm: {
      dates: Date[];
      users: string[];
      data: { name: string; qtd: number; date: Date }[];
    };
  };

  requestUpdateClient: {
    qtdTotal: number;
    qtdPendency: number;
    qtdFinished: number;
    userTypes: {
      name: string;
      length: number;
    }[];
    requestTypes: {
      name: string;
      length: number;
    }[];
    requestFlow: {
      date: Date[];
      content: {
        name: string;
        data: number[];
      }[];
    };

    mediumTerm: {
      date: Date[];
      content: {
        name: string;
        data: number[];
      }[];
    };
  };
}

export async function getRequestUpdateClientAnalytic(): Promise<
  AnalyticResponse | undefined
> {
  const response = await api.get<AnalyticRequest>(
    "/analytic/request-update-client"
  );

  var contentFlowNormalized: {
    name: string;
    data: number[];
  }[] = [];

  response.data.requestUpdateClient.requestFlow.types.forEach((position) =>
    contentFlowNormalized.push({ name: position, data: [] })
  );
  response.data.requestUpdateClient.requestFlow.data.forEach((item) => {
    item.content.forEach((content) => {
      const findOne = contentFlowNormalized.find(
        (f) => f.name === content.description
      );
      if (findOne) {
        findOne.data.push(content.total);
      }
    });
  });

  var contentMediumTermNormalized: {
    name: string;
    data: number[];
  }[] = [];

  response.data.requestUpdateClient.mediumTerm.descriptions.forEach(
    (position) => contentMediumTermNormalized.push({ name: position, data: [] })
  );
  response.data.requestUpdateClient.mediumTerm.data.forEach((item) => {
    item.content.forEach((content) => {
      const findOne = contentMediumTermNormalized.find(
        (f) => f.name === content.description
      );
      if (findOne) {
        findOne.data.push(content.total);
      }
    });
  });

  return {
    ...response.data,
    requestUpdateClient: {
      ...response.data.requestUpdateClient,
      userTypes: response?.data?.requestUpdateClient?.userTypes?.map(
        (item) => ({
          name: userTypeToFormatted(item.name),
          length: item.length,
        })
      ),
      requestTypes: response?.data?.requestUpdateClient?.requestTypes?.map(
        (item) => ({
          name: typeToFormatted(item.name),
          length: item.length,
        })
      ),

      requestFlow: {
        date: response.data.requestUpdateClient.requestFlow.data.map(
          (item) => item.date
        ),
        content: contentFlowNormalized,
      },

      mediumTerm: {
        date: response.data.requestUpdateClient.mediumTerm.data.map(
          (item) => item.date
        ),
        content: contentMediumTermNormalized,
      },
    },
  };
}

export function useRequestUpdateClientAnalytic(): UseQueryResult<AnalyticResponse> {
  return useQuery(
    ["request-update-client-analytic"],
    () => getRequestUpdateClientAnalytic(),
    {
      staleTime: 1000 * 60 * 20, // 10 Minutos
    }
  );
}
