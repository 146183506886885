import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { Layout } from "./components/Layout";
import { Catalog } from "./pages/Catalog";
import FirstAccess from "./pages/FirstAccess";
import ForgotPassword from "./pages/ForgotPassword";
import Holding from "./pages/Holding";
import Home from "./pages/Home";
import HomeScreen from "./pages/HomeScreen";
import { InnerHtml } from "./pages/InnerHtml";
import Login from "./pages/Login";
import Perfil from "./pages/Perfil";
import ResetPassword from "./pages/ResetPassword";
import SSO from "./pages/SSO";
import Scheduler from "./pages/Scheduler";
import Security from "./pages/Security";
import Tracking from "./pages/Tracking";
import { Welcome } from "./pages/Welcome";
import AccessAnalytic from "./pages/analytic/Access";
import ClientAnality from "./pages/analytic/Client";
import AnalyticDevolution from "./pages/analytic/Devolution";
import RequestUpdatedClientAnality from "./pages/analytic/RequestUpdatedClient";
import { SchedulingAnalytic } from "./pages/analytic/Scheduling";
import ConfigCalculator from "./pages/config/Calculator";
import ConfigGateway from "./pages/config/Gateway";
import ConfigOrder from "./pages/config/Order";
import CreateClient from "./pages/create/CreateClient";
import CreateCollection from "./pages/create/CreateCollection";
import CreateHeaderDatasheet from "./pages/create/CreateDatasheet";
import CreateDebt from "./pages/create/CreateDebt";
import CreateDecolucao from "./pages/create/CreateDecolucao";
import CreateDescriptionForecast from "./pages/create/CreateDescriptionForecast";
import CreateForecast from "./pages/create/CreateForecast";
import CreateIndemnity from "./pages/create/CreateIndemnity";
import CreateIndemnityOrDevolution from "./pages/create/CreateIndemnityOrDevolution";
import CreatePayment from "./pages/create/CreatePayment";
import CreatePermission from "./pages/create/CreatePermission";
import CreateProvider from "./pages/create/CreateProvider";
import CreateQuality from "./pages/create/CreateQuality";
import CreateShowFile from "./pages/create/CreateShowFile";
import CreateStatusDatasheet from "./pages/create/CreateStatusDatasheet";
import CreateStatusDevolution from "./pages/create/CreateStatusDevolution";
import CreateStatusHeaderDatasheet from "./pages/create/CreateStatusHeaderDatasheet";
import CreateStatusIndemnity from "./pages/create/CreateStatusIndemnity";
import CreateStatusScheduling from "./pages/create/CreateStatusScheduling";
import CreateUser from "./pages/create/CreateUser";
import CreateBanners from "./pages/createAndDetail/Banners";

import {
  default as CreateColors,
  default as DetailColors,
} from "./pages/createAndDetail/Colors";

import CreateContacts from "./pages/createAndDetail/Contacts";

import TeamSeller from "./pages/TeamSeller";
import {
  default as CreateNcms,
  default as DetailNcms,
} from "./pages/createAndDetail/Ncms";
import EnchestesRs from "./pages/detail/EnchestesRs";

import { AccessTablet } from "./pages/AccessTablet";
import CreateServiceInvoice from "./pages/create/CreateServiceInvoice";
import Calculator from "./pages/detail/Calculator";
import Client from "./pages/detail/Client";
import Collection from "./pages/detail/Collection";
import Datasheet from "./pages/detail/Datasheet";
import Debt from "./pages/detail/Debt";
import DescriptionForecast from "./pages/detail/DescriptionForecast";
import Devoluiton from "./pages/detail/Devolution";
import DevoluitonLegacy from "./pages/detail/DevolutionLegacy";
import Forecast from "./pages/detail/Forecast";
import ForecastSeller from "./pages/detail/ForecastSeller";
import HeaderDatasheet from "./pages/detail/HeaderDatasheet";
import Indemnity from "./pages/detail/Indemnity";
import Order from "./pages/detail/Order";
import Payment from "./pages/detail/Payment";
import Permission from "./pages/detail/Permission";
import Provider from "./pages/detail/Provider";
import Quality from "./pages/detail/Quality";
import RequestUpdateClient from "./pages/detail/RequestUpdateClient";
import Scheduling from "./pages/detail/Scheduling/";
import Seller from "./pages/detail/Seller";
import ServiceInvoice from "./pages/detail/ServiceInvoice";
import ShowFile from "./pages/detail/ShowFile";
import StatusDatasheet from "./pages/detail/StatusDatasheet";
import StatusDevolution from "./pages/detail/StatusDevolution";
import StatusHeaderDatasheet from "./pages/detail/StatusHeaderDatasheet";
import StatusIndemnity from "./pages/detail/StatusIndemnity";
import StatusScheduling from "./pages/detail/StatusScheduling";
import User from "./pages/detail/User";
import ImportBillet from "./pages/import/ImportBillet";
import ImportBrand from "./pages/import/ImportBrand";
import ImportBrandManager from "./pages/import/ImportBrandManager";
import ImportBrandToSeller from "./pages/import/ImportBrandToSeller";
import ImportClient from "./pages/import/ImportClient";
import ImportColor from "./pages/import/ImportColor";
import ImportReasonInternalDevolution from "./pages/import/ImportReasonInternalDevolution";
import ImportReasonInternalIndemnity from "./pages/import/ImportReasonInternalIndemnity";
import ImportSalesman from "./pages/import/ImportSalesman";
import ImportSeller from "./pages/import/ImportSeller";
import ImportShippingCompany from "./pages/import/ImportShippingCompany";
import PanelBanners from "./pages/panel/PanelBanners";
import PanelBillet from "./pages/panel/PanelBillet";
import PanelBrandManager from "./pages/panel/PanelBrandManager";
import PanelBrands from "./pages/panel/PanelBrands";
import PanelClients from "./pages/panel/PanelClients";
import PanelClientsAnality from "./pages/panel/PanelClientsAnality";
import PanelCollection from "./pages/panel/PanelCollection";
import PanelColors from "./pages/panel/PanelColors";
import PanelContacts from "./pages/panel/PanelContacts";
import PanelContactsSeller from "./pages/panel/PanelContactsSeller";
import PanelDebt from "./pages/panel/PanelDebt";
import PanelDescriptionForecast from "./pages/panel/PanelDescriptionForecast";
import PanelDevolutionsLegacy from "./pages/panel/PanelDevolutionLegacy";
import PanelDevolutions from "./pages/panel/PanelDevolutions";
import PanelEnchestesRs from "./pages/panel/PanelEnchestesRs";
import PanelEnchestesRsSeller from "./pages/panel/PanelEnchestesRsSeller";
import PanelForecast from "./pages/panel/PanelForecast";
import PanelHeaderDatasheet from "./pages/panel/PanelHeaderDatasheet";
import PanelIndemnity from "./pages/panel/PanelIndemnity";
import PanelNcms from "./pages/panel/PanelNcms";
import PanelOrder from "./pages/panel/PanelOrder";
import PanelPermissions from "./pages/panel/PanelPermissions";
import PanelProvider from "./pages/panel/PanelProviders";
import PanelReasonInternalDevolution from "./pages/panel/PanelReasonInternalDevolution";
import PanelReasonInternalIndemnity from "./pages/panel/PanelReasonInternalIndemnity";
import PanelRequestUpdateClient from "./pages/panel/PanelRequestUpdateClient";
import PanelSalesman from "./pages/panel/PanelSalesman";
import PanelScheduling from "./pages/panel/PanelScheduling";
import PanelSelles from "./pages/panel/PanelSellers";
import PanelServiceInvoice from "./pages/panel/PanelServiceInvoice";
import PanelShippingCompany from "./pages/panel/PanelShippingCompany";
import PanelShowFile from "./pages/panel/PanelShowFile";
import PanelStatusDatasheet from "./pages/panel/PanelStatusDatasheet";
import PanelStatusDevolution from "./pages/panel/PanelStatusDevolution";
import PanelStatusHeaderDatasheet from "./pages/panel/PanelStatusHeaderDatasheet";
import PanelStatusIndemnity from "./pages/panel/PanelStatusIndemnity";
import PanelStatusScheduling from "./pages/panel/PanelStatusScheduling";
import PanelUsers from "./pages/panel/PanelUsers";
import * as authClient from "./service/authClient";
import * as authProvider from "./service/authProvider";
import * as authSeller from "./service/authSeller";
import * as authUser from "./service/authUser";

const RouteAuth = ({ component: Component, isAuthenticated, ...rest }: any) => {
  const routeComponent = (props: any) => {
    if (
      authUser.isAuthenticated() ||
      authSeller.isAuthenticated() ||
      authClient.isAuthenticated() ||
      authProvider.isAuthenticated()
    ) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }

    return <Redirect to="/" />;
  };
  return <Route {...rest} render={routeComponent} />;
};

const RouteAdminCatalog = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          authUser.isAuthenticated() ? (
            <Component {...props} />
          ) : (
            <Redirect to="/admin" />
          )
        }
      />
    );
  };
  return <Route {...rest} render={routeComponent} />;
};

const PrivateRouteAdmin = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          authUser.isAuthenticated() ? (
            <Layout admin={true} component={Component} props={props} />
          ) : (
            <Redirect to="/admin" />
          )
        }
      />
    );
  };
  return <Route {...rest} render={routeComponent} />;
};

const PrivateRouteClient = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => (
    <Route
      {...rest}
      render={(props) =>
        authClient.isAuthenticated() ? (
          <Layout client={true} component={Component} props={props} />
        ) : (
          <Redirect to="/cliente" />
        )
      }
    />
  );
  return <Route {...rest} render={routeComponent} />;
};

const PrivateRouteSeller = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => (
    <Route
      {...rest}
      render={(props) =>
        authSeller.isAuthenticated() ? (
          <Layout seller={true} component={Component} props={props} />
        ) : (
          <Redirect to="/representante" />
        )
      }
    />
  );
  return <Route {...rest} render={routeComponent} />;
};

const PrivateRouteProvider = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => (
    <Route
      {...rest}
      render={(props) =>
        authProvider.isAuthenticated() ? (
          <Layout provider={true} component={Component} props={props} />
        ) : (
          <Redirect to="/fornecedor" />
        )
      }
    />
  );
  return <Route {...rest} render={routeComponent} />;
};

const PrivateRouteSellerManagerOrSupervisor = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => (
    <Route
      {...rest}
      render={(props) =>
        authSeller.isManagerOrIsSupervisor() ? (
          <Layout seller={true} component={Component} props={props} />
        ) : (
          <Redirect to="/representante" />
        )
      }
    />
  );
  return <Route {...rest} render={routeComponent} />;
};

const PublicRouteClient = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => (
    <Route
      {...rest}
      render={(props) =>
        authSeller.isAuthenticated() ? (
          <Redirect to="/representante/home" />
        ) : authClient.isAuthenticated() ? (
          <Redirect to="/home" />
        ) : authUser.isAuthenticated() ? (
          <Redirect to="/admin/home" />
        ) : authProvider.isAuthenticated() ? (
          <Redirect to="/fornecedor/home" />
        ) : (
          <Component client {...props} />
        )
      }
    />
  );
  return <Route {...rest} render={routeComponent} />;
};

const PublicRouteUser = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => (
    <Route
      {...rest}
      render={(props) =>
        authSeller.isAuthenticated() ? (
          <Redirect to="/representante/home" />
        ) : authClient.isAuthenticated() ? (
          <Redirect to="/home" />
        ) : authUser.isAuthenticated() ? (
          <Redirect to="/admin/home" />
        ) : authProvider.isAuthenticated() ? (
          <Redirect to="/fornecedor/home" />
        ) : (
          <Component admin {...props} />
        )
      }
    />
  );
  return <Route {...rest} render={routeComponent} />;
};

const PublicRouteSeller = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => (
    <Route
      {...rest}
      render={(props) =>
        authSeller.isAuthenticated() ? (
          <Redirect to="/representante/home" />
        ) : authClient.isAuthenticated() ? (
          <Redirect to="/home" />
        ) : authUser.isAuthenticated() ? (
          <Redirect to="/admin/home" />
        ) : authProvider.isAuthenticated() ? (
          <Redirect to="/fornecedor/home" />
        ) : (
          <Component seller {...props} />
        )
      }
    />
  );
  return <Route {...rest} render={routeComponent} />;
};

const PublicRouteProvider = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => (
    <Route
      {...rest}
      render={(props) =>
        authSeller.isAuthenticated() ? (
          <Redirect to="/representante/home" />
        ) : authClient.isAuthenticated() ? (
          <Redirect to="/home" />
        ) : authUser.isAuthenticated() ? (
          <Redirect to="/admin/home" />
        ) : authProvider.isAuthenticated() ? (
          <Redirect to="/fornecedor/home" />
        ) : (
          <Component provider {...props} />
        )
      }
    />
  );
  return <Route {...rest} render={routeComponent} />;
};

const PublicRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          authSeller.isAuthenticated() ? (
            <Redirect to="/representante/home" />
          ) : authClient.isAuthenticated() ? (
            <Redirect to="/home" />
          ) : authUser.isAuthenticated() ? (
            <Redirect to="/admin/home" />
          ) : authProvider.isAuthenticated() ? (
            <Redirect to="/fornecedor/home" />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  };
  return <Route {...rest} render={routeComponent} />;
};

const TokenRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}: any) => {
  const routeComponent = (props: any) => {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  };
  return <Route {...rest} render={routeComponent} />;
};

const Routes = () => {
  return (
    <BrowserRouter>
      {/* Contexto de dados
      <DataProvider> */}
      <ToastProvider>
        <Switch>
          {/* Publico */}
          <PublicRoute component={HomeScreen} exact path="/" />
          <TokenRoute component={SSO} exact path="/sso/:token" />
          <TokenRoute component={Welcome} exact path="/bem-vindo/:id" />
          {/* Rotas de Fornecedor */}
          <PublicRouteProvider component={Login} exact path="/fornecedor" />
          <PublicRouteProvider
            component={ForgotPassword}
            exact
            path="/fornecedor/senha/resetar/"
          />
          <PublicRouteProvider
            component={ResetPassword}
            exact
            path="/fornecedor/senha/resetar/:id/:token"
          />
          <PrivateRouteProvider
            component={Home}
            exact
            path="/fornecedor/home"
          />
          <PrivateRouteProvider
            component={Perfil}
            exact
            path="/fornecedor/perfil"
          />
          <PrivateRouteProvider
            component={Security}
            exact
            path="/fornecedor/seguranca"
          />
          <PrivateRouteProvider
            component={Scheduler}
            exact
            path="/fornecedor/calendario"
          />
          <PrivateRouteProvider
            component={PanelScheduling}
            exact
            path="/fornecedor/agendamentos"
          />
          <PrivateRouteProvider
            component={Scheduling}
            exact
            path="/fornecedor/agendamento/:id"
          />
          <PrivateRouteProvider
            component={Devoluiton}
            exact
            path="/fornecedor/devolucao/:id"
          />
          <PrivateRouteProvider
            component={PanelDevolutions}
            exact
            path="/fornecedor/devolucoes"
          />
          <PrivateRouteProvider
            component={CreateDecolucao}
            exact
            path="/fornecedor/criar/devolucao"
          />
          {/* <PrivateRouteProvider
            component={HeaderDatasheet}
            exact
            path="/fornecedor/cabecalho-fichas-tecnicas/:id"
          />
          <PrivateRouteProvider
            component={Datasheet}
            exact
            path="/fornecedor/cabecalho-ficha-tecnica/:idHeaderDatasheet/ficha-tecnica/:idDatasheet"
          /> */}
          {/* ---------------------*/}
          {/* Rotas de Usuario */}
          <PublicRouteClient component={Login} exact path="/cliente" />
          <PublicRouteClient
            component={ForgotPassword}
            exact
            path="/cliente/senha/resetar/"
          />
          <PublicRouteClient
            component={ResetPassword}
            exact
            path="/cliente/senha/resetar/:cnpj/:token"
          />
          <PrivateRouteClient component={Home} exact path="/home" />
          <PrivateRouteClient component={Perfil} exact path="/perfil" />
          <PrivateRouteClient component={Security} exact path="/seguranca" />
          <PrivateRouteClient component={Holding} exact path="/holding" />
          <PrivateRouteClient
            component={Devoluiton}
            exact
            path="/devolucao/:id"
          />
          <PrivateRouteClient
            component={PanelDevolutions}
            exact
            path="/devolucoes"
          />
          <PrivateRouteClient
            component={CreateDecolucao}
            exact
            path="/criar/devolucao"
          />
          <PrivateRouteClient component={Tracking} exact path="/rastreamento" />
          <PrivateRouteClient
            component={CreateIndemnityOrDevolution}
            exact
            path="/criar/indenizacaoOuDevolucao"
          />
          <PrivateRouteClient
            component={Indemnity}
            exact
            path="/indenizacao/:id"
          />
          <PrivateRouteClient
            component={PanelIndemnity}
            exact
            path="/indenizacaos"
          />
          <PrivateRouteClient
            component={CreateIndemnity}
            exact
            path="/criar/indenizacao"
          />
          <PrivateRouteClient
            component={DevoluitonLegacy}
            exact
            path="/devolucaoLegado"
          />
          <PrivateRouteClient
            component={PanelDevolutionsLegacy}
            exact
            path="/devolucoesLegado"
          />
          <PrivateRouteClient
            component={Payment}
            exact
            path="/negociacao/realizada/:id"
          />
          <PrivateRouteClient
            component={Debt}
            exact
            path="/negociacao/info/:id"
          />
          {/* <PrivateRouteClient
            component={PanelPayment}
            exact
            path="/negociacoes/realizadas"
          /> */}
          <PrivateRouteClient
            component={CreatePayment}
            exact
            path="/realizar/pagamento/:id"
          />
          <PrivateRouteClient
            component={PanelDebt}
            exact
            path="/negociacoes/abertas"
          />
          <PrivateRouteClient component={PanelBillet} exact path="/boletos" />

          <PrivateRouteClient
            component={PanelOrder}
            exact
            path="/pedidos/situacao/:position"
          />
          <PrivateRouteClient component={Order} exact path="/pedidos/:id" />

          {/* Rota sem Navbar */}
          <PublicRouteClient
            component={FirstAccess}
            exact
            path="/cliente/primeiro/acesso/:cnpj/:token"
          />
          {/* Rotas de representante */}

          <PublicRouteSeller component={Login} exact path="/representante" />
          <PublicRouteSeller
            component={ForgotPassword}
            exact
            path="/representante/senha/resetar/"
          />
          <PublicRouteSeller
            component={ResetPassword}
            exact
            path="/representante/senha/resetar/:cod/:token"
          />
          <PrivateRouteSeller
            component={TeamSeller}
            exact
            path="/representante/equipe"
          />
          <PrivateRouteSeller
            component={Home}
            exact
            path="/representante/home"
          />
          <PrivateRouteSeller
            component={Perfil}
            exact
            path="/representante/perfil"
          />
          <PrivateRouteSeller
            component={Security}
            exact
            path="/representante/seguranca"
          />
          <PrivateRouteSeller
            component={PanelContactsSeller}
            exact
            path="/representante/contatos"
          />

          <PrivateRouteSellerManagerOrSupervisor
            component={AnalyticDevolution}
            exact
            path="/representante/analise/devolucao"
          />

          <PrivateRouteSeller
            component={Devoluiton}
            exact
            path="/representante/devolucao/:id"
          />
          <PrivateRouteSeller
            component={PanelDevolutions}
            exact
            path="/representante/devolucoes"
          />
          <PrivateRouteSeller
            component={AccessTablet}
            exact
            path="/representante/acessos-tablet"
          />

          <PrivateRouteSeller
            component={PanelBillet}
            exact
            path="/representante/boletos"
          />

          <PrivateRouteSeller
            component={ShowFile}
            exact
            path="/representante/arquivo/:id"
          />

          <PrivateRouteSeller
            component={PanelShowFile}
            exact
            path="/representante/arquivos"
          />
          <PrivateRouteSeller
            component={CreateForecast}
            exact
            path="/representante/criar/previsao"
          />
          <PrivateRouteSeller
            component={PanelForecast}
            exact
            path="/representante/previsoes"
          />
          <PrivateRouteSeller
            component={Forecast}
            exact
            path="/representante/previsao/:id"
          />
          <PrivateRouteSeller
            component={PanelServiceInvoice}
            exact
            path="/representante/notas-servico"
          />
          <PrivateRouteSeller
            component={ServiceInvoice}
            exact
            path="/representante/nota-servico/:id"
          />

          <PrivateRouteSeller
            component={PanelRequestUpdateClient}
            exact
            path="/representante/atualizacao-fotos"
          />
          <PrivateRouteSeller
            component={RequestUpdateClient}
            exact
            path="/representante/atualizacao-fotos/:id"
          />

          <PrivateRouteSeller
            component={PanelOrder}
            exact
            path="/representante/pedidos/situacao/:position"
          />
          <PrivateRouteSeller
            component={Order}
            exact
            path="/representante/pedidos/:id"
          />
          <PrivateRouteSeller
            component={PanelClientsAnality}
            exact
            path="/representante/analise/clientes/"
          />
          <PrivateRouteSeller
            component={ClientAnality}
            exact
            path="/representante/analise/clientes/:id"
          />
          <PrivateRouteSeller
            component={Calculator}
            exact
            path="/representante/calculadora-vendor"
          />

          <PrivateRouteSeller
            component={CreateClient}
            exact
            path="/representante/criar/cliente"
          />
          <PrivateRouteSeller
            component={PanelClients}
            exact
            path="/representante/clientes"
          />
          <PrivateRouteSeller
            component={Client}
            exact
            path="/representante/cliente/:id"
          />
          <PrivateRouteSeller
            component={PanelEnchestesRsSeller}
            exact
            path="/representante/enchestes-rs/"
          />
          <PrivateRouteSellerManagerOrSupervisor
            component={PanelEnchestesRs}
            exact
            path="/representante/manager/enchestes-rs/"
          />
          <PrivateRouteSellerManagerOrSupervisor
            component={EnchestesRs}
            exact
            path="/representante/manager/enchestes-rs/:clientId/:sellerCod/:brandName"
          />
          <PrivateRouteSeller
            component={EnchestesRs}
            exact
            path="/representante/enchestes-rs/:clientId"
          />

          {/* Rotas de representante Supervisor ou Gerente */}
          <PrivateRouteSellerManagerOrSupervisor
            component={ForecastSeller}
            exact
            path="/representante/previsao/detalheda/:id"
          />

          <PrivateRouteSellerManagerOrSupervisor
            component={SchedulingAnalytic}
            exact
            path="/representante/analise/agendamento"
          />
          <PrivateRouteSellerManagerOrSupervisor
            component={PanelScheduling}
            exact
            path="/representante/agendamentos"
          />
          <PrivateRouteSellerManagerOrSupervisor
            component={Scheduling}
            exact
            path="/representante/agendamento/:id"
          />
          <PrivateRouteSellerManagerOrSupervisor
            component={Scheduler}
            exact
            path="/representante/calendario"
          />
          <PrivateRouteSellerManagerOrSupervisor
            component={Seller}
            exact
            path="/representante/representante/:id"
          />
          <PrivateRouteSellerManagerOrSupervisor
            component={PanelSelles}
            exact
            path="/representante/representantes"
          />

          {/* Rotas de admin */}
          <PublicRouteUser component={Login} exact path="/admin" />
          <RouteAdminCatalog component={Catalog} exact path="/catalago" />
          <RouteAuth component={InnerHtml} exact path="/innerHTML" />
          <PrivateRouteAdmin
            component={Scheduler}
            exact
            path="/admin/calendario"
          />
          <PrivateRouteAdmin component={Home} exact path="/admin/home" />
          <PrivateRouteAdmin component={Perfil} exact path="/admin/perfil" />
          <PrivateRouteAdmin
            component={Security}
            exact
            path="/admin/seguranca"
          />
          <PrivateRouteAdmin
            component={DevoluitonLegacy}
            exact
            path="/admin/devolucaoLegado"
          />
          <PrivateRouteAdmin
            component={PanelDevolutionsLegacy}
            exact
            path="/admin/devolucoesLegado"
          />
          <PrivateRouteAdmin
            component={Payment}
            exact
            path="/admin/negociacao/realizada/:id"
          />
          <PrivateRouteAdmin
            component={Debt}
            exact
            path="/admin/negociacao/info/:id"
          />

          <PrivateRouteAdmin
            component={CreateDebt}
            exact
            path="/admin/criar/negociacao/aberta"
          />
          <PrivateRouteAdmin
            component={PanelDebt}
            exact
            path="/admin/negociacoes/abertas"
          />
          <PrivateRouteAdmin
            component={Client}
            exact
            path="/admin/cliente/:id"
          />
          <PrivateRouteAdmin
            component={PanelClients}
            exact
            path="/admin/clientes"
          />
          <PrivateRouteAdmin
            component={CreateClient}
            exact
            path="/admin/criar/cliente"
          />
          <PrivateRouteAdmin
            component={Seller}
            exact
            path="/admin/representante/:id"
          />
          <PrivateRouteAdmin
            component={PanelSelles}
            exact
            path="/admin/representantes"
          />

          <PrivateRouteAdmin
            component={PanelServiceInvoice}
            exact
            path="/admin/notas-servico"
          />
          <PrivateRouteAdmin
            component={ServiceInvoice}
            exact
            path="/admin/nota-servico/:id"
          />
          <PrivateRouteAdmin
            component={CreateServiceInvoice}
            exact
            path="/admin/criar/nota-servico"
          />

          <PrivateRouteAdmin
            component={Indemnity}
            exact
            path="/admin/indenizacao/:id"
          />
          <PrivateRouteAdmin
            component={PanelIndemnity}
            exact
            path="/admin/indenizacaos"
          />
          <PrivateRouteAdmin
            component={CreateIndemnity}
            exact
            path="/admin/criar/indenizacao"
          />
          <PrivateRouteAdmin
            component={PanelCollection}
            exact
            path="/admin/colecoes"
          />
          <PrivateRouteAdmin
            component={CreateCollection}
            exact
            path="/admin/criar/colecao"
          />
          <PrivateRouteAdmin
            component={Collection}
            exact
            path="/admin/colecao/:id"
          />
          <PrivateRouteAdmin
            component={Tracking}
            exact
            path="/admin/rastreamento"
          />
          <PrivateRouteAdmin
            component={CreateHeaderDatasheet}
            exact
            path="/admin/criar/cabecalho-ficha-tecnica"
          />
          <PrivateRouteAdmin
            component={CreateQuality}
            exact
            path="/admin/criar/qualidade/cabecalho-ficha-tecnica/:headerId/ficha-tecnica/:datasheetId"
          />

          <PrivateRouteAdmin
            component={PanelHeaderDatasheet}
            exact
            path="/admin/cabecalho-fichas-tecnicas"
          />
          <PrivateRouteAdmin
            component={HeaderDatasheet}
            exact
            path="/admin/cabecalho-ficha-tecnica/:id"
          />
          <PrivateRouteAdmin
            component={StatusHeaderDatasheet}
            exact
            path="/admin/status/cabecalho/ficha-tecnica"
          />
          <PrivateRouteAdmin
            component={PanelStatusHeaderDatasheet}
            exact
            path="/admin/status/cabecalho/fichas-tecnicas"
          />
          <PrivateRouteAdmin
            component={CreateStatusHeaderDatasheet}
            exact
            path="/admin/criar/status/cabecalho/ficha-tecnica"
          />

          <PrivateRouteAdmin
            component={Datasheet}
            exact
            path="/admin/cabecalho-ficha-tecnica/:idHeaderDatasheet/ficha-tecnica/:idDatasheet"
          />
          <PrivateRouteAdmin
            component={Quality}
            exact
            path="/admin/cabecalho-ficha-tecnica/:idHeaderDatasheet/ficha-tecnica/:idDatasheet/qualidade/:idQuality"
          />
          <PrivateRouteAdmin
            component={StatusDatasheet}
            exact
            path="/admin/status/ficha-tecnica"
          />
          <PrivateRouteAdmin
            component={PanelStatusDatasheet}
            exact
            path="/admin/status/fichas-tecnicas"
          />
          <PrivateRouteAdmin
            component={CreateStatusDatasheet}
            exact
            path="/admin/criar/status/ficha-tecnica"
          />
          <PrivateRouteAdmin
            component={CreateForecast}
            exact
            path="/admin/criar/previsao"
          />
          <PrivateRouteAdmin
            component={PanelForecast}
            exact
            path="/admin/previsoes"
          />
          <PrivateRouteAdmin
            component={Forecast}
            exact
            path="/admin/previsao/:id"
          />
          <PrivateRouteAdmin
            component={ForecastSeller}
            exact
            path="/admin/previsao/detalheda/:id"
          />
          <PrivateRouteAdmin
            component={PanelBillet}
            exact
            path="/admin/boletos"
          />
          <PrivateRouteAdmin
            component={PanelOrder}
            exact
            path="/admin/pedidos/situacao/:position"
          />
          <PrivateRouteAdmin
            component={Order}
            exact
            path="/admin/pedidos/:id"
          />
          <PrivateRouteAdmin
            component={PanelClientsAnality}
            exact
            path="/admin/analise/clientes/"
          />
          <PrivateRouteAdmin
            component={ClientAnality}
            exact
            path="/admin/analise/clientes/:id"
          />
          <PrivateRouteAdmin
            component={ClientAnality}
            exact
            path="/admin/analise/clientes/:id"
          />

          <PrivateRouteAdmin
            component={PanelEnchestesRs}
            exact
            path="/admin/enchestes-rs/"
          />
          <PrivateRouteAdmin
            component={EnchestesRs}
            exact
            path="/admin/enchestes-rs/:clientId/:sellerCod/:brandName"
          />

          <PrivateRouteAdmin
            component={PanelRequestUpdateClient}
            exact
            path="/admin/atualizacao-fotos"
          />
          <PrivateRouteAdmin
            component={RequestUpdateClient}
            exact
            path="/admin/atualizacao-fotos/:id"
          />

          <PrivateRouteAdmin component={User} exact path="/admin/usuario/:id" />
          <PrivateRouteAdmin
            component={PanelUsers}
            exact
            path="/admin/usuarios"
          />
          <PrivateRouteAdmin
            component={CreateUser}
            exact
            path="/admin/criar/usuario"
          />
          <PrivateRouteAdmin
            component={Provider}
            exact
            path="/admin/fornecedor/:id"
          />
          <PrivateRouteAdmin
            component={PanelProvider}
            exact
            path="/admin/fornecedores"
          />
          <PrivateRouteAdmin
            component={CreateProvider}
            exact
            path="/admin/criar/fornecedor"
          />
          <PrivateRouteAdmin
            component={CreatePermission}
            exact
            path="/admin/criar/permissao"
          />
          <PrivateRouteAdmin
            component={PanelPermissions}
            exact
            path="/admin/permissoes"
          />
          <PrivateRouteAdmin
            component={Permission}
            exact
            path="/admin/permissao/:id"
          />
          <PrivateRouteAdmin
            component={Devoluiton}
            exact
            path="/admin/devolucao/:id"
          />
          <PrivateRouteAdmin
            component={PanelDevolutions}
            exact
            path="/admin/devolucoes"
          />
          <PrivateRouteAdmin
            component={CreateDecolucao}
            exact
            path="/admin/criar/devolucao"
          />
          <PrivateRouteAdmin
            component={ShowFile}
            exact
            path="/admin/arquivo/:id"
          />

          <PrivateRouteAdmin
            component={PanelShowFile}
            exact
            path="/admin/arquivos"
          />
          <PrivateRouteAdmin
            component={CreateShowFile}
            exact
            path="/admin/criar/arquivo"
          />
          <PrivateRouteAdmin
            component={StatusDevolution}
            exact
            path="/admin/status/devolucao"
          />
          <PrivateRouteAdmin
            component={PanelStatusDevolution}
            exact
            path="/admin/status/devolucoes"
          />
          <PrivateRouteAdmin
            component={CreateStatusDevolution}
            exact
            path="/admin/criar/status/devolucao"
          />
          <PrivateRouteAdmin
            component={DescriptionForecast}
            exact
            path="/admin/descricao/forecast/:id"
          />
          <PrivateRouteAdmin
            component={PanelDescriptionForecast}
            exact
            path="/admin/descricoes/forecast"
          />
          <PrivateRouteAdmin
            component={CreateDescriptionForecast}
            exact
            path="/admin/criar/descricao/forecast"
          />
          <PrivateRouteAdmin
            component={StatusIndemnity}
            exact
            path="/admin/status/indenizacao"
          />
          <PrivateRouteAdmin
            component={PanelStatusIndemnity}
            exact
            path="/admin/status/indenizacoes"
          />
          <PrivateRouteAdmin
            component={CreateStatusIndemnity}
            exact
            path="/admin/criar/status/indenizacao"
          />
          <PrivateRouteAdmin
            component={StatusScheduling}
            exact
            path="/admin/status/agendamento"
          />
          <PrivateRouteAdmin
            component={PanelStatusScheduling}
            exact
            path="/admin/status/agendamentos"
          />
          <PrivateRouteAdmin
            component={CreateStatusScheduling}
            exact
            path="/admin/criar/status/agendamento"
          />
          <PrivateRouteAdmin
            component={PanelScheduling}
            exact
            path="/admin/agendamentos"
          />
          <PrivateRouteAdmin
            component={Scheduling}
            exact
            path="/admin/agendamento/:id"
          />
          <PrivateRouteAdmin
            component={PanelBrands}
            exact
            path="/admin/marcas"
          />
          <PrivateRouteAdmin
            component={PanelShippingCompany}
            exact
            path="/admin/transportadoras"
          />
          <PrivateRouteAdmin
            component={PanelSalesman}
            exact
            path="/admin/preechimento/representantes"
          />
          <PrivateRouteAdmin
            component={PanelBrandManager}
            exact
            path="/admin/supervisores"
          />
          <PrivateRouteAdmin
            component={PanelReasonInternalDevolution}
            exact
            path="/admin/motivo/interno/devolucoes"
          />
          <PrivateRouteAdmin
            component={PanelReasonInternalIndemnity}
            exact
            path="/admin/motivo/interno/indenizacoes"
          />
          <PrivateRouteAdmin
            component={ImportShippingCompany}
            exact
            path="/admin/importacao/transportadoras"
          />
          <PrivateRouteAdmin
            component={ImportColor}
            exact
            path="/admin/importacao/cores"
          />
          <PrivateRouteAdmin
            component={ImportClient}
            exact
            path="/admin/importacao/cliente"
          />
          <PrivateRouteAdmin
            component={ImportBillet}
            exact
            path="/admin/importacao/boleto"
          />
          <PrivateRouteAdmin
            component={ImportBrand}
            exact
            path="/admin/importacao/marca"
          />
          <PrivateRouteAdmin
            component={ImportSalesman}
            exact
            path="/admin/importacao/preechimento/representante"
          />
          <PrivateRouteAdmin
            component={ImportSeller}
            exact
            path="/admin/importacao/representante"
          />
          <PrivateRouteAdmin
            component={ImportBrandToSeller}
            exact
            path="/admin/importacao/representantemarcas"
          />
          <PrivateRouteAdmin
            component={ImportBrandManager}
            exact
            path="/admin/importacao/supervisor"
          />
          <PrivateRouteAdmin
            component={ImportReasonInternalDevolution}
            exact
            path="/admin/importacao/motivo/interno/devolucao"
          />
          <PrivateRouteAdmin
            component={ImportReasonInternalIndemnity}
            exact
            path="/admin/importacao/motivo/interno/indenizacao"
          />
          <PrivateRouteAdmin
            component={ConfigGateway}
            exact
            path="/admin/configuracoes/gatewayspagamento"
          />
          <PrivateRouteAdmin
            component={ConfigOrder}
            exact
            path="/admin/configuracoes/pedidos"
          />
          <PrivateRouteAdmin
            component={ConfigCalculator}
            exact
            path="/admin/configuracoes/calculadora"
          />
          <PrivateRouteAdmin
            component={Calculator}
            exact
            path="/admin/calculadora-vendor"
          />

          <PrivateRouteAdmin
            component={AnalyticDevolution}
            exact
            path="/admin/analise/devolucao"
          />
          <PrivateRouteAdmin
            component={AccessAnalytic}
            exact
            path="/admin/analise/acessos"
          />
          <PrivateRouteAdmin
            component={SchedulingAnalytic}
            exact
            path="/admin/analise/agendamento"
          />
          <PrivateRouteAdmin
            component={RequestUpdatedClientAnality}
            exact
            path="/admin/analise/atualizacao-cadastral"
          />

          <PrivateRouteAdmin component={PanelNcms} exact path="/admin/ncms" />
          <PrivateRouteAdmin
            component={DetailNcms}
            exact
            path="/admin/ncms/:id"
          />
          <PrivateRouteAdmin
            component={CreateNcms}
            exact
            path="/admin/criar/ncms"
          />

          <PrivateRouteAdmin
            component={PanelColors}
            exact
            path="/admin/cores"
          />
          <PrivateRouteAdmin
            component={DetailColors}
            exact
            path="/admin/cores/:id"
          />
          <PrivateRouteAdmin
            component={CreateColors}
            exact
            path="/admin/criar/cores"
          />
          <PrivateRouteAdmin
            component={CreateContacts}
            exact
            path="/admin/criar/contatos"
          />
          <PrivateRouteAdmin
            component={CreateContacts}
            exact
            path="/admin/contatos/:id"
          />
          <PrivateRouteAdmin
            component={PanelContacts}
            exact
            path="/admin/contatos"
          />
          <PrivateRouteAdmin
            component={CreateBanners}
            exact
            path="/admin/criar/banners"
          />
          <PrivateRouteAdmin
            component={CreateBanners}
            exact
            path="/admin/banners/:id"
          />
          <PrivateRouteAdmin
            component={PanelBanners}
            exact
            path="/admin/banners"
          />
          {/* Rota de erro */}
          <Route
            component={() => (
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <p style={{ fontSize: 64 }}>Erro 404</p>
              </div>
            )}
            exact
            path="*"
          />
        </Switch>
      </ToastProvider>
      {/* </DataProvider> */}
    </BrowserRouter>
  );
};

export default Routes;
