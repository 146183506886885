import React, { useEffect, useState } from "react";
import { AiFillAndroid } from "react-icons/ai";
import { FaApple } from "react-icons/fa";
import ReactPlayer from "react-player";
import QRCode from "react-qr-code";
import * as sellerLocalStorage from "../../service/localStorage/seller";

import { CreateAndDetailLayout } from "../../components/CreateAndDetailLayout";
import { Input } from "../../components/Form/Input";
import { PanelAndDetailAndCreateHeader } from "../../components/panel/PanelAndDetailAndCreateHeader";
import { Seller } from "../../hook/queries/useSeller";
import api from "../../service/api";
import { Card } from "./style";

interface IHeaderProps {
  match: {
    params: {
      id: string;
    };
  };
}

export const AccessTablet: React.FC<IHeaderProps> = () => {
  const [loading, setLoading] = useState(true);
  const sellerStorage = sellerLocalStorage.getUser() as Seller;

  const [seller, setSeller] = useState<Seller>({} as Seller);

  useEffect(() => {
    (async () => {
      const response = await api.get<Seller>(
        `/sellerconfig/${sellerStorage.id}`
      );

      setSeller(response.data);
      setLoading(false);
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <CreateAndDetailLayout isLoading={loading}>
      <PanelAndDetailAndCreateHeader title={"Acessos Tablet"} goBack />

      <Card>
        <div>
          <h3>Nextdata Commerce</h3>
          <p>
            Força de vendas, aplicativo móvel disponível para digitação de
            pedidos.
          </p>

          <h4>Pré-requisito:</h4>
          <span>
            Apenas tablets, marcas homologadas: Samsung e Apple. Indicamos que
            este aparelho possua internet móvel para que seja possível trabalhar
            na rua.
          </span>

          <div className="requirements">
            <div>
              <h4>Apple:</h4>
              <ul>
                <li>Com no mínimo 32GB de armazenamento.</li>
                <li>Tamanho de tela 9.7.</li>
                <li>Versão do sistema IOS 15.0 ou superior.</li>
              </ul>

              <QRCode
                value="https://apps.apple.com/br/app/nextdata-commerce/id1173005545"
                style={{ height: 100, marginTop: "2rem" }}
              />

              <a
                href="https://apps.apple.com/br/app/nextdata-commerce/id1173005545"
                target="_blank"
                rel="noopener noreferrer"
                style={{ background: "#000" }}
              >
                <FaApple size={20} />
                Baixar
              </a>
            </div>

            <div>
              <h4>Samsung:</h4>
              <ul>
                <li>Com no mínimo 16GB de armazenamento.</li>
                <li>Tamanho de tela 9.7.</li>
                <li>Versão do sistema Android igual ou superior a 12.0.</li>
              </ul>

              <QRCode
                style={{ height: 100, marginTop: "2rem" }}
                value="https://play.google.com/store/apps/details?id=br.com.nextdata.app.commerce2&hl=pt"
              />

              <a
                href="https://play.google.com/store/apps/details?id=br.com.nextdata.app.commerce2&hl=pt"
                target="_blank"
                rel="noopener noreferrer"
                style={{ background: "#01875f" }}
              >
                <AiFillAndroid size={20} />
                Baixar
              </a>
            </div>
          </div>

          <h3 style={{ marginTop: "1rem", marginBottom: "1rem" }}>Acessos</h3>

          <Input
            name="nextdata_password"
            label="Login"
            value={seller?.nextdata_login}
            readOnly
            isCopyClipboard
          />
          <Input
            name="nextdata_login"
            label="Senha"
            value={seller?.nextdata_password}
            readOnly
            isCopyClipboard
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <ReactPlayer
            url="https://youtu.be/D_wIlFxx7OY"
            width="100%"
            height="18rem"
            style={{ borderRadius: 8, overflow: "hidden" }}
            controls
          />
        </div>
      </Card>
    </CreateAndDetailLayout>
  );
};
