import React, { useState, useEffect } from "react";
import * as userLocalStorage from "../../../service/localStorage/user";
import {
  Container,
  ContainerForm,
  Form,
  ButtunSubmit,
  Error,
  ScreenLoading,
} from "../styles";
import Loading from "../../../components/loadings/Loading";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import * as Yup from "yup";

import api from "../../../service/api";

interface IHeaderProps {
  admin: boolean;
}

interface IBrand {
  id: number;
  cod: number;
  name: string;
  is_active: boolean;
}

interface IDescription {
  id: number;
  description: string;
  is_active: boolean;
}

interface IStatus {
  id?: number;
  cod: 1;
  name: string;
  color: string;
  finisher: boolean;
  responsable_id: number;
}

const Create: React.FC<IHeaderProps> = () => {
  const userStorage = userLocalStorage.getUser() as userLocalStorage.User;

  const { addToast } = useToasts();
  const history = useHistory();

  const [years, setYears] = useState<
    {
      id: number;
      year: string;
    }[]
  >([]);

  const [loading, setLoading] = useState(false);

  const [descriptions, setDescriptions] = useState<IDescription[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [status, setStatus] = useState<IStatus[]>([]);
  const [statusDates, setStatusDates] = useState({});
  const [statusDatesError, setStatusDatesError] = useState("");

  useEffect(() => {
    (async () => {
      let dateYears = [];
      const year = new Date().getFullYear();

      for (let index = 0; index < 5; index++) {
        dateYears.push({ id: index + 1, year: String(year + index) });
      }

      setYears(dateYears);

      const brandDB = await api.get<IBrand[]>("/populate/brand", {
        params: { active: 1, licensed: 1 },
      });
      setBrands(brandDB.data);

      const descriptionDB = await api.get<IDescription[]>(
        "/populate/forecast/description",
        {
          params: { active: 1 },
        }
      );
      setDescriptions(descriptionDB.data);

      const statusDB = await api.get<IStatus[]>("/populate/collection/status", {
        params: { finisher: 0 },
      });
      setStatus(statusDB.data);
    })();
  }, []);

  useEffect(() => {
    if (
      userStorage &&
      userStorage.permission &&
      (+userStorage.permission === 4 || +userStorage.permission === 11)
    ) {
    } else {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [userStorage]);

  async function handleSubmit(item: any) {
    setLoading(true);
    try {
      const keys = Object.keys(statusDates);
      const values = Object.values(statusDates);
      let status: { cod: number; value: Date }[] = [];

      for (let index = 0; index < values.length; index++) {
        const value: any = values[index];
        const key = keys[index];

        status.push({
          cod: +key,
          value: new Date(value),
        });
      }

      if (!statusDatesError) {
        const data = {
          ...item,
          statusDate: status,
        };

        await api.post("/collection", data);

        setLoading(false);

        addToast("Coleção criado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/admin/colecoes");
      } else {
        setLoading(false);
      }
    } catch (err) {
      const error = err as any;
      setLoading(false);
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setStatusDates({ ...statusDates, [event.target.name]: event.target.value });
  }

  async function testDate() {
    var data = [];

    for (const item of status) {
      //@ts-ignore
      if (statusDates[item.cod]) {
        data.push(item.cod);
      }
    }

    if (status.length !== data.length) {
      setStatusDatesError("Definição de datas é obrigatório");
    } else {
      setStatusDatesError("");
    }
  }

  return (
    <Container>
      {brands && status && status[0] && brands[0] ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>

            <h2>Criar Coleção</h2>
          </header>

          <ContainerForm>
            <Formik
              initialValues={{
                // title: "",
                year: "",
                brand: "",
                collection: "",
              }}
              validationSchema={Yup.object({
                // title: Yup.string().required("Nome é obrigatório"),
                year: Yup.string().required("Ano é obrigatório"),
                brand: Yup.string().required("Marca é obrigatório"),
                collection: Yup.string().required("Coleção é obrigatório"),
              })}
              onSubmit={(data) => handleSubmit(data)}
            >
              {(formik) => (
                <Form
                  onSubmit={
                    loading
                      ? (e) => {
                          e.preventDefault();
                        }
                      : formik.handleSubmit
                  }
                >
                  {/* <div className="field">
                    <label htmlFor="title">Descrição</label>
                    <input {...formik.getFieldProps("title")} name="title" />
                    {formik.touched.title && formik.errors.title ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formik.errors.title} </span>
                      </Error>
                    ) : null}
                  </div> */}

                  <div className="field-group">
                    <div className="field">
                      <label htmlFor="collection">Coleção</label>
                      <select
                        {...formik.getFieldProps("collection")}
                        name="collection"
                      >
                        <option value="">Selecione uma coleção</option>
                        {descriptions.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </select>
                      {formik.touched.collection && formik.errors.collection ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formik.errors.collection} </span>
                        </Error>
                      ) : null}
                    </div>
                    <div className="field">
                      <label htmlFor="year">Ano</label>
                      <select {...formik.getFieldProps("year")} name="year">
                        <option value="">Selecione um ano</option>;
                        {years.map((item) => (
                          <option key={item.id} value={Number(item.year)}>
                            {item.year}
                          </option>
                        ))}
                      </select>
                      {formik.touched.year && formik.errors.year ? (
                        <Error>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formik.errors.year} </span>
                        </Error>
                      ) : null}
                    </div>
                  </div>

                  <div className="field">
                    <label htmlFor="year">Marca</label>
                    <select {...formik.getFieldProps("brand")} name="brand">
                      <option value="">Selecione um ano</option>;
                      {brands.map((item) => (
                        <option key={item.id} value={Number(item.cod)}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.brand && formik.errors.brand ? (
                      <Error>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formik.errors.brand} </span>
                      </Error>
                    ) : null}
                  </div>

                  <h3>Definição de datas nos status </h3>
                  {statusDatesError ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {statusDatesError} </span>
                    </Error>
                  ) : null}
                  <div className="field-group">
                    {status.map((item, index) => (
                      <div className="field" key={item.id}>
                        <label htmlFor="title">{item.name}</label>
                        <input
                          onChange={onChange}
                          // value={}
                          name={String(item.cod)}
                          type="date"
                        />
                      </div>
                    ))}
                  </div>

                  <ButtunSubmit
                    type="submit"
                    onClick={() => {
                      testDate();
                    }}
                  >
                    {loading ? (
                      <div>
                        <Loading borderSize={2} size={20} />
                      </div>
                    ) : (
                      "Criar"
                    )}
                  </ButtunSubmit>
                </Form>
              )}
            </Formik>
          </ContainerForm>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Create;
