import { useQuery, UseQueryResult } from "react-query";
import { OptionsFilter } from "../../@types";
import { ResponsePanelApiDefault } from "../../@types/panelTypes";
import api from "../../service/api";

export type Client = {
  id: string;
  cod: number;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
  phone?: string;
  phone2?: string;
  cellPhone?: string;
  situation: number;
  is_active: boolean;
  created_at?: Date;
  lastOrderDate?: Date;
  lastOrderDateToString: string;

  registerGroup: {
    cod: number;
    name: string;
  };

  adresses?: {
    zip_code?: string;
    uf?: string;
    city?: string;
    street?: string;
    neighborhood?: string;
    latitude?: string;
    longitude?: string;
  };

  client_enchete_rs?: {
    id: number;
    email: string | null;
    phone: string | null;
    reopening: boolean | null;
    openingForecast: Date | null;
    brand: {
      name: string;
    };
    seller: {
      cod: number;
      abbreviation: string;
    };
    invoces_period: {
      id: number;
      amount: number;
      date: Date;
      period: string;
      invoice: boolean;
    }[];
  }[];

  access: {
    date: Date;
    dateToString: string;
  }[];

  isHasNetwork: boolean;
  qtdStores: string;
  cityPurchase: string;
  isCentralizedDelivery: boolean;
  cnpjToCentralizedDelivery: string;
  isStoreInShopping: boolean;
  managerNamePurchase: string;
  managerPhonePurchase: string;
  managerEmailPurchase: string;
  invoiceEmail: string;
  businessEmail: string;
  segment: string;

  logUpdateInternalData: {
    id: string;
    created_at: Date;
    created_atToString: string;
    user: {
      id: number;
      name: string;
      lastname: string;
    };
  }[];

  brands: {
    id: number;
    cod: number;
  }[];
  productTypes: {
    id: number;
    name: string;
  }[];

  lastImageUpdateDate?: Date;
};

export type EnchentesRs = {
  client_pj: {
    id: string;
    cod: number;
    cnpj?: string;
    company_name?: string;
    trade_name?: string;
    email?: string;
    phone?: string;
    phone2?: string;
    cellPhone?: string;
    situation: number;
    is_active: boolean;
    created_at?: Date;
    lastOrderDate?: Date;
    lastOrderDateToString: string;

    registerGroup: {
      cod: number;
      name: string;
    };

    adresses?: {
      zip_code?: string;
      uf?: string;
      city?: string;
      street?: string;
      neighborhood?: string;
      latitude?: string;
      longitude?: string;
    };

    client_enchete_rs?: {
      id: number;
      email: string | null;
      phone: string | null;
      reopening: boolean | null;
      openingForecast: Date | null;
      brand: {
        name: string;
      };
      seller: {
        cod: number;
        abbreviation: string;
      };
      invoces_period: {
        id: number;
        amount: number;
        date: Date;
        period: string;
        invoice: boolean;
      }[];
    }[];

    access: {
      date: Date;
      dateToString: string;
    }[];

    isHasNetwork: boolean;
    qtdStores: string;
    cityPurchase: string;
    isCentralizedDelivery: boolean;
    cnpjToCentralizedDelivery: string;
    isStoreInShopping: boolean;
    managerNamePurchase: string;
    managerPhonePurchase: string;
    managerEmailPurchase: string;
    invoiceEmail: string;
    businessEmail: string;
    segment: string;

    logUpdateInternalData: {
      id: string;
      created_at: Date;
      created_atToString: string;
      user: {
        id: number;
        name: string;
        lastname: string;
      };
    }[];

    brands: {
      id: number;
      cod: number;
    }[];
    productTypes: {
      id: number;
      name: string;
    }[];

    lastImageUpdateDate?: Date;
  };

  brand: { cod: number; name: string };
  seller: {
    cod: number;
    abbreviation: string;
    fullName: string;
  };
};

export type ClientFilters = {
  cod?: string;
  company_name?: string;
  cnpj?: string;
  hasOrder?: boolean;
  isClientContact?: string;
  uf?: string;
  orderType?: string;
  billingOrderPeriod?: number;
  billingOrderStartDate?: string;
  billingOrderEndDate?: string;
  brandId?: number;

  updateImagePeriod?: number;
  updateImagePresetDate?: number;
  updateImageSpecificDate?: Date;
  updateImageStartDate?: Date;
  updateImageEndDate?: Date;
};

export interface FilterOptions {
  neighborhood: OptionsFilter[];
  city: OptionsFilter[];
  seller: OptionsFilter[];
  brand: OptionsFilter[];
}

type getClientsEnchenteRSResponse = {
  clients: Client[];
  filters: FilterOptions;
  totalCount: number;
};

type getClientsEnchenteRSOperatorResponse = {
  clients: EnchentesRs[];
  filters: FilterOptions;
  totalCount: number;
};

interface ClientApiOperatorResponse extends ResponsePanelApiDefault {
  clients: EnchentesRs[];
  filters: FilterOptions;
}
interface ClientApiResponse extends ResponsePanelApiDefault {
  clients: Client[];
  filters: FilterOptions;
}

export async function getClientsEnchenteRS(
  page: number,
  filter?: ClientFilters,
  registersPerPag?: number
): Promise<getClientsEnchenteRSResponse> {
  const { data } = await api.get<ClientApiResponse>("/enchentes-rs/seller", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag, ...filter },
  });

  const clients = data.clients;
  const totalCount = data.total;
  const filters = data.filters;

  return {
    totalCount,
    clients,
    filters,
  };
}

export function useClientsEnchenteRS(
  page: number,
  filter?: ClientFilters,
  registersPerPag?: number
): UseQueryResult<getClientsEnchenteRSResponse> {
  return useQuery(
    ["ClientsEnchenteRS", page, filter],
    () => getClientsEnchenteRS(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getClientsEnchenteRSOperator(
  page: number,
  filter?: ClientFilters,
  registersPerPag?: number
): Promise<getClientsEnchenteRSOperatorResponse> {
  const { data } = await api.get<ClientApiOperatorResponse>("/enchentes-rs/", {
    params: { pageRequest: page - 1, limitRequest: registersPerPag, ...filter },
  });

  const clients = data.clients;
  const totalCount = data.total;
  const filters = data.filters;

  return {
    totalCount,
    clients,
    filters,
  };
}

export function useClientsEnchenteRSOperator(
  page: number,
  filter?: ClientFilters,
  registersPerPag?: number
): UseQueryResult<getClientsEnchenteRSResponse> {
  return useQuery(
    ["ClientsEnchenteRSOperator", page, filter],
    () => getClientsEnchenteRSOperator(page, filter, registersPerPag),
    {
      staleTime: 1000 * 60 * 10, // 10 Minutos
    }
  );
}

export async function getOneClientEnchenteRS(
  id?: number
): Promise<EnchentesRs | undefined> {
  if (!id && id !== 0) return undefined;

  const { data } = await api.get<EnchentesRs>(`/clientPJ/show/${id}`);

  const normalized = {
    ...data,
  };

  return normalized;
}

export function useOneClientEnchenteRS(
  id?: number
): UseQueryResult<EnchentesRs> | undefined {
  return useQuery(["ClientEnchenteRS", id], () => getOneClientEnchenteRS(id), {
    // staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
