import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ActionPrint, Container } from "./styles";

interface CatalogProps {
  location: {
    state?: {
      html?: string;
    };
  };
}

export const Catalog: React.FC<CatalogProps> = (props) => {
  const componentRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    if (!props.location?.state) {
      history.push("/");
    }
    // window.print();

    setTimeout(
      () => {
        // history.goBack();
      },
      1000 * 60 * 5 //5 minute
    );

    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <div className="actions-print" style={{ width: "100vw" }}>
        <ActionPrint>
          <button
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            VOLTAR
          </button>

          <button
            type="button"
            onClick={() => {
              window.print();
              history.goBack();
            }}
          >
            GERAR PDF
          </button>
        </ActionPrint>
      </div>

      {props.location.state && props.location.state.html && (
        <div
          ref={componentRef}
          dangerouslySetInnerHTML={{ __html: props.location.state.html }}
        />
      )}
    </Container>
  );
};
