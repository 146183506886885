import React from "react";
import { StyledBurger } from "./styles";

interface BurguerProps {
  isOpen: boolean;
}

export function Burguer({ isOpen }: BurguerProps) {
  return (
    <StyledBurger open={isOpen}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
}
