import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import useOutsideAlerter from "../../../hook/outsideAlerter";

import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../../service/api";

import LoadingDot from "../../../components/loadings/LoadingDot";

import { FiSearch } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";
import { IPermissionUser } from "../../../@types";
import { dataZero } from "../../../utils/dataProcessing";
import {
  Container,
  ContainerList,
  Filter,
  FilterCheck,
  FilterSelects,
  FooterContainer,
  Form,
  LoadingPanel,
  TableContainer,
} from "../styles";

interface IUser {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
  created_at?: Date;
  isActive?: boolean;

  permission: {
    name: true;
  };
}

interface IFilter {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
  active?: string;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IResponseAPI {
  contents: IUser[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IHeaderProps {
  permission: IPermissionUser;
}

const PanelUsers: React.FC<IHeaderProps> = ({ permission }) => {
  const history = useHistory();
  const outSideRef = useRef(null);

  const [users, setUsers] = useState<IUser[]>([]);
  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({});

  const [loading, setLoading] = useState(true);

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 15 } as IRequestPagination);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const userDB = await api.get<IResponseAPI>("/userAdmin", {
          params: { ...requestPagination, ...filters },
        });

        const { contents, limit, page, pages, total } = userDB.data;

        setUsers(contents);
        setPagination({ limit, page, pages, total });
        setLoading(false);
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, [filters, requestPagination]);

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setFilterView(false);
  }

  function hadleToNewUser() {
    history.push("/admin/criar/usuario");
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  return (
    <Container>
      <header>
        <h2>Lista de usuários</h2>
      </header>

      <Filter>
        <button
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="button"
        >
          <FiSearch />
        </button>
        <input
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="text"
          name="filter"
          id="filter"
          readOnly={true}
        />
        <FilterSelects>
          <ul>
            {filters.id && (
              <li>
                <span>ID: {filters.id}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, id: "" })}
                />
              </li>
            )}
            {filters.name && (
              <li>
                <span>Nome: {filters.name}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, name: "" })}
                />
              </li>
            )}
            {filters.lastname && (
              <li>
                <span>Sobrenome: {filters.lastname}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, lastname: "" })}
                />
              </li>
            )}
            {filters.email && (
              <li>
                <span>Email: {filters.email}</span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, email: "" })}
                />
              </li>
            )}
            {filters.active && (
              <li>
                <span>
                  Situação:{" "}
                  {Boolean(Number(filters.active)) ? "Ativo" : "Inativo"}
                </span>
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, active: undefined })}
                />
              </li>
            )}
          </ul>
        </FilterSelects>

        {filterView && (
          <FilterCheck ref={outSideRef}>
            <header>
              <button
                onClick={() => {
                  setFilterView(false);
                }}
              >
                Fechar
              </button>
            </header>
            <main>
              <Formik
                initialValues={{
                  id: filters.id,
                  name: filters.name,
                  lastname: filters.lastname,
                  email: filters.email,
                  active: filters.active,
                }}
                validationSchema={Yup.object({})}
                onSubmit={(data) => {
                  hadleFilter(data);
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="id">ID</label>
                        <input id="id" {...formik.getFieldProps("id")} />
                      </div>
                      <div className="field">
                        <label htmlFor="name">Nome</label>
                        <input id="name" {...formik.getFieldProps("name")} />
                      </div>
                    </div>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="lastname">Sobrenome</label>
                        <input
                          id="lastname"
                          {...formik.getFieldProps("lastname")}
                        />
                      </div>
                      <div className="field">
                        <label htmlFor="email">Email</label>
                        <input id="email" {...formik.getFieldProps("email")} />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="active">Ativo</label>
                      <select {...formik.getFieldProps("active")}>
                        <option value=""></option>
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                      </select>
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                )}
              </Formik>
            </main>
          </FilterCheck>
        )}
      </Filter>

      <ContainerList>
        <div className="headerTable">
          <h3>Usuários</h3>

          {permission && permission.userAdmin && permission.userAdmin > 2 && (
            <button onClick={hadleToNewUser} type="button">
              <MdAdd size={22} /> <span> NOVO USUÁRIO </span>
            </button>
          )}
        </div>

        <TableContainer>
          {!loading ? (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NAME</th>
                  <th>SOBRENOME</th>
                  <th>EMAIL</th>
                  <th>PERMISSÃO</th>
                  <th>SITUAÇÃO</th>
                </tr>
              </thead>

              <tbody>
                {users.map((user) => (
                  <tr
                    key={user.id}
                    onClick={() => history.push(`/admin/usuario/${user.id}`)}
                    style={{ opacity: user.isActive ? 1 : 0.5 }}
                  >
                    <td> {user.id} </td>
                    <td> {user.name} </td>
                    <td> {user.lastname} </td>
                    <td> {user.email} </td>
                    <td> {user.permission.name} </td>
                    <td> {user.isActive ? "ATIVO" : "INATIVO"} </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>
        <FooterContainer>
          <span>
            Mostrado
            {dataZero(
              Number(users.length + pagination.limit * pagination.page)
            )}
            de {dataZero(Number(pagination.total))} resultados
          </span>

          <div>
            <button
              className={pagination.page < 1 ? "buttonNull" : ""}
              onClick={pagination.page < 1 ? () => {} : hadlePreviousPage}
              type="button"
            >
              ANTERIOR
            </button>
            <p style={{ marginRight: 8 }}>
              {dataZero(Number(pagination.page + 1))}
            </p>
            <button
              className={
                pagination.page >= pagination.pages ? "buttonNull" : ""
              }
              type="button"
              onClick={
                Number(pagination.page) >= Number(pagination.pages)
                  ? () => {}
                  : hadleNextPage
              }
            >
              PROXIMO
            </button>
          </div>
        </FooterContainer>
      </ContainerList>
    </Container>
  );
};

export default PanelUsers;
