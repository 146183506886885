import styled from "styled-components";

interface StyledBurgerProps {
  open: boolean;
}

export const StyledBurger = styled.div<StyledBurgerProps>`
  cursor: pointer;
  width: 2rem;
  height: 2rem;

  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;

  div {
    width: 2rem;
    height: 4px;
    background-color: ${({ open }) => (open ? "#aaa" : "#333")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.2s ease;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) =>
        open ? "translateX(-100%)" : "translateX(0)"};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
