import React, { FormEvent, useEffect, useState } from "react";

import api from "../../../service/api";

import { useToasts } from "react-toast-notifications";

import { Input } from "../../../components/Form/Input";
import { ButtunSubmit, Container, ContainerForm, Form } from "../styles";

interface HeaderProps {
  admin: boolean;
}

export interface CalculatorDefaultConfig {
  percentVendor: number;
  taxIof: number;
}

const ConfigGateway: React.FC<HeaderProps> = () => {
  const { addToast } = useToasts();
  const [data, setData] = useState<CalculatorDefaultConfig>(
    {} as CalculatorDefaultConfig
  );

  useEffect(() => {
    (async () => {
      try {
        const config = await api.get<CalculatorDefaultConfig>(
          `/calculator/config`
        );

        setData(config.data);
      } catch (error) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    })();

    // eslint-disable-next-line
  }, []);

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setData({
      ...data,
      [name]: value,
    });
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      await api.put(`/calculator/config`, data);

      addToast(
        "Alteração dos parâmetros da calculadora realizada com sucesso",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    } catch (error) {
      console.log(error);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <h2>Parâmetros Calculadora Vendor</h2>
      </header>

      <ContainerForm>
        <Form onSubmit={handleSubmit}>
          <Input
            label="Percentual Vendor"
            name="percentVendor"
            value={data.percentVendor}
            type="number"
            onChange={handleInputChange}
          />

          <Input
            label="Taxa IOF"
            name="taxIof"
            value={data.taxIof}
            // type="number"
            onChange={handleInputChange}
          />

          <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
        </Form>
      </ContainerForm>
    </Container>
  );
};

export default ConfigGateway;
