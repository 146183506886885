import { useQuery, UseQueryResult } from "react-query";
import api from "../../service/api";

export type GroupClient = {
  cod: number;
  name: string;
};

export async function getGroupsClient(): Promise<GroupClient[]> {
  const { data } = await api.get<GroupClient[]>("/register-group-client/list");

  return data;
}

export function useGroupsClient(): UseQueryResult<GroupClient[]> {
  return useQuery(["groups-client"], () => getGroupsClient(), {
    staleTime: 1000 * 60 * 10, // 10 Minutos
  });
}
