import React, { useState, useEffect } from "react";

import Dropzone from "../../../components/Upload/DropzoneXlsx";
import filesize from "filesize";
import generateHash from "../../../utils/generateHash";

import api from "../../../service/api";
import { useToasts } from "react-toast-notifications";
import * as UserStorage from "../../../service/localStorage/user";

import { useHistory } from "react-router-dom";
import { IPermissionUser } from "../../../@types";

import { Container, ContainerPreviews } from "../styles";
import { ContainerDropZone } from "../../detail/styles";
import { ButtunSubmit } from "../../../styles/global";

import Loading from "../../../components/loadings/Loading";

import logoExecel from "../../../assets/logo-execel.svg";
import { IoMdTrash } from "react-icons/io";
import { RiFileExcel2Line } from "react-icons/ri";
import { MdArrowDownward } from "react-icons/md";

interface IResponseSuccess {
  nome: string;
  email: string;
}
interface User {
  id: string;
  name: string;
  lastname: string;
  email: string;
  token: string;
}

interface IHeaderProps {
  permission: IPermissionUser;
}

const ImportSalesman: React.FC<IHeaderProps> = ({ permission }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const userData = UserStorage.getUser() as User;

  const [selectFile, setSelectFile] = useState<File>();
  const [files, setFiles] = useState<File | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectFile) setFiles(selectFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFile]);

  useEffect(() => {
    if (permission && permission.import && permission.import < 3) {
      history.push("/404");
    }

    // eslint-disable-next-line
  }, [permission]);

  async function hadleImport() {
    setLoading(true);

    if (!files) {
      return setLoading(false);
    }

    const formData = new FormData();
    formData.append("file", files);
    formData.append("email", userData.email);
    formData.append("name", `${userData.name} ${userData.lastname}`);

    try {
      const response = await api.post<IResponseSuccess>(
        "/seller/many/sellerToBrand",
        formData
      );

      setFiles(undefined);
      setLoading(false);
      addToast(
        `Este processo pode demorar alguns minutos, então vai ser processado em segundo plano ao fim do mesmo recebera um e-mail em ${response.data.email} para confirmação do processo`,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    } catch (err) {
      const error = err as any;
      setLoading(false);
      if (!error.response || error.response.status === 404) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 406) {
        return addToast("Planilha de importação incorreta!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function hadleDeleteFile() {
    setFiles(undefined);
  }

  return (
    <Container>
      {permission && permission.import && permission.import > 2 ? (
        <>
          <header>
            <h2>Importar Relação de Marca e Representante</h2>

            <nav className="download-file">
              <a
                // eslint-disable-next-line
                target="_blank"
                href="http://api.portal.alpardobrasil.com.br/populate/sellerToBrand/download/file/import"
              >
                Baixar planilha modelo
                <span>
                  <RiFileExcel2Line color="#207245" size={20} />
                  <MdArrowDownward color="#207245" size={14} />
                </span>
              </a>
            </nav>
          </header>

          <ContainerDropZone>
            <Dropzone onFileUploaded={(e) => setSelectFile(e)} />
            <ContainerPreviews>
              {files && (
                <li key={`${generateHash()}-${files.name}`}>
                  <img src={logoExecel} alt="Logo_Execel" />
                  <div className="fileInfo">
                    <div>
                      <strong>{files.name}</strong>
                      <span>{filesize(files.size)}</span>
                    </div>

                    <button
                      type="button"
                      onClick={() => {
                        hadleDeleteFile();
                      }}
                    >
                      <IoMdTrash size={30} />
                    </button>
                  </div>
                </li>
              )}
            </ContainerPreviews>
            <ButtunSubmit
              type="button"
              className="buttonSubmit"
              onClick={() => {
                hadleImport();
              }}
            >
              {loading ? (
                <div>
                  <Loading borderSize={2} size={20} />
                </div>
              ) : (
                "Importar"
              )}
            </ButtunSubmit>
          </ContainerDropZone>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default ImportSalesman;
