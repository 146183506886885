import styled from "styled-components";

export const TabletSelect = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  color: rgba(0, 0, 0, 0.8);

  font-weight: 400;
  padding: 0;
  width: 100%;

  margin-top: 20px;
  display: inline-table;
  overflow: auto;

  thead th {
    position: sticky;
    top: 0px;
  }

  thead > :last-child th {
    background: #fff;
    position: sticky;
    top: 30px; /* This is for all the the "th" elements in the second row, (in this casa is the last child element into the thead) */
  }

  thead > :first-child th {
    background: #fff;
    position: sticky;
    top: 0px; /* This is for all the the "th" elements in the first child row */
  }

  & > thead {
    clip-path: none;
    height: auto;
    overflow: auto;
    position: relative;
    width: auto;
  }

  & > thead > tr {
    border: 0;
    display: table-row;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  & > thead > tr > th {
    padding: 11px 24px;
    /* background-color: #ededed;
  border-bottom: 1px solid #f5f5f5; */
    font-weight: 600;
    vertical-align: middle;
    text-align: initial;
    font-size: 0.95rem;
  }

  & > tbody {
    display: table-row-group;
  }
  & > tbody > tr {
    height: 40px;
    border: 0;
    display: table-row;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
  & > tbody > tr > td {
    display: table-cell;
    box-sizing: border-box;
    font-weight: 500;
    padding: 0 24px;
    font-weight: 400;
    font-size: 0.875rem;
  }

  .action-button {
    height: 32px;
    width: 32px;
    border-radius: 50%;

    margin-left: 0.5rem;

    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    svg {
      color: rgba(0, 0, 0, 0.54);
      font-size: 24px;
    }
  }

  /* & > tbody > tr:nth-child(2n) {
  background-color: #f5f5f5;
} */
`;

export const RowNone = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 7px 0;
  color: #333;
  font-size: 0.875rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;
