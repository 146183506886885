import React, { useState, useEffect, useRef } from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../../service/api";
import { useToasts } from "react-toast-notifications";

import { useHistory } from "react-router-dom";
import useOutsideAlerter from "../../../hook/outsideAlerter";

import { FiSearch } from "react-icons/fi";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { IoMdCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";

import {
  Container,
  Filter,
  FilterCheck,
  ContainerList,
  TableContainer,
  FooterContainer,
  Form,
  FilterSelects,
} from "../styles";
import { IPermissionUser } from "../../../@types";

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IResponseApi {
  contents: IDescription[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IDescription {
  id: number;
  description: string;
  is_active: boolean;
}

interface IFilter {
  active?: number;
}

interface IHeaderProps {
  admin?: boolean;
  permission?: IPermissionUser;
}

const PanelDescriptionForecast: React.FC<IHeaderProps> = ({ permission }) => {
  const outSideRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts();

  const [datas, setDatas] = useState<IDescription[]>([{} as IDescription]);
  const [brandUpdate, setbrandUpdate] = useState<IDescription[]>([]);

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({ active: 1 });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<IResponseApi>("/forecast/description", {
          params: filters,
        });
        setDatas(response.data.contents);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [filters]);

  useEffect(() => {
    setDatas([...brandUpdate]);
  }, [brandUpdate]);

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setFilterView(false);
  }

  function handleAlterActive(brandProps: IDescription) {
    let arr = datas;

    arr.forEach(async (brand) => {
      if (brandProps.id === brand.id) {
        try {
          await api.put(`/forecast/description/${brand.id}`, {
            active: Number(!brand.is_active),
          });
          setbrandUpdate(arr);

          brand.is_active = !brand.is_active;
        } catch (err) {
          const error = err as any;
          if (!error.response) {
            return addToast(
              "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }

          if (error.response.data.error === "cannot be changed") {
            return addToast("Descrição não pode ser alterado", {
              appearance: "warning",
              autoDismiss: true,
            });
          }

          if (error.response.status === 401) {
            return addToast("Sem permissão para esta ação", {
              appearance: "warning",
              autoDismiss: true,
            });
          }

          addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      }
    });
  }

  function hadleToNewStatus() {
    history.push("/admin/criar/descricao/forecast");
  }

  return (
    <Container>
      <header>
        <h2>Lista de coleções forecast</h2>
      </header>
      <Filter>
        <button
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="button"
        >
          <FiSearch />
        </button>
        <input
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="text"
          name="filter"
          id="filter"
          readOnly={true}
        />
        <FilterSelects>
          <ul>
            {filters.active && (
              <li>
                <span>
                  Situação: {Number(filters.active) === 0 ? "Inativo" : "Ativo"}
                </span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, active: undefined })}
                />
              </li>
            )}
          </ul>
        </FilterSelects>

        {filterView && (
          <FilterCheck ref={outSideRef}>
            <header>
              <button
                onClick={() => {
                  setFilterView(false);
                }}
              >
                {" "}
                Fechar{" "}
              </button>
            </header>
            <main>
              <Formik
                initialValues={{
                  active: filters.active,
                }}
                validationSchema={Yup.object({})}
                onSubmit={(data) => {
                  hadleFilter({
                    active: data.active,
                  });
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="field">
                      <label htmlFor="active">SITUAÇÃO</label>
                      <select id="active" {...formik.getFieldProps("active")}>
                        <option value=""></option>
                        <option value={1}>Ativo</option>
                        <option value={0}>Inativo</option>
                      </select>
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                )}
              </Formik>
            </main>
          </FilterCheck>
        )}
      </Filter>

      <ContainerList>
        <div className="headerTable">
          <h3>Coleções forecast</h3>

          {permission && permission.config && permission.config > 2 && (
            <button onClick={hadleToNewStatus} type="button">
              <MdAdd size={22} /> <span> NOVO DESCRIÇÃO </span>
            </button>
          )}
        </div>

        <TableContainer>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>DESCRIÇÃO</th>
                <th>SITUAÇÃO</th>
              </tr>
            </thead>

            <tbody>
              {datas.map((item: IDescription, index) => (
                <tr key={index}>
                  <td
                    onClick={() =>
                      history.push(`/admin/descricao/forecast/${item.id}`, {
                        content: item,
                      })
                    }
                    style={{ opacity: item.is_active ? 1 : 0.4 }}
                  >
                    {item.id}
                  </td>
                  <td
                    onClick={() =>
                      history.push(`/admin/descricao/forecast/${item.id}`, {
                        content: item,
                      })
                    }
                    style={{ opacity: item.is_active ? 1 : 0.4 }}
                  >
                    {item.description}
                  </td>

                  <td>
                    <div className="stuationContainer">
                      <div
                        onClick={() => {
                          handleAlterActive(item);
                        }}
                      >
                        {item.is_active ? (
                          <AiFillCheckCircle color={"#009c27"} size={24} />
                        ) : (
                          <AiFillCloseCircle size={24} color={"#d10a0a"} />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
        <FooterContainer>
          {/* <span>Mostrando { clints.length } de { pagination.total } resultados</span>          

          <div>
            <button className={ pagination.page < 1 ? "buttonNull": ""} type="button" onClick={ pagination.page < 1 ?  ()=>{} : hadlePreviousPage } >ANTERIOR</button>
            <p style={{marginRight: 8}}>{pagination.page + 1 }</p>
            <button className={ pagination.page > pagination.pages ? "buttonNull": ""} type="button" onClick={ pagination.page > pagination.pages ?  ()=>{} : hadleNextPage } >PROXIMO</button>
          </div> */}
        </FooterContainer>
      </ContainerList>
    </Container>
  );
};

export default PanelDescriptionForecast;
