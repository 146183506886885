import React, { ReactNode, memo, useState } from "react";

import { BodyTab, BtnTab, Container } from "./styles";

interface HeaderTabProps {
  tabs: TabProps[];
}

interface TabProps {
  description: string;
  component: ReactNode;
}

const TabComponent: React.FC<HeaderTabProps> = ({ tabs }) => {
  const [indexActive, setIndexActive] = useState(0);

  return (
    <Container>
      {tabs.map((tab, index) => (
        <BtnTab
          type="button"
          key={tab.description}
          onClick={() => setIndexActive(index)}
          isActive={index === indexActive ? true : false}
        >
          {tab.description}
        </BtnTab>
      ))}

      <BodyTab>{tabs[indexActive].component}</BodyTab>
    </Container>
  );
};

export const Tab = memo(TabComponent);
