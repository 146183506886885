import {
  Form as FormPadrao,
  ContainerForm as ContainerFormPadrao,
  Error as ErrorPadrao,
} from "../Security/styles";

import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;

  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 90%;
  max-width: 400px;
  padding: 20px 0 0 0;
  background: #fafafa;

  border: 1px solid rgba(77, 77, 77, 0.2);

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;

    h2 {
      font-size: 18px;
      text-align: center;
      font-weight: 500;
      color: #222;
      padding: 0 10px;
    }

    p {
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      color: rgba(77, 77, 77);
      padding: 0 10px;
    }
  }

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    width: 100%;

    input {
      width: 100%;
      height: 40px;
      padding: 5px 10px;

      border: 1.3px solid rgba(77, 77, 77, 0.5);
      border-radius: 8px;

      color: #555;

      &:focus-within {
        border: 1.3px solid rgba(33, 33, 33);
        color: #111;
      }
    }

    div {
      display: flex;
      justify-content: center;

      svg {
        color: #f00;
        margin-right: 5px;
        margin-top: 4px;
      }

      span {
        color: #f00;
        font-size: 13px;
        margin-top: 5px;
        font-weight: 500;
      }
    }

    button {
      background-color: #d0222d;
      border-radius: 4px;
      width: 100%;
      height: 40px;
      margin-top: 25px;

      font-weight: 600;
      color: #fff;
    }
  }
  footer {
    height: 50px;
    width: 100%;

    margin-top: 10px;

    border-top: 1px solid rgba(77, 77, 77, 0.2);

    button {
      height: 100%;
      width: 100%;
      background: #fafafa;
      font-weight: 400;
    }
  }
`;

export const Form = styled(FormPadrao)`
  background: #fafafa;
  padding: 0 12px;
  width: 85%;
  .field {
    input {
      width: 100%;
      height: 40px;
    }
  }
`;
export const ContainerForm = styled(ContainerFormPadrao)``;
export const Error = styled(ErrorPadrao)``;

export const ContainerLocker = styled.div`
  height: 100px;
  width: 100px;
  padding: 10px 10px;

  border-radius: 50%;
  border: 2px solid #333;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 10px;
`;
