import { getUser } from "./localStorage/seller";

interface IToken {
  access_token: string;
  iat: number;
  exp: number;
}

export interface Seller {
  id: number;
  cod: number;
  fullName?: string;
  abbreviation?: string;
  email: string;

  is_manager: boolean;
  is_supervisor: boolean;
}

export const TOKEN_KEY = "@key-Seller";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const isManager = () => {
  let authenticated = false;

  const seller = getUser() as Seller;

  if (seller !== null && seller.is_manager) {
    authenticated = true;
  }

  return authenticated;
};

export const isSupervisor = () => {
  let authenticated = false;

  const seller = getUser() as Seller;

  if (seller !== null && seller.is_supervisor) {
    authenticated = true;
  }

  return authenticated;
};

export const isManagerOrIsSupervisor = () => {
  let authenticated = false;

  const seller = getUser() as Seller;

  if (seller !== null && (seller.is_supervisor || seller.is_manager)) {
    authenticated = true;
  }

  return authenticated;
};

export const getToken = () => {
  const seller = localStorage.getItem(TOKEN_KEY);

  return JSON.parse(seller as string);
};

export const setToken = (token: IToken) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};

export const logout = () => {
  localStorage.clear();
};
