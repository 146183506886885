export interface User {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
  permission?: string;
}

export const STORAGE = "@Data-user";

export const getUser = () => {
  const user = localStorage.getItem(STORAGE);

  return JSON.parse(user as string);
};

export const setUser = (user: User) => {
  localStorage.setItem(STORAGE, JSON.stringify(user));
};

export const removeUser = () => {
  localStorage.removeItem(STORAGE);
};
