import CSS from "csstype";
import React from "react";
import LoadingDot from "../../../loadings/LoadingDot";
import { Container, ContainerLoadingPanel, Text } from "./styles";

export interface FieldProps<T> {
  name: string;
  field: string;
  viewList?: boolean;
  notViewFilter?: boolean;

  render?: (row: T, key: any) => React.ReactNode;
  tdStyles?: (row: T) => React.CSSProperties | undefined;
  tdOnClick?: (v: T) => void;
}

interface TableContentProps<T> {
  isLoading?: boolean;

  columns: FieldProps<T>[];
  data?: any[];

  onClickRow?: (v: T) => void;
  rowStyle?: (row: T) => CSS.Properties | undefined;

  height?: number | string;
  textAlign?: "center" | "start" | "end";
  disabledHover?: boolean;
}

export function TableContent<T>({
  isLoading,
  data,
  columns,
  height,
  onClickRow,
  rowStyle,
  textAlign = "center",
  disabledHover = false,
}: TableContentProps<T>) {
  return (
    <Container style={{ height: height }} disabledHover={disabledHover}>
      {!isLoading ? (
        <>
          <table>
            <thead>
              <tr>
                {columns
                  .filter((f) => f.viewList)
                  .map((colum, index) => (
                    <th key={index} style={{ textAlign }}>
                      {colum.field}
                    </th>
                  ))}
              </tr>
            </thead>

            <tbody>
              {data?.map((item, indexData) => (
                <tr
                  key={indexData}
                  style={rowStyle ? rowStyle(item) : undefined}
                  onClick={onClickRow ? () => onClickRow(item) : () => {}}
                >
                  {columns.map((colum, indexColum) => (
                    <td
                      key={indexColum}
                      style={
                        colum?.tdStyles
                          ? { textAlign, ...colum?.tdStyles(item) }
                          : { textAlign }
                      }
                      onClick={
                        colum?.tdOnClick
                          ? () => {
                              //@ts-ignore
                              colum.tdOnClick(item);
                            }
                          : () => {}
                      }
                    >
                      {colum.render
                        ? colum.render(item, indexData)
                        : item[colum.name]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          {data && data?.length < 1 && (
            <Text>Não há registros a serem exibidos</Text>
          )}
        </>
      ) : (
        <ContainerLoadingPanel>
          <LoadingDot />
        </ContainerLoadingPanel>
      )}
    </Container>
  );
}
