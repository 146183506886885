import React, { memo } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { MdContentPaste } from "react-icons/md";
import { useToasts } from "react-toast-notifications";
import { Contain, Error as ContainerError, Field } from "./styles";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  label?: string;
  error?: string;
  requiredOn?: boolean;
  isCopyClipboard?: boolean;
  isHighlighter?: boolean;

  styleField?: React.CSSProperties;
  styleContain?: React.CSSProperties;
  styleInput?: React.CSSProperties;
}

const InputComponent: React.FC<InputProps> = ({
  label,
  name,
  error,
  requiredOn,
  styleField,
  styleContain,
  styleInput,
  isCopyClipboard = false,
  isHighlighter = false,
  ...rest
}) => {
  const restAny = rest as any;
  const { addToast } = useToasts();

  async function handleCopyClipboard() {
    try {
      await navigator.clipboard.writeText(restAny.value);
      addToast("Dados copiados para sua área de transferência", {
        appearance: "info",
        autoDismiss: true,
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
      addToast("Error ao copiar os dados para sua área de transferência", {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }

  return (
    <Field className="field" style={styleField}>
      {label && <label htmlFor={name}>{label}</label>}

      <Contain style={styleContain}>
        <input
          id={name}
          name={name}
          {...restAny}
          style={{
            border: requiredOn ? "2px solid #d21e26" : undefined,
            background: isHighlighter
              ? "rgba(255, 238, 0, 0.6)"
              : "transparent",
            ...styleInput,
          }}
        />
        {isCopyClipboard && (
          <button type="button" onClick={handleCopyClipboard}>
            <MdContentPaste size={"1.6rem"} />
          </button>
        )}
      </Contain>

      {!!error && (
        <ContainerError>
          <FiAlertCircle color="#f00" size={16} />
          <span> {error} </span>
        </ContainerError>
      )}
    </Field>
  );
};

export const Input = memo(InputComponent);
