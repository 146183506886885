import React from "react";

import { RiFileExcel2Line } from "react-icons/ri";
import { ContainerForm } from "../../pages/detail/styles";
import { generateXlsx } from "../../utils/generateXlsx";

interface HistoryAccessProps {
  title?: string;
  noTitle?: boolean;
  isNameOnList?: boolean;

  data: {
    id: string;
    cod: string;
    name: string;
    date: Date;
    dateToString: string;
  }[];
}

export const HistoryAccess = ({
  data,
  noTitle = false,
  isNameOnList,
  title = "Histórico de login",
}: HistoryAccessProps) => {
  async function handleExportAccess() {
    generateXlsx({
      filename: title,
      data:
        data.map((access) => ({
          ID: access.id,
          Código: access.cod,
          Nome: access.name,
          Data: access.dateToString,
        })) ?? [],
    });
  }

  return (
    <>
      <header style={{ marginTop: "1rem" }}>
        {!noTitle && <h2>{title}</h2>}

        <div className="containerActions">
          <button
            onClick={handleExportAccess}
            className="buttonActionDatasheet"
          >
            <div style={{ fontWeight: "lighter" }}>
              <span>
                <RiFileExcel2Line color="#207245" size={24} />
              </span>
              Download
            </div>
          </button>
        </div>
      </header>
      <ContainerForm style={{ maxHeight: "40vh", overflowY: "scroll" }}>
        {data.length === 0 && (
          <span style={{ padding: "12px 0", color: "#333" }}>
            Não possui acessos
          </span>
        )}
        <ul>
          {data.map((item, index) => (
            <li
              key={index}
              style={{
                padding: "4px 0",
                marginBottom: 6,
                borderBottom:
                  index + 1 === data.length ?? 0
                    ? undefined
                    : "0.4px solid #5f5f5f9e",
              }}
            >
              {isNameOnList
                ? `${item.name} - ${item.dateToString}`
                : item.dateToString}
            </li>
          ))}
        </ul>
      </ContainerForm>
    </>
  );
};
