import styled from "styled-components";

export const Container = styled.div`
  top: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 999999999999999;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  background: rgba(255, 255, 255);
  border-radius: 8px;
  height: 300px;
  width: 260px;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;

  padding: 20px;
`;

export const Spinner = styled.div`
  height: 170px;
  width: 170px;
  margin-bottom: 15px;

  position: relative;

  border-radius: 50%;
  border: 10px solid rgba(0, 0, 0, 0.2);
  border-left-color: #d21e26;

  animation: spin 0.7s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Progress = styled.p`
  position: absolute;
  top: 60px;
  right: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #000;
  font-size: 43px;
  font-weight: 700;

  span {
    color: #555;
    font-size: 20px;
    font-weight: 400;
  }
`;

export const Title = styled.p`
  color: #000;
  font-size: 20px;
  font-weight: 700;
`;

export const ContainerLoading = styled.div`
  position: relative;

  margin-top: 40px;
`;
