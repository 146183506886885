import React, { useEffect, useRef, useState } from "react";

import moment from "moment";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ModalConfirm from "../../../components/ModalLib";
import api from "../../../service/api";
import * as clientLocalStorage from "../../../service/localStorage/client";
import banks from "../../../utils/banks";
import { indemnities, TypesAccount } from "../../../utils/selectsDevolution";

import filesize from "filesize";
import Dropzone from "../../../components/Upload/Dropzone";
import generateHash from "../../../utils/generateHash";

import { useFormik } from "formik";
import * as Yup from "yup";

import Loading from "../../../components/loadings/Loading";

import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle, FiSearch } from "react-icons/fi";
import { IoMdTrash } from "react-icons/io";
import {
  ButtunSubmit,
  Container,
  ContainerCheck,
  ContainerDropZone,
  ContainerForm,
  ContainerList,
  ContainerPreviews,
  Error,
  Filter,
  FilterCheck,
  FilterSelects,
  FooterContainer,
  Form,
  MainFilter,
  ModalBody,
  TableContainer,
} from "../styles";

import useOutsideAlerter from "../../../hook/outsideAlerter";

import Modal from "../../../components/Modal";

import { AxiosError } from "axios";
import { BsFileEarmarkCheck } from "react-icons/bs";
import iconPdf from "../../../assets/icon_pdf.png";
import { Order } from "../../../hook/queries/useOrders";
import { ScreenLoading } from "../../analytic/styles";
import { PopUpConfirmation } from "../../detail/styles";

interface IHeaderProps {
  admin: boolean;
}

interface Client {
  id?: string;
  cod?: number;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
}

interface IFile {
  id: string;
  name: string;
  size: Number;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype?: string;
}

interface IDevolution {
  client?: {
    cod: number;
    company_name: string;
  };

  requesterName: string;
  phone: string;
  email: string;

  //Dados da solicitação
  numberInvoice: string;
  situationRequest: string;
  products: string;

  requestType: string;

  //indenização
  brand: string;
  stateProducts: string;
  dateEmissionNFconsumer?: string;
  invoiceORcoupon?: string;
  reason: string;

  //Dados bancários
  bank: string;
  agency: string;
  account: string;
  typeAccountBank: string;
}

interface IBrand {
  id?: number;
  cod: number;
  name: string;
  is_active: boolean;
  indemnityDays?: number;
  indemnityDaysUsedProduct?: number;
}

interface IFilter {
  cod?: string;
  company_name?: string;
  cnpj?: string;
}

interface IClietsApi {
  clients: IClient[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IClient {
  id?: string;
  cod?: number;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IRequestPagination {
  limitRequest?: number;
  pageRequest?: number;
}

const CreateDecolucao: React.FC<IHeaderProps> = ({ admin }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const outSideRefFilter = useRef(null);

  const clietStorage = clientLocalStorage.getUser() as Client;
  const [selectFile, setSelectFile] = useState<File>();
  const [files, setFiles] = useState<File[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);

  const [modalVisible, setModalVisible] = useState(false);

  const [pagination, setPagination] = useState<IPagination>({} as IPagination);
  const [requestPagination, setRequestPagination] =
    useState<IRequestPagination>({ limitRequest: 10 } as IRequestPagination);

  const [loading, setLoading] = useState(false);

  const [clints, setClients] = useState<IClient[]>([]);
  const [filters, setFilters] = useState<IFilter>({});
  const [filterView, setFilterView] = useState(false);

  const [dateInvoiceValidate, setDateInvoiceValidate] = useState(366);

  const [isConfirmNumberInvoice, setIsConfirmNumberInvoice] = useState(false);
  const [order, setOrder] = useState<Order>({} as Order);
  const [loadingScreen, setLoadingScreen] = useState(false);

  // const phoneRegExp = /^\([0-9]{2}(?:\))\s?[0-9]{5}(?:-)[0-9]{4}$/mg

  useEffect(() => {
    if (selectFile) setFiles([...files, selectFile]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFile]);

  useEffect(() => {
    (async () => {
      const brandDB = await api.get<IBrand[]>("/populate/brand", {
        params: { active: 1 },
      });
      setBrands(brandDB.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (admin) {
        try {
          const clients = await api.get<IClietsApi>("/clientPJ", {
            params: { ...filters, ...requestPagination },
          });
          const { limit, page, pages, total } = clients.data;
          setClients(clients.data.clients);
          setPagination({ limit, page, pages, total });
          //@ts-ignore
          main.current.scrollTo(0, 0);
        } catch (error) {
          // return addToast('Desculpe, ocorreu um erro interno, Tente novamente mais tarde',{
          //   appearance: 'error',
          //   autoDismiss: true,})
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, requestPagination]);

  async function hadleDeleteFile(name: string) {
    files.forEach((element: File, index) => {
      if (element.name === name) {
        files.splice(index, 1);
      }
    });

    setFiles([]);

    setTimeout(() => {
      setFiles(files);
    }, 1);
  }

  function handleSelectClient(
    cod: number | undefined,
    company_name: string | undefined
  ) {
    formikCreateIndemnity.setFieldValue("client.cod", cod);
    formikCreateIndemnity.setFieldValue("client.company_name", company_name);
    setModalVisible(false);
  }

  async function hadleSubmitClient(devolution: IDevolution) {
    setLoading(true);
    const formData = new FormData();

    if (selectFile) {
      files.forEach((element) => {
        formData.append("file", element);
      });
    }

    try {
      const filesResponse = await api.post<IFile[]>(
        "/indemnity/upload",
        formData
      );
      const response = await api.post("/indemnity", {
        devolution,
        files: filesResponse.data.map((item) => ({
          id: item.id,
          name: item.name,
          size: item.size,
          url: item.url,
          key: item.key,
          mimetype: item.mimetype,
        })),
        client: {
          cod: clietStorage.cod,
        },
      });

      if (!admin) {
        await api.put(`/indemnity/notification/on/${response.data.id}`);
      }

      setLoading(false);

      addToast("Devulução criada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/indenizacaos");
    } catch (error) {
      setLoading(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function hadleSubmitAdmin(devolution: IDevolution) {
    setLoading(true);
    const formData = new FormData();

    if (selectFile) {
      files.forEach((element) => {
        formData.append("file", element);
      });
    }

    try {
      const filesResponse = await api.post<IFile[]>(
        "/indemnity/upload",
        formData
      );
      await api.post("/indemnity", {
        devolution,
        files: filesResponse.data.map((item) => ({
          id: item.id,
          name: item.name,
          size: item.size,
          url: item.url,
          key: item.key,
          mimetype: item.mimetype,
        })),
        client: {
          cod: devolution.client?.cod,
        },
      });

      setLoading(false);

      addToast("Indenização criada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/indenizacaos");
    } catch (error) {
      setLoading(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleClearFilter() {
    setFilters({});
    formikFilter.setFieldValue("cod", "");
    formikFilter.setFieldValue("cnpj", "");
    formikFilter.setFieldValue("company_name", "");
  }

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setFilterView(false);
  }

  function hadleNextPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page + 1,
    });
  }

  function hadlePreviousPage() {
    setRequestPagination({
      ...requestPagination,
      pageRequest: pagination.page - 1,
    });
  }

  async function handleGetInvoiceData(invoice: string) {
    if (!invoice)
      return addToast("E necessário informar a seu número de nota fiscal", {
        appearance: "warning",
        autoDismiss: true,
      });

    if (isNaN(Number(invoice)))
      return addToast("Número de nota fiscal invalido", {
        appearance: "warning",
        autoDismiss: true,
      });

    try {
      setLoadingScreen(true);

      const orderResponse = await api.get<Order>(
        `/orderSankhya/invoice/show/${invoice}`
      );

      setOrder(orderResponse.data);
      setLoadingScreen(false);
      setIsConfirmNumberInvoice(true);
    } catch (err) {
      const error = err as AxiosError;

      setLoadingScreen(false);

      if (error.response?.data?.message) {
        return addToast(error.response?.data?.message, {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (!error.response || error.response.status >= 500) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
  }

  function handleSetOrderInDevolution() {
    const { clients_pj, brands, billingDate, documentNumber } = order;

    formikCreateIndemnity.resetForm();

    formikCreateIndemnity.setFieldValue("numberInvoice", documentNumber);
    formikCreateIndemnity.setFieldValue("client.cod", clients_pj?.cod);
    formikCreateIndemnity.setFieldValue(
      "client.company_name",
      clients_pj?.company_name
    );
    formikCreateIndemnity.setFieldValue(
      "requesterName",
      clients_pj?.company_name
    );
    formikCreateIndemnity.setFieldValue("phone", clients_pj?.phone);
    formikCreateIndemnity.setFieldValue("email", clients_pj?.email);
    formikCreateIndemnity.setFieldValue("brand", brands?.cod?.toString());
    formikCreateIndemnity.setFieldValue(
      "dateInvoice",
      moment(billingDate).format("YYYY-MM-DD")
    );

    setIsConfirmNumberInvoice(false);
  }

  useOutsideAlerter(outSideRefFilter, () => {
    if (filterView) setFilterView(false);
  });

  const formikCreateIndemnity = useFormik({
    initialValues: {
      //Cliente
      client: {
        cod: NaN,
        company_name: "",
      },

      //Dados do solicitante
      requesterName: "", //nome do solicitante
      phone: "", //telefone
      email: "",

      //Dados da solicitação
      numberInvoice: "", // numero nota fiscal
      situationRequest: "total", //Situação da solicitação
      products: "",

      requestType: "indenização",

      //indenização
      brand: "", //marca
      stateProducts: "novo", //Estado do Produto
      dateInvoice: "", //Data da NF

      // dateEmissionNFconsumer: "", //Data de emissão da NF ao consumidor
      // invoiceORcoupon: "", //Nota fiscal ou cupom consumidor

      reason: "", //Motivo

      //Dados bancários
      bank: "", //banco
      agency: "", //banco
      account: "", //banco
      typeAccountBank: "", //banco

      //Infprmações Complementares
      complementaryInformation: "",
    },

    validationSchema: Yup.object({
      client: Yup.object().shape({
        company_name: Yup.string(),
      }),

      requesterName: Yup.string().required("Nome solicitante é obrigatório"),
      phone: Yup.string()
        // .matches(phoneRegExp, "Formato do telefone invalido, formato correto (DD) 00000-0000")
        .required("Telefone solicitante é obrigatório"),
      email: Yup.string()
        .email("informe um e-mail válido")
        .required("E-mail solicitante é obrigatório"),

      numberInvoice: Yup.number()
        .typeError("Apenas numeros")
        .required("Nº nota fiscal de origem é obrigatório"),

      products: Yup.string().when("situationRequest", {
        is: "parcial",
        then: Yup.string().required("Marca solicitante é obrigatório"),
      }),

      //Devolução & Indenização
      reason: Yup.string().required("Motivo solicitante é obrigatório"),

      // indenização
      brand: Yup.string().when("requestType", {
        is: "indenização",
        then: Yup.string().required("Marcas é obrigatório"),
      }),

      dateInvoice: Yup.date()
        .when("brand", {
          is: true,
          then: Yup.string().required("Informe a marca"),
        })
        .min(
          dateInvoicevalidate(),
          `O prazo máximo para solicitação de Indenização é de ${
            dateInvoiceValidate - 1
          } dias.`
        )
        .max(new Date(), `Data invalida.`)
        .required("Data nota fiscal de origem é obrigatório"),

      // dateEmissionNFconsumer: Yup.date()
      //   .min(
      //     dateInvoicevalidate(),
      //     `O prazo máximo para solicitação de Indenização é de 365 dias.`
      //   )
      //   .max(new Date(), `Data invalida.`)
      //   .required("Data de emissão da NF é obrigatório"),
      // invoiceORcoupon: Yup.string().when("requestType", {
      //   is: "indenização",
      //   then: Yup.string().required(
      //     "Nota fiscal ou cupom consumidor é obrigatório"
      //   ),
      // }),

      bank: Yup.string().required("Banco é obrigatório"),
      agency: Yup.string().required("Agência é obrigatório"),
      account: Yup.string().required("Conta é obrigatório"),
      typeAccountBank: Yup.string().required("Tipo da conta é obrigatório"),

      complementaryInformation: Yup.string().required(
        "Informações complementares é obrigatório"
      ),
    }),

    onSubmit: (values) => {
      admin ? hadleSubmitAdmin(values) : hadleSubmitClient(values);
    },
  });

  const formikFilter = useFormik({
    initialValues: {
      cod: filters.cod,
      company_name: filters.company_name,
      cnpj: filters.cnpj,
    },

    validationSchema: Yup.object({}),

    onSubmit: (data) =>
      hadleFilter({
        cnpj: data.cnpj?.trim()?.toUpperCase(),
        cod: data.cod?.trim(),
        company_name: data.company_name?.trim()?.toUpperCase(),
      }),
  });

  function dateInvoicevalidate() {
    let now: Date = new Date();
    let qtdDays = dateInvoiceValidate;

    now.setDate(now.getDate() - qtdDays);
    return now;
  }

  useEffect(() => {
    if (formikCreateIndemnity?.values) {
      const brand = brands.find(
        (f) => Number(f.cod) === Number(formikCreateIndemnity?.values?.brand)
      );
      if (brand) {
        if (
          formikCreateIndemnity?.values?.stateProducts === "novo" &&
          brand?.indemnityDays
        ) {
          setDateInvoiceValidate(brand.indemnityDays + 1);
        }
        if (
          formikCreateIndemnity?.values?.stateProducts === "usado" &&
          brand?.indemnityDaysUsedProduct
        ) {
          setDateInvoiceValidate(brand.indemnityDaysUsedProduct + 1);
        }
      }
    }

    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    formikCreateIndemnity?.values?.brand,
    // eslint-disable-next-line
    formikCreateIndemnity?.values?.stateProducts,
  ]);

  return (
    <>
      {loadingScreen && (
        <ScreenLoading
          style={{
            height: `100vh`,
            width: `100vw`,
            position: `fixed`,
            zIndex: 1,
            background: `white`,
            opacity: 0.8,
          }}
        >
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}

      <Container>
        <header>
          <button
            type="button"
            onClick={() => {
              history.goBack();
            }}
            className="arrowBack"
          >
            <FaArrowLeft size={18} />
          </button>

          <h2>Nova solitação de indenização</h2>
        </header>
        <ContainerForm>
          <Form
            onSubmit={
              loading
                ? (e) => {
                    e.preventDefault();
                  }
                : formikCreateIndemnity.handleSubmit
            }
          >
            <h3>Dados da indenização</h3>
            <div className="field">
              <label htmlFor="numberInvoice">Nº nota fiscal de origem</label>
              <input
                id="numberInvoice"
                {...formikCreateIndemnity.getFieldProps("numberInvoice")}
                onBlur={(e) => handleGetInvoiceData(e.target.value)}
              />
              {formikCreateIndemnity.touched.numberInvoice &&
              formikCreateIndemnity.errors.numberInvoice ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikCreateIndemnity.errors.numberInvoice} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label htmlFor="name">Marca</label>
              <select {...formikCreateIndemnity.getFieldProps("brand")}>
                <option value="">Selecionar marca</option>
                {brands.map((item) => (
                  <option key={item.id} value={item.cod}>
                    {item.name}
                  </option>
                ))}
              </select>
              {formikCreateIndemnity.touched.brand &&
              formikCreateIndemnity.errors.brand ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikCreateIndemnity.errors.brand} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label htmlFor="dateInvoice">
                Data nota fiscal de origem{" "}
                <span style={{ fontWeight: "bold", marginLeft: "0.2rem" }}>
                  *NOTA DE ORIGEM A PARTIR DE 01/10/2022 PARA PRODUTOS USADOS.
                </span>
              </label>
              <input
                type="date"
                {...formikCreateIndemnity.getFieldProps("dateInvoice")}
              />
              {formikCreateIndemnity.touched.dateInvoice &&
              formikCreateIndemnity.errors.dateInvoice ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikCreateIndemnity.errors.dateInvoice} </span>
                </Error>
              ) : null}
            </div>

            <div className="field">
              <label htmlFor="name">Situação da solicitação</label>
              <ContainerCheck>
                <button
                  type="button"
                  className={
                    formikCreateIndemnity.values.situationRequest === "total"
                      ? "check"
                      : ""
                  }
                  onClick={() =>
                    formikCreateIndemnity.setFieldValue(
                      "situationRequest",
                      "total"
                    )
                  }
                >
                  Total
                </button>

                <button
                  type="button"
                  onClick={() =>
                    formikCreateIndemnity.setFieldValue(
                      "situationRequest",
                      "parcial"
                    )
                  }
                  className={
                    formikCreateIndemnity.values.situationRequest === "parcial"
                      ? "check"
                      : ""
                  }
                >
                  Parcial
                </button>
              </ContainerCheck>
            </div>
            {formikCreateIndemnity.values.situationRequest === "parcial" ? (
              <div className="field">
                <label htmlFor="products">Produtos</label>
                <input
                  id="products"
                  {...formikCreateIndemnity.getFieldProps("products")}
                />
                {formikCreateIndemnity.touched.products &&
                formikCreateIndemnity.errors.products ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formikCreateIndemnity.errors.products} </span>
                  </Error>
                ) : null}
              </div>
            ) : null}

            <div className="field-group">
              <div className="field">
                <label htmlFor="name">Motivo</label>
                <select {...formikCreateIndemnity.getFieldProps("reason")}>
                  <option value="">Selecionar motivo</option>
                  {indemnities.map((item) => (
                    <option key={item.title} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>
                {formikCreateIndemnity.touched.reason &&
                formikCreateIndemnity.errors.reason ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formikCreateIndemnity.errors.reason} </span>
                  </Error>
                ) : null}
              </div>
            </div>
            <div className="field">
              <label htmlFor="name">Estado do Produto</label>
              <ContainerCheck>
                <button
                  type="button"
                  className={
                    formikCreateIndemnity.values.stateProducts === "novo"
                      ? "check"
                      : ""
                  }
                  onClick={() =>
                    formikCreateIndemnity.setFieldValue("stateProducts", "novo")
                  }
                >
                  Novo
                </button>

                <button
                  type="button"
                  className={
                    formikCreateIndemnity.values.stateProducts === "usado"
                      ? "check"
                      : ""
                  }
                  onClick={() =>
                    formikCreateIndemnity.setFieldValue(
                      "stateProducts",
                      "usado"
                    )
                  }
                >
                  Usado
                </button>
              </ContainerCheck>
            </div>

            {admin && (
              <>
                <h3>Cliente</h3>

                <div className="field-group">
                  <div className="field">
                    <label htmlFor="cod">Código Cliente</label>
                    <input
                      style={{ borderStyle: "dashed" }}
                      id="cod"
                      type="number"
                      {...formikCreateIndemnity.getFieldProps("client.cod")}
                      readOnly={true}
                    />
                  </div>

                  <div className="field">
                    <label htmlFor="company_name">Razão Social</label>
                    <input
                      style={{ borderStyle: "dashed" }}
                      id="company_name"
                      type="text"
                      {...formikCreateIndemnity.getFieldProps(
                        "client.company_name"
                      )}
                      readOnly={true}
                    />
                  </div>
                </div>

                <div className="field">
                  <div className="selectClient">
                    <button
                      type="button"
                      onClick={() => setModalVisible(!modalVisible)}
                    >
                      Selecionar cliente
                    </button>
                  </div>
                  {formikCreateIndemnity.touched.client?.company_name &&
                  formikCreateIndemnity.errors.client?.company_name ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span>
                        {" "}
                        {formikCreateIndemnity.errors.client?.company_name}{" "}
                      </span>
                    </Error>
                  ) : null}
                </div>

                {modalVisible && (
                  <Modal
                    title="Selecione o cliente"
                    modalVisible={modalVisible}
                    setModalVisible={setModalVisible}
                  >
                    <ModalBody>
                      <Filter>
                        <button
                          onClick={() => {
                            setFilterView(!filterView);
                          }}
                          type="button"
                        >
                          <FiSearch />
                        </button>
                        <input
                          onClick={() => {
                            setFilterView(!filterView);
                          }}
                          type="text"
                          name="filter"
                          id="filter"
                          readOnly={true}
                        />
                        <FilterSelects>
                          <ul>
                            {filters.cod && (
                              <li>
                                <span>COD: {filters.cod} </span>
                              </li>
                            )}
                            {filters.company_name && (
                              <li>
                                <span>
                                  Razão social: {filters.company_name}
                                </span>
                              </li>
                            )}
                            {filters.cnpj && (
                              <li>
                                <span>CNPJ: {filters.cnpj}</span>
                              </li>
                            )}
                          </ul>
                        </FilterSelects>

                        {filterView && (
                          <FilterCheck ref={outSideRefFilter}>
                            {/* <HeaderFilter>
                                  <button type='button' onClick={()=> hadleCloseFilterViewer()} > Fechar </button>
                                </HeaderFilter> */}
                            <MainFilter>
                              <Form onSubmit={formikFilter.handleSubmit}>
                                <div className="field-group">
                                  <div className="field">
                                    <label htmlFor="cod">COD</label>
                                    <input
                                      id="cod"
                                      {...formikFilter.getFieldProps("cod")}
                                    />
                                  </div>
                                  <div className="field">
                                    <label htmlFor="company_name">
                                      Razão social
                                    </label>
                                    <input
                                      id="company_name"
                                      {...formikFilter.getFieldProps(
                                        "company_name"
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="field">
                                  <label htmlFor="cnpj">CNPJ</label>
                                  <input
                                    id="cnpj"
                                    {...formikFilter.getFieldProps("cnpj")}
                                  />
                                </div>

                                <footer>
                                  <button
                                    className="clearFilter"
                                    type="button"
                                    onClick={() => {
                                      handleClearFilter();
                                    }}
                                  >
                                    LIMPAR FILTRO
                                  </button>
                                  <button type="submit">BUSCAR</button>
                                </footer>
                              </Form>
                            </MainFilter>
                          </FilterCheck>
                        )}
                      </Filter>
                      <ContainerList>
                        <TableContainer>
                          <table>
                            <thead>
                              <tr>
                                <th>COD</th>
                                <th>RAZAO SOCIAL</th>
                                <th>CNPJ</th>
                              </tr>
                            </thead>

                            <tbody>
                              {clints.map((client: IClient) => (
                                <tr
                                  key={client.id}
                                  onClick={() =>
                                    handleSelectClient(
                                      client.cod,
                                      client.company_name
                                    )
                                  }
                                >
                                  <td> {client.cod} </td>
                                  <td> {client.company_name} </td>
                                  <td> {client.cnpj} </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </TableContainer>
                        <FooterContainer>
                          <span>
                            Mostrando {clints.length} de {pagination.total}{" "}
                            resultados
                          </span>

                          <div>
                            <button
                              className={
                                pagination.page <= 0 ? "buttonNull" : ""
                              }
                              type="button"
                              onClick={
                                pagination.page <= 0
                                  ? () => {}
                                  : hadlePreviousPage
                              }
                            >
                              ANTERIOR
                            </button>
                            <p style={{ marginRight: 8 }}>
                              {pagination.page + 1}
                            </p>
                            <button
                              className={
                                pagination.page >= pagination.pages
                                  ? "buttonNull"
                                  : ""
                              }
                              type="button"
                              onClick={
                                pagination.page >= pagination.pages
                                  ? () => {}
                                  : hadleNextPage
                              }
                            >
                              PROXIMO
                            </button>
                          </div>
                        </FooterContainer>
                      </ContainerList>
                    </ModalBody>
                  </Modal>
                )}
              </>
            )}
            <h3>Dados do solicitante</h3>
            <div className="field-group">
              <div className="field">
                <label htmlFor="requesterName">Nome solicitante</label>
                <input
                  id="requesterName"
                  {...formikCreateIndemnity.getFieldProps("requesterName")}
                />
                {formikCreateIndemnity.touched.requesterName &&
                formikCreateIndemnity.errors.requesterName ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formikCreateIndemnity.errors.requesterName} </span>
                  </Error>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="phone">Telefone</label>
                <input
                  id="phone"
                  type="text"
                  {...formikCreateIndemnity.getFieldProps("phone")}
                />
                {formikCreateIndemnity.touched.phone &&
                formikCreateIndemnity.errors.phone ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formikCreateIndemnity.errors.phone} </span>
                  </Error>
                ) : null}
              </div>
            </div>
            <div className="field">
              <label htmlFor="email">E-mail</label>
              <input
                id="firstName"
                {...formikCreateIndemnity.getFieldProps("email")}
              />
              {formikCreateIndemnity.touched.email &&
              formikCreateIndemnity.errors.email ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikCreateIndemnity.errors.email} </span>
                </Error>
              ) : null}
            </div>

            {/* <div className="field">
            <label htmlFor="dateEmissionNFconsumer">
              Data de emissão da NF ao consumidor
            </label>
            <input
              type="date"
              {...formikCreateIndemnity.getFieldProps("dateEmissionNFconsumer")}
            />
            {formikCreateIndemnity.touched.dateEmissionNFconsumer &&
            formikCreateIndemnity.errors.dateEmissionNFconsumer ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span>
                  {" "}
                  {formikCreateIndemnity.errors.dateEmissionNFconsumer}{" "}
                </span>
              </Error>
            ) : null}
          </div> */}
            {/* <div className="field">
            <label htmlFor="invoiceORcoupon">Nota fiscal</label>
            <input
              type="text"
              {...formikCreateIndemnity.getFieldProps("invoiceORcoupon")}
            />
            {formikCreateIndemnity.touched.invoiceORcoupon &&
            formikCreateIndemnity.errors.invoiceORcoupon ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formikCreateIndemnity.errors.invoiceORcoupon} </span>
              </Error>
            ) : null}
          </div> */}
            <h3>Dados bancários</h3>
            <div className="field">
              <label htmlFor="name">Banco</label>
              <select {...formikCreateIndemnity.getFieldProps("bank")}>
                <option value="0">Selecionar Banco</option>
                {banks.map((item) => (
                  <option key={item.value} value={item.label}>
                    {`${item.value} - ${item.label}`}
                  </option>
                ))}
              </select>
              {formikCreateIndemnity.touched.bank &&
              formikCreateIndemnity.errors.bank ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikCreateIndemnity.errors.bank} </span>
                </Error>
              ) : null}
            </div>
            <div className="field-group">
              <div className="field">
                <label htmlFor="agency">Agência</label>
                <input
                  type="text"
                  {...formikCreateIndemnity.getFieldProps("agency")}
                />
                {formikCreateIndemnity.touched.agency &&
                formikCreateIndemnity.errors.agency ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formikCreateIndemnity.errors.agency} </span>
                  </Error>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="account">Conta</label>
                <input
                  type="text"
                  {...formikCreateIndemnity.getFieldProps("account")}
                />
                {formikCreateIndemnity.touched.account &&
                formikCreateIndemnity.errors.account ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formikCreateIndemnity.errors.account} </span>
                  </Error>
                ) : null}
              </div>
            </div>
            <div className="field">
              <label htmlFor="name">Tipo de conta</label>
              <select
                {...formikCreateIndemnity.getFieldProps("typeAccountBank")}
              >
                <option value="">Selecionar tipo da conta</option>
                {TypesAccount.map((item) => (
                  <option key={item.title} value={item.title}>
                    {item.title}
                  </option>
                ))}
              </select>
              {formikCreateIndemnity.touched.typeAccountBank &&
              formikCreateIndemnity.errors.typeAccountBank ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span> {formikCreateIndemnity.errors.typeAccountBank} </span>
                </Error>
              ) : null}
            </div>

            <h3>Informações complementares</h3>
            <div className="field">
              <textarea
                id="complementaryInformation"
                {...formikCreateIndemnity.getFieldProps(
                  "complementaryInformation"
                )}
              ></textarea>
              {formikCreateIndemnity.touched.complementaryInformation &&
              formikCreateIndemnity.errors.complementaryInformation ? (
                <Error>
                  <FiAlertCircle color="#f00" size={16} />
                  <span>
                    {" "}
                    {formikCreateIndemnity.errors.complementaryInformation}{" "}
                  </span>
                </Error>
              ) : null}
            </div>

            <h3>Arquivos</h3>
            <ContainerDropZone>
              <Dropzone
                accept={["image/*", "application/pdf"]}
                onFileUploaded={setSelectFile}
              />
              <ContainerPreviews
                style={{
                  overflowY: "auto",
                  maxHeight: "40vh",
                }}
              >
                {files.map((file) => (
                  <li key={`${generateHash()}-${file.name}`}>
                    <img
                      src={
                        file.type === "application/pdf"
                          ? iconPdf
                          : URL.createObjectURL(file)
                      }
                      alt="icon_xml"
                    />
                    <div className="fileInfo">
                      <div>
                        <strong>{file.name}</strong>
                        <span>{filesize(file.size)}</span>
                      </div>

                      <button
                        type="button"
                        onClick={() => {
                          hadleDeleteFile(file.name);
                        }}
                      >
                        <IoMdTrash size={30} />
                      </button>
                    </div>
                  </li>
                ))}
              </ContainerPreviews>
            </ContainerDropZone>

            <div className="containerButton">
              <ButtunSubmit type="submit">
                {loading ? (
                  <Loading
                    size={22}
                    borderSize={4}
                    colorLoading="rgba(255,255,255)"
                    borderColor="rgba(255,255,255, 0.3)"
                  />
                ) : (
                  "Enviar solicitação"
                )}
              </ButtunSubmit>
            </div>
          </Form>
        </ContainerForm>
      </Container>

      <ModalConfirm
        modalVisible={isConfirmNumberInvoice}
        setModalVisible={setIsConfirmNumberInvoice}
        headerOff
        maxHeight={500}
        maxWidth={600}
        scrollOff
      >
        <PopUpConfirmation>
          <div className="headerPopUp">
            <nav>
              <BsFileEarmarkCheck size={100} opacity={0.2} />
            </nav>
            <span>Confirmação de nota fiscal</span>
          </div>

          <div
            style={{ color: "#555", fontWeight: "lighter", fontSize: "1rem" }}
          >
            <p>#{order.cod}</p>
            <p>
              Valor:{" "}
              {Number(order.noteValue).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
            <p>Marca: {order.brands?.name}</p>
            <p>
              Representante:{" "}
              {order.seller
                ? `${order.seller.cod} ${order.seller.abbreviation}`
                : "-"}
            </p>
            <p>
              Data entrada: {moment(order.deliveryDate).format("DD/MM/YYYY")}
            </p>
            <p>
              {`Data faturamento: ${moment(order.billingDate).format(
                "DD/MM/YYYY"
              )}`}
            </p>
          </div>

          <div className="buttonsPopUp" style={{ padding: "15px 10px" }}>
            <button
              type="button"
              onClick={() => {
                setIsConfirmNumberInvoice(false);
                setOrder({} as Order);
              }}
            >
              Cancelar
            </button>
            <button
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleSetOrderInDevolution}
            >
              Confirmo
            </button>
          </div>
        </PopUpConfirmation>
      </ModalConfirm>
    </>
  );
};

export default CreateDecolucao;
