import React from "react";

import { FaArrowLeft } from "react-icons/fa";

import { useHistory } from "react-router-dom";

import { FcQuestions } from "react-icons/fc";
import { ButtonSubmit } from "../../../components/Form/ButtonSubmit";
import { Container, ContainerForm } from "../styles";

const CreateIndemnityOrDevolution: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2>Criar devolução ou indenização</h2>
      </header>
      <ContainerForm>
        <div
          style={{
            width: `100%`,
            display: `flex`,
            justifyContent: `center`,
            marginBottom: `2rem`,
          }}
        >
          <FcQuestions fontSize={`10rem`} />
        </div>

        <p style={{ fontSize: `2rem`, fontWeight: "lighter" }}>
          Seu produto possui defeito de fabricação? {" "}
        </p>

        <div style={{ display: `flex`, columnGap: `1rem`, marginTop: `1rem` }}>
          <ButtonSubmit
            type="button"
            onClick={() => {
              history.push("/criar/devolucao");
            }}
          >
            NÃO
          </ButtonSubmit>
          <ButtonSubmit
            type="button"
            style={{ background: `#2cab48` }}
            onClick={() => {
              history.push("/criar/indenizacao");
            }}
          >
            SIM
          </ButtonSubmit>
        </div>
      </ContainerForm>
    </Container>
  );
};

export default CreateIndemnityOrDevolution;
