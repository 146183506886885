export interface SellerProps {
  id: number;
  cod: number;
  fullName?: string;
  abbreviation?: string;
  email: string;
  brands: number[];
  isSankhya: boolean;
  sellerTeamOn: boolean;
  is_manager: boolean;
  is_supervisor: boolean;
}

export const STORAGE = "@Data-seller";

export const getUser = () => {
  const user = localStorage.getItem(STORAGE);

  return JSON.parse(user as string);
};

export const setUser = (user: SellerProps) => {
  localStorage.setItem(STORAGE, JSON.stringify(user));
};

export const removeUser = () => {
  localStorage.removeItem(STORAGE);
};
