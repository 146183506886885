import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;

  display: grid;
  grid-template-columns: 15px 1fr 15px;
  grid-template-rows: 40px 1fr 50px;
  grid-template-areas:
    ". . ."
    ". tabela .";

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaa;
    width: 8px;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  header {
    margin-bottom: 20px;
  }

  @media (max-width: 629px) {
    overflow-y: unset;
  }

  @media (max-width: 900px) {
    grid-template-columns: 15px 1fr 15px;
    grid-template-rows: 20px 1fr 20px;
  }
`;

export const ContainerModal = styled.div`
  grid-area: tabela;
  display: flex;
  justify-content: center;
  align-items: center;

  height: auto;
  width: 100%;
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;

  width: 100%;
  max-width: 770px;

  padding: 10px 10px;
  margin-bottom: 15px;

  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

  header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .logoAlpar {
      width: 150px;
      /* height: 40px; */
    }

    h3 {
      color: #333;
      font-weight: 700;
      font-size: 20px;
      padding: 0 10px;
      text-align: center;
    }

    span {
      color: #333;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      padding: 0 10px;
    }
  }

  main {
    margin-top: 40px;
    margin-bottom: 15px;
    width: 100%;
    height: 100%;
    padding: 0 15px;
  }
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;

  h3 {
    font-size: 18;
    color: #333;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0 0px;
  }
  .field-group {
    width: 100%;
    display: flex;
    flex-flow: row;
  }

  .containerButtonSubmit {
    margin-top: 40px;
  }

  @media (max-width: 900px) {
    .field-group {
      flex-flow: column;
    }
  }

  .field {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-bottom: 24px;

    span {
      font-size: 0.8rem;
      margin-bottom: 4px;
    }

    .alert-span {
      color: #b3842d;
    }

    label {
      font-size: 14px;
      margin-bottom: 4px;
      color: #555;
    }
    input {
      padding: 0 10px;
      background: #fff;
      height: 35px;
      font-size: 16px;
      color: #000;
      border: 1.5px solid #aaa;
      border-radius: 5px;
    }

    .selectClient {
      height: 40px;
      width: 100%;

      button {
        width: 100%;
        height: 100%;
        background: #d21e26;

        font-weight: bold;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
      }
    }

    input:focus {
      border: 1.5px solid #333;
    }

    input::placeholder {
      color: #a0a0b2;
    }

    textarea {
      padding: 10px 10px;
      background: #fff;
      height: 80px;
      font-size: 16px;
      color: #000;
      border: 1.5px solid #aaa;
      border-radius: 5px;
      resize: none;

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-thumb {
        background: #333;
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background: #888;
      }

      &:focus {
        border: 1.5px solid #333;
      }
    }

    select {
      height: 35px;

      background: #fff;
      border-radius: 5px;
      border: 1.5px solid #aaa;
      padding: 4px 8px;

      font-size: 16px;
      color: #aaa;

      cursor: pointer;
      /* -webkit-appearance: none; */
      -moz-appearance: none;
      /* appearance: none;  */

      &:focus {
        border: 1.5px solid #333;
        color: #333;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  .containerButton {
    padding: 0 20px;
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 2px;
  span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #f00;
  }
`;
