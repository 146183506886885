import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  z-index: 2000;
  position: fixed;
  top: 0px;
  right: 0px;

  background: rgba(0, 0, 0, 0.8);

  animation: fadeOpacity 0.2s;

  @keyframes fadeOpacity {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const ModalContainer = styled.div`
  max-height: 90%;
  max-width: 920px;
  width: 80%;

  @keyframes fadeScale {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }
`;

export const BodyModal = styled.div`
  width: 100%;
  height: 80%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

  img {
    max-height: 95%;
    max-width: 100%;
  }

  video {
    max-height: 95%;
    max-width: 100%;
    position: relative;
  }

  #pdfContainer {
    height: 80vh;
    width: 100%;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
`;

export const HeaderModal = styled.div`
  background: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  width: 100%;

  font-size: 18px;
  margin-bottom: 20px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
      color: #fff;
      font-weight: bold;
      font-size: 16px;
    }
  }

  .closeModal {
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s;
    /* background: rgba(255, 255, 255, 0.2); */
    padding: 2px 2px;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      transform: scale(1.1);
    }
  }
`;

export const ContainerPage = styled.div`
  .previous {
    z-index: 50;

    position: absolute;
    top: 50%;
    left: 10px;
    background: transparent;

    color: #fff;
    font-size: 65px;

    @media (max-width: 900px) {
      left: 2px;
      font-size: 35px;
    }
  }

  .next {
    z-index: 50;

    position: absolute;
    top: 50%;
    right: 10px;
    background: transparent;

    color: #fff;
    font-size: 65px;

    @media (max-width: 900px) {
      right: 2px;
      font-size: 35px;
    }
  }
`;
