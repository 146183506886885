import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const Header = styled.div``;

export const Content = styled.ul`
  top: 30px;
  width: 180px;

  border-radius: 5px;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.59);

  position: absolute;
  overflow: hidden;
  list-style: none;
  z-index: 5;

  background-color: #fff;

  li {
    cursor: pointer;

    display: flex;
    align-items: center;
    transition: all 0.3s;

    padding: 8px 15px;
    border-bottom: 1px solid #bbb;
    width: 100%;

    span {
      font-size: 14px;
    }
  }

  li:hover {
    background-color: #ccc;
  }

  &:last-child {
    border-bottom: none;
  }
`;
