import styled from "styled-components";

export const ContainerCheckbox = styled.div`
  position: relative;
  height: 150px;
  width: 100%;

  .titleCheckbox {
    position: absolute;
    background-color: #fff;
    top: -11px;
    left: 6px;
    padding: 3px;

    margin-bottom: 4px;
  }

  .contentsCheckbox {
    border: 2px solid #ccc;
    padding: 10px 8px 8px 8px;
    overflow: hidden;
    border-radius: 5px;

    overflow-y: scroll;
    height: 100%;

    .boxCheckbox {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-bottom: 4px;

      label {
        font-size: 14px;
        margin-left: 4px;
        margin-bottom: 0;
        color: #555;
      }

      input[type="checkbox"] {
        height: 16px;
        width: 16px;
      }

      @media (max-width: 550px) {
        input[type="checkbox"] {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
`;
