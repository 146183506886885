import styled from "styled-components";

export const Container = styled.div`
  .no-color {
    position: relative;
  }

  .no-color::after {
    content: "";
    position: absolute;
    height: 15px;
    width: 2px;
    background: #f00;
    transform: rotate(45deg);

    top: -0.8px;
    right: 6px;
  }

  .row-none {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #fff;
    padding: 7px 0;
    color: #333;
    font-size: 0.875rem !important;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  .container-action {
    display: flex;
    column-gap: 6px;
  }

  .container-subtitle {
    display: flex;
    align-items: center;

    button {
      height: 46px;
      width: 46px;
      border-radius: 50%;

      margin-left: 0.5rem;

      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      svg {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 10px;

    thead {
      tr {
        th {
          font-size: 1rem !important;
          padding: 6px 8px;
          text-align: center;
          padding: 6px 16px 6px 10px;

          border-bottom: 1px solid rgba(224, 224, 224, 1);
        }
      }
    }
    tbody {
      display: table-row-group;
      tr {
        display: table-row;
        outline: 0;
        vertical-align: middle;
        font-size: 1rem !important;

        border-bottom: 1px solid rgba(224, 224, 224, 1);

        td {
          padding: 6px 16px 6px 12px;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
`;
