import React from "react";
import Loading from "../loadings/Loading";

import { Container, ScreenLoading } from "./styles";

interface CreateAndDetailLayoutProps {
  isLoading?: boolean;
}

export const CreateAndDetailLayout: React.FC<CreateAndDetailLayoutProps> = ({
  children,

  isLoading,
}) => {
  return (
    <Container>
      {isLoading ? (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      ) : (
        children
      )}
    </Container>
  );
};
