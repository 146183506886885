import styled from "styled-components";

export const ContainerForm = styled.div`
  width: 100%;
  padding: 20px 20px;
  background: #fff;
  border-radius: 8px;

  max-width: 1100px;
  /* margin-top: 1.4rem; */
  margin-bottom: 1.4rem;
`;

export const Form = styled.form`
  border-radius: 4px 0px 0px 4px;
  width: 100%;
  height: 100%;
  background-color: #fff;

  h3 {
    font-size: 18;
    color: #333;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0 0px;
  }

  .field-group {
    width: 100%;
    column-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
  }

  .containerCnpj {
    display: flex;
    width: 100%;
    button {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 15%;
      height: 35px;

      color: #fff;
      font-size: 16px;
      border-radius: 4px;
      background-color: #d21e26;
      transition: all 0.3s;

      &:hover {
        opacity: 0.8;
      }
    }
    input {
      width: 85%;
      margin-right: 8px;
    }
  }

  .container-subtitle {
    display: flex;
    align-items: center;

    button {
      height: 46px;
      width: 46px;
      border-radius: 50%;

      margin-left: 0.5rem;

      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }

      svg {
        color: rgba(0, 0, 0, 0.54);
        font-size: 24px;
      }
    }
  }

  .field {
    display: flex;
    flex-direction: column;

    flex: 1;
    margin-bottom: 24px;
    max-width: 100%;
    min-width: 150px;

    label {
      font-size: 14px;
      margin-bottom: 4px;
      color: #555;

      .required {
        color: #f00;
        font-size: 11px;
      }
    }
    input {
      padding: 0 10px;
      background: #fff;
      height: 35px;
      font-size: 16px;
      color: #222;
      border: 2px solid #aaa;
      border-radius: 5px;
    }

    .selectClient {
      height: 40px;
      width: 100%;

      button {
        width: 100%;
        height: 100%;
        background: #d21e26;

        font-weight: bold;
        font-size: 16px;
        color: #fff;
        border-radius: 5px;
      }
    }

    input:focus {
      border: 2px solid #333;
    }

    input::placeholder {
      color: #a0a0b2;
    }

    textarea {
      padding: 10px 10px;
      background: #fff;
      height: 80px;
      font-size: 16px;
      color: #222;
      border: 2px solid #aaa;
      border-radius: 5px;
      resize: vertical;

      ::-webkit-scrollbar {
        width: 8px;
      }

      ::-webkit-scrollbar-thumb {
        background: #333;
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background: #888;
      }

      &:focus {
        border: 2px solid #333;
      }
    }

    select {
      height: 35px;

      background: #fff;
      border-radius: 5px;
      border: 2px solid #aaa;
      padding: 4px 8px;

      font-size: 16px;
      color: #222 !important;
      cursor: pointer;
      /* -webkit-appearance: none; */
      -moz-appearance: none;
      /* appearance: none;  */

      &:focus {
        border: 2px solid #333;
        color: #333;
      }
    }
  }

  .containerLabelButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2px;

    button {
      border-radius: 4px;
      background: transparent;

      display: flex;
      align-items: center;
      justify-content: center;

      color: #d21e26;
      padding: 2px 4px;
      font-weight: 600;
    }
  }

  /* @media (max-width: 900px) {
    .field-group {
      flex-flow: column;
    }
  } */

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }

  .containerButton {
  }
`;
