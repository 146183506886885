import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdClose, IoMdCloseCircle } from "react-icons/io";
import { MdAddBox } from "react-icons/md";
import * as Yup from "yup";
// import { IProvider } from "..";

import { ContainerTabletSelect, RowNone, TabletSelect } from "./styles";

import LoadingDot from "../../../../components/loadings/LoadingDot";
import Modal from "../../../../components/ModalLib";
import { Pagination } from "../../../../components/panel/table/Pagination";
import api from "../../../../service/api";
import { LoadingPanel } from "../../../panel/styles";
import {
  ContainerList,
  Filter,
  FilterCheck,
  FilterSelects,
  Form,
  MainFilter,
  ModalBody,
  TableContainer,
} from "../../styles";

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

export type IDefect = {
  id: string;
  cod: string;
  description?: string;
  type?: string;
};

interface IResponseAPI {
  contents: IDefect[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface ProviderComponentProps {
  setDefectsLinks?: React.Dispatch<React.SetStateAction<IDefect[]>>;
  defectsLinks: IDefect[];

  disabled?: boolean;
}

interface IFilter {
  cod?: string;
  description?: string;
}

export const SelectDefect: React.FC<ProviderComponentProps> = ({
  setDefectsLinks: setProvidersLinks,
  defectsLinks: providersLinks,

  disabled = false,
}) => {
  const outSideRefFilter = useRef(null);

  const [isDisabledModal, setIsDisabledModal] = useState<boolean>(false);

  const [defects, setDefects] = useState<IDefect[]>([]);
  const [filters, setFilters] = useState<IFilter>({});
  const [filterView, setFilterView] = useState(false);

  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const [modalVisibleProvider, setModalVisibleProvider] = useState(false);
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);

  const defectsFilters = defects.filter(
    (d) => !providersLinks.map((f) => f.cod).includes(d.cod)
  );

  const formikFilter = useFormik({
    initialValues: {
      cod: filters.cod,
      description: filters.description,
    },

    validationSchema: Yup.object({}),

    onSubmit: (data) => {
      hadleFilter(data);
    },
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const providersDB = await api.get<IResponseAPI>(
          "/headerDatasheet/datasheet/qualities/defect/list",
          {
            params: {
              ...filters,
              pageRequest: page - 1,

              limitRequest: 10,
            },
          }
        );

        const { limit, page: pageGet, pages, total } = providersDB.data;

        setDefects(providersDB.data.contents);
        setPagination({ limit, page: pageGet, pages, total });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [filters, page]);

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setFilterView(false);
  }

  function handleSelectProvider(provider: IDefect) {
    const existProvider = providersLinks.find((f) => f.id === provider.id);

    if (!existProvider && setProvidersLinks) {
      setProvidersLinks([...providersLinks, provider]);
    }

    if (!isDisabledModal) setModalVisibleProvider(false);
  }
  function handleRemoveProvider(provider: IDefect) {
    const providersNoRemove = providersLinks.filter(
      (f) => f.id !== provider.id
    );

    if (setProvidersLinks) {
      setProvidersLinks([...providersNoRemove]);
    }
  }

  return (
    <>
      <div className="container-subtitle">
        <h3>Defeitos</h3>

        {!disabled && (
          <button
            type="button"
            className="action-button"
            onClick={() => {
              setModalVisibleProvider(true);
            }}
          >
            <MdAddBox />
          </button>
        )}
      </div>
      <ContainerTabletSelect style={{ maxHeight: "40vh" }}>
        <TabletSelect>
          <thead>
            <tr>
              <th>Cód.</th>
              <th>Descrição</th>
              <th>Tipo</th>
              {!disabled && <th>Ação</th>}
            </tr>
          </thead>

          <tbody>
            {providersLinks.map((provider, indexData) => (
              <tr key={indexData}>
                <td>{provider.cod}</td>
                <td>{provider.description}</td>
                <td>{provider.type}</td>
                {!disabled && (
                  <td>
                    <button
                      type="button"
                      className="action-button"
                      onClick={() => {
                        handleRemoveProvider(provider);
                      }}
                    >
                      <IoMdClose />
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </TabletSelect>
        {providersLinks.length < 1 && (
          <RowNone>Não há registros a serem exibidos</RowNone>
        )}
      </ContainerTabletSelect>

      <Modal
        title="Vincular Defeitos"
        modalVisible={modalVisibleProvider}
        setModalVisible={setModalVisibleProvider}
        maxWidth={800}
        maxHeight={800}
        isDisabled={isDisabledModal}
        setIsDisabled={setIsDisabledModal}
      >
        <ModalBody>
          <Filter>
            <button
              onClick={() => {
                setFilterView(!filterView);
              }}
              type="button"
            >
              <FiSearch />
            </button>
            <input
              onClick={() => {
                setFilterView(!filterView);
              }}
              type="text"
              name="filter"
              id="filter"
              readOnly={true}
            />
            <FilterSelects>
              <ul>
                {filters.cod && (
                  <li>
                    <span>CÓDIGO: {filters.cod}</span>{" "}
                    <IoMdCloseCircle
                      onClick={() => setFilters({ ...filters, cod: "" })}
                    />
                  </li>
                )}

                {filters.description && (
                  <li>
                    <span>DESCRIÇÃO: {filters.description}</span>{" "}
                    <IoMdCloseCircle
                      onClick={() =>
                        setFilters({ ...filters, description: "" })
                      }
                    />
                  </li>
                )}
              </ul>
            </FilterSelects>

            {filterView && (
              <FilterCheck ref={outSideRefFilter}>
                <MainFilter>
                  <Form onSubmit={formikFilter.handleSubmit}>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="cod">Código</label>
                        <input
                          id="cod"
                          {...formikFilter.getFieldProps("cod")}
                        />
                      </div>
                      <div className="field">
                        <label htmlFor="description">Descrição</label>
                        <input
                          id="description"
                          {...formikFilter.getFieldProps("description")}
                        />
                      </div>
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                          formikFilter.resetForm();
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                </MainFilter>
              </FilterCheck>
            )}
          </Filter>
          <ContainerList>
            <TableContainer>
              {loading && (
                <LoadingPanel>
                  <LoadingDot />
                </LoadingPanel>
              )}
              {!loading && (
                <table>
                  <thead>
                    <tr>
                      <th>CÓDIGO</th>
                      <th>DESCRIÇÃO</th>
                      <th>TIPO</th>
                    </tr>
                  </thead>

                  <tbody>
                    {defectsFilters.map((provider: IDefect) => (
                      <tr
                        key={provider.id}
                        onClick={() => handleSelectProvider(provider)}
                      >
                        <td> {provider.cod} </td>
                        <td> {provider.description} </td>
                        <td> {provider.type} </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </TableContainer>

            <Pagination
              totalCountOfRegisters={pagination.total}
              registersPerPage={pagination.limit}
              currentPage={page}
              onPageChange={(data) => {
                setPage(data);
              }}
            />
          </ContainerList>
        </ModalBody>
      </Modal>
    </>
  );
};
