export function downloadFilaBase64({
  base64,
  filename,
  mimetype,
}: {
  base64: string;
  filename: string;
  mimetype: string;
}) {
  const linkSource = `data:${mimetype};base64,${base64}`;
  const downloadLink = document.createElement("a");

  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
}
