import filesize from "filesize";
import MaterialTable, { Column } from "material-table";
import React, { useEffect, useRef, useState } from "react";
import {
  BiImport,
  BsFileEarmarkCheck,
  HiBadgeCheck,
  IoMdTrash,
} from "react-icons/all";
import { FaArrowLeft } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import { MdArrowDownward } from "react-icons/md";
import { RiAlertFill, RiFileExcel2Line } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as XLSX from "xlsx";
import { IPermissionUser } from "../../../@types";
import logoExecel from "../../../assets/logo-execel.svg";
import FloatButton from "../../../components/FloatButton";
import Modal from "../../../components/Modal";
import Dropzone from "../../../components/Upload/DropzoneXlsx";
import Loading from "../../../components/loadings/Loading";
import LoadingDot from "../../../components/loadings/LoadingDot";
import useOutsideAlerter from "../../../hook/outsideAlerter";
import api from "../../../service/api";
import * as userLocalStorage from "../../../service/localStorage/user";
import generateHash from "../../../utils/generateHash";
import { ContainerDropZone } from "../../detail/styles";
import { ContainerPreviews } from "../../import/styles";
import { ReportDownload } from "../../panel/styles";
import {
  ButtunSubmit,
  Container,
  ContainerButtons,
  ContainerImportModal,
  ContainerList,
  Form,
  InfoDevolution as Info,
  InfoStatus,
  PopUpConfirmation,
  ScreenLoading,
} from "../styles";

interface HomeProps {
  location: {
    state: {
      content: ICollection;
    };
  };

  match: {
    params: {
      id: number;
    };
  };

  admin?: boolean;
  seller?: boolean;
  permission?: IPermissionUser;
}

interface ICollection {
  id: number;
  title: string;
  year: string;
  markUpClient: number;
  markUpStore: number;
  brand: { cod: number; name: string };
  description: { description: string };
  status: IStatus;
  created_at: Date;
}
interface IProduct {
  id: string;
  situacao_markup?: 0 | 1;
  referencia: string;
  cod_alternativo: string;
  descricao_longa: string;
  tipo: string;
  conceito: string;
  ncm: string;
  unidade: string;
  tamanho: string;
  ean_upc: string;
  preco_de_custo: string;
  preco_de_venda: string;
  preco_de_venda_comercial: string;
  preco_de_venda_final: string;
  origem_da_mercadoria: string;
  linha: string;
  cor_predominante: string;
  segunda_cor_predominante: string;
  genero: string;
  idade: string;
  categoria: string;
  caracteristicas: string;
  material: string;
  classe_de_produto: string;
  pesobruto: string;
  pesoliq: string;
  m3: string;
  altura: string;
  largura: string;
  espessura: string;
  mes_entrega: string;
  quantidade: string;
  provider: string;
  providerCod: string;
}

interface IProductEditable {
  referencia: boolean;
  cod_alternativo: boolean;
  descricao_longa: boolean;
  tipo: boolean;
  conceito: boolean;
  ean_upc: boolean;
  ncm: boolean;
  unidade: boolean;
  tamanho: boolean;
  preco_de_custo: boolean;
  preco_de_venda: boolean;
  preco_de_venda_comercial: boolean;
  preco_de_venda_final: boolean;
  origem_da_mercadoria: boolean;
  linha: boolean;
  cor_predominante: boolean;
  segunda_cor_predominante: boolean;
  genero: boolean;
  idade: boolean;
  categoria: boolean;
  caracteristicas: boolean;
  material: boolean;
  classe_de_produto: boolean;
  pesobruto: boolean;
  pesoliq: boolean;
  m3: boolean;
  altura: boolean;
  largura: boolean;
  espessura: boolean;
  mes_entrega: boolean;
  quantidade: boolean;
  fornecedor: boolean;
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  responsable_id: number;
}

export interface IProvider {
  id: number;
  cod: number;
  fullname?: string;
  brand?: string;
  email?: string;
}

interface IResponseAPIProducts {
  contents: IProduct[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IResponseAPI {
  contents: IProvider[];
  total: number;
  limit: number;
  page: number;
  pages: number;
}

interface IReciveProduct {
  referencia: string;
  codAlternativo: string;
  descricaoLonga: string;
  preCusto: Number;
  pdvAtual: Number;
  "pdv(Conforme Pré custo)": any;
  pdvFinal: Number;
  tipo: string;
  precoDeVenda: Number;
  markupProduto: Number | string;
  conceito: string;
  unidade: string;
  tamanho: string;
  origemDaMercadoria: string;
  linha: string;
  corPredominante: string;
  segundaCorPredominante: string;
  genero: string;
  idade: string;
  categoria: string;
  caracteristicas: string;
  composicao: string;
  ncm: string;
  classeDeProduto: string;
  pesoBruto: string;
  pesoLiq: string;
  m3: string;
  altura: string;
  largura: string;
  espessura: string;
  quantidade: string;
  mesEntrega: string;
}

const Collection: React.FC<HomeProps> = ({ match, admin }) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const outSideRefReport = useRef(null);

  const userStorage = userLocalStorage.getUser() as userLocalStorage.User;

  const [confirmationChange, setConfirmationChange] = useState<boolean>(false);
  const [modalUpload, setModalUpload] = useState<boolean>(false);
  const [files, setFiles] = useState<File | undefined>();

  const [providers, setProviders] = useState<IProvider[]>([]);

  const [contentPage, setContentPage] = useState<ICollection>(
    {} as ICollection
  );
  const [products, setProducts] = useState<IProduct[]>([]);
  const [reaload, setReaload] = useState(1);
  const [updateDateNcm, setUpdateDateNcm] = useState(false);
  const [updateDate, setUpdateDate] = useState(false);
  const [disabledInput, setDisabledInput] = useState(true);
  const [enableButton, setEnableButton] = useState(false);
  const [editablePdv, setEditablePdv] = useState(false);
  const [editableTable, setEditableTable] = useState<IProductEditable>({
    referencia: false,
    cod_alternativo: false,
    ean_upc: false,
    descricao_longa: false,
    tipo: false,
    conceito: false,
    ncm: false,
    unidade: false,
    tamanho: false,
    preco_de_custo: false,
    preco_de_venda: false,
    preco_de_venda_comercial: false,
    preco_de_venda_final: false,
    origem_da_mercadoria: false,
    linha: false,
    cor_predominante: false,
    segunda_cor_predominante: false,
    genero: false,
    idade: false,
    categoria: false,
    caracteristicas: false,
    material: false,
    classe_de_produto: false,
    pesobruto: false,
    pesoliq: false,
    m3: false,
    altura: false,
    largura: false,
    espessura: false,
    mes_entrega: false,
    quantidade: false,
    fornecedor: false,
  });

  const [markUpClient, setMarkUpClient] = useState<number>();
  const [markUpStore, setMarkUpStore] = useState<number>();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingTable, setLoadingTable] = useState(true);

  const [loadingReport, setLoadingReport] = useState(false);
  const [visibleReport, setVisibleReport] = useState(false);

  const columns: Column<IProduct>[] = [
    { title: "ID", field: "id", editable: "never" },
    {
      editable: editableTable.referencia ? undefined : "never",
      title: "Referência",
      field: "referencia",
    },
    {
      editable: editableTable.cod_alternativo ? undefined : "never",
      title: "Cod.Alternativo",
      field: "cod_alternativo",
    },
    {
      editable: editableTable.ean_upc ? undefined : "never",
      title: "Ean",
      field: "ean_upc",
    },
    {
      editable: editableTable.descricao_longa ? undefined : "never",
      title: "Desc.Longa",
      field: "descricao_longa",
    },
    {
      editable: editableTable.preco_de_custo ? undefined : "never",
      title: "Pré Custo",
      field: "preco_de_custo",
      type: "numeric",
      render: (rowData) => {
        if (rowData.preco_de_custo) {
          return Number(rowData.preco_de_custo).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        } else {
          return "-";
        }
      },
    },
    {
      editable: editableTable.preco_de_venda ? undefined : "never",
      title: "PDV Atual",
      field: "preco_de_venda",
      type: "numeric",
      render: (rowData) => {
        if (rowData.preco_de_venda) {
          return Number(rowData.preco_de_venda).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        } else {
          return "";
        }
      },
    },
    {
      editable: "never",
      field: "preco_de_venda_calc",
      title: "PDV (conforme pré custo)",
      sorting: false,
      render: (rowData) => {
        if (markUpClient && markUpStore) {
          return Number(
            +rowData.preco_de_custo * markUpClient * markUpStore
          ).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
        } else {
          return "-";
        }
      },
    },
    {
      editable: "never",
      title: "Situação do Valor",
      field: "situacao_markup",
      render: (rowData) =>
        // contentPage.status.cod === 2 ? (
        rowData.situacao_markup === 1 ? (
          <RiAlertFill size={26} color={"#f6ff00"} />
        ) : (
          <HiBadgeCheck size={26} color={"#21b543"} />
        ),
      // ) : ("-")
    },
    {
      editable: editableTable.preco_de_venda_final ? undefined : "never",
      title: "PDV Final",
      field: "preco_de_venda_final",
      type: "numeric",
      render: (rowData) => {
        if (rowData.preco_de_venda_final) {
          return Number(rowData.preco_de_venda_final).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        } else {
          return "";
        }
      },
    },
    {
      editable: editableTable.tipo ? undefined : "never",
      title: "Tipo",
      field: "tipo",
      // lookup: {
      //   calçados: "calçados",
      //   confecção: "confecção",
      //   equipamento: "equipamento",
      // },
    },
    {
      editable: editableTable.fornecedor ? undefined : "never",
      title: "Fornecedor",
      field: "provider",
      lookup: arrToObj(
        providers.map((item) => ({
          name: item.fullname,
          subName: item.cod,
          value: item.id,
        }))
      ),
    },
    {
      editable: editableTable.preco_de_venda_comercial ? undefined : "never",
      title: "Preço de Venda",
      field: "preco_de_venda_comercial",
      type: "numeric",
      render: (rowData) => {
        if (rowData.preco_de_venda_comercial) {
          return Number(rowData.preco_de_venda_comercial).toLocaleString(
            "pt-br",
            {
              style: "currency",
              currency: "BRL",
            }
          );
        } else {
          return "";
        }
      },
    },

    {
      editable: "never",
      field: "markup_product_calc",
      title: "Markup do Produto",
      sorting: false,
      render: (rowData) => {
        if (rowData.preco_de_venda && rowData.preco_de_venda_comercial) {
          return Number(
            +rowData.preco_de_venda / +rowData.preco_de_venda_comercial
          ).toFixed(2);
        } else {
          return "-";
        }
      },
    },

    {
      editable: editableTable.conceito ? undefined : "never",
      title: "Conceito",
      field: "conceito",
    },
    {
      editable: editableTable.unidade ? undefined : "never",
      title: "Unidade",
      field: "unidade",
    },
    {
      editable: editableTable.tamanho ? undefined : "never",
      title: "Tamanho",
      field: "tamanho",
    },
    {
      editable: editableTable.origem_da_mercadoria ? undefined : "never",
      title: "Origem da Mercadoria",
      field: "origem_da_mercadoria",
    },
    {
      editable: editableTable.mes_entrega ? undefined : "never",
      title: "Mês de entrega",
      field: "mes_entrega",
    },
    {
      editable: editableTable.quantidade ? undefined : "never",
      title: "Quantidade",
      field: "quantidade",
    },
    {
      editable: editableTable.linha ? undefined : "never",
      title: "Linha",
      field: "linha",
    },

    {
      editable: editableTable.cor_predominante ? undefined : "never",
      title: "Cor predominante",
      field: "cor_predominante",
    },
    {
      editable: editableTable.segunda_cor_predominante ? undefined : "never",
      title: "Segunda cor predominante",
      field: "segunda_cor_predominante",
    },
    {
      editable: editableTable.genero ? undefined : "never",
      title: "Gênero",
      field: "genero",
    },
    {
      editable: editableTable.idade ? undefined : "never",
      title: "Idade",
      field: "idade",
    },
    {
      editable: editableTable.categoria ? undefined : "never",
      title: "Categoria",
      field: "categoria",
    },
    {
      editable: editableTable.caracteristicas ? undefined : "never",
      title: "Características",
      field: "caracteristicas",
    },
    {
      editable: editableTable.material ? undefined : "never",
      title: "Composição",
      field: "material",
    },
    {
      editable: editableTable.ncm ? undefined : "never",
      title: "Ncm",
      field: "ncm",
    },
    {
      editable: editableTable.classe_de_produto ? undefined : "never",
      title: "Classe de produto",
      field: "classe_de_produto",
    },
    {
      editable: editableTable.pesobruto ? undefined : "never",
      title: "Peso unitário bruto",
      field: "pesobruto",
    },
    {
      editable: editableTable.pesoliq ? undefined : "never",
      title: "Peso unitário líquido",
      field: "pesoliq",
    },
    {
      editable: editableTable.m3 ? undefined : "never",
      title: "M3",
      field: "m3",
    },
    {
      editable: editableTable.altura ? undefined : "never",
      title: "Altura",
      field: "altura",
    },
    {
      editable: editableTable.largura ? undefined : "never",
      title: "Largura",
      field: "largura",
    },
    {
      editable: editableTable.espessura ? undefined : "never",
      title: "Espessura",
      field: "espessura",
    },
  ];

  useEffect(() => {
    setLoading(true);

    (async () => {
      if (Boolean(match.params.id)) {
        try {
          const collectionDB = await api.get<ICollection>(
            `/collection/show/${match.params.id}`
          );

          if (!collectionDB.data) {
            return history.push("/404");
          }

          const providersDB = await api.get<IResponseAPI>("/provider", {
            params: { limitRequest: 99999, isCod: true },
          });

          const { markUpClient, markUpStore } = collectionDB.data;

          setProviders(providersDB.data.contents);
          setContentPage(collectionDB.data);
          setMarkUpClient(markUpClient);
          setMarkUpStore(markUpStore);

          setLoading(false);
        } catch (error) {
          return history.push("/404");
        }
      } else {
        history.push("/404");
      }
    })();
    // eslint-disable-next-line
  }, [history, admin, match, reaload]);

  useEffect(() => {
    (async () => {
      if (contentPage && contentPage.id) {
        setLoadingTable(true);
        try {
          if (userStorage && userStorage.permission) {
            if (
              +contentPage.status.responsable_id === +userStorage.permission
            ) {
              switch (+contentPage.status.cod) {
                case 1:
                  setUpdateDate(true);
                  setEditableTable({
                    ...editableTable,
                    referencia: true,
                    cod_alternativo: true,
                    ean_upc: true,
                    descricao_longa: true,
                    tipo: true,
                    unidade: true,
                    tamanho: true,
                    preco_de_custo: true,
                    origem_da_mercadoria: true,
                    linha: true,
                    cor_predominante: true,
                    segunda_cor_predominante: true,
                    genero: true,
                    idade: true,
                    categoria: true,
                    caracteristicas: true,
                    material: true,
                    classe_de_produto: true,
                    pesobruto: true,
                    pesoliq: true,
                    m3: true,
                    altura: true,
                    largura: true,
                    espessura: true,
                  });
                  break;
                case 2:
                  setDisabledInput(false);
                  setEditableTable({
                    ...editableTable,
                    mes_entrega: true,
                    quantidade: true,
                    conceito: true,
                    preco_de_venda: true,
                    preco_de_venda_comercial: true,
                    preco_de_venda_final: true,
                  });
                  setEditablePdv(true);
                  setEnableButton(true);
                  break;
                case 3:
                  setUpdateDate(true);
                  setEditableTable({
                    ...editableTable,
                    conceito: true,
                    preco_de_venda: true,
                    fornecedor: true,
                  });
                  break;

                default:
                  break;
              }
            }
            if (
              +userStorage.permission === 13 &&
              !contentPage.status.finisher
            ) {
              setUpdateDateNcm(true);
              setEditableTable({
                ...editableTable,
                ncm: true,
              });
            }
          }

          const productsDB = await api.get<IResponseAPIProducts>(
            `/collection/product/${contentPage.id}`,
            {
              params: {
                limitRequest: 9999,
              },
            }
          );
          const { contents } = productsDB.data;

          setProducts(contents);
          setLoadingTable(false);
        } catch (error) {
          addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      }
    })();
    // eslint-disable-next-line
  }, [contentPage]);

  async function handleCreateProduct(product: IProduct) {
    try {
      setProducts([...products, product]);

      await api.post(`/collection/product/${contentPage.id}`, product);
      addToast("Produto criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleUpdateProduct(
    oldProduct?: IProduct,
    newProduct?: IProduct
  ) {
    if (!oldProduct || !newProduct) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "warning",
          autoDismiss: true,
        }
      );
    }

    try {
      const resposne = await api.put<IProduct>(
        `/collection/product/${oldProduct.id}`,
        newProduct
      );
      // addToast("Produto editado com sucesso", {
      //   appearance: "success",
      //   autoDismiss: true,
      // });

      const normilized = products.map((item) => {
        if (resposne.data.id === item.id) {
          return resposne.data;
        } else {
          return item;
        }
      });

      setProducts(normilized);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleDeleteProduct(product: IProduct) {
    try {
      const productsDelete = products.filter((item) => item.id !== product.id);
      setProducts(productsDelete);

      await api.delete(`/collection/product/${product.id}`);
      addToast("Produto excluido com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleDownload() {
    setLoadingReport(true);

    try {
      let productsDB = await api.get<IResponseAPIProducts>(
        `/collection/product/${contentPage.id}`,
        { params: { limitRequest: 9999 } }
      );

      const newData: IReciveProduct[] = productsDB.data.contents.map(
        (item) => ({
          id: item.id,
          referencia: item.referencia,
          codAlternativo: item.cod_alternativo,
          ean: item.ean_upc,
          descricaoLonga: item.descricao_longa,
          preCusto: Number(item.preco_de_custo),
          pdvAtual: Number(item.preco_de_venda),
          "pdv(Conforme Pré custo)":
            Number(item.preco_de_custo) *
            Number(markUpClient) *
            Number(markUpStore),
          pdvFinal: Number(item.preco_de_venda_final),
          tipo: item.tipo,
          fornecedor: item.providerCod,
          precoDeVenda: Number(item.preco_de_venda_comercial),
          markupProduto:
            item.preco_de_venda && item.preco_de_venda_comercial
              ? Number(
                  +item.preco_de_venda / +item.preco_de_venda_comercial
                ).toFixed(2)
              : "-",
          conceito: item.conceito,
          unidade: item.unidade,
          tamanho: item.tamanho,
          origemDaMercadoria: item.origem_da_mercadoria,
          quantidade: item.quantidade,
          mesEntrega: item.mes_entrega,
          linha: item.linha,
          corPredominante: item.cor_predominante,
          segundaCorPredominante: item.segunda_cor_predominante,
          genero: item.genero,
          idade: item.idade,
          categoria: item.categoria,
          caracteristicas: item.caracteristicas,
          composicao: item.material,
          ncm: item.ncm,
          classeDeProduto: item.classe_de_produto,
          pesoBruto: item.pesobruto,
          pesoLiq: item.pesoliq,
          m3: item.m3,
          altura: item.altura,
          largura: item.largura,
          espessura: item.espessura,
        })
      );
      const workSheet = XLSX.utils.json_to_sheet(newData);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "aba");
      //Binary string
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      //Download
      XLSX.writeFile(workBook, "Importar-Colecao-Produtos.xlsx");
      setLoadingReport(false);
    } catch (error) {
      setLoadingReport(false);
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleExport(type: "product" | "ean") {
    setLoadingReport(true);

    try {
      const productsDB = await api.get(`/collection/export/${contentPage.id}`, {
        params: {
          typeReport: type,
        },
      });

      const workSheet = XLSX.utils.json_to_sheet(productsDB.data ?? []);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, "aba");
      //Binary string
      XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      //Download
      XLSX.writeFile(workBook, `Colecao-Sankhya-${type}.xlsx`);
      setLoadingReport(false);
    } catch (error) {
      setLoadingReport(false);
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function hadleImportProduct() {
    try {
      if (files !== undefined) {
        //@ts-ignore
        const arrBuffer = await files.arrayBuffer();

        //parse data
        const workBook = XLSX.read(arrBuffer, {
          type: "array",
          cellDates: true,
        });

        const workSheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[workSheetName];
        //convert to array
        const fileData: IProduct[] = XLSX.utils.sheet_to_json(workSheet);

        await api.post(`/collection/product/many/${contentPage.id}`, {
          products: fileData,
        });
        setFiles(undefined);
        setModalUpload(false);
        setReaload(reaload + 1);
        addToast("Produto criado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
      }
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleChangeStatus() {
    try {
      var error = false;
      const status = selectNextStatus(contentPage.status.cod).nextStatus;

      switch (Number(contentPage.status.cod)) {
        case 1:
          if (!products || !products[0]) {
            error = true;
            addToast("Necessário possuir no minimo um produto cadastrado", {
              appearance: "warning",
              autoDismiss: true,
            });
          } else {
            var execTest = await testValidateInput();
            if (execTest.isError) {
              var inputsError = "";
              error = execTest.isError;

              for (
                let index = 0;
                index < execTest.inputsError.length;
                index++
              ) {
                var item = execTest.inputsError[index];
                if (item.toUpperCase() === "PRECO_DE_VENDA_COMERCIAL")
                  item = "Preço de Venda";

                if (execTest.inputsError.length === index + 1) {
                  inputsError += `${item.toUpperCase()} `;
                } else {
                  inputsError += `${item.toUpperCase()} ,`;
                }
              }

              setConfirmationChange(false);
              addToast(
                `Os campos (${inputsError}) não foram preenchidos, é necessário preenchimentos para alterar a situação.`,
                {
                  appearance: "warning",
                  autoDismiss: true,
                }
              );
            }
          }

          break;

        case 2:
          if (!markUpClient || !markUpStore) {
            error = true;
            addToast(
              "Markup Alpar e Lojista deve estar preechidos para prosseguir",
              {
                appearance: "warning",
                autoDismiss: true,
              }
            );
          } else {
            const execTest = await testValidateInput();
            if (execTest.isError) {
              // eslint-disable-next-line
              var inputsError = "";
              error = execTest.isError;

              for (
                let index = 0;
                index < execTest.inputsError.length;
                index++
              ) {
                // eslint-disable-next-line
                var item = execTest.inputsError[index];
                if (item.toUpperCase() === "PRECO_DE_VENDA_COMERCIAL")
                  item = "Preço de Venda";

                if (execTest.inputsError.length === index + 1) {
                  inputsError += `${item.toUpperCase()}`;
                } else {
                  inputsError += `${item.toUpperCase()}, `;
                }
              }

              setConfirmationChange(false);
              addToast(
                `Os campos (${inputsError}) não foram preenchidos, é necessário preenchimentos para alterar a situação.`,
                {
                  appearance: "warning",
                  autoDismiss: true,
                }
              );
            } else {
              const test = handleTestValue();

              if (test) {
                error = test;
                addToast("Todas situações de produtos devem estar válidas", {
                  appearance: "warning",
                  autoDismiss: true,
                });
              }
            }
          }

          break;

        case 3:
          var execTestFinished = await testFinished();
          if (execTestFinished.isError) {
            // eslint-disable-next-line
            var inputsError = "";
            error = execTestFinished.isError;

            for (
              let index = 0;
              index < execTestFinished.inputsError.length;
              index++
            ) {
              // eslint-disable-next-line
              var item = execTestFinished.inputsError[index];
              if (item.toUpperCase() === "PRECO_DE_VENDA_COMERCIAL")
                item = "Preço de Venda";

              if (execTestFinished.inputsError.length === index + 1) {
                inputsError += `${item.toUpperCase()}`;
              } else {
                inputsError += `${item.toUpperCase()}, `;
              }
            }

            setConfirmationChange(false);
            addToast(
              `Todos campos devem estar preechidos para finalizar coleção, os campos (${inputsError}) não foram preenchidos`,
              {
                appearance: "warning",
                autoDismiss: true,
              }
            );
          }
          break;

        default:
          break;
      }

      if (!error) {
        await api.put(`/collection/${contentPage.id}`, {
          status,
          markUpClient: markUpClient ? Number(markUpClient) : undefined,
          markUpStore: markUpStore ? Number(markUpStore) : undefined,
        });

        addToast("Coleção alterado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/admin/colecoes");
      }

      setConfirmationChange(false);
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleSubmitMarkup(e: React.FormEvent) {
    e.preventDefault();
    setLoadingSubmit(true);
    try {
      if (markUpClient && markUpStore) {
        await api.put(`/collection/${contentPage.id}`, {
          markUpClient: +markUpClient,
          markUpStore: +markUpStore,
        });

        setContentPage({
          ...contentPage,
          markUpClient: +markUpClient,
          markUpStore: +markUpStore,
        });

        addToast("Markup alterado com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        setLoadingSubmit(false);
      } else {
        addToast(
          "Markup Alpar e Lojista deve estar preechidos para prosseguir",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
        setLoadingSubmit(false);
      }
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    var data: number | undefined = undefined;

    if (!isNaN(Number(value))) {
      data = Number(value);
    }

    if (data !== undefined) {
      // data = Number(value.replace(/\D/g, "")) ;
      if (name === "markUp.client") {
        setMarkUpClient(+data);
      }

      if (name === "markUp.store") {
        setMarkUpStore(+data);
      }
    }
  }

  function handleTestValue(): boolean {
    var data = false;
    if (products) {
      for (const product of products) {
        if (product.situacao_markup === 1) {
          data = true;
        }
      }
    }

    return data;
  }

  async function testFinished() {
    var isError = false;
    var inputsError: string[] = [];

    if (products && products[0]) {
      var keys = Object.keys(products[-0]);

      for (const product of products) {
        for (const key of keys) {
          //@ts-ignore
          if (product[key] === null || product[key] === undefined) {
            //@ts-ignore
            var isExist = inputsError.filter((item) => item === key);
            if (isExist[0] === undefined) {
              inputsError.push(key);
            }
            isError = true;
          }
        }
      }
    } else {
      isError = true;
    }

    return { isError, inputsError };
  }

  async function testValidateInput() {
    var isError = false;
    var inputsError: string[] = [];
    var validate: string[] = [];

    for (let index = 0; index < Object.keys(editableTable).length; index++) {
      if (Object.values(editableTable)[index]) {
        validate.push(Object.keys(editableTable)[index]);
      }
    }

    if (products && products[0]) {
      for (const product of products) {
        for (const key of validate) {
          //@ts-ignore
          if (product[key] === null || product[key] === undefined) {
            //@ts-ignore
            var isExist = inputsError.filter((item) => item === key);
            if (isExist[0] === undefined) {
              inputsError.push(key);
            }
            isError = true;
          }
        }
      }
    } else {
      isError = true;
    }

    return { isError, inputsError };
  }

  function selectNextStatus(codStatus: number): {
    title: string;
    nextStatus: number;
  } {
    switch (codStatus) {
      case 1:
        return {
          title: "PRÉ-CADASTRO FINALIZADO",
          nextStatus: 2,
        };

      case 2:
        return {
          title: "APROVAÇÃO DE MARKUP FINALIZADA",
          nextStatus: 3,
        };

      case 3:
        return {
          title: "TERMINO DE CADASTRO FINALIZADA",
          nextStatus: 4,
        };

      default:
        return { title: "", nextStatus: 0 };
    }
  }

  function arrToObj(arr: { value: any; name: any; subName?: any }[]) {
    return arr.reduce(
      (obj, item) =>
        Object.assign(obj, {
          [item.value]: item.subName
            ? `${item.subName} ${item.name}`
            : item.name,
        }),
      {}
    );
  }

  useOutsideAlerter(outSideRefReport, () => {
    if (visibleReport) setVisibleReport(false);
  });

  return (
    <Container style={{ padding: "10px 30px 100px 30px" }}>
      {!loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Detalhes da coleção</h2>

            {/* <button
              type="button"
              className="reportDownload"
              onClick={handleDownload}
            >
              {loadingReport ? (
                <Loading
                  borderSize={5}
                  size={22}
                  colorLoading="#259457"
                  borderColor="rgba(32, 114, 69, 0.5)"
                />
              ) : (
                <div>
                  <RiFileExcel2Line color="#207245" size={24} />
                  <MdArrowDownward color="#207245" size={16} />
                </div>
              )}
            </button> */}

            <ReportDownload
              onClick={() => setVisibleReport(!visibleReport)}
              style={{ marginLeft: 10 }}
            >
              {loadingReport ? (
                <Loading
                  borderSize={5}
                  size={20}
                  colorLoading="#259457"
                  borderColor="rgba(32, 114, 69, 0.5)"
                />
              ) : (
                <div className="icon-execel">
                  <RiFileExcel2Line color="#207245" size={24} />
                  <div
                    className={visibleReport ? "row-excel-active" : "row-excel"}
                  >
                    <MdArrowDownward color="#207245" size={16} />
                  </div>
                </div>
              )}
              {visibleReport && (
                <ul ref={outSideRefReport} className="optionReport">
                  <li onClick={() => handleDownload()}>
                    <div className="icon-execel">
                      <RiFileExcel2Line color="#207245" size={18} />
                    </div>
                    <span>Exportar em coleção padão</span>
                  </li>
                  <li onClick={() => handleExport("product")}>
                    <div className="icon-execel">
                      <RiFileExcel2Line color="#207245" size={18} />
                    </div>
                    <span>Cadastro de produto Sankhya</span>
                  </li>
                  <li onClick={() => handleExport("ean")}>
                    <div className="icon-execel">
                      <RiFileExcel2Line color="#207245" size={18} />
                    </div>
                    <span>Cadastro de Ean Sankhya</span>
                  </li>
                </ul>
              )}
            </ReportDownload>
          </header>

          <InfoStatus>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>COLEÇÃO - ANO</h5>
                </div>
                <p>
                  {contentPage.description && contentPage.year
                    ? `${contentPage.description.description} - ${contentPage.year}`
                    : `-`}
                </p>
              </div>
            </div>

            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>SITUAÇÃO</h5>
                </div>

                {contentPage.status ? (
                  <span
                    style={{
                      backgroundColor: contentPage.status.color,
                    }}
                  >
                    {contentPage.status.name}
                  </span>
                ) : (
                  <p>`-`</p>
                )}
              </div>
            </div>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>MARCA</h5>
                </div>
                <p>
                  {contentPage.brand !== undefined
                    ? contentPage.brand.name
                    : `-`}
                </p>
              </div>
            </div>

            {/* <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>DATA FINALIZAÇÃO</h5>
                </div>
                <p>
                  {contentPage.closing_at
                    ? moment(contentPage.closing_at)
                        .add("days", 1)
                        .format("DD/MM/YYYY")
                    : "-"}
                </p>
              </div>
            </div> */}
          </InfoStatus>

          {!contentPage.status.finisher &&
            Number(contentPage.status.responsable_id) ===
              Number(userStorage.permission) && (
              <ContainerButtons>
                <button
                  type="button"
                  onClick={() => {
                    setConfirmationChange(true);
                  }}
                >
                  {selectNextStatus(contentPage.status.cod).title}
                </button>
              </ContainerButtons>
            )}

          {confirmationChange && (
            <Modal
              modalVisible={confirmationChange}
              setModalVisible={setConfirmationChange}
              headerOff={true}
              maxHeight={350}
              maxWidth={650}
            >
              <PopUpConfirmation>
                <div className="headerPopUp">
                  <nav>
                    <FiSend size={95} opacity={0.2} />
                  </nav>
                  <span>
                    {`Você tem certeza que deseja realiar alteração? Após o
                    envio os dados não poderão ser alterados.`}
                  </span>
                </div>
                <div className="buttonsPopUp">
                  <button
                    type="button"
                    onClick={() => setConfirmationChange(false)}
                  >
                    Cancelar
                  </button>
                  <button type="button" onClick={handleChangeStatus}>
                    Alterar
                  </button>
                </div>
              </PopUpConfirmation>
            </Modal>
          )}

          <Info>
            <div className="headerDevoltion">
              <h2>Informações de venda</h2>
            </div>

            <div className="bodyDevoltion">
              <Form onSubmit={handleSubmitMarkup}>
                <div className="field-group">
                  <div className="field">
                    <label htmlFor="markUp.store">Markup Alpar</label>
                    <input
                      name={"markUp.store"}
                      value={markUpStore}
                      type="number"
                      // onChange={onChange}
                      onBlur={onChange}
                      disabled={disabledInput ? true : false}
                      style={{
                        borderStyle: disabledInput ? "dashed" : undefined,
                      }}
                    />
                  </div>
                  <div className="field">
                    <label htmlFor="markUp.client">Markup Lojista</label>
                    <input
                      name={"markUp.client"}
                      value={markUpClient}
                      type="number"
                      // onChange={onChange}
                      onBlur={onChange}
                      disabled={disabledInput ? true : false}
                      style={{
                        borderStyle: disabledInput ? "dashed" : undefined,
                      }}
                    />
                  </div>
                </div>

                {!disabledInput ? (
                  <div className="containerButton">
                    <ButtunSubmit
                      type="submit"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {loadingSubmit ? (
                        <Loading
                          size={22}
                          borderSize={4}
                          colorLoading="rgba(255,255,255)"
                          borderColor="rgba(255,255,255, 0.3)"
                        />
                      ) : (
                        "Salvar alterações"
                      )}
                    </ButtunSubmit>
                  </div>
                ) : null}
              </Form>
            </div>
          </Info>

          {!loadingTable ? (
            <ContainerList>
              <MaterialTable<IProduct>
                title="Produtos"
                data={products}
                columns={columns}
                localization={{
                  toolbar: {
                    searchPlaceholder: "Pesquisar",
                    searchTooltip: "Pesquisar",
                  },
                  header: {
                    actions: "Ações",
                  },

                  body: {
                    emptyDataSourceMessage: "Não há registros a serem exibidos",
                    editRow: {
                      saveTooltip: "Salvar",
                      cancelTooltip: "Cancelar",
                      deleteText:
                        "Tem certeza que deseja deletar este registro?",
                    },

                    addTooltip: "Adicionar",
                    deleteTooltip: "Deletar",
                    editTooltip: "Editar",
                  },
                  pagination: {
                    firstTooltip: "Primeira página",
                    previousTooltip: "anterior",
                    nextTooltip: "próxima",
                    lastTooltip: "Última página",
                    labelRowsSelect: "",
                    labelDisplayedRows: "{from}-{to} de {count}",
                  },
                }}
                editable={
                  updateDate
                    ? {
                        onRowAdd: (newRow) =>
                          new Promise(async (resolve) => {
                            await handleCreateProduct(newRow);
                            setReaload(reaload + 1);
                            resolve(true);
                          }),
                        onRowDelete: (selectedRow) =>
                          new Promise(async (resolve) => {
                            await handleDeleteProduct(selectedRow);
                            resolve(true);
                          }),
                        onRowUpdate: (newRow, oldRow) =>
                          new Promise(async (resolve) => {
                            await handleUpdateProduct(oldRow, newRow);
                            resolve(true);
                          }),
                      }
                    : editablePdv || updateDateNcm
                    ? {
                        // isEditable: (dataRow) =>
                        //   dataRow.situacao_markup === 1 ? true : false,
                        onRowUpdate: (newRow, oldRow) =>
                          new Promise(async (resolve) => {
                            await handleUpdateProduct(oldRow, newRow);
                            resolve(true);
                          }),
                      }
                    : undefined
                }
                isLoading={loadingTable}
                options={{
                  // actionsColumnIndex: -1,
                  // exportButton: true,
                  maxBodyHeight: "70vh",
                  debounceInterval: 700,
                  search: true,
                  addRowPosition: "first",
                  padding: "dense",
                  paginationType: "stepped",
                  pageSize: products.length,
                  pageSizeOptions: [products.length],
                  showFirstLastPageButtons: false,

                  rowStyle: (rowData: IProduct) => {
                    return {
                      backgroundColor:
                        contentPage.status.cod === 2
                          ? rowData.situacao_markup === 1
                            ? "rgba(255,0,0, 0.4)"
                            : undefined
                          : undefined,
                    };
                  },
                }}

                // data={(query) =>
                //   new Promise((resolve, reject) => {
                //     console.log(query);

                //     api
                //       .get<IResponseAPIProducts>(
                //         `/collection/product/${contentPage.id}`,
                //         {
                //           params: {
                //             search: query.search,
                //             limitRequest: query.pageSize,
                //             pageRequest: query.page,
                //             orderByField: query.orderBy?.field,
                //             orderDirection: query.orderDirection,
                //           },
                //         }
                //       )
                //       .then((productsDB) => {
                //         setLoadingTable(false);
                //         const { contents, page, total } = productsDB.data;
                //         resolve({
                //           data: contents,
                //           page: page,
                //           totalCount: total,
                //         });
                //       });
                //   })
                // }
              />
            </ContainerList>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingDot />
            </div>
          )}

          {updateDateNcm || updateDate || enableButton ? (
            <FloatButton
              Icon={BsFileEarmarkCheck}
              title="Alterar status"
              notFloatButton={true}
            >
              <li
                onClick={() => {
                  setModalUpload(true);
                }}
              >
                <span>Importar Produtos </span>
                <div
                  style={{
                    background: "#E99D09",
                    opacity: 0.9,
                  }}
                >
                  <BiImport color="#fff" size={22} />
                </div>
              </li>
            </FloatButton>
          ) : null}

          {modalUpload && (
            <Modal
              title="Importação de produtos"
              modalVisible={modalUpload}
              setModalVisible={setModalUpload}
              maxHeight={330}
              maxWidth={650}
            >
              <ContainerImportModal>
                <header>
                  <nav className="download-file">
                    <a
                      // eslint-disable-next-line
                      target="_blank"
                      href="http://localhost:3333/collection/product/download/file/import"
                    >
                      Baixar planilha modelo
                      <span>
                        <RiFileExcel2Line color="#207245" size={20} />
                        <MdArrowDownward color="#207245" size={14} />
                      </span>
                    </a>
                  </nav>
                </header>

                <ContainerDropZone>
                  <Dropzone onFileUploaded={(e) => setFiles(e)} />
                  <ContainerPreviews style={{ marginTop: 5 }}>
                    {files && (
                      <li key={`${generateHash()}-${files.name}`}>
                        <img src={logoExecel} alt="Logo_Execel" />
                        <div className="fileInfo">
                          <div>
                            <strong>{files.name}</strong>
                            <span>{filesize(files.size)}</span>
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              setFiles(undefined);
                            }}
                          >
                            <IoMdTrash size={30} />
                          </button>
                        </div>
                      </li>
                    )}
                  </ContainerPreviews>

                  <ButtunSubmit
                    className="buttonSubmit"
                    onClick={() => {
                      hadleImportProduct();
                    }}
                  >
                    {loading ? (
                      <div>
                        <Loading borderSize={2} size={20} />
                      </div>
                    ) : (
                      "Importar"
                    )}
                  </ButtunSubmit>
                </ContainerDropZone>
              </ContainerImportModal>
            </Modal>
          )}
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Collection;
