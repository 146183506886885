import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  grid-area: APP;

  padding: 10px 30px 10px 30px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #333;
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #888;
  }
`;

export const ContainerBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 15px;
  column-gap: 9px;
  row-gap: 9px;

  @media (max-width: 510px) {
    flex-direction: column;
  }
`;
export const Box = styled.nav`
  min-width: 230px;

  .boxExternal {
    flex: 1;
    height: 70px;
    width: 240px;

    /* max-width: 300px; */

    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);

    display: flex;
    justify-content: space-around;

    span {
      font-size: 1.2rem;
      font-weight: 600;
      margin-right: 5px;
      color: #555;
    }

    .icon {
      color: #d21e26;
      font-size: 30px;
    }

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    transition: all 0.2s;
  }

  @media (max-width: 900px) {
    flex: 1;
    width: 100%;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .boxExternal {
      width: 100%;
      min-width: 150px;
    }
  }
`;

export const ContainerBanner = styled.div`
  width: 100%;
  height: 20rem;
  border-radius: 8px;

  margin-top: 1rem;
  margin-bottom: 1rem;

  overflow: hidden;
  position: relative;

  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

export const Slide = styled.div`
  width: 100%;
  height: 20rem;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const PaginationSlide = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 10;

  background-color: white;
  padding: 4px;
  border-radius: 8px;

  display: flex;
  column-gap: 8px;

  div {
    height: 0.6rem;
    width: 0.6rem;
    background-color: #aaa;

    border-radius: 50%;
    cursor: pointer;
  }
`;
