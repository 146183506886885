import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { useToasts } from "react-toast-notifications";
// @ts-ignore
import { mask } from "remask";
import * as Yup from "yup";
import {
  ButtunSubmit,
  ContainerCheck,
  Error as ErrorComponent,
  Form,
  ScreenLoading,
} from "../../pages/create/styles";
import {
  DatasheetTableDataProps,
  DatasheetVariationProps,
  FileProps,
  GridProps,
  HeaderDatasheetAndDatasheet,
} from "../../pages/detail/Datasheet";
import {
  HeaderDatasheetProps,
  StatusProps,
} from "../../pages/detail/HeaderDatasheet";
import api from "../../service/api";
//import * as UserStorage from "../../service/localStorage/user";
import Loading from "../loadings/Loading";
import { Files } from "./Files";
import { LinkFile } from "./LinkFile";
import { GridErpProps, SelectGrid } from "./SelectGrid";
import { SelectProvider } from "./SelectProvider";
import { Container, ContainerForm } from "./styles";
import { Variation } from "./Variation";

type HeaderDatasheetPickProps = Pick<
  HeaderDatasheetProps,
  "id" | "collection" | "year"
>;

interface ModalModificationDatasheetProps {
  type: "create" | "update";
  onRequestUpdate: () => void;
  onRequestClose: () => void;
  data?: HeaderDatasheetAndDatasheet;

  headerDatasheet: HeaderDatasheetPickProps;
}

export interface FilesProps {
  isLink?: boolean;
  link?: string;
  name?: string;
  file?: File;
  category: string;
}

export interface IProvider {
  id: number;
  cod?: number;
  fullname?: string;
  brand?: string;
  email?: string;
}

export interface ColorAndCategoryProps {
  id: number;
  cod: number;
  description: string;
  active: boolean;
}

interface GeneretedDataProps {
  type: "alternative_code" | "description" | "characteristics";
}

interface FileCarryOverNormalized {
  name: string;
  size: number;
  key: string;
  url: string;
  mimetype: string;
  category_file: {};
}

export interface OptionsDataProps {
  genres: {
    id: number;
    initials: string;
    name: string;
  }[];

  unit_measures: {
    id: number;
    initials: string;
    name: string;
  }[];

  age_types: {
    id: number;
    name: string;
  }[];
  categories: {
    id: number;
    description: string;
  }[];
  materials: {
    id: number;
    description: string;
  }[];
  materials_natures: {
    id: number;
    description: string;
  }[];
  price_classification: {
    id: number;
    name: string;
  }[];

  product_origins: {
    id: number;
    name: string;
  }[];
  product_types: {
    id: number;
    name: string;
  }[];
  product_lines: {
    id: number;
    name: string;
  }[];
  product_groups: {
    id: number;
    name: string;
  }[];
  sleeves: {
    id: number;
    name: string;
  }[];
  ncms: NcmsProps[];
  grids: GridErpProps[];
}

export interface NcmsProps {
  id: number;
  code: number;
  cest: number;
  gpc: number;
  material: {
    id: number;
  }[];
  material_nature: {
    id: number;
  }[];
  type: {
    id: number;
  }[];
  genre: {
    id: number;
    initials: number;
  }[];
  category: {
    id: number;
  }[];
  group: {
    id: number;
  }[];
}

interface DisabledInputProps {
  status: boolean;
  description: boolean;
  alternative_code: boolean;
  unit_measure: boolean;
  price_classification: boolean;
  genre: boolean;
  type: boolean;
  group: boolean;
  line: boolean;
  category: boolean;
  material: boolean;
  modeling: boolean;
  characteristics: boolean;
  composition: boolean;
  price_cost: boolean;
  price_sale: boolean;
  pre_price_cost: boolean;
  pre_price_sale: boolean;
  tissue: boolean;
  product_origin: boolean;
  age: boolean;
  sleeve: boolean;
  material_nature: boolean;

  barcode_ncm: boolean;
  barcode_cest: boolean;
  barcode_gpc: boolean;
  weight_net: boolean;
  weight_gross: boolean;

  model: boolean;
  minimum: boolean;

  grid: boolean;
  variation: boolean;
  provider: boolean;
  measurementTable: boolean;
  files: boolean;
}

export const ModalModificationDatasheet: React.FC<
  ModalModificationDatasheetProps
> = ({ type, onRequestUpdate, onRequestClose, headerDatasheet, data }) => {
  const { addToast } = useToasts();
  // const history = useHistory();

  //const UserData = UserStorage.getUser() as UserStorage.User;

  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [files, setFiles] = useState<FilesProps[]>([]);

  const [disabledInput, setDisabledInput] = useState<DisabledInputProps>({
    status: true,
    description: true,
    alternative_code: true,
    unit_measure: true,
    genre: true,
    type: true,
    group: true,
    line: true,
    category: true,
    material: true,
    modeling: true,
    characteristics: true,
    composition: true,
    price_cost: true,
    price_sale: true,
    pre_price_cost: true,
    pre_price_sale: true,
    tissue: true,
    product_origin: true,
    age: true,
    sleeve: true,
    barcode_ncm: true,
    barcode_cest: true,
    barcode_gpc: true,
    weight_net: true,
    weight_gross: true,
    model: true,
    grid: true,
    variation: true,
    provider: true,
    measurementTable: true,
    files: true,
    price_classification: true,
    minimum: true,
    material_nature: true,
  });

  const [disabledButton, setDisabledButton] = useState(true);

  const [filesCarryOver, setFilesCarryOver] = useState<FileProps[]>([]);

  const [measurements, setMeasurements] = useState<DatasheetTableDataProps[]>(
    []
  );
  const [variations, setVariations] = useState<DatasheetVariationProps[]>([]);
  const [providersLinks, setProvidersLinks] = useState<IProvider[]>([]);
  const [gridsSelect, setGridsSelect] = useState<GridProps[]>([]);

  const [materials, setMaterials] = useState<ColorAndCategoryProps[]>([]);
  const [sizes, setSizes] = useState<ColorAndCategoryProps[]>([]);
  const [modelings, setModelings] = useState<ColorAndCategoryProps[]>([]);
  const [categories, setCategories] = useState<ColorAndCategoryProps[]>([]);
  const [colors, setColors] = useState<ColorAndCategoryProps[]>([]);

  const [optionsData, setOptionsData] = useState<OptionsDataProps>({
    genres: [],
    unit_measures: [],
    product_origins: [],
    age_types: [],
    product_types: [],
    product_lines: [],
    product_groups: [],
    sleeves: [],
    ncms: [],
    grids: [],
    categories: [],
    materials: [],
    price_classification: [],
    materials_natures: [],
  });

  const [ncms, setNcms] = useState<NcmsProps[]>([]);

  const [loadingGeneratedAlternativeCode, setLoadingGeneratedAlternativeCode] =
    useState(false);
  const [loadingGeneratedCharacteristics, setLoadingGeneratedCharacteristics] =
    useState(false);
  const [
    loadingGeneratedCharacteristicsDesc,
    setLoadingGeneratedCharacteristicsDesc,
  ] = useState(false);
  const [loadingGeneratedDescription, setLoadingGeneratedDescription] =
    useState(false);

  const [statusDatasheet, setStatusDatasheet] = useState<StatusProps[]>([]);

  function validadeteRequiredField(name: string, value: any): boolean {
    let error = true;

    const findStatus = statusDatasheet.find(
      (s) => Number(s.cod) === Number(formik.values.status)
    );

    if (findStatus?.required_fields.some((item) => item.field.name === name)) {
      if (value === undefined || value === null) error = false;
    }

    return error;
  }

  const formik = useFormik({
    // validateOnChange: false,
    validateOnMount: false,

    initialValues: {
      isCarryOver: data?.isCarryOver ? "s" : "n",
      isClassic: data?.isClassic ? "s" : "n",
      carryOverId: type === "update" ? data?.alternative_code : "",
      carryOver: {
        alternative_code: "",
        description: "",
      },

      status: type === "update" ? data?.status.cod : "",
      description: type === "update" ? data?.description : "",
      alternative_code: type === "update" ? data?.alternative_code : "",
      unit_measure: type === "update" ? data?.unit_measure : "",
      genre: type === "update" ? data?.genre?.id : "",
      type: type === "update" ? data?.product_type?.id : "",
      group: type === "update" ? data?.product_group?.id : "",
      line: type === "update" ? data?.product_line?.id : "",
      category: type === "update" ? data?.category?.id : "",
      material: type === "update" ? data?.material?.id : "",
      material_nature: type === "update" ? data?.material_nature?.id : "",
      modeling: type === "update" ? data?.modeling?.id : "",
      characteristics: type === "update" ? data?.characteristics : "",
      composition: type === "update" ? data?.composition : "",
      price_cost: type === "update" ? data?.price_cost : undefined,
      price_sale: type === "update" ? data?.price_sale : undefined,
      minimum: type === "update" ? data?.minimum : undefined,
      price_classification:
        type === "update" ? data?.price_classification?.id : undefined,
      pre_price_cost: type === "update" ? data?.pre_price_cost : undefined,
      pre_price_sale: type === "update" ? data?.pre_price_sale : undefined,
      tissue: type === "update" ? data?.tissue : "",

      barcode_ncm: type === "update" ? data?.barcode_ncm : "",
      barcode_cest: type === "update" ? data?.barcode_cest : "",
      barcode_gpc: type === "update" ? data?.barcode_gpc : "",

      product_origin: type === "update" ? data?.product_origin?.id : "",
      age: type === "update" ? data?.age_type?.id : "",
      sleeve: type === "update" ? data?.sleeve?.id : "",

      codGridSankhya: type === "update" ? data?.codGridSankhya : "",

      model: "",

      weight_net: type === "update" ? data?.weight_net : "",
      weight_gross: type === "update" ? data?.weight_gross : "",

      measurement_table: {
        description_measured:
          type === "update" ? data?.measurement_table.description_measured : "",
        unit_measurement:
          type === "update" ? data?.measurement_table.unit_measurement : "",
      },
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("description", value)
        )
        .required("Obrigatório"),
      alternative_code: Yup.string().test(
        "is_Fisisher",
        "Obrigatório",
        async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("alternative_code", value);
          }

          return error;
        }
      ),
      category: Yup.string().test(
        "is_Fisisher",
        "Obrigatório ",
        async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("category", value);
          }

          return error;
        }
      ),
      material: Yup.string().test(
        "is_Fisisher",
        "Obrigatório ",
        async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("material", value);
          }

          return error;
        }
      ),
      modeling: Yup.string().test(
        "is_Fisisher",
        "Obrigatório ",
        async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("modeling", value);
          }

          return error;
        }
      ),
      genre: Yup.string().test(
        "is_Fisisher",
        "Obrigatório ",
        async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("genre", value);
          }

          return error;
        }
      ),
      unit_measure: Yup.string()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("unit_measure", value);
          }

          return error;
        }),
      characteristics: Yup.string().test(
        "is_Fisisher",
        "Obrigatório ",
        async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("characteristics", value);
          }

          return error;
        }
      ),
      price_cost: Yup.string()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (status && status.finisher) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("price_cost", value);
          }

          return error;
        }),
      weight_net: Yup.string()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("weight_net", value);
          }

          return error;
        }),
      weight_gross: Yup.string()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("weight_gross", value);
          }

          return error;
        }),
      type: Yup.string()
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("type", value);
          }

          return error;
        }),
      group: Yup.string().test(
        "is_Fisisher",
        "Obrigatório ",
        async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("group", value);
          }

          return error;
        }
      ),
      line: Yup.string().test(
        "is_Fisisher",
        "Obrigatório ",
        async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("line", value);
          }

          return error;
        }
      ),
      composition: Yup.string().test(
        "is_Fisisher",
        "Obrigatório ",
        async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("composition", value);
          }

          return error;
        }
      ),

      carryOverId: Yup.string().test(
        "Valid_CarryOver",
        "Obrigatório",
        function (value) {
          let error = true;

          if (formik.values.isCarryOver === "s" && !value) {
            error = false;
          }

          return error;
        }
      ),
      barcode_ncm: Yup.string()
        .min(8, "Inválido, NCM possui 8 dígitos")
        .max(8, "Inválido, NCM possui 8 dígitos")
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("barcode_ncm", value);
          }

          return error;
        }),
      barcode_cest: Yup.string()
        .min(7, "Inválido, CEST possui 7 dígitos")
        .max(7, "Inválido, CEST possui 7 dígitos")
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("barcode_cest", value)
        ),
      barcode_gpc: Yup.string()
        .min(8, "Inválido, GPC possui 8 dígitos")
        .max(8, "Inválido, GPC possui 8 dígitos")
        .nullable()
        .test("is_Fisisher", "Obrigatório ", async function (value) {
          let error = true;

          const status = statusDatasheet.find(
            (f) => f.cod === Number(formik.values.status)
          );

          if (
            status &&
            (status.finisher ||
              status.is_generate_barcode ||
              status.is_generate_barcode_sample)
          ) {
            if (value === undefined || value === "" || value === null)
              error = false;
          }

          if (error) {
            error = validadeteRequiredField("barcode_gpc", value);
          }

          return error;
        }),

      price_classification: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("price_classification", value)
        ),
      minimum: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("minimum", value)
        ),
      product_origin: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("product_origin", value)
        ),
      status: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("status", value)
        ),
      price_sale: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("price_sale", value)
        ),
      pre_price_cost: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("pre_price_cost", value)
        ),
      pre_price_sale: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("pre_price_sale", value)
        ),
      tissue: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("tissue", value)
        ),
      age: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("age", value)
        ),
      sleeve: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("sleeve", value)
        ),
    }),

    onSubmit: async (data) =>
      type === "create"
        ? await handleSubmitCreate(data)
        : await handleSubmitUpdate(data),
  });

  useEffect(() => {
    let ncmsNormalized: NcmsProps[] = [];

    optionsData?.ncms?.forEach((ncm) => {
      let arrValid: number[] = [];

      ncm?.material_nature?.forEach((material) => {
        if (Number(material?.id) === Number(formik.values.material_nature)) {
          arrValid.push(1);
        }
      });

      ncm?.material?.forEach((material) => {
        if (Number(material?.id) === Number(formik.values.material)) {
          arrValid.push(1);
        }
      });

      ncm?.category?.forEach((category) => {
        if (Number(category?.id) === Number(formik.values.category)) {
          arrValid.push(1);
        }
      });
      ncm?.type?.forEach((type) => {
        if (Number(type?.id) === Number(formik.values.type)) {
          arrValid.push(1);
        }
      });
      ncm?.genre?.forEach((genre) => {
        if (Number(genre?.id) === Number(formik.values.genre)) {
          arrValid.push(1);
        }
      });

      ncm?.group?.forEach((group) => {
        if (Number(group?.id) === Number(formik.values.group)) {
          arrValid.push(1);
        }
      });

      const valid = arrValid.filter((f) => f === 1);
      if (valid.length >= 5) {
        ncmsNormalized.push(ncm);
      } else {
        if (valid.length >= 4) {
          ncmsNormalized.push(ncm);
        }
      }
    });

    if (ncmsNormalized.length === 1) {
      formik.setFieldValue("barcode_ncm", ncmsNormalized[0].code);
      if (ncmsNormalized[0].cest) {
        formik.setFieldValue("barcode_cest", ncmsNormalized[0].cest);
      }
      if (ncmsNormalized[0].gpc) {
        formik.setFieldValue("barcode_gpc", ncmsNormalized[0].gpc);
      }

      if (data?.id) {
        const updateDatasheet = {
          barcode_ncm: ncmsNormalized[0].code
            ? Number(ncmsNormalized[0].code)
            : undefined,
          barcode_cest: ncmsNormalized[0].cest
            ? Number(ncmsNormalized[0].cest)
            : undefined,
          barcode_gpc: ncmsNormalized[0].gpc
            ? Number(ncmsNormalized[0].gpc)
            : undefined,
        };

        api
          .put(`/headerDatasheet/datasheet/${data?.id}`, updateDatasheet)
          .then(() => true);
      }
    } else {
      if (ncmsNormalized.length === 0 && data?.barcode_ncm) {
        const oneNcmNormalized = optionsData?.ncms.filter(
          (f) => Number(f.code) === Number(data?.barcode_ncm)
        );
        ncmsNormalized = oneNcmNormalized;
      }
    }

    setNcms(ncmsNormalized);
    // eslint-disable-next-line
  }, [
    optionsData,
    optionsData.ncms,
    formik.values.material,
    formik.values.category,
    formik.values.type,
    formik.values.genre,
  ]);

  useEffect(() => {
    if (type === "create") {
      setDisabledButton(false);
      setDisabledInput({
        status: false,
        price_sale: false,
        price_cost: false,
        pre_price_sale: false,
        pre_price_cost: false,
        description: false,
        alternative_code: false,
        unit_measure: false,
        genre: false,
        type: false,
        group: false,
        line: false,
        category: false,
        material: false,
        modeling: false,
        characteristics: false,
        composition: false,
        tissue: false,
        product_origin: false,
        age: false,
        sleeve: false,
        barcode_ncm: false,
        barcode_cest: false,
        barcode_gpc: false,
        weight_net: false,
        weight_gross: false,
        model: false,
        grid: false,
        variation: false,
        provider: false,
        measurementTable: false,
        files: false,
        price_classification: false,
        minimum: false,
        material_nature: false,
      });
    }

    if (type === "update" && data) {
      if (!data.header_datasheet.status.finisher) {
        // const findOneReponsibles = data.status.responsibles.find(
        //   (f) => Number(f?.userId) === Number(UserData?.id)
        // );

        // if (!findOneReponsibles) return;

        setDisabledButton(false);

        var permissionFields: DisabledInputProps = {
          status: false,
          price_sale: false,
          price_cost: false,
          pre_price_sale: false,
          pre_price_cost: false,
          description: false,
          alternative_code: false,
          unit_measure: false,
          genre: false,
          type: false,
          group: false,
          line: false,
          category: false,
          material: false,
          modeling: false,
          characteristics: false,
          composition: false,
          tissue: false,
          product_origin: false,
          age: false,
          sleeve: false,
          barcode_ncm: false,
          barcode_cest: false,
          barcode_gpc: false,
          weight_net: false,
          weight_gross: false,
          model: false,
          grid: false,
          variation: false,
          provider: false,
          measurementTable: false,
          files: false,
          price_classification: false,
          minimum: false,
          material_nature: false,
        };

        if (data.isClassic && data.datasheetId) {
          permissionFields = {
            ...permissionFields,
            // category: false,

            description: false,
            alternative_code: false,
            unit_measure: false,
            genre: false,
            type: false,
            group: false,
            line: false,
            material: false,
            modeling: false,
            characteristics: false,
            composition: false,
            tissue: false,
            product_origin: false,
            age: false,
            sleeve: false,
            barcode_ncm: false,
            barcode_cest: false,
            barcode_gpc: false,
            weight_net: false,
            weight_gross: false,
            model: false,
            grid: false,
            variation: false,
            measurementTable: false,
            files: false,
          };
        }

        if (data.isCarryOver) {
          permissionFields = {
            ...permissionFields,

            // category: true,
            // sleeve: true,
            // genre: true,
            // unit_measure: true,
            // type: true,
            // material: true,
            // modeling: true,
            // line: true,
            // product_origin: true,
            // age: true,
            // group: true,
            // barcode_ncm: true,
            // barcode_cest: true,
            // barcode_gpc: true,
            // measurementTable: true,
            description: true,
            alternative_code: true,
            characteristics: true,
            weight_net: true,
            weight_gross: true,
            model: true,
          };
        }

        if (data.isGeneratedBarcode || data.isGeneratedBarcodeSample) {
          permissionFields = {
            ...permissionFields,
            alternative_code: true,
            // description: true,
            // unit_measure: true,
            // genre: true,
            // category: true,
            // modeling: true,
            // sleeve: true,
            // barcode_ncm: true,
            // barcode_cest: true,
            // barcode_gpc: true,
            // weight_net: true,
            // weight_gross: true,
            // model: true,
          };
        }

        setDisabledInput(permissionFields);

        return;
      }
    }

    // eslint-disable-next-line
  }, [data, type]);

  useEffect(() => {
    if (type === "update" && data) {
      setVariations(data.variations);

      setMeasurements(data.measurement_table.measurement_data);
      setProvidersLinks(data.providers);

      setGridsSelect(
        data.grids.map((grid) => ({
          id: grid.id,
          name: grid.size.description,
          sizeId: grid.sizesProductsId,
        }))
      );
    }
  }, [data, type]);

  useEffect(() => {
    (async () => {
      const optionsDataDB = await api.get<OptionsDataProps>(
        `/headerDatasheet/datasheet/options`
      );
      setOptionsData(optionsDataDB.data);

      const statusDB = await api.get<StatusProps[]>(
        `/headerDatasheet/datasheet/status`,
        {
          params: {
            // defineStatusDatasheetId: data && data?.id,
            // users: type === "update" ? false : true,
          },
        }
      );
      setStatusDatasheet(statusDB.data);

      const gategoriesDB = await api.get<ColorAndCategoryProps[]>(
        `/headerDatasheet/datasheet/category`
      );
      setCategories(gategoriesDB.data);

      const modelingDB = await api.get<ColorAndCategoryProps[]>(
        `headerDatasheet/datasheet/modeling`
      );
      setModelings(modelingDB.data);

      const materialDB = await api.get<ColorAndCategoryProps[]>(
        `headerDatasheet/datasheet/material`
      );
      setMaterials(materialDB.data);

      const sizeDB = await api.get<ColorAndCategoryProps[]>(
        `headerDatasheet/datasheet/size`
      );

      const normalizedSize = sizeDB.data.map((sizeItemDB) => {
        let active = false;
        if (data && data.grids) {
          data.grids.forEach((item) => {
            if (item.sizesProductsId === sizeItemDB.id) {
              active = true;
            }
          });
        }

        return { ...sizeItemDB, active };
      });
      setSizes(normalizedSize);

      const colorsDB = await api.get<ColorAndCategoryProps[]>(
        `/populate/color`
      );
      setColors(colorsDB.data);

      setLoadingPage(false);
    })();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formik.values.isCarryOver === "n") {
      if (type === "update") {
        formik.setFieldValue("carryOverId", data?.id);
        formik.setFieldValue("description", data?.description);
        formik.setFieldValue("bar_code", data?.bar_code);
        formik.setFieldValue("alternative_code", data?.alternative_code);
        formik.setFieldValue("unit_measure", data?.unit_measure?.id);
        formik.setFieldValue("genre", data?.genre?.id);
        formik.setFieldValue("type", data?.product_type?.id);
        formik.setFieldValue("group", data?.product_group?.id);
        formik.setFieldValue("line", data?.product_line?.id);
        formik.setFieldValue("category", data?.category?.id);
        formik.setFieldValue("material", data?.material?.id);
        formik.setFieldValue("modeling", data?.modeling?.id);
        formik.setFieldValue("characteristics", data?.characteristics);
        formik.setFieldValue("composition", data?.composition);
        formik.setFieldValue("price_cost", data?.price_cost);
        formik.setFieldValue("tissue", data?.tissue);
        formik.setFieldValue("product_origin", data?.product_origin?.id);
        formik.setFieldValue("age", data?.age_type?.id);
        formik.setFieldValue("sleeve", data?.sleeve?.id);
        formik.setFieldValue("material_nature", data?.material_nature?.id);

        formik.setFieldValue(
          "measurement_table.description_measured",
          data?.measurement_table.description_measured
        );
        formik.setFieldValue(
          "measurement_table.unit_measurement",
          data?.measurement_table.unit_measurement
        );
      } else {
        formik.setFieldValue("description", "");
        formik.setFieldValue("bar_code", "");
        formik.setFieldValue("reference", "");
        formik.setFieldValue("alternative_code", "");
        formik.setFieldValue("primary_color", "");
        formik.setFieldValue("secondary_color", "");
        formik.setFieldValue("unit_measure", "");
        formik.setFieldValue("genre", "");
        formik.setFieldValue("type", "");
        formik.setFieldValue("group", "");
        formik.setFieldValue("line", "");
        formik.setFieldValue("product_class", "");
        formik.setFieldValue("grid", "");
        formik.setFieldValue("category", "");
        formik.setFieldValue("material", "");
        formik.setFieldValue("modeling", "");
        formik.setFieldValue("characteristics", "");
        formik.setFieldValue("composition", "");
        formik.setFieldValue("price_cost", "");
        formik.setFieldValue("tissue", "");
        formik.setFieldValue("product_origin", "");
        formik.setFieldValue("age", "");
        formik.setFieldValue("material_nature", "");
        formik.setFieldValue("sleeve", "");

        formik.setFieldValue("carryOver.alternative_code", "");
        formik.setFieldValue("carryOver.description", "");
        formik.setFieldValue("carryOverId", "");

        formik.setFieldValue("measurement_table.description_measured", "");
        formik.setFieldValue("measurement_table.unit_measurement", "");

        setVariations([]);
        setMeasurements([]);
        setFilesCarryOver([]);
        setProvidersLinks([]);
      }
    }
    // eslint-disable-next-line
  }, [formik.values.isCarryOver, type]);

  async function handleSubmitCreate(props: any) {
    try {
      setLoading(true);

      const normalizedMeasurements = measurements.map((measurement) => ({
        description: String(measurement.description),
        size: Number(measurement.size),
      }));

      const normalizedvariations = variations.map((variation) => ({
        primary_color_cod: !isNaN(Number(variation.primary_color_cod))
          ? Number(variation.primary_color_cod)
          : undefined,
        secondary_color_cod: !isNaN(Number(variation.secondary_color_cod))
          ? Number(variation.secondary_color_cod)
          : undefined,
        is_drop: variation.is_drop,
      }));

      const validFiles = await onValidRequiredFiles();

      if (validFiles) {
        setLoading(false);
        return addToast("É obrigatório informar todos os tipos dos arquivos", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      var providersRelation;

      if (providersLinks.length > 0) {
        providersRelation = providersLinks.map((provider) => ({
          id: provider.id,
        }));
      }

      const newDatasheet = {
        statusId: props.status,
        description: props.description,
        reference: props.reference,
        alternative_code: props.alternative_code,
        primary_color: props.primary_color,
        secondary_color: props.secondary_color,
        unit_measure: props.unit_measure,
        genre: props.genre,
        type: props.type,
        tissue: props.tissue,
        group: props.group,
        category: props.category,
        line: props.line,
        product_class: props.product_class,
        grid: props.grid,
        material: props.material,
        material_nature: props.material_nature,
        minimum: props.minimum,
        price_classification: props.price_classification,
        modeling: props.modeling,
        composition: props.composition,
        characteristics: props.characteristics,
        providers: providersRelation,
        price_cost: props.price_cost ? Number(props.price_cost) : undefined,
        price_sale: props.price_sale ? Number(props.price_sale) : undefined,
        pre_price_cost: props.pre_price_cost
          ? Number(props.pre_price_cost)
          : undefined,
        pre_price_sale: props.pre_price_sale
          ? Number(props.pre_price_sale)
          : undefined,

        product_origin: props.product_origin,
        age: props.age,
        sleeve: props.sleeve,

        isClassic: props.isClassic === "s" ? true : undefined,

        barcode_ncm: props.barcode_ncm ? Number(props.barcode_ncm) : undefined,
        barcode_cest: props.barcode_cest
          ? Number(props.barcode_cest)
          : undefined,
        barcode_gpc: props.barcode_gpc ? Number(props.barcode_gpc) : undefined,
        weight_net: props.weight_net ? Number(props.weight_net) : undefined,
        weight_gross: props.weight_gross
          ? Number(props.weight_gross)
          : undefined,

        measurement_table: {
          description_measured: props.measurement_table.description_measured,
          unit_measurement: props.measurement_table.unit_measurement,
        },

        measurements: normalizedMeasurements,
        variations: normalizedvariations,
        grids: gridsSelect,

        isCarryOver: props.isCarryOver === "s" ? true : false,
        carryOverId: props.carryOverId ? +props.carryOverId : undefined,
        filesCarryOver: normalizedCarryOverFile(filesCarryOver),
      };

      const response = await api.post(
        `/headerDatasheet/datasheet/${headerDatasheet.id}`,
        newDatasheet
      );

      await handleSendFiles(response.data.id);

      setLoading(false);
      onRequestClose();
      onRequestUpdate();

      // history.push(
      //   `/admin/cabecalho-ficha-tecnica/${headerDatasheet.id}/ficha-tecnica/${response.data.id}`
      // );

      addToast("Ficha técnica criada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      setLoading(false);

      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (
        error?.response?.data?.message === "Código alternativo já cadastrado"
      ) {
        return addToast("Código alternativo já cadastrado", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleSubmitUpdate(props: any) {
    if (!data) return;
    setLoading(true);

    var normalizedMeasurementTable: {
      type: "create" | "update" | "delete";
      id: number | undefined;
      description: string;
      size: number;
      tableId?: number;
    }[] = measurements.map((measurementTable) => {
      var type: "create" | "update" | "delete" = "create";
      for (const measurementTableOld of data.measurement_table
        .measurement_data) {
        if (Number(measurementTable.id) === Number(measurementTableOld.id)) {
          type = "update";
        }
      }

      return {
        type,
        id: type === "update" ? measurementTable.id : undefined,
        description: measurementTable.description,
        size: Number(measurementTable.size),
        tableId: data?.measurement_table.id,
      };
    });
    for (const measurement_tableOld of data.measurement_table
      .measurement_data) {
      var findOne = normalizedMeasurementTable.find(
        (f) => Number(f.id) === Number(measurement_tableOld.id)
      );
      if (!findOne)
        normalizedMeasurementTable.push({
          type: "delete",
          id: measurement_tableOld.id,
          description: measurement_tableOld.description,
          size: Number(measurement_tableOld.size),
        });
    }

    var normalizedvariations: {
      type: "create" | "update" | "delete";
      id: number | undefined;
      primary_color_cod: number;
      secondary_color_cod: number | undefined;
      is_drop: boolean | undefined;
    }[] = variations.map((variation) => {
      var type: "create" | "update" | "delete" = "create";
      for (const variationOld of data.variations) {
        if (Number(variation.id) === Number(variationOld.id)) {
          type = "update";
        }
      }

      return {
        type,
        id: type === "update" ? variation.id : undefined,
        primary_color_cod: Number(variation.primary_color_cod),
        secondary_color_cod: variation.secondary_color_cod
          ? Number(variation.secondary_color_cod)
          : undefined,
        is_drop: variation.is_drop,
      };
    });
    for (const variationOld of data.variations) {
      var findOneVariation = normalizedvariations.find(
        (f) => Number(f.id) === Number(variationOld.id)
      );
      if (!findOneVariation)
        normalizedvariations.push({
          type: "delete",
          id: variationOld.id,
          primary_color_cod: variationOld.primary_color_cod,
          secondary_color_cod: !isNaN(Number(variationOld.secondary_color))
            ? Number(variationOld.secondary_color)
            : undefined,
          is_drop: variationOld.is_drop,
        });
    }

    var normalizedGridsSelect: any[] = gridsSelect.map((gridSelect) => {
      var type: "create" | "update" | "delete" = "create";

      for (const gridsSelectOld of data.grids) {
        if (Number(gridSelect.id) === Number(gridsSelectOld.id)) {
          if (sizes) type = "update";
        }
      }

      return {
        ...gridSelect,
        id: type === "update" ? gridSelect.id : undefined,
        type,
        name: gridSelect.sizeId,
      };
    });
    for (const gridsSelectOld of data.grids) {
      var findOneSelectOld = normalizedGridsSelect.find(
        (f) => Number(f.id) === Number(gridsSelectOld.id)
      );
      if (!findOneSelectOld) {
        normalizedGridsSelect.push({
          type: "delete",
          id: gridsSelectOld.id,
          name: gridsSelectOld.size,
          sizeId: gridsSelectOld.sizesProductsId,
        });
      }
    }

    try {
      const validFiles = await onValidRequiredFiles();

      if (validFiles) {
        setLoading(false);
        return addToast("É obrigatório informar todos os tipos dos arquivos", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      await handleSendFiles(data.id);

      var providersRelation;

      providersRelation = providersLinks.map((provider) => ({
        id: provider.id,
      }));

      const updateDatasheet = {
        statusId: props.status,
        description: props.description,
        reference: props.reference,
        alternative_code: props.alternative_code,
        primary_color: props.primary_color,
        secondary_color: props.secondary_color,
        unit_measure: props.unit_measure,
        tissue: props.tissue,
        genre: props.genre,
        type: props.type,
        group: props.group,
        category: props.category,
        line: props.line,
        product_class: props.product_class,
        minimum: props.minimum,
        price_classification: props.price_classification,
        grid: props.grid,
        material: props.material,
        material_nature: props.material_nature,
        modeling: props.modeling,
        composition: props.composition,
        characteristics: props.characteristics,
        providers: providersRelation,
        price_cost: props.price_cost,
        price_sale: props.price_sale,
        pre_price_cost: props.pre_price_cost
          ? Number(props.pre_price_cost)
          : undefined,
        pre_price_sale: props.pre_price_sale
          ? Number(props.pre_price_sale)
          : undefined,

        product_origin: props.product_origin,
        age: props.age,
        sleeve: props.sleeve,
        isClassic: props.isClassic === "s" ? true : undefined,

        barcode_ncm: props.barcode_ncm ? Number(props.barcode_ncm) : undefined,
        barcode_cest: props.barcode_cest
          ? Number(props.barcode_cest)
          : undefined,
        barcode_gpc: props.barcode_gpc ? Number(props.barcode_gpc) : undefined,
        weight_net: props.weight_net ? Number(props.weight_net) : undefined,
        weight_gross: props.weight_gross
          ? Number(props.weight_gross)
          : undefined,

        measurement_table: {
          description_measured: props.measurement_table.description_measured,
          unit_measurement: props.measurement_table.unit_measurement,
        },

        variations: normalizedvariations,
        measurements: normalizedMeasurementTable,
        grids: normalizedGridsSelect,

        filesCarryOver: normalizedCarryOverFile(filesCarryOver),
      };

      await api.put(`/headerDatasheet/datasheet/${data.id}`, updateDatasheet);

      setLoading(false);
      onRequestClose();
      onRequestUpdate();

      addToast("Ficha técnica atualizada com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      setLoading(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleSendFiles(datasheetId: number) {
    if (files.length > 0) {
      try {
        addToast("Enviando arquivos...", {
          appearance: "info",
        });

        const formData = new FormData();

        files.forEach((element) => {
          if (element.file) {
            formData.append("file", element.file);
          }
          formData.append("category", element.category);

          if (element.link && element.isLink) {
            formData.append("link", element.link);
            formData.append("name", element.name ?? "");
            formData.append("isLink", "1");
          }
        });

        await api.post(
          `/headerDatasheet/datasheet/files/${datasheetId}`,
          formData
        );

        addToast("Arquivos enviados com sucesso.", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (error) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
  }
  async function onValidRequiredFiles() {
    var response = false;

    for (const file of files) {
      if (file.category === "") {
        response = true;
      }
    }
    return response;
  }

  function normalizedCarryOverFile(files: FileProps[]) {
    var normalized: FileCarryOverNormalized[] = [];

    for (const file of files) {
      const newFile: FileCarryOverNormalized = {
        key: file.key,
        url: file.key,
        name: file.name,
        mimetype: file.mimetype,
        size: file.size,
        category_file: {
          connect: {
            id: file.category_file.id,
          },
        },
      };

      normalized.push(newFile);
    }

    return normalized;
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data: any;

    if (name === "minimum") {
      data = Number(value.replace(/\D/g, ""));
    }

    if (
      name === "price_cost" ||
      name === "price_sale" ||
      name === "pre_price_sale" ||
      name === "pre_price_cost"
    ) {
      data = Number(value.replace(/\D/g, "")) / 100;
    }

    if (
      name === "barcode_ncm" ||
      name === "barcode_cest" ||
      name === "barcode_gpc"
    ) {
      data = value.replace(/\D/g, "");
    }

    formik.setFieldValue(name, data);
  }

  async function generetedData({ type }: GeneretedDataProps) {
    try {
      if (!data) {
        throw new Error("Sem dados");
      }

      switch (type) {
        case "alternative_code":
          if (
            data.header_datasheet.brand.initials?.toString() &&
            formik.values.genre &&
            formik.values.category
          ) {
            const brand = data.header_datasheet.brand.initials?.toString();
            const genre = optionsData.genres.find(
              (g) => Number(g.id) === Number(formik.values.genre)
            )?.initials;
            const category = formik.values.category
              ? ("00" + formik.values.category).slice(-2)
              : undefined;

            const alternative_code_pre = String(
              `${brand}${genre}${category}`
            ).toUpperCase();

            const genetedModel = await api.post(
              `/headerDatasheet/datasheet/genereteCode/${data.id ?? 0}`,
              {
                code: alternative_code_pre,
                genreId: formik.values.genre,
                brandId: data.header_datasheet.brand.id,
                model: formik.values.model,
              }
            );

            const model = genetedModel.data.model;

            const alternative_code = String(
              `${brand}${genre}${category}${model}`
            ).toUpperCase();

            formik.setFieldValue("alternative_code", alternative_code);
            setLoadingGeneratedAlternativeCode(false);
          } else {
            var paramsAlternativeCode: string[] = [];
            if (!data.header_datasheet.brand.initials?.toString())
              paramsAlternativeCode.push("Marca");
            if (!formik.values.genre) paramsAlternativeCode.push("Gênero");
            if (!formik.values.category)
              paramsAlternativeCode.push("Classe de produto");

            addToast(
              `Faltam parâmetros para gerar o Cód Alternativo. Parâmetros: ${paramsAlternativeCode.join()}`,
              {
                appearance: "warning",
                autoDismiss: true,
              }
            );
            setLoadingGeneratedAlternativeCode(false);
          }

          break;

        case "description":
          if (
            formik.values.category !== undefined &&
            formik.values.material !== undefined &&
            formik.values.modeling !== undefined &&
            formik.values.category !== "" &&
            formik.values.material !== "" &&
            formik.values.modeling !== ""
          ) {
            const description = String(
              `${
                categories.find((q) => +q.id === Number(formik.values.category))
                  ?.description
              }${
                formik.values.sleeve
                  ? " " +
                    optionsData.sleeves.find(
                      (q) => +q.id === Number(formik.values.sleeve)
                    )?.name
                  : ""
              } ${
                materials.find((q) => +q.id === Number(formik.values.material))
                  ?.description
              } ${
                modelings.find((q) => +q.id === Number(formik.values.modeling))
                  ?.description
              } (⚠️Detalhes⚠️)
          `
            ).toUpperCase();
            formik.setFieldValue("description", description);
          } else {
            var paramsdescription: string[] = [];

            if (!formik.values.category)
              paramsdescription.push("Classe de produto");
            if (!formik.values.material) paramsdescription.push("Material");
            if (!formik.values.modeling) paramsdescription.push("Modelo");

            addToast(
              `Faltam parâmetros para gerar o Descrição. Parâmetros: ${paramsdescription.join()}`,
              {
                appearance: "warning",
                autoDismiss: true,
              }
            );
          }

          setLoadingGeneratedDescription(false);
          break;

        case "characteristics":
          if (
            formik.values.category &&
            formik.values.material &&
            formik.values.modeling
          ) {
            const description = String(
              `${
                categories.find((q) => +q.id === Number(formik.values.category))
                  ?.description
              }${
                formik.values.sleeve
                  ? " " +
                    optionsData.sleeves.find(
                      (q) => +q.id === Number(formik.values.sleeve)
                    )?.name
                  : ""
              } ${
                materials.find((q) => +q.id === Number(formik.values.material))
                  ?.description
              }  (⚠️ Mais informação do Material⚠️) ${
                modelings.find((q) => +q.id === Number(formik.values.modeling))
                  ?.description
              } (⚠️Detalhes com mais informação⚠️)
          `
            ).toUpperCase();
            formik.setFieldValue("characteristics", description);
          } else {
            var paramscharacteristics: string[] = [];

            if (!formik.values.category)
              paramscharacteristics.push("Classe de produto");
            if (!formik.values.material) paramscharacteristics.push("Material");
            if (!formik.values.modeling) paramscharacteristics.push("Modelo");

            addToast(
              `Faltam parâmetros para gerar o Descrição. Parâmetros: ${paramscharacteristics.join()}`,
              {
                appearance: "warning",
                autoDismiss: true,
              }
            );
          }

          setLoadingGeneratedCharacteristics(false);
          break;

        default:
          break;
      }
    } catch (err) {
      const error = err as any;

      setLoadingGeneratedAlternativeCode(false);
      setLoadingGeneratedDescription(false);
      setLoadingGeneratedCharacteristics(false);

      if (!error?.response || error?.response?.status >= 404) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (
        error.response.status === 400 &&
        error.response?.data?.error === "already exists"
      ) {
        return addToast("Código Alternativo ja existente na base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    }
  }

  return (
    <>
      <Container>
        <Form
          onSubmit={
            loading
              ? (e) => {
                  e.preventDefault();
                }
              : formik.handleSubmit
          }
        >
          <ContainerForm>
            <div className="containerForm">
              {!loadingPage ? (
                <>
                  <h3>Situação</h3>
                  <div className="field">
                    <select
                      {...formik.getFieldProps("status")}
                      disabled={disabledInput.status}
                    >
                      {statusDatasheet.map((status, index) => (
                        <option key={index} value={status.cod}>
                          {status.name}
                        </option>
                      ))}
                    </select>

                    {formik.touched.status && formik.errors.status ? (
                      <ErrorComponent>
                        <FiAlertCircle color="#f00" size={16} />
                        <span> {formik.errors.status} </span>
                      </ErrorComponent>
                    ) : null}
                  </div>

                  {data?.isCarryOver !== true && data?.isClassic !== true && (
                    <>
                      <h3>Clássico</h3>

                      <div className="field">
                        <ContainerCheck>
                          <button
                            type="button"
                            className={
                              formik.values.isClassic === "s" ? "check" : ""
                            }
                            onClick={() => {
                              formik.setFieldValue("isClassic", "s");
                            }}
                          >
                            SIM
                          </button>

                          <button
                            type="button"
                            className={
                              formik.values.isClassic === "n" ? "check" : ""
                            }
                            onClick={() => {
                              formik.setFieldValue("isClassic", "n");
                            }}
                          >
                            NÃO
                          </button>
                        </ContainerCheck>
                      </div>
                    </>
                  )}

                  {type === "update" &&
                    (data?.isCarryOver === true ||
                      data?.isClassic === true) && (
                      <>
                        <h3>Tipo</h3>

                        <div className="field">
                          <ContainerCheck style={{ borderColor: "#E98F93" }}>
                            <button
                              type="button"
                              disabled
                              className={
                                formik.values.isClassic === "s" ? "check" : ""
                              }
                            >
                              Clássico
                            </button>

                            <button
                              type="button"
                              disabled
                              className={
                                formik.values.isCarryOver === "s" ? "check" : ""
                              }
                            >
                              Carry over
                            </button>
                          </ContainerCheck>
                        </div>
                      </>
                    )}

                  <h3>Informações</h3>
                  <>
                    <div className="field">
                      <label htmlFor="description">
                        Descrição <span className="required">*</span>
                      </label>

                      <div className="containerCnpj">
                        <input
                          readOnly={disabledInput.description}
                          type="text"
                          {...formik.getFieldProps("description")}
                        />
                        <button
                          disabled={disabledInput.description}
                          type="button"
                          onClick={() => {
                            setLoadingGeneratedDescription(true);
                            generetedData({ type: "description" });
                          }}
                        >
                          {loadingGeneratedDescription ? (
                            <Loading
                              size={22}
                              borderSize={3.5}
                              colorLoading="rgba(255,255,255)"
                              borderColor="rgba(255,255,255, 0.3)"
                            />
                          ) : (
                            "GERAR"
                          )}
                        </button>
                      </div>

                      {formik.touched.description &&
                      formik.errors.description ? (
                        <ErrorComponent>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formik.errors.description} </span>
                        </ErrorComponent>
                      ) : null}
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="alternative_code">
                          Cód Alternativo
                        </label>

                        <div className="containerCnpj">
                          <input
                            type="text"
                            readOnly
                            style={{
                              borderStyle: "dashed",
                            }}
                            {...formik.getFieldProps("alternative_code")}
                            onChange={formik.handleChange("alternative_code")}
                          />
                          <button
                            disabled={disabledInput.alternative_code}
                            type="button"
                            onClick={() => {
                              setLoadingGeneratedAlternativeCode(true);
                              generetedData({ type: "alternative_code" });
                            }}
                          >
                            {loadingGeneratedAlternativeCode ? (
                              <Loading
                                size={22}
                                borderSize={3.5}
                                colorLoading="rgba(255,255,255)"
                                borderColor="rgba(255,255,255, 0.3)"
                              />
                            ) : (
                              "GERAR"
                            )}
                          </button>
                        </div>

                        {formik.touched.alternative_code &&
                        formik.errors.alternative_code ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.alternative_code} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field" style={{ flex: 0.2 }}>
                        <label htmlFor="model">N.º do modelo</label>
                        <input
                          type="number"
                          readOnly={disabledInput.model}
                          {...formik.getFieldProps("model")}
                          maxLength={4}
                        />
                        {formik.touched.model && formik.errors.model ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.model} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                    </div>
                  </>

                  <h3>Características</h3>
                  <>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="genre">
                          Gênero <span className="required">*</span>
                        </label>
                        <select
                          disabled={disabledInput.genre}
                          {...formik.getFieldProps("genre")}
                        >
                          <option value="">Selecionar</option>
                          {optionsData.genres.map((genre, index) => (
                            <option key={index} value={genre.id}>
                              {genre.name}
                            </option>
                          ))}
                        </select>

                        {formik.touched.genre && formik.errors.genre ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.genre} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field">
                        <label htmlFor="category">
                          Classe de produto <span className="required">*</span>
                        </label>

                        <select
                          disabled={disabledInput.category}
                          {...formik.getFieldProps("category")}
                        >
                          <option value="">Selecionar</option>
                          {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.description}
                            </option>
                          ))}
                        </select>

                        {formik.touched.category && formik.errors.category ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.category} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field">
                        <label htmlFor="category">Manga</label>

                        <select
                          disabled={disabledInput.sleeve}
                          {...formik.getFieldProps("sleeve")}
                        >
                          <option value="">Selecionar</option>

                          {optionsData.sleeves.map((sleeve, index) => (
                            <option key={index} value={sleeve.id}>
                              {sleeve.name}
                            </option>
                          ))}
                        </select>

                        {formik.touched.sleeve && formik.errors.sleeve ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.sleeve} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field">
                        <label htmlFor="modeling">
                          Modelagem <span className="required">*</span>
                        </label>
                        <select
                          disabled={disabledInput.modeling}
                          {...formik.getFieldProps("modeling")}
                        >
                          <option value="">Selecionar</option>
                          {modelings.map((data) => (
                            <option key={data.id} value={data.id}>
                              {data.description}
                            </option>
                          ))}
                        </select>

                        {formik.touched.modeling && formik.errors.modeling ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.modeling} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="material">
                          Tecido Comercial <span className="required">*</span>
                        </label>

                        <select
                          disabled={disabledInput.material}
                          {...formik.getFieldProps("material")}
                        >
                          <option value="">Selecionar</option>
                          {materials.map((data) => (
                            <option key={data.id} value={data.id}>
                              {data.description}
                            </option>
                          ))}
                        </select>

                        {formik.touched.material && formik.errors.material ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.material} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field">
                        <label htmlFor="material">Natureza do Material</label>

                        <select
                          disabled={disabledInput.material_nature}
                          {...formik.getFieldProps("material_nature")}
                        >
                          <option value="">Selecionar</option>
                          {optionsData.materials_natures.map((data) => (
                            <option key={data.id} value={data.id}>
                              {data.description}
                            </option>
                          ))}
                        </select>

                        {formik.touched.material_nature &&
                        formik.errors.material_nature ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.material_nature} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field">
                        <label htmlFor="composition">Tecido/Artigo</label>
                        <input
                          readOnly={disabledInput.tissue}
                          {...formik.getFieldProps("tissue")}
                        />

                        {formik.touched.tissue && formik.errors.tissue ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.tissue} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field">
                        <label htmlFor="group">Categoria</label>

                        <select
                          disabled={disabledInput.group}
                          {...formik.getFieldProps("group")}
                        >
                          <option value="">Selecionar</option>
                          {optionsData.product_groups.map((genre, index) => (
                            <option key={index} value={genre.id}>
                              {genre.name}
                            </option>
                          ))}
                        </select>

                        {formik.touched.group && formik.errors.group ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.group} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="unit_measure">
                          Unid. Medida Embalagem
                        </label>
                        <select
                          disabled={disabledInput.unit_measure}
                          {...formik.getFieldProps("unit_measure")}
                        >
                          <option value="">Selecionar</option>
                          {optionsData.unit_measures.map((unit, index) => (
                            <option key={index} value={unit.id}>{`${
                              unit.name
                            } (${unit.initials.toUpperCase()}) `}</option>
                          ))}
                        </select>
                        {formik.touched.unit_measure &&
                        formik.errors.unit_measure ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.unit_measure} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                      <div className="field">
                        <label htmlFor="type">Tipo</label>

                        <select
                          disabled={disabledInput.type}
                          {...formik.getFieldProps("type")}
                        >
                          <option value="">Selecionar...</option>
                          {optionsData.product_types.map((genre, index) => (
                            <option key={index} value={genre.id}>
                              {genre.name}
                            </option>
                          ))}
                        </select>

                        {formik.touched.type && formik.errors.type ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.type} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field">
                        <label htmlFor="line">Linha</label>

                        <select
                          disabled={disabledInput.line}
                          {...formik.getFieldProps("line")}
                        >
                          <option value="">Selecionar</option>
                          {optionsData.product_lines.map((genre, index) => (
                            <option key={index} value={genre.id}>
                              {genre.name}
                            </option>
                          ))}
                        </select>

                        {formik.touched.line && formik.errors.line ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.line} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="product_origin">
                          Origem da Mercadoria
                        </label>

                        <select
                          disabled={disabledInput.product_origin}
                          {...formik.getFieldProps("product_origin")}
                        >
                          <option value="">Selecionar</option>
                          {optionsData.product_origins.map((genre, index) => (
                            <option key={index} value={genre.id}>
                              {genre.name}
                            </option>
                          ))}
                        </select>

                        {formik.touched.product_origin &&
                        formik.errors.product_origin ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.product_origin} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field">
                        <label htmlFor="composition">Idade</label>

                        <select
                          disabled={disabledInput.age}
                          {...formik.getFieldProps("age")}
                        >
                          <option value="">Selecionar</option>
                          {optionsData.age_types.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>

                        {formik.touched.age && formik.errors.age ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.age} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                    </div>

                    <div className="field">
                      <label htmlFor="composition">Composição</label>
                      <textarea
                        readOnly={disabledInput.composition}
                        {...formik.getFieldProps("composition")}
                      ></textarea>

                      {formik.touched.composition &&
                      formik.errors.composition ? (
                        <ErrorComponent>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formik.errors.composition} </span>
                        </ErrorComponent>
                      ) : null}
                    </div>

                    <div className="field">
                      <label htmlFor="characteristics">Características</label>

                      <div className="containerCnpj">
                        <input
                          readOnly={disabledInput.characteristics}
                          type="text"
                          {...formik.getFieldProps("characteristics")}
                        />
                        <button
                          disabled={disabledInput.characteristics}
                          type="button"
                          onClick={() => {
                            setLoadingGeneratedCharacteristics(true);
                            generetedData({ type: "characteristics" });
                          }}
                        >
                          {loadingGeneratedCharacteristics ? (
                            <Loading
                              size={22}
                              borderSize={3.5}
                              colorLoading="rgba(255,255,255)"
                              borderColor="rgba(255,255,255, 0.3)"
                            />
                          ) : (
                            "GERAR"
                          )}
                        </button>
                        <button
                          style={{
                            marginLeft: "5px",
                          }}
                          disabled={disabledInput.characteristics}
                          type="button"
                          onClick={() => {
                            setLoadingGeneratedCharacteristicsDesc(true);
                            formik.setFieldValue(
                              "characteristics",
                              formik.values.description
                            );
                            setLoadingGeneratedCharacteristicsDesc(false);
                          }}
                        >
                          {loadingGeneratedCharacteristicsDesc ? (
                            <Loading
                              size={22}
                              borderSize={3.5}
                              colorLoading="rgba(255,255,255)"
                              borderColor="rgba(255,255,255, 0.3)"
                            />
                          ) : (
                            "COPIAR DESC."
                          )}
                        </button>
                      </div>

                      {formik.touched.characteristics &&
                      formik.errors.characteristics ? (
                        <ErrorComponent>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formik.errors.characteristics} </span>
                        </ErrorComponent>
                      ) : null}
                    </div>
                  </>

                  <h3>Valores</h3>
                  <>
                    <div className="field">
                      <label htmlFor="line">Faixa</label>

                      <select
                        disabled={disabledInput.price_classification}
                        {...formik.getFieldProps("price_classification")}
                      >
                        <option value="">Selecionar</option>
                        {optionsData.price_classification.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>

                      {formik.touched.price_classification &&
                      formik.errors.price_classification ? (
                        <ErrorComponent>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formik.errors.price_classification} </span>
                        </ErrorComponent>
                      ) : null}
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="pre_price_sale">
                          Pré Preço de venda
                        </label>
                        <input
                          name="pre_price_sale"
                          readOnly={disabledInput.pre_price_sale}
                          onChange={handleInputChange}
                          onBlur={formik.handleBlur("pre_price_sale")}
                          value={
                            !isNaN(Number(formik.values.pre_price_sale))
                              ? Number(
                                  formik.values.pre_price_sale
                                ).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })
                              : ""
                          }
                        />

                        {formik.touched.pre_price_sale &&
                        formik.errors.pre_price_sale ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.pre_price_sale} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                      <div className="field">
                        <label htmlFor="pre_price_cost">
                          Pré Custo do Produto
                        </label>
                        <input
                          name="pre_price_cost"
                          readOnly={disabledInput.pre_price_cost}
                          onChange={handleInputChange}
                          onBlur={formik.handleBlur("pre_price_cost")}
                          value={
                            !isNaN(Number(formik.values.pre_price_cost))
                              ? Number(
                                  formik.values.pre_price_cost
                                ).toLocaleString("pt-br", {
                                  style: "currency",
                                  currency: "BRL",
                                })
                              : ""
                          }
                        />

                        {formik.touched.pre_price_cost &&
                        formik.errors.pre_price_cost ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.pre_price_cost} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                    </div>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="price_sale">Preço de venda Final</label>
                        <input
                          name="price_sale"
                          readOnly={disabledInput.price_sale}
                          onChange={handleInputChange}
                          onBlur={formik.handleBlur("price_sale")}
                          value={
                            !isNaN(Number(formik.values.price_sale))
                              ? Number(formik.values.price_sale).toLocaleString(
                                  "pt-br",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )
                              : ""
                          }
                        />

                        {formik.touched.price_sale &&
                        formik.errors.price_sale ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.price_sale} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                      <div className="field">
                        <label htmlFor="price_cost">
                          Custo do Produto Final
                        </label>
                        <input
                          name="price_cost"
                          readOnly={disabledInput.price_cost}
                          onChange={handleInputChange}
                          onBlur={formik.handleBlur("price_cost")}
                          value={
                            !isNaN(Number(formik.values.price_cost))
                              ? Number(formik.values.price_cost).toLocaleString(
                                  "pt-br",
                                  {
                                    style: "currency",
                                    currency: "BRL",
                                  }
                                )
                              : ""
                          }
                        />

                        {formik.touched.price_cost &&
                        formik.errors.price_cost ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.price_cost} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                    </div>

                    <div className="field">
                      <label htmlFor="minimum">Mínimo</label>

                      <input
                        name="minimum"
                        onChange={handleInputChange}
                        onBlur={formik.handleBlur("minimum")}
                        value={formik.values.minimum}
                      />

                      {formik.touched.minimum && formik.errors.minimum ? (
                        <ErrorComponent>
                          <FiAlertCircle color="#f00" size={16} />
                          <span> {formik.errors.minimum} </span>
                        </ErrorComponent>
                      ) : null}
                    </div>
                  </>

                  <h3>Código de barras</h3>
                  <>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="weight_net">
                          Peso líquido em gramas
                        </label>
                        <input
                          type="number"
                          readOnly={disabledInput.weight_net}
                          {...formik.getFieldProps("weight_net")}
                        />

                        {formik.touched.weight_net &&
                        formik.errors.weight_net ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.weight_net} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                      <div className="field">
                        <label htmlFor="weight_gross">
                          Peso bruto em gramas
                        </label>
                        <input
                          type="number"
                          readOnly={disabledInput.weight_gross}
                          {...formik.getFieldProps("weight_gross")}
                        />

                        {formik.touched.weight_gross &&
                        formik.errors.weight_gross ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.weight_gross} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="barcode_ncm">NCM</label>
                        <select
                          disabled={disabledInput.barcode_ncm}
                          {...formik.getFieldProps("barcode_ncm")}
                        >
                          <option value="">Selecionar</option>
                          {ncms.map((ncm) => (
                            <option key={ncm.id} value={ncm.code}>
                              {mask(ncm.code, ["9999.99.99"])}
                            </option>
                          ))}
                        </select>

                        {formik.touched.barcode_ncm &&
                        formik.errors.barcode_ncm ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.barcode_ncm} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field">
                        <label htmlFor="barcode_cest">CEST</label>
                        <input
                          name="barcode_cest"
                          readOnly={disabledInput.barcode_cest}
                          maxLength={9}
                          onChange={handleInputChange}
                          onBlur={formik.handleBlur("barcode_cest")}
                          value={
                            formik.values.barcode_cest
                              ? mask(formik.values.barcode_cest, ["99.999.99"])
                              : ""
                          }
                        />

                        {formik.touched.barcode_cest &&
                        formik.errors.barcode_cest ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.barcode_cest} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>

                      <div className="field">
                        <label htmlFor="barcode_gpc">GPC</label>
                        <input
                          name="barcode_gpc"
                          readOnly={disabledInput.barcode_gpc}
                          maxLength={10}
                          onChange={handleInputChange}
                          onBlur={formik.handleBlur("barcode_gpc")}
                          value={
                            formik.values.barcode_gpc
                              ? mask(formik.values.barcode_gpc, ["9999.99.99"])
                              : ""
                          }
                        />

                        {formik.touched.barcode_gpc &&
                        formik.errors.barcode_gpc ? (
                          <ErrorComponent>
                            <FiAlertCircle color="#f00" size={16} />
                            <span> {formik.errors.barcode_gpc} </span>
                          </ErrorComponent>
                        ) : null}
                      </div>
                    </div>
                  </>

                  <SelectGrid
                    formik={formik}
                    gridsSelect={gridsSelect}
                    setGridsSelect={setGridsSelect}
                    sizes={sizes}
                    setSizes={setSizes}
                    disabled={disabledInput.grid}
                    grids={optionsData.grids}
                  />
                  <br />
                  <SelectProvider
                    providersLinks={providersLinks}
                    setProvidersLinks={setProvidersLinks}
                    disabled={disabledInput.provider}
                  />
                  <br />
                  <br />
                  <br />

                  {/* TABELA DE MEDIDAS */}
                  {/* <MeasurementTable
                    type={type}
                    formik={formik}
                    measurements={measurements}
                    setMeasurements={setMeasurements}
                    disabled={disabledInput.measurementTable}
                  />
                  <br />
                  <br /> */}
                  <Variation
                    alternative_code={formik.values.alternative_code ?? ""}
                    colors={colors}
                    variations={variations}
                    setVariations={setVariations}
                    disabled={disabledInput.variation}
                  />

                  {!disabledInput.files && (
                    <>
                      <br />
                      <div className="field-group">
                        <div style={{ flex: 1, minWidth: 515 }}>
                          <LinkFile
                            files={files}
                            setFiles={setFiles}
                            fileQueryOver={filesCarryOver}
                          />
                          <br />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Files
                            files={files}
                            setFiles={setFiles}
                            fileQueryOver={filesCarryOver}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <ScreenLoading>
                  <Loading
                    colorLoading="#333"
                    size={40}
                    borderColor={"#888"}
                    borderSize={5}
                  />
                </ScreenLoading>
              )}
            </div>

            {disabledButton ? undefined : (
              <div className="containerFormButton">
                <ButtunSubmit style={{ margin: 0 }} type="submit">
                  {loading ? (
                    <div>
                      <Loading borderSize={2} size={20} />
                    </div>
                  ) : type === "create" ? (
                    "Criar Ficha Técnica"
                  ) : (
                    "Editar Ficha Técnica"
                  )}
                </ButtunSubmit>
              </div>
            )}
          </ContainerForm>
        </Form>
      </Container>
    </>
  );
};
