interface generateReportProps {
  reference: string;
  provider: string;
  date: string;
  inspector: string;
  oc: string;
  oc_total_quantity: string;
  observation: string;
  status: string;
  type: string;
  sampling: string;
  variations: { id: number; qtd: number; primary_color: string }[];
  variationsTotal: number;

  defects: { cod: number; qtd: number; description: string }[];
  defectsTotal: number;

  samplingTotal: number;
  nqa_accpet: number;
  nqa_reject: number;
}

export function generateReportQuality(data: generateReportProps) {
  var variationHtml = "";
  data.variations.forEach((variation) => {
    variationHtml += `<div><span class="text">${variation.primary_color}</span> <span class="text">${variation.qtd}</span></div>`;
  });

  var defectsHtml = "";
  data.defects.forEach((defect) => {
    defectsHtml += `
    <div>
      <span class="text">${defect.description}</span>
      <span class="text">${defect.qtd}</span>
    </div>
    `;
  });

  const html = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Relatório de auditoria</title>
    <style>
      body {
        padding: 16px;
        max-width: 700px;
        min-width: 700px;
      }

      h1 {
        font-size: 26px;
      }

      .containerForm {
        border: 2px solid #000;
      }

      .containerForm > header > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        position: relative;
      }
      .imageLogo {
        position: absolute;
        width: 20%;
        max-height: 80%;
        left: 10px;
        object-fit: contain;
      }

      .infoTypes {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 6px;

        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
      }
      .infoTypes > span {
        font-size: 14px;
        font-weight: bold;
      }

      main > div {
        padding: 4px;

        display: flex;
        justify-content: space-between;
      }
      main > div + div {
        border-top: 1px solid #000;
      }

      main > div > span {
        font-size: 12px;
        font-weight: bold;
      }

      .blockDefects {
        border-top: 1px solid #000;
      }
      .blockDefects > header {
        border-bottom: 1px solid #000;
        font-size: 11px;
        font-weight: bold;
        padding: 4px;
        display: flex;
        justify-content: center;
      }
      .blockDefects > div {
        min-height: 120px;
        padding: 4px;
      }

      .variationsColor {
        border-top: 1px solid #000;
      }
      .variationsColor > header {
        border-bottom: 1px solid #000;
        font-size: 12px;
        font-weight: bold;
        padding: 4px;

        display: flex;
        justify-content: center;
      }
      .variationsColor > div {
        display: flex;
        justify-content: space-around;
      }
      .variationsColor > div + div {
        display: flex;
        justify-content: space-around;
        border-top: 1px solid #000;
      }

      .variationsColor > div > span {
        font-size: 11px;
        font-weight: bold;
        flex: 1;
        padding: 4px;
        display: flex;
        justify-content: center;
      }
      .variationsColor > div > span + span {
        border-left: 1px solid #000;
      }

      .observation {
        padding: 4px;
        min-height: 80px;
      }
      .observation > span {
        font-size: 12px;
        font-weight: bold;
      }

      .text {
        font-weight: lighter !important;
        font-size: 10px;
      }
    </style>
  </head>
  <body>
    <div class="containerForm">
      <header>
        <div>
          <img
            class="imageLogo"
            src="https://alpar.sfo3.digitaloceanspaces.com/Alpar/logo-red.png"
            alt="Logo"
          />
          <h1>Relatório de auditoria</h1>
        </div>
        <nav class="infoTypes">
          <span>Inspeção Final ( ${data.type === "1" ? "X" : ""} )</span>
          <span>Reinspeção ( ${data.type === "2" ? "X" : ""} )</span>
          <span>Inspeção em Processos ( ${data.type === "3" ? "X" : ""} )</span>
        </nav>
      </header>
      <main>
        <div>
          <span>AUDITOR: <span class='text'> ${data.inspector}</span></span>
          <span>DATA: <span class='text'> ${data.date}</span></span>
        </div>
        <div>
          <span>FORNECEDOR: <span class='text'> ${data.provider}</span></span>
        </div>
        <div>
          <span>O.C: <span class='text'> ${data.oc}</span></span>
        </div>

        <div>
          <span>REFERÊNCIA: <span class='text'> ${data.reference}</span></span>
        </div>

        <section class="variationsColor">
          <header>VARIANTES DE COR</header>
          <div><span>Código variante</span> <span>Quantidade</span></div>
          ${variationHtml}
          <div><span>-</span> <span>-</span></div>
          <div><span>TOTAL DE PEÇAS:</span> <span>${
            data.variationsTotal
          }</span></div>
          
          <div>
            <span>AMOSTRAGEM</span> 
            <span>ACEITA/NÃO ACEITA</span>
          </div>
          <div>
            <span class='text'>${data.samplingTotal}</span> 
            <span class='text'>${data.nqa_accpet}/${data.nqa_reject}</span>
          </div>
        </section>

        <section class="variationsColor">
          <header>
            IRREGULARIDADES VERIFICADAS - (Logística, Dimensional e Visual)
          </header>
          <div>
             <span>Descrição das Irregularidades</span>
            <span>Quantidade Defeitos</span>
          </div>
            ${defectsHtml}
          <div>
             <span class="text">-</span>
            <span class="text">-</span>
          </div>

          <div>
            <span>TOTAL DE DEFEITOS:</span> <span>${data.defectsTotal}</span>
          </div>
        </section>

        <nav class="infoTypes">
          <span>APROVADO ( ${data.status === "1" ? "X" : ""} )</span>
          <span>REPROVADO ( ${data.status === "3" ? "X" : ""} )</span>
        </nav>

        <section class="observation">
          <span>Observações Gerais: <span class='text'> ${
            data.observation
          }</span></span>
        </section>
      </main>
    </div>
  </body>
</html>

`;

  return html;
}
