import React, { memo } from "react";
import { FiAlertCircle } from "react-icons/fi";
import {
  Error as ContainerError,
  InputColor as InputColorCompoenet,
} from "./styles";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: string;
}

const InputColorComponent: React.FC<InputProps> = ({
  label,
  name,
  error,
  ...rest
}) => {
  const restAny = rest as any;

  return (
    <div className="field" style={{ position: "relative" }}>
      {label && <label htmlFor={name}>{label}</label>}

      <input id={name} name={name} {...restAny} />

      <InputColorCompoenet type="color" id={name} name={name} {...restAny} />

      {!!error && (
        <ContainerError>
          <FiAlertCircle color="#f00" size={16} />
          <span> {error} </span>
        </ContainerError>
      )}
    </div>
  );
};
export const InputColor = memo(InputColorComponent);
