import styled from "styled-components";

interface ContainerCheckProps {
  disabled?: boolean;
}

export const ContainerCheck = styled.div<ContainerCheckProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 40px;

  border: 2px solid #d21e26;
  border-radius: 4px;
  overflow: hidden;

  button {
    border-radius: 0px;
    flex: 1;

    background: #fff;
    font-weight: bold;
    color: #333;
  }

  button:first-child {
    border-right: 2px solid #d21e26;
  }

  button:last-child {
    border-left: 2px solid #d21e26;
  }

  button:hover {
    opacity: 1;
  }

  .check {
    background: #d21e26;
    color: #fff;
  }
`;

export const Error = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 2px;
  span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #f00;
  }
`;
