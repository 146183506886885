import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import { IPermissionUser } from "../../../@types";
import api from "../../../service/api";
import {
  ButtunSubmit,
  Container,
  ContainerCheck,
  ContainerForm,
  Error,
  Form,
} from "../styles";
import { SelectPermission } from "./SelectPermission";
import { SelectRequired } from "./SelectRequired";
import { SelectStatus } from "./SelectStatus";
import { SelectUser, UserProps } from "./SelectUser";

interface HomeProps {
  location: {
    state: {
      brand: StatusDbProps;
    };
  };
  permission: IPermissionUser;
}

export interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  is_visible_provider: boolean;
  finisher: boolean;
  is_active: boolean;
  is_generate_barcode: boolean;
  is_generate_barcode_sample: boolean;
  is_generate_book: boolean;
}

export interface StatusDbProps {
  id?: number;
  cod: number;
  name: string;
  color: string;
  is_visible_provider: boolean;
  finisher: boolean;
  is_active: boolean;
  is_generate_barcode: boolean;
  is_generate_barcode_sample: boolean;
  is_generate_book: boolean;

  responsibles: { user: UserProps }[];
  users: { user: UserProps }[];
  next_status: IStatus[];
  permission_fields: {
    name: string;
    description: string;
  }[];
  required_fields: {
    id: number;

    field: {
      id: number;
      name: string;
      description: string;
    };
  }[];
}

const StatusDatasheet: React.FC<HomeProps> = ({ location, permission }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [responsibles, setResponsibles] = useState<UserProps[]>([]);
  const [users, setUsers] = useState<UserProps[]>([]);

  const [statusDatasheet, setStatusDatasheet] = useState<IStatus[]>([]);

  const [permissionFields, setPermissionFields] = useState<any>({});
  const [requeredFields, setRequeredFields] = useState<any>({});

  const {
    id,
    cod,
    name,
    color,
    finisher,
    is_active,
    is_visible_provider,
    is_generate_barcode,

    is_generate_barcode_sample,
    is_generate_book,

    responsibles: responsiblesData,
    users: usersData,
    next_status: next_statusData,
    permission_fields,
    required_fields,
  } = location.state.brand;

  useEffect(() => {
    setResponsibles(responsiblesData.map((item) => item.user));
    setUsers(usersData.map((item) => item.user));
    setStatusDatasheet(next_statusData);
  }, [responsiblesData, usersData, next_statusData]);

  async function handleSubmit(updates: any) {
    try {
      let permissions = "";
      const arrPerm = Object.getOwnPropertyNames(permissionFields);
      for (const itemBrand of arrPerm) {
        if (permissionFields[itemBrand]) permissions += `${itemBrand},`;
      }
      let requered = "";
      const arrRequ = Object.getOwnPropertyNames(requeredFields);
      for (const itemBrand of arrRequ) {
        if (requeredFields[itemBrand]) requered += `${itemBrand},`;
      }

      await api.put(`/headerDatasheet/datasheet/status/${updates.id}`, {
        ...updates,
        responsibles: responsibles.map((item) => ({ id: item.id })),
        users: users.map((item) => ({ id: item.id })),
        next_status: statusDatasheet.map((item) => ({ id: item.id })),
        permission_fields: permissions,
        requered_fields: requered,
      });
      addToast("Status de Indenização alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/status/fichas-tecnicas");
    } catch (err) {
      console.log(err);

      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.data.error === "cannot be changed") {
        return addToast("Status não pode ser alterado", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este e-mail já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>
        <h2>Status da Coleção da Ficha Técnica</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            id: id,
            cod: cod,
            name: name,
            color: color,
            finisher: finisher,
            is_active: is_active,
            is_generate_barcode: is_generate_barcode,
            is_visible_provider: is_visible_provider,
            is_generate_barcode_sample: is_generate_barcode_sample,
            is_generate_book: is_generate_book,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Nome é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form
              onSubmit={
                permission && permission.config && permission.config > 2
                  ? formik.handleSubmit
                  : () => {}
              }
            >
              <div className="field">
                <label htmlFor="name">Situação</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.is_active ? "check" : ""}
                    onClick={() => formik.setFieldValue("is_active", true)}
                  >
                    Ativo
                  </button>

                  <button
                    type="button"
                    className={!formik.values.is_active ? "check" : ""}
                    onClick={() => formik.setFieldValue("is_active", false)}
                  >
                    Inativo
                  </button>
                </ContainerCheck>
              </div>

              <div className="field">
                <label htmlFor="COD">COD</label>
                <input
                  type="text"
                  className="id"
                  {...formik.getFieldProps("cod")}
                  readOnly={true}
                />
              </div>
              <div className="field">
                <label htmlFor="name">Descrição</label>
                <input id="name" {...formik.getFieldProps("name")} />
                {formik.touched.name && formik.errors.name ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.name} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="color">Cor de fundo</label>
                <input id="color" {...formik.getFieldProps("color")} />
                {formik.touched.color && formik.errors.color ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.color} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field-group">
                <SelectPermission
                  permissionFields={permissionFields}
                  setPermissionFields={setPermissionFields}
                  permissionFieldsAllowed={permission_fields.map(
                    (permission) => ({ name: permission.name })
                  )}
                />
                <SelectRequired
                  permissionFields={requeredFields}
                  setPermissionFields={setRequeredFields}
                  permissionFieldsAllowed={required_fields.map(
                    (permission) => ({
                      name: permission.field.name,
                    })
                  )}
                />
              </div>

              <div className="field-group">
                <div className="field">
                  <SelectUser
                    title="Utilizadores"
                    setUsersLinks={setUsers}
                    usersLinks={users}
                  />
                </div>
                <div className="field">
                  <SelectUser
                    title="Responsáveis"
                    setUsersLinks={setResponsibles}
                    usersLinks={responsibles}
                  />
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="name">Visualização fornecedor</label>
                  <ContainerCheck>
                    <button
                      type="button"
                      className={
                        formik.values.is_visible_provider ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue("is_visible_provider", true)
                      }
                    >
                      SIM
                    </button>

                    <button
                      type="button"
                      className={
                        !formik.values.is_visible_provider ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue("is_visible_provider", false)
                      }
                    >
                      NÃO
                    </button>
                  </ContainerCheck>
                </div>
                <div className="field">
                  <label htmlFor="name">Gera Book Técnico</label>
                  <ContainerCheck>
                    <button
                      type="button"
                      className={formik.values.is_generate_book ? "check" : ""}
                      onClick={() =>
                        formik.setFieldValue("is_generate_book", true)
                      }
                    >
                      SIM
                    </button>

                    <button
                      type="button"
                      className={!formik.values.is_generate_book ? "check" : ""}
                      onClick={() =>
                        formik.setFieldValue("is_generate_book", false)
                      }
                    >
                      NÃO
                    </button>
                  </ContainerCheck>
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="name">Gera EAN</label>
                  <ContainerCheck>
                    <button
                      type="button"
                      className={
                        formik.values.is_generate_barcode ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue("is_generate_barcode", true)
                      }
                    >
                      SIM
                    </button>

                    <button
                      type="button"
                      className={
                        !formik.values.is_generate_barcode ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue("is_generate_barcode", false)
                      }
                    >
                      NÃO
                    </button>
                  </ContainerCheck>
                </div>
                <div className="field">
                  <label htmlFor="name">Gera Amostra EAN </label>
                  <ContainerCheck>
                    <button
                      type="button"
                      className={
                        formik.values.is_generate_barcode_sample ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue("is_generate_barcode_sample", true)
                      }
                    >
                      SIM
                    </button>

                    <button
                      type="button"
                      className={
                        !formik.values.is_generate_barcode_sample ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue(
                          "is_generate_barcode_sample",
                          false
                        )
                      }
                    >
                      NÃO
                    </button>
                  </ContainerCheck>
                </div>
              </div>

              <div className="field">
                <label htmlFor="name">Finalização</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.finisher ? "check" : ""}
                    onClick={() => formik.setFieldValue("finisher", true)}
                  >
                    SIM
                  </button>

                  <button
                    type="button"
                    className={!formik.values.finisher ? "check" : ""}
                    onClick={() => formik.setFieldValue("finisher", false)}
                  >
                    NÃO
                  </button>
                </ContainerCheck>
              </div>

              <div className="field">
                <SelectStatus
                  title="Proximas situações"
                  setUsersLinks={setStatusDatasheet}
                  usersLinks={statusDatasheet}
                />
              </div>

              {permission && permission.config && permission.config > 2 && (
                <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
              )}
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default StatusDatasheet;
