import React from "react";
import { IDevolution } from ".";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { FieldProps } from "../../../components/panel/table/TableContent";

type Props = {
  columns: FieldProps<IDevolution>[];
  formikFilter: any;

  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  isVisible: boolean;

  filters: DevolutionFilterProps;
  setFilters: (data: DevolutionFilterProps) => void;

  is_admin?: boolean;
  is_client?: boolean;
  is_provider?: boolean;
  is_seller?: boolean;
  is_manager?: boolean;

  filterOptions: {
    brands: {
      id: number;
      cod: number;
      name: string;
    }[];
    status: {
      cod: number;
      name: string;
    }[];
    users: {
      id?: string;
      name?: string;
      lastname?: string;
    }[];
    shippingCompanies: {
      id: number;
      cod: number;
      fullname: string;
    }[];
    listPermission: {
      id: number;
      name: string;
    }[];
  };
};

export interface DevolutionFilterProps {
  protocol?: string;
  requestType?: string;
  status?: string;
  notificationUser?: number;
  refusal?: number | string;
  finisher?: number | string;
  blockClient?: number | string;
  blockClientAction?: number | string;
  pendencyType?: number | string;
  pendency?: number | string;
  shippingCompany?: number | string;

  codSalesman?: number;

  codClient?: number;
  cnpj?: string;
  company_name?: string;

  numberInvoice?: string;
  responsible?: string;
  brand?: string;

  creationPeriod?: number;
  presetDate?: number;
  specificDate?: Date;
  startDate?: Date;
  endDate?: Date;

  finisherPeriod?: number;
  presetDateFinisher?: number;
  specificDateFinisher?: Date;
  startDateFinisher?: Date;
  endDateFinisher?: Date;
}

const pendencyType = [
  { id: 0, name: " Sem ator" },
  { id: 1, name: " Cliente" },
  { id: 2, name: " Operador" },
  { id: 3, name: " Representante" },
  { id: 4, name: " Gerente" },
];

export function DevolutionFilter({
  filters,
  setFilters,
  columns,
  formikFilter,
  setIsVisible,
  isVisible,
  filterOptions,
  is_client,
  is_admin,
  is_seller,
  is_provider,
}: Props) {
  function selectDescription(st: any, title: string) {
    let data = String(st);
    const arrTitle = [
      "brand",
      "status",
      "finisher",
      "refusal",
      "notificationUser",
      "shippingCompany",
      "blockClient",
      "blockClientAction",
      "presetDate",
      "creationPeriod",
      "finisherPeriod",
      "presetDateFinisher",
      "pendencyType",
      "pendency",
    ];
    if (arrTitle.includes(title)) {
      switch (title) {
        case "creationPeriod":
          if (Number(st) === 1) {
            data = "Predefinida";
          }
          if (Number(st) === 2) {
            data = "Específica";
          }
          if (Number(st) === 3) {
            data = "Intevalo";
          }

          break;
        case "presetDate":
          if (Number(st) === 0) {
            data = "Hoje";
          }
          if (Number(st) === 2) {
            data = "Ontem";
          }
          if (Number(st) === 7) {
            data = "Últimos 7 dias";
          }
          if (Number(st) === 15) {
            data = "Últimos 15 dias";
          }
          if (Number(st) === 30) {
            data = "Últimos 30 dias";
          }

          break;
        case "finisherPeriod":
          if (Number(st) === 1) {
            data = "Predefinida";
          }
          if (Number(st) === 2) {
            data = "Específica";
          }
          if (Number(st) === 3) {
            data = "Intevalo";
          }

          break;
        case "presetDateFinisher":
          if (Number(st) === 0) {
            data = "Hoje";
          }
          if (Number(st) === 2) {
            data = "Ontem";
          }
          if (Number(st) === 7) {
            data = "Últimos 7 dias";
          }
          if (Number(st) === 15) {
            data = "Últimos 15 dias";
          }
          if (Number(st) === 30) {
            data = "Últimos 30 dias";
          }

          break;
        case "brand":
          data =
            filterOptions.brands.find((b) => Number(b.cod) === Number(st))
              ?.name ?? st;

          break;
        case "status":
          data =
            filterOptions.status.find((b) => Number(b.cod) === Number(st))
              ?.name ?? st;
          break;
        case "finisher":
          data = Number(st) >= 1 ? "Finalizado" : "Em andamento";
          break;
        case "refusal":
          data = Number(st) >= 1 ? "Sim" : "Não";
          break;
        case "notificationUser":
          data = Number(st) >= 1 ? "Sim" : "Não";
          break;
        case "blockClient":
          data = Number(st) >= 1 ? "Sim" : "Não";
          break;
        case "blockClientAction":
          data = Number(st) >= 1 ? "Sim" : "Não";
          break;
        case "shippingCompany":
          data =
            filterOptions.shippingCompanies.find(
              (b) => Number(b.id) === Number(st)
            )?.fullname ?? st;
          break;
        case "pendencyType":
          data =
            pendencyType.find((b) => Number(b.id) === Number(st))?.name ?? st;
          break;
        case "pendency":
          data =
            filterOptions.listPermission.find(
              (b) => Number(b.id) === Number(st)
            )?.name ?? st;
          break;
      }
    }

    return data;
  }

  return (
    <PanelFilter
      filters={filters}
      setFilters={setFilters}
      columns={columns}
      onSubmit={formikFilter.handleSubmit}
      onResetForm={() => formikFilter.handleReset(formikFilter)}
      setViewFilter={setIsVisible}
      viewFilter={isVisible}
      normalizedFilters={selectDescription}
    >
      <GroupInput>
        <Input label="PROTOCOLO" {...formikFilter.getFieldProps("protocol")} />
        <Input label="NF" {...formikFilter.getFieldProps("numberInvoice")} />
        {!is_client && !is_provider && (
          <Input
            label="CÓD. REPRESENTANTE"
            {...formikFilter.getFieldProps("codSalesman")}
          />
        )}
        <Input
          label="GERENTE"
          {...formikFilter.getFieldProps("brandManager")}
        />
      </GroupInput>

      {!is_seller && (
        <Select
          label="MARCA"
          {...formikFilter.getFieldProps("brand")}
          data={filterOptions.brands.map((brand) => ({
            name: brand.name,
            value: brand.cod,
          }))}
        />
      )}

      {!is_client && !is_provider && (
        <GroupInput>
          <Input
            label="CÓD. CLIENTE"
            {...formikFilter.getFieldProps("codClient")}
          />
          <Input label="CNPJ" {...formikFilter.getFieldProps("cnpj")} />
          <Input
            label="RAZÃO SOCIAL"
            {...formikFilter.getFieldProps("company_name")}
          />
        </GroupInput>
      )}

      <GroupInput>
        <Select
          label="STATUS"
          {...formikFilter.getFieldProps("status")}
          data={filterOptions.status.map((s) => ({
            name: s.name,
            value: s.cod,
          }))}
        />

        {!is_client && !is_provider && (
          <>
            <Select
              label="TIPO STATUS"
              {...formikFilter.getFieldProps("finisher")}
              data={[
                {
                  name: "Finalizado",
                  value: 1,
                },
                {
                  name: "Em andamento",
                  value: 0,
                },
              ]}
            />
            {is_admin && (
              <Select
                label="RESPONSÁVEL"
                {...formikFilter.getFieldProps("responsible")}
                data={filterOptions.users.map((user) => ({
                  name: `${user.name} ${user.lastname}`,
                  value: `${user.name} ${user.lastname}`,
                }))}
              />
            )}
          </>
        )}
      </GroupInput>

      {!is_client && !is_provider && (
        <GroupInput>
          <Select
            label="RECUSA"
            {...formikFilter.getFieldProps("refusal")}
            data={[
              {
                name: "Sim",
                value: 1,
              },
              {
                name: "Não",
                value: 0,
              },
            ]}
          />
          <Select
            label="AÇÃO CLIENTES"
            {...formikFilter.getFieldProps("notificationUser")}
            data={[
              {
                name: "Sim",
                value: 1,
              },
              {
                name: "Não",
                value: 0,
              },
            ]}
          />
          <Select
            label="FORNECEDORES"
            {...formikFilter.getFieldProps("shippingCompany")}
            data={filterOptions.shippingCompanies.map((shipping) => ({
              name: `${shipping?.cod ?? `-`} ${shipping?.fullname}`,
              value: shipping.id,
            }))}
          />
        </GroupInput>
      )}

      {!is_client && !is_provider && (
        <GroupInput>
          <Select
            label="CLIENTES BLOQUEADOS"
            {...formikFilter.getFieldProps("blockClient")}
            data={[
              {
                name: "Sim",
                value: 1,
              },
              {
                name: "Não",
                value: 0,
              },
            ]}
          />
          <Select
            label="CLIENTES BLOQUEADOS NO ERP"
            {...formikFilter.getFieldProps("blockClientAction")}
            data={[
              {
                name: "Sim",
                value: 1,
              },
              {
                name: "Não",
                value: 0,
              },
            ]}
          />
        </GroupInput>
      )}

      {!is_client && !is_provider && (
        <GroupInput>
          <Select
            label="PENDÊNCIA POR TIPO DE USUÁRIO"
            {...formikFilter.getFieldProps("pendencyType")}
            data={pendencyType.map((pendency) => ({
              name: pendency.name,
              value: pendency.id,
            }))}
          />

          {Number(formikFilter.values.pendencyType) === 2 && (
            <Select
              label="PENDÊNCIA POR PERMISSIONAMENTO DE USUÁRIO"
              {...formikFilter.getFieldProps("pendency")}
              data={filterOptions.listPermission.map((permission) => ({
                name: permission.name,
                value: permission.id,
              }))}
            />
          )}
        </GroupInput>
      )}

      <Select
        label="PERÍODO DE CRIAÇÃO"
        data={[
          { name: "Predefinida", value: 1 },
          { name: "Específica", value: 2 },
          { name: "Intervalo", value: 3 },
        ]}
        {...formikFilter.getFieldProps("creationPeriod")}
      />

      {Number(formikFilter.values.creationPeriod) === 1 && (
        <Select
          label="DATA PREDEFINIDA"
          data={[
            { name: "Hoje", value: 0 },
            { name: "Ontem", value: 1 },
            { name: "Últimos 7 dias", value: 7 },
            { name: "Últimos 15 dias", value: 15 },
            { name: "Últimos 30 dias", value: 30 },
          ]}
          {...formikFilter.getFieldProps("presetDate")}
          error={
            formikFilter.touched.presetDate && formikFilter.errors.presetDate
              ? formikFilter.errors.presetDate
              : undefined
          }
        />
      )}
      {Number(formikFilter.values.creationPeriod) === 2 && (
        <Input
          type="date"
          label="DATA DO PEDIDO"
          {...formikFilter.getFieldProps("specificDate")}
          error={
            formikFilter.touched.specificDate &&
            formikFilter.errors.specificDate
              ? formikFilter.errors.specificDate
              : undefined
          }
        />
      )}
      {Number(formikFilter.values.creationPeriod) === 3 && (
        <GroupInput>
          <Input
            type="date"
            label="DATA INICIAL"
            {...formikFilter.getFieldProps("startDate")}
            error={
              formikFilter.touched.startDate && formikFilter.errors.startDate
                ? formikFilter.errors.startDate
                : undefined
            }
          />
          <Input
            type="date"
            label="DATA FINAL"
            {...formikFilter.getFieldProps("endDate")}
            error={
              formikFilter.touched.endDate && formikFilter.errors.endDate
                ? formikFilter.errors.endDate
                : undefined
            }
          />
        </GroupInput>
      )}

      {is_admin && (
        <>
          <Select
            label="PERÍODO DE FINALIZAÇÃO"
            data={[
              { name: "Predefinida", value: 1 },
              { name: "Específica", value: 2 },
              { name: "Intervalo", value: 3 },
            ]}
            {...formikFilter.getFieldProps("finisherPeriod")}
          />

          {Number(formikFilter.values.finisherPeriod) === 1 && (
            <Select
              label="DATA PREDEFINIDA"
              data={[
                { name: "Hoje", value: 0 },
                { name: "Ontem", value: 1 },
                { name: "Últimos 7 dias", value: 7 },
                { name: "Últimos 15 dias", value: 15 },
                { name: "Últimos 30 dias", value: 30 },
              ]}
              {...formikFilter.getFieldProps("presetDateFinisher")}
              error={
                formikFilter.touched.presetDateFinisher &&
                formikFilter.errors.presetDateFinisher
                  ? formikFilter.errors.presetDateFinisher
                  : undefined
              }
            />
          )}
          {Number(formikFilter.values.finisherPeriod) === 2 && (
            <Input
              type="date"
              label="DATA DO PEDIDO"
              {...formikFilter.getFieldProps("specificDateFinisher")}
              error={
                formikFilter.touched.specificDateFinisher &&
                formikFilter.errors.specificDateFinisher
                  ? formikFilter.errors.specificDateFinisher
                  : undefined
              }
            />
          )}
          {Number(formikFilter.values.finisherPeriod) === 3 && (
            <GroupInput>
              <Input
                type="date"
                label="DATA INICIAL"
                {...formikFilter.getFieldProps("startDateFinisher")}
                error={
                  formikFilter.touched.startDateFinisher &&
                  formikFilter.errors.startDateFinisher
                    ? formikFilter.errors.startDateFinisher
                    : undefined
                }
              />
              <Input
                type="date"
                label="DATA FINAL"
                {...formikFilter.getFieldProps("endDateFinisher")}
                error={
                  formikFilter.touched.endDateFinisher &&
                  formikFilter.errors.endDateFinisher
                    ? formikFilter.errors.endDateFinisher
                    : undefined
                }
              />
            </GroupInput>
          )}
        </>
      )}
    </PanelFilter>
  );
}
