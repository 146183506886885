import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  cursor: pointer;

  .pre-filter-title {
    display: flex;
    align-items: center;
    justify-content: end;

    span {
      margin-right: 6px;
      color: #555;
      font-size: 0.9rem;
    }

    .pre-filter-title-arrow {
      background-color: #aaa;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 2px;

      transition: 0.4s;
      transform: rotate(0deg);
    }

    .pre-filter-title-arrow-active {
      background-color: #aaa;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 2px;

      transition: 0.4s;
      transform: rotate(180deg);
    }
  }

  .pre-filter-option {
    animation: fadeout 0.4s;

    @keyframes fadeout {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.59);

    position: absolute;
    top: 30px;
    right: -10px;
    width: 230px;
    border-radius: 5px;

    background-color: #fff;
    overflow: hidden;
    list-style: none;
    z-index: 5;

    li {
      display: flex;
      align-items: center;
      transition: all 0.3s;

      padding: 8px 15px;
      width: 100%;

      border-bottom: 1px solid #bbb;

      &:last-child {
        border-bottom: none;
      }

      .icon-execel {
        margin-right: 8px;
      }

      span {
        font-size: 14px;
      }
    }

    li:hover {
      background-color: #ccc;
    }
  }
`;
