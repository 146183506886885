import React from "react";

import bcrypt from "bcryptjs";
import * as Yup from "yup";
import { Formik, Field } from "formik";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import api from "../../service/api";
import * as clientStorage from "../../service/localStorage/client";
import * as sellerStorage from "../../service/localStorage/seller";
import * as providerStorage from "../../service/localStorage/provider";

import { FiAlertCircle } from "react-icons/fi";
import { Container, Form, ContainerForm, ButtunSubmit, Error } from "./styles";

interface Client {
  id?: string;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;

  acess_client: {
    password: string;
  };
}

interface Seller {
  cod: Number;
  id: Number;
  abbreviation: string;
  fullName: string;
  email: string;
  phone: string;

  acess_seller: {
    password: string;
  };
}

interface Provider {
  id: Number;

  acess_provider: {
    email: string;
    password: string;
  };
}

interface IPassword {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

interface HeaderProps {
  admin: boolean;
  client: boolean;
  seller: boolean;
  provider: boolean;
}

const Security: React.FC<HeaderProps> = ({
  admin,
  client,
  seller,
  provider,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();

  async function hadleSubmit(pass: IPassword) {
    if (admin) {
      const verifyPass = await api.post("/user/verifyPass", {
        oldPassword: pass.oldPassword,
      });

      if (verifyPass.data)
        return addToast("Senha atual não corresponde", {
          appearance: "warning",
          autoDismiss: true,
        });

      try {
        await api.put(`/user/profile/`, { password: pass.password });
        addToast("Alterção de senha realizada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/admin/home");
      } catch (error) {
        console.log(error);
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
    if (client) {
      const clientData = (await clientStorage.getUser()) as Client;
      const clientDB = await api.get<Client>(`/clientconfig/${clientData.id}`);

      const { password } = clientDB.data.acess_client;

      if (!(await bcrypt.compare(pass.oldPassword, password)))
        return addToast("Senha atual não corresponde", {
          appearance: "warning",
          autoDismiss: true,
        });

      try {
        await api.put(`/clientconfig/${clientData.id}`, {
          password: pass.password,
        });
        addToast("Alterção de senha realizada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/home");
      } catch (error) {
        console.log(error);
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
    if (seller) {
      const sellerData = (await sellerStorage.getUser()) as Client;
      const sellerDB = await api.get<Seller>(`/sellerconfig/${sellerData.id}`);

      const { password } = sellerDB.data.acess_seller;

      if (!(await bcrypt.compare(pass.oldPassword, password)))
        return addToast("Senha atual não corresponde", {
          appearance: "warning",
          autoDismiss: true,
        });

      try {
        await api.put(`/sellerconfig/${sellerData.id}`, {
          password: pass.password,
        });
        addToast("Alterção de senha realizada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/representante");
      } catch (error) {
        console.log(error);
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
    if (provider) {
      const providerData = (await providerStorage.getUser()) as Provider;
      const providerDB = await api.get<Provider>(
        `/provider/config/${providerData.id}`
      );

      const { password } = providerDB.data.acess_provider;

      if (!(await bcrypt.compare(pass.oldPassword, password)))
        return addToast("Senha atual não corresponde", {
          appearance: "warning",
          autoDismiss: true,
        });

      try {
        await api.put(`/provider/config/${providerData.id}`, {
          password: pass.password,
        });
        addToast("Alterção de senha realizada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
        history.push("/fornecedor");
      } catch (error) {
        console.log(error);
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    }
  }

  return (
    <Container>
      <header>
        <h2>Segurança</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            oldPassword: "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={Yup.object({
            oldPassword: Yup.string().required("Senha Atual é obrigatoria"),
            password: Yup.string()
              .required("Nova senha é obrigatoria")
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "A senha precisa conter letrar e numeros, no mínimo 8 caracteres e um caracter especial."
              ),
            confirmPassword: Yup.string()
              .oneOf([Yup.ref("password")], "As senhas precisam ser iguais ")
              .required("Confirma nova senha é obrigatoria"),
          })}
          onSubmit={(data) => hadleSubmit(data)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="oldPassword">Digite sua senha atual</label>
                <Field type="password" name="oldPassword" />
                {formik.touched.oldPassword && formik.errors.oldPassword ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.oldPassword} </span>
                  </Error>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="password">Nova senha</label>
                <Field type="password" name="password" />
                {formik.touched.password && formik.errors.password ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.password} </span>
                  </Error>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="confirmPassword">Confirme sua nova senha</label>
                <Field type="password" name="confirmPassword" />
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.confirmPassword} </span>
                  </Error>
                ) : null}
              </div>

              <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default Security;
