import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { IPermissionUser } from "../../../@types";

import { Search } from "../../../components/Search";
import LoadingDot from "../../../components/loadings/LoadingDot";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import { ContainerLoadingPanel } from "../../../components/panel/table/TableContent/styles";

import { useShowFiles } from "../../../hook/queries/useShowFile";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { PainelVideos } from "../styles";

interface HeaderProps {
  admin: boolean;
  seller: boolean;
  permission: IPermissionUser;
}

const Panel: React.FC<HeaderProps> = ({ admin, permission }) => {
  const history = useHistory();
  const query = useQueryParams();

  const [search, setSearch] = useState(() => {
    const searchQuery = query.get("search");

    if (searchQuery && searchQuery.length > 0) {
      return String(searchQuery);
    }

    return "";
  });
  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });
  const { data, isLoading } = useShowFiles(page, search, 10);

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }

    if (search.length > 0) {
      setQueryParams({
        data: {
          field: "search",
          value: String(search),
        },
        type: "set",
        history,
      });
    } else {
      setQueryParams({
        data: {
          field: "search",
          value: String(search),
        },
        type: "delete",
        history,
      });
    }
  }, [page, history, search]);

  return (
    <>
      <PanelLayout>
        <PanelAndDetailAndCreateHeader title="Lista de Tutoriais">
          {admin && permission && permission.showFile > 2 && (
            <button
              onClick={() => {
                history.push("/admin/criar/arquivo");
              }}
              type="button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "1rem",
                background: "#d21e26",
                color: "white",
                borderRadius: 6,
                padding: 6,
              }}
            >
              <MdAdd size={22} /> <span> NOVO </span>
            </button>
          )}
        </PanelAndDetailAndCreateHeader>

        <Search
          handleSearch={(search) => {
            setPage(1);
            setSearch(search);
          }}
          currentSearch={search}
        />

        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          {!isLoading ? (
            <PainelVideos>
              {data?.showFiles.map((file) => (
                <button
                  key={file.id}
                  type="button"
                  onClick={() => history.push(`arquivo/${file.id}`)}
                  style={{
                    background: "#fff",
                    borderRadius: 6,
                    overflow: "hidden",
                    maxWidth: 350,
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={`https://img.youtube.com/vi/${file.keyVideo}/sddefault.jpg`}
                    alt={file.name}
                    style={{ width: "100%" }}
                  />

                  <div
                    style={{
                      textAlign: "start",
                      padding: "1rem",
                    }}
                  >
                    <p
                      style={{
                        color: "#333",
                        fontSize: "1.2rem",
                      }}
                    >
                      {file.name}
                    </p>
                    <span
                      style={{
                        color: "#555",
                        fontSize: "0.875rem",
                        wordBreak: "break-all",
                        width: "100%",
                      }}
                    >
                      {file.description}
                    </span>
                  </div>
                </button>
              ))}
            </PainelVideos>
          ) : (
            <ContainerLoadingPanel>
              <LoadingDot />
            </ContainerLoadingPanel>
          )}
        </div>

        <Pagination
          totalCountOfRegisters={data?.totalCount ?? 0}
          currentPage={page}
          onPageChange={setPage}
          registersPerPage={10}
        />
      </PanelLayout>
    </>
  );
};

export default Panel;
