export default function logout() {
  const locaisStorage = [
    "@key-User",
    "@key-Client",
    "@key-Seller",
    "@Key-Provider",
    "@Data-client",
    "@Data-Provider",
    "@Data-seller",
    "@Permission-user",
    "@Data-user",
  ];

  for (const storage of locaisStorage) {
    localStorage.removeItem(storage);
  }
}
