import React, { memo } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { Field } from "../Input/styles";
import { Error as ContainerError } from "./styles";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  error?: string;
  requiredOn?: boolean;
}

const InputComponent: React.FC<InputProps> = ({
  label,
  name,
  error,
  requiredOn,
  ...rest
}) => {
  const restAny = rest as any;

  return (
    <Field>
      {label && <label htmlFor={name}>{label}</label>}

      <textarea
        id={name}
        name={name}
        {...restAny}
        style={{
          border: requiredOn ? "2px solid #d21e26" : undefined,
        }}
      />

      {!!error && (
        <ContainerError>
          <FiAlertCircle color="#f00" size={16} />
          <span> {error} </span>
        </ContainerError>
      )}
    </Field>
  );
};

export const Textarea = memo(InputComponent);
