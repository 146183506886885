import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import api from "../../service/api";

import * as authSeller from "../../service/authSeller";
import * as sellerStorage from "../../service/localStorage/seller";

import LoadingDot from "../../components/loadings/LoadingDot";
import {
  Container,
  ContainerList,
  FooterContainer,
  LoadingPanel,
  TableContainer,
} from "./styles";

import { BiLogInCircle } from "react-icons/bi";

import { useToasts } from "react-toast-notifications";
import { Seller } from "../../hook/queries/useSeller";
import logout from "../../utils/logout";
import { ResponseSingInSeller } from "../Login";

const TeamSeller: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const sellerData = sellerStorage.getUser() as sellerStorage.SellerProps;

  const [contents, setContents] = useState<Seller[]>([]);
  const [loading, setLoading] = useState(true);

  async function handleAccess(sellerId: number) {
    try {
      const responseVerify = await api.post<ResponseSingInSeller>(
        `/sellerconfig/sign-team/${sellerId}`
      );

      const { seller, token } = responseVerify.data;

      const {
        id,
        email,
        cod,
        abbreviation,
        fullName,
        is_manager,
        is_supervisor,
        isSankhya,
        brands,
      } = seller;

      logout();

      sellerStorage.setUser({
        id,
        email,
        cod,
        abbreviation,
        fullName,
        is_manager,
        is_supervisor,
        isSankhya,
        brands,
        sellerTeamOn: true,
      });

      const { access_token, exp, iat } = token;
      authSeller.setToken({
        access_token,
        exp,
        iat,
      });

      history.push(`/representante/home`);
      window.location.reload();
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  useEffect(() => {
    (async () => {
      const response = await api.get("/seller/team");

      setContents(response.data.contents);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!sellerData?.sellerTeamOn) {
      history.push("/representante");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <header>
        <h2>Equipe de vendas</h2>
      </header>

      <ContainerList>
        <div className="headerTable">
          <h3>Representante</h3>
        </div>

        <TableContainer>
          {!loading ? (
            <table>
              <thead>
                <tr>
                  <th>CÓDIGO</th>
                  <th>APELIDO</th>
                  <th>NOME</th>
                  <th>MARCA</th>
                  <th>E-MAIL</th>
                  <th>AÇÃO</th>
                </tr>
              </thead>

              <tbody>
                {contents
                  .filter((f) => f.id !== sellerData.id)
                  .map((element) => (
                    <tr
                      key={element.id}
                      style={{ cursor: "auto", textAlign: "start" }}
                    >
                      <td>{element.cod}</td>
                      <td>{element.abbreviation}</td>
                      <td>{element.fullName}</td>
                      <td>
                        {element.brands.map((brand) => brand.name).join(", ")}
                      </td>
                      <td>{element.email}</td>
                      <td className="accessButton" style={{ width: 150 }}>
                        <button
                          onClick={() => {
                            if (Number(sellerData.id) !== Number(element.id)) {
                              handleAccess(element.id);
                            }
                          }}
                        >
                          {Number(sellerData.id) !== Number(element.id) && (
                            <>
                              <span>ACESSAR</span>
                              <BiLogInCircle />
                            </>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>

        <FooterContainer />
      </ContainerList>
    </Container>
  );
};

export default TeamSeller;
