export interface Client {
  id?: string;
  cod?: number;
  cnpj?: string;
  company_name?: string;
  trade_name?: string;
  email?: string;
}

export const STORAGE = "@Data-client";

export const getUser = () => {
  const user = localStorage.getItem(STORAGE);

  return JSON.parse(user as string);
};

export const setUser = (user: Client) => {
  localStorage.setItem(STORAGE, JSON.stringify(user));
};

export const removeUser = () => {
  localStorage.removeItem(STORAGE);
};
