import { AxiosError } from "axios";
import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import Modal from "../../../../../../components/ModalLib";
import { TableClean } from "../../../../../../components/TableClean";
import api from "../../../../../../service/api";
import { Container } from "./style";

interface ModalVariationProps {
  data: any;

  admin?: boolean;

  setUpdate?: () => void;

  modalVisible: boolean;
  setModalVisible: (value: React.SetStateAction<boolean>) => void;
}

export const ModalVariation: React.FC<ModalVariationProps> = ({
  modalVisible,
  setModalVisible,
  data,
  setUpdate,
  admin,
}) => {
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);

  async function handleGenerateBarcode(e: any) {
    setLoading(true);
    addToast("Gerando EANs, esta ação pode levar alguns instantes.", {
      appearance: "info",
      autoDismiss: true,
    });

    try {
      await api.post(`/headerDatasheet/datasheet/generate/barcodeOne/${e.id}`);

      if (setUpdate) setUpdate();

      addToast("EANs da Ficha Técnica gerados com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      const err = error as AxiosError;

      if (
        err.response?.status === 400 &&
        err.response.data?.message === "Erro ao gerar código de barras"
      ) {
        let errorMessage = err.response.data?.info
          ? err.response.data?.info
          : err.response.data?.message;

        return addToast(errorMessage, {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Modal
      title={`Referência: ${data.reference}`}
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      maxHeight={500}
      maxWidth={data.is_generate_barcode_sample ? 850 : 750}
    >
      <Container>
        <TableClean
          actionName="Gerar Ean Mostruário"
          setAction={
            data.is_generate_barcode_sample && !loading && admin
              ? (e) => handleGenerateBarcode(e)
              : undefined
          }
          columns={[
            { field: "reference", title: "Referência" },
            { field: "color", title: "Cor" },
            { field: "size", title: "Tamanho" },
            { field: "barcode", title: "EAN" },
            { field: "barcodeSample", title: "EAN Mostruário" },
          ]}
          data={
            !!data.variations && data.variations.length > 0
              ? data.variations

                  .sort((c: any, d: any) => {
                    const a = c?.size;
                    const b = d?.size;

                    if (!isNaN(Number(a)) && !isNaN(Number(b))) {
                      return Number(a) - Number(b);
                    } else {
                      if (a === "G" && b === "GG") {
                        return -1;
                      }

                      if (a === "GG" && b === "G") {
                        return 1;
                      }

                      if (a > b) {
                        return -1;
                      }
                      if (a < b) {
                        return 1;
                      }

                      return 0;
                    }
                  })
                  .map((item: any) => ({
                    ...item,
                    reference: data.reference,
                    color: data.primary_color,
                  }))
              : [{ reference: "-", size: "-", barcode: "-", color: "-" }]
          }
        />
      </Container>
    </Modal>
  );
};
