import React, { useEffect, useState } from "react";

import api from "../../service/api";
import { useHistory } from "react-router-dom";

import * as authClient from "../../service/authClient";
import * as clientStorage from "../../service/localStorage/client";

import {
  Container,
  Form,
  ContainerList,
  TableContainer,
  FooterContainer,
  LoadingPanel,
} from "./styles";
import Filter from "../../components/Filter";
import LoadingDot from "../../components/loadings/LoadingDot";

import { BiLogInCircle } from "react-icons/bi";

import { useFormik } from "formik";
import * as Yup from "yup";
import logout from "../../utils/logout";

interface IFilter {
  name: string;
  description: string;
  value: any;
}

interface IClient {
  id: number;
  cod: string;
  cnpj: string;
  company_name: string;
  trade_name: string;
}

interface IClientAccess {
  id: string;
  cod: number;
  cnpj: string;
  company_name: string;
  trade_name: string;
  email: string;
}

interface IResponseAccessClient {
  client: IClientAccess;
  token: IToken;
}

interface IToken {
  access_token: string;
  iat: number;
  exp: number;
}

const filterDescription = {
  company_name: "Razão social",
  cnpj: "CNPJ",
};

const Holding: React.FC = () => {
  const history = useHistory();
  const clientData = clientStorage.getUser() as IClientAccess;

  const [contents, setContents] = useState<IClient[]>([]);
  const [viewFilter, setViewFilter] = useState(false);
  const [filters, setFilters] = useState<IFilter[]>([]);

  const [loading, setLoading] = useState({
    panel: true,
  });

  const formik = useFormik({
    initialValues: {
      company_name: undefined,
      cnpj: undefined,
    },
    validationSchema: Yup.object({}),
    onSubmit: (data) => handleSubimit(data),
  });

  useEffect(() => {
    (async () => {
      setLoading({ ...loading, panel: true });
      var nomalized = {};
      for (const filter of filters) {
        nomalized = { ...nomalized, [filter.name]: filter.value.trim() };
      }

      const response = await api.get("/clientconfig/holding", {
        params: nomalized,
      });
      setContents(response.data);
      setLoading({ ...loading, panel: false });
    })();

    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    // eslint-disable-next-line
    const keysFormik: string[] = Object.keys(formik.values);
    for (const filter of filters) {
      // eslint-disable-next-line
      formik.setFieldValue(filter.name, filter.value);
    }
    // eslint-disable-next-line
  }, [filters]);

  async function handleSubimit(item: any) {
    const values = Object.values(item);
    const keys = Object.keys(item);

    var nomalized: IFilter[] = [];
    for (let index = 0; index < values.length; index++) {
      if (values[index])
        nomalized.push({
          name: String(keys[index]),
          value: values[index],
          //@ts-ignore
          description: filterDescription[keys[index]],
        });
    }

    setFilters(nomalized);

    setViewFilter(false);
  }

  async function handleAccess(id: number) {
    try {
      const responseVerify = await api.post<IResponseAccessClient>(
        `/clientconfig/holding/${id}`
      );

      const { client, token } = responseVerify.data;

      logout();

      clientStorage.setUser({
        id: client.id,
        cod: client.cod,
        cnpj: client.cnpj,
        company_name: client.company_name,
        trade_name: client.trade_name,
        email: client.email,
      });

      const { access_token, exp, iat } = token;
      authClient.setToken({
        access_token,
        exp,
        iat,
      });

      history.push(`/home`);
      window.location.reload();
    } catch (error) {}
  }

  return (
    <Container>
      <header>
        <h2>Holding</h2>
      </header>

      <Filter
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        setFilters={setFilters}
        filters={filters}
      >
        <Form onSubmit={formik.handleSubmit}>
          <div className="field-group">
            <div className="field">
              <label htmlFor="cnpj">CNPJ</label>
              <input {...formik.getFieldProps("cnpj")} />
            </div>
          </div>
          <div className="field">
            <label htmlFor="company_name">Razão social</label>
            <input {...formik.getFieldProps("company_name")} />
          </div>

          <footer>
            <button
              className="clearFilter"
              type="button"
              onClick={() => {
                setViewFilter(false);
                setFilters([]);
              }}
            >
              LIMPAR FILTRO
            </button>
            <button type="submit">BUSCAR</button>
          </footer>
        </Form>
      </Filter>

      <ContainerList>
        <div className="headerTable">
          <h3>Holding</h3>
        </div>

        <TableContainer>
          {!loading.panel ? (
            <table>
              <thead>
                <tr>
                  <th>CNPJ</th>
                  <th>RAZÃO SOCIAL</th>
                  <th>NOME FANTASIA</th>
                  <th>AÇÃO</th>
                </tr>
              </thead>

              <tbody>
                {contents.map((element) => (
                  <tr key={element.id} style={{ cursor: "auto" }}>
                    <td>{element.cnpj}</td>
                    <td>{element.company_name}</td>
                    <td>{element.trade_name}</td>
                    <td className="accessButton">
                      <button
                        onClick={() => {
                          if (Number(clientData.id) !== Number(element.id)) {
                            handleAccess(element.id);
                          }
                        }}
                      >
                        {Number(clientData.id) !== Number(element.id) && (
                          <>
                            <span>ACESSAR</span>
                            <BiLogInCircle />
                          </>
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <LoadingPanel>
              <LoadingDot />
            </LoadingPanel>
          )}
        </TableContainer>

        <FooterContainer />
      </ContainerList>
    </Container>
  );
};

export default Holding;
