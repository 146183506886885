import React, { memo } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { Error as ContainerError } from "./styles";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  data: {
    value: string | number;
    name: string | number;
  }[];
  label?: string;
  error?: string;

  isNotDefaultOption?: boolean;
}

const SelectComponent: React.FC<InputProps> = ({
  label,
  name,
  data,
  error,
  isNotDefaultOption = false,
  ...rest
}) => {
  const restAny = rest as any;

  return (
    <div className="field">
      {label && <label htmlFor={name}>{label}</label>}

      <select id={name} name={name} {...restAny}>
        {!isNotDefaultOption && <option value="">Selecionar...</option>}

        {data.map((item) => (
          <option key={item.value} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>

      {!!error && (
        <ContainerError>
          <FiAlertCircle color="#f00" size={16} />
          <span> {error} </span>
        </ContainerError>
      )}
    </div>
  );
};

export const Select = memo(SelectComponent);
