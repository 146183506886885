import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  label {
    font-weight: lighter;

    select {
      background: transparent;
      font-size: 1rem;
      margin-left: 1px;

      option {
        border: 0;
        border-radius: 6px;
      }
    }
  }
`;
