import api from "../service/api";

export type JwtSsoPayload = {
  email?: string;
};

export function useSsoAlparhub({ email }: JwtSsoPayload) {
  async function accessSsoAlparhub() {
    const response = await api.post("sso/create/alparhub", {
      email,
    });
    const token = response.data;
    const url = `https://www.alparhub.com.br/addons/sso-login/?token=${token}`;

    setTimeout(() => {
      window.open(url, "_blank");
    });

    return;
  }

  return { accessSsoAlparhub };
}
