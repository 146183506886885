import React, { useState } from "react";
import { MdAddBox } from "react-icons/md";
import Modal from "../../../../components/ModalLib";

import { BoxSelectSeller, RowNone } from "./styles";

import { IoMdClose } from "react-icons/io";
import { Search } from "../../../../components/Search";
import { Pagination } from "../../../../components/panel/table/Pagination";
import { TableContent } from "../../../../components/panel/table/TableContent";
import {
  Seller,
  SellerFilters,
  useSellers,
} from "../../../../hook/queries/useSeller";
import { ModalBody } from "../../../create/styles";

interface ProviderComponentProps {
  onChangeSeller: (data: Seller | null) => void;
  seller?: Seller | null;

  disabled?: boolean;
  filters?: SellerFilters;
}

export const SelectSeller: React.FC<ProviderComponentProps> = ({
  onChangeSeller,
  seller,
  disabled = false,
  filters,
}) => {
  const [isDisabledModalClose, setIsDisabledModalClose] =
    useState<boolean>(false);
  const [modalVisibleNewSeller, setModalVisibleNewSeller] = useState(false);
  const [searchNewSeller, setSearchNewSeller] = useState("");
  const [page, setPage] = useState<number>(1);

  const getSellers = useSellers(page, { ...filters, search: searchNewSeller });

  function handleSelect(seller: Seller) {
    onChangeSeller(seller);

    if (!isDisabledModalClose) setModalVisibleNewSeller(false);
  }
  function handleRemove() {
    onChangeSeller(null);
  }

  return (
    <>
      <div className="container-subtitle">
        <h3>Vendedor </h3>

        {!disabled && (
          <button
            type="button"
            className="action-button"
            onClick={() => {
              setModalVisibleNewSeller(true);
            }}
          >
            <MdAddBox />
          </button>
        )}
      </div>
      {!!seller && (
        <BoxSelectSeller>
          <span>{seller.cod}</span>
          <span>{seller.abbreviation}</span>

          <button
            type="button"
            className="action-button"
            onClick={handleRemove}
          >
            <IoMdClose fontSize={"5rem"} />
          </button>
        </BoxSelectSeller>
      )}

      {!seller && <RowNone>Não há registros a serem exibidos</RowNone>}

      <Modal
        title="Vincular vendedor"
        modalVisible={modalVisibleNewSeller}
        setModalVisible={setModalVisibleNewSeller}
        maxHeight={"36rem"}
        isDisabled={isDisabledModalClose}
        setIsDisabled={setIsDisabledModalClose}
      >
        <ModalBody>
          <Search
            currentSearch={searchNewSeller}
            handleSearch={setSearchNewSeller}
          />
          <TableContent<Seller>
            data={getSellers.data?.sellers}
            columns={[
              {
                name: "cod",
                field: "Código",
                viewList: true,
              },
              {
                name: "abbreviation",
                field: "Abreviação",
                viewList: true,
              },
              {
                name: "fullName",
                field: "Nome",
                viewList: true,
              },
            ]}
            isLoading={getSellers.isLoading}
            onClickRow={(row) => {
              handleSelect(row);
            }}
          />

          <Pagination
            totalCountOfRegisters={getSellers?.data?.totalCount ?? 0}
            currentPage={page}
            onPageChange={setPage}
            registersPerPage={15}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
