import { Field, Formik } from "formik";
import React, { useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import api from "../../../service/api";
import { SelectPermission } from "../../detail/StatusDatasheet/SelectPermission";
import { SelectRequired } from "../../detail/StatusDatasheet/SelectRequired";
import { SelectStatus } from "../../detail/StatusDatasheet/SelectStatus";
import { SelectUser, UserProps } from "../../detail/StatusDatasheet/SelectUser";
import {
  ButtunSubmit,
  Container,
  ContainerCheck,
  ContainerForm,
  Error,
  Form,
} from "../styles";

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  is_visible_provider: boolean;
  finisher: boolean;
  is_active: boolean;
  is_generate_barcode: boolean;
  is_generate_barcode_sample: boolean;
  is_generate_book: boolean;
}

const CreateUser: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [responsibles, setResponsibles] = useState<UserProps[]>([]);
  const [users, setUsers] = useState<UserProps[]>([]);

  const [statusDatasheet, setStatusDatasheet] = useState<IStatus[]>([]);

  const [permissionFields, setPermissionFields] = useState<any>({});
  const [requeredFields, setRequeredFields] = useState<any>({});

  async function handleSubmit(user: IStatus) {
    let permissions = "";
    const arrPerm = Object.getOwnPropertyNames(permissionFields);
    for (const itemBrand of arrPerm) {
      if (permissionFields[itemBrand]) permissions += `${itemBrand},`;
    }
    let requered = "";
    const arrRequ = Object.getOwnPropertyNames(requeredFields);
    for (const itemBrand of arrRequ) {
      if (requeredFields[itemBrand]) requered += `${itemBrand},`;
    }
    try {
      await api.post("/headerDatasheet/datasheet/status", {
        ...user,
        responsibles: responsibles.map((item) => ({ id: item.id })),
        users: users.map((item) => ({ id: item.id })),
        next_status: statusDatasheet.map((item) => ({ id: item.id })),
        permission_fields: permissions,
        requered_fields: requered,
      });

      addToast("Status de Coleção de ficha técnica criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/status/fichas-tecnicas");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "cod status already exists") {
        return addToast("Este código já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2>Criar status das Ficha Técnica</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            cod: undefined,
            name: "",
            color: "",
            finisher: false,
            is_visible_provider: false,
            is_generate_barcode: false,

            is_generate_barcode_sample: false,
            is_generate_book: false,
          }}
          validationSchema={Yup.object({
            cod: Yup.string().required("Cod é obrigatório"),
            name: Yup.string().required("Descrição é obrigatório"),
            color: Yup.string().required("Cor é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data as any)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="field">
                <label htmlFor="name">COD</label>
                <Field name="cod" />
                {formik.touched.cod && formik.errors.cod ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.cod} </span>
                  </Error>
                ) : null}
              </div>
              <div className="field">
                <label htmlFor="name">Descrição</label>
                <input id="name" {...formik.getFieldProps("name")} />
                {formik.touched.name && formik.errors.name ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.name} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="color">Cor de fundo</label>
                <input id="color" {...formik.getFieldProps("color")} />
                {formik.touched.color && formik.errors.color ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.color} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field-group">
                <SelectPermission
                  permissionFields={permissionFields}
                  setPermissionFields={setPermissionFields}
                />
                <SelectRequired
                  permissionFields={requeredFields}
                  setPermissionFields={setRequeredFields}
                />
              </div>

              <div className="field-group">
                <div className="field">
                  <SelectUser
                    title="Utilizadores"
                    setUsersLinks={setUsers}
                    usersLinks={users}
                  />
                </div>
                <div className="field">
                  <SelectUser
                    title="Responsáveis"
                    setUsersLinks={setResponsibles}
                    usersLinks={responsibles}
                  />
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="name">Visualização fornecedor</label>
                  <ContainerCheck>
                    <button
                      type="button"
                      className={
                        formik.values.is_visible_provider ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue("is_visible_provider", true)
                      }
                    >
                      SIM
                    </button>

                    <button
                      type="button"
                      className={
                        !formik.values.is_visible_provider ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue("is_visible_provider", false)
                      }
                    >
                      NÃO
                    </button>
                  </ContainerCheck>
                </div>
                <div className="field">
                  <label htmlFor="name">Gera Book Técnico</label>
                  <ContainerCheck>
                    <button
                      type="button"
                      className={formik.values.is_generate_book ? "check" : ""}
                      onClick={() =>
                        formik.setFieldValue("is_generate_book", true)
                      }
                    >
                      SIM
                    </button>

                    <button
                      type="button"
                      className={!formik.values.is_generate_book ? "check" : ""}
                      onClick={() =>
                        formik.setFieldValue("is_generate_book", false)
                      }
                    >
                      NÃO
                    </button>
                  </ContainerCheck>
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label htmlFor="name">Gera EAN</label>
                  <ContainerCheck>
                    <button
                      type="button"
                      className={
                        formik.values.is_generate_barcode ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue("is_generate_barcode", true)
                      }
                    >
                      SIM
                    </button>

                    <button
                      type="button"
                      className={
                        !formik.values.is_generate_barcode ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue("is_generate_barcode", false)
                      }
                    >
                      NÃO
                    </button>
                  </ContainerCheck>
                </div>
                <div className="field">
                  <label htmlFor="name">Gera Amostra EAN </label>
                  <ContainerCheck>
                    <button
                      type="button"
                      className={
                        formik.values.is_generate_barcode_sample ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue("is_generate_barcode_sample", true)
                      }
                    >
                      SIM
                    </button>

                    <button
                      type="button"
                      className={
                        !formik.values.is_generate_barcode_sample ? "check" : ""
                      }
                      onClick={() =>
                        formik.setFieldValue(
                          "is_generate_barcode_sample",
                          false
                        )
                      }
                    >
                      NÃO
                    </button>
                  </ContainerCheck>
                </div>
              </div>

              <div className="field">
                <label htmlFor="name">Finalização</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.finisher ? "check" : ""}
                    onClick={() => formik.setFieldValue("finisher", true)}
                  >
                    SIM
                  </button>

                  <button
                    type="button"
                    className={!formik.values.finisher ? "check" : ""}
                    onClick={() => formik.setFieldValue("finisher", false)}
                  >
                    NÃO
                  </button>
                </ContainerCheck>
              </div>

              <div className="field">
                <SelectStatus
                  title="Proximas situações"
                  setUsersLinks={setStatusDatasheet}
                  usersLinks={statusDatasheet}
                />
              </div>

              <ButtunSubmit type="submit"> Criar status </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default CreateUser;
