import React, { useEffect, useState } from "react";
import api from "../../../../service/api";

import { ContainerCheckbox } from "./styles";

interface SelectPermissionProps {
  permissionFieldsAllowed?: { name: string }[];

  permissionFields: any;
  setPermissionFields: (a: any) => any;
}

interface PermissionField {
  description: string;
  name: string;
  is_allowed?: boolean;
}

export const SelectPermission: React.FC<SelectPermissionProps> = ({
  permissionFieldsAllowed,

  setPermissionFields,
  permissionFields,
}) => {
  const [permissionFieldsDb, setPermissionFieldsDb] = useState<
    PermissionField[]
  >([]);

  useEffect(() => {
    (async () => {
      const contentsDB = await api.get(
        "/headerDatasheet/datasheet/permissionField"
      );

      setPermissionFieldsDb(
        contentsDB.data.map((item: PermissionField) => ({
          ...item,
          is_allowed: false,
        }))
      );
    })();
  }, []);

  useEffect(() => {
    var permission_fields_checks: any = {};

    permissionFieldsAllowed?.forEach((field) => {
      permission_fields_checks = {
        ...permission_fields_checks,
        [field.name]: true,
      };
    });

    setPermissionFields({ ...permission_fields_checks });

    // eslint-disable-next-line
  }, []);

  function setChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setPermissionFields({
      ...permissionFields,
      [e.target.value]: e.target.checked,
    });
  }

  return (
    <div className="field">
      <ContainerCheckbox>
        <label className="titleCheckbox" htmlFor="">
          Bloquear campos
        </label>
        <div className="contentsCheckbox">
          {permissionFieldsDb.map((element, index) => (
            <div key={index} className="boxCheckbox">
              <input
                type="checkbox"
                onChange={setChangeCheckbox}
                checked={permissionFields[element.name] ? true : false}
                value={element.name}
                name={`permission_fields`}
              />
              <label htmlFor="">{element.description}</label>
            </div>
          ))}
        </div>
      </ContainerCheckbox>
    </div>
  );
};
