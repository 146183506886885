import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";

import useDebounce from "../../hook/useDebounce";
import { Filter as FilterContainer } from "./style";

interface Props {
  handleSearch: (search: string) => void;
  currentSearch: string;
}

export function Search({ handleSearch, currentSearch }: Props) {
  const [search, setSearch] = useState(currentSearch);
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    handleSearch(debouncedSearch ?? "");

    // eslint-disable-next-line
  }, [debouncedSearch]);

  return (
    <FilterContainer>
      <button style={{ background: "#d21e26" }} type="button">
        <span>Filtrar</span>
        <FiSearch />
      </button>
      <input
        placeholder="Buscar"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        onBlur={() => handleSearch(search)}
      />
    </FilterContainer>
  );
}
