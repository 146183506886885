import styled from "styled-components";

export const ButtonPage = styled.button`
  height: 32px;
  width: 32px;

  border-radius: 4px;
  font-weight: 700;

  color: #555;
  background-color: #fff;

  transition: all 0.8s;

  margin-right: 0 !important;

  margin-left: 8px;

  &:hover {
    background-color: #ccc;
  }
`;
