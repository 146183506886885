import React, { useState, useEffect } from "react";

import moment from "moment";

import { FaArrowLeft } from "react-icons/fa";
import { Container, InfoDevolution, InfoStatus } from "../styles";

import iconPdf from "../../../assets/icon_pdf.png";
import iconExcel from "../../../assets/icon_excel.png";
import iconPowerPoint from "../../../assets/icon_power_point.png";
import iconWord from "../../../assets/icon_word.png";
import iconZip from "../../../assets/icon_zip.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { useHistory } from "react-router-dom";
import api from "../../../service/api";
import { IPermissionUser } from "../../../@types";

interface HomeProps {
  location: {
    state: {
      devolution: IDevolution;
    };
  };

  admin: boolean;
  client: boolean;
  permission: IPermissionUser;
}

interface IFile {
  id: string;
  name: string;
  size: Number;
  url?: string;
  key?: string;
  preview_url?: string;
  mimetype?: string;
}

interface IDevolution {
  id?: string;
  protocol: number;
  status?: IStatus;

  requesterName: string;
  phone: string;
  email: string;

  //Dados da solicitação
  numberInvoice: string;
  situationRequest: string;
  products: string;

  requestType: string;

  //Dados da Devolução & indenização
  reason: string;
  subReason: string;

  //devolução
  dateInvoice: string;

  //indenização
  brand: string;
  stateProducts: string;
  dateEmissionNFconsumer: string;
  nfdDateEmission: Date;
  invoiceORcoupon: string;

  //Dados bancários
  bank: string;
  agency: string;
  account: string;
  typeAccountBank: string;

  complementaryInformation: string;

  created_at: Date;

  clients_pj: {
    id: string;
    cnpj: string;
    company_name: string;
    trade_name: string;
    email: string;
  };

  //Dados internos
  //Dados NFD
  nfdNumber?: string;
  nfdValue: number;
  nfdDate: Date;
  closingDate: Date;

  //Representante / Gestor
  salesman?: string;
  brandManager?: string;

  //Trasporte
  freightBrand?: string;
  freightValue?: string;

  files: IFile[];
}

interface IChat {
  id?: number;
  name?: string;
  typeUser?: string;
  textMessage?: string;
  iDindemnity?: number;
  created_at?: Date;
}

interface IBrand {
  id?: number;
  cod: number;
  idBrand: number;
  name: string;
  is_active: boolean;
}

interface IInternalReason {
  id?: number;
  cod: number;
  name: string;
  is_active: boolean;
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_active: boolean;
}

const Devolution: React.FC<HomeProps> = ({
  location,
  admin,
  client,
  permission,
}) => {
  const history = useHistory();

  const [chat, setChat] = useState<IChat[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);

  useEffect(() => {
    (async () => {
      const fileDB = await api.get<IFile[]>(
        `/devolutionLegacy/file/${location.state.devolution.id}`
      );
      setFiles(fileDB.data);

      const chatDB = await api.get<IChat[]>(
        `/devolutionLegacy/messages/${location.state.devolution.id}`
      );
      setChat(chatDB.data);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>
        <h2>Detalhes da solicitação</h2>
      </header>

      <InfoStatus>
        <div className="containerStatus">
          <div className="box">
            <div>
              <h5>PROTOCOLO</h5>
            </div>
            <p>{location.state.devolution.protocol}</p>
          </div>
        </div>

        <div className="containerStatus">
          <div className="box">
            <div>
              <h5>STATUS</h5>
            </div>
            <span
              style={{
                backgroundColor: location.state.devolution.status?.color,
              }}
            >
              {location.state.devolution.status}
            </span>
          </div>
        </div>
        <div className="containerStatus">
          <div className="box">
            <div>
              <h5>NOTA FISCAL</h5>
            </div>
            <p>{location.state.devolution.numberInvoice}</p>
          </div>
        </div>

        {!location.state.devolution.status?.finisher && (
          <div className="containerStatus">
            <div className="box">
              <div>
                <h5>CRIADO EM</h5>
              </div>
              <p>
                {moment(location.state.devolution.created_at).format(
                  "DD/MM/YYYY"
                )}
              </p>
            </div>
          </div>
        )}

        {location.state.devolution.status?.finisher && (
          <>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>CRIADO EM</h5>
                </div>
                <p>
                  {moment(location.state.devolution.created_at).format(
                    "DD/MM/YYYY"
                  )}
                </p>
              </div>
            </div>
            <div className="containerStatus">
              <div className="box">
                <div>
                  <h5>FINALIZADO EM</h5>
                </div>
                <p>
                  {moment(location.state.devolution.closingDate).format(
                    "DD/MM/YYYY"
                  )}
                </p>
              </div>
            </div>
          </>
        )}
      </InfoStatus>

      <InfoDevolution>
        <div className="headerDevoltion">
          <h2>Informações</h2>
        </div>
        <div className="bodyDevoltion">
          <div className="conatinerInfo">
            <h3>Cliente</h3>
            <div className="line"></div>

            <div>
              <h4>CNPJ</h4>
              <span>{location.state.devolution.clients_pj.cnpj}</span>

              <h4>RAZÃO SOCIAL</h4>
              <span>{location.state.devolution.clients_pj.company_name}</span>

              <h4>NOME FANTASIA</h4>
              <span>{location.state.devolution.clients_pj.trade_name}</span>
            </div>
          </div>

          <div className="conatinerInfo">
            <h3>Solicitante</h3>
            <div className="line"></div>

            <div>
              <h4>NOME SOLICITANTE</h4>
              <span>{location.state.devolution.requesterName}</span>

              <h4>TELEFONE</h4>
              <span>{location.state.devolution.phone}</span>

              <h4>E-MAIL</h4>
              <span>{location.state.devolution.email}</span>
            </div>
          </div>

          <div className="conatinerInfo">
            <h3>Solicitação</h3>
            <div className="line"></div>
            <div>
              <h4>PROTOCOLO</h4>
              <span>{location.state.devolution.protocol}</span>

              <h4>NOTA FISCAL N°</h4>
              <span>{location.state.devolution.numberInvoice}</span>

              <h4>TIPO DE DEVOLUÇÃO</h4>
              <span>{location.state.devolution.situationRequest}</span>
              {location.state.devolution.situationRequest === "parcial" ? (
                <>
                  <h4>PRODUTOS</h4>
                  <span>{location.state.devolution.products}</span>
                </>
              ) : null}

              <h4>TIPO DE SOLICITAÇÃO</h4>
              <span>{location.state.devolution.requestType}</span>
            </div>
          </div>

          {location.state.devolution.requestType !==
          "Indenização (somente produtos com problemas de qualidade)" ? (
            <div className="conatinerInfo">
              <h3>Devolução</h3>
              <div className="line"></div>
              <div>
                <h4>MOTIVO</h4>
                <span>{location.state.devolution.reason}</span>

                <h4>DATA NOTA FISCAL</h4>
                <span>{location.state.devolution.dateInvoice}</span>
              </div>
            </div>
          ) : (
            <div className="conatinerInfo">
              <h3>Indenização</h3>
              <div className="line"></div>
              <div>
                <h4>MARCA</h4>
                <span>{location.state.devolution.brand}</span>

                <h4>ESTADO DO PRODUTO</h4>
                <span>{location.state.devolution.stateProducts}</span>

                <h4>DATA DE EMISSÃO DA NF AO CONSUMIDOR</h4>
                <span>
                  {moment(
                    location.state.devolution.dateEmissionNFconsumer
                  ).format("YY/MM/YYYY")}
                </span>

                <h4>NOTA FISCAL OU CUPOM CONSUMIDOR</h4>
                <span>{location.state.devolution.invoiceORcoupon}</span>
              </div>
            </div>
          )}

          <div className="conatinerInfo">
            <h3>Dados bancários</h3>
            <div className="line"></div>
            <div>
              <h4>BANCO</h4>
              <span>{location.state.devolution.bank}</span>

              <h4>AGENCIA</h4>
              <span>{location.state.devolution.agency}</span>

              <h4>CONTA</h4>
              <span>{location.state.devolution.account}</span>

              <h4>TIPO DE CONTA</h4>
              <span>{location.state.devolution.typeAccountBank}</span>
            </div>
          </div>

          <div className="conatinerInfo">
            <h3>Informações complementares</h3>
            <div className="line" />
            <div>
              <p>{location.state.devolution.complementaryInformation}</p>
            </div>
          </div>

          <div className="conatinerInfo">
            <h3>Arquivos</h3>
            <div className="line" />
            <div className="containerImg">
              {files.map((img, index) => (
                <div>
                  <LazyLoadImage
                    effect="blur"
                    onClick={() => {
                      window.open(img.url);
                    }}
                    key={img.id}
                    src={
                      img.mimetype === "application/pdf"
                        ? iconPdf
                        : img.mimetype === "application/vnd.ms-excel" ||
                          img.mimetype ===
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ? iconExcel
                        : img.mimetype === "application/msword" ||
                          img.mimetype === "application/octetstream" ||
                          img.mimetype ===
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                          img.mimetype === "text/xml"
                        ? iconWord
                        : img.mimetype === "application/x-zip-compressed"
                        ? iconZip
                        : img.mimetype ===
                          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                        ? iconPowerPoint
                        : img.url
                    }
                    alt={img.name}
                  />
                  <p>{img.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </InfoDevolution>

      <InfoDevolution>
        <form onSubmit={() => {}}>
          <div className="headerDevoltion">
            <h2>Mensagens</h2>
          </div>

          <div className="bodyChat">
            {chat.map((item, i) => {
              let messageDate = new Date(item.created_at as Date);

              return (
                <div
                  key={i}
                  className={
                    admin
                      ? item.typeUser === "client"
                        ? "to"
                        : "i"
                      : item.typeUser === "client"
                      ? "i"
                      : "to"
                  }
                >
                  <div className="containerchat">
                    <div className="containerMessage">
                      <header>
                        <span>{item.name}</span>
                      </header>
                      <p>{item.textMessage}</p>
                    </div>
                    <footer>
                      {moment(messageDate).format("hh:mm A - DD/MM/YYYY")}
                    </footer>
                  </div>
                </div>
              );
            })}
          </div>
        </form>
      </InfoDevolution>
    </Container>
  );
};

export default Devolution;
