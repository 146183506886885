import React from "react";

import { HiUserGroup } from "react-icons/hi";
import { SiWhatsapp } from "react-icons/si";
import logo from "../../assets/logo-red.png";
import * as clientStorage from "../../service/localStorage/client";
import * as sellerStorage from "../../service/localStorage/seller";
import {
  Container,
  ContainerActions,
  ContainerBar,
  ContainerBarMobile,
  ContainerButtons,
  ContainerIconHolding,
  IconHolding,
  IconWhats,
  InfoCod,
  LogoAlpar,
} from "./styles";
// import { FaBars } from "react-icons/fa";

import { IPermissionUser } from "../../@types";
import { useSideBar } from "../../hook/useSideBar";
import { Burguer } from "../Burguer";
import NavUserInfo from "../NavUserInfo";

interface HeaderProps {
  admin?: boolean;
  seller?: boolean;
  client?: boolean;
  provider?: boolean;

  is_manager?: boolean;
  is_supervisor?: boolean;

  permission?: IPermissionUser;
}

const NavbarTop: React.FC<HeaderProps> = ({
  admin,
  seller,
  client,
  provider,
}) => {
  const sidebar = useSideBar();
  const clientData = clientStorage.getUser() as clientStorage.Client;
  const sellerData = sellerStorage.getUser() as sellerStorage.SellerProps;

  function openWhats() {
    if (admin) {
      window.open("https://alpar.orpen.com.br/", "_blank");
    } else {
      window.open("https://api.whatsapp.com/send?phone=555134411000", "_blank");
    }
  }

  return (
    <>
      {client && (
        <InfoCod>
          <span>
            Seu código de cliente é <u>{` ${clientData.cod}`}</u>
          </span>
        </InfoCod>
      )}

      <ContainerBarMobile>
        <button
          type="button"
          onClick={() => sidebar.handleSetIsOpen(!sidebar.isOpen)}
        >
          <Burguer isOpen={sidebar.isOpen} />
        </button>
      </ContainerBarMobile>

      <Container>
        <div></div>
        <ContainerBar>
          <button
            type="button"
            onClick={() => sidebar.handleSetIsOpen(!sidebar.isOpen)}
          >
            <Burguer isOpen={sidebar.isOpen} />
          </button>
        </ContainerBar>
        <LogoAlpar>
          <img src={logo} alt="Alpar do Brasil" />
        </LogoAlpar>
        <ContainerButtons>
          <ContainerActions>
            {client && (
              <ContainerIconHolding to={"/holding"}>
                <IconHolding>
                  <HiUserGroup size={25} color="#FFF" />
                </IconHolding>
              </ContainerIconHolding>
            )}
            {seller && !!sellerData?.sellerTeamOn && (
              <ContainerIconHolding to={"/representante/equipe"}>
                <IconHolding>
                  <HiUserGroup size={25} color="#FFF" />
                </IconHolding>
              </ContainerIconHolding>
            )}
            <IconWhats onClick={openWhats}>
              <SiWhatsapp size={25} color="#FFF" />
            </IconWhats>
          </ContainerActions>

          <NavUserInfo
            admin={admin}
            seller={seller}
            client={client}
            provider={provider}
          />
        </ContainerButtons>
      </Container>
    </>
  );
};

export default NavbarTop;
