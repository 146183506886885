import styled from "styled-components";

export const Error = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 2px;
  span {
    margin-left: 4px;
    font-weight: 400;
    font-size: 14px;
    color: #f00;
  }
`;
