import React, { useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { Formik, Field } from "formik";
import { FiAlertCircle } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import * as Yup from "yup";

import api from "../../../service/api";
import { Container, ContainerForm, Form, ButtunSubmit, Error } from "../styles";

interface IUser {
  id?: string;
  name?: string;
  lastname?: string;
  email?: string;
  password?: string;
}

interface IPermission {
  id?: string;
  name?: string;
}

const CreateUser: React.FC = () => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [listPermission, setListPermission] = useState<IPermission[]>([]);

  useEffect(() => {
    (async () => {
      const permissionDB = await api.get("/populate/permission");
      setListPermission(permissionDB.data);
    })();
  }, []);

  async function handleSubmit(user: IUser) {
    try {
      await api.post("/userAdmin", user);

      addToast("Usuário criado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/usuarios");
    } catch (err) {
      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este e-mail já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>

        <h2>Criar usuário</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            name: undefined,
            lastname: undefined,
            email: undefined,
            password: undefined,
            permission: undefined,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Nome é obrigatório"),
            lastname: Yup.string().required("Sobrenome é obrigatório"),
            permission: Yup.string().required("Permissionamento é obrigatório"),
            email: Yup.string()
              .email("informe um e-mail válido")
              .required("email é obrigatório"),
            password: Yup.string()
              .min(8, "A senha precisa conter no mínimo 8 caracteres.")
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "A senha precisa conter letrar e numeros, no mínimo 8 caracteres e um caracter especial."
              )
              .required("Senha é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="field-group">
                <div className="field">
                  <label htmlFor="name">Nome</label>
                  <Field name="name" />
                  {formik.touched.name && formik.errors.name ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.name} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label htmlFor="lastname">Sobrenome</label>
                  <input id="lastname" {...formik.getFieldProps("lastname")} />
                  {formik.touched.lastname && formik.errors.lastname ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.lastname} </span>
                    </Error>
                  ) : null}
                </div>
              </div>
              <div className="field">
                <label htmlFor="email">Email</label>
                <input id="email" {...formik.getFieldProps("email")} />
                {formik.touched.email && formik.errors.email ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.email} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="password">Senha</label>
                <input id="password" {...formik.getFieldProps("password")} />
                {formik.touched.password && formik.errors.password ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.password} </span>
                  </Error>
                ) : null}
              </div>

              <h3>Perfil de permissionamento</h3>
              <div className="field">
                <label htmlFor="permission">Permissão</label>
                <select id="permission" {...formik.getFieldProps("permission")}>
                  <option value={undefined}>Selecionar permissão</option>
                  {listPermission &&
                    listPermission.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
                {formik.touched.permission && formik.errors.permission ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.permission} </span>
                  </Error>
                ) : null}
              </div>
              <ButtunSubmit type="submit"> Criar Usuário </ButtunSubmit>
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default CreateUser;
