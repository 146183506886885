import styled from "styled-components";

export const Container = styled.header`
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }

  .arrowBack {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 10px;

    height: 30px;
    width: 30px;

    border-radius: 50%;

    transition: all 0.4s;

    &:hover {
      background-color: #ccc;
    }
  }

  .infoUpdate {
    span {
      font-size: 0.8rem;
      color: #555;
      font-weight: 300;
    }
  }
`;
