import React, { FormEvent, useEffect, useState } from "react";

import api from "../../../service/api";

import { useToasts } from "react-toast-notifications";

import { ButtunSubmit, Container, ContainerForm, Form } from "../styles";

interface HeaderProps {
  admin: boolean;
}

export interface GatewayDefaultConfig {
  parcel: number;
  parcel_renegotiation: number;
  payment_gateway: {
    id: number;
    title: string;
  };
}

interface Gateways {
  id: number;
  environment: string;
  payment_config: string;
  title: string;
  client_Id: string;
  seller_Id: string;
  client_Secret: string;
  base_url: string;
}

// interface DefaultGateways {
//   id?: number;
//   title?: string;
//   parcel?: number;
// }

interface ISubmit {
  gatewayDefault: number;
  parcel: number;
  parcel_renegotiation: number;
  seller_Id: string;
  client_Id: string;
  client_Secret: string;
  environment?: string;
}

interface IData {
  gatewayDefault: number;
  parcel: number;
  parcel_renegotiation: number;
  title: string;

  seller_Id: string;
  client_Id: string;
  client_Secret: string;
  environment: string;
  base_url: string;
}

const ConfigGateway: React.FC<HeaderProps> = () => {
  const { addToast } = useToasts();

  const [data, setData] = useState<IData>({} as IData);
  const [gatewayList, setGatewayList] = useState<Gateways[]>([]);
  // const [defaultGateway, setDefaultGateway] = useState<DefaultGateways>();

  useEffect(() => {
    (async () => {
      try {
        const gatewayDefault = await api.get<GatewayDefaultConfig>(
          `/payment/gateway/config/default`
        );

        const gateways = await api.get<Gateways[]>(`/payment/gateway/list`);

        setGatewayList(gateways.data);
        // setDefaultGateway({
        //   id: gatewayDefault.data.payment_gateway.id,
        //   parcel: gatewayDefault.data.parcel,
        //   title: gatewayDefault.data.payment_gateway.title,
        // });

        const { parcel, parcel_renegotiation, payment_gateway } =
          gatewayDefault.data;

        setData({
          ...data,
          gatewayDefault: payment_gateway.id,
          title: payment_gateway.title,
          parcel,
          parcel_renegotiation,
        });
      } catch (error) {}
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      setData({
        ...data,
        title: selectGateway(Number(data.gatewayDefault)).title,
        seller_Id: selectGateway(Number(data.gatewayDefault)).seller_Id,
        client_Id: selectGateway(Number(data.gatewayDefault)).client_Id,
        client_Secret: selectGateway(Number(data.gatewayDefault)).client_Secret,
        base_url: selectGateway(Number(data.gatewayDefault)).base_url,
      });
    })();
    // eslint-disable-next-line
  }, [data.gatewayDefault]);

  function hadleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setData({
      ...data,
      [name]: value,
    });
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    let submit: ISubmit = {
      gatewayDefault: data.gatewayDefault,
      seller_Id: data.seller_Id,
      client_Id: data.client_Id,
      client_Secret: data.client_Secret,
      parcel: data.parcel,
      parcel_renegotiation: data.parcel_renegotiation,
    };
    try {
      await api.put(`/payment/gateway/config/default`, submit);

      addToast(
        "Alterção de dados do Gateway de Pagamento realizada com sucesso",
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    } catch (error) {
      console.log(error);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  function selectGateway(id: number) {
    let response: Gateways = {} as Gateways;

    for (const element of gatewayList) {
      if (element.id === id) {
        response = element;
      }
    }

    return response;
  }

  return (
    <Container>
      <header>
        <h2>Gateway de Pagamento</h2>
      </header>

      <ContainerForm>
        <Form onSubmit={handleSubmit}>
          <h3>Geral</h3>
          <div className="field">
            <label htmlFor="gatewayDefault">Gateway padrão</label>
            <select
              name="gatewayDefault"
              value={data.gatewayDefault}
              onChange={(e) => {
                setData({
                  ...data,
                  [e.target.name]: e.target.value,
                });
              }}
            >
              <option value=""></option>
              {gatewayList.map((element) => (
                <option value={element.id}>{element.title}</option>
              ))}
            </select>
          </div>
          <div className="field-group">
            <div className="field">
              <label htmlFor="parcel">Máximo de Parcelas para Pedidos</label>
              <input
                type="number"
                name="parcel"
                value={data.parcel}
                onChange={hadleInputChange}
              />
            </div>
            <div className="field">
              <label htmlFor="parcel_renegotiation">
                Máximo de Parcelas para Renegociação
              </label>
              <input
                type="number"
                name="parcel_renegotiation"
                value={data.parcel_renegotiation}
                onChange={hadleInputChange}
              />
            </div>
          </div>

          <h3>{data.title}</h3>

          <div className="field">
            <label htmlFor="seller_Id">Seller Id</label>
            <input
              value={data.seller_Id}
              onChange={hadleInputChange}
              name="seller_Id"
            />
          </div>

          <div className="field-group">
            <div className="field">
              <label htmlFor="client_Id">Client Id</label>
              <input
                value={data.client_Id}
                onChange={hadleInputChange}
                name="client_Id"
              />
            </div>
            <div className="field">
              <label htmlFor="client_Secret">Client Secret</label>
              <input
                value={data.client_Secret}
                onChange={hadleInputChange}
                name="client_Secret"
              />
            </div>
          </div>
          <div className="field">
            <label htmlFor="base_url">URL API</label>
            <input
              name="base_url"
              value={data.base_url}
              onChange={hadleInputChange}
            />
          </div>
          {/* <div className="field">
            <label htmlFor="environment">Ambiente</label>
            <select name="" id="">
              <option value="">Produção</option>
              <option value="">Sandbox</option>
            </select>
            {formik.touched.environment && formik.errors.environment ? (
              <Error>
                <FiAlertCircle color="#f00" size={16} />
                <span> {formik.errors.environment} </span>
              </Error>
            ) : null}
          </div> */}

          <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
        </Form>
      </ContainerForm>
    </Container>
  );
};

export default ConfigGateway;
