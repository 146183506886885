import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineLink } from "react-icons/ai";
import { BiEditAlt, BiLinkExternal } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { FaArrowLeft, FaTrashAlt } from "react-icons/fa";
import { FiEdit, FiSend } from "react-icons/fi";
import { ImBook } from "react-icons/im";
import { IoIosBarcode } from "react-icons/io";
import { IoReload } from "react-icons/io5";
import { MdAdd, MdArrowDownward, MdOutlineCancel } from "react-icons/md";
import { RiDeleteBinLine, RiFileExcel2Line } from "react-icons/ri";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as XLSX from "xlsx";
import * as Yup from "yup";
import { IPermissionUser, OptionsFilter } from "../../../@types";
import { FieldProps } from "../../../@types/panelTypes";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import Modal from "../../../components/ModalLib";
import {
  ModalModificationDatasheet,
  OptionsDataProps,
} from "../../../components/ModalModificationDatasheet";
import { ModalModificationHeaderDatasheet } from "../../../components/ModalModificationHeaderDatasheet";
import { TableEditableV2 } from "../../../components/TableEditableV2";
import Loading from "../../../components/loadings/Loading";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { OrderBy } from "../../../components/panel/PanelOrderBy";
import { Pagination } from "../../../components/panel/table/Pagination";
import useOutsideAlerter from "../../../hook/outsideAlerter";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import api from "../../../service/api";
import * as UserStorage from "../../../service/localStorage/user";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";
import {
  ContainerList,
  ExternalLink,
  ExternalLinkA,
  Reaload,
  ReportDownload,
} from "../../panel/styles";
import { DatasheetProps, HeaderDatasheetAndDatasheet } from "../Datasheet";
import {
  BtnAction,
  Container,
  InfoStatus,
  PopUpConfirmation,
  ScreenLoading,
  TableContainer,
} from "../styles";
import { ModalGenerateBook } from "./ModalGenerateBook";
import { ModalLinkDatasheet } from "./ModalLinkDatasheet";

interface HomeProps {
  location: {
    state: {
      search?: string;
    };
  };

  match: {
    params: {
      id: number;
    };
  };

  admin?: boolean;
  provider?: boolean;

  permission?: IPermissionUser;
}

interface DatasheetListProps extends DatasheetProps {
  header_datasheet: {
    markUpStore: number;
    collection: {
      id: number;
      description: string;
    };
    year: number;
    brand: {
      id: number;
      name: string;
    };
  };
}

export interface StatusProps {
  id: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_generate_barcode: boolean;
  is_generate_barcode_sample: boolean;
  is_generate_book: boolean;

  responsibles: {
    userId: string;
  }[];
  permission_fields: {
    name: string;
  }[];
  required_fields: {
    field: {
      name: string;
      description: string;
    };
  }[];
}

export interface HeaderDatasheetProps {
  id: number;
  collection: string;
  collectionId: number;
  markUpStore: number;
  year: number;

  markupMediaAritmetica?: number;
  markupMediaPonderada?: number;

  finished: boolean;
  finished_at: Date | null;
  created_at: Date;

  status: {
    id: number;
    cod: number;
    name: string;
    color: string;
    finisher: boolean;
    is_generate_book: boolean;
  };

  brand: IBrand;
}

interface IFilter {
  status?: string;
  alternative_code?: string;
  collection?: string;
  year?: string;
  brand?: string;
  genre?: string;
  provider?: string;
  category?: string;
}

interface IBrand {
  id: number;
  cod: number;
  name: string;
}

interface IResponseAPI {
  contents: DatasheetListProps[];
  filters: IFilterOption;
  total: number;
  limit: number;
  page: number;
  pages: number;
}
interface IFilterOption {
  category: OptionsFilter[];
  status: OptionsFilter[];
  brand: OptionsFilter[];
  collection: OptionsFilter[];
  year: OptionsFilter[];
  genre: OptionsFilter[];
  provider: OptionsFilter[];
}

interface IPagination {
  total: number;
  limit: number;
  page: number;
  pages: number;
}

const APP_LINK = "http://portal.alpardobrasil.com.br";
const NOIMAGE_LINK =
  "https://alpar.sfo3.digitaloceanspaces.com/Alpar/no-image.jpg";

const columns: FieldProps[] = [
  {
    name: "alternative_code",
    field: "REF",
    viewList: true,
  },
  {
    name: "description",
    field: "Descrição",
    viewList: true,
  },
  {
    name: "status",
    field: "Situação",
    viewList: true,
  },
  {
    name: "category",
    field: "Classe de produto",
    viewList: true,
  },
  {
    name: "genre",
    field: "Gênero",
    viewList: true,
  },
  {
    name: "provider",
    field: "Fornecedor",
    viewList: true,
  },
];

const Datasheet: React.FC<HomeProps> = ({
  match,
  admin,
  provider,
  permission,
  location,
}) => {
  const outSideRef = useRef(null);
  const outSideRefReport = useRef(null);
  const outSideRefAction = useRef(null);
  const query = useQueryParams();

  const userData = UserStorage.getUser() as UserStorage.User;

  const history = useHistory();
  const { addToast } = useToasts();

  const [status, setStatus] = useState<StatusProps[]>([]);

  const [contentPage, setContentPage] = useState<HeaderDatasheetProps>();
  const [loading, setLoading] = useState<boolean>(true);

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });

  const [idEditableRow, setIdEditableRow] = useState<string>("");

  const formikFilter = useFormik({
    initialValues: {
      status: "",
      collection: "",
      year: "",
      brand: "",
      alternative_code: "",
      category: "",
    },
    onSubmit: (data) => {
      handleFilter(data);
    },
  });

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });
  const [filtersOptions, setFiltersOptions] = useState<IFilterOption>({
    status: [],
    brand: [],
    collection: [],
    year: [],
    category: [],
    genre: [],
    provider: [],
  });

  const [modalGenerateBook, setModalGenerateBook] = useState(false);

  const [datasheets, setDatasheets] = useState<DatasheetListProps[]>([]);
  const [update, setUpdate] = useState(1);
  const [updateHeader, setUpdateHeader] = useState(1);

  const [orderBy, setOrderBy] = useState("alternative_code.asc");

  const [viewFilter, setViewFilter] = useState(false);

  const [loadingReport, setLoadingReport] = useState(false);
  const [visibleReport, setVisibleReport] = useState(false);

  const [visibleAction, setVisibleAction] = useState(false);

  const [loadingPanel, setLoadingPanel] = useState(true);
  const [pagination, setPagination] = useState<IPagination>({} as IPagination);

  const [confirmationDelete, setConfirmationDelete] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [optionsData, setOptionsData] = useState<OptionsDataProps>({
    genres: [],
    unit_measures: [],
    product_origins: [],
    age_types: [],
    product_types: [],
    product_lines: [],
    product_groups: [],
    sleeves: [],
    ncms: [],
    grids: [],
    categories: [],
    materials: [],
    price_classification: [],
    materials_natures: [],
  });

  const [
    activeUpdateHeaderDatasheetModal,
    setActiveUpdateHeaderDatasheetModal,
  ] = useState<boolean>(false);

  const [confirmationFinished, setConfirmationFinished] =
    useState<boolean>(false);
  const [activeCreateDatasheetModal, setActiveCreateDatasheetModal] =
    useState<boolean>(false);

  const [activeModalLinkDatasheet, setActiveModalLinkDatasheet] =
    useState<boolean>(false);

  const formikEditableRow = useFormik({
    initialValues: {
      description: "",
      status: "",
      price_sale: "",
      pre_price_cost: "",
      price_cost: "",
      group: "",
      minimum: "",
      price_classification: "",
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("description", value)
        )

        .required("Obrigatório"),
      status: Yup.string().required("Obrigatório"),

      price_sale: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("price_sale", value)
        ),
      price_cost: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("price_cost", value)
        ),
      pre_price_cost: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("pre_price_cost", value)
        ),
      group: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("group", value)
        ),
      minimum: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("minimum", value)
        ),
      price_classification: Yup.string()
        .nullable()
        .test("validate", "Obrigatório", async (value) =>
          validadeteRequiredField("price_classification", value)
        ),
    }),

    onSubmit: async (data) => {
      try {
        const updateDatasheet = {
          description: data.description,
          statusId: data.status,
          price_sale: data.price_sale,
          price_cost: data.price_cost,
          pre_price_cost: data.pre_price_cost,
          group: data.group,
          minimum: data.minimum,
          price_classification: data.price_classification,
        };

        const findProductGroup = optionsData.product_groups.find(
          (s) => Number(s.id) === Number(data.group)
        );
        const findPriceClassification = optionsData.price_classification.find(
          (s) => Number(s.id) === Number(data.price_classification)
        );

        const findStatus = status.find(
          (s) => Number(s.cod) === Number(data.status)
        );
        const findDatasheet = datasheets.find(
          (s) => Number(s.id) === Number(idEditableRow)
        );

        let erros: string[] = [];

        if (findDatasheet) {
          findStatus?.required_fields.forEach((field) => {
            //@ts-ignore
            if (!findDatasheet[field.field.name]) {
              erros.push(field.field.description);
            }
          });

          if (erros.length === 0) {
            await api.put(
              `/headerDatasheet/datasheet/${idEditableRow}`,
              updateDatasheet
            );

            setDatasheets((oldDatasheets) => {
              return oldDatasheets.map((datasheet) => {
                if (Number(datasheet.id) === Number(idEditableRow)) {
                  return {
                    ...datasheet,
                    description: data.description,
                    status: findStatus ? findStatus : datasheet.status,
                    price_sale: Number(data.price_sale),
                    price_cost: Number(data.price_cost),
                    minimum: Number(data.minimum),
                    pre_price_cost: Number(data.pre_price_cost),
                    product_group: findProductGroup?.id
                      ? {
                          id: findProductGroup?.id,
                          name: findProductGroup?.name,
                        }
                      : undefined,

                    price_classification: findPriceClassification?.id
                      ? {
                          id: findPriceClassification?.id,
                          name: findPriceClassification?.name,
                        }
                      : undefined,
                  };
                }

                return datasheet;
              });
            });

            addToast("Ficha técnica atualizada com sucesso", {
              appearance: "success",
              autoDismiss: true,
            });

            setIdEditableRow("");
          } else {
            addToast(
              `Para selecionar esta situação e necessário preencher os campos: ${erros.join(
                ", "
              )}`,
              {
                appearance: "warning",
                autoDismiss: true,
              }
            );
          }
        }
      } catch (error) {
        addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        if (!Boolean(match.params.id)) history.push("/404");
        if (!Boolean(admin) && !Boolean(provider)) history.push("/404");

        if (admin) {
          const headerDatasheetDB = await api.get<HeaderDatasheetProps>(
            `/headerDatasheet/show/${match.params.id}`
          );
          if (!Boolean(headerDatasheetDB.data)) {
            return history.push("/404");
          }
          setContentPage(headerDatasheetDB.data);
        }
      } catch (error) {
        return history.push("/404");
      }
    })();
    // eslint-disable-next-line
  }, [admin, provider, match.params, updateHeader]);

  useEffect(() => {
    (async () => {
      if ((admin && contentPage?.id) || provider) {
        setLoadingPanel(true);

        const query = admin ? contentPage?.id : "all";

        const datasheets = await api.get<IResponseAPI>(
          `/headerDatasheet/datasheet/list/${query}`,
          {
            params: {
              limitRequest: 15,
              pageRequest: page - 1,
              ...filters,
              orderBy: orderBy,
            },
          }
        );

        if (!Boolean(datasheets.data)) {
          return history.push("/404");
        }

        const {
          contents,
          limit,

          pages,
          total,
          filters: filtersData,
        } = datasheets.data;

        setDatasheets(contents);
        setFiltersOptions(filtersData);
        setPagination({ limit, page: datasheets.data.page, pages, total });
        setLoadingPanel(false);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [contentPage, admin, update, page]);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const statusDB = await api.get<StatusProps[]>(
        `/headerDatasheet/datasheet/status`,
        {
          params: {
            // defineStatusDatasheetId: data && data?.id,
            // users: type === "update" ? false : true,
          },
        }
      );

      setStatus(statusDB.data);

      const optionsDataDB = await api.get<OptionsDataProps>(
        `/headerDatasheet/datasheet/options`
      );
      setOptionsData(optionsDataDB.data);
    })();
  }, []);

  function hadleToNew() {
    setActiveCreateDatasheetModal(true);
  }

  async function handleFinishForecastSeller() {
    setConfirmationFinished(false);

    addToast("Envio de forecast realizada com sucesso.", {
      appearance: "success",
      autoDismiss: true,
    });

    return history.push("/representante/previsoes");
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    let data: any;

    if (name === "minimum") {
      data = Number(value.replace(/\D/g, ""));
    }

    if (
      name === "price_cost" ||
      name === "price_sale" ||
      name === "pre_price_sale" ||
      name === "pre_price_cost"
    ) {
      data = Number(value.replace(/\D/g, "")) / 100;
    }

    if (
      name === "barcode_ncm" ||
      name === "barcode_cest" ||
      name === "barcode_gpc"
    ) {
      data = value.replace(/\D/g, "");
    }

    formikEditableRow.setFieldValue(name, data);
  }

  function handleFilter(filter: IFilter) {
    setFilters({
      ...filter,
      alternative_code: filter.alternative_code?.toUpperCase(),
    });
    setPage(1);
    setViewFilter(false);
  }
  function selectDescription(st: any, title: string) {
    let data = String(st);
    const arrTitle = [
      "status",
      "collection",
      "brand",
      "category",
      "genre",
      "provider",
    ];
    if (arrTitle.includes(title)) {
      //@ts-ignore
      filtersOptions[title].forEach((item) => {
        if (Number(item.id) === Number(st)) {
          return (data = item.name);
        }
      });
    }

    return data;
  }

  async function handleDelete() {
    setLoadingModal(true);
    try {
      await api.delete(`/headerDatasheet/${contentPage?.id}`);

      setConfirmationDelete(false);
      history.push(`/admin/cabecalho-fichas-tecnicas`);

      setLoadingModal(false);
      addToast("Coleção da ficha Técnica excluído com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      const error = err as any;

      if (+error?.response?.status === 400) {
        setLoadingModal(false);
        return addToast(
          "Coleção não pode ser excluida porque possui fichas técnicas cadastradas",
          {
            appearance: "warning",
            autoDismiss: true,
          }
        );
      }

      setLoadingModal(false);
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function hadleReportCreate(type: number) {
    try {
      setLoadingReport(true);
      const datasheetsDB = await api.get(
        `/headerDatasheet/datasheet/export/${contentPage?.id}`,
        { params: { ...filters, orderBy: orderBy, typeReport: type } }
      );

      var typeReport = type === 1 ? "Coleção" : type === 2 ? "Completo" : "";

      const workSheet = XLSX.utils.json_to_sheet(datasheetsDB.data);
      const workBook = XLSX.utils.book_new();
      await XLSX.utils.book_append_sheet(workBook, workSheet, "aba");
      //Binary string
      await XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
      //Download
      await XLSX.writeFile(
        workBook,
        `${typeReport}-${contentPage?.brand.name}-${contentPage?.collection}-${contentPage?.year}.xlsx`
      );

      setLoadingReport(false);
    } catch (error) {
      setLoadingReport(false);
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleGenerateEanAll() {
    try {
      const email = {
        email: userData.email,
        name: `${userData.name} ${userData.lastname}`,
      };

      await api.post(`/headerDatasheet/generate/barcodes/${contentPage?.id}`, {
        email: email,
      });

      setVisibleAction(false);

      addToast(
        `Este processo pode demorar alguns minutos, então vai ser processado em segundo plano ao fim do mesmo recebera um e-mail em ${email.email} para confirmação do processo`,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  async function handleGenerateEanSampleAll() {
    try {
      const email = {
        email: userData.email,
        name: `${userData.name} ${userData.lastname}`,
      };

      await api.post(
        `/headerDatasheet/generate/barcodesSample/${contentPage?.id}`,
        { email: email }
      );

      setVisibleAction(false);

      addToast(
        `Este processo pode demorar alguns minutos, então vai ser processado em segundo plano ao fim do mesmo recebera um e-mail em ${email.email} para confirmação do processo`,
        {
          appearance: "success",
          autoDismiss: true,
        }
      );
    } catch (error) {
      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }
  function validadeteRequiredField(name: string, value: any): boolean {
    let error = true;

    const findOneStatus = status.find(
      (s) => Number(s.cod) === Number(formikEditableRow.values.status)
    );

    if (
      findOneStatus?.required_fields.some((item) => item.field.name === name)
    ) {
      if (value === undefined || value === "" || value === null) error = false;
    }

    return error;
  }

  async function openRow(datasheet: DatasheetProps) {
    const search = query.toString();

    admin
      ? history.push(
          `/admin/cabecalho-ficha-tecnica/${contentPage?.id}/ficha-tecnica/${datasheet.id}`,
          { search }
        )
      : history.push(
          `/fornecedor/cabecalho-ficha-tecnica/${datasheet.headerDatasheetId}/ficha-tecnica/${datasheet.id}`,
          { search }
        );
  }

  async function handleSubmit() {
    formikEditableRow.submitForm();
  }

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });
  useOutsideAlerter(outSideRefReport, () => {
    if (visibleReport) setVisibleReport(false);
  });
  useOutsideAlerter(outSideRefAction, () => {
    if (visibleAction) setVisibleAction(false);
  });

  return (
    <Container>
      {!loading ? (
        <>
          {admin && contentPage && (
            <>
              <header
                style={{ justifyContent: "space-between", flexWrap: "wrap" }}
              >
                <div style={{ display: "flex" }}>
                  <button
                    type="button"
                    onClick={() => {
                      history.push(
                        `/${
                          admin ? "admin" : "provider"
                        }/cabecalho-fichas-tecnicas`
                      );
                    }}
                    className="arrowBack"
                  >
                    <FaArrowLeft size={18} />
                  </button>

                  <h2>Detalhes da Coleção de Fichas Técnicas</h2>

                  <div className="containerActions">
                    {contentPage.status.is_generate_book && (
                      <button
                        type="button"
                        className="buttonActionDatasheet"
                        onClick={() => {
                          setModalGenerateBook(true);
                        }}
                      >
                        {
                          <div>
                            <span>
                              <ImBook color="#4d2700" />
                            </span>
                            Book
                          </div>
                        }
                      </button>
                    )}

                    {admin && permission && permission?.datasheet > 2 && (
                      <>
                        <button
                          type="button"
                          className="buttonActionDatasheet"
                          onClick={() => {
                            setActiveUpdateHeaderDatasheetModal(true);
                          }}
                        >
                          {
                            <div>
                              <span>
                                <FiEdit color="#8f6e09" />
                              </span>
                              Editar
                            </div>
                          }
                        </button>
                        {datasheets.length <= 0 && (
                          <button
                            type="button"
                            className="buttonActionDatasheet"
                            onClick={() => {
                              setConfirmationDelete(true);
                            }}
                          >
                            {
                              <div>
                                <span>
                                  <RiDeleteBinLine color="#d21e26" />
                                </span>
                                Excluir
                              </div>
                            }
                          </button>
                        )}
                      </>
                    )}

                    <ReportDownload
                      onClick={() => setVisibleReport(!visibleReport)}
                      style={{ marginLeft: 10 }}
                    >
                      {loadingReport ? (
                        <Loading
                          borderSize={5}
                          size={20}
                          colorLoading="#259457"
                          borderColor="rgba(32, 114, 69, 0.5)"
                        />
                      ) : (
                        <div className="icon-execel">
                          <RiFileExcel2Line color="#207245" size={24} />
                          <div
                            className={
                              visibleReport ? "row-excel-active" : "row-excel"
                            }
                          >
                            <MdArrowDownward color="#207245" size={16} />
                          </div>
                        </div>
                      )}

                      {visibleReport && (
                        <ul ref={outSideRefReport} className="optionReport">
                          <li onClick={() => hadleReportCreate(2)}>
                            <div className="icon-execel">
                              <RiFileExcel2Line color="#207245" size={18} />
                            </div>
                            <span>Exportar fichas</span>
                          </li>
                          {/* <li onClick={() => hadleReportCreate(5)}>
                          <div className="icon-execel">
                            <RiFileExcel2Line color="#207245" size={18} />
                          </div>
                          <span>Exportar Variações</span>
                        </li>
                        <li onClick={() => hadleReportCreate(4)}>
                          <div className="icon-execel">
                            <RiFileExcel2Line color="#207245" size={18} />
                          </div>
                          <span>Gerar miniaturas</span>
                        </li> */}

                          <li onClick={() => hadleReportCreate(1)}>
                            <div className="icon-execel">
                              <RiFileExcel2Line color="#207245" size={18} />
                            </div>
                            <span>Exportar em coleção [SIGER]</span>
                          </li>
                          <li onClick={() => hadleReportCreate(7)}>
                            <div className="icon-execel">
                              <RiFileExcel2Line color="#207245" size={18} />
                            </div>
                            <span>Cadastro Ean [SIGER]</span>
                          </li>
                          {/* <li onClick={() => hadleReportCreate(6)}>
                          <div className="icon-execel">
                            <RiFileExcel2Line color="#207245" size={18} />
                          </div>
                          <span>Cadastro Produto [SANKHYA]</span>
                        </li>
                        <li onClick={() => hadleReportCreate(3)}>
                          <div className="icon-execel">
                            <RiFileExcel2Line color="#207245" size={18} />
                          </div>
                          <span>Cadastro Ean produto [SANKHYA]</span>
                        </li> */}
                        </ul>
                      )}
                    </ReportDownload>
                  </div>
                </div>

                {admin && (
                  <div>
                    <div>{`Média aritmética: ${contentPage.markupMediaAritmetica}`}</div>
                    <div>{`Média ponderada: ${contentPage.markupMediaPonderada}`}</div>
                  </div>
                )}
              </header>

              <InfoStatus>
                <div className="containerStatus">
                  <div className="box">
                    <div>
                      <h5>COLEÇÃO - ANO</h5>
                    </div>
                    <p>
                      {contentPage.year && contentPage.collection
                        ? `${contentPage.collection} - ${contentPage.year}`
                        : `-`}
                    </p>
                  </div>
                </div>

                <div className="containerStatus">
                  <div className="box">
                    <div>
                      <h5>Situação</h5>
                    </div>

                    <span
                      style={{
                        backgroundColor: contentPage.status.color,
                      }}
                    >
                      {contentPage.status.name}
                    </span>
                  </div>
                </div>
                <div className="containerStatus">
                  <div className="box">
                    <div>
                      <h5>MARCA</h5>
                    </div>
                    <p>
                      {contentPage.brand !== undefined
                        ? contentPage.brand.name
                        : `-`}
                    </p>
                  </div>
                </div>

                {contentPage.finished ? (
                  <div className="containerStatus">
                    <div className="box">
                      <div>
                        <h5>DATA FINALIZAÇÃO</h5>
                      </div>
                      <p>
                        {contentPage.finished_at
                          ? moment(contentPage.finished_at)
                              .add("days", 1)
                              .format("DD/MM/YYYY")
                          : "-"}
                      </p>
                    </div>
                  </div>
                ) : null}
              </InfoStatus>
            </>
          )}

          {provider && (
            <header>
              <h2>Lista de Fichas Técnicas</h2>
            </header>
          )}

          <PanelFilter
            filters={filters}
            setFilters={setFilters}
            columns={columns}
            onSubmit={formikFilter.handleSubmit}
            onResetForm={formikFilter.resetForm}
            setViewFilter={setViewFilter}
            viewFilter={viewFilter}
            normalizedFilters={selectDescription}
          >
            <GroupInput>
              <Input
                label="Ref"
                {...formikFilter.getFieldProps("alternative_code")}
              />
              <Input
                label="Descrição"
                {...formikFilter.getFieldProps("description")}
              />
            </GroupInput>

            <Select
              label="Situação"
              data={filtersOptions.status.map((status) => ({
                name: String(status.name),
                value: String(status.id),
              }))}
              {...formikFilter.getFieldProps("status")}
            />

            <GroupInput>
              <Select
                label="Classe de produto"
                data={filtersOptions.category.map((category) => ({
                  name: String(category.name),
                  value: String(category.id),
                }))}
                {...formikFilter.getFieldProps("category")}
              />
              <Select
                label="Gênero"
                data={filtersOptions.genre.map((genre) => ({
                  name: String(genre.name),
                  value: String(genre.id),
                }))}
                {...formikFilter.getFieldProps("genre")}
              />
              <Select
                label="Fornecedor"
                data={filtersOptions.provider.map((provider) => ({
                  name: String(provider.name),
                  value: String(provider.id),
                }))}
                {...formikFilter.getFieldProps("provider")}
              />
            </GroupInput>
          </PanelFilter>

          <div>
            <OrderBy
              data={[
                {
                  name: "Maior Cód. Alternativo",
                  value: "alternative_code.desc",
                },
                {
                  name: "Menor Cód. Alternativo",
                  value: "alternative_code.asc",
                },

                {
                  name: "Situação",
                  value: "statusId.desc",
                },

                {
                  name: "Recentes Criação",
                  value: "created_at.desc",
                },
                {
                  name: "Antigas Criação",
                  value: "created_at.asc",
                },
              ]}
              setState={setOrderBy}
              state={orderBy}
              setUpdate={() => {
                setUpdate(update + 1);
              }}
            />
          </div>

          <ContainerList>
            <div className="headerTable">
              <div style={{ display: "flex", alignItems: "center" }}>
                <h3>Fichas Técnicas</h3>
              </div>

              <div className="container-actions">
                <Reaload onClick={() => setUpdate(update + 1)}>
                  <IoReload size={19} />
                  <span>RECARREGAR</span>
                </Reaload>

                {admin &&
                  permission &&
                  permission.datasheet > 2 &&
                  !contentPage?.status.finisher && (
                    <>
                      <button
                        onClick={() => {
                          handleGenerateEanAll();
                          handleGenerateEanSampleAll();
                        }}
                      >
                        <IoIosBarcode color="#fff" />
                        <span>Gerar EANs</span>
                      </button>

                      <button onClick={hadleToNew} type="button">
                        <MdAdd size={22} /> <span> NOVA FICHA TÉCNICA </span>
                      </button>

                      <button
                        onClick={() => {
                          setActiveModalLinkDatasheet(true);
                        }}
                        type="button"
                      >
                        <AiOutlineLink size={22} />{" "}
                        <span> ADICIONAR FICHAS TÉCNICAS </span>
                      </button>
                    </>
                  )}
              </div>
            </div>

            <TableContainer>
              <TableEditableV2
                isLoading={loadingPanel}
                idEditableRow={idEditableRow}
                colums={[
                  {
                    field: "action",

                    title: "",
                    render: (row, key) =>
                      String(idEditableRow) === String(row?.id) ? (
                        <td key={key}>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <BtnAction
                              style={{ marginBottom: 2, background: "#259457" }}
                              onClick={() => handleSubmit()}
                            >
                              <BsCheckCircle />
                            </BtnAction>
                            <BtnAction onClick={() => setIdEditableRow("")}>
                              <MdOutlineCancel />
                            </BtnAction>
                          </div>
                        </td>
                      ) : (
                        <ExternalLink
                          key={key}
                          style={{
                            height: "8rem",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {admin && permission && permission.datasheet > 2 && (
                            <button
                              style={{
                                background: "transparent",
                                marginRight: 4,
                              }}
                              onClick={() => {
                                formikEditableRow.setFieldValue(
                                  "description",
                                  row?.description
                                );
                                formikEditableRow.setFieldValue(
                                  "status",
                                  row?.status.cod
                                );
                                formikEditableRow.setFieldValue(
                                  "price_sale",
                                  row?.price_sale
                                );
                                formikEditableRow.setFieldValue(
                                  "pre_price_cost",
                                  row?.pre_price_cost
                                );
                                formikEditableRow.setFieldValue(
                                  "price_cost",
                                  row?.price_cost
                                );
                                formikEditableRow.setFieldValue(
                                  "group",
                                  row?.product_group?.id
                                );
                                formikEditableRow.setFieldValue(
                                  "minimum",
                                  row?.minimum
                                );
                                formikEditableRow.setFieldValue(
                                  "price_classification",
                                  row?.price_classification?.id
                                );
                                setIdEditableRow(String(row.id));
                              }}
                            >
                              <BiEditAlt color="#333" size={24} />
                            </button>
                          )}
                          {/* <button
                            style={{
                              background: "transparent",
                              marginRight: 4,
                            }}
                            onClick={() => openRow(row)}
                          >
                            <AiFillFolderOpen color="#333" size={24} />
                          </button> */}
                          <ExternalLinkA
                            href={
                              admin
                                ? `${APP_LINK}/admin/cabecalho-ficha-tecnica/${contentPage?.id}/ficha-tecnica/${row.id}`
                                : provider
                                ? `${APP_LINK}/fornecedor/cabecalho-ficha-tecnica/${row.headerDatasheetId}/ficha-tecnica/${row.id}`
                                : `404`
                            }
                            target="_blank"
                          >
                            <BiLinkExternal size={24} />
                          </ExternalLinkA>
                        </ExternalLink>
                      ),
                  },

                  {
                    field: "files",
                    title: "IMAGEM",
                    render: (row, key) => (
                      <td
                        key={key}
                        className="td-img-logo"
                        onClick={() => openRow(row)}
                      >
                        <LazyLoadImage
                          loading="lazy"
                          // effect="blur"
                          src={row.files[0] ? row.files[0].url : NOIMAGE_LINK}
                          alt={row.description}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = NOIMAGE_LINK;
                          }}
                        />
                      </td>
                    ),
                  },

                  {
                    field: "alternative_code",
                    title: "REF",
                    render: (row, key) => (
                      <td key={key}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <p>{row.alternative_code}</p>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "15px 15px 15px",
                              gap: 2,
                              marginTop: 6,
                            }}
                          >
                            {row?.variations
                              ?.filter((variation) => !variation.is_drop)
                              ?.map((item) => (
                                <div
                                  className={
                                    item?.primary_color_hex
                                      ? undefined
                                      : "no-color"
                                  }
                                  style={{
                                    background:
                                      item?.primary_color_hex ?? "#fff",
                                    borderRadius: "50%",
                                    height: 15,
                                    width: 15,
                                    border: "0.5px solid #333",
                                  }}
                                ></div>
                              ))}
                          </div>
                        </div>
                      </td>
                    ),
                  },
                  {
                    field: "status.name",
                    title: "SITUAÇÃO",
                    render: (row, key) => (
                      <td key={key} onClick={() => openRow(row)}>
                        <span
                          className="status"
                          style={{ backgroundColor: row.status.color }}
                        >
                          {row.status.name}
                        </span>
                      </td>
                    ),
                    editableRow: (_, key) => (
                      <td key={key}>
                        <Select
                          style={{ maxWidth: 150 }}
                          {...formikEditableRow.getFieldProps("status")}
                          data={status.map((element) => ({
                            value: element.cod,
                            name: element.name,
                          }))}
                          error={
                            formikEditableRow.touched.status &&
                            formikEditableRow.errors.status
                              ? formikEditableRow.errors.status
                              : undefined
                          }
                        />
                      </td>
                    ),
                  },
                  {
                    field: "description",
                    title: "DESCRIÇÃO",
                    render: (row, key) => (
                      <td
                        key={key}
                        style={{ maxWidth: 30 }}
                        onClick={() => openRow(row)}
                      >
                        {row.description}
                      </td>
                    ),
                    editableRow: (_, key) => (
                      <td key={key}>
                        <Input
                          {...formikEditableRow.getFieldProps("description")}
                          error={
                            formikEditableRow.touched.description &&
                            formikEditableRow.errors.description
                              ? formikEditableRow.errors.description
                              : undefined
                          }
                        />
                      </td>
                    ),
                  },
                  {
                    field: "price_cost",
                    title: "PRÉ-CUSTO",
                    render: (row, key) => (
                      <td key={key} onClick={() => openRow(row)}>
                        {row?.pre_price_cost
                          ? Number(row?.pre_price_cost)?.toLocaleString(
                              "pt-br",
                              {
                                style: "currency",
                                currency: "BRL",
                              }
                            )
                          : "-"}
                      </td>
                    ),
                    editableRow: (_, key) => (
                      <td key={key}>
                        <input
                          name="pre_price_cost"
                          style={{ width: 75 }}
                          onChange={handleInputChange}
                          onBlur={formikEditableRow.handleBlur(
                            "pre_price_cost"
                          )}
                          value={Number(
                            formikEditableRow.values.pre_price_cost ?? 0
                          )?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        />
                      </td>
                    ),
                  },
                  {
                    field: "price_cost",
                    title: "CUSTO",
                    render: (row, key) => (
                      <td key={key} onClick={() => openRow(row)}>
                        {Number(row?.price_cost)?.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                    ),
                    editableRow: (_, key) => (
                      <td key={key}>
                        <input
                          name="price_cost"
                          style={{ width: 75 }}
                          onChange={handleInputChange}
                          onBlur={formikEditableRow.handleBlur("price_cost")}
                          value={Number(
                            formikEditableRow.values.price_cost
                          )?.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        />
                      </td>
                    ),
                  },
                  {
                    field: "price_sale",
                    title: "PDV",
                    render: (row, key) => (
                      <td key={key} onClick={() => openRow(row)}>
                        <div>
                          <p>
                            {Number(row?.price_sale)?.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </p>
                          <p
                            style={{
                              marginTop: 4,
                              fontWeight: "bold",
                              color:
                                row.price_cost && row.price_sale
                                  ? Number(row.price_sale / row.price_cost) <
                                    row.header_datasheet.markUpStore
                                    ? "#d21e26"
                                    : "#259457"
                                  : undefined,
                            }}
                          >
                            {row.price_cost && row.price_sale
                              ? Number(row.price_sale / row.price_cost).toFixed(
                                  2
                                )
                              : "-"}
                          </p>
                        </div>
                      </td>
                    ),
                    editableRow: (row, key) => (
                      <td key={key}>
                        <div>
                          <p>
                            <input
                              name="price_sale"
                              style={{ width: 75 }}
                              onChange={handleInputChange}
                              onBlur={formikEditableRow.handleBlur(
                                "price_sale"
                              )}
                              value={Number(
                                formikEditableRow.values.price_sale
                              )?.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                              })}
                            />
                          </p>

                          <p style={{ marginTop: 3 }}>
                            {row.price_cost &&
                            formikEditableRow.values.price_sale
                              ? Number(
                                  Number(formikEditableRow.values.price_sale) /
                                    row.price_cost
                                ).toFixed(2)
                              : "-"}
                          </p>
                        </div>
                      </td>
                    ),
                  },

                  {
                    field: "price_classification",
                    title: "FAIXA",
                    render: (row, key) => (
                      <td key={key} onClick={() => openRow(row)}>
                        {row.price_classification?.name ?? "-"}
                      </td>
                    ),
                    editableRow: (_, key) => (
                      <td key={key}>
                        <Select
                          style={{ width: 40 }}
                          {...formikEditableRow.getFieldProps(
                            "price_classification"
                          )}
                          data={optionsData.price_classification.map(
                            (classification) => ({
                              value: Number(classification.id),
                              name: classification.name,
                            })
                          )}
                          error={
                            formikEditableRow.touched.price_classification &&
                            formikEditableRow.errors.price_classification
                              ? formikEditableRow.errors.price_classification
                              : undefined
                          }
                        />
                      </td>
                    ),
                  },

                  {
                    field: "minimum",
                    title: "MÍNIMO",
                    render: (row, key) => (
                      <td key={key} onClick={() => openRow(row)}>
                        {row.minimum ?? "-"}
                      </td>
                    ),
                    editableRow: (_, key) => (
                      <td key={key}>
                        <input
                          name="minimum"
                          style={{ width: 75 }}
                          onChange={handleInputChange}
                          onBlur={formikEditableRow.handleBlur("minimum")}
                          value={formikEditableRow.values.minimum}
                        />
                      </td>
                    ),
                  },

                  {
                    field: "group",
                    title: "CATEGORIA",
                    render: (row, key) => (
                      <td key={key} onClick={() => openRow(row)}>
                        {row.product_group?.name ?? "-"}
                      </td>
                    ),
                    editableRow: (_, key) => (
                      <td key={key}>
                        <Select
                          style={{ width: 75 }}
                          {...formikEditableRow.getFieldProps("group")}
                          data={optionsData.product_groups.map((element) => ({
                            value: element.id,
                            name: element.name,
                          }))}
                          error={
                            formikEditableRow.touched.group &&
                            formikEditableRow.errors.group
                              ? formikEditableRow.errors.group
                              : undefined
                          }
                        />
                      </td>
                    ),
                  },
                  {
                    field: "provider",
                    title: "FORNECEDOR",
                    render: (row, key) => (
                      <td key={key} onClick={() => openRow(row)}>
                        {row.provider[0] ? row.provider[0]?.fullname : "-"}
                      </td>
                    ),
                  },
                ]}
                data={datasheets}
              />
            </TableContainer>
            <Pagination
              totalCountOfRegisters={pagination.total}
              registersPerPage={pagination.limit}
              currentPage={page}
              onPageChange={setPage}
            />
          </ContainerList>

          {contentPage && (
            <>
              <ModalGenerateBook
                modal={modalGenerateBook}
                setModal={setModalGenerateBook}
                headerDatasheetId={contentPage.id}
              />
              <Modal
                title="Ficha Técnica"
                setModalVisible={setActiveCreateDatasheetModal}
                modalVisible={activeCreateDatasheetModal}
                maxWidth={1100}
                maxHeight={650}
                scrollOff
                isShouldCloseOnEscAndClickOffline
              >
                <ModalModificationDatasheet
                  type="create"
                  onRequestUpdate={() => setUpdate(update + 1)}
                  data={
                    {
                      headerDatasheetId: contentPage.id,
                      header_datasheet: { brand: { ...contentPage.brand } },
                    } as HeaderDatasheetAndDatasheet
                  }
                  onRequestClose={() => setActiveCreateDatasheetModal(false)}
                  headerDatasheet={{
                    id: contentPage.id,
                    collection: contentPage.collection,
                    year: contentPage.year,
                  }}
                />
              </Modal>

              <Modal
                title="Adicionar Fichas técnicas"
                setModalVisible={setActiveModalLinkDatasheet}
                modalVisible={activeModalLinkDatasheet}
                maxWidth={800}
                scrollOff
              >
                <ModalLinkDatasheet
                  isOpen={activeModalLinkDatasheet}
                  onRequestClose={() => setActiveModalLinkDatasheet(false)}
                  onRequestUpdate={() => setUpdate((old) => old + 1)}
                  brandId={contentPage.brand.cod}
                  headerDatasheetId={contentPage.id}
                />
              </Modal>

              {activeUpdateHeaderDatasheetModal && (
                <Modal
                  title="Coleção de Fichas Técnicas"
                  modalVisible={activeUpdateHeaderDatasheetModal}
                  setModalVisible={setActiveUpdateHeaderDatasheetModal}
                  maxWidth={1100}
                  scrollOff={true}
                  maxHeight={450}
                >
                  <ModalModificationHeaderDatasheet
                    type="update"
                    data={contentPage}
                    onRequestClose={() => {
                      setActiveUpdateHeaderDatasheetModal(false);
                    }}
                    onRequestUpdate={() => setUpdateHeader(updateHeader + 1)}
                  />
                </Modal>
              )}
            </>
          )}

          {confirmationFinished && (
            <Modal
              title="Selecione o cliente"
              modalVisible={confirmationFinished}
              setModalVisible={setConfirmationFinished}
              headerOff={true}
              maxHeight={350}
              maxWidth={650}
            >
              <PopUpConfirmation>
                <div className="headerPopUp">
                  <nav>
                    <FiSend size={95} opacity={0.2} />
                  </nav>
                  <span>
                    Você tem certeza que deseja finalizar o forecast? Após o
                    envio os dados não poderão ser alterados.
                  </span>
                </div>
                <div className="buttonsPopUp">
                  <button
                    type="button"
                    onClick={() => setConfirmationFinished(false)}
                  >
                    Cancelar
                  </button>
                  <button type="button" onClick={handleFinishForecastSeller}>
                    Finalizar
                  </button>
                </div>
              </PopUpConfirmation>
            </Modal>
          )}
          {confirmationDelete && (
            <Modal
              title="Selecione o cliente"
              modalVisible={confirmationDelete}
              setModalVisible={setConfirmationDelete}
              headerOff={true}
              maxHeight={350}
              maxWidth={650}
            >
              <PopUpConfirmation>
                <div className="headerPopUp">
                  <nav>
                    <FaTrashAlt size={95} opacity={0.2} />
                  </nav>
                  <span>
                    Você tem certeza que deseja excluir esta Coleção de fichas
                    Técnicas?
                  </span>
                </div>
                <div className="buttonsPopUp">
                  <button
                    type="button"
                    onClick={() => setConfirmationDelete(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    style={{ background: "#d21e26" }}
                    type="button"
                    onClick={() => (loadingModal ? () => {} : handleDelete())}
                  >
                    {!loadingModal ? (
                      "Deletar"
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <Loading
                          size={22}
                          borderSize={4}
                          colorLoading="rgba(255,255,255)"
                          borderColor="rgba(255,255,255, 0.3)"
                        />
                      </div>
                    )}
                  </button>
                </div>
              </PopUpConfirmation>
            </Modal>
          )}
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default Datasheet;
