export default function regraTres(value: number, total: number) {
  let percentage = 0;

  let diferenca = value;

  let diferencaData = diferenca / total;

  percentage = Math.round(diferencaData * 100);

  return percentage;
}
