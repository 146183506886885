import { FormikProps } from "formik";

export function setFormikValues(
  data: any,
  formik: FormikProps<any>,
  setLoading?: (loading: boolean) => void
) {
  const arrKeys = Object.keys(data);
  const arrValues = Object.values(data);

  arrKeys.forEach((key, index) => {
    const value = arrValues[index];

    if (value !== undefined && value !== null) {
      formik.setFieldValue(key, value);
    } else {
      formik.setFieldValue(key, "");
    }

    if (setLoading) setLoading(false);
  });
}
