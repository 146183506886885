import React, { useEffect, useState } from "react";
import { AiFillAndroid } from "react-icons/ai";
import { FaApple } from "react-icons/fa";
import { HiExternalLink } from "react-icons/hi";
import ReactPlayer from "react-player";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";

import api from "../../service/api";

import { Input } from "../../components/Form/Input";
import Loading from "../../components/Loading";
import { useContacts } from "../../hook/queries/useContact";
import { ScreenLoading } from "../create/styles";
import { Card, Container, Content, InnerHtml } from "./style";

interface IHeaderProps {
  match: {
    params: {
      id: string;
    };
  };
}

interface ISeller {
  cod: number;
  fullName?: string;
  abbreviation?: string;
  email: string;
  nextdata_login?: string;
  nextdata_password?: string;
  brands: number[];
}

interface ResponseWelcomeSeller {
  id: string;
  created_at: Date;
  seller: ISeller;
}

export const Welcome: React.FC<IHeaderProps> = ({ match }) => {
  const history = useHistory();
  const [data, setData] = useState<ResponseWelcomeSeller>(
    {} as ResponseWelcomeSeller
  );
  const { data: getContacts } = useContacts(1, ``, 100);
  const [invalidDate, setInvalidDate] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (match.params.id) {
          const response = await api.get<ResponseWelcomeSeller>(
            `/seller/welcome/${match.params.id}`
          );

          const validDate = new Date();
          validDate.setDate(validDate.getDate() + 20);

          if (response && new Date(response.data.created_at) <= validDate) {
            await api.patch(`/seller/welcome/${match.params.id}`);
            setData(response.data);
          } else {
            setInvalidDate(true);
          }
        } else {
          history.push("/");
        }
      } catch (error) {
        history.push("/");
      }
    })();
    // eslint-disable-next-line
  }, [match.params.id]);

  if (!data?.id) {
    return (
      <ScreenLoading>
        <Loading />
      </ScreenLoading>
    );
  }
  if (invalidDate) {
    return (
      <ScreenLoading style={{ flexDirection: "column" }}>
        <img
          src="https://alpar.sfo3.digitaloceanspaces.com/Alpar/alert.png"
          alt="handshake"
          style={{ height: 150, marginBottom: 40 }}
        />

        <h1>Link de boas vindas expirado. </h1>
      </ScreenLoading>
    );
  }

  return (
    <Container>
      <img
        src="/logo-red.png"
        alt="Logo"
        style={{ width: 200, marginBottom: "1rem" }}
      />
      <h2>Olá, {data?.seller?.fullName}</h2>
      <h1>Seja bem-vindo ao time Alpar do Brasil</h1>
      <br />

      <Content>
        <h2>Sistemas</h2>

        <Card>
          <div>
            <h3>Portal - Alpar do Brasil</h3>
            <p>
              Portal interno do representante para consulta, analise e relatório
              de seus pedidos, consulta e andamento de devoluções, geração de 2ª
              via Boletos de seus pedidos, aba exclusiva com videos tutorias
              para os sistemas.
            </p>

            <a
              href="https://portal.alpardobrasil.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HiExternalLink size={20} /> Acessar
            </a>
          </div>

          <div>
            <ReactPlayer
              url="https://youtu.be/RWMZ55f83ic"
              width="100%"
              height="18rem"
              style={{ borderRadius: 8, overflow: "hidden" }}
              controls
            />
          </div>
        </Card>
        <Card>
          <div>
            <h3>Nextdata Commerce</h3>
            <p>
              Força de vendas, aplicativo móvel disponível para digitação de
              pedidos.
            </p>

            <h4>Pré-requisito:</h4>
            <span>
              Apenas tablets, marcas homologadas: Samsung e Apple. Indicamos que
              este aparelho possua internet móvel para que seja possível
              trabalhar na rua.
            </span>

            <div className="requirements">
              <div>
                <h4>Apple:</h4>
                <ul>
                  <li>Com no mínimo 32GB de armazenamento.</li>
                  <li>Tamanho de tela 9.7.</li>
                  <li>Versão do sistema IOS 15.0 ou superior.</li>
                </ul>

                <QRCode
                  value="https://apps.apple.com/br/app/nextdata-commerce/id1173005545"
                  style={{ height: 100, marginTop: "2rem" }}
                />

                <a
                  href="https://apps.apple.com/br/app/nextdata-commerce/id1173005545"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ background: "#000" }}
                >
                  <FaApple size={20} />
                  Baixar
                </a>
              </div>

              <div>
                <h4>Samsung:</h4>
                <ul>
                  <li>Com no mínimo 16GB de armazenamento.</li>
                  <li>Tamanho de tela 9.7.</li>
                  <li>Versão do sistema Android igual ou superior a 12.0.</li>
                </ul>

                <QRCode
                  style={{ height: 100, marginTop: "2rem" }}
                  value="https://play.google.com/store/apps/details?id=br.com.nextdata.app.commerce2&hl=pt"
                />

                <a
                  href="https://play.google.com/store/apps/details?id=br.com.nextdata.app.commerce2&hl=pt"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ background: "#01875f" }}
                >
                  <AiFillAndroid size={20} />
                  Baixar
                </a>
              </div>
            </div>

            <h3 style={{ marginTop: "1rem", marginBottom: "1rem" }}>Acessos</h3>

            <Input
              name="nextdata_password"
              label="Login"
              value={data?.seller?.nextdata_login}
              readOnly
              isCopyClipboard
            />
            <Input
              name="nextdata_login"
              label="Senha"
              value={data?.seller?.nextdata_password}
              readOnly
              isCopyClipboard
            />
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <ReactPlayer
              url="https://youtu.be/D_wIlFxx7OY"
              width="100%"
              height="18rem"
              style={{ borderRadius: 8, overflow: "hidden" }}
              controls
            />
          </div>
        </Card>
        <Card>
          <div>
            <h3>App Alpar do Brasil</h3>
            <p>
              Visualização de todos os produtos disponíveis para venda,
              funcionalidade de gerar catálogos e relatórios para serem
              compartilhados com cliente visando auxilio da venda.
            </p>

            <a
              href="https://app.alpardobrasil.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HiExternalLink size={20} /> Acessar
            </a>
          </div>

          <div>
            <ReactPlayer
              url="https://youtu.be/kYnRQaJ32f4"
              width="100%"
              height="18rem"
              style={{ borderRadius: 8, overflow: "hidden" }}
              controls
            />
          </div>
        </Card>

        <h2>Contatos</h2>

        {getContacts?.contacts.map((contact) => (
          <Card key={contact.id}>
            <div>
              <h3>{contact.title}</h3>
              <InnerHtml dangerouslySetInnerHTML={{ __html: contact.text }} />
            </div>
          </Card>
        ))}
      </Content>

      <h2 style={{ marginTop: "4rem", color: "#333" }}>Boas vendas!</h2>
      <img
        src="https://alpar.sfo3.digitaloceanspaces.com/Alpar/handshake.png"
        alt="handshake"
        style={{ height: 200 }}
      />
    </Container>
  );
};
