import filesize from "filesize";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { IoMdTrash } from "react-icons/io";
// import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import Loading from "../../../../components/loadings/Loading";
import LoadingDot from "../../../../components/loadings/LoadingDot";
import Modal from "../../../../components/ModalLib";
import DropzoneOne from "../../../../components/Upload/Dropzone";
import {
  ButtunSubmit,
  ContainerPreviews,
  Form,
  ScreenLoading,
} from "../../../../pages/create/styles";
import api from "../../../../service/api";
import { Container, ContainerForm } from "./styles";

interface ModalGenerateBookProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;

  headerDatasheetId: number;
}

export interface FileProps {
  id: number;
  name: string;
  url: string;
  key: string;
  mimetype: string;
  size: number;
}

export const ModalGenerateBook: React.FC<ModalGenerateBookProps> = ({
  modal,
  setModal,

  headerDatasheetId,
}) => {
  // const history = useHistory();
  const { addToast } = useToasts();

  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const [fileBackground, setFileBackground] = useState<FileProps>();
  const [fileCover, setFileCover] = useState<FileProps>();
  const [fileBackcover, setFileBackcover] = useState<FileProps>();

  const [selectFileBackground, setSelectFileBackground] = useState<
    File | undefined
  >();
  const [selectFileCover, setSelectFileCover] = useState<File | undefined>();
  const [selectFileBackcover, setSelectFileBackcover] = useState<
    File | undefined
  >();

  const formik = useFormik({
    initialValues: {
      title: "",
      genr: "",
    },
    validationSchema: Yup.object({
      // title: Yup.string().required("É obrigatório"),
    }),
    onSubmit: (data) => handleGenerateBook(data),
  });

  useEffect(() => {
    (async () => {
      const response = await api.get(
        `headerDatasheet/files/show/${headerDatasheetId}`
      );

      const { fileBackcover, fileBackground, fileCover } = response.data;

      if (fileBackground?.id) setFileBackground(fileBackground);
      if (fileCover?.id) setFileCover(fileCover);
      if (fileBackcover?.id) setFileBackcover(fileBackcover);

      setLoadingPage(false);
    })();
  }, [headerDatasheetId]);

  // function printerCatalog(html: string) {
  //   history.push("/catalago", {
  //     html: html,
  //   });
  // }

  async function handleGenerateBook(data: any) {
    setLoading(true);

    try {
      const response = await api.post(
        `headerDatasheet/catalog/${headerDatasheetId}`
      );
      // printerCatalog(response.data.html);

      window.open(response.data.link, "_blank");

      setModal(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function handleSendFiles(
    fileType: "background" | "cover" | "backcover",
    file: File
  ) {
    try {
      addToast("Enviando arquivos...", {
        appearance: "info",
        autoDismiss: true,
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", fileType);

      const response = await api.post(
        `/headerDatasheet/files/${headerDatasheetId}`,
        formData
      );

      switch (fileType) {
        case "backcover":
          setFileBackcover({ ...response.data });
          break;
        case "cover":
          setFileCover({ ...response.data });
          break;
        case "background":
          setFileBackground({ ...response.data });
          break;

        default:
          break;
      }

      addToast("Arquivos enviados com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  async function handleRemoveFiles(
    fileType: "background" | "cover" | "backcover",
    fileId: number
  ) {
    try {
      await api.post(`/headerDatasheet/files/remove/${fileId}`, {
        fileType,
        fileId,
      });

      switch (fileType) {
        case "background":
          setSelectFileBackground(undefined);
          setFileBackground(undefined);
          break;

        case "cover":
          setSelectFileCover(undefined);
          setFileCover(undefined);
          break;

        case "backcover":
          setFileBackcover(undefined);
          setSelectFileBackcover(undefined);
          break;

        default:
          break;
      }

      addToast("Arquivos excluido com sucesso.", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Modal
      title="Gerar Book"
      modalVisible={modal}
      setModalVisible={setModal}
      maxHeight={500}
      scrollOff
    >
      <Container>
        <Form onSubmit={formik.handleSubmit}>
          {!loadingPage ? (
            <ContainerForm>
              <div className="containerForm">
                <div className="field">
                  <label>Backgroug</label>

                  {!selectFileBackground && !fileBackground && (
                    <DropzoneOne
                      accept={["image/*"]}
                      onFileUploaded={(file) => {
                        setSelectFileBackground(file);
                        handleSendFiles("background", file);
                      }}
                    />
                  )}

                  <ContainerPreviews
                    style={{
                      overflowY: "auto",
                    }}
                  >
                    {!fileBackground && selectFileBackground && <LoadingDot />}

                    {fileBackground && (
                      <li>
                        <img src={fileBackground.url} alt="icon_xml" />
                        <div className="fileInfo">
                          <div>
                            <strong>{fileBackground.name}</strong>
                            <span>{filesize(fileBackground.size)}</span>
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              handleRemoveFiles(
                                "background",
                                fileBackground.id
                              );
                            }}
                          >
                            <IoMdTrash size={30} />
                          </button>
                        </div>
                      </li>
                    )}
                  </ContainerPreviews>
                </div>

                <div className="field-group">
                  <div className="field">
                    <label>Capa</label>

                    {!selectFileCover && !fileCover && (
                      <DropzoneOne
                        accept={["image/*"]}
                        onFileUploaded={(file) => {
                          setSelectFileCover(file);
                          handleSendFiles("cover", file);
                        }}
                      />
                    )}

                    <ContainerPreviews
                      style={{
                        overflowY: "auto",
                      }}
                    >
                      {!fileCover && selectFileCover && <LoadingDot />}

                      {fileCover && (
                        <li>
                          <img src={fileCover.url} alt="icon_xml" />
                          <div className="fileInfo">
                            <div>
                              <strong>{fileCover.name}</strong>
                              <span>{filesize(fileCover.size)}</span>
                            </div>

                            <button
                              type="button"
                              onClick={() => {
                                handleRemoveFiles("cover", fileCover.id);
                              }}
                            >
                              <IoMdTrash size={30} />
                            </button>
                          </div>
                        </li>
                      )}
                    </ContainerPreviews>
                  </div>

                  <div className="field">
                    <label>Contracapa</label>

                    {!selectFileBackcover && !fileBackcover && (
                      <DropzoneOne
                        accept={["image/*"]}
                        onFileUploaded={(file) => {
                          setSelectFileBackcover(file);
                          handleSendFiles("backcover", file);
                        }}
                      />
                    )}

                    <ContainerPreviews
                      style={{
                        overflowY: "auto",
                      }}
                    >
                      {!fileBackcover && selectFileBackcover && <LoadingDot />}

                      {fileBackcover && (
                        <li>
                          <img src={fileBackcover.url} alt="icon_xml" />
                          <div className="fileInfo">
                            <div>
                              <strong>{fileBackcover.name}</strong>
                              <span>{filesize(fileBackcover.size)}</span>
                            </div>

                            <button
                              type="button"
                              onClick={() => {
                                handleRemoveFiles(
                                  "backcover",
                                  fileBackcover.id
                                );
                              }}
                            >
                              <IoMdTrash size={30} />
                            </button>
                          </div>
                        </li>
                      )}
                    </ContainerPreviews>
                  </div>
                </div>
              </div>
              <div className="containerFormButton">
                <ButtunSubmit style={{ margin: 0 }} type="submit">
                  {loading ? (
                    <div>
                      <Loading borderSize={2} size={20} />
                    </div>
                  ) : (
                    "GERAR"
                  )}
                </ButtunSubmit>
              </div>
            </ContainerForm>
          ) : (
            <ScreenLoading>
              <Loading
                colorLoading="#333"
                size={40}
                borderColor={"#888"}
                borderSize={5}
              />
            </ScreenLoading>
          )}
        </Form>
      </Container>
    </Modal>
  );
};
