import React, { useEffect } from "react";

import { ContainerCheckbox } from "./styles";

interface InputCheckboxProps {
  height?: string;
  name: string;
  label?: string;
  error?: string;
  requiredOn?: boolean;

  permissionFields: any;
  setPermissionFields: (a: any) => any;

  data: PermissionField[];

  permissionFieldsAllowed?: string[];
}

export interface PermissionField {
  description: string;
  name: string;
  is_allowed?: boolean;
}

export const InputCheckbox: React.FC<InputCheckboxProps> = ({
  name,
  label,
  data,
  permissionFieldsAllowed,
  height,

  setPermissionFields,
  permissionFields,
}) => {
  function setChangeCheckbox(e: React.ChangeEvent<HTMLInputElement>) {
    setPermissionFields({
      ...permissionFields,
      [e.target.value]: e.target.checked,
    });
  }

  useEffect(() => {
    var data_checks: any = {};

    permissionFieldsAllowed?.forEach((field) => {
      data_checks = {
        ...data_checks,
        [field]: true,
      };
    });

    setPermissionFields({ ...data_checks });
    // eslint-disable-next-line
  }, [setPermissionFields]);

  return (
    <div className="field" style={{ height: height }}>
      <ContainerCheckbox style={{ height: height }}>
        {label && <label htmlFor={name}>{label}</label>}
        <div className="contentsCheckbox">
          {data?.map((element, index) => (
            <div key={index} className="boxCheckbox">
              <input
                type="checkbox"
                onChange={setChangeCheckbox}
                checked={permissionFields[element.name] ? true : false}
                value={element.name}
                name={`permission_fields`}
              />
              <label htmlFor="">{element.description}</label>
            </div>
          ))}
        </div>
      </ContainerCheckbox>
    </div>
  );
};
