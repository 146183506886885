interface IToken {
  access_token: string;
  iat: number;
  exp: number;
}

export const TOKEN_KEY = "@Key-Provider";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => {
  const user = localStorage.getItem(TOKEN_KEY);

  return JSON.parse(user as string);
};

export const setToken = (token: IToken) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};

export const logout = () => {
  localStorage.clear();
};
