import React from "react";

import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";
import api from "../../../service/api";

import { FiAlertCircle } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import {
  Container,
  ContainerForm,
  Form,
  ButtunSubmit,
  Error,
  ContainerCheck,
} from "../styles";

import { IPermissionUser } from "../../../@types";

interface HomeProps {
  location: {
    state: {
      brand: IStatus;
    };
  };
  permission: IPermissionUser;
}

interface IStatus {
  id?: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_active: boolean;
  is_generate_book: boolean;
}

const StatusDatasheet: React.FC<HomeProps> = ({ location, permission }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const { id, cod, name, color, finisher, is_active, is_generate_book } =
    location.state.brand;

  async function handleSubmit(updates: any) {
    try {
      await api.put(`/headerDatasheet/status/${updates.id}`, updates);
      addToast("Status de Indenização alterado com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/status/cabecalho/fichas-tecnicas");
    } catch (err) {
      const error = err as any;
      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.data.error === "cannot be changed") {
        return addToast("Status não pode ser alterado", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      if (error.response.data.error === "email already exists") {
        return addToast("Este e-mail já existe em nossa base de dados", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  return (
    <Container>
      <header>
        <button
          type="button"
          onClick={() => {
            history.goBack();
          }}
          className="arrowBack"
        >
          <FaArrowLeft size={18} />
        </button>
        <h2>Status da Coleção da Ficha Técnica</h2>
      </header>

      <ContainerForm>
        <Formik
          initialValues={{
            id: id,
            cod: cod,
            name: name,
            color: color,
            finisher: finisher,
            is_active: is_active,
            is_generate_book: is_generate_book,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Nome é obrigatório"),
          })}
          onSubmit={(data) => handleSubmit(data)}
        >
          {(formik) => (
            <Form
              onSubmit={
                permission && permission.config && permission.config > 2
                  ? formik.handleSubmit
                  : () => {}
              }
            >
              <div className="field">
                <label htmlFor="name">Situação</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.is_active ? "check" : ""}
                    onClick={() => formik.setFieldValue("is_active", true)}
                  >
                    Ativo
                  </button>

                  <button
                    type="button"
                    className={!formik.values.is_active ? "check" : ""}
                    onClick={() => formik.setFieldValue("is_active", false)}
                  >
                    Inativo
                  </button>
                </ContainerCheck>
              </div>

              <div className="field">
                <label htmlFor="COD">COD</label>
                <input
                  type="text"
                  className="id"
                  {...formik.getFieldProps("cod")}
                  readOnly={true}
                />
              </div>
              <div className="field">
                <label htmlFor="name">Descrição</label>
                <input id="name" {...formik.getFieldProps("name")} />
                {formik.touched.name && formik.errors.name ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.name} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="color">Cor de fundo</label>
                <input id="color" {...formik.getFieldProps("color")} />
                {formik.touched.color && formik.errors.color ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.color} </span>
                  </Error>
                ) : null}
              </div>

              <div className="field">
                <label htmlFor="name">Gera Book</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.is_generate_book ? "check" : ""}
                    onClick={() =>
                      formik.setFieldValue("is_generate_book", true)
                    }
                  >
                    SIM
                  </button>

                  <button
                    type="button"
                    className={!formik.values.is_generate_book ? "check" : ""}
                    onClick={() =>
                      formik.setFieldValue("is_generate_book", false)
                    }
                  >
                    NÃO
                  </button>
                </ContainerCheck>
              </div>

              <div className="field">
                <label htmlFor="name">Finalização</label>
                <ContainerCheck>
                  <button
                    type="button"
                    className={formik.values.finisher ? "check" : ""}
                    onClick={() => formik.setFieldValue("finisher", true)}
                  >
                    SIM
                  </button>

                  <button
                    type="button"
                    className={!formik.values.finisher ? "check" : ""}
                    onClick={() => formik.setFieldValue("finisher", false)}
                  >
                    NÃO
                  </button>
                </ContainerCheck>
              </div>

              {permission && permission.config && permission.config > 2 && (
                <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
              )}
            </Form>
          )}
        </Formik>
      </ContainerForm>
    </Container>
  );
};

export default StatusDatasheet;
