import React, { useState } from "react";

import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import {
  ButtunSubmit as ButtonSubmit,
  Form,
  ScreenLoading,
} from "../../../create/styles";

import Loading from "../../../../components/Loading";
import {
  Container,
  ContainerForm,
} from "../../../../components/ModalModificationHeaderDatasheet/styled";
import { Seller } from "../../../../hook/queries/useSeller";
import api from "../../../../service/api";
import { ClientForecastProps } from "../../ForecastSeller";
import { SelectClients } from "../../ForecastSeller/SelectClients";
import { SelectSeller } from "../SelectSeller";

interface ModalAddSellerProps {
  onRequestClose: () => void;

  data: {
    forecastId: number;
    brandCode: number;
    histories: {
      historic1: string;
      historic2: string;
      historic3: string;
      historic4: string;
    };
  };
}

export const ModalAddSeller: React.FC<ModalAddSellerProps> = ({
  onRequestClose,
  data,
}) => {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [loadingPage] = useState(false);

  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      seller: undefined,
      clientsForecast: [],
    },

    onSubmit: async (data) => await handleSubmitUpdate(data as any),
  });

  async function handleSubmitUpdate({
    clientsForecast,
    seller,
  }: {
    seller: Seller;
    clientsForecast: ClientForecastProps[];
  }) {
    if (!seller) {
      return addToast("É necessário vincular um vendedor.", {
        appearance: "warning",
        autoDismiss: true,
      });
    }

    if (clientsForecast.length <= 0) {
      return addToast("É necessário vincular pelo menos um cliente.", {
        appearance: "warning",
        autoDismiss: true,
      });
    }

    setLoading(true);
    try {
      await api.post(`/forecast/forecastseller/${data.forecastId}`, {
        clients: clientsForecast.map((client) => ({
          cod: client.cod,
          historic1: client.historic1,
          historic2: client.historic2,
          historic3: client.historic3,
          historic4: client.historic4,
        })),
        sellerCode: seller.cod,
      });

      onRequestClose();

      addToast("Adicionado vendedor com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      return addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <Form
        onSubmit={
          loading
            ? (e) => {
                e.preventDefault();
              }
            : formik.handleSubmit
        }
      >
        <ContainerForm>
          <div className="containerForm">
            {!loadingPage ? (
              <>
                <div style={{ marginBottom: "1rem" }}>
                  <SelectSeller
                    seller={formik.values.seller ?? null}
                    onChangeSeller={(seller) => {
                      formik.setFieldValue("seller", seller);
                    }}
                    filters={{ active: 1, brandCode: +data.brandCode }}
                  />
                </div>

                <div>
                  <SelectClients
                    clients={formik.values.clientsForecast ?? []}
                    onChangeClients={(data) => {
                      formik.setFieldValue("clientsForecast", data);
                    }}
                    histories={data.histories}
                  />
                </div>
              </>
            ) : (
              <ScreenLoading>
                <Loading
                  colorLoading="#333"
                  size={40}
                  borderColor={"#888"}
                  borderSize={5}
                />
              </ScreenLoading>
            )}
          </div>

          <div className="containerFormButton">
            <ButtonSubmit style={{ margin: 0 }} type="submit">
              {loading ? (
                <div>
                  <Loading borderSize={2} size={20} />
                </div>
              ) : (
                "Adicionar"
              )}
            </ButtonSubmit>
          </div>
        </ContainerForm>
      </Form>
    </Container>
  );
};
