import React from "react";
import { IoMdTrash } from "react-icons/io";
import { Container } from "./styles";

interface Props {
  data: {
    name: string;
    uri: string;
    size: string;
    type?: string;
  };

  onTrash?: () => void;
}

export function ImageClient({ data, onTrash }: Props) {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            marginBottom: "0.5rem",
            fontSize: "1.1rem",
          }}
        >
          {data.type}
        </p>

        {onTrash && (
          <button type="button" onClick={onTrash}>
            <IoMdTrash size={20} />
          </button>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <img src={data.uri} alt={data.name} />
      </div>

      <p
        style={{
          color: "#333",
          fontSize: "1rem",
          marginTop: "0.5rem",

          maxWidth: 300,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {data.name}
      </p>
      <span
        style={{
          fontWeight: "lighter",
          color: "#333",
          fontSize: "0.865rem",
        }}
      >
        {data.size}
      </span>
    </Container>
  );
}
