import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import { FaFileExcel } from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import { MdAdd } from "react-icons/md";
import { IPermissionUser } from "../../../@types";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Input } from "../../../components/Form/Input";
import { Select } from "../../../components/Form/Select";
import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelFilter } from "../../../components/panel/PanelFilter";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { Pagination } from "../../../components/panel/table/Pagination";
import {
  FieldProps,
  TableContent,
} from "../../../components/panel/table/TableContent";
import { TableHeader } from "../../../components/panel/table/TableHeader";
import {
  getServiceInvoice,
  ServiceInvoice,
  ServiceInvoiceFilters,
  useServiceInvoice,
} from "../../../hook/queries/useServiceInvoice";
import { setQueryParams, useQueryParams } from "../../../hook/useQueryParams";
import { generateXlsx } from "../../../utils/generateXlsx";
import { objectToForEach } from "../../../utils/objectToForEach";
import { setFormikValues } from "../../../utils/setFormikValues";

const columns: FieldProps<ServiceInvoice>[] = [
  {
    name: "id",
    field: "CÓDIGO",
    viewList: true,
  },
  {
    name: "type",
    field: "TIPO",
    viewList: true,
    render: (invoice) => invoice.typeNormalized,
  },
  {
    name: "status",
    field: "SITUAÇÃO",
    viewList: true,
    render: (invoice) => {
      return (
        <nav
          style={{
            color: "#000",
            fontWeight: "bold",
            backgroundColor: invoice.status.color,
            borderRadius: 6,
            fontSize: 12,
            padding: "4px 0",
          }}
        >
          {invoice.status.name}
        </nav>
      );
    },
  },
  {
    name: "seller",
    field: "REPRESENTANTE",
    viewList: true,
    render: (invoice) =>
      `${invoice.seller.cod} - ${invoice.seller.abbreviation}`,
  },
  {
    name: "operador",
    field: "RESPONSÁVEL",
    viewList: true,
    render: (invoice) =>
      !!invoice.operador
        ? `${invoice.operador.name} ${invoice.operador.lastname}`
        : "-",
  },
  {
    name: "periodNormalized",
    field: "PERÍODO",
    viewList: true,
  },
  {
    name: "created_atNormalized",
    field: "CRIADO EM",
    viewList: true,
  },
  {
    name: "finished_atNormalized",
    field: "FINALIZADO EM",
    viewList: true,
  },
];

type Props = {
  admin?: boolean;
  permission?: IPermissionUser;
};

const PanelUsers: React.FC<Props> = ({ admin, permission }) => {
  const history = useHistory();
  const query = useQueryParams();

  const [page, setPage] = useState<number>(() => {
    const pageQuery = query.get("page");

    if (!isNaN(Number(pageQuery)) && Number(pageQuery) > 0)
      return Number(pageQuery);

    return 1;
  });
  const [filters, setFilters] = useState<ServiceInvoiceFilters>(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    return filtersValueQueryParams;
  });
  const [viewFilter, setViewFilter] = useState(false);
  const { data, isLoading, isFetching, refetch } = useServiceInvoice(
    page,
    filters,
    15
  );

  const formikFilter = useFormik({
    initialValues: {
      cod: "",
      type: "",
      status: "",
      seller: "",
      operador: "",
    },
    onSubmit: (data) => {
      handleFilter(data);
    },
  });

  async function handleExport() {
    const getData = await getServiceInvoice(1, {}, 99999);

    generateXlsx({
      data: getData.invoices.map((invoice) => ({
        CÓDIGO: invoice.id,
        TIPO: invoice.typeNormalized,
        SITUAÇÃO: invoice.status.name,
        REPRESENTANTE: `${invoice.seller.cod} - ${invoice.seller.abbreviation}`,
        RESPONSÁVEL: !!invoice.operador
          ? `${invoice.operador.name} ${invoice.operador.lastname}`
          : "-",
        "CRIADO EM": invoice.created_atNormalized,
      })),
      filename: "Representantes",
    });
  }

  function handleFilter(data: ServiceInvoiceFilters) {
    setFilters(data);
    setPage(1);
    setViewFilter(false);
  }

  useEffect(() => {
    if (page > 0) {
      setQueryParams({
        data: {
          field: "page",
          value: String(page),
        },
        type: "set",
        history,
      });
    }
  }, [page, history]);

  useEffect(() => {
    let filtersValueQueryParams = {};
    query.forEach((value, key) => {
      const findOne = columns.find((f) => f.name === key);

      if (findOne) {
        filtersValueQueryParams = {
          ...filtersValueQueryParams,
          [key]: value,
        };
      }
    });

    setFormikValues(filtersValueQueryParams, formikFilter);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    objectToForEach(filters, (key, value) => {
      if (value) {
        setQueryParams({
          data: {
            field: String(key),
            value: String(value),
          },
          type: "set",
          history,
        });
      } else {
        setQueryParams({
          data: {
            field: String(key),
          },
          type: "delete",
          history,
        });
      }
    });

    setFormikValues(filters, formikFilter);
    // eslint-disable-next-line
  }, [filters]);

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de notas de serviço" />

      <PanelFilter
        filters={filters}
        setFilters={setFilters}
        columns={columns}
        onSubmit={formikFilter.handleSubmit}
        onResetForm={formikFilter.resetForm}
        setViewFilter={setViewFilter}
        viewFilter={viewFilter}
        normalizedFilters={(value: any, label: string) => {
          switch (label) {
            case "type":
              return value === "comissao" ? "Notas serviço" : "Outras";

            default:
              return value;
          }
        }}
      >
        <GroupInput>
          <Input label="CÓDIGO" {...formikFilter.getFieldProps("cod")} />
        </GroupInput>
        <GroupInput>
          {admin && (
            <Select
              label="REPRESENTANTE"
              {...formikFilter.getFieldProps("seller")}
              data={
                data?.filters?.sellers?.map((item) => ({
                  value: String(item.id),
                  name: `${item.id} - ${item.name}`,
                })) ?? []
              }
            />
          )}

          <Select
            label="RESPONSÁVEL"
            {...formikFilter.getFieldProps("operador")}
            data={
              data?.filters?.operador?.map((item) => ({
                value: String(item.id),
                name: item.name,
              })) ?? []
            }
          />
        </GroupInput>
        <GroupInput>
          <Select
            label="SITUAÇÃO"
            {...formikFilter.getFieldProps("status")}
            data={
              data?.filters?.status?.map((item) => ({
                value: String(item.id),
                name: item.name,
              })) ?? []
            }
          />
          <Select
            label="TIPO"
            {...formikFilter.getFieldProps("type")}
            data={[
              { name: "Notas serviço", value: "comissao" },
              { name: "Outras", value: "outras" },
            ]}
          />
        </GroupInput>
      </PanelFilter>

      <TableHeader title="NOTAS" isFetching={isFetching && !isLoading}>
        <button style={{ background: "#2d792f" }} onClick={handleExport}>
          <FaFileExcel size={19} />
          <span>EXPORTAR</span>
        </button>
        <button onClick={() => refetch()}>
          <IoReload size={19} />
          <span>RECARREGAR</span>
        </button>

        {(permission?.serviceInvoice ?? 0) > 2 && (
          <button
            onClick={() => {
              history.push("/admin/criar/nota-servico");
            }}
            type="button"
          >
            <MdAdd size={22} /> <span> NOVO </span>
          </button>
        )}
      </TableHeader>

      <TableContent<ServiceInvoice>
        data={data?.invoices}
        columns={columns.filter((f) => f.viewList)}
        isLoading={isLoading}
        onClickRow={(e) => {
          if (admin) {
            history.push(`/admin/nota-servico/${e.id}`);
          } else {
            history.push(`/representante/nota-servico/${e.id}`);
          }
        }}
      />

      <Pagination
        totalCountOfRegisters={data?.totalCount ?? 0}
        currentPage={page}
        onPageChange={setPage}
        registersPerPage={15}
      />
    </PanelLayout>
  );
};

export default PanelUsers;
