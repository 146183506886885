import React from "react";
import { Link } from "react-router-dom";
import { useSideBar } from "../../../hook/useSideBar";
import useWindowDimensions from "../../../hook/useWindowDimensions";

export const ItemsNav: React.FC = () => {
  const { itemsNav: data, handleActiveGroup } = useSideBar();
  const { width } = useWindowDimensions();
  const { handleSetIsOpen } = useSideBar();

  function handleCloseMobile() {
    if (width < 901) {
      handleSetIsOpen(false);
    }
  }

  return (
    <ul className="nav-links">
      {data.map((item, index) => {
        return !item.isGroup ? (
          <li key={index}>
            <Link to={item.path} onClick={handleCloseMobile}>
              <item.Icon className="nav-icon" />
              <span className="link_name">{item.name}</span>
            </Link>
            <ul className="sub-menu blank">
              <li>
                <Link
                  onClick={handleCloseMobile}
                  className="link_name"
                  to={item.path}
                >
                  {item.name}
                </Link>
              </li>
            </ul>
          </li>
        ) : (
          <li key={index} className={item.isGroupActive ? "showMenu" : ""}>
            <div className="iocn-link">
              <Link
                onClick={() => {
                  if (item.path === "#") {
                    handleActiveGroup(item.name);
                  } else {
                    handleCloseMobile();
                  }
                }}
                to={item.path}
              >
                <item.Icon className="nav-icon" />
                <span className="link_name">{item.name}</span>
              </Link>

              <i
                onClick={() => handleActiveGroup(item.name)}
                className="bx bxs-chevron-down arrow"
              ></i>
            </div>

            <ul className="sub-menu showMenu">
              <li>
                <Link
                  onClick={handleCloseMobile}
                  className="link_name"
                  to={item.path}
                >
                  {item.name}
                </Link>
              </li>

              {item.groupPath?.map((itemGroup, indexGroup) => (
                <li key={indexGroup}>
                  <Link onClick={handleCloseMobile} to={itemGroup.path}>
                    {itemGroup.name}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        );
      })}
    </ul>
  );
};
