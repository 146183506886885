import React, { useState } from "react";
import { BtnFixed, BtnOptions, Container } from "./styles";

import { IconType } from "react-icons";

interface IPropsHeader {
  Icon: IconType;
  title: String;
  color?: string;
  onPress?: () => void;
  notFloatButton?: boolean;
}

const FloatButton: React.FC<IPropsHeader> = ({
  children,
  Icon,
  title,
  notFloatButton = false,
  color,
  onPress,
}) => {
  const [activeOptions, setActiveOptions] = useState(true);

  return (
    <Container>
      {!notFloatButton && (
        <>
          <span onClick={() => setActiveOptions(!activeOptions)}>{title}</span>
          <BtnFixed
            style={{ background: color ? color : undefined }}
            onClick={() => {
              onPress && onPress();
              setActiveOptions(!activeOptions);
            }}
          >
            <Icon color={"#fff"} size={26} />
          </BtnFixed>
        </>
      )}

      <BtnOptions
        style={{
          opacity: activeOptions ? 1 : 0,
          transform: activeOptions ? "scale(1)" : "scale(0)",
          bottom: notFloatButton ? 5 : undefined,
        }}
      >
        {children}
      </BtnOptions>
    </Container>
  );
};

export default FloatButton;
