import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { ButtunSubmit, Form } from "../../../../../create/styles";

import { ClientForecastProps } from "../../..";
import { GroupInput } from "../../../../../../components/Form/GroupInput";
import { Input } from "../../../../../../components/Form/Input";
import {
  Container,
  ContainerForm,
} from "../../../../../../components/ModalModificationHeaderDatasheet/styled";

interface ModalEditForecastClientProps {
  onChange?: (data: ClientForecastProps) => void;
  data: ClientForecastProps;
  histories: {
    historic1: string;
    historic2: string;
    historic3: string;
    historic4: string;
  };
}

export const ModalEditForecastClient: React.FC<
  ModalEditForecastClientProps
> = ({ onChange, data, histories }) => {
  const formik = useFormik({
    validateOnChange: false,

    initialValues: {
      historic1: data.historic1,
      historic2: data.historic2,
      historic3: data.historic3,
      historic4: data.historic4,
    },
    validationSchema: Yup.object({
      historic1: Yup.number().required("É obrigatorio"),
      historic2: Yup.number().required("É obrigatorio"),
      historic3: Yup.number().required("É obrigatorio"),
      historic4: Yup.number().required("É obrigatorio"),
    }),

    onSubmit: async (data) => await handleSubmitUpdate(data),
  });

  async function handleSubmitUpdate(props: any) {
    if (onChange) onChange({ ...data, ...props });
  }

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        <ContainerForm>
          <div className="containerForm">
            <h3>Cliente</h3>
            <GroupInput>
              <Input
                name="clientCod"
                value={data.cod}
                label="Código"
                readOnly
              />
              <Input
                name="clientName"
                label="Razão social"
                value={data.company_name}
                readOnly
              />
            </GroupInput>

            <h3>Histórico</h3>
            <GroupInput>
              <Input
                {...formik.getFieldProps("historic1")}
                error={
                  formik.errors.historic1 && formik.touched.historic1
                    ? formik.errors.historic1
                    : undefined
                }
                label={histories.historic1}
                type="number"
              />
              <Input
                {...formik.getFieldProps("historic2")}
                error={
                  formik.errors.historic2 && formik.touched.historic2
                    ? formik.errors.historic2
                    : undefined
                }
                label={histories.historic2}
                type="number"
              />
            </GroupInput>
            <GroupInput>
              <Input
                {...formik.getFieldProps("historic3")}
                error={
                  formik.errors.historic3 && formik.touched.historic3
                    ? formik.errors.historic3
                    : undefined
                }
                label={histories.historic3}
                type="number"
              />
              <Input
                {...formik.getFieldProps("historic4")}
                error={
                  formik.errors.historic4 && formik.touched.historic4
                    ? formik.errors.historic4
                    : undefined
                }
                label={histories.historic4}
                type="number"
              />
            </GroupInput>
          </div>

          <div className="containerFormButton">
            <ButtunSubmit
              style={{ margin: 0 }}
              type="button"
              onClick={formik.submitForm}
            >
              Alterar
            </ButtunSubmit>
          </div>
        </ContainerForm>
      </Form>
    </Container>
  );
};
