import React from "react";
import useAlertBilletConsent from "../../hook/useAlertBilletConsent";

import { Container } from "./styles";

export function AlertBillet() {
  const { cookiesAlertBillet, setCookiesAlertBillet } = useAlertBilletConsent();

  if (cookiesAlertBillet) return null;

  return (
    <Container>
      <nav className={`content ${!cookiesAlertBillet ? "on" : "close"}`}>
        <p>COMUNICADO IMPORTANTE</p>

        <section>
          <p>
            <br />
          </p>
          <p>Prezado cliente,</p>
          <p>
            <br />
          </p>
          <p>
            Recebemos relatos de clientes informando sobre tentativas de fraudes
            relacionadas à boletos em nome da Alpar. Esse assunto já foi tratado
            junto aos bancos desde a data em que tomamos ciência da tentativa de
            fraude.
          </p>
          <p>
            Com os desdobramentos do caso, verificamos que o problema é externo
            à empresa, visto que outras companhias também têm relatado problemas
            semelhantes.
          </p>
          <p>
            Abaixo algumas dicas em relação à segurança de pagamentos e
            transações financeiras com a nossa empresa:
          </p>
          <ol>
            <li>
              <p>
                Não temos registros de problemas com relação ao nosso sistema de
                emissão de boletos. Caso recebam e-mail informando esse fato,
                solicitando a troca do boleto, desconsiderar e entrar em contato
                imediatamente conosco pelos contatos abaixo;
              </p>
            </li>

            <li>
              <p>
                Os nossos boletos são emitidos pela Instituição Bancária e
                enviados por Correio ou juntamente com a Nota Fiscal;
              </p>
            </li>

            <li>
              <p>
                Só concedemos descontos em caso de abatimento de devoluções;
              </p>
            </li>

            <li>
              <p>
                Sempre confira o número do código de barras, os dados do boleto
                e o valor;
              </p>
            </li>

            <li>
              <p>
                Ao processar seus pagamentos, atente-se ao beneficiário. Todos
                os nossos boletos possuem como beneficiária a
                <b> ALPAR DO BRASIL IND. E COM. DE CALÇADOS LTDA</b>.
              </p>
            </li>

            <li>
              <p>
                Todos os nossos e-mails são enviados exclusivamente pelo domínio
                Alpar do Brasil na internet, que é <b>@a</b>
                <b>lpardobrasil.com.br</b>. Caso receba algum e-mail com domínio
                diferente, desconsiderar, e reportar para ao nosso setor
                Financeiro.
              </p>
            </li>
          </ol>

          <p>
            <br />
          </p>
          <p>
            Em caso de dúvidas, entre em contato conosco pelo telefone ou e-mail
            no setor Financeiro.
          </p>
          <p>
            <br />
          </p>
          <p>
            Telefone: <b>(51) 3441-1000</b>
          </p>
          <p>
            E-mail: <b>financeiro@alpardobrasil.com.br</b>
          </p>
          <p>
            <br />
          </p>
        </section>

        <div>
          <button type="button" onClick={() => setCookiesAlertBillet(true)}>
            Eu entendo
          </button>
          <a
            // eslint-disable-next-line
            target="_blank"
            href="https://alpardobrasil.com.br/arquivos/COMUNICADO_IMPORTANTE.docx"
            className="profile-details"
          >
            Saber mais
          </a>
        </div>
      </nav>
    </Container>
  );
}
