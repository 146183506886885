import React, { useEffect, useRef, useState } from "react";

import { Formik } from "formik";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";
import api from "../../../service/api";

import { useHistory } from "react-router-dom";
import useOutsideAlerter from "../../../hook/outsideAlerter";

import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { IoMdCloseCircle } from "react-icons/io";
import { MdAdd } from "react-icons/md";

import { IPermissionUser } from "../../../@types";
import {
  Container,
  ContainerList,
  Filter,
  FilterCheck,
  FilterSelects,
  FooterContainer,
  Form,
  TableContainer,
} from "../styles";

export interface BrandProps {
  id: number;
  cod: number;
  name: string;
  color: string;
  finisher: boolean;
  is_active: boolean;
  is_generate_barcode: boolean;
  is_generate_barcode_sample: boolean;
}

interface IFilter {
  cod?: string;
  name?: string;
  active?: number;
  finisher?: number;
}

interface IHeaderProps {
  admin?: boolean;
  permission?: IPermissionUser;
}

const PanelStatusDatasheet: React.FC<IHeaderProps> = ({ permission }) => {
  const outSideRef = useRef(null);
  const history = useHistory();
  const { addToast } = useToasts();

  const [brands, setBrands] = useState<BrandProps[]>([{} as BrandProps]);
  const [brandUpdate, setbrandUpdate] = useState<BrandProps[]>([]);

  const [filterView, setFilterView] = useState(false);
  const [filters, setFilters] = useState<IFilter>({ active: 1 });

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get<BrandProps[]>(
          "/headerDatasheet/datasheet/status",
          {
            params: filters,
          }
        );
        setBrands(response.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [filters]);

  useEffect(() => {
    setBrands([...brandUpdate]);
  }, [brandUpdate]);

  useOutsideAlerter(outSideRef, () => {
    if (filterView) setFilterView(false);
  });

  function hadleFilter(filter: IFilter) {
    setFilters(filter);
    setFilterView(false);
  }

  function handleAlterActive(brandProps: BrandProps) {
    let arr = brands;

    arr.forEach(async (brand) => {
      if (brandProps.id === brand.id) {
        try {
          await api.put(`/headerDatasheet/datasheet/status/${brand.id}`, {
            active: Number(!brand.is_active),
          });
          setbrandUpdate(arr);

          brand.is_active = !brand.is_active;
        } catch (err) {
          const error = err as any;

          if (!error.response) {
            return addToast(
              "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }

          if (error.response.data.error === "cannot be changed") {
            return addToast("Status não pode ser alterado", {
              appearance: "warning",
              autoDismiss: true,
            });
          }

          if (error.response.status === 401) {
            return addToast("Sem permissão para esta ação", {
              appearance: "warning",
              autoDismiss: true,
            });
          }

          return addToast(
            "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
        }
      }
    });
  }

  function hadleToNewStatus() {
    history.push("/admin/criar/status/ficha-tecnica");
  }

  return (
    <Container>
      <header>
        <h2>Lista de status das Fichas Técnicas</h2>
      </header>
      <Filter>
        <button
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="button"
        >
          <FiSearch />
        </button>
        <input
          onClick={() => {
            setFilterView(!filterView);
          }}
          type="text"
          name="filter"
          id="filter"
          readOnly={true}
        />
        <FilterSelects>
          <ul>
            {filters.cod && (
              <li>
                <span>COD: {filters.cod}</span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, cod: "" })}
                />
              </li>
            )}
            {filters.name && (
              <li>
                <span>Descrição: {filters.name}</span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, name: "" })}
                />
              </li>
            )}
            {filters.finisher && (
              <li>
                <span>
                  Finalização: {Number(filters.finisher) === 0 ? "Sim" : "Não"}
                </span>{" "}
                <IoMdCloseCircle
                  onClick={() =>
                    setFilters({ ...filters, finisher: undefined })
                  }
                />
              </li>
            )}
            {filters.active && (
              <li>
                <span>
                  Situação: {Number(filters.active) === 0 ? "Inativo" : "Ativo"}
                </span>{" "}
                <IoMdCloseCircle
                  onClick={() => setFilters({ ...filters, active: undefined })}
                />
              </li>
            )}
          </ul>
        </FilterSelects>

        {filterView && (
          <FilterCheck ref={outSideRef}>
            <header>
              <button
                onClick={() => {
                  setFilterView(false);
                }}
              >
                {" "}
                Fechar{" "}
              </button>
            </header>
            <main>
              <Formik
                initialValues={{
                  cod: filters.cod,
                  name: filters.name,
                  finisher: filters.finisher,
                  active: filters.active,
                }}
                validationSchema={Yup.object({})}
                onSubmit={(data) => {
                  hadleFilter({
                    cod: data.cod?.trim(),
                    name: data.name,
                    finisher: data.finisher,
                    active: data.active,
                  });
                }}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="field">
                      <label htmlFor="cod">COD</label>
                      <input id="cod" {...formik.getFieldProps("cod")} />
                    </div>

                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="name">DESCRIÇÃO</label>
                        <input id="name" {...formik.getFieldProps("name")} />
                      </div>
                    </div>
                    <div className="field">
                      <label htmlFor="finisher">FINALIZAÇÃO</label>
                      <select
                        id="finisher"
                        {...formik.getFieldProps("finisher")}
                      >
                        <option value=""></option>
                        <option value={1}>SIM</option>
                        <option value={0}>NÃO</option>
                      </select>
                    </div>
                    <div className="field">
                      <label htmlFor="active">SITUAÇÃO</label>
                      <select id="active" {...formik.getFieldProps("active")}>
                        <option value=""></option>
                        <option value={1}>Ativo</option>
                        <option value={0}>Inativo</option>
                      </select>
                    </div>

                    <footer>
                      <button
                        className="clearFilter"
                        type="button"
                        onClick={() => {
                          setFilterView(false);
                          setFilters({});
                        }}
                      >
                        LIMPAR FILTRO
                      </button>
                      <button type="submit">BUSCAR</button>
                    </footer>
                  </Form>
                )}
              </Formik>
            </main>
          </FilterCheck>
        )}
      </Filter>

      <ContainerList>
        <div className="headerTable">
          <h3>Status das Ficha Técnica</h3>

          {permission && permission.config && permission.config > 2 && (
            <button onClick={hadleToNewStatus} type="button">
              <MdAdd size={22} /> <span> NOVO STATUS </span>
            </button>
          )}
        </div>

        <TableContainer>
          <table>
            <thead>
              <tr>
                <th>COD</th>
                <th>DESCRIÇÃO</th>
                <th>FINALIZAÇÃO</th>
                <th>COR</th>
                <th>SITUAÇÃO</th>
              </tr>
            </thead>

            <tbody>
              {brands.map((brand: BrandProps, index) => (
                <tr key={index}>
                  <td
                    onClick={() =>
                      history.push("/admin/status/ficha-tecnica", { brand })
                    }
                    style={{ opacity: brand.is_active ? 1 : 0.4 }}
                  >
                    {brand.cod}
                  </td>
                  <td
                    onClick={() =>
                      history.push("/admin/status/ficha-tecnica", { brand })
                    }
                    style={{ opacity: brand.is_active ? 1 : 0.4 }}
                  >
                    {" "}
                    {brand.name}{" "}
                  </td>
                  <td
                    onClick={() =>
                      history.push("/admin/status/ficha-tecnica", { brand })
                    }
                    style={{ opacity: brand.is_active ? 1 : 0.4 }}
                  >
                    {" "}
                    {brand.finisher ? "Sim" : "Não"}{" "}
                  </td>
                  <td
                    onClick={() =>
                      history.push("/admin/status/ficha-tecnica", { brand })
                    }
                  >
                    <span
                      className="status"
                      style={{
                        opacity: brand.is_active ? 1 : 0.4,
                        backgroundColor: brand.color,
                      }}
                    >
                      {brand.color}
                    </span>
                  </td>

                  <td>
                    <div className="stuationContainer">
                      <div
                        onClick={() => {
                          handleAlterActive(brand);
                        }}
                      >
                        {brand.is_active ? (
                          <AiFillCheckCircle color={"#009c27"} size={24} />
                        ) : (
                          <AiFillCloseCircle size={24} color={"#d10a0a"} />
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
        <FooterContainer>
          {/* <span>Mostrando { clints.length } de { pagination.total } resultados</span>          

          <div>
            <button className={ pagination.page < 1 ? "buttonNull": ""} type="button" onClick={ pagination.page < 1 ?  ()=>{} : hadlePreviousPage } >ANTERIOR</button>
            <p style={{marginRight: 8}}>{pagination.page + 1 }</p>
            <button className={ pagination.page > pagination.pages ? "buttonNull": ""} type="button" onClick={ pagination.page > pagination.pages ?  ()=>{} : hadleNextPage } >PROXIMO</button>
          </div> */}
        </FooterContainer>
      </ContainerList>
    </Container>
  );
};

export default PanelStatusDatasheet;
