import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { useFormik } from "formik";
import * as Yup from "yup";

import { FaArrowLeft } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import api from "../../../service/api";
import {
  ButtunSubmit,
  Container,
  ContainerForm,
  Error,
  Form,
  ScreenLoading,
} from "../styles";

import Loading from "../../../components/loadings/Loading";

import { IPermissionUser } from "../../../@types";
import { GroupInput } from "../../../components/Form/GroupInput";
import { Select } from "../../../components/Form/Select";

interface HomeProps {
  location: {
    state: {};
  };

  match: {
    params: {
      id: number;
    };
  };

  permission: IPermissionUser;
}

interface IPermission {
  id?: string;
  name?: string;

  devolution?: number;
  indemnity?: number;
  billet?: number;
  order?: number;
  forecast?: number;
  forecastInternal?: number;
  clientPJ?: number;
  userAdmin?: number;
  seller?: number;
  analytic?: number;
  config?: number;
  import?: number;
  permission?: number;
  devolutionLegacy?: number;
  payment?: number;
  provider?: number;
  scheduling?: number;
  collection?: number;
  showFile?: number;
  datasheet?: number;
  datasheetQuality?: number;
  requestUpdateClient?: number;
  serviceInvoice?: number;
  is_distribution?: boolean;
  is_licensed?: boolean;
  is_validationSalesType?: boolean;
}

const User: React.FC<HomeProps> = ({ match, permission }) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [contentPage, setContentPage] = useState<IPermission>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (match.params.id) {
      (async () => {
        const permissionDB = await api.get<IPermission>(
          `/permission/${match.params.id}`
        );

        setContentPage(permissionDB.data);
        setLoading(false);
      })();
    }
  }, [match.params.id]);

  useEffect(() => {
    if (contentPage) {
      if (contentPage.id) formik.setFieldValue("id", contentPage.id);
      if (contentPage.name) formik.setFieldValue("name", contentPage.name);

      if (contentPage.devolution)
        formik.setFieldValue("devolution", contentPage.devolution);
      if (contentPage.indemnity)
        formik.setFieldValue("indemnity", contentPage.indemnity);
      if (contentPage.billet)
        formik.setFieldValue("billet", contentPage.billet);
      if (contentPage.order) formik.setFieldValue("order", contentPage.order);
      if (contentPage.forecast)
        formik.setFieldValue("forecast", contentPage.forecast);
      if (contentPage.forecastInternal)
        formik.setFieldValue("forecastInternal", contentPage.forecastInternal);
      if (contentPage.clientPJ)
        formik.setFieldValue("clientPJ", contentPage.clientPJ);
      if (contentPage.userAdmin)
        formik.setFieldValue("userAdmin", contentPage.userAdmin);
      if (contentPage.seller)
        formik.setFieldValue("seller", contentPage.seller);
      if (contentPage.analytic)
        formik.setFieldValue("analytic", contentPage.analytic);
      if (contentPage.config)
        formik.setFieldValue("config", contentPage.config);
      if (contentPage.import)
        formik.setFieldValue("import", contentPage.import);
      if (contentPage.permission)
        formik.setFieldValue("permission", contentPage.permission);
      if (contentPage.devolutionLegacy)
        formik.setFieldValue("devolutionLegacy", contentPage.devolutionLegacy);
      if (contentPage.payment)
        formik.setFieldValue("payment", contentPage.payment);
      if (contentPage.provider)
        formik.setFieldValue("provider", contentPage.provider);
      if (contentPage.scheduling)
        formik.setFieldValue("scheduling", contentPage.scheduling);
      if (contentPage.collection)
        formik.setFieldValue("collection", contentPage.collection);
      if (contentPage.showFile)
        formik.setFieldValue("showFile", contentPage.showFile);
      if (contentPage.datasheet)
        formik.setFieldValue("datasheet", contentPage.datasheet);
      if (contentPage.datasheetQuality)
        formik.setFieldValue("datasheetQuality", contentPage.datasheetQuality);
      if (contentPage.requestUpdateClient)
        formik.setFieldValue(
          "requestUpdateClient",
          contentPage.requestUpdateClient
        );
      if (contentPage.serviceInvoice)
        formik.setFieldValue("serviceInvoice", contentPage.serviceInvoice);
      if (contentPage.is_distribution)
        formik.setFieldValue(
          "is_distribution",
          contentPage.is_distribution ? "1" : "0"
        );
      if (contentPage.is_licensed)
        formik.setFieldValue(
          "is_licensed",
          contentPage.is_licensed ? "1" : "0"
        );
      if (contentPage.is_validationSalesType)
        formik.setFieldValue(
          "is_validationSalesType",
          contentPage.is_validationSalesType ? "1" : "0"
        );
    }
    // eslint-disable-next-line
  }, [contentPage]);

  async function handleSubmit(updates: IPermission) {
    const newUpdate = {
      ...updates,
      is_distribution: Boolean(Number(updates.is_distribution)),
      is_licensed: Boolean(Number(updates.is_licensed)),
      is_validationSalesType: Boolean(Number(updates.is_validationSalesType)),
    };

    try {
      await api.put(`/permission/${updates.id}`, newUpdate);
      addToast("Permissões alteradas com sucesso", {
        appearance: "success",
        autoDismiss: true,
      });
      history.push("/admin/permissoes");
    } catch (err) {
      const error = err as any;

      if (!error.response) {
        return addToast(
          "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      }

      if (error.response.status === 401) {
        return addToast("Sem permissão para esta ação", {
          appearance: "warning",
          autoDismiss: true,
        });
      }

      addToast(
        "Desculpe, ocorreu um erro interno, Tente novamente mais tarde",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      id: undefined,
      name: undefined,

      devolution: undefined,
      indemnity: undefined,
      billet: undefined,
      order: undefined,
      forecast: undefined,
      forecastInternal: undefined,
      clientPJ: undefined,
      userAdmin: undefined,
      seller: undefined,
      analytic: undefined,
      config: undefined,
      import: undefined,
      permission: undefined,
      devolutionLegacy: undefined,
      payment: undefined,
      provider: undefined,
      scheduling: undefined,
      collection: undefined,
      showFile: undefined,
      datasheet: undefined,
      datasheetQuality: undefined,
      requestUpdateClient: undefined,
      serviceInvoice: undefined,

      is_validationSalesType: undefined,
      is_licensed: undefined,
      is_distribution: undefined,
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Nome é obrigatório"),
      devolution: Yup.string().required("Obrigatório"),
      indemnity: Yup.string().required("Obrigatório"),
      billet: Yup.string().required("Obrigatório"),
      order: Yup.string().required("Obrigatório"),
      forecast: Yup.string().required("Obrigatório"),
      forecastInternal: Yup.string().required("Obrigatório"),
      clientPJ: Yup.string().required("Obrigatório"),
      userAdmin: Yup.string().required("Obrigatório"),
      seller: Yup.string().required("Obrigatório"),
      analytic: Yup.string().required("Obrigatório"),
      config: Yup.string().required("Obrigatório"),
      import: Yup.string().required("Obrigatório"),
      permission: Yup.string().required("Obrigatório"),
      devolutionLegacy: Yup.string().required("Obrigatório"),
      provider: Yup.string().required("Obrigatório"),
      scheduling: Yup.string().required("Obrigatório"),
      payment: Yup.string().required("Obrigatório"),
      collection: Yup.string().required("Obrigatório"),
      showFile: Yup.number().required("Obrigatório"),
      datasheet: Yup.number().required("Obrigatório"),
      datasheetQuality: Yup.number().required("Obrigatório"),
      requestUpdateClient: Yup.number().required("Obrigatório"),
      serviceInvoice: Yup.number().required("Obrigatório"),
    }),

    onSubmit: (data) => handleSubmit(data),
  });

  return (
    <Container>
      {!loading ? (
        <>
          <header>
            <button
              type="button"
              onClick={() => {
                history.goBack();
              }}
              className="arrowBack"
            >
              <FaArrowLeft size={18} />
            </button>
            <h2>Perfil de Permissões</h2>
          </header>

          <ContainerForm>
            <Form
              onSubmit={
                permission && permission.permission && permission.permission > 2
                  ? formik.handleSubmit
                  : () => {
                      console.log("123");
                    }
              }
            >
              <div className="field">
                <label>ID</label>
                <input
                  type="text"
                  style={{ borderStyle: "dashed" }}
                  {...formik.getFieldProps("id")}
                  readOnly={true}
                />
              </div>

              <div className="field">
                <label htmlFor="name">Nome</label>
                <input id="name" {...formik.getFieldProps("name")} />
                {formik.touched.name && formik.errors.name ? (
                  <Error>
                    <FiAlertCircle color="#f00" size={16} />
                    <span> {formik.errors.name} </span>
                  </Error>
                ) : null}
              </div>

              <h3>Validar Tipo de Venda</h3>

              <Select
                data={[
                  { name: "SIM", value: "1" },
                  { name: "NÃO", value: "0" },
                ]}
                label="Validar Tipo de Venda (Distribuição e Licenciamento)"
                {...formik.getFieldProps("is_validationSalesType")}
                error={
                  formik.touched.is_validationSalesType &&
                  formik.errors.is_validationSalesType
                    ? formik.errors.is_validationSalesType
                    : undefined
                }
              />

              {Boolean(Number(formik.values.is_validationSalesType)) ===
                true && (
                <GroupInput>
                  <Select
                    data={[
                      { name: "SIM", value: "1" },
                      { name: "NÃO", value: "0" },
                    ]}
                    label="É Licenciamento"
                    {...formik.getFieldProps("is_licensed")}
                    error={
                      formik.touched.is_licensed && formik.errors.is_licensed
                        ? formik.errors.is_licensed
                        : undefined
                    }
                  />
                  <Select
                    data={[
                      { name: "SIM", value: "1" },
                      { name: "NÃO", value: "0" },
                    ]}
                    label="É Distribuição"
                    {...formik.getFieldProps("is_distribution")}
                    error={
                      formik.touched.is_distribution &&
                      formik.errors.is_distribution
                        ? formik.errors.is_distribution
                        : undefined
                    }
                  />
                </GroupInput>
              )}

              <h3>Permissões</h3>

              <div className="field-group">
                <div className="field">
                  <label>Devolução</label>
                  <select {...formik.getFieldProps("devolution")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                    <option value={4}>Criação, Edição e Leitura</option>
                  </select>
                  {formik.touched.devolution && formik.errors.devolution ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.devolution} </span>
                    </Error>
                  ) : null}
                </div>

                <div className="field">
                  <label>Indenização</label>
                  <select {...formik.getFieldProps("indemnity")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.indemnity && formik.errors.indemnity ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.indemnity} </span>
                    </Error>
                  ) : null}
                </div>

                <div className="field">
                  <label>Boleto</label>
                  <select {...formik.getFieldProps("billet")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.billet && formik.errors.billet ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.billet} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label>Pedido</label>
                  <select {...formik.getFieldProps("order")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.order && formik.errors.order ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.order} </span>
                    </Error>
                  ) : null}
                </div>

                <div className="field">
                  <label>Forecast</label>
                  <select {...formik.getFieldProps("forecast")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.forecast && formik.errors.forecast ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.forecast} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label>Forecast dados internos</label>
                  <select {...formik.getFieldProps("forecastInternal")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.forecastInternal &&
                  formik.errors.forecastInternal ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.forecastInternal} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label>Cliente</label>
                  <select {...formik.getFieldProps("clientPJ")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.clientPJ && formik.errors.clientPJ ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.clientPJ} </span>
                    </Error>
                  ) : null}
                </div>

                <div className="field">
                  <label>Usuário</label>
                  <select {...formik.getFieldProps("userAdmin")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.userAdmin && formik.errors.userAdmin ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.userAdmin} </span>
                    </Error>
                  ) : null}
                </div>

                <div className="field">
                  <label>Representante</label>
                  <select {...formik.getFieldProps("seller")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.seller && formik.errors.seller ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.seller} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label>Fornecedor</label>
                  <select {...formik.getFieldProps("provider")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.provider && formik.errors.provider ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.provider} </span>
                    </Error>
                  ) : null}
                </div>

                <div className="field">
                  <label>Análise</label>
                  <select {...formik.getFieldProps("analytic")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.analytic && formik.errors.analytic ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.analytic} </span>
                    </Error>
                  ) : null}
                </div>

                <div className="field">
                  <label>Configuração</label>
                  <select {...formik.getFieldProps("config")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.config && formik.errors.config ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.config} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label>Permissão</label>
                  <select {...formik.getFieldProps("permission")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.permission && formik.errors.permission ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.permission} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label>Importação</label>
                  <select {...formik.getFieldProps("import")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.import && formik.errors.import ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.import} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label>Legado Devolução</label>
                  <select {...formik.getFieldProps("devolutionLegacy")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.devolutionLegacy &&
                  formik.errors.devolutionLegacy ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.devolutionLegacy} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label>Pagamento</label>
                  <select {...formik.getFieldProps("payment")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.payment && formik.errors.payment ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.payment} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label>Agendamento</label>
                  <select {...formik.getFieldProps("scheduling")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.scheduling && formik.errors.scheduling ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.scheduling} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label>Coleção</label>
                  <select {...formik.getFieldProps("collection")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.collection && formik.errors.collection ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.collection} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label>Arquivos</label>
                  <select {...formik.getFieldProps("showFile")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.showFile && formik.errors.showFile ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.showFile} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label>Ficha técnica</label>
                  <select {...formik.getFieldProps("datasheet")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.datasheet && formik.errors.datasheet ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.datasheet} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label>Ficha técnica Qualidade</label>
                  <select {...formik.getFieldProps("datasheetQuality")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.datasheetQuality &&
                  formik.errors.datasheetQuality ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.datasheetQuality} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              <div className="field-group">
                <div className="field">
                  <label>Atualização cadastral</label>
                  <select {...formik.getFieldProps("requestUpdateClient")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.requestUpdateClient &&
                  formik.errors.requestUpdateClient ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.requestUpdateClient} </span>
                    </Error>
                  ) : null}
                </div>
                <div className="field">
                  <label>Notas de serviço</label>
                  <select {...formik.getFieldProps("serviceInvoice")}>
                    <option value={1}>Sem permissão</option>
                    <option value={2}>Leitura</option>
                    <option value={3}>Edição e Leitura</option>
                  </select>
                  {formik.touched.serviceInvoice &&
                  formik.errors.serviceInvoice ? (
                    <Error>
                      <FiAlertCircle color="#f00" size={16} />
                      <span> {formik.errors.serviceInvoice} </span>
                    </Error>
                  ) : null}
                </div>
              </div>

              {permission &&
                permission.permission &&
                permission.permission > 2 && (
                  <ButtunSubmit type="submit"> Alterar dados </ButtunSubmit>
                )}
            </Form>
          </ContainerForm>
        </>
      ) : (
        <ScreenLoading>
          <Loading
            colorLoading="#333"
            size={40}
            borderColor={"#888"}
            borderSize={5}
          />
        </ScreenLoading>
      )}
    </Container>
  );
};

export default User;
